
const NoResults = () => {
  return (
    <span>
      No se han encontrado resultados
    </span>
  )
}

export default NoResults
