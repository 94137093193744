import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { useState } from 'react';
import TableResults from './TableResults';
import useScreenWidth from '../../../../../../../hooks/useScreenWidth';

const InfoTabs = ({iamResults, oemResults, dataInfoDetail}) => {
  const [tab, setTab] = useState('oem');
  const handleChangeTab = (e, tab) => { setTab(tab); }
  const screenWidth = useScreenWidth()
  return (
    <div>
      <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label="Ajustes">
                <Tab label={screenWidth < 500 ? "OEM" : "Referencias OEM"} value="oem" />
                <Tab label={screenWidth < 500 ? "IAM equivalentes" : "Referencias IAM equivalentes"} value="iam" />
            </TabList>
          </Box>
          <TabPanel value="oem">
              <TableResults tab={'oem'} data={oemResults} dataInfoDetail={dataInfoDetail} />
          </TabPanel>
          <TabPanel value="iam">
              <TableResults tab={'iam'} data={iamResults} dataInfoDetail={dataInfoDetail} />
          </TabPanel>
      </TabContext>
    </div>
  )
}

export default InfoTabs
