import { useState, useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Box } from "@mui/material";
import VirtualizedAutocompletePersonas from "../../../../../shared/components/AutocompletePersonas";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import moment from 'moment';
import { useSelector } from "react-redux";
import VirtualizedAutocomplete from "../../../../../shared/components/Autocomplete";

export default function AvisoStockForm({
    onSubmitAviso
}) {
    const piezasSchema = yup.object({   
        referencia: yup.string().required("La referencia es obligatoria"),
        referencia2: yup.string().optional(),
        fecha_inicio: yup.string().optional(),
        fecha_fin: yup.string().optional(),
        tipo_pieza_id: yup.number().integer("El tipo de pieza es obligatorio").required("El tipo de pieza es obligatorio"),
        entity_id: yup.number().integer("El cliente es obligatorio").moreThan(0).required("El cliente es obligatorio"),
    });
    const { register, handleSubmit, setValue, formState: {errors}, control, watch } = useForm({
        resolver: yupResolver(piezasSchema),
        mode: "onChange",
        defaultValues: {
            referencia: '',
            referencia2: '',
            fecha_inicio: '',
            fecha_fin: '',
            entity: null,
            entity_id: null,
            tipo_pieza_id: null,
            infinito: 0
        }
    });
    const tipoPiezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });
    const [startDateValue, setStartDateValue] = useState(null);
    const [endDateValue, setEndDateValue] = useState(null);
    const [cliente, setCliente] = useState(null);

    useEffect(() => {
        
    }, []);

    const handleChangeCliente = async (cliente) => {
        if(cliente) {
            setValue('entity', cliente);
            setValue('entity_id', cliente.id);
            setCliente(cliente);
        } else {
            setValue('entity', null);
            setValue('entity_id', null);
            setCliente(null);
        }
    }

    const handleChangeTipoPieza = (value) => { 
        if(value) {
            setValue('tipo_pieza', value);
            setValue('tipo_pieza_id', value.id);
        } else {
            setValue('tipo_pieza', null);
            setValue('tipo_pieza_id', 0);
        }
    }
    
    const onSubmit = async (data, e) => {
        e.preventDefault();
        
        if(data.infinito == 1) {
            data.fecha_inicio = null;
            data.fecha_fin = null;
        }

        onSubmitAviso(data);
    };
 
    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            <div className="w-100">
                <form className="form mt-4 d-flex flex-column align-items-end" onSubmit={handleSubmit(onSubmit)}>
                    <div className="w-100">
                        <div className="input-group">
                            <label className="me-3">Sin rango de fechas?</label>
                            <input 
                                type="checkbox"
                                {...register("infinito")}
                                defaultChecked={false} />
                        </div>
                    </div>

                    <div className="w-100 d-flex flex-column flex-md-row flex-wrap flex-md-nowrap ">
                        <div className="form-group">
                            <label htmlFor="referencia">Referencia</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                maxLength={50}
                                {...register("referencia", { required: true, maxLength: 50 })} 
                                defaultValue={''} />

                            <div className="form-invalid">
                                {(errors.referencia) && errors.referencia?.message}
                            </div>
                        </div>

                        <div className="form-group ms-3">
                            <label htmlFor="referencia2">Referencia 2</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                maxLength={50}
                                {...register("referencia2", { required: true, maxLength: 50 })} 
                                defaultValue={''} />

                            <div className="form-invalid">
                                {(errors.referencia2) && errors.referencia2?.message}
                            </div>
                        </div>
                    </div>

                    <div className="w-100 d-flex flex-column flex-md-row flex-wrap flex-md-nowrap align-items-end">
                        <div className="form-group me-md-3 me-0">
                            <label htmlFor="entity_id">Cliente</label>
                            <input type="hidden" 
                                {...register("entity_id")} 
                                defaultValue={null} />

                            <VirtualizedAutocompletePersonas 
                                id={"entity"}
                                control={control}
                                item={'cliente'}
                                labels={['nombre', 'cif', 'telefono']}
                                defaultValue={cliente}
                                onChange={handleChangeCliente}
                            />

                            <div className="form-invalid">
                                {(errors.entity_id) && errors.entity_id?.message}
                            </div>
                        </div>

                        <div className="form-group flex-grow-1">
                            <label htmlFor="tipo_pieza_id">Tipo de pieza</label>
                            { <VirtualizedAutocomplete
                                    id={"tipo_pieza"}
                                    control={control}
                                    options={tipoPiezas.sort((a, b) => a.familia_tipo_pieza_id - b.familia_tipo_pieza_id)}
                                    groupBy={null}
                                    labels={['nombre']}
                                    defaultValue={null} 
                                    onChange={handleChangeTipoPieza}
                                />
                            }

                            <div className="form-invalid">
                                {errors.tipo_pieza_id?.message && 'Selecciona un tipo de pieza válido.'}
                            </div>
                        </div>
                    </div>

                    { (watch('infinito') == 0) &&
                        <div className="w-100 d-flex flex-column flex-md-row flex-wrap flex-md-nowrap">
                            <div className="form-group">
                                <label>Fecha inicial:</label>

                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                    <DatePicker
                                        disablePast
                                        label=""
                                        value={startDateValue}
                                        onChange={(newValue) => {
                                            setStartDateValue(newValue);
                                            setValue('fecha_inicio', moment(newValue).format('YYYY-MM-DD'))
                                        }}
                                        mask={'__/__/____'}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box className="date-picker me-3" sx={{ position: 'relative' }}>
                                                <input className="form-control" ref={inputRef} {...inputProps} />
                                                {InputProps?.endAdornment}
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                            </div>

                            <div className="form-group">
                                <label>Fecha final:</label>

                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                    <DatePicker
                                        label=""
                                        value={endDateValue}
                                        onChange={(newValue) => {
                                            setEndDateValue(newValue);
                                            setValue('fecha_fin', moment(newValue).format('YYYY-MM-DD'))
                                        }}
                                        mask={'__/__/____'}
                                        inputFormat="dd/MM/yyyy"
                                        minDate={startDateValue}
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box className="date-picker" sx={{ position: 'relative' }}>
                                                <input className="form-control" ref={inputRef} {...inputProps} />
                                                {InputProps?.endAdornment}
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                    }
                    
                    <div className="d-flex align-items-center align-self-end ms-3">
                        <button 
                            type="submit"
                            className="btn btn-primary align-self-end">Guardar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}