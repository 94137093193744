import { useEffect, useState } from "react";

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

import config from "../config";

const useEcho = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const [echoInstance, setEchoInstance] = useState(null);

    useEffect(() => {
        //  Setup Pusher client
        const PusherClient = new Pusher(config.reverb.appKey, {
            broadcaster: 'reverb',
            key: config.reverb.appKey,
            wsHost: config.reverb.host,
            wsPort: config.reverb.port ?? 80,
            wssPort: config.reverb.port ?? 443,
            forceTLS: (config.reverb.scheme ?? 'https') === 'https',
            enabledTransports: ['ws', 'wss'],
            cluster: {},
            disableStats: true,
            apiOriginAllow: {
                allowCors: true,
                allowOrigin: "*",
                allowMethods: "GET,POST",
                allowHeaders: "Origin, Content-Type, X-Auth-Token, X-Requested-With, Accept, Authorization, X-CSRF-TOKEN, X-Socket-Id"
            },
            authorizer: (channel, options) => {
                return {
                    authorize: (socketId, callback) => {
                        fetch(config.recomotor.crmApiUrl + 'broadcasting/auth', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + user.token
                            },
                            body: JSON.stringify({
                                socket_id: socketId,
                                channel_name: channel.name
                            })
                        })
                        .then(response => response.json())
                        .then(data => {
                            callback(false, data);
                        })
                        .catch((error) => {
                            callback(true, error);
                        });
                    }
                };
            }
        });

        // Create Echo instance
        const echo = new Echo({
            broadcaster: "reverb",
            client: PusherClient,
        });

        setEchoInstance(echo);

        // Cleanup on unmount
        return () => {
            if (echo) {
                echo.disconnect();
            }
        };
    }, []);

    return echoInstance;
};

export default useEcho;