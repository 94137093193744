import React, { useState } from 'react';

export default function PorcentajeFalloIncidenciaTable({
    data,
    from,
    ventasColumn,
    onAction
}) {

    return (
        <div className="w-100">
            <div className='table-responsive'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Tipo</th>
                            <th className='text-center'>Incidencias</th>
                            {ventasColumn && <th className='text-center'>Ventas</th>}
                            <th className='text-center'>% fallo</th>
                        </tr>
                    </thead>
                    <tbody>
                        { data ? data?.map((dataset, i) => {
                            return (
                                <tr key={'dataset-tipo-' + i} style={{cursor: 'pointer'}} onClick={() => from === 'incidencias' ? onAction(dataset) : null}>
                                    <td>{dataset.label}</td>
                                    <td className='text-center'>{dataset.count_incidencias}</td>
                                    {ventasColumn && <td className='text-center'>{dataset.count_ventas}</td>}
                                    <td className='text-center'>{dataset.fallo}%</td>
                                </tr>
                            )
                        }) : <tr><td colSpan={4} className='text-center'>No hay datos</td></tr>}
                    </tbody>
                </table>
            </div>
        </div>
    )
}