import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Loading from "../../Loading";
import ServiceRow from "../ServiceRow";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

export default function PresupuestosServicesList({
    isLoading,
    isVoluminoso,
    destino,
    proveedor,
    selectedOption,
    serviciosEnvioList,
    precioEnvioBaleares,
    onSelectServicio,
    onChangePrecioEnvio
}) {
    const [provServices, setProvServices] = useState([]);
    const [hermesServices, setHermesServices] = useState([]);

    useEffect(() => {
        if(!proveedor.credito || !proveedor.integracion) {
            const prov = serviciosEnvioList.filter(se => se.transportista_id === 1);
            const hermes = serviciosEnvioList.filter(se => se.transportista_id !== 1);

            setProvServices(prov);
            setHermesServices(hermes);
        }
    }, [isLoading, serviciosEnvioList, proveedor]);

    return (
        <div className="w-100 table-responsive">
            <table className="table">
                <tbody>
                    { !isLoading ? 
                    <>
                        { (!proveedor.credito) ?
                            <>
                            { provServices?.length > 0 ?
                                provServices?.map((option, i) => {
                                return (
                                    <ServiceRow 
                                        option={option}
                                        selectedOption={selectedOption}
                                        i={i}
                                        key={'servicio-prov-' + i}
                                        id={'proveedor'}
                                        isVoluminoso={isVoluminoso}
                                        destino={destino}
                                        precioEnvioBaleares={precioEnvioBaleares}
                                        onChangePrecioEnvio={onChangePrecioEnvio}
                                        onSelectServicio={onSelectServicio}
                                    />
                                )})
                                : 
                                <tr>
                                    <td colSpan="5" className="text-center">
                                        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="action" color="#ffc107" /> 
                                        No hay servicios de envio de proveedor para este destino.
                                        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="action" color="#ffc107" /> 
                                    </td>
                                </tr>
                            }

                            <tr>
                                <td colSpan="5">
                                    <div className="w-100">
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMore />}
                                                aria-controls="hermes-content"
                                                id="hermes-header"
                                                >
                                                Más
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                    <table className="w-100">
                                                        <tbody>
                                                        { hermesServices?.length > 0 ?
                                                            hermesServices?.map((option, i) => {
                                                            return (
                                                                <ServiceRow 
                                                                    option={option}
                                                                    selectedOption={selectedOption}
                                                                    i={i}
                                                                    key={'servicio-hermes-' + i}
                                                                    id={'hermes'}
                                                                    isVoluminoso={isVoluminoso}
                                                                    destino={destino}
                                                                    precioEnvioBaleares={precioEnvioBaleares}
                                                                    onChangePrecioEnvio={onChangePrecioEnvio}
                                                                    onSelectServicio={onSelectServicio}
                                                                />
                                                            )})
                                                            : 
                                                            <tr>
                                                                <td colSpan="5" className="text-center">
                                                                    <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="action" color="#ffc107" /> 
                                                                    No hay servicios de envio por nuestra cuenta para este destino.
                                                                    <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="action" color="#ffc107" /> 
                                                                </td>
                                                            </tr>
                                                        }
                                                        </tbody>
                                                    </table>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </td>
                            </tr>
                            </>
                            :
                            <>
                            { serviciosEnvioList?.length > 0 ?
                                serviciosEnvioList?.map((option, i) => {
                                return (
                                    <ServiceRow 
                                        option={option}
                                        selectedOption={selectedOption}
                                        i={i}
                                        key={'servicio-' + i}
                                        id={'todos'}
                                        isVoluminoso={isVoluminoso}
                                        destino={destino}
                                        precioEnvioBaleares={precioEnvioBaleares}
                                        onChangePrecioEnvio={onChangePrecioEnvio}
                                        onSelectServicio={onSelectServicio}
                                    />
                                )})
                                : 
                                <tr>
                                    <td colSpan="5" className="text-center">
                                        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="action" color="#ffc107" /> 
                                        No hay servicios de envio para este destino.
                                        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="action" color="#ffc107" /> 
                                    </td>
                                </tr>
                            }
                            </>
                        }
                    </>
                        : 
                        <tr>
                            <td colSpan="5"><Loading /></td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
     )
}