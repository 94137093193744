import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { removeImagesBG, saveImages } from "../../../../../../../../../shared/services/piezas";
import UploadForm from "./components/UploadForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { setErrorMessage } from "../../../../../../../../../shared/helpers/functionalities";

export default function Images({
    presupuestoId,
    piezaId,
    onDone,
    onClose
}) {
    const dispatch = useDispatch();
    const [images, setImages] = useState(null);

    useEffect(() => {
    }, []);

    const handleSubmitImages = async(data) => {
        dispatch({type: 'loading/set', payload: true});
        const response = await removeImagesBG(data)
        .catch(function (error) {
            dispatch({type: 'loading/set', payload: false});
            toast.error(setErrorMessage(error))
        });

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            let imgs = response.data?.map(img => img);
            setImages(imgs);
        }
    }

    const handleSelectImages = async() => {
        dispatch({type: 'loading/set', payload: true});
        const response = await saveImages({
            presupuesto_id: presupuestoId,
            pieza_id: piezaId,
            images
        }).catch(function (error) {
            dispatch({type: 'loading/set', payload: false});
            toast.error(setErrorMessage(error))
        });

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            onDone();
        }
    }

    const handleGoBack = () => setImages(null);

    const handleDeleteImg = (i) => {
        const imagesList = [...images];
        imagesList.splice(i, 1);
        setImages(imagesList);
    }
    
    return (
        <div className="w-100 d-flex flex-column">
            { (images) ?
                <div className="w-100">
                    <label>Resultados:</label>

                    <div className="w-100 d-flex align-items-stretch pb-4">
                        { (images?.length > 0) ?
                            images.map((img, i) => {
                                return (
                                    <div className="d-flex flex-column align-items-center" key={'pieza-img-'+i}>
                                        <img 
                                            src={`data:image/jpeg;base64,${img}`}
                                            alt="" 
                                            style={{'width': 'auto', 'height' : '250px'}}
                                        />

                                        <span 
                                            title="Eliminar" 
                                            style={{cursor: 'pointer'}} 
                                            onClick={() => handleDeleteImg(i)}>
                                            <FontAwesomeIcon icon={faTrash} size="1x" className="action" color="#dc3545" />
                                        </span>
                                    </div>
                                )
                            })
                            
                        : <p>No hay imagenes.</p>
                        }
                    </div>

                    <div className="w-100 d-flex align-items-center justify-content-end mt-4">
                        <button type="button" className="btn btn-default me-3" onClick={() => handleGoBack()}>Atrás</button>
                        <button type="button" className="btn btn-primary" onClick={() => handleSelectImages()}>Guardar selección</button>
                    </div>
                </div>
            
                :

                <div className="w-100 mt-3">
                    <UploadForm 
                        onSubmit={handleSubmitImages}
                    />
                </div>
            }
        </div>
    )
}