import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { deleteDetalle, newDetalle, updateDetalle } from "../../../../../shared/services/proveedores";
import DetalleForm from "./components/DetalleForm";
import InfoModal from "../../../../../shared/components/InfoModal";
import { useDispatch } from "react-redux";

export default function DetallesTable({
    detalles,
    proveedorId,
    onReloadFicha
}) {
    const dispatch = useDispatch();
    const [currentDetalle, setCurrentDetalle] = useState(null);
    const [openForm, setOpenForm] = useState(false);

    useEffect(() => {
        
    }, [detalles]);

    const handleOpenForm = (detalle = null) => {
        setCurrentDetalle(detalle);
        setOpenForm(true);
    }
    const handleCloseForm = () => {
        setCurrentDetalle(null);
        setOpenForm(false);
    }

    const handleSubmitDetalle = async(detalle) => {
        dispatch({type: 'loading/set', payload: true});
        let response;

        if(currentDetalle) {
            response = await updateDetalle(detalle);
        } else {
            detalle.proveedor_id = proveedorId;
            response = await newDetalle(detalle);
        }

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            onReloadFicha();
        }
    }

    const handleDelete = async(detalle) => {
        dispatch({type: 'loading/set', payload: true});
        const response = await deleteDetalle(detalle.id);

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            onReloadFicha();
        }
    }
    
    return (
        <div className="w-100 d-flex flex-column">
            <div className="w-100 d-flex align-items-center justify-content-end">
                <button type="button" className="btn btn-add mb-4" onClick={() => handleOpenForm()}>
                    <span className="add">
                        <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                    </span>
                    Nuevo
                </button>
            </div>

            { detalles && detalles.length > 0 ?
                <div className="w-100 table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>fecha</th>
                                <th className="text-center">observaciones</th>
                                <th className="text-center">acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            { detalles?.map(detalle => {
                                return (
                                    <tr key={'detalles-' + detalle.id}>
                                        <td width={'12%'}>{moment(detalle.fecha).format('DD-MM-YYYY')}</td>
                                        <td>{detalle.observaciones}</td>
                                        <td className="text-center" width={'8%'}>
                                            <span title="Editar" className="action me-2" style={{cursor: 'pointer'}} onClick={() => handleOpenForm(detalle)}>
                                                <FontAwesomeIcon icon={faEdit} size="1x" color="#215732" />
                                            </span>
                                            <span title="Eliminar" className="action me-2" style={{cursor: 'pointer'}} onClick={() => handleDelete(detalle)}>
                                                <FontAwesomeIcon icon={faTrash} size="1x" color="#dc3545" />
                                            </span>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

                : <p>No hay puntos importantes a destacar.</p>
            }

            { (openForm) && 
                <InfoModal
                    state={openForm}
                    title={(currentDetalle ? 'Editar' : 'Nuevo') + ' punto importante'}
                    content={
                        <DetalleForm 
                            detalle={currentDetalle}
                            onSubmit={handleSubmitDetalle}
                        />
                    }
                    width={'sm'}
                    onClose={handleCloseForm}
                ></InfoModal>
            }
        </div>
    );
}