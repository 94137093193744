import { useState, useEffect } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

export default function FacturasProveedorLista({onDownloadInvoice, venta}) {
    const [ list, setList ] = useState([]);

    useEffect(() => {
        const invoices = venta.factura_proveedor.split("|");
        setList(invoices);
    }, []);

    useEffect(() => {
        if(venta) {
            const invoices = venta.factura_proveedor.split("|");
            setList(invoices);
        }
    }, [venta]);

    const handleDownloadInvoice = async (invoice) => {
        onDownloadInvoice({factura_proveedor: invoice});
    };
 
    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            { list.length > 0 &&
                <div className="table-responsive">
                    <table className="table">
                        <tbody>
                            { list.map((invoice, i) => {
                                return (
                                    <tr key={'invoice-' + i}>
                                        <td>{invoice}</td>
                                        <td className="text-end">
                                            <FontAwesomeIcon icon={faDownload} size="1x" className="action" color="#215732" onClick={() => handleDownloadInvoice(invoice)} />
                                        </td>
                                    </tr>
                                )
                            }) }
                        </tbody>
                    </table>
                </div>
            }
        </div>
    );
}