import { useState, useEffect } from "react";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import ContactoForm from "./components/ContactoForm";
import MedidasForm from "./components/MedidasForm";
import { unset } from "lodash";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightArrowLeft, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import EnvioSearcher from "../../../shared/components/EnvioSearcher";

export default function EnvioForm({
    incidencia,
    fromSection,
    onSubmitEnvio, 
}) {
    const tipoPiezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });
    const paises = useSelector(state => {
        const { paises } = state;
        return paises;
    });

    const [recalcular, setRecalcular] = useState(false);
    const [dataEnvio, setDataEnvio] = useState(null);
    const [dataEnvioArray, setDataEnvioArray] = useState([]);
    const [tipoPieza, setTipoPieza] = useState(null);
    const [allDisabled, setAllDisabled] = useState(false);
    const [seguroDisabled, setSeguroDisabled] = useState(true);

    const envioSchema = yup.object({
        remitente_nombre: yup.string().required(),
        remitente_telefono: yup.string().required(),
        remitente_email: yup.string().required(),
        remitente_direccion: yup.string().required(),
        remitente_poblacion: yup.string().required(),
        remitente_codigo_postal: yup.string().required(),
        remitente_area: yup.string().required(),
        remitente_pais_id: yup.number().integer().required(),
        destinatario_nombre: yup.string().required(),
        destinatario_telefono: yup.string().required(),
        destinatario_email: yup.string().required(),
        destinatario_direccion: yup.string().required(),
        destinatario_poblacion: yup.string().required(),
        destinatario_codigo_postal: yup.string().required(),
        destinatario_area: yup.string().required(),
        destinatario_pais_id: yup.number().integer().required(),
        peso: yup.number().required(),
        largo: yup.number().required(),
        ancho: yup.number().required(),
        alto: yup.number().required(),
        valor_asegurado: yup.number().required(),
        transportista_id: yup.number().integer().required(),
        servicio_envio_id: yup.number().integer().required(),
    }).required();

    const methods = useForm({
        resolver: yupResolver(envioSchema),
        defaultValues: {
            remitente_tipo: 'buscar',
            remitente_nombre: '',
            remitente_telefono: '',
            remitente_email: '',
            remitente_direccion: '',
            remitente_poblacion: '',
            remitente_codigo_postal: '',
            remitente_area: '',
            remitente_pais_id: null,
            remitente_data: null,
            remitente_entity_id: '',
            remitente_direccion_envio_id: '',
            remitente_proveedor_id: '',
            remitente_almacen_id: '',
            remitente_remitente_id: '',
            remitente_destinatario_id: '',
            destinatario_tipo: 'buscar',
            destinatario_nombre: '',
            destinatario_telefono: '',
            destinatario_email: '',
            destinatario_direccion: '',
            destinatario_poblacion: '',
            destinatario_codigo_postal: '',
            destinatario_area: '',
            destinatario_pais_id: null,
            destinatario_data: null,
            destinatario_entity_id: '',
            destinatario_direccion_envio_id: '',
            destinatario_proveedor_id: '',
            destinatario_almacen_id: '',
            destinatario_remitente_id: '',
            destinatario_destinatario_id: '',
            peso: 0,
            largo: 0,
            ancho: 0,
            alto: 0,
            peso_total: 0,
            peso_volumetrico: 0,
            seguro: 0,
            porcentaje_seguro: 0,
            valor_asegurado: 0,
            tipo_pieza: null,
            tipo_pieza_id: '',
            observaciones: '',
            transportista_id: null,
            servicio_envio_id: null,
            pais_origen_id: 1,
            pais_destino_id: 1
        }
    });

    useEffect(() => {
        if(incidencia) {
            const envio = incidencia?.venta?.envios?.find(e => e.piezas?.find(p => p.id === incidencia.piezas[0].id));
            const remitente = envio.destinatario;
            const destinatario = envio.remitente;
            const tipo = tipoPiezas.find(tp => tp.id === incidencia.piezas[0].tipo_pieza_id);
            const observaciones = incidencia.piezas[0].nombre + ' ID: ' + incidencia.piezas[0].id_referencia_plataforma;
            setTipoPieza(tipo);
            methods.setValue('tipo_pieza', tipo);
            methods.setValue('observaciones', observaciones);
            

            if(remitente) {
                methods.setValue('remitente_nombre', remitente.nombre);
                methods.setValue('remitente_telefono', remitente.telefono);
                methods.setValue('remitente_email', remitente.email);
                methods.setValue('remitente_direccion', remitente.direccion);
                methods.setValue('remitente_poblacion', remitente.poblacion);
                methods.setValue('remitente_codigo_postal', remitente.codigo_postal)
                methods.setValue('remitente_area', remitente.area)
                methods.setValue('remitente_pais_id', remitente.pais_id);
                methods.setValue('remitente_data', remitente);
                methods.setValue('remitente_entity_id', remitente?.entity_id);
                methods.setValue('remitente_direccion_envio_id', remitente?.direccion_envio_id);
                methods.setValue('remitente_proveedor_id', remitente?.proveedor_id);
                methods.setValue('remitente_almacen_id', remitente?.almacen_id);
            }

            if(destinatario) {
                methods.setValue('destinatario_nombre', destinatario.nombre);
                methods.setValue('destinatario_telefono', destinatario.telefono);
                methods.setValue('destinatario_email', destinatario.email);
                methods.setValue('destinatario_direccion', destinatario.direccion);
                methods.setValue('destinatario_poblacion', destinatario.poblacion);
                methods.setValue('destinatario_codigo_postal', destinatario.codigo_postal);
                methods.setValue('destinatario_area', destinatario.area);
                methods.setValue('destinatario_pais_id', destinatario.pais_id);
                methods.setValue('destinatario_data', destinatario);
                methods.setValue('destinatario_entity_id', destinatario?.entity_id);
                methods.setValue('destinatario_direccion_envio_id', destinatario?.direccion_envio_id);
                methods.setValue('destinatario_proveedor_id', destinatario?.proveedor_id);
                methods.setValue('destinatario_almacen_id', destinatario?.almacen_id);
            }

            setDataEnvioArray([{...remitente}, {...destinatario}])
            setDataEnvio({remitente, destinatario})
        }
    }, [incidencia]);

    const handleChangeMedidas = () => { 
        const servicioId = methods.getValues('servicio_envio_id');
            
        if(servicioId !== "" && servicioId !== null) setRecalcular(true); 
    }

    // metodo para guardar la nueva pieza
    const handleSubmitEnvio = async (data, e) => {
        e.preventDefault();
       
        unset(data.remitente_tipo);
        unset(data.remitente_data);
        unset(data.destinatario_tipo);
        unset(data.destinatario_data);
        unset(data.tipo_pieza);
        unset(data.peso_volumetrico);

        const envio = {
            remitente: {
                nombre: data.remitente_nombre,
                telefono: data.remitente_telefono,
                email: data.remitente_email,
                direccion: data.remitente_direccion,
                poblacion: data.remitente_poblacion,
                codigo_postal: data.remitente_codigo_postal,
                area: data.remitente_area,
                pais_id: data.remitente_pais_id,
                entity_id: (data.remitente_entity_id === "null" || data.remitente_entity_id === "") ? null : data.remitente_entity_id,
                direccion_envio_id: (data.remitente_entity_id === "null" || data.remitente_entity_id === "") ? null : data.remitente_direccion_envio_id,
                proveedor_id: (data.remitente_proveedor_id === "null" || data.remitente_proveedor_id === "") ? null : data.remitente_proveedor_id,
                almacen_id: (data.remitente_almacen_id === "null" || data.remitente_almacen_id === "") ? null : data.remitente_almacen_id,
                remitente_id: (data.remitente_remitente_id === "null" || data.remitente_remitente_id === "") ? null : data.remitente_remitente_id,
                destinatario_id: (data.remitente_destinatario_id === "null" || data.remitente_destinatario_id === "") ? null : data.remitente_destinatario_id,
            },
            destinatario: {
                nombre: data.destinatario_nombre,
                telefono: data.destinatario_telefono,
                email: data.destinatario_email,
                direccion: data.destinatario_direccion,
                poblacion: data.destinatario_poblacion,
                codigo_postal: data.destinatario_codigo_postal,
                area: data.destinatario_area,
                pais_id: data.destinatario_pais_id,
                entity_id: (data.destinatario_entity_id === "null" || data.destinatario_entity_id === "") ? null : data.destinatario_entity_id,
                direccion_envio_id: (data.destinatario_entity_id === "null" || data.destinatario_entity_id === "") ? null : data.destinatario_direccion_envio_id,
                proveedor_id: (data.destinatario_proveedor_id === "null" || data.destinatario_proveedor_id === "") ? null : data.destinatario_proveedor_id,
                almacen_id: (data.destinatario_almacen_id === "null" || data.destinatario_almacen_id === "") ? null : data.destinatario_almacen_id,
                remitente_id: (data.destinatario_remitente_id === "null" || data.destinatario_remitente_id === "") ? null : data.destinatario_remitente_id,
                destinatario_id: (data.destinatario_destinatario_id === "null" || data.destinatario_destinatario_id === "") ? null : data.destinatario_destinatario_id,
            },
            medidas: {
                alto: data.alto,
                ancho: data.ancho,
                largo: data.largo,
                peso: data.peso,
                peso_total: data.peso_total,
            },
            precio_transporte: data.precio_transporte,
            tipo_pieza_id: data.tipo_pieza_id,
            observaciones: data.observaciones ? data.observaciones : '',
            seguro: seguroDisabled ? false : true,
            porcentaje_seguro: data.porcentaje_seguro,
            valor_asegurado: data.valor_asegurado,
            transportista_id: data.transportista_id,
            servicio_envio_id: data.servicio_envio_id,
            incidencia_id: incidencia ? incidencia.id : null
        }

        onSubmitEnvio(envio);
    };

    const handleDisable = (disabled) => { setAllDisabled(disabled); }

    const handleSelectServicioEnvio = (servicio) => {
        methods.setValue('servicio_envio_id', servicio.servicio_envio_id);
        methods.setValue('transportista_id', servicio.transportista_id);
        methods.setValue('seguro', seguroDisabled ? false : true);
        methods.setValue('porcentaje_seguro', servicio.porcentaje_seguro);
        methods.setValue('precio_transporte', servicio.precio_transporte);
    }

    const reverse = () => {
        const tipoRemitente = methods.getValues('remitente_tipo');
        const tipoDestinatario = methods.getValues('destinatario_tipo');
    
        const remitente = methods.getValues('remitente_data') || {
            almacen_id: methods.getValues('remitente_almacen_id') || '',
            area: methods.getValues('remitente_area') || '',
            codigo_postal: methods.getValues('remitente_codigo_postal') || '',
            destinatario_id: methods.getValues('remitente_destinatario_id') || '',
            direccion: methods.getValues('remitente_direccion') || '',
            direccion_envio_id: methods.getValues('remitente_direccion_envio_id') || '',
            email: methods.getValues('remitente_email') || '',
            entity_id: methods.getValues('remitente_entity_id') || '',
            nombre: methods.getValues('remitente_nombre') || '',
            pais_id: methods.getValues('remitente_pais_id') || null,
            poblacion: methods.getValues('remitente_poblacion') || '',
            proveedor_id: methods.getValues('remitente_proveedor_id') || '',
            remitente_id: methods.getValues('remitente_remitente_id') || '',
            telefono: methods.getValues('remitente_telefono') || '',
        };
        
        const destinatario = methods.getValues('destinatario_data') || {
            almacen_id: methods.getValues('destinatario_almacen_id') || '',
            area: methods.getValues('destinatario_area') || '',
            codigo_postal: methods.getValues('destinatario_codigo_postal') || '',
            destinatario_id: methods.getValues('destinatario_destinatario_id') || '',
            direccion: methods.getValues('destinatario_direccion') || '',
            direccion_envio_id: methods.getValues('destinatario_direccion_envio_id') || '',
            email: methods.getValues('destinatario_email') || '',
            entity_id: methods.getValues('destinatario_entity_id') || '',
            nombre: methods.getValues('destinatario_nombre') || '',
            pais_id: methods.getValues('destinatario_pais_id') || null,
            poblacion: methods.getValues('destinatario_poblacion') || '',
            proveedor_id: methods.getValues('destinatario_proveedor_id') || '',
            remitente_id: methods.getValues('destinatario_remitente_id') || '',
            telefono: methods.getValues('destinatario_telefono') || '',
        };
    
        methods.setValue('remitente_almacen_id', destinatario?.almacen_id);
        methods.setValue('remitente_area', destinatario.area);
        methods.setValue('remitente_codigo_postal', destinatario.codigo_postal);
        methods.setValue('remitente_data', destinatario);
        methods.setValue('remitente_direccion', destinatario.direccion);
        methods.setValue('remitente_direccion_envio_id', destinatario?.direccion_envio_id);
        methods.setValue('remitente_email', destinatario.email);
        methods.setValue('remitente_entity_id', destinatario?.entity_id);
        methods.setValue('remitente_nombre', destinatario.nombre);
        methods.setValue('remitente_pais_id', destinatario.pais_id);
        methods.setValue('remitente_poblacion', destinatario.poblacion);
        methods.setValue('remitente_proveedor_id', destinatario?.proveedor_id);
        methods.setValue('remitente_telefono', destinatario.telefono);
        methods.setValue('remitente_tipo', tipoDestinatario);

        methods.setValue('destinatario_almacen_id', remitente?.almacen_id);
        methods.setValue('destinatario_area', remitente.area);
        methods.setValue('destinatario_codigo_postal', remitente.codigo_postal);
        methods.setValue('destinatario_data', remitente);
        methods.setValue('destinatario_direccion', remitente.direccion);
        methods.setValue('destinatario_direccion_envio_id', remitente?.direccion_envio_id);
        methods.setValue('destinatario_email', remitente.email);
        methods.setValue('destinatario_entity_id', remitente?.entity_id);
        methods.setValue('destinatario_nombre', remitente.nombre);
        methods.setValue('destinatario_pais_id', remitente.pais_id);
        methods.setValue('destinatario_poblacion', remitente.poblacion);
        methods.setValue('destinatario_proveedor_id', remitente?.proveedor_id);
        methods.setValue('destinatario_telefono', remitente.telefono);
        methods.setValue('destinatario_tipo', tipoRemitente);
    };

    const handleSetSeguro = (enabled) => setSeguroDisabled(!enabled); 

    return (
        <div className="w-100 d-flex flex-column justify-content-center mt-2">
            <FormProvider {...methods}>
                <form className="form mb-4" onSubmit={methods.handleSubmit(handleSubmitEnvio)}>
                    { (allDisabled) &&
                        <div className="w-100 mb-3">
                            <p className="m-0">
                                <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action" color="#dc3545" />
                                <span className="mx-2">No se puede realizar un envío a canarias.</span>
                                <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action" color="#dc3545" />
                            </p>
                        </div>
                    }

                    <div className="w-100 d-flex align-items-center justify-content-center mt-2 mb-4">
                        <button className="btn btn-add" onClick={reverse}>
                            <span className="add me-0">
                                <FontAwesomeIcon icon={faArrowRightArrowLeft} size="1x" className="action" color="#215732" /> 
                            </span>
                        </button>
                    </div>


                    <>
                        <div className="w-100 d-flex flex-wrap flex-md-nowrap">
                            <div className="col-12 col-md-6 d-flex flex-column ps-0 pe-md-3">
                                <ContactoForm 
                                    data={dataEnvio}
                                    options={dataEnvioArray}
                                    control={methods.control}
                                    register={methods.register}
                                    setValue={(id, value) => methods.setValue(id, value)}
                                    getValues={methods.getValues}
                                    titulo={"Origen del paquete"}
                                    tipo={'remitente'}
                                    onDisable={handleDisable}
                                />
                            </div>
                            
                            <div 
                                className="col-12 col-md-6 d-flex flex-column ps-0 ps-md-3"
                                style={{borderLeft: '1px solid #000'}}
                            >
                                <ContactoForm 
                                    data={dataEnvio}
                                    options={dataEnvioArray}
                                    control={methods.control}
                                    register={methods.register}
                                    setValue={(id, value) => methods.setValue(id, value)}
                                    getValues={methods.getValues}
                                    titulo={"Destino del paquete"}
                                    tipo={'destinatario'}
                                    onDisable={handleDisable}
                                />
                            </div>
                        </div>

                        <div className="w-100 d-flex align-items-center">
                            <input type="hidden" 
                                name={`seguro`}
                                {...methods.register(`seguro`)} 
                                defaultValue={0} 
                            />
                            <input type="hidden" 
                                name={`procentaje_seguro`}
                                {...methods.register(`procentaje_seguro`)} 
                                defaultValue={0} 
                            />
                            <div className="form-group flex-grow-1">
                                <label htmlFor="observaciones">Observaciones</label>
                                <textarea
                                    disabled={allDisabled}
                                    {...methods.register("observaciones")}
                                    defaultValue={''} >
                                </textarea>
                            </div>
                        </div>

                        <div className="w-100 d-flex align-items-center">
                            <MedidasForm 
                                disabled={allDisabled}
                                tipo={tipoPieza}
                                seguro={seguroDisabled}
                                register={methods.register}
                                setValue={methods.setValue}
                                getValues={methods.getValues}
                                control={methods.control}
                                errors={methods.formState.errors}
                                onChangeMedidas={handleChangeMedidas}
                            />
                        </div>

                        <div className="w-100 d-flex align-items-center">
                            { (
                                methods.watch('remitente_codigo_postal') !== "" && 
                                methods.watch('destinatario_codigo_postal') !== "" &&
                                methods.watch('remitente_poblacion') !== "" &&
                                methods.watch('destinatario_poblacion') !== "" &&
                                methods.watch('alto') !== 0 &&
                                methods.watch('ancho') !== 0 &&
                                methods.watch('largo') !== 0 &&
                                methods.watch('peso') !== 0
                            ) &&
                                <EnvioSearcher 
                                    from={{
                                        codigo_postal: methods.getValues('remitente_codigo_postal'),
                                        poblacion: methods.getValues('remitente_poblacion'),
                                        area: methods.getValues('remitente_area'),
                                        pais_id: parseInt(methods.getValues('remitente_pais_id')) || 1,
                                        pais: paises.find(p => p.id === methods.getValues('remitente_pais_id'))?.codigo || 'ES'
                                    }}
                                    to={{
                                        codigo_postal: methods.getValues('destinatario_codigo_postal'),
                                        poblacion: methods.getValues('destinatario_poblacion'),
                                        area: methods.getValues('destinatario_area'),
                                        pais_id: parseInt(methods.getValues('destinatario_pais_id')) || 1,
                                        pais: paises.find(p => p.id === methods.getValues('destinatario_pais_id'))?.codigo || 'ES'
                                    }}
                                    medidas={
                                        { 
                                            alto: methods.getValues('alto'),
                                            ancho: methods.getValues('ancho'),
                                            largo: methods.getValues('largo'),
                                            peso: methods.getValues('peso'),
                                            peso_volumetrico: methods.getValues('peso_volumetrico'),
                                            tipo_pieza_id: methods.getValues('tipo_pieza_id')
                                        }
                                    }
                                    valor={methods.getValues('valor_asegurado')}
                                    fromPresupuesto={false}
                                    fromSection={fromSection}
                                    proveedor={{id: methods.getValues('remitente_proveedor_id')}}
                                    register={methods.register}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                    onSelectServicio={handleSelectServicioEnvio}
                                    onSeguroSelected={handleSetSeguro}
                                />
                            }
                        </div>
                    </>


                    <div className="d-flex align-items-center align-self-end mt-4">
                        <button 
                            disabled={allDisabled}
                            type="submit" 
                            className="btn btn-primary align-self-end">Guardar</button>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
}