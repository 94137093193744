import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import './backdrop.scss';

export default function LoadingBackdrop() {
    return (
        <div className="loading-backdrop">
            <div className="loading-backdrop-container">
                <div className="loading-backdrop-wrapper">
                    <div className="w-100 d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon icon={faSpinner} size="2x" spin /> <span className="ms-2">Cargando</span>
                    </div>

                    <p className="mt-3">Espera unos instantes mientras se procesa la petición...</p>
                </div>
            </div>
        </div>
    )
}