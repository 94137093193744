export const getActions = (item) => {
    const actions = {
        actions: [],
        extra: []
    };

    if(!item.activo) {
        actions.actions.push({
            label: 'Habilitar',
            icon: 'enable',
            action: 'enable'
        });
    }

    if(item.activo) {
        actions.actions.push({
            label: 'Deshabilitar',
            icon: 'disable',
            action: 'disable'
        });
    }

    return actions;
}