import React from 'react';

export default function InfoGeneralRecambistas({
    data
}) {
    return(
        <div className='w-100 d-flex flex-column align-items-start summary mb-5'>
            <h1>Información general</h1>

            <div className='w-100 d-flex align-items-center flex-wrap'>
                <div className='card-grid w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between mt-4'>
                    <div className='card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                        <h2>Presupuestos diarios</h2>
                        <h1>{data.presupuestos_dia}</h1>
                    </div>

                    <div className='card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                        <h2>Presupuestos semanales</h2>
                        <h1>{data.presupuestos_semana}</h1>
                    </div>

                    <div className='card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                        <h2>Presupuestos mensuales</h2>
                        <h1>{data.presupuestos_mes}</h1>
                    </div>
                </div>

                <div className='card-grid w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between mt-3'>
                    <div className='card-item d-flex flex-column align-items-center mt-3'>
                        <h2>Ventas diarias</h2>
                        <h1>{data.ventas_dia}</h1>
                    </div>

                    <div className='card-item d-flex flex-column align-items-center mt-3'>
                        <h2>Ventas semanales</h2>
                        <h1>{data.ventas_semana}</h1>
                    </div>

                    <div className='card-item d-flex flex-column align-items-center mt-3'>
                        <h2>Ventas mensuales</h2>
                        <h1>{data.ventas_mes}</h1>
                    </div>
                </div>

                <div className='card-grid w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between mt-3'>
                    <div className='card-item d-flex flex-column align-items-center mt-3'>
                        <h2>Facturación diaria</h2>
                        <h1>{data.facturacion_diaria}€</h1>
                    </div>

                    <div className='card-item d-flex flex-column align-items-center mt-3'>
                        <h2>Facturación semanal</h2>
                        <h1>{data.facturacion_semanal}€</h1>
                    </div>

                    <div className='card-item d-flex flex-column align-items-center mt-3'>
                        <h2>Facturación mensual</h2>
                        <h1>{data.facturacion_mensual}€</h1>
                    </div>
                </div>

                <div className='card-grid w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between mt-3'>
                    <div className='card-item d-flex flex-column align-items-center mt-3'>
                        <h2>Talleres que han comprado +2 veces en los últimos 60 dias</h2>
                        <h1>{data.talleres_60_dias}</h1>
                    </div>

                    <div className='card-item d-flex flex-column align-items-center mt-3'>
                        <h2>Bolsa envíos</h2>
                        <h1>{data.bolsa_envios}€</h1>
                    </div>
                </div>
            </div>
        </div>
    );
    
}