import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import Loading from "../../Loading";
import ServiceRow from "../ServiceRow";

export default function ServicesList({
    isLoading,
    isVoluminoso,
    destino,
    selectedOption,
    serviciosEnvioList,
    precioEnvioBaleares,
    onSelectServicio,
    onChangePrecioEnvio
}) {

    useEffect(() => {

    }, [isLoading, serviciosEnvioList]);

    return (
        <div className="w-100 table-responsive">
            <table className="table">
                <tbody>
                    { !isLoading ? 
                    <>
                    { serviciosEnvioList?.length > 0 ?
                        serviciosEnvioList?.map((option, i) => {
                        return (
                            <ServiceRow 
                                option={option}
                                selectedOption={selectedOption}
                                i={i}
                                id={'todos'}
                                isVoluminoso={isVoluminoso}
                                destino={destino}
                                precioEnvioBaleares={precioEnvioBaleares}
                                onChangePrecioEnvio={onChangePrecioEnvio}
                                onSelectServicio={onSelectServicio}
                            />
                        )})
                        : 
                        <tr>
                            <td colSpan="5" className="text-center">
                                <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="action" color="#ffc107" /> 
                                No hay servicios de envio para este destino.
                                <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="action" color="#ffc107" /> 
                            </td>
                        </tr>
                    }
                    </>
                        : 
                        <tr>
                            <td colSpan="5"><Loading /></td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
     )
}