import { getDetallesEntidades } from "../views/Dashboard/components/shared/services/comerciales";
import { asyncMac, makeAsyncTypes, makeFetchingReducer } from "./utils";

const initialComerciales = {
    detalle: []
};

export const comercialReducer = (state = initialComerciales, action) => {
    switch(action.type){
        case "comercial_detail/fullfilled": {            
            return action.payload;
        }
        default:
            return state
    }
}

const asyncComercialDetail = makeAsyncTypes('comercial_detail');

const [setPending, setFullFilled, setError ] = asyncMac(asyncComercialDetail);

export const comercialDetailFetchingReducer = makeFetchingReducer([
    'comercial_detail/pending', 
    'comercial_detail/fullfilled', 
    'comercial_detail/rejected'
]);

export const fetchDetallesEntidades = (page, search) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getDetallesEntidades(page, search)
        const data = await response.data;

        if(search?.includes('search') && !search?.includes('seccion')) {
            const activeSection = data?.secciones.reduce((prev, current) => (prev.total_items > current.total_items) ? prev : current)
        
            if(activeSection) {
                localStorage.setItem('comerciales-tab', activeSection.item);
            }
        }

        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const comercialDetailReducer = comercialReducer;
export const comercialDetailStatusReducer = comercialDetailFetchingReducer;