import API from "../../../../../services/api";
import { headers } from "../helpers/functionalities";

export const importDesguacesOffline = async (excel) => {
    return API.post('desguaces_offline/import/excel', excel, headers).then(res => res.data);
}

export const getDesguacesOffline = async (page = null, search = null) => {
    const pagination = page !== null ? '?page=' + page : '';
    const searcher = search !== null && page !== null ? '&' + search : search !== null ? '?' + search : '';
    return await API.get('/desguaces_offline'+ pagination + searcher).then(res => res.data);
}

export const createPresupuestoFromSolicitudOffline = async (solicitud) => {
    return API.post('desguaces_offline/new-presupuesto', solicitud).then(res => res.data);
}

export const sendEmailDesguaceOffline = async (offlineId, email) => {
    return API.post('desguaces_offline/' + offlineId + '/email', email).then(res => res.data);
}

export const downloadProformaDesguaceOffline = async (proforma) => {
    return API.post('desguaces_offline/download', proforma, {responseType: 'blob'}).then(res => res.data);
}

export const updateDesguaceOffline = async (desguace) => {
    return API.put('desguaces_offline/' + desguace.id, desguace).then(res => res.data);
}