import API from '../../../../../services/api';

export const getAvisosTipoPiezas = async () => {
    return await API.get('/avisos_tipo_piezas').then(res => res.data);
}

export const newAvisoTipoPieza = async (aviso) => {
    return API.post('/avisos_tipo_piezas', aviso).then(res => res.data);
}

export const updateAvisoTipoPieza = async (aviso) => {
    return API.put('/avisos_tipo_piezas/' + aviso.id, aviso).then(res => res.data);
}