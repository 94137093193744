import API from '../../../../../services/api';

export const newDireccion = async (direccion) => {
    return API.post('/direcciones', direccion).then(res => res.data);
}

export const updateDireccion = async (direccion) => {
    return API.put('/direcciones/' + direccion.id, direccion).then(res => res.data);
}

export const deleteDireccion = async (direccionId) => {
    return API.delete('/direcciones/' + direccionId).then(res => res.data);
}

export const getDireccionesCliente = async (clienteId) => {
    return API.get('/direcciones/listado/' + clienteId).then(res => res.data);
}