import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { Box } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import moment from 'moment';
import { faEuroSign, faRedo, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Filters({
    onChange,
}) {
    const recambistas = useSelector(state => {
        return state.recambistas
    });
    const fichaSearcher = useSelector(state => {
        return state.ficha_search
    });
    const [search, setSearch] = useState('');
    const [startDateValue, setStartDateValue] = useState(null);
    const [endDateValue, setEndDateValue] = useState(null);
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [responsable, setResponsable] = useState(-1);

    useEffect(() => {
        if(fichaSearcher) {
            if(fichaSearcher?.search) setSearch(fichaSearcher?.search);
            if(fichaSearcher?.start_date) setStartDateValue(fichaSearcher?.start_date);
            if(fichaSearcher?.end_date) setEndDateValue(fichaSearcher?.end_date);
            if(fichaSearcher?.responsable) setResponsable(fichaSearcher?.responsable);
            if(fichaSearcher?.min) setMin(fichaSearcher?.min);
            if(fichaSearcher?.max) setMax(fichaSearcher?.max);
            
        }
    }, [fichaSearcher]);

    const handleReset = () => {
        setSearch('');
        setStartDateValue(null);
        setEndDateValue(null);
        setMax(0);
        setMin(0);

        onChange(null, {
            search: '',
            start_date: null,
            end_date: null,
            responsable: null,
            min: null,
            max: null,
        });
    }

    const handleSearch = (newValue, type) => {
        const searchValue = (newValue && type === 'search') ? newValue : search;
        const resp = (newValue && type === 'resp') ? parseInt(newValue) : parseInt(responsable);
        const start = startDateValue ? moment(startDateValue).format('YYYY-MM-DD') : null;
        const end = (newValue && type === 'end') ? moment(newValue).format('YYYY-MM-DD') : endDateValue ? moment(endDateValue).format('YYYY-MM-DD') : null;
        
        const query = (start || end || (resp && resp !== -1) || min || max) ?
            'search=' + search
            + (start ? '&start_date=' + start : '')
            + (end ? '&end_date=' + end : '')
            + ((resp && resp !== -1) ? '&responsable=' + resp : '')
            + (min ? '&min=' + min : '')
            + (max ? '&max=' + max : '')
        : (searchValue && searchValue !== "" && searchValue.length > 0) ?
            'search=' + searchValue
        : null;

        var searchData = {
            search: searchValue,
            start_date: start,
            end_date: end,
            responsable: resp,
            min: min,
            max: max,
        }

        onChange(query, searchData);
    };

    const handleResponsable = (e) => { 
        const value = parseInt(e.target.value);
        setResponsable(value)
        handleSearch(value, 'resp');
    }

    const handleChangeMin = (event) => setMin(event.target.value);
    const handleChangeMax = (event) => setMax(event.target.value);

    const handleChange = (event) => { setSearch(event.target.value)}

    return (
        <div className="flex-grow-1 d-flex align-items-center justify-content-between">
            <div className="d-flex flex-grow-1 flex-column flex-md-row align-items-end justify-content-start">
                <div className="d-flex flex-wrap flex-md-nowrap align-items-center me-0 me-md-3">
                    <div className="form-group m-0">
                        <small>Importe (con IVA)</small>

                        <div className="d-flex align-items-center">
                            <div className="position-relative">
                                <input 
                                    type="number" 
                                    name="min" 
                                    step={0.1}
                                    className="form-control" 
                                    value={min} 
                                    onChange={handleChangeMin}
                                    placeholder="mínimo"
                                    onKeyPress={e => {
                                        if (e.key === 'Enter') {
                                            handleSearch(null);
                                    }}}  
                                    />
                                <FontAwesomeIcon icon={faEuroSign} color="#215732" className="icon" />
                            </div>

                            <div className="position-relative ms-3">
                                <input 
                                    type="number" 
                                    name="max" 
                                    step={0.1}
                                    className="form-control" 
                                    value={max} 
                                    placeholder="máximo"
                                    onChange={handleChangeMax}
                                    onKeyPress={e => {
                                        if (e.key === 'Enter') {
                                            handleSearch(null);
                                        }}}  
                                    />
                                <FontAwesomeIcon icon={faEuroSign} color="#215732" className="icon" />
                            </div>
                        </div>
                        
                    </div>
                </div>
                
                <div className="d-flex align-items-center me-0 me-md-3 mt-3 mt-md-0">
                    <div className="form-group m-0">
                        <small>Fecha</small>

                        <div className="d-flex align-items-center">
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                <DatePicker
                                    disableFuture
                                    label=""
                                    value={startDateValue}
                                    onChange={(newValue) => {
                                        setStartDateValue(newValue);
                                    }}
                                    mask={'__/__/____'}
                                    inputFormat="dd/MM/yyyy"
                                    renderInput={({ inputRef, inputProps, InputProps }) => (
                                        <Box className="date-picker me-3" sx={{ position: 'relative' }}>
                                            <input className="form-control" ref={inputRef} {...inputProps} />
                                            {InputProps?.endAdornment}
                                        </Box>
                                    )}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                <DatePicker
                                    disableFuture
                                    label=""
                                    value={endDateValue}
                                    onChange={(newValue) => {
                                        setEndDateValue(newValue);
                                        handleSearch(newValue, 'end');
                                    }}
                                    mask={'__/__/____'}
                                    inputFormat="dd/MM/yyyy"
                                    minDate={startDateValue}
                                    renderInput={({ inputRef, inputProps, InputProps }) => (
                                        <Box className="date-picker" sx={{ position: 'relative' }}>
                                            <input className="form-control" ref={inputRef} {...inputProps} />
                                            {InputProps?.endAdornment}
                                        </Box>
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center me-0 me-md-3 mt-3 mt-md-0">
                    <div className="form-group m-0">
                        <small>Responsable</small>
                        { (recambistas && recambistas.length > 0) &&
                            <select 
                                id="responsable"
                                value={responsable ? responsable : -1}
                                onChange={handleResponsable}>
                                <option key={'recambistas-' + 0} value={-1}>Todos</option>
                                {recambistas.map(op => <option key={'recambistas-' + op.id} value={op.id}>{op.username}</option>)}
                            </select>
                        }
                    </div>
                </div>

                <div className="position-relative ms-auto">
                    <input 
                        type="text" 
                        name="searcher" 
                        className="form-control" 
                        value={search} 
                        onChange={handleChange}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                handleSearch();
                            }}}  
                        />
                    <FontAwesomeIcon icon={faSearch} color="#215732" className="icon" style={{cursor: 'pointer'}} onClick={() => handleSearch(null)} />
                </div>
            </div>

            <div className="d-flex mt-3 mt-lg-0 align-items-center justify-content-end">
                <span className="d-block mt-3" title="Reset">
                    <FontAwesomeIcon icon={faRedo} color="#dc3545" className="icon ms-3" style={{cursor: 'pointer'}} onClick={() => handleReset()} />
                </span>
            </div>
        </div>
    );
}