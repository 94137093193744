import { getReconstruidos } from "../views/Dashboard/components/shared/services/reconstruidos";
import { asyncMac, makeAsyncTypes, makeFetchingReducer, makeSetReducer } from "./utils";


const asyncReconstruidos = makeAsyncTypes('reconstruidos');

const [setPending, setFullFilled, setError ] = asyncMac(asyncReconstruidos);

export const desguacesOfflineFetchingReducer = makeFetchingReducer([
    'reconstruidos/pending', 
    'reconstruidos/fullfilled', 
    'reconstruidos/rejected'
]);

const fullfilledReducer = makeSetReducer(['reconstruidos/fullfilled']);

export const fetchReconstruidos = (page, search) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getReconstruidos(page, search)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const reconstruidosReducer = fullfilledReducer;
export const reconstruidosStatusReducer = desguacesOfflineFetchingReducer;