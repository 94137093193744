import React, { useEffect, useState } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';

export default function AutocompleteDynamic({
  options, 
  id, 
  selectedValues, 
  onSetValue
}) {

    const [optionsList, setOptionsList] = useState([]);
    const [value, setValue] = useState(selectedValues);
    const handleChangeOptions = (value) => { 
        setValue(value); 
        onSetValue(value); 
    }

    useEffect(() => {
        if(selectedValues) {
            setValue(selectedValues);
        }
    }, [selectedValues]);

    useEffect(() => {
        if(options) {
            setOptionsList(options);
        }
    }, [options]);

    return (
        <div className="w-100">
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    handleChangeOptions(newValue);
                }}
                getOptionLabel={(option) =>  option}
                multiple
                id={id}
                options={optionsList}
                freeSolo
                renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                    />
                ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="filled"
                        placeholder="Buscar y/o añadir"
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                            setOptionsList([...optionsList, `${e.target.value}`])
                        }}}
                    />
                )}
            />
        </div>
    );
}