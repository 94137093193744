import { toast } from "react-toastify";
import { getAutorizacionesCredito } from "../views/Dashboard/components/shared/services/autorizacionesCredito";
import { asyncMac, makeAsyncTypes, makeFetchingReducer, makeSetReducer } from "./utils";
import { setErrorMessage } from "../views/Dashboard/components/shared/helpers/functionalities";

const asyncAlbaranes  = makeAsyncTypes('autorizaciones');

const [setPending, setFullFilled, setError ] = asyncMac(asyncAlbaranes);

export const autorizacionesFetchingReducer = makeFetchingReducer([
    'autorizaciones/pending', 
    'autorizaciones/fullfilled', 
    'autorizaciones/rejected'
]);

const fullfilledReducer = makeSetReducer(['autorizaciones/fullfilled']);

export const fetchAutorizacionesCredito = (page, search) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getAutorizacionesCredito(page, search)
        .catch(function (error) {
            toast.error(setErrorMessage(error.message))
        });
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const autorizacionesReducer = fullfilledReducer;
export const autorizacionesStatusReducer = autorizacionesFetchingReducer;