import React, { useEffect, useState } from 'react';
import MetricsSortTable from '../../shared/MetricsSortTable';

export default function InformacionGeneralProveedores({
    view,
    data,
    onFilterData
}) {
    const [culpa, setCulpa] = useState('todos');

    useEffect(() => {
        
    }, [data, view])
    

    const handleChangeCulpa = (e) => {
        setCulpa(e.target.value);
        onFilterData('ranking_incidencias', e.target.value);
    }

    return (
        <div className="w-100 d-flex flex-wrap align-items-stretch justify-content-between mt-5">
            <div className="w-100 d-flex flex-wrap align-items-stretch justify-content-between">
                <div className='col-12 col-md-6 col-lg-4 d-flex align-items-stretch pe-0 pe-md-3'>
                    { (data?.ranking_vendidas && data?.ranking_vendidas?.length > 0) ?
                        <MetricsSortTable 
                            titulo={"Ranking Proveedores - Piezas pedidas"}
                            labels={['nombre', 'total']}
                            data={data.ranking_vendidas}
                        />
                        : 
                        <div className='w-100 h-100 d-flex flex-column align-items-center py-5 py-md-0'>
                            <h1 className='text-center'>Ranking Proveedores - Piezas pedidas</h1>

                            <div className='w-100 d-flex flex-grow-1 align-items-center justify-content-center'>
                                <p className='text-center'>No hay datos.</p>
                            </div>
                        </div>
                    }
                </div>

                <div className='col-12 col-md-6 col-lg-4 d-flex align-items-stretch pe-0 pe-md-3'>
                    { (data?.ranking_score && data?.ranking_score?.length > 0) ?
                        <MetricsSortTable 
                            titulo={"Ranking Proveedores - Score"}
                            labels={['nombre', 'score']}
                            data={data.ranking_score}
                        />
                        : 
                        <div className='w-100 h-100 d-flex flex-column align-items-center py-5 py-md-0'>
                            <h1 className='text-center'>Ranking Proveedores - Score</h1>

                            <div className='w-100 d-flex flex-grow-1 align-items-center justify-content-center'>
                                <p className='text-center'>No hay datos.</p>
                            </div>
                        </div>
                    }
                </div>

                <div className='col-12 col-md-6 col-lg-4 d-flex align-items-stretch pe-0 pe-md-3'>
                    { (data?.ranking_vendidas && data?.ranking_vendidas.length > 0) ?
                        <MetricsSortTable 
                            titulo={"Ranking Proveedores - Incidencias"}
                            labels={['nombre', 'total']}
                            data={data.ranking_incidencias}
                            filter={true}
                            filterType={'culpa'}
                            filterValue={culpa}
                            onChangeFilter={handleChangeCulpa}
                        />
                        : 
                        <div className='w-100 h-100 d-flex flex-column align-items-center py-5 py-md-0'>
                            <h1 className='text-center'>Ranking Proveedores - Incidencias</h1>
                            
                            <div className='w-100 d-flex flex-grow-1 align-items-center justify-content-center'>
                                <p className='text-center'>No hay datos.</p>
                            </div>
                        </div>
                    }
                </div> 

                <div className='col-12 col-md-6 col-lg-4 d-flex align-items-stretch pe-0 pe-md-3 mt-5'>
                    { data?.ranking_piezas_incidencias_general && data?.ranking_piezas_incidencias_general?.length > 0 ?
                        <MetricsSortTable 
                            titulo={"Ranking Piezas Incidencias"}
                            labels={['tipo', 'incidencias', 'ventas', 'fallo']}
                            data={data.ranking_piezas_incidencias}
                        />
                        : 
                        <div className='w-100 h-100 d-flex flex-column align-items-center py-5 py-md-0'>
                            <h1 className='text-center'>Ranking Piezas Incidencias</h1>
                            
                            <div className='w-100 d-flex flex-grow-1 align-items-center justify-content-center'>
                                <p className='text-center'>No hay datos.</p>
                            </div>
                        </div>
                    }
                </div>

                <div className='col-12 col-md-6 col-lg-4 d-flex align-items-stretch pe-0 pe-md-3 mt-5'>
                    { (data?.ranking_avisos_proveedores && data?.ranking_avisos_proveedores?.length > 0) ?
                        <MetricsSortTable 
                            titulo={"Ranking Avisos"}
                            labels={['nombre', 'total']}
                            data={data.ranking_avisos_proveedores}
                            filter={false}
                            tooltip={true}
                            from={'dashboard-proveedores'}
                        />
                        : 
                        <div className='w-100 h-100 d-flex flex-column align-items-center py-5 py-md-0'>
                            <h1 className='text-center'>Ranking Avisos</h1>
                            
                            <div className='w-100 d-flex flex-grow-1 align-items-center justify-content-center'>
                                <p className='text-center'>No hay datos.</p>
                            </div>
                        </div>
                    }
                </div> 

                <div className='col-12 col-md-6 col-lg-4 d-flex align-items-stretch pe-0 pe-md-3 mt-5'>
                    { (data?.ranking_bloqueo_piezas && data?.ranking_bloqueo_piezas?.length > 0) ?
                        <MetricsSortTable 
                            titulo={"Ranking Piezas Bloqueadas"}
                            labels={['nombre', 'total']}
                            data={data.ranking_bloqueo_piezas}
                            filter={false}
                            tooltip={true}
                            from={'dashboard-proveedores'}
                        />
                        : 
                        <div className='w-100 h-100 d-flex flex-column align-items-center py-5 py-md-0'>
                            <h1 className='text-center'>Ranking Piezas Bloqueadas</h1>
                            
                            <div className='w-100 d-flex flex-grow-1 align-items-center justify-content-center'>
                                <p className='text-center'>No hay datos.</p>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}