import React, { useEffect, useState } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import InfoModal from "../../../../../shared/components/InfoModal";
import SolicitudProveedorForm from "../SolicitudProveedorForm";

export default function SolicitudesProveedorTable({
    incidencia,
    solicitudes,
    onNewSolicitud
}) {
    const [openForm, setOpenForm] = useState(false);

    useEffect(() => {
        
    }, [solicitudes]);

    const handleNewSolicitud = () => setOpenForm(true);
    const handleCloseNewSolicitud = () => setOpenForm(false);
    const handleSaveNewSolicitud = (data) => {
        onNewSolicitud(data);
        handleCloseNewSolicitud();
    }

    return (
        <div className="w-100">
            <div className="w-100 d-flex align-items-center justify-content-end mb-4">
                <button className="btn btn-add me-2"  onClick={() => handleNewSolicitud()}>
                    <span className="add">
                        <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" /> 
                    </span>
                    Nueva Solicitud 
                </button>
            </div>

            <div className="w-100 table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th className="text-center">tipo</th>
                            <th className="text-center">responsable</th>
                        </tr>
                    </thead>

                    <tbody>
                        { solicitudes?.length > 0 ?
                            solicitudes?.sort((a, b) => -a.fecha.localeCompare(b.fecha))
                            .map(solicitud => {
                                return (
                                    <tr key={'solicitud-prov-' + solicitud.id}>
                                        <td>{moment(solicitud.fecha).format('DD-MM-YYYY HH:mm:ss')}</td>
                                        <td className="text-center text-uppercase">{solicitud.tipo}</td>
                                        <td className="text-center text-uppercase">{solicitud.created_by.username}</td>
                                    </tr>
                                )
                            })
                            : 
                            <tr>
                                <td colSpan={3} className="text-center">No hay solicitudes</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>

            { (openForm) &&
                <InfoModal 
                    state={openForm}
                    title={"Nueva solicitud"}
                    content={
                        <SolicitudProveedorForm 
                            incidencia={incidencia}
                            onSubmit={handleSaveNewSolicitud}
                        />
                    }
                    width={'sm'}
                    onClose={handleCloseNewSolicitud}
                />
            }
        </div>
    );
}