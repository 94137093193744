import { useEffect, useState } from "react";
import React from "react";
import { Switch } from "@mui/material";

export default function PedidoPiezasForm({
    field,
    index,
    register,
    setValue
}) {
    const [RF, setRF] = useState(false);
    const [credito, setCredito] = useState(false);
    const [proforma, setProforma] = useState(false);

    useEffect(() => {
        if(field.tipo_pedido !== "") {
            if(field.tipo_pedido === 'RF') setRF(true);
            else if(field.tipo_pedido === 'credito') setCredito(true);
            else setProforma(true);
        }
    }, [field]);

    const handleChangeRF = (e, index) => {
        setRF(e.target.checked); 
        setCredito(false);
        setProforma(false);
        setValue(`piezas.${index}.tipo_pedido`, 'RF');
    }

    const handleChangeCredito = (e, index) => {
        setCredito(e.target.checked); 
        setRF(false);
        setProforma(false);
        setValue(`piezas.${index}.tipo_pedido`, 'credito');
    }

    const handleChangeProforma = (e, index) => {
        setProforma(e.target.checked); 
        setRF(false);
        setCredito(false);
        setValue(`piezas.${index}.tipo_pedido`, 'proforma');
    }
 
    return (
        <div className="w-100" key={field.id}>
            <div className="w-100 d-flex align-items-center justify-content-start">
                <div className="d-flex flex-row align-items-center">
                    <Switch 
                        color="success"
                        checked={RF}
                        onChange={(e) => handleChangeRF(e, index)}
                        inputProps={{ 'aria-label': 'controlled' }} /> 
                    <label>RecambioFacil</label>
                </div>

                <div className="d-flex flex-row align-items-center">
                    <Switch 
                        color="success"
                        checked={credito}
                        onChange={(e) => handleChangeCredito(e, index)}
                        inputProps={{ 'aria-label': 'controlled' }} /> 
                    <label>Crédito</label>
                </div>

                <div className="d-flex flex-row align-items-center">
                    <Switch 
                        color="success"
                        checked={proforma}
                        onChange={(e) => handleChangeProforma(e, index)}
                        inputProps={{ 'aria-label': 'controlled' }} /> 
                    <label>Proforma</label>
                </div>
            </div>

            <input
                type="hidden"
                {...register(`piezas.${index}.pieza_id`)} 
            />
            <input
                type="hidden"
                {...register(`piezas.${index}.tipo_pedido`)} 
            />

            { (RF || credito || proforma) &&
                <div className="form-group">
                    <input 
                        type="text"
                        {...register(`piezas.${index}.codigo_pedido`)}
                        placeholder="Número pedido RF o Nº Albaran"
                        defaultValue={""}
                        onChange={(e) => setValue(`piezas.${index}.codigo_pedido`, e.target.value)}
                    />
                </div>
            }

            { (proforma) &&
                <div className="form-group">
                    <textarea
                            {...register(`piezas.${index}.comentario`)}
                        defaultValue={""}
                        placeholder="Comentario para finanzas (opcional)"
                        onChange={(e) => setValue(`piezas.${index}.comentario`, e.target.value)}
                    ></textarea>
                </div>
            }
        </div>
    );
}