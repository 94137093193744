import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import MessageItem from './components/MessageItem';

export default function MessagesList({mensajes}) {
    const [searcher, setSearcher] = useState("");
    const [randomIndex, setRandomIndex] = useState(null);
    const [messages, setMessages] = useState(mensajes);

    useEffect(() => {
        setMessages(mensajes);

        if(mensajes?.length > 0) {
            const random = Math.floor(Math.random() * mensajes?.length);
            setRandomIndex(random);
        }
        
      }, []);

    const handleChange = (e) => setSearcher(e.target.value);

    const handleSearch = () => {
        if(searcher !== "") {
            const filtered = messages.filter(m => m.titulo.includes(searcher) || m.descripcion.includes(searcher));
            setMessages(filtered);
        } else setMessages(mensajes);
    }

    return (
        <div className='w-100 d-flex flex-column align-items-center dashboard'>
            <div className='w-100 summary'>
                <h1>Tablón de mensajes:</h1>

                <div className='form-group'>
                    <div className="position-relative">
                        <input 
                            type="text" 
                            name="searcher" 
                            className="form-control" 
                            value={searcher} 
                            onChange={handleChange}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    handleSearch(null);
                                }}}  
                            />
                        <FontAwesomeIcon icon={faSearch} color="#215732" className="icon" style={{cursor: 'pointer'}} onClick={() => handleSearch(null)} />
                    </div>
                </div>

                <div className='w-100 d-flex justify-content-stretch flex-wrap justify-content-center card-grid  mt-4'>
                   { searcher === "" &&
                        <div className='w-100 mb-3'>
                            <p>Último mensaje añadido: </p>
                        </div>
                    }
                    
                    { messages?.filter((msj, i) => i === randomIndex).map((msj, i) => {
                        return(
                            <MessageItem 
                                key={'mensaje-item' + msj.id}
                                message={msj}
                                i={i}
                            />
                        )
                     })}

                     { searcher === "" &&
                        <div className='w-100 my-2'>
                            <p>Otros mensajes:</p>
                        </div>
                     }
                    
                     { messages?.filter((msj, i) => i !== randomIndex).map((msj, i) => {
                        return(
                            <MessageItem
                                key={'mensaje-item' + msj.id}
                                message={msj}
                                i={i + 1}
                            />
                        )
                     })}
                </div>
            </div>
        </div>
    )
}
