import React from "react";

export default function ClienteExtraInfo({
    cliente,
    direccionEnvioId,
}) {

    return (
        <div className="w-100 d-flex align-items-start justify-content-between">
            <div className="d-flex flex-column mt-2 mt-md-0">
                    <label className="mb-1">Información de contacto</label>
                    <p>Teléfono: {cliente?.telefono}</p>
                    <p>Email: {cliente?.email ? cliente.email : '-'}</p>
                    { cliente?.email2 ? <p>Email Cc: {cliente?.email2 ? cliente.email2 : '-'}</p>  : ''}
            </div>

            <div className="d-flex flex-column mt-2 mt-md-0 ms-2">
                <label className="mb-1">Dirección de facturación</label>
                <p>{cliente?.direccion_facturacion ? cliente?.direccion_facturacion : '-'}</p>
                <p className="text-uppercase">
                    {(cliente.codigo_postal_facturacion + ' ' + cliente.poblacion_facturacion)},
                </p>
                <p className="text-uppercase">
                    {cliente.area_facturacion}, {cliente?.pais_facturacion_id ? cliente.pais_facturacion.nombre : '-'} 
                </p>
            </div>

            { (direccionEnvioId) ? 
                <div className="d-flex flex-column mt-2 mt-md-0">
                    <label className="mb-1">Dirección de envío:</label>
                    { cliente?.direcciones.map((direccion, i) => {
                        return direccion.id === direccionEnvioId &&  (
                            <div className="d-flex flex-column" key={'direccion-' + direccion.id}>
                                <p>{direccion.nombre} &nbsp;&nbsp; {direccion.telefono}</p>
                                <p>{direccion?.direccion ? direccion.direccion : '-'}</p>
                                <p className="text-uppercase m-0">
                                    {(direccion.codigo_postal + ' ' + direccion.poblacion)},
                                </p>
                                <p className="text-uppercase m-0">
                                    {direccion.area}, {direccion?.pais_id ? direccion.pais.nombre : '-'} 
                                </p>
                            </div>
                        )                       
                    })}
                </div>
                    : ''
            }
        </div>
    )
}