import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { CustomTooltip } from '../../../../../../shared/helpers/functionalities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

function descendingComparator(a, b, orderBy) {
  if(!isNaN(parseFloat(a[orderBy])) && !isNaN(parseFloat(b[orderBy]))) {
    const newA = parseFloat(a[orderBy]);
    const newB = parseFloat(b[orderBy]);

    if (newB < newA) {
      return -1;
    }
    if (newB > newA) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { labels, order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {labels.map((headCell) => (
          <TableCell
            key={headCell}
            align={headCell !== 'tipo' ? 'center' : 'left'}
            sortDirection={orderBy === headCell ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell}
              direction={orderBy === headCell ? order : 'asc'}
              onClick={createSortHandler(headCell)}
            >
              {headCell.toUpperCase()} &nbsp;
              {headCell === 'porcentaje' && '(%)'}
              {headCell === 'ticket' && '(€)'}
              {headCell === 'fallo' && '(%)'}
              {orderBy === headCell ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function MetricsSortTable({
    titulo,
    labels, 
    filter,
    filterType,
    filterValue,
    tooltip,
    from,
    data,
    onChangeFilter
}) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="w-100 d-flex flex-column align-items-center justify-content-between">
      <div className='w-100'>
        <h1 className='text-center'>{titulo}</h1>

        { (filter) &&
          <>
            { (filterType === 'culpa') &&
              <div className='w-100 my-2'>
                <select 
                    id="culpa"
                    value={filterValue}
                    onChange={onChangeFilter}>
                    <option value={'todos'}>Todos</option>
                    <option value={'cliente'}>Cliente</option>
                    <option value={'proveedor'}>Proveedor</option>
                    <option value={'recomotor'}>Recomotor</option>
                    <option value={'atencion-comercial'}>Atención comercial</option>
                    <option value={'transporte-propio'}>Transporte propio</option>
                    <option value={'transporte-proveedor'}>Transporte proveedor</option>
                </select>
              </div>
            }
          </>
        }
      </div>
        
      <div className='w-100 d-flex align-items-stretch justify-content-end mt-3'>
          <Box sx={{ width: '100%', height: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table
                  stickyHeader
                  aria-labelledby="tableTitle"
                >
                  <EnhancedTableHead
                      labels={labels}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={data.length}
                  />

                  <TableBody>
                    {stableSort(data, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {

                      return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={'sorted-dataset-tipo-' + index}
                          >
                              { labels?.map((label, i) => {
                                  return (
                                      <TableCell 
                                        align={label !== 'tipo' ? 'center' : "left"}
                                        key={'sorted-dataset-tipo-data-' + i}
                                      >
                                        { tooltip && from === 'dashboard-proveedores' && label === 'nombre' && (row?.ranking && row.ranking?.length > 0) ?
                                          <CustomTooltip
                                            title={
                                                <React.Fragment>
                                                  <table>
                                                    <tbody>
                                                      { row.ranking.map(r => {
                                                        return (
                                                          <tr key={'r-' + row.id + '-' + r.id}>
                                                            <td className='text-uppercase'>{r.nombre}</td>
                                                            <td>{r.total}</td>
                                                          </tr>
                                                        )
                                                      })}
                                                    </tbody>
                                                  </table>
                                                </React.Fragment>
                                            }
                                            >
                                                <span className="me-2" style={{cursor: 'pointer'}}>
                                                    <FontAwesomeIcon icon={faInfoCircle} size="lg" className="action" color="#62B1F6" /> 
                                                </span>
                                            </CustomTooltip>
                                          :
                                          null
                                        }
                                        {row[label]}
                                      </TableCell>
                                  )
                              })}
                          </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                  labelRowsPerPage={"Resultados por página"}
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
      </div>
    </div>
  );
}