import { getRecordatorios } from "../../views/Dashboard/components/shared/services/notificaciones";
import { asyncMac, makeAsyncTypes, makeFetchingReducer } from "../utils";

const initialState = [];

export const recordatoriosReducer = (state = initialState, action) => {
    switch(action.type){
        case "recordatorios/fullfilled": {
            return action.payload;
        }
        default:
            return state
    }
}

const asyncRecordatorios = makeAsyncTypes('recordatorios');

const [setPending, setFullFilled, setError ] = asyncMac(asyncRecordatorios);

export const recordatoriosFetchingReducer = makeFetchingReducer([
    'recordatorios/pending', 
    'recordatorios/fullfilled', 
    'recordatorios/rejected'
]);

export const fetchRecordatoriosClientes = () => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getRecordatorios()
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const recordatoriosClientesReducer = recordatoriosReducer;
export const recordatoriosClientesStatusReducer = recordatoriosFetchingReducer;