// LOAD ALL THE READ ONLY DATA
import { getMarcas } from "../views/Dashboard/components/shared/services/marcas";
import { makeFetchingReducer, makeAsyncTypes, asyncMac, makeSetReducer } from "./utils";

const asyncMarcas = makeAsyncTypes('marcas');

const [setPending, setFullFilled, setError ] = asyncMac(asyncMarcas);

export const marcasFullfilledReducer = makeSetReducer(['marcas/fullfilled']);

export const fetchingReducer = makeFetchingReducer([
    'marcas/pending', 
    'marcas/fullfilled', 
    'marcas/rejected'
]);

export const fetchMarcas = () => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getMarcas()
        const data = await response.data;
        dispatch(setFullFilled(data));
        const readOnly = JSON.parse(localStorage.getItem('read_only'));
        readOnly.marcas = data;
        localStorage.setItem('read_only', JSON.stringify(readOnly));
    } catch (e) {
        dispatch(setError(e.message))
    }
}