import { useEffect, useState } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import ConfirmModal from "../../../../../../../shared/components/ConfirmModal";

export default function ValeDescuentoForm({
    incidencia,
    valeDescuento,
    onSubmitValeDescuento,
    onDeleteValeDescuento
}) {
    const [isForDelete, setIsForDelete] = useState(false);
    const { register, handleSubmit } = useForm({
        defaultValues: {
            total: valeDescuento ? valeDescuento.total : 0,
        }
    });

    useEffect(() => {
    }, [incidencia]);

    const handleSubmitValeDescuento = async (data, e) => {
        e.preventDefault();

        if(valeDescuento === null) {
            data.incidencia_id = incidencia.id;
            data.entity_id = incidencia.cliente.id;
        } else {
            data.id = valeDescuento.id;
        }
       
        onSubmitValeDescuento(data);
    };

    const handleOpenDelete = () => setIsForDelete(true);
    const handleConfirmDelete = (confirm) => {
        if(confirm) {
            onDeleteValeDescuento();
        }

        setIsForDelete(false);
    }
 
    return (
        <div className="d-flex flex-column justify-content-center">
            <form className="form mb-4" onSubmit={handleSubmit(handleSubmitValeDescuento)}>
                <div className="w-100 d-flex align-items-center">

                    <div className="input-group align-items-center me-3">
                        <label className="me-3">Total: (MÁXIMO 300€)</label>
                        <div className="position-relative w-100">
                            <input 
                                className="form-control"
                                type="number" 
                                step="0.01"
                                max={300}
                                {...register("total", {required: true})} 
                                defaultValue={valeDescuento ? valeDescuento.total : 0} />
                            <span className="text-success icon">
                                <b>€</b>
                            </span>
                        </div>
                    </div>

                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                    { (valeDescuento) &&
                        <button 
                            type="button" 
                            className="btn btn-danger align-self-end ms-3" 
                            onClick={() => handleOpenDelete()}>Eliminar</button>
                    }
                </div>
            </form>


            { isForDelete && 
                <ConfirmModal 
                    onConfirmAction={handleConfirmDelete} 
                    title={'Eliminar'} 
                    description={'Estás seguro de eliminar?'}
                    state={isForDelete}>
                </ConfirmModal>
            }
        </div>
    );
}