import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  addCarroceriaPesada,
  editCarroceriaPesada,
} from "../../../../../shared/services/carroceriasPesadas";
import { useDispatch, useSelector } from "react-redux";

export default function CarroceriaPesadaForm({
  carroceriaPesada,
  onActionDone,
}) {
  /** Variables **/
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      id: carroceriaPesada?.id || null,
      familia_tipo_pieza_id:
        carroceriaPesada?.tipo_pieza?.familia_tipo_pieza_id || null,
      tipo_pieza_id: carroceriaPesada?.tipo_pieza?.id || null,
    },
  });

  /** Redux **/
  const dispatch = useDispatch();
  const familiaPiezas = useSelector((state) => {
    const { familia_piezas } = state;
    return familia_piezas;
  });
  const tipoPiezas = useSelector((state) => {
    const { tipo_piezas } = state;
    return tipo_piezas;
  });

  /** States **/
  const [currentFamiliaPieza, setCurrentFamiliaPieza] = useState(
    carroceriaPesada?.tipo_pieza?.familia_tipo_pieza_id || null
  );

  /** Lifecycle **/
  useEffect(() => {
    reset(carroceriaPesada);
  }, [carroceriaPesada]);

  /** Methods **/
  const handleChangeFamilia = (e) => {
    const value = parseInt(e.target.value);
    setCurrentFamiliaPieza(value);
    const firstTipoPiezaOfFamilia = tipoPiezas.find(
      (tp) => tp.familia_tipo_pieza_id === value
    );
    setValue("familia_tipo_pieza_id", value);
    setValue("tipo_pieza_id", firstTipoPiezaOfFamilia?.id || null);
  };

  const handleChangeTipoPieza = (e) => {
    const value = parseInt(e.target.value);
    setValue("tipo_pieza_id", value);
  };

  const handleSubmitCarroceriaPesada = async (data, e) => {
    e.preventDefault();
    dispatch({ type: "loading/set", payload: true });

    let response;

    if (carroceriaPesada) {
      const payload = {
        tipo_pieza_id: data.tipo_pieza_id,
        active: true,
      };
      response = await editCarroceriaPesada(data.id, payload);
    } else {
      const payload = {
        tipo_pieza_id: data.tipo_pieza_id,
      };
      response = await addCarroceriaPesada(payload);
    }

    if (response && response.success) {
      dispatch({ type: "loading/set", payload: false });
      toast.success(response.message);
      onActionDone();
    }
  };

  /** Render **/
  return (
    <form onSubmit={handleSubmit(handleSubmitCarroceriaPesada)}>
      <div className="d-flex flex-column gap-3">
        <input
          type="hidden"
          {...register("id")}
          defaultValue={carroceriaPesada?.id || ""}
        />

        <div>
          <label htmlFor="familia_tipo_pieza_id">Familia</label>
          {familiaPiezas && familiaPiezas.length > 0 && (
            <select
              {...register("familia_tipo_pieza_id")}
              defaultValue={
                carroceriaPesada?.tipo_pieza?.familia_tipo_pieza_id || ""
              }
              onChange={handleChangeFamilia}
            >
              {familiaPiezas
                .sort((a, b) => a.nombre.localeCompare(b.nombre))
                .map((op) => (
                  <option key={"familia-" + op.id} value={op.id}>
                    {op.nombre}
                  </option>
                ))}
            </select>
          )}
        </div>

        <div>
          <label htmlFor="tipo_pieza_id">Tipo Pieza</label>
          {tipoPiezas && tipoPiezas.length > 0 && (
            <select
              {...register("tipo_pieza_id")}
              defaultValue={carroceriaPesada?.tipo_pieza?.id || ""}
              disabled={!currentFamiliaPieza}
              onChange={handleChangeTipoPieza}
            >
              {tipoPiezas
                .filter(
                  (tp) => tp.familia_tipo_pieza_id === currentFamiliaPieza
                )
                .sort((a, b) => a.nombre.localeCompare(b.nombre))
                .map((tp) => (
                  <>
                    <option key={"tipos-" + tp.id} value={tp.id}>
                      {tp.nombre}
                    </option>
                  </>
                ))}
            </select>
          )}
        </div>

        <div className="d-flex justify-content-end">
          <button type="submit" className="btn btn-primary">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
}
