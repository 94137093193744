import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import VirtualizedAutocomplete from "../../../../../../../../../../../../../shared/components/Autocomplete";

export default function Filter({
    filters,
    tipoPiezasFiltro,
    onFilter,
}) {
    const { register, handleSubmit, control, setValue, reset } = useForm({
        mode: "onChange",
        defaultValues: {
            min: 0,
            max: 0,
            sort: 'DESC',
            tipo_pieza_id: null,
            tipo_pieza: null
        }
    });
    const [tipoPiezas, setTipoPiezas] = useState(tipoPiezasFiltro);
    const [sort, setSort] = useState('DESC');

    useEffect( () => {
        if(tipoPiezasFiltro) { setTipoPiezas(tipoPiezasFiltro); }

        if(filters) { reset(filters); }
    }, [filters, tipoPiezasFiltro]);

    const handleSubmitFilter = (data, e) => {
        e.preventDefault();
        data.tipo_pieza_id = data.tipo_pieza ? data.tipo_pieza?.nombre : null;
        data.sort = sort;
        onFilter(data);
    };

    const handleChangeTipoPieza = (tipo) => {
        if(tipo) { setValue('tipo_pieza', tipo); }
        else { setValue('tipo_pieza', null); }
    }

    return (
        <div className="w-100 mt-4 mt-md-0 mt-lg-0">
            <h1>Filtro</h1>
            <form className="form" onSubmit={handleSubmit(handleSubmitFilter)}>
                <div className="w-100 d-flex flex-wrap flex-md-nowrap align-items-end justify-content-between">
                    <div className="form-group m-0">
                        <label htmlFor="tipo_pieza_id">Tipo de pieza</label>
                        { <VirtualizedAutocomplete
                                id={"tipo_pieza"}
                                control={control}
                                options={tipoPiezas?.sort((a, b) => a.familia_tipo_pieza_id - b.familia_tipo_pieza_id)}
                                groupBy={null}
                                labels={['nombre']}
                                defaultValue={null} 
                                onChange={handleChangeTipoPieza}
                            />
                        }
                    </div>

                    <button type="submit" className="btn btn-primary ms-0 ms-md-3">FILTRAR</button>
                </div>
            </form>
        </div>
    );
}

/**
 * 
 * const handleChangeMin = (event) => {
        const value = event.target.value;

        if(value >= 0) {
            setValue('min', parseInt(value));
        } else setValue('min', value);
    }
    const handleChangeMax = (event) => {
        const value = event.target.value;

        if(value >= 0) {
            setValue('max', parseInt(value));
        } else setValue('max', value);
    }
    <div className="form-group m-0 ms-3">
        <label>Importe</label>

        <div className="d-flex align-items-center">
            <div className="position-relative">
                <input 
                    type="number"
                    step={0.1}
                    {...register("min")} 
                    className="form-control" 
                    onChange={handleChangeMin}
                    placeholder="mínimo" 
                />
                <FontAwesomeIcon icon={faEuroSign} color="#215732" className="icon" />
            </div>

            <div className="position-relative ms-3">
                <input 
                    type="number" 
                    {...register("max")} 
                    step={0.1}
                    className="form-control"
                    placeholder="máximo"
                    onChange={handleChangeMax}
                />
                <FontAwesomeIcon icon={faEuroSign} color="#215732" className="icon" />
            </div>
        </div>
    </div>

    <div className="form-group m-0 ms-3">
        <label>ORDENAR PRECIO</label>
        { sort === 'DESC' ?
            <span title="Ordenar Ascendente" style={{cursor:'pointer'}} onClick={() => setSort('ASC')}>
                <FontAwesomeIcon icon={faSortAmountAsc} color="#215732" className="icon" />
                <small className="ms-1">Ascendente</small>
            </span>
            :
            <span title="Ordenar Descendente" style={{cursor:'pointer'}} onClick={() => setSort('DESC')}>
                <FontAwesomeIcon icon={faSortAmountDesc} color="#215732" className="icon" />
                <small className="ms-1">Descendente</small>
            </span>
        }
    </div>
 */