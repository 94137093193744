import React, { useEffect } from "react";
import moment from "moment";

export default function ExistentPresupuestosList({
    presupuestos
}) {

    useEffect(() => {

    }, []);

    return (
        <div className="w-100 d-flex flex-column mt-4 mt-md-0 mt-lg-0">
            <div className="w-100 table-responsive">
                <table className="table">
                <thead>
                    <tr>
                        <th>Nº</th>
                        <th className="text-center">fecha</th>
                        <th><small>Pieza</small></th>
                        <th className="text-center"><small>compra</small></th>
                        <th className="text-center"><small>venta</small></th>
                        <th className="text-center"><small>casco</small></th>
                    </tr>
                </thead>
                    { presupuestos?.map((presupuesto, index) => {
                        const piezasValues = Object.values(presupuesto.piezas)
                        const piezasRows = piezasValues.map((pieza, i) => {
                        const numero = i === 0 ? <td rowSpan={piezasValues.length + 1} width="12%">
                            {presupuesto?.numero}
                        </td> : null;

                        const fecha = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%" className="text-center">
                            <div className="w-100 d-flex flex-column">
                                <p>{moment(presupuesto.fecha).format("DD-MM-YYYY")}</p>
                            </div>
                        </td> : null
                            
                        return (
                            <tr key={i}>
                                {numero}
                                {fecha}
                                <td className="border-light">
                                    <div className="w-100">
                                        <small className="w-100 d-flex flex-column align-items-start">
                                        <span>{pieza.nombre}</span>
                                        { pieza?.contiene_casco > 0 ?
                                            <span className="fw-bold text-uppercase highlight"><small>contiene casco</small></span>
                                            : ''
                                        }
                                        </small>
                                    </div>
                                </td>
                                <td className="text-center border-light">{parseFloat(pieza.precio_compra).toFixed(2)}€</td>
                                <td className="text-center border-light">{parseFloat(pieza.precio_venta).toFixed(2)}€</td>
                                <td className="text-center">{pieza.casco > 0 ? parseFloat(pieza.casco).toFixed(2) + '€' : '-'}</td>
                            </tr>
                        )
                        })
                        return (
                        <tbody className="border-light" key={index}>
                            {piezasRows}
                        </tbody>
                        )
                    }) 
                    }
                </table>
            </div>
        </div>
    );
}