import ImagesSlider from "../ImagesSlider";
import React from "react";
import './part_details.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";

export default function PartDetails ({
    part,
    onSelectedPart,
    onGoBack
}) {
    const formatPrice = (price) => {
        if (price === undefined) {
            return '-';
        }

        if (typeof price === 'number') {
            price = price.toString();
        }

        price = price.replace(',', '.');
        price = parseFloat(price).toFixed(2);

        return price === 'NaN' ? '-' : price;
    }

    const getValueIfExists = (value) => value ? value : '-';
    const getIntIfExists = (value) => value || 0;
    const handleSelectedPart = (part) => onSelectedPart(part);

    return (
        <div className={'part-details my-3 d-flex row justify-content-center'}>
            <div className={'col-5 rounded border me-5'}>
                <div className={'part-details__first-column'}>
                    <div className={'part-details__images'}>
                        { part.images?.length > 0
                            ? <ImagesSlider images={part.images} />
                            : <p>No hay imágenes disponibles</p>
                        }
                    </div>
                    <div className={'my-5 me-2'}>
                        <p className={'mb-1'}>Proveedor <strong>{ getValueIfExists(part.vendedor)}</strong></p>
                        <p className={'mb-1'}>Procedencia <strong>{ getValueIfExists(part.marketPlace)}</strong></p>
                        { part.isFromIntegration
                            ? null
                            // eslint-disable-next-line
                            : <p><a href={part.url} target="_blank">Enlace a la pieza</a></p>
                        }
                    </div>
                </div>
            </div>
            <div className={'col-5 rounded grey-part-card'}>
                <div className={'mx-2 mt-4 mb-2'}>
                    { part.presupuestos_integraciones?.length > 0 ?
                        <small className="text-uppercase fw-bold mb-2">
                            <FontAwesomeIcon icon={faExclamationTriangle} size="1x" color="#dc3545"></FontAwesomeIcon>
                            &nbsp; Pieza añadida en otro presupuesto &nbsp;
                            <FontAwesomeIcon icon={faExclamationTriangle} size="1x" color="#dc3545"></FontAwesomeIcon>
                        </small>
                        : ''
                    }
                    <p className={'text-success fw-bolder mb-1 fs-6'}>{ getValueIfExists(part.pieza) }</p>
                    <p>{ getValueIfExists(part['Versión'])}</p>
                    <p className='mt-3 mb-4 text-success'>
                        <span className={'bg-white rounded px-4 py-2 me-2'}>
                            Tipo: <b>{ getValueIfExists(part.type) }</b>
                        </span>
                        <span className={'bg-white rounded px-4 py-2'}>
                            ID: <b>{ getValueIfExists(part.ref_vendedor) }</b>
                        </span>
                    </p>
                    <div className={'d-flex mb-4'}>
                        <div className={'me-5'}>
                            <p>SKU</p>
                            <p><strong>{ getValueIfExists(part.SKU)}</strong></p>
                        </div>
                        <div>
                            <p>Ref. Vendedor</p>
                            <p><strong>{ getValueIfExists(part.ref_vendedor)}</strong></p>
                        </div>
                    </div>
                    <p className={'mb-1'}>Motor <strong>{ getValueIfExists(part.Motor) }</strong></p>
                    <p className={'mb-1'}>Combustible <strong>{ getValueIfExists(part.Combustible) }</strong></p>
                    <p className={'mb-1'}>Año <strong>{ getValueIfExists(part['Año']) }</strong></p>
                    <p className={'mb-1'}>Km <strong>{getIntIfExists(part.Km) }</strong></p>
                    <p className={'mb-1'}>Puertas <strong>{ getValueIfExists(part.puertas) }</strong></p>
                    <p className={'mb-1'}>Color <strong>{ getValueIfExists(part.color) }</strong></p>
                    <p className={'mb-1'}>Categoría <strong>{ getValueIfExists(part.type) }</strong></p>
                    <p className={'mb-1'}>Tipo motor <strong>{ getValueIfExists(part['Tipo Motor']) }</strong></p>
                    <p className={'mb-1'}>Notas <strong>{ getValueIfExists(part.observaciones) }</strong></p>
                    <div className={'d-flex justify-content-between'}>
                        <p className={'mb-1'}>Tipo cambio <strong>{ getValueIfExists(part['Tipo Cambio']) }</strong></p>
                        <p className={'text-success fw-bolder fs-5'}>
                            { parseInt(part.precio) === 0 ? 'A consultar' : formatPrice(part.precio) + ' €'}
                        </p>
                    </div>
                    { part.isFromIntegration && part?.descuento ?
                        (<b className="text-green text-uppercase">descuento {part.descuento}%</b>)
                        : <b className="text-danger text-uppercase">no hay descuento</b>
                    }
                    <div className={'d-flex justify-content-between mt-2'}>
                        <button
                            type={"button"}
                            className={"btn btn-sm btn-primary"}
                            onClick={() => onGoBack()}
                        >
                            Atrás
                        </button>
                        <button
                            type={"button"}
                            className={"btn btn-primary btn-sm"}
                            onClick={() => handleSelectedPart(part)}
                        >
                            Seleccionar
                        </button>
                    </div>
                </div>
            </div>
    </div>
    );
};