import { useState } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faEye, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import ValoresTable from "./components/ValoresTable";
import ValoresForm from "./components/ValoresForm";
import TarifasForm from "./components/TarifasForm";
import { deleteTarifa } from "../../../shared/services/tarifas";
import { toast } from "react-toastify";
import { deleteTarifaValor } from "../../../shared/services/tarifaValores";
import ConfirmModal from "../../../shared/components/ConfirmModal";
import InfoModal from "../../../shared/components/InfoModal";

export default function Tarifas() {
    const dispatch = useDispatch();
    const [tarifaId, setTarifaId] = useState(null);
    const [currentTarifa, setCurrentTarifa] = useState(null);
    const [currentValor, setCurrentValor] = useState(null);
    const [isEditTarifa, setIsEditTarifa] = useState(false);
    const [isEditValor, setIsEditValor] = useState(false);
    const [isForDelete, setIsForDelete] = useState(false);
    const [openTarifaForm, setOpenTarifaForm] = useState(false);
    const [openValoresList, setOpenValoresList] = useState(false);
    const [openValoresForm, setOpenValoresForm] = useState(false);

    const tarifas = useSelector(state => {
        return state.tarifas
    });
    const tipoClientes = useSelector(state => {
        const { tipo_clientes } = state;
        return tipo_clientes;
    });

    const handleOpenTarifaValores = (tarifa) => {
        setTarifaId(tarifa.id);
        setOpenValoresList(true);
    }
    const handleCloseTarifaValores = () => {
        setTarifaId(null);
        setOpenValoresList(false);
    }

    const handleOpenForm = (element, elementValue = null) => {
        if(element === 'tarifa') {
            setCurrentTarifa(elementValue);

            if(elementValue) {
                setIsEditTarifa(true);
            }
            
            setOpenTarifaForm(true);
        } else {
            const valor = tarifas.find(t => t.id === tarifaId).valores.find(v => v.id === elementValue);
            setCurrentValor(valor);

            if(elementValue) {
                setIsEditValor(true);
            }
            
            setOpenValoresForm(true);
        }
    }

    const handleCloseForm = () => { 
        setOpenTarifaForm(false);
        setOpenValoresForm(false);
        setCurrentTarifa(null);
        setCurrentValor(null);
        setIsEditTarifa(false);
        setIsEditValor(false);
    }

    const handleActionDone = () => {
        handleCloseForm();
    }

    const handleDeleteElement = async(element, elementId) => {
        let response;
        dispatch({type: 'loading/set', payload: true});

        if(element === 'tarifa') {
            response = await deleteTarifa(elementId);
            setTarifaId(null);
            setCurrentTarifa(null);
        } else {
            response = await deleteTarifaValor(elementId);
            setCurrentValor(null);
        }

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
        }
    }

    const handleDeleteTarifa = (id) => { 
        setTarifaId(id);
        setIsForDelete(true); 
    }

    const handleConfirmDelete = (confirm) => {
        if(confirm) {
            handleDeleteElement('tarifa', tarifaId)
        } else setIsForDelete(false);
    }

    return (
        <div className="w-100 d-flex justify-content-center">
            <div className="col-12 col-md-6 d-flex flex-column align-items-end">
                <button className="btn btn-add ms-0 ms-md-5" onClick={() => handleOpenForm('tarifa')}>
                    <span className="add">
                        <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                    </span>
                    Nueva
                </button>

                <div className="w-100 d-flex flex-column mt-5">
                    { tarifas.map(tarifa => {
                        return (
                            <div className="w-100 d-flex flex-column" key={'tarifa-' + tarifa.id}>
                                <div className="d-flex align-items-center justify-content-between">
                                    <p className="m-0 text-uppercase">{tarifaId === tarifa.id ? <b>{tarifa.nombre}</b> : tarifa.nombre} - {tipoClientes.find(f => f.id === tarifa.tipo_cliente_id)?.nombre}</p>
                                    <div>
                                        {tarifa.default ? <span className="default me-3">POR DEFECTO</span> : ''}
                                        {tarifa.categoria !== null ? <span className="default me-3">{tarifa.categoria}</span> : ''}
                                        <span title="Ver valores" style={{cursor: 'pointer'}} className="me-3">
                                            <FontAwesomeIcon icon={faEye} size="1x" className="action" color="#215732" onClick={() => handleOpenTarifaValores(tarifa)} />
                                        </span>
                                        <span title="Editar" style={{cursor: 'pointer'}} className="me-3">
                                            <FontAwesomeIcon icon={faPen} size="1x" className="action" color="#215732" onClick={() => handleOpenForm('tarifa', tarifa)} />
                                        </span>
                                        <span title="Eliminar" style={{cursor: 'pointer'}}>
                                            <FontAwesomeIcon icon={faTrash} size="1x" className="action" color="#dc3545" onClick={() => handleDeleteTarifa(tarifa.id)} />
                                        </span>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        )
                    }) }
                </div>
            </div>

            { (openTarifaForm) && 
                <InfoModal
                    state={openTarifaForm}
                    title={(isEditTarifa ? 'Editar' : 'Nueva') + ' tarifa'}
                    content={
                        <TarifasForm 
                            tarifa={currentTarifa}
                            isEdit={isEditTarifa}
                            onActionDone={handleActionDone}
                        />
                    }
                    width={'sm'}
                    onClose={handleCloseForm}
                ></InfoModal>
            }

            { (openValoresList) && 
                <InfoModal
                    state={openValoresList}
                    title={"Valores de " + tarifas.find(t => t.id === tarifaId)?.nombre}
                    content={
                        <div className="col-12 d-flex flex-column align-items-end mt-3 mt-md-0">
                            <button type="button" className="btn btn-add" onClick={() => handleOpenForm('valor')}>
                                <span className="add">
                                    <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                                </span>
                                Nuevo
                            </button>
                            
                            <div className="w-100 d-flex flex-column mt-4">
                                <ValoresTable 
                                    valores={tarifas.find(t => t.id === tarifaId)?.valores}
                                    onEdit={handleOpenForm}
                                    onDelete={handleDeleteElement}
                                />
                            </div>
                        </div>
                    }
                    width={'md'}
                    onClose={handleCloseTarifaValores}
                ></InfoModal>
            }

            { (openValoresForm) && 
                <InfoModal
                    state={openValoresForm}
                    title={(isEditValor ? 'Editar' : 'Nuevo') + ' valor'}
                    content={
                        <ValoresForm 
                            valor={currentValor} 
                            tarifaId={tarifaId}
                            isEdit={isEditValor}
                            onActionDone={handleActionDone}
                        />
                    }
                    width={'sm'}
                    onClose={handleCloseForm}
                ></InfoModal>
            }

            { isForDelete && 
                <ConfirmModal 
                    onConfirmAction={handleConfirmDelete} 
                    title={'Eliminar'} 
                    description={'Estás seguro de eliminar?'}
                    state={isForDelete}>
                </ConfirmModal>
            }
        </div>
    )
}