import { useState, useEffect } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faUser, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { CustomTooltip } from "../../../../../../../shared/helpers/functionalities";
import { useSelector } from "react-redux";
import InfoModal from "../../../../../../../shared/components/InfoModal";
import ClienteExtraInfo from "./components/ClienteExtraInfo";
import ClienteMetrics from "./components/ClienteMetrics";

export default function ClienteInfo({
    cliente,
    direccionEnvioId,
    hasWarning
}) {
    const [openInfo, setOpenInfo] = useState(false);

    const tipoClientes = useSelector(state => {
        const { tipo_clientes } = state;
        return tipo_clientes;
    });
    const tipoClientesB2b = useSelector(state => {
        const { tipo_clientes_b2b } = state;
        return tipo_clientes_b2b;
    });
    const tarifas = useSelector(state => {
        const { tarifas } = state;
        return tarifas;
    });
    const tipoVencimientos = useSelector(state => {
        const { tipo_vencimientos } = state;
        return tipo_vencimientos;
    });

    useEffect(() => {
    }, [hasWarning]);

    const getTipoClienteName = (tipoId) => tipoClientes.find(f => f.id === tipoId)?.nombre;
    const getTipoClienteB2bName = (tipoId) => tipoClientesB2b.find(f => f.id === tipoId)?.nombre
    
    const handleOpenInfo = () => { setOpenInfo(true); }
    const handleCloseInfo = () => { setOpenInfo(false); }

    return (
        <div className="w-100 d-flex flex-wrap flex-lg-nowrap align-items-stretch justify-content-between">
            <div className="flex-grow-1 d-flex align-items-start">
                <div className="d-flex flex-column me-3">
                    <span className="icon">
                        <FontAwesomeIcon icon={faUser} size="1x" color="#215732" />
                    </span>
                </div>
            

                <div className="d-flex flex-column me-3">
                    <div className="d-flex flex-wrap align-items-center mb-1">
                        <h3 className="text-success"> {cliente?.nombre}</h3>

                        <div className="d-flex align-items-center ms-2">
                            { (hasWarning) ?
                                <CustomTooltip
                                    title={
                                        <React.Fragment>
                                            <small className="m-0">Atención! Cliente de Baleares, Canarias, Ceuta, Melilla o fuera de España.</small>
                                        </React.Fragment>
                                    }
                                >
                                    <span className="align-self-start me-2" style={{cursor: 'pointer'}}>
                                        <FontAwesomeIcon icon={faExclamationCircle} size="lg" className="action" color="#ffc107" /> 
                                    </span>
                                </CustomTooltip>
                                : ''
                            }

                            { (cliente.credito) ?
                                <CustomTooltip
                                    title={
                                        <React.Fragment>
                                            <small className="m-0">Atención! Cliente a crédito.</small>
                                        </React.Fragment>
                                    }
                                >
                                    <span className="align-self-start" style={{cursor: 'pointer'}}>
                                        <FontAwesomeIcon icon={faExclamationCircle} size="lg" className="action" color="#dc3545" /> 
                                    </span>
                                </CustomTooltip>
                                : '' 
                            }
                        </div>
                    </div>
                    <p>{cliente?.cif ? cliente.cif : '-'}</p>
                    <p className="text-uppercase">
                        { getTipoClienteName(cliente.tipo_cliente_id) }
                        { (cliente.b2b) && 
                            <span className="text-uppercase">
                                <span className="mx-2">|</span>
                                { getTipoClienteB2bName(cliente.tipo_cliente_b2b_id) }
                            </span>
                        }
                    </p>
                    <p className="text-uppercase">{tarifas.find(f => f.id === cliente.tarifa_cliente_id)?.nombre}</p>
                    { cliente.credito ?
                        <p className="fw-bold text-uppercase">
                            <span className="text-success">A crédito</span>
                            <span className="ms-2">{tipoVencimientos.find(f => f.id === cliente.tipo_vencimiento_id)?.duracion}</span>
                        </p>
                        : ''
                    }
                </div>

                { (direccionEnvioId) ? 
                    <div className="d-flex flex-column mt-2 mt-md-0">
                        <label className="mb-1">Dirección de envío:</label>
                        { cliente?.direcciones.map((direccion, i) => {
                            return direccion.id === direccionEnvioId &&  (
                                <div className="d-flex flex-column" key={'direccion-' + direccion.id}>
                                    <p>{direccion.nombre} &nbsp;&nbsp; {direccion.telefono}</p>
                                    <p>{direccion?.direccion ? direccion.direccion : '-'}</p>
                                    <p className="text-uppercase">
                                        {(direccion.codigo_postal + ' ' + direccion.poblacion + ", " + direccion.area)}, &nbsp;
                                        {direccion?.pais_id ? direccion.pais.nombre : '-'} 
                                    </p>
                                </div>
                            )                       
                        })}
                    </div>
                    : ''
                }

                <ClienteExtraInfo 
                    cliente={cliente}
                    direccionEnvioId={direccionEnvioId}
                />
            </div>


            <div className="d-flex flex-column">
                <span className="align-self-start ms-2" style={{cursor: 'pointer'}} onClick={() => handleOpenInfo()}>
                    <FontAwesomeIcon icon={faUserAlt} size="lg" className="action" color="#215732" /> 
                </span>
            </div>

            { (openInfo) && 
                <InfoModal
                    state={openInfo}
                    title={'Información de Cliente'}
                    content={
                        <ClienteMetrics 
                            cliente={cliente}
                        />
                    }
                    width={'md'}
                    onClose={handleCloseInfo}
                ></InfoModal>
            }
        </div>
    )
}