import { useEffect, useState } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckDouble, faEnvelope, faFileExcel, faFilePdf, faTimes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { toast } from "react-toastify";
import { generateFacturaPDF, reclamarFacturaCredito, updateFactura } from '../../../shared/services/facturas';
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../../../shared/components/ConfirmModal";
import { exportFacturasCreditoCliente } from "../../../shared/services/clientes";

export default function ClienteCreditoFacturas({
    cliente,
    onMarkAsPaid
}) { 
    const dispatch = useDispatch();
    const tipoVencimientos = useSelector(state => {
        const { tipo_vencimientos } = state;
        return tipo_vencimientos;
    });

    const [isSendEmail, setIsSendEmail] = useState(false);
    const [currentFactura, setCurrentFactura] = useState(null);

    useEffect(() => {
    }, [cliente]);

    const generateThePdf = async (factura) => {
        dispatch({type: 'loading/set', payload: true});
        const pdfResult = await generateFacturaPDF(factura.id);
  
        if(pdfResult) {
            dispatch({type: 'loading/set', payload: false});
            toast.success('PDF generado correctamente!')
            const url = window.URL.createObjectURL(new Blob([pdfResult]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', factura.numero + '.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
        } else {
          toast.error('Ups! Algo ha ido mal...')
        }
    }

    const handleMarkAsPaid = async(factura, cobrada) => {
        dispatch({type: 'loading/set', payload: true});
        const response = await updateFactura({id: factura.id, cobrada: cobrada});

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            onMarkAsPaid({id: factura.id, cobrada: cobrada});
        }
    }

    const getFechaVencimiento = (factura) => {
        const fecha = moment(factura.fecha);
        const vencimiento = tipoVencimientos?.find(tv => tv.id === cliente.tipo_vencimiento_id)?.duracion;
        const dias = vencimiento.substring(0, 2);
        const fechaVencimiento = fecha.add(dias, 'days');
        return fechaVencimiento.format('DD-MM-YYYY');
    }

    const handleSendEmail = (factura) => { setIsSendEmail(true); setCurrentFactura(factura); }

    const handleConfirmSend = async(confirm) => {
        if(confirm) {
            dispatch({type: 'loading/set', payload: true});
            const response = await reclamarFacturaCredito(currentFactura.id);

            if(response && response.success) {
                dispatch({type: 'loading/set', payload: false});
                toast.success(response.message);
            }
        }

        setIsSendEmail(false);
    }

    const handleExport = async() => {
        dispatch({type: 'loading/set', payload: true});
        const pdfResult = await exportFacturasCreditoCliente(cliente.id);
  
        if(pdfResult) {
            dispatch({type: 'loading/set', payload: false});
            toast.success('Excel generado correctamente!')
            const url = window.URL.createObjectURL(new Blob([pdfResult]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'facturas_credito.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        } else {
          toast.error('Ups! Algo ha ido mal...')
        }
    }

    return (
        <div className="w-100 d-flex flex-column align-items-end">
            <div className="w-100 d-flex align-items-center justify-content-end my-4">
                <button className="btn btn-add"  onClick={() => handleExport()}>
                    <span className="add">
                        <FontAwesomeIcon icon={faFileExcel} size="1x" className="action" color="#215732" /> 
                    </span>
                    Exportar
                </button>
            </div>

            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th className="text-center">fecha</th>
                            <th className="text-center">fecha vencimiento</th>
                            <th className="text-center">albaranes</th>
                            <th className="text-center">total</th>
                            <th className="text-center">total envío</th>
                            <th className="text-center">total casco</th>
                            <th className="text-center">I.V.A</th>
                            <th className="text-center">total I.V.A</th>
                            <th className="text-center">cobrada</th>
                            <th className="text-center">acciones</th>
                        </tr>
                    </thead>

                    <tbody>
                    { cliente.facturas_credito?.map((factura) => {
                        return (
                            <tr key={factura.id}>
                                <td>{factura.numero}</td>
                                <td className="text-center">{moment(factura.fecha).format("DD-MM-YYYY")}</td>
                                <td className="text-center">{getFechaVencimiento(factura)}</td>
                                <td width="25%" className="text-center">
                                    {factura.ventas}, {factura.devoluciones}
                                </td>
                                <td className="text-center">{parseFloat(factura.total).toFixed(2)}€</td>
                                <td className="text-center">{parseFloat(factura.total_envio).toFixed(2)}€</td>
                                <td className="text-center">{parseFloat(factura.total_casco).toFixed(2)}€</td>
                                <td className="text-center">{factura.iva}%</td>
                                <td className="text-center">{parseFloat(factura.total_iva).toFixed(2)}€</td>
                                <td className="text-center">
                                    <div className="d-flex flex-column align-items-center justify-content-center">
                                        { (factura.cobrada) ?
                                            <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#00986C" />
                                            : 
                                            <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                                        }
                                        <small>
                                            {factura.cobrada ? moment(factura.fecha_cobrada).format("DD-MM-YYYY") : ''}
                                        </small>
                                    </div>
                                </td>
                                <td width="10%" className="text-center">
                                    <span title="Descargar PDF" style={{cursor: 'pointer'}}>
                                        <FontAwesomeIcon icon={faFilePdf} size="1x" className="action" color="#215732" onClick={() => generateThePdf(factura)}></FontAwesomeIcon>
                                    </span>
                                    { (!factura.cobrada) ?
                                        <>
                                            <span title="Marcar como cobrada" style={{cursor: 'pointer'}}>
                                                <FontAwesomeIcon icon={faCheckDouble} size="1x" className="action" color="#215732" onClick={() => handleMarkAsPaid(factura, true)}></FontAwesomeIcon>
                                            </span>
                                            <span title="Enviar email" className="ms-2" style={{cursor: 'pointer'}}>
                                                <FontAwesomeIcon icon={faEnvelope} size="1x" className="action" color="#215732" onClick={() => handleSendEmail(factura)}></FontAwesomeIcon>
                                            </span>
                                        </>
                                        
                                        : 
                                        <span title="Marcar como cobrada" style={{cursor: 'pointer'}}>
                                            <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" onClick={() => handleMarkAsPaid(factura, false)}></FontAwesomeIcon>
                                        </span>
                                    }
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>

            { isSendEmail && 
                <ConfirmModal 
                    onConfirmAction={handleConfirmSend} 
                    title={'Enviar'} 
                    description={'Estás seguro de enviar el email reclamando la factura ' + currentFactura.numero + '?'}
                    state={isSendEmail}>
                </ConfirmModal>
            }
        </div>
    )
}