import { useEffect, useRef } from 'react'
import './SkeletonChart.scss'
const SkeletonChart = ({sizeContainerChart}) => {
  const containerSkeleton = useRef(null)
  useEffect(() => {
    containerSkeleton.current.style.width = sizeContainerChart.width + 'px';
    containerSkeleton.current.style.height = sizeContainerChart.height + 'px';
  }, [sizeContainerChart])
  
  return (
    <div className='skeleton-chart' ref={containerSkeleton}>
      <div className='skeleton-chart__card'>
        <p className='skeleton-chart__card--title'></p>
        <div className='skeleton-chart__card--container-users'>
          <p className='skeleton-chart__card--container-users--each'></p>
          <p className='skeleton-chart__card--container-users--each'></p>
          <p className='skeleton-chart__card--container-users--each'></p>
          <p className='skeleton-chart__card--container-users--each'></p>
          <p className='skeleton-chart__card--container-users--each'></p>
        </div>
        <div className='skeleton-chart__card--chart'>
            <span className='skeleton-chart__card--chart--hole'></span>
        </div>
      </div>
    </div>
  )
}

export default SkeletonChart
