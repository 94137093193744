import { useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";

export default function SeguimientoForm({
    envioId,
    envio,
    onSubmitSeguimiento,
}) {
    const { 
        register, 
        formState: { errors }, 
        handleSubmit,  
        reset 
    } = useForm({
            defaultValues: {
                id: envioId,
                url_seguimiento: '',
                numero_seguimiento: '',
            }
    });

    useEffect(() => {
        if(envio && envio.numero_seguimiento !== '-') {
            reset({
                id: envioId,
                url_seguimiento: envio.url_seguimiento,
                numero_seguimiento: envio.numero_seguimiento
            });
        }
    }, [envio]);

    const onSubmit = async (data, e) => {
        e.preventDefault();
        onSubmitSeguimiento(data);
    };

    return (
        <div className="w-100">
            <div className="w-100 d-flex flex-wrap flex-md-nowrap">
                <form className="form col-12 mb-4" onSubmit={handleSubmit(onSubmit)}>
                    <input 
                        type="hidden" 
                        {...register("id")} 
                        defaultValue={envioId} />

                    <div className="w-100">
                        <div className="form-group">
                            <label htmlFor="url_seguimiento">URL de seguimiento:</label>
                            <input
                                type="text" 
                                className="form-control" 
                                {...register("url_seguimiento")} 
                                defaultValue={''} />
                        </div>

                        <div className="form-invalid">
                            {errors.url_seguimiento && 'La URL de seguimiento es obligatoria'}
                        </div>
                    </div>

                    <div className="w-100">
                        <div className="form-group">
                            <label htmlFor="numero_seguimiento">Número de seguimiento:</label>
                            <input
                                className="form-control" 
                                {...register("numero_seguimiento", { maxLength: 255 })} 
                                defaultValue={''} />
                        </div>

                        <div className="form-invalid">
                            {errors.numero_seguimiento && 'El mensaje es obligatorio'}
                        </div>
                    </div>

                    <div className="mt-5">
                        <button type="submit" className="btn btn-primary">Enviar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}