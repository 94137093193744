import React from 'react';
import MetricsSortTable from '../../../shared/MetricsSortTable';
import MetricsTable from '../../../shared/MetricsTable';

export default function PiezasMetrics({view, data}) {
        return (
            <div className='w-100 d-flex flex-column align-items-center justify-content-between my-3'>
                 <div className='card-grid w-100 d-flex flex-wrap flex-md-nowrap'>
                    <div className='w-100 w-md-50 card-item d-flex flex-column align-items-center'>
                        { view === 'dia' ? <h2>Piezas diarias</h2> : ''}
                        { view === 'semana' ? <h2>Piezas semanales</h2> : ''}
                        { (view === 'mes' || view === 'variable') ? <h2>Piezas mensuales</h2> : ''}
                        { (view === 'año' || view === 'año-variable') ? <h2>Piezas anuales</h2> : ''}
                        { (view === 'ultimos-12meses') ? <h2>Piezas</h2> : ''}
                        <h1>{data.num_piezas}</h1>
                    </div>
                </div>

                <div className="w-100 d-flex flex-wrap justify-content-between mt-5">
                    <div className="col-12 col-md-6 pe-0 pe-md-3">
                        <MetricsTable 
                            titulo={"Top 10 más vendidas"}
                            labels={['tipo', 'total']}
                            data={data.top_10_vendidas}
                        />
                    </div>

                    <div className="col-12 col-md-6 pe-0 pe-md-3">
                        <MetricsTable 
                            titulo={"Top 10 más rentables"}
                            labels={['tipo', 'inversion', 'beneficio', 'porcentaje']}
                            data={data.top_10_rentabilidad}
                        />
                    </div>
                </div>

                <div className="w-100 d-flex flex-wrap justify-content-between mt-5">
                    <div className="col-12 col-md-6 pe-0 pe-md-3">
                        <MetricsTable 
                            titulo={"Top 10 más incidencias"}
                            labels={['tipo', 'total']}
                            data={data.top_10_mas_incidencias}
                        />
                    </div>

                    <div className="col-12 col-md-6 pe-0 pe-md-3">
                        <MetricsTable 
                            titulo={"Top 10 menos incidencias"}
                            labels={['tipo', 'total']}
                            data={data.top_10_menos_incidencias}
                        />
                    </div>
                </div>

                <div className="w-100 d-flex flex-wrap justify-content-between mt-5">
                    <div className="col-12 col-md-6 pe-0 pe-md-3">
                        <MetricsSortTable 
                            titulo={"Total vendidas"}
                            labels={['tipo', 'total']}
                            data={data.total_vendidas}
                        />
                    </div>

                    <div className="col-12 col-md-6 pe-0 pe-md-3">
                        <MetricsSortTable 
                            titulo={"Ticket medio"}
                            labels={['tipo', 'piezas', 'total', 'ticket']}
                            data={data.ticket_medio}
                        />
                    </div>
                </div>

                <div className="w-100 d-flex flex-wrap align-items-center justify-content-between mt-5">
                    <div className="col-12 col-md-6 pe-0 pe-md-3">
                        <MetricsSortTable 
                            titulo={"Porcentaje de conversion"}
                            labels={['tipo', 'presupuestadas', 'vendidas', 'porcentaje']}
                            data={data.conversion}
                        />
                    </div>

                    <div className="col-12 col-md-6 pe-0 pe-md-3">
                        <MetricsSortTable 
                            titulo={"Margen de beneficio"}
                            labels={['tipo', 'piezas', 'coste', 'venta', 'porcentaje']}
                            data={data.margen_beneficio}
                        />
                    </div>
                </div>

                <div className="w-100 d-flex flex-wrap align-items-center justify-content-between mt-5">
                    <div className="col-12 col-md-6 pe-0 pe-md-3">
                        <MetricsSortTable 
                            titulo={"Porcentaje de fallo de incidencias"}
                            labels={['tipo', 'incidencias', 'piezas', 'porcentaje']}
                            data={data.incidencias_fallo}
                        />
                    </div>

                    <div className="col-12 col-md-6 pe-0 pe-md-3">

                    </div>
                </div>
            </div>
        )
}