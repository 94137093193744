import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import AutocompleteSelect from "../../../../../shared/components/AutocompleteSelect";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { editAlmacen, saveAlmacen } from "../../../../../../../../redux/features/currentProveedor";
import GooglePlacesAutocomplete from "../../../GooglePlacesAutocomplete";

export default function AlmacenForm({
    almacen, 
    proveedorId, 
    isEdit, 
    onActionDone
}) {
    const almacenSchema = yup.object({
        nombre: yup.string().nullable().required("El nombre es obligatorio"),
        telefono: yup.string().nullable().required("El teléfono es obligatorio"),
        direccion:  yup.string().nullable().required("La dirección es obligatoria"),
        codigo_postal: yup.string().nullable().required("El código postal es obligatorio"),
        poblacion: yup.string().nullable().required("La población es obligatoria"),
        pais_id: yup.number().integer().nullable().required("El país es obligatorio")
      }).required();
    const { register, formState: { errors }, handleSubmit, reset, watch, setValue, getValues } = useForm({
        resolver: yupResolver(almacenSchema),
        mode: "onChange",
        defaultValues: {
            id: 0,
            nombre: '',
            direccion: '',
            codigo_postal: '',
            poblacion: '',
            telefono: '',
            pais_id: '',
            proveedor_id: '',
            tipo_piezas: []
        }
    });
    const dispatch = useDispatch();
    const tipoPiezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });
    const paises = useSelector(state => {
        const { paises } = state;
        return paises;
    });

    useEffect(() => {
        reset(almacen);
    }, [almacen]);

    const handleChangePais = async(e) => {
        const value = parseInt(e.target.value);

        setValue('poblacion', null);
        setValue('codigo_postal', null);
        setValue('area', null);
        setValue('pais_id', value);
    }

    const handleSubmitAlmacen = async(data, e) => {
        e.preventDefault();
        dispatch({type: 'loading/set', payload: true});
        
        if(data.tipo_piezas && data.tipo_piezas?.length > 0) {
            const piezasIds = data.tipo_piezas.map(p => p.id);
            data.tipo_piezas = piezasIds;
        }

       if(isEdit) { dispatch(editAlmacen(data)) } 
       else {
            data.proveedor_id = proveedorId;
            dispatch(saveAlmacen(data));
        }

        onActionDone();
    }

    const handleChangeTipoPiezas = (tipoPiezas) => { setValue('tipo_piezas', tipoPiezas); }
    
    const handleChangeDireccion = (direccion) => {
        Object.keys(direccion).forEach(function(key) {
            setValue(key, direccion[key])
        });
    }
    
    return (
        <div className="w-100 d-flex flex-column">
            <form className="form mb-4" onSubmit={handleSubmit(handleSubmitAlmacen)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={isEdit ? almacen.id : ''} />
                <input 
                    type="hidden" 
                    {...register("proveedor_id")} 
                    defaultValue={isEdit ? almacen.proveedor_id : ''} />

                <div className="w-100 d-flex flex-column">
                    <div className="d-flex align-items-center">
                        <div className="form-group me-3">
                            <label htmlFor="nombre">Nombre</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                {...register("nombre")} 
                                defaultValue={isEdit ? almacen.nombre : ''} />

                            <div className="form-invalid">
                                {(errors.nombre) && errors.nombre?.message}
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="telefono">Teléfono</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                {...register("telefono")} 
                                defaultValue={isEdit ? almacen.telefono : ''} />
                            
                            <div className="form-invalid">
                                {(errors.telefono) && errors.telefono?.message}
                            </div>
                        </div>
                    </div>

                    <div className="w-100 d-flex align-items-center mb-2">
                        <div className="d-flex flex-column mb-3 me-3">
                            <label className="mb-1" htmlFor="pais_id">País</label>
                            { (paises) &&
                                <select 
                                    {...register("pais_id", {required: true})}
                                    defaultValue={isEdit ? almacen.pais_id : 1}
                                    onChange={handleChangePais}>
                                    {paises.map(op => <option key={'paises-' + op.id} value={op.id}>{op.nombre}</option>)}
                                </select>
                            }

                            <div className="form-invalid">
                                {(errors.pais_id) && errors.pais_id?.message}
                            </div>
                        </div>
                        
                        <div className="form-group">
                            <label className="mb-1">Buscar una dirección</label>
                            <GooglePlacesAutocomplete 
                                type={'address'}
                                types={['street_address', 'route', 'car_dealer', 'car_rental', 'car_repair']}
                                country={watch('pais_id') ? paises?.find(p => p.id === parseInt(getValues('pais_id')))?.codigo : ''}
                                onSelectResult={handleChangeDireccion}
                            />
                        </div>
                    </div>

                    <div className="d-flex align-items-center">
                        <div className="d-flex flex-column mb-3 me-3">
                            <label htmlFor="codigo_postal" style={{marginBottom: '5px'}}>Código postal</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                {...register("codigo_postal", { required: true })} 
                                defaultValue={isEdit ? almacen.codigo_postal : ''} />

                            <div className="form-invalid">
                                {(errors.codigo_postal) && errors.codigo_postal?.message}
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="direccion">Dirección</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                {...register("direccion", { required: true })} 
                                defaultValue={isEdit ? almacen.direccion : ''} />
                            
                            <div className="form-invalid">
                                {(errors.direccion) && errors.direccion?.message}
                            </div>
                        </div>
                    </div>

                    <div className="d-flex align-items-center">
                        <div className="form-group me-3">
                            <label htmlFor="poblacion">Población</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                {...register("poblacion", { required: true })} 
                                defaultValue={isEdit ? almacen.poblacion : ''} />

                            <div className="form-invalid">
                                {(errors.poblacion) && errors.poblacion?.message}
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="area">Area (Provincia, CCAA)</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder="Provincia, CCAA"
                                {...register("area", { required: true })} 
                                defaultValue={isEdit ? almacen.area : ''} />

                            <div className="form-invalid">
                                {(errors.area) && errors.area?.message}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-100 d-flex">
                    <div className="form-group flex-grow-1">
                        <label>Tipo de piezas</label>
                        <AutocompleteSelect 
                            options={tipoPiezas}
                            id={'tipo_piezas'}
                            selectedValues={isEdit ? almacen.tipo_piezas : []}
                            onSetValue={handleChangeTipoPiezas}
                        />
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    )
}