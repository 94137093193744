export const getActions = (item) => {
    const actions = {
        actions: [],
        extra: []
    };

    if(item.validado == false && item.presupuesto) {
        actions.actions.push( {
            label: 'Validar pedido',
            icon: 'check',
            action: 'validate'
        });
    }

    if(!item.presupuesto && item.validado == false) {
        actions.actions.push( {
            label: 'Asignar presupuesto',
            icon: 'add',
            action: 'assign'
        });
    }

    return actions;
}