import API from "../../../../../services/api";


export const getReconstruidos = async (page = null, search = null) => {
    const pagination = page !== null ? '?page=' + page : '';
    const searcher = search !== null && page !== null ? '&' + search : search !== null ? '?' + search : '';
    return await API.get('/reconstruidos'+ pagination + searcher).then(res => res.data);
}

export const updateReconstruido = async (reconstruido) => {
    return API.put('reconstruidos/' + reconstruido.id, reconstruido).then(res => res.data);
}