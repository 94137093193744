import React from 'react';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { fetchGeneralAtcMetrics, fetchUserAtcMetrics } from '../../../../../../../../redux/features/dashboard';
import '../../../Dashboard.scss';
import DateSelector from '../../../../../shared/components/DateSelector';
import GeneralMetrics from './components/GeneralMetrics';
import InfoGeneralIncidencias from '../shared/InfoGeneralIncidencias';
import AtencionClienteResume from './components/AtencionClienteResume';

export default function AtencionClientesTab() {
    const dispatch = useDispatch();
    const [currentUser, setCurrentUser] = useState(null);
    const [startDate, setStartDate] = useState(moment().date() >= 26 ?
        moment().date(26).format('YYYY-MM-DD')
    :   moment().subtract(1, 'month').date(26).format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().date() >= 26 ? 
        moment().add(1, 'month').date(25).format('YYYY-MM-DD')
    :   moment().date(25).format('YYYY-MM-DD'));
    const [view, setView] = useState('variable');
    const [currentUsers, setCurrentUsers] = useState(null);
    const [tipo, setTipo] = useState(null);
    const [culpa, setCulpa] = useState(null);
    const dashboard = useSelector(state => {
        return state.dashboard.entity.admin
    });
    const atencionCliente = useSelector(state => {
        return state.atencion_cliente
    });
    const administracion = useSelector(state => {
        return state.administracion
    });
    const tipoIncidencias = useSelector(state => {
        return state.tipo_incidencias
    });

    useEffect(() => {
        const usuarios = [...atencionCliente, ...administracion];
        setCurrentUsers(usuarios);
        dispatch(fetchGeneralAtcMetrics(startDate, endDate, view, null, null));
    }, []);

    const handleChangeUser = (e) => {
        if(parseInt(e.target.value) === -1) {
            dispatch(fetchGeneralAtcMetrics(startDate, endDate, view, tipo, culpa));
            setCurrentUser(null);
        } else {
            dispatch(fetchUserAtcMetrics(e.target.value, startDate, endDate, view, tipo, culpa));
            setCurrentUser(e.target.value);
        }
    }

    const handleDateInterval = (startDate, endDate, view) => {
        setView(view);
        setStartDate(startDate);
        setEndDate(endDate);

        if(!currentUser) dispatch(fetchGeneralAtcMetrics(startDate, endDate, view, tipo, culpa));
        else dispatch(fetchUserAtcMetrics(currentUser, startDate, endDate, view, tipo, culpa));
    }

    const handleTipo = (e) => {
        setTipo(e.target.value);

        if(e.target.value === 'todos') {
            if(!currentUser) {
                dispatch(fetchGeneralAtcMetrics(startDate, endDate, view, null, culpa));
            } else dispatch(fetchUserAtcMetrics(currentUser, startDate, endDate, view, tipo, culpa));
        } else {
            if(!currentUser) {
                dispatch(fetchGeneralAtcMetrics(startDate, endDate, view, e.target.value, culpa));
            } else dispatch(fetchUserAtcMetrics(currentUser, startDate, endDate, view, tipo, culpa));
        } 
    }

    const handleCulpa = (e) => {
        setCulpa(e.target.value);

        if(e.target.value === 'todos') {
            if(!currentUser) {
                dispatch(fetchGeneralAtcMetrics(startDate, endDate, view, tipo, null));
            } else dispatch(fetchUserAtcMetrics(currentUser, startDate, endDate, view, tipo, null));
        } else {
            if(!currentUser) {
                dispatch(fetchGeneralAtcMetrics(startDate, endDate, view, tipo, e.target.value));
            } else dispatch(fetchUserAtcMetrics(currentUser, startDate, endDate, view, tipo, e.target.value));
        } 
    }
 

    return (
        <div className='w-100 d-flex flex-column align-items-center dashboard'>
            <InfoGeneralIncidencias
                data={dashboard?.general}
            />

            <div className='w-100 summary'>
                <div className='w-100 d-flex align-items-center mb-4'>
                    <div className="d-flex align-items-center me-0 me-md-3 mt-3 mt-md-0">
                        <div className="form-group m-0">
                            <small>Usuario</small>

                            { (currentUsers && currentUsers.length > 0) &&
                                <select 
                                    id="responsable"
                                    defaultValue={currentUser ? currentUser : -1}
                                    onChange={(e) => handleChangeUser(e)}>
                                    <option key={'atc-' + 0} value={-1}>Todos</option>
                                    {currentUsers.map(op => <option key={'atc-' + op.id} value={op.id}>{op.username}</option>)}
                                </select>
                            }
                        </div>
                    </div>

                    <div className="d-flex align-items-center me-0 me-md-3 mt-3 mt-md-0">
                        <div className="form-group m-0">
                            <small>Tipo</small>
                            { (tipoIncidencias && tipoIncidencias.length > 0) &&
                                    <select 
                                        id="tipo"
                                        value={tipo ? tipo : 'todos'}
                                        onChange={handleTipo}>
                                        <option key={'tipo-' + 0} value={'todos'}>Todos</option>
                                        {tipoIncidencias.map(op => <option key={'tipo-' + op.id} value={op.id}>{op.nombre}</option>)}
                                    </select>
                            }
                        </div>
                    </div>

                    <div className="d-flex align-items-center me-0 me-md-3 mt-3 mt-md-0">
                        <div className="form-group m-0">
                            <small>Culpa</small>
                            <select 
                                id="culpa"
                                value={culpa ? culpa : 'todos'}
                                onChange={handleCulpa}>
                                <option value={'todos'}>Todos</option>
                                <option value={'cliente'}>Cliente</option>
                                <option value={'proveedor'}>Proveedor</option>
                                <option value={'recomotor'}>Recomotor</option>
                                <option value={'atencion-comercial'}>Atención comercial</option>
                                <option value={'transporte-propio'}>Transporte propio</option>
                                <option value={'transporte-proveedor'}>Transporte proveedor</option>
                            </select>
                        </div>
                    </div>

                    <DateSelector onSelectedDate={handleDateInterval} />
                </div>

                { (dashboard.generalAtcMetrics?.resumen && dashboard.generalAtcMetrics?.resumen?.length > 0) &&
                    <AtencionClienteResume 
                        resumen={dashboard.generalAtcMetrics?.resumen}
                    />
                }

                { (dashboard.generalAtcMetrics) &&  
                    <GeneralMetrics 
                        view={view} 
                        data={dashboard.generalAtcMetrics} />
                }

                { (dashboard.userAtcMetrics) &&  
                    <GeneralMetrics 
                        view={view} 
                        data={dashboard.userAtcMetrics} />
                }
            </div>
        </div>
    )
}
