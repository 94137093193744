import { useState, useEffect } from "react";
import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import AvisosList from "./components/AvisosList";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchAvisosList, saveAviso } from "../../../../../../redux/features/avisosList";
import AvisoForm from "../shared/AvisoForm";
import moment from "moment";
import Loading from "../../../shared/components/Loading";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
        className="w-100"
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
    >
      {value === index && (
        <Box>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Avisos({
  avisoId
}) {
    const dispatch = useDispatch();
    const [today, setToday] = useState(null);
    const [tab, setTab] = useState(0);
    const [startDateValue, setStartDateValue] = useState(null);
    const [endDateValue, setEndDateValue] = useState(null);
    const [search, setSearch] = useState("");
    const [isNew, setIsNew] = useState(false);
    const incidencias = useSelector(state => {
      return state.incidencias.entities
    });
    const avisos = useSelector(state => {
      const { avisosList } = state;
      return avisosList.entity;
    });
    const status = useSelector(state => {
      const { avisosList } = state;
      return avisosList.status;
    });

    useEffect(() => {
      setToday(moment().format('DD-MM-YYYY'));
      dispatch(fetchAvisosList(moment().format('DD-MM-YYYY'), null));
    }, []);

    const handleChangeTab = (e, tab) => {
      setTab(tab);


      if(tab === 'today') {dispatch(fetchAvisosList(moment().format('DD-MM-YYYY'), null));}
      else {
        const startOfPastWeek = moment().subtract(1, 'week').startOf('week');
        const endOfFuturetWeek = moment().add(1, 'week').endOf('week');

        setStartDateValue(startOfPastWeek);
        setEndDateValue(endOfFuturetWeek);
        dispatch(fetchAvisosList(startOfPastWeek.format('DD-MM-YYYY'), endOfFuturetWeek.format('DD-MM-YYYY')));
      }
    }

    const handleSearch = (endDate) => {
      setEndDateValue(endDate);
      dispatch(fetchAvisosList(moment(startDateValue).format('DD-MM-YYYY'), moment(endDate).format('DD-MM-YYYY')));
    }

    const handleCreateAviso = async(data) => {
      dispatch({type: 'loading/set', payload: true});
      dispatch(saveAviso(data));
      dispatch(fetchAvisosList(startDateValue, endDateValue));
      setIsNew(false);
    }

    const handleNewAviso = () => { setIsNew(true); }
    const handleCancel = () => { setIsNew(false); }

    const handleChangeSearcher = (e) => {
      const value = e.target.value;
      setSearch(value);
    }

    return (
        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
          <Tabs
            orientation="vertical"
            value={tab}
            onChange={handleChangeTab}
            aria-label="Avisos"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab label="Hoy" {...a11yProps(0)} />
            <Tab label="Próximo" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={tab} index={0}>
            <div className="w-100 ps-3">
              <div className="w-100 d-flex align-items-center justify-content-end">
                <button type="button" className="btn btn-add" onClick={() => handleNewAviso()}>
                    <span className="add">
                        <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                    </span>
                    Nuevo
                </button>
              </div>

              <div className="w-100">
                { isNew && 
                  <AvisoForm 
                    incidencias={incidencias.activas} 
                    cancel={true}
                    isEdit={false}
                    onCancel={handleCancel}
                    onSubmitAviso={handleCreateAviso}
                  />
                }
              </div>

              { (status.loading === 'pending' || status.loading === 'idle') ?
                <Loading />
                : 
                <AvisosList 
                  startDate={startDateValue}
                  endDate={endDateValue}
                  avisos={avisos}
                  avisoId={avisoId}
                  incidencias={incidencias.activas}
                  type="today"
                  today={today} />
              }
            </div>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <div className="w-100 ps-3">
                <div className="w-100 d-flex flex-column align-items-end">
                  <div className="w-100 d-flex align-items-center justify-content-between mb-3"> 
                    <div className="d-flex align-items-center">
                      <div className="form-group m-0">
                        <small>Buscar...</small>
                        <div className="position-relative">
                          <input 
                              type="text" 
                              name="searcher" 
                              className="form-control" 
                              value={search} 
                              onChange={(e) => handleChangeSearcher(e)}
                              placeholder="Buscar por número de incidéncia"
                              />
                          <FontAwesomeIcon icon={faSearch} color="#215732" className="icon" style={{cursor: 'pointer'}} />
                        </div>
                      </div>

                      <div className="form-group m-0 ms-3">
                          <small>Fecha</small>

                          <div className="d-flex align-items-center">
                              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                  <DatePicker
                                      label=""
                                      value={startDateValue}
                                      onChange={(newValue) => {
                                          setStartDateValue(newValue);
                                      }}
                                      mask={'__/__/____'}
                                      inputFormat="dd/MM/yyyy"
                                      renderInput={({ inputRef, inputProps, InputProps }) => (
                                          <Box className="date-picker me-3" sx={{ position: 'relative' }}>
                                              <input className="form-control" ref={inputRef} {...inputProps} />
                                              {InputProps?.endAdornment}
                                          </Box>
                                      )}
                                  />
                              </LocalizationProvider>

                              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                  <DatePicker
                                      label=""
                                      value={endDateValue}
                                      onChange={(newValue) => {
                                          setEndDateValue(newValue);
                                          handleSearch(newValue);
                                      }}
                                      mask={'__/__/____'}
                                      inputFormat="dd/MM/yyyy"
                                      minDate={startDateValue}
                                      renderInput={({ inputRef, inputProps, InputProps }) => (
                                          <Box className="date-picker" sx={{ position: 'relative' }}>
                                              <input className="form-control" ref={inputRef} {...inputProps} />
                                              {InputProps?.endAdornment}
                                          </Box>
                                      )}
                                  />
                              </LocalizationProvider>
                          </div>
                      </div>
                    </div>
                    
                    
                    <button type="button" className="btn btn-add" onClick={() => handleNewAviso()}>
                      <span className="add">
                          <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                      </span>
                      Nuevo
                    </button>
                  </div>

                  
                </div>

                <div className="w-100">
                  { isNew && 
                    <AvisoForm 
                      incidencias={incidencias.activas}
                      cancel={true}
                      isEdit={false}
                      onCancel={handleCancel}
                      onSubmitAviso={handleCreateAviso}
                    />
                  }
                </div>
                
                { (status.loading === 'pending' || status.loading === 'idle') ?
                <Loading />
                : 
                <AvisosList 
                  startDate={startDateValue}
                  endDate={endDateValue}
                  avisos={avisos}
                  avisoId={avisoId}
                  search={search}
                  incidencias={incidencias.activas}
                  type="future"
                  today={today} />
              }
            </div>
          </TabPanel>
        </Box>
      );
}