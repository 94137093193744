import { getAlbaranes } from "../views/Dashboard/components/shared/services/albaranes";
import { asyncMac, makeAsyncTypes, makeCrudReducer, makeFetchingReducer, makeSetReducer, reduceReducers } from "./utils";

const crudReducer = makeCrudReducer([
    'albaranes/add',
    'albaranes/edit',
    'albaranes/delete'
])

const asyncAlbaranes  = makeAsyncTypes('albaranes');

const [setPending, setFullFilled, setError ] = asyncMac(asyncAlbaranes);

export const albaranesFetchingReducer = makeFetchingReducer([
    'albaranes/pending', 
    'albaranes/fullfilled', 
    'albaranes/rejected'
]);

const fullfilledReducer = makeSetReducer(['albaranes/fullfilled']);

export const fetchAlbaranes = (page, search) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getAlbaranes(page, search)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const albaranesReducer = reduceReducers(crudReducer, fullfilledReducer);
export const albaranesStatusReducer = albaranesFetchingReducer;