import { getFichaEntidad, updateEntidad } from "../../views/Dashboard/components/shared/services/clientes";
import { asyncMac, makeAsyncTypes, makeFetchingReducer } from "../utils";
import { toast } from 'react-toastify';

const initialCliente = {
    cliente: {},
    ventas: [],
    devoluciones: [],
    presupuestos: [],
    metricas: [],
};

export const clienteReducer = (state = initialCliente, action) => {
    switch(action.type){
        case "ficha-cliente/fullfilled": {
            return action.payload;
        }
        case "metricas-cliente/fullfilled": {
            return {...state, metricas: action.payload}
        }
        default:
            return state
    }
}

const asyncCliente = makeAsyncTypes('ficha-cliente');

const asyncClienteMetricas = makeAsyncTypes('metricas-cliente');

const [setPending, setFullFilled, setError ] = asyncMac(asyncCliente);
const [setPendingM, setFullFilledM, setErrorM ] = asyncMac(asyncClienteMetricas);

export const fichaClienteFetchingReducer = makeFetchingReducer([
    'ficha-cliente/pending', 
    'ficha-cliente/fullfilled', 
    'ficha-cliente/rejected'
]);

export const fetchFichaCliente = (section, search, page, clienteId) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getFichaEntidad(section, search, page, clienteId)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
}

export const fetchMetricasCliente = (clienteId, startDate, endDate, view) => async dispatch => {
    dispatch(setPendingM());

    let query = 'start_date=' + startDate
      + '&end_date=' + endDate
      + '&view=' + view;

    try {
        const response = await getFichaEntidad('metricas', query, 1, clienteId)
        const data = await response.data;
        dispatch(setFullFilledM(data));
    } catch (e) {
        toast.error(e.message)
        dispatch(setErrorM(e.message))
    }
}

export const editCliente = (clienteId) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await updateEntidad(clienteId)
        const data = await response.data;
        toast.success(response.message);
        dispatch(setFullFilled(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
}

export const fichaClienteReducer = clienteReducer;
export const fichaClienteStatusReducer = fichaClienteFetchingReducer;