import React from "react";
import { useState } from "react";
import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { reportCrmProblem } from "../../services/users";
import { toast } from "react-toastify";

export default function ReportModal({ 
    state,
    onClose
}) {
    const { register, handleSubmit, formState: {errors} } = useForm({
        mode: "onChange",
        defaultValues: {
            mensaje: '',
        }
    });
    const [open, setOpen] = useState(state);

    const handleClose = (event, reason) => { 
        if (reason && reason === "backdropClick") return;
        setOpen(false); 
        onClose();
    };

    const handleSend = async(data) => {
        const response = await reportCrmProblem(data);

        if(response && response.success) {
            toast.success(response.message);
            onClose();
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="confirm-title"
            aria-describedby="confirm-description">
            <DialogTitle id="confirm-title">
                <div className="w-100 d-flex align-items-center justify-content-between">
                    <p className="fw-bold text-uppercase">¿Tienes algún problema?</p>

                    <span title="cerrar" className="action" onClick={() => handleClose()}>
                        <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#215732" />
                    </span>
                </div>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="confirm-description">
                    Envíanos un mensaje a través de este formulario y lo resolveremos lo antes posible.
                </DialogContentText>

                <div className="w-100 mt-3">
                    <form className="form mt-4 d-flex flex-column align-items-end" onSubmit={handleSubmit(handleSend)}>
                        <div className="w-100 d-flex flex-column flex-md-row flex-wrap flex-md-nowrap">
                            <div className="form-group">
                                <label htmlFor="message">Mensaje</label>
                                <textarea 
                                    className="form-control" 
                                    {...register("mensaje", { required: true })} 
                                    defaultValue={''} ></textarea>
                            </div>

                            <div className="form-invalid">
                                {(errors.mensaje) && errors.mensaje?.message}
                            </div>
                        </div>
                        
                        <div className="d-flex align-items-center align-self-end ms-3">
                            <button 
                                type="submit"
                                className="btn btn-primary align-self-end">Enviar</button>
                        </div>
                    </form>
                </div>
            </DialogContent>
        </Dialog>
    );
}