import React, { useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ContactosList from "./components/ContactosList";
import FeedbacksList from "./components/FeedbacksList";


export default function EntidadContacto({
    entidad,
    contactos,
    feedbacks,
    onEditContacto
}) {
    const [tab, setTab] = useState('contactos');
    const handleChangeTab = (e, tab) => setTab(tab);
    
    return (
        <div className="w-100 sheet">
            { entidad.categoria === 'cliente' ?
                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChangeTab} aria-label="Contacto tabs">
                        <Tab label="Contactos" value="contactos" />
                        <Tab label="Feedbacks" value="feedbacks" />
                    </TabList>
                    </Box>
                    <TabPanel value="contactos">
                        <ContactosList 
                            contactos={contactos}
                            onEditContacto={onEditContacto}
                        />
                    </TabPanel>
                    <TabPanel value="feedbacks">
                        <FeedbacksList feedbacks={feedbacks} /> 
                    </TabPanel>
                </TabContext>
            : <ContactosList 
                    contactos={contactos}
                    onEditContacto={onEditContacto}
                />
            }
            
        </div>
    );
}