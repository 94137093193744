import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import VirtualizedAutocompletePersonas from '../../../../../../shared/components/AutocompletePersonas';
import MetricsSortTable from '../../shared/MetricsSortTable';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { newAvisoProveedor, updateAvisoProveedor } from '../../../../../../shared/services/proveedores';
import { toast } from 'react-toastify';
import { bloquearTodosTipoPiezas } from '../../../../../../shared/services/avisosProveedor';
import TipoPiezasSortTable from '../TipoPiezasSortTable';

export default function InformacionProveedores({
    view,
    data,
    proveedor,
    onSelectProveedor,
    onActionDone,
}) {
    const dispatch = useDispatch();
    const tipoIncidencias = useSelector(state => {
        const { tipo_incidencias } = state;
        return tipo_incidencias;
    });
    const tipoPiezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });
    const [search, setSearch] = useState("");
    const [tipoPiezasList, setTipoPiezasList] = useState(tipoPiezas);

    const { 
        control,
    } = useForm({
        defaultValues: {
            proveedor: null
        }
    });

    useEffect(() => {
        if(tipoPiezas.length > 0) {
            handleSetBloqueoTipoPiezasData(tipoPiezas)
        }
    }, [data, proveedor, view])

    const handleSetBloqueoTipoPiezasData = (tipos) => {
        const list = tipos.reduce((acc, curr) => {
            const isIn = data.porcentajes_tipo_piezas?.find(p => p.id === curr.id);
            const fallo = isIn ? isIn.fallo : 0;
            
            const dataObj = {
                id: curr.id,
                tipo: curr.nombre,
                fallo: fallo
            };

            acc.push(dataObj);
            return acc;
        }, [])

        list.sort((a,b) => a.fallo < b.fallo);
        setTipoPiezasList(list);
    }


    const handleAvisosProveedor = async(tipoIncidencia, enable) => {
        dispatch({type: 'loading/set', payload: true});
        let response;
        const params = {
            proveedor_id: proveedor.id,
            tipo_incidencia_id: tipoIncidencia.id,
            activo: enable,
            porcentaje: tipoIncidencia.id === 1 ?
                data.porcentajes_proveedor.garantia
                : tipoIncidencia.id === 2 ? 
                data.porcentajes_proveedor.envio
                : tipoIncidencia.id === 4 ?
                data.porcentajes_proveedor.prodIncorrecto
                : tipoIncidencia.id === 5 ? 
                data.porcentajes_proveedor.falloStock
                : tipoIncidencia.id === 6 ?
                data.porcentajes_proveedor.casco
                :
                data.porcentajes_proveedor.prodIncompleto
        };

        if(checkAvisosProveedor('aviso', tipoIncidencia)) {
            const aviso = proveedor.avisos_piezas.find(a => a.tipo_incidencia_id && a.tipo_incidencia_id === tipoIncidencia.id);
            params.id = aviso.id;
            response = await updateAvisoProveedor(params)
        } else {
            response = await newAvisoProveedor(params);
        }
       
        if(response && response.success) {
            const newProveedor = proveedor;
            toast.success(response.message);
            dispatch({type: 'loading/set', payload: false});

            if(checkAvisosProveedor('aviso', tipoIncidencia)) {
                const index = newProveedor.avisos_piezas.findIndex((a) => parseInt(a.id) === parseInt(params.id));
                newProveedor.avisos_piezas.splice(index, 1, response.data);
            } else {
                newProveedor.avisos_piezas.push(response.data);
            }

            onActionDone(newProveedor);
        }
    }

    const handleAvisosBloqueoProveedor = async(tipoPieza, enable) => {
        dispatch({type: 'loading/set', payload: true});
        let response;
        const porcentaje = data.porcentajes_tipo_piezas.find(p => p.id === tipoPieza.id);
        const params = {
            proveedor_id: proveedor.id,
            tipo_pieza_id: tipoPieza.id,
            activo: enable,
            porcentaje: porcentaje ? porcentaje.fallo : 0
        };

        if(checkAvisosProveedor('bloqueo', tipoPieza)) {
            const aviso = proveedor.avisos_piezas.find(a => a.tipo_pieza_id && a.tipo_pieza_id === tipoPieza.id);
            params.id = aviso.id;
            response = await updateAvisoProveedor(params)
        } else {
            response = await newAvisoProveedor(params);
        }
       
        if(response && response.success) {
            const newProveedor = proveedor;
            toast.success(response.message);
            dispatch({type: 'loading/set', payload: false});

            if(checkAvisosProveedor('aviso', tipoPieza)) {
                const index = newProveedor.avisos_piezas.findIndex((a) => parseInt(a.id) === parseInt(params.id));
                newProveedor.avisos_piezas.splice(index, 1, response.data);
            } else {
                newProveedor.avisos_piezas.push(response.data);
            }

            onActionDone(newProveedor);
        }
    }

    const checkAvisosProveedor = (tipoAviso, tipo) => {
        if(proveedor.avisos_piezas?.length > 0) {
            return proveedor.avisos_piezas.find(a => {
                if(tipoAviso === 'aviso') {
                    return a.tipo_incidencia_id && a.tipo_incidencia_id === tipo.id
                } else{
                    return a.tipo_pieza_id && a.tipo_pieza_id === tipo.id
                }
            })
        } else return false
    }

    const handleBloqueoTipoPieza = async(tipoPieza, enabled) => {
        dispatch({type: 'loading/set', payload: true});
        const response = await bloquearTodosTipoPiezas({
            tipo_pieza_id: tipoPieza.id,
            porcentaje: tipoPieza.fallo,
            activo: enabled
        });

        if(response && response.success) {
            toast.success(response.message);
            dispatch({type: 'loading/set', payload: false});
            onActionDone();
        }
    }

    const handleAction = (actionType, item, enabled) => {
        if(actionType === 'all-block') {
            handleBloqueoTipoPieza(item, enabled)
        } else {
            handleAvisosBloqueoProveedor(item, enabled)
        }
    }

    const handleChange = (e) => {
        const value = e.target.value;
        setSearch(value);

        if(value === "") {
            handleSetBloqueoTipoPiezasData(tipoPiezas)
        } else {
            const newList = tipoPiezas.filter(f => f.nombre.toUpperCase()?.includes(value?.toUpperCase()))
            handleSetBloqueoTipoPiezasData(newList)
        }
    }

    return (
        <div className="w-100 d-flex flex-column align-items-stretch mt-3">
            <hr />
            <h1 className='mt-3'>Información detallada</h1>

            <div className='w-100 d-flex align-items-center mt-3'>
                <span className='d-block w-100'>
                    <VirtualizedAutocompletePersonas 
                        id={"proveedor"}
                        control={control}
                        item={'proveedor'}
                        labels={['nombre', 'telefono', 'score']}
                        defaultValue={proveedor}
                        onChange={(prov) => {
                            setSearch("");
                            onSelectProveedor(prov)
                        }}
                    />
                </span>
            </div>

            <div className='w-100 d-flex flex-wrap align-items-stretch mt-5'>
                <div className='col-12 col-md-6 pe-0 pe-md-3'>
                    { data?.ranking_piezas_incidencias && data?.ranking_piezas_incidencias?.length > 0 ?
                        <MetricsSortTable 
                            titulo={"Ranking Piezas Incidencias"}
                            labels={['tipo', 'incidencias', 'ventas', 'fallo']}
                            data={data.ranking_piezas_incidencias}
                        />
                        : 
                        <div className='w-100 h-100 d-flex flex-column align-items-center py-5 py-md-0'>
                            <h1 className='text-center'>Ranking Piezas Incidencias</h1>
                            
                            <div className='w-100 d-flex flex-grow-1 align-items-center justify-content-center'>
                                <p className='text-center'>No hay datos.</p>
                            </div>
                        </div>
                    }
                </div>

                <div className='col-12 col-md-6 pe-0 pe-md-3'>
                    { data?.ranking_tipo_incidencias && data?.ranking_tipo_incidencias?.length > 0 ?
                        <MetricsSortTable 
                            titulo={"Ranking Tipo Incidencias"}
                            labels={['tipo', 'total', 'fallo']}
                            data={data.ranking_tipo_incidencias}
                        />
                        : 
                        <div className='w-100 h-100 d-flex flex-column align-items-center py-5 py-md-0'>
                            <h1 className='text-center'>Ranking Tipo Incidencias</h1>
                            
                            <div className='w-100 d-flex flex-grow-1 align-items-center justify-content-center'>
                                <p className='text-center'>No hay datos.</p>
                            </div>
                        </div>
                    }
                </div>

                
                { (proveedor) ?
                    <div className='col-12 col-md-6 pe-0 pe-md-3 mt-5'>
                        <h1 className='text-center'>Avisos</h1>

                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align={'center'}>
                                            TIPO
                                        </TableCell>
                                        <TableCell align={'center'}>
                                            FALLO
                                        </TableCell>
                                        <TableCell align={'center'}>
                                            ACCIONES
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    { tipoIncidencias.filter(tp => [1, 2, 4, 5, 6, 7].includes(tp.id)).map(tipo => {
                                        return (
                                            <TableRow key={'tipo-incidencia-' + tipo.id}>
                                                <TableCell align={'center'}>
                                                    {tipo.nombre}
                                                </TableCell>
                                                <TableCell align={'center'}>
                                                    { tipo.id === 1 ?
                                                        data.porcentajes_proveedor?.garantia
                                                        : tipo.id === 2 ? 
                                                        data.porcentajes_proveedor?.envio
                                                        : tipo.id === 4 ?
                                                        data.porcentajes_proveedor?.prodIncorrecto
                                                        : tipo.id === 5 ? 
                                                        data.porcentajes_proveedor?.falloStock
                                                        : tipo.id === 6 ?
                                                        data.porcentajes_proveedor?.casco
                                                        :
                                                        data.porcentajes_proveedor?.prodIncompleto
                                                    }
                                                    %
                                                </TableCell>
                                                <TableCell align={'center'}>
                                                    { (checkAvisosProveedor('aviso', tipo) && checkAvisosProveedor('aviso', tipo)?.activo) ?
                                                        <span className='action' onClick={() => handleAvisosProveedor(tipo, false)}>
                                                            <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                                                        </span>
                                                        :
                                                        <span className='action' onClick={() => handleAvisosProveedor(tipo, true)}>
                                                            <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#00986C" />
                                                        </span>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    : null
                }

                <div className='col-12 col-md-6 pe-0 pe-md-3 mt-5'>
                        <h1 className='text-center'>Bloqueo Piezas</h1>

                        <div className="position-relative ms-auto my-4">
                            <input 
                                type="text" 
                                name="searcher" 
                                className="form-control" 
                                value={search} 
                                onChange={handleChange}
                            />
                            <FontAwesomeIcon icon={faSearch} color="#215732" className="icon" />
                        </div>

                        <TipoPiezasSortTable 
                            labels={['tipo', 'fallo', 'acciones']}
                            data={tipoPiezasList}
                            dashboardData={data}
                            proveedor={proveedor}
                            onAction={handleAction}
                        />
                    </div>
            </div>

            <div className='w-100 d-flex flex-wrap flex-md-nowrap align-items-center mt-5'>
               
            </div>
        </div>
    )
}