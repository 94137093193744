import API from "../../../../../services/api";
import { headers } from "../helpers/functionalities";

export const getSolicitudes = async (page = null, search = null) => {
    const pagination = page !== null ? '?page=' + page : '';
    const searcher = search !== null && page !== null ? '&' + search : search !== null ? '?' + search : '';
    return await API.get('/solicitudes'+ pagination + searcher).then(res => res.data);
}

export const saveSolicitud = async (solicitud) => {
    return API.post('/solicitudes', solicitud, headers).then(res => res.data);
}

export const renerateSolicitud = async (solicitudId, type) => {
    return API.post('/solicitudes/' + solicitudId + '/' + type, {}).then(res => res.data);
}

export const downloadFichaTecnica = async (solicitudId) => {
    return API.post('/solicitudes/' + solicitudId + '/ficha', null, {responseType: 'blob'}).then(res => res.data);
}