import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { checkIfIsReliableProveedor, setErrorMessage } from "../../../../../../../../../../../shared/helpers/functionalities";
import { scrappingURL } from "../../../../../../../../../../../shared/services/scrapping";
import Loading from "../../../../../../../../../../../shared/components/Loading";
import ProveedorPocoFiableModal from "../ProveedorPocoFiableModal";

export default function ScrappingForm({
    onScrapping
}) {
    const { register, formState: { errors }, handleSubmit: handleSubmitURL } = useForm();
    const [scrapping, setScrapping] = useState(null);
    const [showUnreliableModal, setShowUnreliableModal] = useState(false);
    const [isScrapping, setIsScrapping] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
    }, [showUnreliableModal, scrapping]);
    
    // metodo para obtener la URL y hacer scrapping
    const onSubmit = async (data, e) => {
        e.preventDefault();
        setIsScrapping(true);
        setIsLoading(true);
        
        const scrappingResponse = await scrappingURL(data)
        .catch(function (error) {
            setIsLoading(false);
            setIsScrapping(false);
            toast.error(setErrorMessage(error ? error : "ERROR! Ha ocurrido un problema o el anuncio no está disponible."))
        });

        if(scrappingResponse && scrappingResponse.success) {
            let scrappingData = scrappingResponse.data;
            // si es multiple es que nos da un listado de piezas
            if (scrappingData?.multiple){
                onScrapping(scrappingData, data);
            } else if(scrappingData?.proveedor) {
                if(checkIfIsReliableProveedor(scrappingData?.proveedor)) {
                    onScrapping(scrappingData, data);
                } else {
                    setShowUnreliableModal(true);
                    setScrapping({scrappingData: scrappingData, data: data});
                }
            } else { onScrapping(scrappingData, data); }
        }

        setIsScrapping(false);
        setIsLoading(false);
    };

    const handleScrapping = (confirm) => {
        if(confirm) onScrapping(scrapping.scrappingData, scrapping.data);
        else {
            setScrapping(null);
            setShowUnreliableModal(false);
        }
    }

    return (
        <>
            { (isLoading) && <Loading /> }

            { (!isLoading) &&
                <form className="d-flex align-self-center mt-2" onSubmit={handleSubmitURL(onSubmit)}>
                    <div className="form-group mb-0">
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="URL pieza"
                            {...register("url", { required: true })} 
                            defaultValue='' />

                        <div className="form-invalid">
                            {errors.url?.type === 'required' && "URL es obligatoria"}
                        </div>
                    </div>

                    <button type="submit" disabled={isScrapping} className="btn btn-primary ms-2">Scrapping</button>
                </form>
            }

            { (showUnreliableModal && scrapping) &&
                <ProveedorPocoFiableModal
                    pieza={scrapping.scrappingData}
                    onConfirmAction={handleScrapping} 
                    state={showUnreliableModal}>
                </ProveedorPocoFiableModal>
            }
            
        </>
    );
}