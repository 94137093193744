import React, { useState } from 'react';
import { useEffect } from "react";
import { getAvisosProveedor } from '../../../../../../shared/services/avisosProveedor';
import { useDispatch } from 'react-redux';
import { Pagination } from '@mui/material';
import moment from 'moment';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Filter from './Filter';


export default function HistoryList({
    startDate,
    endDate
}) {
    const dispatch = useDispatch();
    const [currentPage, setPage] = useState(1);
    const [history, setHistory] = useState(null);
    const [currentProveedor, setCurrentProveedor] = useState(null);
    const [currentTipo, setCurrentTipo] = useState("-1");
    const [reset, setReset] = useState(false);

    useEffect(() => {
        getHistory(1, startDate, endDate);
    }, [])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getHistory(newPage, startDate, endDate)
    }

    const getPaginationCount = () => {
        var totalPages = history.total_items < 15 ? 1 : Math.ceil(history.total_items / 15);
        return parseInt(totalPages);
    }

    const handleChangeProveedor = async(proveedor) => {
        if(proveedor !== "" || proveedor !== undefined || proveedor !== null) {
            setCurrentProveedor(proveedor);
            getHistory(currentPage, startDate, endDate, currentTipo === "" ? null : currentTipo, proveedor.id);
        } else {
            setCurrentProveedor(null);
            getHistory(currentPage, startDate, endDate, currentTipo === "" ? null : currentTipo, null);
        }

        setReset(false);
    }

    const handleChangeTipo = (e) => {
        const value = e.target.value;
        setCurrentTipo(value);

        if(value === "-1") {
            getHistory(currentPage, startDate, endDate, null, currentProveedor ? currentProveedor.id : null);
        } else {
            getHistory(currentPage,  startDate, endDate, value, currentProveedor ? currentProveedor.id : null);
        }

        setReset(false);
    }

    const handleReset = () => {
        setReset(true);
        setCurrentProveedor(null);
        setCurrentTipo("-1");
        getHistory(currentPage, startDate, endDate);
    }

    const getHistory = async(page, startDate, endDate, type = null, proveedorId = null) => {
        dispatch({type: 'loading/set', payload: true});
        const response = await getAvisosProveedor(page, startDate, endDate, type, proveedorId)

        if(response && response.success) {
            setHistory(response.data);
            dispatch({type: 'loading/set', payload: false});
        }
    }

    return (
        <div className='w-100'>
            { (history) &&
            
                <div>
                    <div className='w-100 d-flex align-items-center mb-4'>
                        <Filter 
                            proveedor={currentProveedor}
                            tipo={currentTipo}
                            reset={reset}
                            onChangeProveedor={handleChangeProveedor}
                            onChangeTipo={handleChangeTipo}
                            onReset={handleReset}
                        />
                    </div>

                    <div className='w-100 table-responsive'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className='text-center'>grado</th>
                                    <th className='text-center'>fecha</th>
                                    <th className='text-center'>tipo</th>
                                    <th className='text-center'>item</th>
                                    <th className='text-center'>porcentaje</th>
                                    <th>proveedor</th>
                                    <th className='text-center'>activo</th>
                                    <th className='text-center'>archivado</th>
                                    <th className='text-center'>fecha archivado</th>
                                </tr>
                            </thead>

                            <tbody>
                                { history.historico?.length > 0 ?
                                    history.historico.map(h => {
                                    return (
                                        <tr key={'historico-' + h.id}>
                                            <td width={'5%'}>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <span className="circle" style={{background: h.porcentaje >= 40 ? '#dc3545' : '#ffc107' }}></span>
                                                </div>
                                            </td>
                                            <td width={'8%'} className='text-center text-uppercase'>{h.porcentaje >= 40 ? 'grave' : 'leve'}</td>
                                            <td width={'8%'} className='text-center'>{moment(h.fecha).format('DD-MM-YYYY')}</td>
                                            <td width={'8%'} className='text-center text-uppercase'>
                                                { h.tipo_pieza_id ? 'bloqueo' : 'aviso' }
                                            </td>
                                            <td width={'8%'} className='text-center text-uppercase'>
                                                { h.tipo_pieza_id ? h.tipo_pieza.nombre : h.tipo_incidencia.nombre }
                                            </td>
                                            <td className='text-center fw-bold'>{h.porcentaje}%</td>
                                            <td className='text-uppercase'>{h.proveedor.nombre}</td>
                                            <td className='text-center'>
                                                { h.activo ? 
                                                    <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#215732" />
                                                    :  
                                                    <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                                                }
                                            </td>
                                            <td className='text-center'>
                                                { h.archivado ? 
                                                    <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#215732" /> 
                                                    :  
                                                    <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                                                }
                                            </td>
                                            <td className='text-center'>
                                                {h.archivado && h.fecha_archivado !== null ? moment(h.fecha_archivado).format('DD-MM-YYYY') : '-'}
                                            </td>
                                        </tr>
                                    )})
                                    : 
                                    <tr>
                                        <td colSpan={10} className='text-center'>No hay registros</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>

                    <Pagination 
                        className="mt-3" 
                        count={getPaginationCount()} 
                        page={currentPage} 
                        onChange={handleChangePage} 
                    />
                </div>
            }
        </div>
    )
}
