import { useEffect, useState } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import InfoModal from "../../../shared/components/InfoModal";
import { fetchVariables } from "../../../../../../redux/variables";
import { updateVariable } from "../../../shared/services/variables";
import { toast } from "react-toastify";
import VariableForm from "./components/VariableForm";

export default function Variables() {
    const dispatch = useDispatch();
    const [currentVariable, setCurrentVariable] = useState(null);
    const [openForm, setOpenForm] = useState(false);

    const variables = useSelector(state => {
        return state.variables
    });

    useEffect(() => {
        dispatch(fetchVariables());
      }, []);

    const handleOpenForm = (variable) => {
        setOpenForm(true);
        setCurrentVariable(variable);
    }

    const handleCloseForm = () => { 
        setOpenForm(false);
        setCurrentVariable(null);
    }

    const handleUpdateVariable = async(data) => {
        dispatch({type: 'loading/set', payload: true});
        const response = await updateVariable(data);

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            handleCloseForm();
        }
    }

    return (
        <div className="w-100 d-flex justify-content-center">
            <div className="col-12 col-md-8 d-flex flex-column align-items-end">
                <div className="w-100 d-flex flex-column mt-4">
                    <div className="w-100 table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>nombre</th>
                                    <th className="text-center">valor</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                { variables.map(variable => {
                                    return (
                                        <tr>
                                            <td>{variable.nombre}</td>
                                            <td className="text-center">{variable.valor}</td>
                                            <td width={'8%'} className="text-center">
                                                <span title="Editar" style={{cursor: 'pointer'}} className="me-3">
                                                    <FontAwesomeIcon icon={faPen} size="1x" className="action" color="#215732" onClick={() => handleOpenForm(variable)} />
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                }) }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            { (openForm) && 
                <InfoModal
                    state={openForm}
                    title={'Editar variable'}
                    content={
                        <VariableForm 
                            variable={currentVariable}
                            onSubmitVariable={handleUpdateVariable}
                        />
                    }
                    width={'sm'}
                    onClose={handleCloseForm}
                ></InfoModal>
            }
        </div>
    )
}