import { faCheck, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect } from "react";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function ProblemasList({
    problemas,
    onMarkAsRead
}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {}, []);

    const handleGoToVenta = (numero) => {
        dispatch({type: "search/set", payload: {search: numero }});
        navigate('/ventas/1', { replace : true});
      }
 
    return (
        <div className="w-100 d-flex flex-column my-3">
            { problemas?.map(problema => {
                return (
                  <div className="alert alert-danger">
                    <div className="w-100 d-flex">
                        <div className="d-flex flex-grow-1">
                            <div className="d-flex align-items-center">
                                <span className="me-2">
                                    <FontAwesomeIcon icon={faExclamationTriangle} size="1x" color="#dc3545" /> 
                                </span>
                                <span className="fw-bold" onClick={() => handleGoToVenta(problema.venta.presupuesto.numero)}>{problema.venta.presupuesto.numero}</span>
                                <small className="mx-2">|</small>
                                <small>{moment(problema.fecha).format('DD-MM-YYYY')}</small>
                                <small className="mx-2">|</small>
                            </div>

                            <div className="d-flex flex-column">
                                <ul className="m-0">
                                    <li style={{listStyle: 'square'}}>
                                        <div className="d-flex flex-wrap">
                                            <small className="text-uppercase fw-bold me-2">{problema.respuesta === 1 ? 'Fallo de stock' : 'Rotura de la pieza'}</small>
                                            <small className="text-uppercase">{problema.piezas.nombre}</small>

                                            <div className="d-flex align-items-center ms-3">
                                                <small className="text-uppercase fw-bold me-2">{problema.piezas.proveedor.nombre}</small>
                                                <small>{problema.piezas.proveedor.telefono}</small>
                                                { (problema.piezas.proveedor.telefono_recambios) &&
                                                    <>
                                                        <small className="mx-2">|</small>
                                                        <small>Recambios: {problema.piezas.proveedor.persona_recambios} {problema.piezas.proveedor.telefono_recambios}</small>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <span className="align-self-end" title="Marcar como leído" onClick={() => onMarkAsRead(problema.id)}>
                            <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#215732" /> 
                        </span>
                    </div>
                  </div>
                )
            })}
        </div>
    );
}