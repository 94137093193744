import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function ValoresTable({valores, onEdit, onDelete}) {

    const handleEdit = (valorId) => { onEdit('valor', valorId); }
    const handleDelete = (valorId) => { onDelete('valor', valorId); }

    return (
        <div className="w-100 d-flex flex-column">
            <div className="w-100 table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="text-center">Min</th>
                            <th className="text-center">Max</th>
                            <th className="text-center">Valor</th>
                            <th className="text-center">Tipo</th>
                            <th className="text-center">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        { valores?.sort((a, b) => a.min - b.min)?.map(valor => {
                              return (
                                  <tr key={'valor-' + valor.id}>
                                      <td className="text-center">{valor.min}</td>
                                      <td className="text-center">{valor.max ? valor.max : '-'}</td>
                                      <td className="text-center">{valor.valor}</td>
                                      <td className="text-center text-uppercase" width="35%">{valor.tipo}</td>
                                      <td className="text-center" width="10%">
                                        <span title="Editar" style={{cursor: 'pointer'}} className="me-3">
                                            <FontAwesomeIcon icon={faPen} size="1x" className="action" color="#215732" onClick={() => handleEdit(valor.id)} />
                                        </span>
                                        <span title="Eliminar" style={{cursor: 'pointer'}}>
                                            <FontAwesomeIcon icon={faTrash} size="1x" className="action" color="#dc3545" onClick={() => handleDelete(valor.id)} />
                                        </span>
                                      </td>
                                  </tr>
                              )
                          })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}