import { getNotificaciones } from "../../views/Dashboard/components/shared/services/notificaciones";
import { asyncMac, makeAsyncTypes, makeFetchingReducer, makeSetReducer, reduceReducers } from "../utils";


const initialState = {
    notificaciones: [],
    archivadas: []
};

export const notificacionReducer = (state = initialState, action) => {
    switch(action.type){
        case "notificaciones/fullfilled": {
            return action.payload;
        }
        default:
            return state
    }
}

const asyncNotificaciones = makeAsyncTypes('notificaciones');

const [setPending, setFullFilled, setError ] = asyncMac(asyncNotificaciones);

export const notificacionesFetchingReducer = makeFetchingReducer([
    'notificaciones/pending', 
    'notificaciones/fullfilled', 
    'notificaciones/rejected'
]);

const fullfilledReducer = makeSetReducer(['notificaciones/fullfilled']);

export const fetchNotificaciones = () => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getNotificaciones()
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const notificacionesReducer = reduceReducers(notificacionReducer, fullfilledReducer);;
export const notificacionesStatusReducer = notificacionesFetchingReducer;