import { asyncMac, makeAsyncTypes, makeFetchingReducer } from "../utils";
import { toast } from 'react-toastify';
import { getAvisos, markAsReadAviso, newAviso, updateAviso } from "../../views/Dashboard/components/shared/services/avisos";
import moment from "moment";

const initialAvisos = [];

export const avisoReducer = (state = initialAvisos, action) => {
    switch(action.type){
        case "avisos-list/fullfilled": {
            if(Array.isArray(action.payload)) {
                return action.payload;
            } else {
                if(action.payload) {
                    const newAvisos = state;
                    let parentIndex = newAvisos.findIndex(fecha => fecha.avisos.find(aviso => parseInt(aviso.id) === parseInt(action.payload.id)));
                    const index = newAvisos.map((fecha) => fecha.avisos.findIndex(aviso => parseInt(aviso.id) === parseInt(action.payload.id)))[0];
                    
                    if(parentIndex === -1 || index === -1) {
                        const newAvisoFecha = moment(action.payload.fecha).format('YYYY-MM-DD');
                        parentIndex = newAvisos.findIndex(fecha => moment(fecha.fecha).format('YYYY-MM-DD') === newAvisoFecha);
    
                        if(parentIndex !== -1) {
                            newAvisos[parentIndex].avisos.push(action.payload);
                        } else {
                            newAvisos.push({fecha: newAvisoFecha, avisos: [action.payload]})
                        }
                    } else {
                        newAvisos[parentIndex].avisos.splice(index, 1, action.payload);
                    }
                    
                   return newAvisos
                } else return state;
            }
        }
        default:
            return state
    }
}

const asyncAvisosList = makeAsyncTypes('avisos-list');

const [setPending, setFullFilled, setError ] = asyncMac(asyncAvisosList);


export const avisosListFetchingReducer = makeFetchingReducer([
    'avisos-list/pending', 
    'avisos-list/fullfilled', 
    'avisos-list/rejected'
]);


export const fetchAvisosList = (start, end) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getAvisos(start, end)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
}

export const saveAviso = (aviso) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await newAviso(aviso)
        const data = await response.data;
        toast.success(response.message);
        dispatch(setFullFilled(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        const err = e.response.data;
        toast.error(err.message)
        dispatch(setError(err.message))
        dispatch(setFullFilled());
    }
}

export const editAviso = (aviso) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await updateAviso(aviso)
        const data = await response.data;
        toast.success(response.message);
        dispatch(setFullFilled(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
}

export const markAsRead = (aviso) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await markAsReadAviso(aviso)
        const data = await response.data;
        toast.success(response.message);
        dispatch(setFullFilled(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
}



export const avisosListReducer = avisoReducer;
export const avisosListStatusReducer = avisosListFetchingReducer;