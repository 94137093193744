import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SearchContainer = ({oemToSearch, setOemToSearch, submitSearch, className=''}) => {
  return (
    <div className={`position-relative ${className}`}>
        <input 
          type="text" 
          className="form-control" 
          placeholder='Introduce OEM a buscar' 
          value={oemToSearch} 
          onChange={(e) => setOemToSearch(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && submitSearch()}  
        />
        <FontAwesomeIcon icon={faSearch} color="#215732" className="icon" style={{cursor: 'pointer'}} onClick={submitSearch} />
    </div>
  )
}

export default SearchContainer
