import { useState, useEffect } from "react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import { toast } from "react-toastify";
import Header from '../shared/components/Header'
import { useDispatch, useSelector } from "react-redux";
import { fetchSolicitudes } from "../../../../redux/solicitudes";
import InfoModal from "../shared/components/InfoModal";
import SolicitudForm from "./components/SolicitudForm";
import { downloadFichaTecnica, renerateSolicitud, saveSolicitud } from "../shared/services/solicitudes";
import moment from 'moment';
import RespuestasList from "./components/RespuestasList";
import Loading from "../shared/components/Loading";
import { createPresupuestoFromSolicitudOffline, downloadProformaDesguaceOffline, sendEmailDesguaceOffline } from "../shared/services/desguaces_offline";
import ClienteModal from "../shared/components/ClienteModal";
import ClienteChangeModal from "../shared/components/ClienteChangeModal";
import { newEntidad } from "../shared/services/clientes";
import EmailForm from "./components/EmailForm";
import ActionsMenu from "../shared/components/ActionsMenu";
import { getActions } from "./actions";

export default function Solicitudes() {
  const dispatch = useDispatch();
  const { page } = useParams();
  const currPage = parseInt(page);
  let navigate = useNavigate();
  const [currentPage, setPage] = useState(currPage);
  const [currentDesguace, setCurrentDesguace] = useState(null);
  const [currentSolicitud, setCurrentSolicitud] = useState(null);
  const [currentRespuesta, setCurrentRespuesta] = useState(null);
  const [newSolicitud, setNewSolicitud] = useState(false);
  const [viewRespuestas, setViewRespuestas] = useState(false);
  const [openSelectCliente, setOpenSelectCliente] = useState(false);
  const [openNewCliente, setOpenNewCliente] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const solicitudes = useSelector(state => {
    return state.solicitudes.entities
  });
  const solicitudesStatus = useSelector(state => {
    return state.solicitudes.status
  });
  const searcher = useSelector(state => {
    return state.search
  })
  
  useEffect(() => {
    dispatch(fetchSolicitudes(currPage, getQuery()));
  }, [searcher]);

  const getQuery = () => {
    const isEmpty = Object.values(searcher).every(x => x === null || x === '');
    let query = !isEmpty ? 'search=' + (searcher?.search ? searcher?.search : '')
      + (searcher?.responsable ? '&responsable=' + searcher?.responsable : '')
    : null;

    return query;
  }

  const handleSearch = (searchData) => {
    setPage(1);
    navigate(`/solicitudes/${1}`);
    dispatch({type: "search/set", payload: searchData});     
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    navigate(`/solicitudes/${newPage}`);
    dispatch(fetchSolicitudes(newPage, getQuery()));
  }

  const getPaginationCount = () => {
    var totalPages = solicitudes.total_items < 15 ? 1 : Math.ceil(solicitudes.total_items / 15);
    return parseInt(totalPages);
  }

  const handleAction = (action, solicitud) => {
    if(action === 'responses') {
      handleViewRespuestas(solicitud)
    } else if(action === 'ficha') {
      handleDownloadFicha(solicitud)
    } else if(action === 'regenerate') {
      handleRegenerateSolicitud(solicitud)
    }
}

  const handleGoTo = (to) => { navigate(to + '/1', { replace: true }) }

  const handleOpenNewSolicitud = () => setNewSolicitud(true);
  const handleCancelNewSolicitud = () => setNewSolicitud(false);
  const handleViewRespuestas = (solicitud) => { setViewRespuestas(true); setCurrentSolicitud(solicitud); }
  const handleCancelViewRespuestas = () => setViewRespuestas(false);

  const handleNewSolicitud = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await saveSolicitud(data);

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      setNewSolicitud(false);
      dispatch(fetchSolicitudes(currPage, getQuery()));
    }
  }

  const handleDownloadFicha = async(solicitud) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await downloadFichaTecnica(solicitud.id)
    .catch((e) => {
      console.error(`Download ficha failed: ${e}`);
    })
    .finally(() => {
      dispatch({type: 'loading/set', payload: false});
    });

    if(response) {
      toast.success('Ficha técnica descargada correctamente!')
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', solicitud.ficha_tecnica); //or any other extension
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }
  }

  const handleOpenSelectCliente = (respuesta) => {
    setCurrentRespuesta(respuesta);
    setOpenSelectCliente(true);
  }
  const handleSelectCliente = (cliente) => { 
    setOpenSelectCliente(false);
    handleNewPresupuesto(cliente); 
  }
  const handleOpenNewCliente = () => setOpenNewCliente(true);
  const handleCloseNewCliente = () => setOpenNewCliente(false);
  const handleCloseSelectCliente = () => setOpenSelectCliente(false);
  const handleNewCliente = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await newEntidad(data);

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      handleNewPresupuesto(response.data.id);
    }
  }

  const handleNewPresupuesto = async(clienteId) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await createPresupuestoFromSolicitudOffline({
      cliente_id: clienteId,
      solicitud_id: currentSolicitud.id, 
      respuesta_id: currentRespuesta.id
    });

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      setCurrentRespuesta(null);
      setOpenSelectCliente(false);
      setOpenNewCliente(false);
      navigate(`/presupuesto/${response.data.id}`, { replace: true, state: { from: 'offline' }});    
    }
  }

  const handleOpenSendEmail = (desguace) => {
    setCurrentDesguace(desguace);
    setSendEmail(true);
  }
  const handleCloseSendEmail = () => {
    setSendEmail(false);
    setCurrentDesguace(null);
  }
  const handlSendEmail = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await sendEmailDesguaceOffline(currentDesguace.id, data);

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      setSendEmail(false);
      setCurrentDesguace(null);
    }
  }

  const handleDownloadProforma = async(proforma) => {
    dispatch({type: 'loading/set', payload: true});
    const pdfResult = await downloadProformaDesguaceOffline({
      proforma: proforma
    });

    if(pdfResult) {
      dispatch({type: 'loading/set', payload: false});
      toast.success('Proforma descargada correctamente!')
      const url = window.URL.createObjectURL(new Blob([pdfResult]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', proforma); //or any other extension
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }
  }

  const handleRegenerateSolicitud = async(solicitud) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await renerateSolicitud(solicitud.id, solicitud.tipo)
    .catch((e) => {
      console.error(`Regenerate solicitud failed: ${e}`);
    })
    .finally(() => {
      dispatch({type: 'loading/set', payload: false});
    });
    
    if(response) {
      toast.success(response.message);
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }
  }

  if(solicitudesStatus.loading === 'pending' || solicitudesStatus.loading === 'idle') {
    return ( <Loading /> );
  }

  if(solicitudesStatus.loading === 'succeed' || solicitudesStatus.loading === 'rejected') {
    const pageCount = getPaginationCount();

    return (
      <div className="content pt-3">
        <Header
          viewSearch={true} 
          onSearch={handleSearch}
          onNew={handleOpenNewSolicitud}
        ></Header>

      <div className="w-100 d-flex flex-column align-items-end content-table">
        <div className="w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between mb-3">
          <div className="flex-grow-1 d-flex align-items-center justify-content-lg-start">
            <div className="legend d-flex align-items-center me-md-3">
              <span className="circle" style={{background: '#00986C'}}></span>
              <p>Pieza disponible</p>
            </div>

            <div className="legend d-flex align-items-center">
              <span className="circle" style={{background: '#ffc107'}}></span>
              <p>En espera de respuesta</p>
            </div>
          </div>

          <button type="button" className="btn btn-default me-3" onClick={() => handleGoTo('/reconstruidos')}>RECONSTRUIDOS</button>
          <button type="button" className="btn btn-default" onClick={() => handleGoTo('/desguaces-offline')}>DESGUACES OFFLINE</button>
        </div>

        <div className="w-100 d-flex flex-column align-items-end content-table">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th>numero</th>
                  <th className="text-center">telefono</th>
                  <th className="text-center">tipo</th>
                  <th className="text-center">fecha</th>
                  <th className="text-center">ref</th>
                  <th className="text-center">bastidor</th>
                  <th className="text-center">marca</th>
                  <th className="text-center">modelo</th>
                  <th className="text-center">tipo pieza</th>
                  <th  className="text-center">resp</th>
                  <th className="text-center"></th>
                </tr>
              </thead>

              { solicitudes.solicitudes?.map((solicitud, index) => {
                    const modelosValues = Object.values(solicitud.marcas)
                    const modelosRows = modelosValues.map((modelo, i) => {
                      const respuesta = i === 0 ? <td rowSpan={modelosValues.length + 1} width="2%">
                        <span title={solicitud.respuestas_count === 0 ? 'En espera' : 'Respondida'} className="d-block circle mt-1" style={{backgroundColor: solicitud.respuestas_count === 0 ? '#ffc107' : '#00986C'}}></span>
                      </td> : null
                      const numero = i === 0 ? <td rowSpan={modelosValues.length + 1} width="10%">
                        <span>{solicitud.numero}</span>
                      </td> : null
                      const telefono = i === 0 ? <td rowSpan={modelosValues.length + 1} width="10%" className="text-center">
                        <span>{solicitud.telefono}</span>
                      </td> : null
                      const tipo = i === 0 ?  <td rowSpan={modelosValues.length + 1} width="12%"  className="text-center text-uppercase">
                        {solicitud.tipo}
                      </td> : null
                      const fecha = i === 0 ?  <td rowSpan={modelosValues.length + 1} width="8%" className="text-center">{moment(solicitud.fecha).format("DD-MM-YYYY")}</td> : null
                      const referencia = i === 0 ? 
                        <td rowSpan={modelosValues.length + 1}  width="12%" className="text-center">
                            {solicitud.referencia_pieza} 
                        </td> 
                      : null
                      const bastidor = i === 0 ?  <td rowSpan={modelosValues.length + 1} width="12%"  className="text-center">
                        {solicitud.bastidor}
                      </td> : null
                      const tipoPieza = i === 0 ? 
                        <td rowSpan={modelosValues.length + 1}  width="12%" className="text-center text-uppercase">
                            {solicitud.tipo_pieza.nombre} 
                        </td> 
                      : null
                      const responsable = i === 0 ?  <td rowSpan={modelosValues.length + 1}  className="text-center text-uppercase">{solicitud.created_by?.username}</td> : null
                      const acciones = i === 0 ?  <td rowSpan={modelosValues.length + 1} className="text-center">
                        <ActionsMenu 
                            options={getActions()}
                            onAction={(action) => handleAction(action, solicitud)}
                        />
                      </td> : null
                          
                      return (
                        <tr key={i}>
                          {respuesta}
                          {numero}
                          {telefono}
                          {tipo}
                          {fecha}
                          {referencia}
                          {bastidor}
                          <td className="text-center">{modelo.marca.nombre}</td>
                          <td className="text-center">{modelo.modelo.nombre}</td>
                          {tipoPieza}
                          {responsable}
                          {acciones}
                        </tr>
                      )
                    })
                    return (
                      <tbody key={index}>
                        {modelosRows}
                      </tbody>
                    )
                  }) 
                }
            </table>
          </div>

            <Pagination 
                className="mt-3" 
                count={pageCount} 
                page={currentPage} 
                onChange={handleChangePage} 
            />

            { newSolicitud && 
                <InfoModal
                    state={newSolicitud}
                    title="Nueva solicitud"
                    content={
                        <SolicitudForm 
                            onSubmitSolicitud={handleNewSolicitud}
                        />
                    }
                    width={'md'}
                    onClose={handleCancelNewSolicitud}
                ></InfoModal>
            }

            { sendEmail && 
                <InfoModal
                    state={sendEmail}
                    title="Nueva solicitud"
                    content={
                        <EmailForm 
                          onSubmitEmail={handlSendEmail}
                        />
                    }
                    width={'md'}
                    onClose={handleCloseSendEmail}
                ></InfoModal>
            }

            { viewRespuestas && 
              <InfoModal
                  state={viewRespuestas}
                  title={"Respuestas de la solicitud " + currentSolicitud.numero}
                  content={
                      <RespuestasList 
                        solicitud={currentSolicitud}
                        onNewPresupuesto={handleOpenSelectCliente}
                        onSendEmailDesguaceOffline={handleOpenSendEmail}
                        onDownloadProforma={handleDownloadProforma}
                      />
                  }
                  width={'lg'}
                  onClose={handleCancelViewRespuestas}
              ></InfoModal>
            }

            { (openNewCliente) &&
                <ClienteModal
                    fromPresupuesto={false} 
                    open={openNewCliente}
                    isEdit={false}
                    onSubmitCliente={handleNewCliente}
                    onClose={handleCloseNewCliente}
                />
            }

            { (openSelectCliente) &&
                <ClienteChangeModal 
                    fromPresupuesto={false}
                    open={openSelectCliente}
                    title={"Seleccionar cliente"}
                    presupuestoClient={null}
                    onClienteChange={handleSelectCliente}
                    onNewCliente={handleOpenNewCliente}
                    onClose={handleCloseSelectCliente}
                />
            }
        </div>
      </div>
    </div>
    )
  }
}