import { useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";

export default function MessageForm({
    checkoutId,
    onSubmitMessage,
}) {
    const defaultMessage = `¿Nos puedes mandar el justificante descargando el comprobante de la operación en tu banca digital? 
                 
    Si no es posible, tendremos que esperar a que se refleje el dinero en el banco para poder enviar la pieza y se generará un retraso en la entrega del pedido.`;

    const { 
        register, 
        formState: { errors }, 
        setValue,
        handleSubmit,  
        control } = useForm({
            defaultValues: {
                checkout_id: checkoutId,
                message: ''
            }
    });

    useEffect(() => {
        setValue('message', defaultMessage);
    }, []);

    const onSubmit = async (data, e) => {
        e.preventDefault();
        onSubmitMessage(data);
    };

    return (
        <div className="w-100">
            <div className="w-100 d-flex flex-wrap flex-md-nowrap">
                <form className="form col-12 mb-4" onSubmit={handleSubmit(onSubmit)}>
                    <input 
                        type="hidden" 
                        {...register("checkout_id")} 
                        defaultValue={checkoutId} />

                    <div className="w-100">
                        <div className="form-group">
                            <label htmlFor="message">Mensaje para el cliente:</label>
                            <textarea 
                                className="form-control" 
                                rows={5}
                                {...register("message", { required: true })} 
                                defaultValue={''} ></textarea>
                        </div>

                        <div className="form-invalid">
                            {errors.message && 'El mensaje es obligatorio'}
                        </div>
                    </div>

                    <div className="mt-5">
                        <button type="submit" className="btn btn-primary">Enviar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}