import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { newAvisoTipoPieza, updateAvisoTipoPieza } from "../../../../../shared/services/avisosTipoPiezas";
import { Switch } from "@mui/material";

export default function AvisoForm({
    tipoPieza,
    aviso,
    onActionDone
}) {
    const dispatch = useDispatch();
    const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm({
        defaultValues: {
            id: 0,
            mensaje: '',
        }
    });

    useEffect(() => {
        if(aviso) {
            reset(aviso);
        }
    }, [aviso]);

    const handleSubmitAvisoTipoPieza = async(data, e) => {
        e.preventDefault();
        data.tipo_pieza_id = tipoPieza.id;
        dispatch({type: 'loading/set', payload: true});
        let response;

        if(aviso) {
            if(data.activo !== aviso.activo) {
                delete data.mensaje;
            }

            response = await updateAvisoTipoPieza(data);
        } else {
            response = await newAvisoTipoPieza(data);
        }

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            onActionDone();
        }
    }
    
    return (
        <div className="w-100 d-flex flex-column mt-4">
            <form className="form align-items-center mb-4" onSubmit={handleSubmit(handleSubmitAvisoTipoPieza)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={aviso ? aviso.id : ''} />

                    <div className="form-group flex-grow-1">
                        <label htmlFor="mensaje">Mensaje</label>
                        <textarea
                            {...register("mensaje", {required: true})}
                            defaultValue={aviso ? aviso.mensaje : ""}
                            rows={3}
                        ></textarea>

                        <div className="form-invalid">
                            {errors.mensaje?.type === 'required' && "El mensaje es obligatorio"}
                        </div>
                    </div>

                    { aviso && 
                        <div className="w-100 d-flex align-items-center">
                            <Switch 
                                color="success"
                                defaultChecked={aviso?.activo}
                                onChange={(e) => {
                                    setValue('activo', e.target.checked);
                                }}
                                inputProps={{ 'aria-label': 'controlled' }} /> 
                            <label>Activo</label>
                        </div>
                    }

                <div className="d-flex align-items-center align-self-end ms-3 mt-4">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    )
}