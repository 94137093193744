import React from "react";

export default function ClienteMetrics({
    cliente,
}) {

    const getRatioConversion = () => parseFloat(((cliente?.ventas?.length * 100) / cliente?.presupuestos?.length)).toFixed(2);
    const getRatioIncidencias = () => parseFloat(((cliente?.incidencias?.length * 100) / cliente?.ventas?.length)).toFixed(2);
    const getRatioDevoluciones = () => parseFloat(((cliente?.devoluciones?.length * 100) / cliente?.ventas?.length)).toFixed(2);

    return (
        <div className="flex-grow-1 d-flex flex-column align-items-center justify-content-between">
           <table className="w-100">
                <tbody>
                    <tr>
                        <td>
                            <div className="d-flex flex-column align-items-center pb-2">
                                <label>Presupuestos</label>
                                <small>{cliente?.presupuestos?.length}</small>
                            </div>
                        </td>

                        <td>
                            <div className="d-flex flex-column align-items-center pb-2">
                                <label>Compras</label>
                                <small>{cliente?.ventas?.length}</small>
                            </div>
                        </td>

                        <td>
                            <div className="d-flex flex-column align-items-center pb-2">
                                <label>Devoluciones</label>
                                <small>{cliente?.devoluciones?.length}</small>
                            </div>
                        </td>

                        <td>
                            <div className="d-flex flex-column align-items-center pb-2">
                                <label>Incidencias</label>
                                <small>{cliente?.incidencias?.length}</small>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div className="d-flex flex-column align-items-center">
                                <label>Ratio de conversion</label>
                                <small>{getRatioConversion()}%</small>
                            </div>
                        </td>

                        <td>
                            <div className="d-flex flex-column align-items-center">
                                <label>Ratio de devoluciones</label>
                                <small>{getRatioDevoluciones()}%</small>
                            </div>
                        </td>

                        <td>
                            <div className="d-flex flex-column align-items-center">
                                <label>Ratio de incidencias</label>
                                <small>{getRatioIncidencias()}%</small>
                            </div>
                        </td>

                        <td>
                            <div className="d-flex flex-column align-items-center">
                                <label>Número de vales</label>
                                <small>{cliente?.vales_descuentos?.length}</small>
                            </div>
                        </td>
                    </tr>
                </tbody>
           </table>
        </div>
    )
}