import { useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

export default function NotaCargoForm({
    venta,
    onSubmitNota, 
}) {
    const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm({
        defaultValues: {
            piezas: [],
            concepto: '',
            iva: true
        }
    });

    useEffect(() => {   
       if(venta) {
        setValue('concepto', 'Incidencia de transporte')
       }
    }, [venta]);

    const handleSubmitNota = async (data, e) => {
        e.preventDefault();
        let notas = [];
           
        if(data.piezas && data.piezas.length > 0) {
            data.piezas.forEach((piezaId) => {
                const pieza = venta.piezas.find(p => p.id === parseInt(piezaId));

                let notaAux = {
                    'concepto': data.concepto,
                    'venta_id': parseInt(venta.id),
                    'pieza_id': piezaId,
                    'transportista_id': pieza.servicio_envio.transportista_id,
                    'with_iva': data.iva ? true : false
                };
    
                notas.push(notaAux);
            })

            onSubmitNota(notas);
        } else {
            toast.warning('Tienes que elegir al menos una pieza!!');
            return;
        }
    };
 
    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            <form className="form mb-4" onSubmit={handleSubmit(handleSubmitNota)}>
                <div className="w-100 d-flex flex-column">
                    <div className="form-group d-flex flex-row align-items-center">
                        <label className="m-0 me-2" htmlFor="concepto">IVA: </label>
                        <input
                            type="checkbox"
                            {...register("iva")}
                            defaultChecked={true}
                        />
                        <small className="ms-2">{watch('iva') ? 'SI' : 'NO'}</small>
                    </div>

                    <div className="form-group">
                        <label htmlFor="concepto">Concepto: </label>
                        <input
                            type="text"
                            className="form-control"
                            {...register("concepto", { required: true })}
                            defaultValue={""}
                        />

                        <div className="form-invalid">
                            {errors.concepto && "El concepto es obligatorio"}
                        </div>
                    </div>

                    <div className="w-100">
                        <div className="w-100 d-flex align-items-center justify-content-between mb-2">
                            <label>Piezas:</label>
                            <small className="ms-2">
                                <FontAwesomeIcon icon={faInfoCircle} size="1x" className="me-2" color="#62B1F6" />
                                Por cada pieza seleccionada, se generará una nueva nota de cargo.
                            </small>
                        </div>

                        <div className="w-100 table-responsive">
                            <table className="table">
                                <tbody>
                                    { venta?.piezas.map((pieza, i) => {
                                            return (
                                                <tr key={'pieza-'+ pieza.id}>
                                                    <td>
                                                        <input type="checkbox"
                                                                name={`piezas[]`}
                                                                {...register(`piezas[]`)}
                                                                value={pieza.id}
                                                                className="mt-2" />
                                                    </td>

                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <FontAwesomeIcon icon={faCogs} size="1x" color="#215732"></FontAwesomeIcon>
                                                            
                                                            <div className="d-flex flex-column">
                                                                <p className="ms-3">{pieza.nombre}</p>
                                                                { pieza.observaciones ? <p className="ms-3"> <b>Observaciones:</b>{pieza.observaciones}</p> : ''}
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        {pieza.precio_compra} €
                                                        {pieza?.venta_relationship?.unidades > 1 ? 
                                                            <strong>{'x'+pieza?.venta_relationship?.unidades}</strong> : '' 
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end mt-3">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    );
}