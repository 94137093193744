import { useEffect, useState } from "react";
import React from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import InfoModal from "../../../../../shared/components/InfoModal";
import { newModelo, updateModelo } from "../../../../../shared/services/modelos";
import ModeloForm from "./components/ModeloForm";

export default function Modelos() {
    const [modelosList, setModelosList] = useState([]);
    const [search, setSearch] = useState(null);
    const [filterMarca, setFilterMarca] = useState(null);
    const [currentModelo, setCurrentModelo] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const marcas = useSelector(state => {
        const { marcas } = state;
        return marcas;
    });
    const modelos = useSelector(state => {
        const { modelos } = state;
        return modelos;
    });

    useEffect(() => {
        setModelosList(modelos);
    }, []);

    useEffect(() => {
        setModelosList(modelos);
    }, [modelos]);

    const getMarca = (marcaId) => marcas?.find(m => m.id === marcaId)

    const handleOpenForm = (modelo = null) => {
        setCurrentModelo(modelo);
        setOpenForm(true);
    }

    const handleCloseForm = () => { 
        setOpenForm(false);
        setCurrentModelo(null);
    }

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearch(value);

        if(value === "") {
            if(filterMarca) {
                const newModelos = modelos.filter(m => m.marca_id === filterMarca);
                setModelosList(newModelos);
            } else setModelosList(modelos);
        } else {
            if(filterMarca) {
                const newModelos = modelos.filter(m => m.nombre?.toUpperCase().includes(value.toUpperCase()) && m.marca_id === filterMarca);
                setModelosList(newModelos);
            } else {
                const newModelos = modelos.filter(m => m.nombre?.toUpperCase().includes(value.toUpperCase()));
                setModelosList(newModelos);
            }
        }
    }

    const handleChangeMarca = (e) => {
        const value = e.target.value === "" ? e.target.value : parseInt(e.target.value);
        setFilterMarca(value);

        if(value === "") {
            if(search) {
                const newModelos = modelos.filter(m => m.nombre?.toUpperCase().includes(search.toUpperCase()));
                setModelosList(newModelos);
            } else setModelosList(modelos);
        } else {
            if(search) {
                const newModelos = modelos.filter(m => m.nombre?.toUpperCase().includes(search.toUpperCase()) && m.marca_id === value);
                setModelosList(newModelos);
            } else {
                const newModelos = modelos.filter(m => m.marca_id === value);
                setModelosList(newModelos);
            }
        }
    }

    const handleSubmitModelo = async(data) => {
        let response;

        if(currentModelo) {
            response = await updateModelo(data);
        } else response = await newModelo(data);

        if(response && response.success) {
            toast.success(response.message);
            handleCloseForm();
        }
    }

    return (
        <div className="w-100 d-flex flex-column align-items-center"> 
            <div className="w-100 d-flex align-items-center justify-content-between mb-4">
                <div className="flex-grow-1 d-flex align-items-center ">
                    <div className="position-relative">
                        <input 
                            type="text" 
                            className="form-control" 
                            defaultValue={search} 
                            id="filterBy" 
                            placeholder="Busca un modelo..."
                            onChange={(e) => handleSearch(e)} 
                        />
                        <FontAwesomeIcon icon={faSearch} color="#215732" className="icon" />
                    </div>

                    <div className="ms-3">
                        <select 
                            id="marca_id"
                            defaultValue={filterMarca}
                            onChange={handleChangeMarca}>
                            <option key={'marcas-0'} value="">Selecciona una marca</option>
                            {marcas.map(op => <option key={'marcas-' + op.id} value={op.id}>{op.nombre}</option>)}
                        </select>
                    </div>
                </div>

                <button className="btn btn-add" onClick={() => handleOpenForm()}>
                    <span className="add">
                        <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                    </span>
                    Nuevo
                </button>
            </div>

            <div className="w-100 table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Marca</th>
                            <th className="text-center">acciones</th>
                        </tr>
                    </thead>

                    <tbody>
                        
                        { modelosList?.length > 0 ? modelosList?.map((modelo) => {
                                return (
                                    <tr key={'marca-' + modelo.id}>
                                        <td>{modelo.nombre}</td>
                                        <td>{getMarca(modelo.marca_id)?.nombre}</td>
                                        <td width={'15%'} className="text-center">
                                            <span title="Editar" style={{cursor: 'pointer'}}>
                                                <FontAwesomeIcon icon={faPen} size="1x" className="action" color="#215732" onClick={() => handleOpenForm(modelo)} />
                                            </span>
                                        </td>
                                    </tr>
                                )
                            })
                            : 
                            <tr>
                                <td colSpan={3} className="text-center">No hay resultados</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>

            { (openForm) && 
                <InfoModal
                    state={openForm}
                    title={(currentModelo ? 'Editar' : 'Nueva') + ' modelo'}
                    content={
                        <ModeloForm 
                            marca={currentModelo}
                            onSubmitModelo={handleSubmitModelo}
                        />
                    }
                    width={'sm'}
                    onClose={handleCloseForm}
                ></InfoModal>
            }
        </div>
    )
    
}