import React from "react";
import { useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faInfoCircle, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";


export default function ProveedorSelectorDialog({
  open,
  data, 
  proveedor,
  onConfirmAction, 
}) {

  useEffect(() => { }, [open, data, proveedor]);

  const handleClose = (event, reason) => { 
    if (reason && reason === "backdropClick") return;
    onConfirmAction(null); 
  };
  const handleSelectProveedor = (prov, isNew) => { onConfirmAction(prov, isNew); }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={'md'}
      aria-labelledby="pieza-selector-title"
      aria-describedby="pieza-selector-description">
      <DialogTitle id="pieza-selector-title">
          <div className="w-100 d-flex align-items-center justify-content-between">
              <p className="fw-bold text-uppercase m-0">Hemos encontrado proveedores con un nombre similar a {proveedor?.nombre}</p>

              <span title="cerrar" className="action" onClick={() => handleClose()}>
                  <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#215732" />
              </span>
          </div>
      </DialogTitle>
      <DialogContent>
          <div className="w-100 d-flex align-items-center justify-content-between">
            <p className="m-0">
              <FontAwesomeIcon icon={faInfoCircle} size="1x" className="action" color="#62B1F6" />
              <small className="mx-2">Si no es uno de estos, haz click en crear nuevo</small>
              <FontAwesomeIcon icon={faInfoCircle} size="1x" className="action" color="#62B1F6" />
            </p>

            <button type="button" className="btn btn-add mb-3"  onClick={() => {handleSelectProveedor(proveedor, true)}}>
              <span className="add">
                  <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
              </span>
              Crear nuevo
            </button>
          </div>

          <div className="w-100 mt-4">
            <h1>Similares:</h1>

            <div className="w-100 table-responsive">
              <table className="table">
                <tbody>
                  { data && data.map((prov, index) => 
                    <tr key={`${index}${prov.nombre}`}>
                      <td>
                        <div className="w-100 d-flex align-items-center justify-content-between">
                          <div className={"w-100 d-flex flex-column flex-grow-1" + (prov.bloqueado ? ' disabled-option' : '')}>
                            <p className="fw-bold text-uppercase m-0">
                              {prov.bloqueado ? 
                                <FontAwesomeIcon icon={faBan} size="1x" className="action" color="#dc3545" />
                                : ''
                              }
                              {prov.nombre}
                            </p>
                            <small>{prov.direccion} {prov?.poblacion?.nombre}</small>
                          </div>

                          <button 
                            type="button" 
                            className="btn btn-primary btn-sm" 
                            onClick={() => handleSelectProveedor(prov, false)}
                            disabled={prov.bloqueado}
                          >SELECCIONAR</button>
                        </div> 
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
      </DialogContent>
  </Dialog>
  );
}