import API from '../../../../../services/api';

export const getDetallesEntidades = async (page = 1, search = null) => {
    const pagination = page !== null ? '?page=' + page : '';
    const searcher = search !== null && page !== null ? '&' + search : search !== null ? '?' + search : '';
    return await API.get('/detalle-comercial' + pagination + searcher).then(res => res.data);
}

export const newProximoContacto = async (contacto) => {
    return API.post('/detalle-comercial/'+ contacto.entity_id +'/proximo-contacto', contacto).then(res => res.data);
}

export const updateObservaciones = async (observaciones) => {
    return API.post('/detalle-comercial/'+ observaciones.entity_id +'/observaciones', observaciones).then(res => res.data);
}

export const updateDetalleComercial = async (detalle) => {
    return API.put('/detalle-comercial/'+ detalle.id, detalle).then(res => res.data);
}

/****** CONTACTOS ******/

export const newContacto = async (entityId, contacto) => {
    return API.post('/detalle-comercial/' + entityId + '/contacto', contacto).then(res => res.data);
}

export const updateContacto = async (contacto) => {
    return API.put('/detalle-comercial/'+ contacto.id +'/update-contacto', contacto).then(res => res.data);
}

/****** IMPORTS & EXPORTS ******/

export const exportClientesUltimasComprasExcel = async () => {
    return API.post('/detalle-comercial/export/detalles', null, {responseType: 'blob'}).then(res => res.data);
}

export const exportConversionEntidadesExcel = async (filters) => {
    return API.post('/detalle-comercial/export/conversion', filters, {responseType: 'blob'}).then(res => res.data);
}

export const comprobarTelefonos = async (data) => {
    return API.post('/detalle-comercial/export/comprobar', data, {responseType: 'blob'}).then(res => res.data);
}