import { useState, useEffect } from "react";
import React from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import CarroceriaPesadaForm from "./components/CarroceriaPesadaForm";
import InfoModal from "../../../shared/components/InfoModal";

export default function CarroceriasPesadas() {
  /** Variables **/
  const [carroceriasPesadasList, setCarroceriasPesadasList] = useState(null);
  const [currentCarroceriaPesada, setCurrentCarroceriaPesada] = useState(null);
  const [search, setSearch] = useState("");
  const [openForm, setOpenForm] = useState(false);

  /** Redux **/
  const carroceriasPesadas = useSelector((state) => {
    const { carrocerias_pesadas } = state;
    return carrocerias_pesadas;
  });

  /** Lifecycle **/
  useEffect(() => {
    setCarroceriasPesadasList(carroceriasPesadas);
  }, [carroceriasPesadas]);

  /** Methods **/
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase().trim();
    setSearch(value);
    setCarroceriasPesadasList(
      carroceriasPesadas.filter(
        (carroceriaPesada) => !value || carroceriaPesada.tipo_pieza?.nombre.toLowerCase().includes(value)
      )
    );
  };

  const handleOpenForm = (caroceriaPesada = null) => {
    setCurrentCarroceriaPesada(caroceriaPesada);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setCurrentCarroceriaPesada(null);
  };

  /** Render **/
  return (
    <div className="w-100 d-flex flex-column align-items-start">
      <div className="w-100 d-flex justify-content-center">
        <div className="col-12 col-md-8 d-flex flex-column align-items-center order-1 order-md-0">
          <div className="w-100 d-flex align-content-center justify-content-between mb-4">
            <div className="flex-grow-1 d-flex align-items-center ">
              <div className="position-relative w-50">
                <input
                  type="text"
                  name="searcher"
                  className="form-control"
                  value={search}
                  placeholder="Busca una carrocería pesada..."
                  onChange={(e) => handleSearch(e)}
                />
                <FontAwesomeIcon
                  icon={faSearch}
                  color="#215732"
                  className="icon"
                />
              </div>
            </div>

            <button
              type="button"
              className="btn btn-add"
              onClick={() => handleOpenForm()}
            >
              <span className="add">
                <FontAwesomeIcon
                  icon={faPlus}
                  size="1x"
                  className="action"
                  color="#215732"
                />
              </span>
              Nuevo
            </button>
          </div>

          <div className="w-100 table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th className="text-center" style={{ width: "100px" }}>
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody>
                {carroceriasPesadasList?.map((carroceria) => {
                  return (
                    <tr key={"valor-" + carroceria.id}>
                      <td>{carroceria.tipo_pieza?.nombre}</td>
                      <td className="text-center">
                        <span
                          title="Editar"
                          className="me-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOpenForm(carroceria)}
                        >
                          <FontAwesomeIcon
                            icon={faPen}
                            size="1x"
                            className="action"
                            color="#215732"
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {!carroceriasPesadasList?.length && (
              <p className="text-center">Lista de carrocerias pesadas vacia</p>
            )}
          </div>
        </div>

        {openForm && (
          <InfoModal
            state={openForm}
            title={
              (currentCarroceriaPesada ? "Editar" : "Nueva") +
              " carrocería pesada"
            }
            content={
              <CarroceriaPesadaForm
                carroceriaPesada={currentCarroceriaPesada}
                onActionDone={handleCloseForm}
              />
            }
            width={"sm"}
            onClose={handleCloseForm}
          />
        )}
      </div>
    </div>
  );
}
