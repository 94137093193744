module.exports = {
    app: {
        version: `${process.env.REACT_APP_VERSION || 'dev'}`,
        originUrl: process.env.REACT_APP_ORIGIN
    },
    recomotor: {
        crmApiUrl: `${process.env.REACT_APP_API_BASE}/api/`,
        storageUrl: `${process.env.REACT_APP_API_BASE}/storage/`,
        stockApiUrl: `${process.env.REACT_APP_STOCK_API}/recomotor-stock-rest/`,
        ftpApiUrl: `${process.env.REACT_APP_STOCK_API}/recomotor-images-rest/`,
        clientsUrl: `${process.env.REACT_APP_CLIENTES_URL}/`,
        cdnUrl: `${process.env.REACT_APP_RECOMOTOR_CDN_URL}/`,
        scraperUrl: `${process.env.REACT_APP_SCRAPER_REST_URL}`,
        tecdocUrl: process.env.REACT_APP_TECDOC,
        landingUrl: process.env.REACT_APP_LANDING_URL
    },
    reverb: {
        appId: process.env.REVERB_APP_ID,
        appKey: process.env.REACT_APP_REVERB_APP_KEY,
        host: process.env.REACT_APP_REVERB_HOST,
        port: process.env.REACT_APP_REVERB_PORT || 8080,
        scheme: process.env.REACT_APP_REVERB_SCHEME || 'http'
    },
    googlePlaces: {
        apiKey: process.env.REACT_APP_PLACES_API_KEY
    },
    i18n: {
        debug: false
    }
}