import { getDesguacesOffline } from "../views/Dashboard/components/shared/services/desguaces_offline";
import { asyncMac, makeAsyncTypes, makeFetchingReducer, makeSetReducer } from "./utils";


const asyncDesguacesOffline = makeAsyncTypes('desguaces-offline');

const [setPending, setFullFilled, setError ] = asyncMac(asyncDesguacesOffline);

export const desguacesOfflineFetchingReducer = makeFetchingReducer([
    'desguaces-offline/pending', 
    'desguaces-offline/fullfilled', 
    'desguaces-offline/rejected'
]);

const fullfilledReducer = makeSetReducer(['desguaces-offline/fullfilled']);

export const fetchDesguacesOffline = (page, search) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getDesguacesOffline(page, search)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const desguacesOfflineReducer = fullfilledReducer;
export const desguacesOfflineStatusReducer = desguacesOfflineFetchingReducer;