import { useState, useEffect } from "react";
import React from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Tarifas from "./components/Tarifas";
import Excels from "./components/Excels";
import Steps from "./components/Steps";
import TipoPiezas from "./components/TipoPiezas";
import Transportistas from "./components/Transportistas";
import MensajesRecambistas from "./components/MensajesRecambistas";
import MarcasModelos from "./components/MarcasModelos";
import Variables from "./components/Variables";
import CarroceriasPesadas from "./components/CarroceriasPesadas";

export default function Ajustes() {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const [tab, setTab] = useState('mensajes');

    useEffect(() => {
        
    }, []);

    const isEnabled = (section) => {
        if(section === 'marcas-modelos') {
            return currentUser.rol_id === 1 || currentUser.responsable
        } else return currentUser.rol_id === 1 || (currentUser.rol_id === 5 && currentUser.responsable)
    }

    const handleChangeTab = (e, tab) => setTab(tab);
  
    return (
        <div className="content ajustes">
            <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChangeTab} aria-label="Ajustes">
                    <Tab label="Mensajes Recambistas" value="mensajes" />
                    { (isEnabled('variables')) ? <Tab label="Variables" value="variables" /> : ''}
                    { (isEnabled('transportistas')) ? <Tab label="Transportistas" value="transportistas" disabled={false} /> : '' }
                    <Tab label="Tipo piezas" value="tipo_piezas" />
                    <Tab label="Pasos incidéncia" value="steps" />
                    { (isEnabled('marcas-modelos')) ? <Tab label="Marcas y Modelos" value="marcas-modelos" /> : ''}
                    <Tab label="Carrocerías pesadas" value="carrocerias_pesadas" />
                    { (isEnabled('tarifas')) ?  <Tab label="Tarifas" value="tarifas" /> : '' }
                    { currentUser.rol_id === 1 ? <Tab label="Importación" value="importacion" disabled={false} /> : '' }
                </TabList>
                </Box>
                <TabPanel value="mensajes">
                    <MensajesRecambistas />
                </TabPanel>
                <TabPanel value="variables">
                    <Variables />
                </TabPanel>
                <TabPanel value="tipo_piezas">
                    <TipoPiezas />
                </TabPanel>
                <TabPanel value="steps">
                    <Steps />
                </TabPanel>
                <TabPanel value="tarifas">
                    <Tarifas />
                </TabPanel>
                <TabPanel value="carrocerias_pesadas">
                    <CarroceriasPesadas />
                </TabPanel>
                <TabPanel value="transportistas">
                    <Transportistas />
                </TabPanel>
                <TabPanel value="importacion">
                    <Excels />
                </TabPanel>
                <TabPanel value="marcas-modelos">
                    <MarcasModelos />
                </TabPanel>
            </TabContext>
        </div>
    )
}