import { useState } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faPortrait, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { CustomTooltip } from "../../../../../../../shared/helpers/functionalities";
import InfoModal from "../../../../../../../shared/components/InfoModal";
import ProveedorPersonasInfo from "./ProveedorPersonasInfo";

export default function ProveedorInfo({
    proveedor,
}) {
    const [openInfo, setOpenInfo] = useState(false);

    const handleOpenInfo = () => { setOpenInfo(true); }
    const handleCloseInfo = () => { setOpenInfo(false); }

    return (
        <div className="w-100 d-flex flex-wrap flex-lg-nowrap align-items-stretch justify-content-between">
            <div className="d-flex align-items-start">
                <div className="d-flex flex-column me-3">
                    <span className="icon">
                        <FontAwesomeIcon icon={faPortrait} size="1x" color="#215732" />
                    </span>
                </div>
            

                <div className="d-flex flex-column me-3">
                    <div className="d-flex flex-wrap align-items-center mb-1">
                        <h3 className="text-success"> {proveedor?.nombre}</h3>
                        
                        { (proveedor.credito) ?
                            <CustomTooltip
                                title={
                                    <React.Fragment>
                                        <small className="m-0">Atención! Proveedor a crédito.</small>
                                    </React.Fragment>
                                }
                            >
                                <span className="align-self-start ms-2" style={{cursor: 'pointer'}}>
                                    <FontAwesomeIcon icon={faExclamationCircle} size="lg" className="action" color="#ffc107" /> 
                                </span>
                            </CustomTooltip>
                            : '' 
                        }

                        { (proveedor.integracion) ?
                            <CustomTooltip
                                title={
                                    <React.Fragment>
                                        <small className="m-0">Atención! Proveedor con integración.</small>
                                    </React.Fragment>
                                }
                            >
                                <span className={"align-self-start " + (proveedor.credito) ? 'ms-1' : ''} style={{cursor: 'pointer'}}>
                                    <FontAwesomeIcon icon={faExclamationCircle} size="lg" className="action" color="#dc3545" /> 
                                </span>
                            </CustomTooltip>
                            : '' 
                        }
                    </div>
                    <p className="m-0 text-uppercase">CIF: {proveedor?.cif ? proveedor?.cif : '-'}</p>
                    <p>Teléfono: {proveedor.telefono}</p>

                    <div className="d-flex flex-wrap">
                        { proveedor?.credito ?
                            <small className="fw-bold text-uppercase">A crédito</small>
                            : ''
                        }
                        { proveedor?.credito && proveedor?.integracion ? <p className="mx-2">|</p> : ''}
                        { proveedor?.integracion ?
                            <small className={"fw-bold text-uppercase " + (!proveedor?.credito ? 'ms-2' : '')}>Integrado</small>
                            : ''
                        }
                    </div>

                    <small className={"fw-bold " + (proveedor?.score >= 7 ? 'text-green' : (proveedor?.score >= 6.5 || proveedor?.score === null) ? 'text-warning' : 'text-danger')}>
                        SCORE: {proveedor.score ? proveedor?.score : '-'}
                    </small>
                </div>

                <div className="d-flex flex-column">
                    <label>Dirección:</label>
                    <p>{proveedor?.direccion}</p>
                    <p>{(proveedor.codigo_postal + ' ' + proveedor.poblacion + ", " + proveedor.area)}, {proveedor.pais_id ? proveedor.pais.nombre : ''}</p>
                </div>
            </div>

            <div className="d-flex flex-column">
                <span className="align-self-start ms-2" style={{cursor: 'pointer'}} onClick={() => handleOpenInfo()}>
                    <FontAwesomeIcon icon={faUserAlt} size="lg" className="action" color="#215732" /> 
                </span>
            </div>

            { (openInfo) && 
                <InfoModal
                    state={openInfo}
                    title={'Información Proveedor'}
                    content={
                        <ProveedorPersonasInfo 
                            proveedor={proveedor}
                        />
                    }
                    width={'sm'}
                    onClose={handleCloseInfo}
                ></InfoModal>
            }
        </div>
    )
}