// LOAD ALL THE READ ONLY DATA
import { getModelos } from "../views/Dashboard/components/shared/services/modelos";
import { makeFetchingReducer, makeAsyncTypes, asyncMac, makeSetReducer } from "./utils";

const asyncModelos = makeAsyncTypes('modelos');

const [setPending, setFullFilled, setError ] = asyncMac(asyncModelos);

export const modelosFullfilledReducer = makeSetReducer(['modelos/fullfilled']);

export const fetchingReducer = makeFetchingReducer([
    'modelos/pending', 
    'modelos/fullfilled', 
    'modelos/rejected'
]);

export const fetchModelos = () => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getModelos()
        const data = await response.data;
        dispatch(setFullFilled(data));
        const readOnly = JSON.parse(localStorage.getItem('read_only'));
        readOnly.modelos = data;
        localStorage.setItem('read_only', JSON.stringify(readOnly));
    } catch (e) {
        dispatch(setError(e.message))
    }
}