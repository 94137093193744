import { useEffect, useState } from "react";
import React from "react";
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import { asignarAlternativa, downloadFile } from "../../../shared/services/incidencias";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft, faTrash, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import ComentariosIncidencia from "./components/ComentariosIncidencia";
import FilesGrid from "../../../shared/components/FilesGrid";
import { useDispatch, useSelector } from "react-redux";
import { addDocumentationIncidencia, addImgPaqueteIncidencia, deleteDocumentationIncidencia, deleteImgPaqueteIncidencia, fetchIncidencia, fetchIncidenciaLogs } from "../../../../../../redux/features/fichaIncidencia";
import IncidenciaSteps from "./components/IncidenciaSteps";
import PerdidaForm from "./components/PerdidaForm";
import InfoModal from "../../../shared/components/InfoModal";
import MultiFileUploadForm from "../../../shared/components/MultiFileUploadForm";
import Loading from "../../../shared/components/Loading";
import InfoCard from "./components/InfoCard";
import AvisosModal from "./components/AvisosModal";
import ValeDescuentoModal from "./components/ValeDescuentoModal";
import ConfirmModal from "../../../shared/components/ConfirmModal";
import SolicitudesProveedorTable from "./components/SolicitudesProveedorTable";
import { saveSolicitudProveedor } from "../../../shared/services/solicitudesProveedor";

export default function FichaIncidencia() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { incidenciaId } = useParams();
    const [isUpload, setIsUpload] = useState(false);
    const [isUploadPaquete, setIsUploadPaquete] = useState(false);
    const [isOpenAvisos, setIsOpenAvisos] = useState(false);
    const [isOpenValeDescuento, setIsOpenValeDescuento] = useState(false);
    const [openConfirmExit, setOpenConfirmExit] = useState(false);
    const [openSolicitudes, setOpenSolicitudes] = useState(false);

    const currentIncidencia = useSelector(state => {
      return state.currentIncidencia.entity?.incidencia
    })
    const historico = useSelector(state => {
      return state.currentIncidencia.entity?.logs
    })
    const status = useSelector(state => {
        return state.currentIncidencia.status
    })

    useEffect(() => {
      dispatch(fetchIncidencia(incidenciaId))
      dispatch(fetchIncidenciaLogs(incidenciaId))

      return () => {
        dispatch({type: "incidencia/clear", payload: null })
      };
    }, []);

    const handleDeleteFile = async (file) => { 
      dispatch({type: 'loading/set', payload: true});
      dispatch(deleteDocumentationIncidencia(currentIncidencia.id, {documento: file.name})) 
    }

    const handleDeleteImgPaquete = async (file) => { 
      dispatch({type: 'loading/set', payload: true});
      dispatch(deleteImgPaqueteIncidencia(currentIncidencia.id, {img: file})) 
    }

    const handleDownload = async (file) => {
      dispatch({type: 'loading/set', payload: true});
      const fileResult = await downloadFile(currentIncidencia.id, {documento: file.name});

      if(fileResult) {
        dispatch({type: 'loading/set', payload: false});
        toast.success('Documento descargado correctamente!')
        const url = window.URL.createObjectURL(new Blob([fileResult]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.name); //or any other extension
        document.body.appendChild(link);
        link.click();
      } else {
        toast.error('Ups! Algo ha ido mal...')
      }
    }

    const handleUpload = () => { setIsUpload(true); }
    const handleCloseUpload = () => setIsUpload(false);
    const handleUploadFiles = async (data) => {
      const files = data.files;
      let incidenciaFiles = new FormData();
      incidenciaFiles.append("id", currentIncidencia.id);
  
      if(files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          incidenciaFiles.append(`files[${i}]`, files[i])
        }
        
        dispatch({type: 'loading/set', payload: true});
        dispatch(addDocumentationIncidencia(currentIncidencia.id, incidenciaFiles))
        setIsUpload(false);
      }
    }

    const handleUploadPaquete = () => { setIsUploadPaquete(true); }
    const handleCloseUploadPaquete = () => setIsUploadPaquete(false);
    const handleUploadPaqueteFiles = async (data) => {
      const files = data.files;
      let paqueteFiles = new FormData();
      paqueteFiles.append("id", currentIncidencia.id);
  
      if(files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          paqueteFiles.append(`files[${i}]`, files[i])
        }
        
        dispatch({type: 'loading/set', payload: true});
        dispatch(addImgPaqueteIncidencia(currentIncidencia.id, paqueteFiles))
        setIsUploadPaquete(false);
      }
    }

    const handleOpenAvisos = () => setIsOpenAvisos(true);
    const handleCloseAvisos = () => setIsOpenAvisos(false);

    const handleOpenValeDescuento = () => setIsOpenValeDescuento(true);
    const handleCloseValeDescuento = () => setIsOpenValeDescuento(false);

    const handleExit = () => { setOpenConfirmExit(true); }
    const handleConfirmExit = (confirm) => {
      setOpenConfirmExit(false);

      if(confirm) navigate('/incidencias/1');
    }

    const handleSetAlternativa = async(presupuesto) => {
      dispatch({type: 'loading/set', payload: true});
      const response = await asignarAlternativa({
        id: incidenciaId,
        presupuesto_id: presupuesto.id
      })

      if(response && response.success) {
        dispatch({type: 'loading/set', payload: false});
        toast.success(response.message);
        dispatch(fetchIncidencia(incidenciaId))
      }
    }

    const handleOpenSolicitudesProveedor = () => {
      setOpenSolicitudes(true);
    }
    const handleCloseSolicitudes = () => {
      setOpenSolicitudes(false);
    }
    const handleNewSolicitudProveedor = async(data) => {
      const response = await saveSolicitudProveedor(data);

      if(response && !response.data) {
        toast.warning(response.message);
        return;
      }
      
      if(response && response.success) {
        toast.success(response.message);
        dispatch(fetchIncidencia(incidenciaId))
      }
    }

    if(status.loading === 'pending' || status.loading === 'idle') {
      return ( <Loading /> );
    }

    if(status.loading === 'succeed' || status.loading === 'rejected') {
      return (
        <div className="w-100 d-flex flex-column incidencia content">
          <div className="w-100 mb-4">
              <button type="button" className="btn btn-add" onClick={() => (handleExit())}>
                  <span className="add">
                      <FontAwesomeIcon icon={faLongArrowAltLeft} size="1x" className="action" color="#215732" />
                  </span>
                  Atrás
              </button>
          </div>

          {currentIncidencia &&
            <div className="w-100 d-flex flex-column">
              <InfoCard 
                incidencia={currentIncidencia}
                cliente={currentIncidencia?.cliente} 
                direccionEnvioId={currentIncidencia?.direccion_envio_id}
                proveedor={currentIncidencia.proveedor}
                piezas={currentIncidencia.piezas}
                showPrecioCompra={false}
                contacto={currentIncidencia?.proveedor_contacto}
                valeDescuento={currentIncidencia?.vale_descuento}
                onViewAvisos={handleOpenAvisos}
                onViewValeDescuento={handleOpenValeDescuento}
                onOpenSolicitudes={handleOpenSolicitudesProveedor}
              />

              <div className="w-100 d-flex align-items-end justify-content-between my-4">
                <PerdidaForm
                  incidencia={currentIncidencia}
                />

                <button className="btn btn-primary ms-3" onClick={() => handleUpload()}>Documentos</button>

                <button className="btn btn-primary ms-3" onClick={() => handleUploadPaquete()}>Paquete</button>
              </div>

              { currentIncidencia?.comentarios_cliente && 
                (currentIncidencia?.comentarios_cliente !== "" && currentIncidencia?.comentarios_cliente !== null) ?
                <div className="w-100 alert alert-warning">
                  <div className="w-100 d-flex align-items-center">
                    <FontAwesomeIcon icon={faUserAlt} size="1x" color="#ffc107" />

                    <div className="d-flex flex-column ms-3">
                      <label>Comentario del cliente:</label>
                  
                      <p>
                        {currentIncidencia?.comentarios_cliente}
                      </p>
                    </div>
                  </div>
                </div>
                : ''
              }
              
              <div className="w-100">
                { (currentIncidencia && historico?.length > 0) ? 
                  <ComentariosIncidencia 
                    historico={historico}
                    incidenciaId={currentIncidencia?.id} />
                  :
                  <IncidenciaSteps 
                    incidencia={currentIncidencia}
                    incidenciaId={currentIncidencia?.id}
                    tipoId={currentIncidencia?.tipo_incidencia_id}
                    logs={currentIncidencia?.steps} 
                    onSetAlternativa={handleSetAlternativa}
                  />
                }
              </div>
            </div>
          }

          <div className="w-100">
            { (currentIncidencia && currentIncidencia?.documentacion !== '') &&
              <FilesGrid 
                filesList={currentIncidencia?.documentacion}
                incidenciaId={incidenciaId}
                canDelete={true}
                onDelete={handleDeleteFile}
                onDownload={handleDownload}
              />
            }
          </div>

          { isUpload && 
            <InfoModal
              state={isUpload}
              title="Cargar documentación"
              content={
                <MultiFileUploadForm 
                  onSubmit={handleUploadFiles} 
                  itemId={incidenciaId} 
                  labelText={"Documentos"} 
                />
              }
              onClose={handleCloseUpload}
            ></InfoModal>
          }

          { isUploadPaquete && 
            <InfoModal
              state={isUploadPaquete}
              width={'lg'}
              title="Cargar imágenes del paquete"
              content={
                <div className="w-100">
                  <div className="w-100 d-flex align-items-center mb-3">
                    { currentIncidencia.imgs_paquete?.length > 0 ? currentIncidencia.imgs_paquete?.map((img, i) => {
                        return (
                            <div className="flex-grow-1 d-flex flex-column align-items-center" key={'img-paquete-' + i}>
                              <img 
                                key={"Imagen " + (i+1)} 
                                src={`data:image/jpeg;base64,${img.img}`}
                                alt={"Imagen " + (i+1)} 
                                className="mb-4 me-4"
                                width={'200px'}
                                height={'200px'}
                                style={{objectFit: 'cover'}}
                              />

                              <span title="Eliminar" style={{cursor: 'pointer'}}>
                                <FontAwesomeIcon icon={faTrash} size="1x" className="action" color="#dc3545" onClick={() => handleDeleteImgPaquete(img.name)} />
                              </span>
                            </div>
                        )
                    }) : <p>No hay imágenes.</p>
                    }
                  </div>

                  <MultiFileUploadForm 
                    onSubmit={handleUploadPaqueteFiles} 
                    itemId={incidenciaId} 
                    labelText={"Imágenes"} 
                  />
                </div>
              }
              onClose={handleCloseUploadPaquete}
            ></InfoModal>
          }

          { isOpenAvisos && 
            <AvisosModal
              state={isOpenAvisos}
              title={"Avisos incidéncia " + currentIncidencia.numero}
              onClose={handleCloseAvisos}
              incidencia={currentIncidencia}
            ></AvisosModal>
          }

          { isOpenValeDescuento &&
            <ValeDescuentoModal
              title={"Vale de descuento de la incidéncia " + currentIncidencia.numero} 
              state={isOpenValeDescuento}
              incidencia={currentIncidencia}
              onClose={handleCloseValeDescuento}
            />
          }

          { (openConfirmExit) && 
            <ConfirmModal 
                onConfirmAction={handleConfirmExit} 
                title={'Salir'} 
                description={'Estás seguro de salir?'}
                content={null}
                onClose={() => handleConfirmExit(false)}
                state={openConfirmExit}>
            </ConfirmModal>
          }

          { (openSolicitudes) &&
            <InfoModal 
              state={openSolicitudes}
              title={"Solicitudes a " + currentIncidencia?.proveedor.nombre}
              content={
                  <SolicitudesProveedorTable 
                    incidencia={currentIncidencia}
                    solicitudes={currentIncidencia?.solicitudes_proveedor}
                    onNewSolicitud={handleNewSolicitudProveedor}
                  />
              }
              width={'md'}
              onClose={handleCloseSolicitudes}
            />
          }
        </div>
      )
    }
}