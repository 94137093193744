import API from '../../../../../services/api';

export const getAutorizacionesCredito = async (page = null, search = null) => {
    const pagination = page !== null ? '?page=' + page : '';
    const searcher = search !== null && page !== null ? '&' + search : search !== null ? '?' + search : '';
    return await API.get('/autorizaciones-credito' + pagination + searcher).then(res => res.data);
}

export const updatePedido = async (pedido) => {
    return API.put('/autorizaciones-credito/' + pedido.id, pedido).then(res => res.data);
}

export const actualizarPedidosCredito = async() => {
    return API.post('autorizaciones-credito/set-presupuesto', {}).then(res => res.data);
}