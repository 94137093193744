import { toast } from "react-toastify";
import { deleteImgPaquete, deleteIncidenciaFile, getIncidencia, uploadDocuments, uploadImgPaquete } from "../../views/Dashboard/components/shared/services/incidencias";
import { newStepsLogs } from "../../views/Dashboard/components/shared/services/incidenciaStepLogs";
import { getHistorico, newIncidenciaLog } from "../../views/Dashboard/components/shared/services/incidencias_logs";
import { asyncMac, makeAsyncTypes, makeFetchingReducer, reduceReducers } from "../utils";

const initialFicha = { 
};

export const fichaReducer = (state = initialFicha, action) => {
    switch(action.type){
        case "incidencia/fullfilled": {
            const futureState = {...state, incidencia: action.payload};
            return futureState;
        }
        case "incidencia-logs/fullfilled": {
            if(Array.isArray(action.payload)) {
                return {...state, logs: action.payload }
            } else {
                const futureState = {...state, logs: state.logs.concat({ ...action.payload }) }
                return futureState;
            }   
        }
        case "incidencia-steps/fullfilled": {
            const futureState = {...state, incidencia: {...state.incidencia, steps: action.payload}};
            return futureState;
        }
        case "incidencia/clear": {
            return {
                ...state,
                incidencia: null,
                logs: []
            }
        }
        default:
            return state
    }
}

const asyncIncidencia = makeAsyncTypes('incidencia');

const [setPending, setFullFilled, setError ] = asyncMac(asyncIncidencia);

const asyncIncidenciaLogs = makeAsyncTypes('incidencia-logs');

const [setPendingLogs, setFullFilledLogs, setErrorLogs ] = asyncMac(asyncIncidenciaLogs);

const asyncIncidenciaSteps = makeAsyncTypes('incidencia-steps');

const [setPendingSteps, setFullFilledSteps, setErrorSteps ] = asyncMac(asyncIncidenciaSteps);

export const fichaFetchingReducer = makeFetchingReducer([
    'incidencia/pending', 
    'incidencia/fullfilled', 
    'incidencia/rejected'
]);

export const fichaFetchingLogsReducer = makeFetchingReducer([
    'incidencia-logs/pending', 
    'incidencia-logs/fullfilled', 
    'incidencia-logs/rejected'
]);

export const fichaFetchingStepsReducer = makeFetchingReducer([
    'incidencia-steps/pending', 
    'incidencia-steps/fullfilled', 
    'incidencia-steps/rejected'
]);

export const fetchIncidencia = (incidenciaId) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getIncidencia(incidenciaId)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const fetchIncidenciaLogs = (incidenciaId) => async dispatch => {
    dispatch(setPendingLogs());

    try {
        const response = await getHistorico(incidenciaId)
        const data = await response.data;
        dispatch(setFullFilledLogs(data));
    } catch (e) {
        dispatch(setErrorLogs(e.message))
    }
}

export const newIncidenciaLogs = (log) => async dispatch => {
    dispatch(setPendingLogs());

    try {
        const response = await newIncidenciaLog(log)
        const data = await response.data;
        dispatch(setFullFilledLogs(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch(setErrorLogs(e.message))
    }
}

export const newIncidenciaSteps = (steps) => async dispatch => {
    dispatch(setPendingSteps());

    try {
        const response = await newStepsLogs(steps)
        const data = await response.data;
        toast.success(response.message);
        dispatch(setFullFilledSteps(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch(setErrorSteps(e.message))
    }
}

export const addDocumentationIncidencia = (incidenciaId, documentos) => async dispatch => {
    try {
        const response = await uploadDocuments(incidenciaId, documentos)
        const data = await response.data;
        toast.success(response.message);
        dispatch(setFullFilled(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch(setError(e.message))
        toast.error(e.message);
    }
}

export const addImgPaqueteIncidencia = (incidenciaId, imgs) => async dispatch => {
    try {
        const response = await uploadImgPaquete(incidenciaId, imgs)
        const data = await response.data;
        toast.success(response.message);
        dispatch(setFullFilled(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch(setError(e.message))
        toast.error(e.message);
    }
}

export const deleteDocumentationIncidencia = (incidenciaId, documento) => async dispatch => {
    try {
        const response = await deleteIncidenciaFile(incidenciaId, documento)
        const data = await response.data;
        toast.success(response.message);
        dispatch(setFullFilled(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch(setError(e.message))
        toast.error(e.message);
    }
}

export const deleteImgPaqueteIncidencia = (incidenciaId, img) => async dispatch => {
    try {
        const response = await deleteImgPaquete(incidenciaId, img)
        const data = await response.data;
        toast.success(response.message);
        dispatch(setFullFilled(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch(setError(e.message))
        toast.error(e.message);
    }
}

export const fichaIncidenciaReducer = fichaReducer;
export const currentIncidenciaStatusReducer = reduceReducers(
    fichaFetchingReducer,
    fichaFetchingLogsReducer
);