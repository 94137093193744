import { useState, useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import moment from 'moment';
import { LocalizationProvider, DatePicker, PickersDay } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box } from "@mui/material";
import esLocale from 'date-fns/locale/es';

export default function AvisoForm({
    incidencias,
    incidenciaId,
    aviso,
    isEdit,
    cancel,
    onSubmitAviso,
    onCancel
}) {
    const { register, handleSubmit, setValue, reset } = useForm({
        defaultValues: {
            nombre: '',
            canal: '',
            asunto: '',
            concepto: '',
            incidencia_id: incidenciaId,
        }
    });
    const [avisoDate, setAvisoDate] = useState(null);

    useEffect(() => {
        if(aviso) {
            reset(aviso);
            setAvisoDate(aviso.fecha);
        } else {
           setAvisoDate(moment(new Date()).format("YYYY-MM-DD"));

           if(incidenciaId) {
                const incidencia = incidencias?.find(i => i.id === incidenciaId);
                setValue('incidencia_id', incidenciaId);

                if(incidencia) {
                    let piezas = incidencia?.piezas?.map(p => p.nombre);
                    piezas = piezas.join(' + ');
                    setValue('concepto', `${incidencia?.proveedor?.nombre} ${incidencia?.proveedor?.telefono} - ${piezas}`)
                }
            }
        }

    }, [incidenciaId, aviso, incidencias]);

    const handleChangeIncidencia = (e) => { setValue('incidencia_id', parseInt(e.target.value)); } 
    const handleChangeCanal = (e) => setValue('canal', e.target.value);

    const onSubmit = async (data, e) => {
        e.preventDefault();
        data.fecha = moment(new Date(avisoDate)).format("YYYY-MM-DD H:m:s");
        
        onSubmitAviso(data);
    };

    const handleCancel = () => { onCancel(); }

    const customDayRenderer = (
        date,
        selectedDates,
        pickersDayProps
      ) => {
        const isWeekend = moment(date).isoWeekday();

        if (isWeekend === 6 || isWeekend === 7) {
          return <PickersDay {...pickersDayProps} disabled />;
        }
        return <PickersDay {...pickersDayProps} />;
    };
 
    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            <div className="w-100">
                <form className="form mt-4 d-flex flex-column align-items-end" onSubmit={handleSubmit(onSubmit)}>
                    { isEdit && 
                        <input 
                            type="hidden" 
                            {...register("id")} 
                            defaultValue={isEdit ? aviso.id : 0} />
                    }

                    { isEdit && 
                        <input 
                            type="hidden" 
                            {...register("incidencia_id")} 
                            defaultValue={aviso.incidencia_id} />
                    }

                    <div className="w-100 d-flex flex-column flex-md-row">
                        <div className="w-100 d-flex">
                            <div className="form-group me-0 me-md-3">
                                <label htmlFor="nombre">Persona de contacto: </label>
                                <input
                                    type="text"
                                    {...register("nombre", { required: true })}
                                    defaultValue={isEdit ? aviso.nombre : ""}
                                />
                            </div>

                            <div className="form-group me-0 me-md-3">
                                <label htmlFor="canal">Canal: </label>
                                <select 
                                    {...register("canal")}
                                    defaultValue={isEdit ? aviso.canal : 'whatsapp'}
                                    onChange={handleChangeCanal}>
                                    <option value={"whatsapp"}>Whatsapp</option>
                                    <option value={"email"}>Email</option>
                                    <option value={"rf"}>RF</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-group me-0 me-md-3">
                            <label htmlFor="asunto">Asunto: </label>
                            <input
                                type="text"
                                {...register("asunto", { required: true })}
                                defaultValue={isEdit ? aviso.asunto : ""}
                            />
                        </div>

                        <div className="form-group">
                            <label>Fecha:</label>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                <DatePicker
                                    renderDay={customDayRenderer}
                                    value={avisoDate}
                                    onChange={(newValue) => {
                                        setAvisoDate(newValue);
                                    }}
                                    mask={'__/__/____'}
                                    inputFormat="dd/MM/yyyy"
                                    renderInput={({ inputRef, inputProps, InputProps }) => (
                                        <Box className="date-picker" sx={{ position: 'relative' }}>
                                            <input className="form-control" ref={inputRef} {...inputProps} />
                                            {InputProps?.endAdornment}
                                        </Box>
                                    )}
                                />
                            </LocalizationProvider>
                        </div>

                        { (!isEdit && !incidenciaId) && 
                            <div className="form-group ms-0 ms-md-3">
                                <label htmlFor="incidencia_id">Incidencia:</label>
                                <select 
                                    {...register("incidencia_id", {required: true})}
                                    defaultValue={""}
                                    onChange={handleChangeIncidencia}>
                                    <option key={'incidencias-0'} value="">Selecciona una</option>
                                    {incidencias && incidencias.length > 0 && incidencias.map(op => <option key={'incidencias-' + op.id} value={op.id}>{op.numero}</option>)}
                                </select>
                            </div>
                        }
                    </div>

                    <div className="form-group">
                        <label htmlFor="concepto">Concepto: </label>
                        <textarea
                            {...register("concepto", { required: true })}
                            defaultValue={isEdit ? aviso.concepto : ""}
                        ></textarea>
                    </div>

                    <div className="d-flex align-items-center align-self-end ms-3">
                        <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                        {cancel && <button type="button" className="btn btn-default align-self-end ms-3" onClick={handleCancel}>Cancelar</button>}
                    </div>
                </form>
            </div>
        </div>
    );
}