import API from '../../../../../services/api';

/****** ENVIAR WHATSAPPS DE RECORDATORIO ******/ 
export const enviarWhatsAppRecordatorio = async (recordatorio) => {
    return API.post('whatsapp/recordatorio', recordatorio).then(res => res.data);
}

export const enviarWhatsAppsRecordatorios = async (recordatorios) => {
    return API.post('whatsapp/recordatorios', recordatorios).then(res => res.data);
}

export const archivarWhatsAppsRecordatorios = async (recordatorios) => {
    return API.post('whatsapp/archivar-recordatorios', recordatorios).then(res => res.data);
}