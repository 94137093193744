import { useState, useEffect } from "react";
import React from "react";
import { toast } from 'react-toastify';
import { useNavigate, useParams } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import { compareFacturasAndVentas, exportExcel, exportReport, generateFacturaPDF, regenerateFactura, sendFacturaByEmail } from '../shared/services/facturas';
import Header from '../shared/components/Header'
import moment from 'moment';
import InfoModal from "../shared/components/InfoModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchFacturas } from "../../../../redux/facturas";
import ExportForm from "../shared/components/ExportForm";
import Loading from "../shared/components/Loading";
import ActionsMenu from "../shared/components/ActionsMenu";
import { getActions } from "./actions";
import ConfirmModal from "../shared/components/ConfirmModal";
import { setErrorMessage } from "../shared/helpers/functionalities";

export default function Facturas() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { page } = useParams();
  const currPage = parseInt(page);
  const [currentFactura, setCurrentFactura] = useState(null);
  const [isExport, setIsExport] = useState(false);
  const [isCompare, setIsCompare] = useState(false);
  const [isReport, setIsReport] = useState(false);
  const [openConfirmSend, setOpenConfirmSend] = useState(false);
  const [currentPage, setPage] = useState(currPage);
  const tipoFacturas = useSelector(state => {
    const { tipo_facturas } = state;
    return tipo_facturas;
  });
  const facturas = useSelector(state => {
    return state.facturas.entities
  });
  const facturasStatus = useSelector(state => {
    return state.facturas.status
  });
  const searcher = useSelector(state => {
    return state.search
  })

  useEffect(() => {
    dispatch(fetchFacturas(currPage, getQuery()));
  }, [searcher]);

  const getQuery = () => {
    const isEmpty = Object.values(searcher).every(x => x === null || x === '');
    let query = !isEmpty ? 'search=' + (searcher?.search ? searcher?.search : '')
    : null;

    return query;
  }
  
  const handleSearch = (searchData) => {
    setPage(1);
    navigate(`/facturas/${1}`);
    dispatch({type: "search/set", payload: searchData});     
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    navigate(`/facturas/${newPage}`);
    dispatch(fetchFacturas(newPage, getQuery()));
  }

  const getPaginationCount = () => {
    var totalPages = facturas.total_items < 15 ? 1 : Math.ceil(facturas.total_items / 15);
    return parseInt(totalPages);
  }

  const handleAction = (action, factura) => {
    if(action === 'download') {
      generateThePdf(factura)
    } else  if(action === 'regenerate') {
      regenerate(factura)
    } else if(action === 'sendByEmail') {
      handleOpenConfirmSend(factura);
    }
  }

  const regenerate = async(factura) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await regenerateFactura({factura_id: factura.id});

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
    }
  }

  const generateThePdf = async (factura) => {
      dispatch({type: 'loading/set', payload: true});
      const pdfResult = await generateFacturaPDF(factura.id);

      if(pdfResult) {
        dispatch({type: 'loading/set', payload: false});
        toast.success('PDF generado correctamente!')
        const url = window.URL.createObjectURL(new Blob([pdfResult]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', factura.numero + '.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
      } else {
        toast.error('Ups! Algo ha ido mal...')
      }
  }

  const getTipoFacturaName = (tipoClienteId) => tipoFacturas.find(tp => tp.id === tipoClienteId)?.nombre;

  const handleExport = () => { setIsExport(true); }
  const handleReport = () => setIsReport(true);
  const handleCloseExport = () => setIsExport(false);
  const handleCloseReport = () => setIsReport(false);
  const handleCompare = () => setIsCompare(true);
  const handleCloseCompare = () => setIsCompare(false);

  const handleCompareFacturasAndVentas = async(data)  => {
    const exportResult = await compareFacturasAndVentas(data);

    if(exportResult) {
      const start = moment(data.startDate).format('DD-MM-YYYY');
      const end = moment(data.endDate).format('DD-MM-YYYY');

      toast.success('Facturas descargadas correctamente!')
      setIsExport(false);
    
      const url = window.URL.createObjectURL(new Blob([exportResult]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'facturas-' + start + '-' + end + '.xlsx');
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }
  }

  const handleExportFacturas = async (data) => {
    dispatch({type: 'loading/set', payload: true});
    const exportResult = await exportExcel(data);

    if(exportResult) {
      dispatch({type: 'loading/set', payload: false});
      const start = moment(data.startDate).format('DD-MM-YYYY');
      const end = moment(data.endDate).format('DD-MM-YYYY');

      toast.success('Facturas descargadas correctamente!')
      setIsExport(false);
    
      const url = window.URL.createObjectURL(new Blob([exportResult]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'facturas-' + start + '-' + end + '.xlsx');
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }
  };

  const handleExcelReport = async (data) => {
    dispatch({type: 'loading/set', payload: true});
    const reportResult = await exportReport(data);

    if(reportResult) {
      dispatch({type: 'loading/set', payload: false});
      const start = moment(data.startDate).format('DD-MM-YYYY');
      const end = moment(data.endDate).format('DD-MM-YYYY');

      toast.success('Informe descargado correctamente!')
      setIsExport(false);
    
      const url = window.URL.createObjectURL(new Blob([reportResult]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'informe_ventas-' + start + '-' + end + '.xlsx');
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }
  }

  const handleView = (item) => { 
    localStorage.removeItem('searcher');

    if(item === 'albaranes') {
      navigate('/albaranes/1', { replace: true });
    } else {
      navigate('/notas-cargo/1', { replace: true });
    }
  }

  const getSubtotal = (factura) => {
    const subtotal = factura.total + factura.total_envio + factura.total_casco;
    return parseFloat(subtotal).toFixed(2);
  }

  const getTotalIVA = (factura, conDescuento) => {
    let totalIVA;

    if(conDescuento) {
      totalIVA = factura.total_iva;
    } else {
      totalIVA = factura.total_iva + factura.total_descuento_iva
    }
    
    return parseFloat(totalIVA).toFixed(2);
  }

  const handleOpenConfirmSend = (factura) => {
    setCurrentFactura(factura);
    setOpenConfirmSend(true);
  }
  const handleCloseConfirmSend = () => {
    setOpenConfirmSend(false);
    setCurrentFactura(null);
  }

  const handleSendFacturaByEmail = async(confirm) => {
    if(confirm) {
      dispatch({type: 'loading/set', payload: true});
      const response = await sendFacturaByEmail(currentFactura.id)
      .catch(function (error) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(setErrorMessage(error))
      });

      if(response && response.success) {
        dispatch({type: 'loading/set', payload: false});
        toast.success(response.message);
      }
    }

    handleCloseConfirmSend();
  }

  if(facturasStatus.loading === 'pending' || facturasStatus.loading === 'idle') {
    return ( <Loading /> );
  }

  if(facturasStatus.loading === 'succeed' || facturasStatus.loading === 'rejected') {
    const pageCount = getPaginationCount();

    return (
      <div className="content pt-3">
        <Header  
          viewSearch={true}
          onSearch={handleSearch} 
          onExport={handleExport} 
          onReport={handleReport}
          onCompare={handleCompare}
        />

        <div className="w-100 d-flex flex-column align-items-end content-table">
          <div className="d-flex align-items-center mb-3">
            <button className="btn btn-default me-3" onClick={() => handleView('notas')}>Notas de cargo</button>
            <button className="btn btn-default" onClick={() => handleView('albaranes')}>Albaranes</button>
          </div>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Nº</th>
                  <th className="text-center">tipo</th>
                  <th className="text-center">fecha</th>
                  <th className="text-center">venta</th>
                  <th className="text-center">devolucion</th>
                  <th className="text-center">total</th>
                  <th className="text-center">total envío</th>
                  <th className="text-center">total casco</th>
                  <th className="text-center">subtotal</th>
                  <th className="text-center">I.V.A</th>
                  <th className="text-center">total I.V.A</th>
                  <th className="text-center">descuento</th>
                  <th className="text-center">total I.V.A</th>
                  <th className="text-center"></th>
                </tr>
              </thead>

              <tbody>
                { facturas.facturas?.map((factura) => {
                  return (
                    <tr key={factura.id}>
                      <td>{factura.numero}</td>
                      <td className="text-center text-uppercase">{tipoFacturas?.length > 0 ? getTipoFacturaName(factura.tipo_factura_id) : ''}</td>
                      <td className="text-center">{moment(factura.fecha).format("DD-MM-YYYY")}</td>
                      <td className="text-center">
                        { (factura.tipo_factura_id !== 4) ?
                          factura.venta_id
                          : factura.ventas
                        }
                      </td>
                      <td className="text-center">
                        { (factura.tipo_factura_id !== 4) ? 
                          (factura.devolucion_id !== null ? factura.devolucion_id : '')
                          : factura.devoluciones
                        }
                      </td>
                      <td className="text-center">{parseFloat(factura.total).toFixed(2)}€</td>
                      <td className="text-center">{parseFloat(factura.total_envio).toFixed(2)}€</td>
                      <td className="text-center">{parseFloat(factura.total_casco).toFixed(2)}€</td>
                      <td className="text-center">{getSubtotal(factura)}€</td>
                      <td className="text-center">{factura.iva}%</td>
                      <td className="text-center">{getTotalIVA(factura, false)}€</td>
                      <td className="text-center">
                        { (factura.vale_descuento) ?
                          <div className="d-flex flex-column">
                            { (factura.tipo_factura_id === 2 || factura.tipo_factura_id === 3) ? 
                                +parseFloat(factura.total_descuento_iva).toFixed(2) + '€'
                                : 
                                -parseFloat(factura.total_descuento_iva).toFixed(2) + '€'
                            }
                            <span className="fw-bold text-green">{factura?.vale_descuento.codigo}</span>
                          </div>
                          : '-'
                        }
                      </td>
                      <td className="text-center">{getTotalIVA(factura, true)}€</td>
                      <td className="text-center">
                        <ActionsMenu 
                            options={getActions()}
                            onAction={(action) => handleAction(action, factura)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <Pagination 
            className="mt-3" 
            count={pageCount} 
            page={currentPage} 
            onChange={handleChangePage} 
          />
        </div>

        { isExport && 
          <InfoModal
            state={isExport}
            title="Exportar facturas a Excel"
            content={
              <ExportForm 
                dateFilter={true}
                responsableFilter={false}
                tipoClienteFilter={false}
                tipoFacturasFilter={true}
                onSubmitExport={handleExportFacturas}
              />
            }
            onClose={handleCloseExport}
          ></InfoModal>
        }

        { isCompare && 
          <InfoModal
            state={isCompare}
            title="Comparar facturas y ventas a Excel"
            content={
              <ExportForm 
                dateFilter={true}
                responsableFilter={false}
                tipoClienteFilter={false}
                tipoFacturasFilter={false}
                onSubmitExport={handleCompareFacturasAndVentas}
              />
            }
            onClose={handleCloseCompare}
          ></InfoModal>
        }

        { isReport && 
          <InfoModal
            state={isReport}
            title="Generar informe de ventas a Excel"
            content={
              <ExportForm 
                dateFilter={true}
                responsableFilter={true}
                tipoClienteFilter={false}
                tipoFacturasFilter={false}
                onSubmitExport={handleExcelReport}
              />
            }
            onClose={handleCloseReport}
          ></InfoModal>
        }

        { openConfirmSend && 
          <ConfirmModal 
              onConfirmAction={handleSendFacturaByEmail} 
              title={'Enviar factura ' + currentFactura.numero} 
              description={'Estás seguro de enviarla por email?'}
              state={openConfirmSend}>
          </ConfirmModal>
        }
      </div>
    )
  }
}