import { Pagination } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";

export default function ValesDescuentosTable({list}) {
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);
  const rowsPerPage = 5;

  useEffect(() => {
      var totalPages = list.length < 5 ? 1 : Math.ceil(list.length / 5);
      setPageCount(parseInt(totalPages));
      setItems(list.slice(0, rowsPerPage));
  }, []);

  const handleChangePage = (event, newPage) => {
      setPage(newPage);
      
      const startPage = (newPage - 1) * rowsPerPage;
      const endPage = startPage + rowsPerPage;
      const currItems = list.slice(startPage, endPage);
      setItems([...currItems]);
  }
    
  return (
      <div className="w-100 d-flex flex-column">
          <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Código</th>
                <th className="text-center">Fecha de creación</th>
                <th className="text-center">Fecha de caducidad</th>
                <th className="text-center">Total</th>
                <th className="text-center">Gastado</th>
                <th className="text-center">disponible</th>
                <th className="text-center">terminado</th>
              </tr>
            </thead>
            <tbody>
                { items.map((vale, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <span className="fw-bold text-green">{vale.codigo}</span>
                            </td>
                            <td className="text-center">{moment(vale.fecha).format('DD-MM-YYYY')}</td>
                            <td className="text-center">
                                <span className="fw-bold">{moment(vale.fecha_caducidad).format('DD-MM-YYYY')}</span>
                            </td>
                            <td className="text-center">{vale.total}€</td>
                            <td className="text-center">{vale.gastado}€</td>
                            <td className="text-center text-green fw-bold">{vale.total - vale.gastado}€</td> 
                            <td className="text-center">
                              { vale?.terminado ?
                                <div className="d-flex flex-column">
                                  <span className="text-danger">SI</span>
                                  { (vale.incidencia_id === null) &&
                                    <small className="fw-bold text-uppercase">incidencia eliminada</small>
                                  }
                                </div>
                                : <span className="text-success">NO</span>
                              }
                            </td>           
                        </tr>
                    )
                })}
              </tbody>
          </table>
        </div>

        <Pagination 
          className="mt-3" 
          count={pageCount} 
          page={page} 
          onChange={handleChangePage} 
        />

      </div>
  );
}