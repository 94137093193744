import React, { useState } from 'react';
import { useEffect } from "react";
import InfoModal from '../../../../../../../shared/components/InfoModal';
import DashboardDinamicoRecambista from '../../../../../../../shared/components/DashboardDinamicoRecambista';

export default function RecambistaResume({
    startDate,
    endDate,
    resumen
}) {

    const [openSeguimiento, setOpenSeguimiento] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        
    }, [startDate, endDate, resumen]);

    const handleOpenSeguimiento = (user) => {
        setCurrentUser({id: user.id, nombre: user.nombre});
        setOpenSeguimiento(true);
    }

    const handleCloseSeguimiento = () => {
        setCurrentUser(null);
        setOpenSeguimiento(false);
    }

    return (
        <div className='w-100 my-4'>
            <h1>Resumen</h1>

            <div className='w-100 table-responsive'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>recambista</th>
                            <th className='text-center'>presupuestos</th>
                            <th className='text-center'>ventas</th>
                            <th className='text-center'>incidencias</th>
                            <th className='text-center'>ratio conversión</th>
                            <th className='text-center'>ratio beneficio</th>
                            <th className='text-center'>ratio incidencias</th>
                            <th className='text-center'>facturación</th>
                            <th className='text-center'>beneficio</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        { resumen.map(res => {
                            return (
                                <tr key={'resumen-' + res.id}>
                                    <td className='text-uppercase'>{res.nombre}</td>
                                    <td className='text-center'>{res.num_presupuestos}</td>
                                    <td className='text-center'>{res.num_ventas}</td>
                                    <td className='text-center'>{res.num_incidencias}</td>
                                    <td className='text-center'>{res.ratio_conversion}%</td>
                                    <td className='text-center'>{res.ratio_beneficio}%</td>
                                    <td className='text-center'>{res.ratio_incidencia}%</td>
                                    <td className='text-center'>{res.facturacion_total}€</td>
                                    <td className='text-center'>{res.beneficio_total}€</td>
                                    <td width={'8%'} className='text-center'>
                                        { (res.active) ?
                                            <small 
                                                className='fw-bold text-success text-uppercase' 
                                                style={{cursor: 'pointer'}}
                                                onClick={() => handleOpenSeguimiento(res)}>
                                                Seguimiento
                                            </small>
                                            : null
                                        }
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            { (openSeguimiento) && 
                <InfoModal
                    state={openSeguimiento}
                    title={`Seguimiento ${currentUser?.nombre}`}
                    content={
                        <DashboardDinamicoRecambista 
                            startDate={startDate}
                            endDate={endDate}
                            userId={currentUser?.id}
                        />
                    }
                    width={'lg'}
                    onClose={handleCloseSeguimiento}
                ></InfoModal>
            }
        </div>
    )
}
