import { faBullhorn, faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function MessageItem({
    message, 
    i
}) {
    return (
        <div className={'card-item mensajes d-flex flex-column mb-4 ' + (i === 0 ? 'col-12' : 'col-12 col-md-3')} key={'mensaje-' + message.id}>
            <div className={'w-100 h-100 d-flex '  + (i === 0 ? 'flex-row' : 'flex-column justify-content-center') }>
                <div className={'d-flex ' + (i === 0 ? 'col-12 col-md-2' : 'col-12 justify-content-center')}>
                    { (message.img) ?
                        <img 
                            src={`data:image/jpeg;base64,${message.img.file}`}
                            alt={message.img.nombre} 
                            className="mb-4 me-4"
                            width={'200px'}
                            height={'200px'}
                            style={{objectFit: 'cover'}}
                        />
                        :
                        <span>
                            { (message.tipo === 'info') && <FontAwesomeIcon icon={faInfoCircle} size="2x" color='#62B1F6' className="action" /> }
                            { (message.tipo === 'aviso') && <FontAwesomeIcon icon={faExclamationTriangle} color="#ffc107" size="2x" className="action" /> }
                            { (message.tipo === 'alerta') && <FontAwesomeIcon icon={faBullhorn} color="#dc3545" size="2x" className="action" /> }
                        </span>
                    }
                    
                </div>

                <div className={'mt-3 ' + (i === 0 ? 'col-12 col-md-5 ms-3' : 'col-12')}>
                    <small><b>{message.titulo}</b></small>
                    <p className='m-0' style={{'whiteSpace': 'pre-line'}}>{message.descripcion}</p>
                </div>
            </div>
        </div>
    )
}
