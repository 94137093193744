import { useEffect } from "react";
import React from "react";
import moment from 'moment';
import { faBullhorn, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

export default function NotificacionItem({
  notificacion,
  archivadas,
  onArchive,
  onGoTo,
  onSendWhatsapp
}) {
  
  
  useEffect(() => {
    
  }, []);


  const getTime = (fecha) => {
    const now = moment();
    const date = moment(fecha);

    const minutes = now.diff(date, 'minutes');
    const hours = now.diff(date, 'hours');
    const days = now.diff(date, 'days');

    if(minutes <= 60) {
      return "Hace " + minutes + (minutes > 1 ? " minutos" : ' minuto');
    } else if(hours < 24) {
      return "Hace " + hours + (hours > 1 ? " horas" : " hora");
    } else {
      return "Hace " + days + (days > 1 ? ' días' : ' día');
    }
  }
  
  return (
    <div className="notificacion">
        <FontAwesomeIcon icon={faBullhorn} size="1x" className="action" />

        <div className="d-flex flex-column ms-3">
            <div className="flex-grow-1">
            <span className="fecha">{getTime(notificacion.fecha)}</span>
            <span>
                { notificacion.presupuesto_id !== null &&
                <small 
                    className="ms-2 text-primary"
                    style={{textDecoration: 'underline', cursor: 'pointer'}}
                    onClick={() => onGoTo('presupuesto', notificacion?.presupuesto?.numero)}>
                    {notificacion?.presupuesto?.numero}
                </small> 
                }

                { notificacion.venta_id !== null &&
                <small 
                    className="ms-2 text-primary"
                    style={{textDecoration: 'underline', cursor: 'pointer'}}
                    onClick={() => onGoTo('venta', notificacion?.venta?.facturas?.length > 0 ? notificacion?.venta?.facturas[0]?.numero : notificacion?.venta?.albaranes[0]?.numero)}>
                    {notificacion?.venta?.facturas?.length > 0 ? notificacion?.venta?.facturas[0]?.numero : notificacion?.venta?.albaranes[0]?.numero}
                </small> 
                }

                { notificacion.devolucion_id !== null &&
                <small 
                    className="ms-2 text-primary"
                    style={{textDecoration: 'underline', cursor: 'pointer'}}
                    onClick={() => onGoTo('devolucion', notificacion?.devolucion?.facturas[0]?.numero)}>
                    {notificacion?.devolucion?.facturas[0]?.numero}
                </small> 
                }

                { notificacion.incidencia_id !== null &&
                <small 
                    className="ms-2 text-primary"
                    style={{textDecoration: 'underline', cursor: 'pointer'}}
                    onClick={() => onGoTo('incidencia', notificacion?.incidencia?.numero)}>
                    {notificacion?.incidencia?.numero}
                </small> 
                }

                { notificacion.proveedor_id !== null &&
                <Link to={"/proveedor/ficha/" + notificacion.proveedor_id} title="Ficha Proveedor" className="text-green">
                    {notificacion?.proveedor?.nombre}
                </Link> 
                }

                { notificacion.solicitud_id !== null &&
                <small 
                    className="ms-2 text-primary"
                    style={{textDecoration: 'underline', cursor: 'pointer'}}
                    onClick={() => onGoTo('solicitud', notificacion?.solicitud?.numero)}>
                    {notificacion?.solicitud?.numero}
                </small> 
                }

                { notificacion.whatsapp === 1 &&
                <span 
                    title="Enviar WhatsApp" 
                    className="ms-5" 
                    style={{cursor: 'pointer'}}
                    onClick={() => onSendWhatsapp(notificacion)}>
                    <FontAwesomeIcon icon={faWhatsapp} size="1x" color="#215732" className="action" />
                </span>
                }
            </span>
            </div>
            <p>{notificacion.mensaje}</p>
        </div>

        { (archivadas) ?
          <span title="Desarchivar" className="close" onClick={() => onArchive(notificacion, false)}>
            <FontAwesomeIcon icon={faCheck} size="1x" className="action" />
          </span>
          :
          <span title="Archivar" className="close" onClick={() => onArchive(notificacion, true)}>
            <FontAwesomeIcon icon={faTimes} size="1x" className="action" />
          </span>
        }
    </div>
  );
  
}