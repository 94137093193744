import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";


// hook to check the app version on route change
const useVersionCheck = () => {
  const dispatch = useDispatch();
  // check if a version update (refresh) needed on route change
  let location = useLocation();

  useLayoutEffect(() => {
    // if there is an update available and no state passed to route
    if (!location.state && window.localStorage.getItem('version-update-needed')) {
      window.localStorage.removeItem('version-update-needed'); // remove the storage object
      dispatch({type: 'reload/set', payload: true});
    }
  }, [location]);
};

export default useVersionCheck