import React from 'react';

export default function MetricsTable({
    titulo,
    labels, 
    data
}) {
        return (
            <div className="w-100 d-flex flex-wrap align-items-center justify-content-between mt-5">
                <h1>{titulo}</h1>
                
                <div className='w-100 d-flex flex-wrap flex-md-nowrap align-items-center mt-3'>
                    { (data && data.length > 0) ? 
                        <div className='table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        { labels?.map((label, i) => {
                                            return (
                                                <th 
                                                    className={(label !== 'tipo' && label !== 'cliente') ? 'text-center' : ''} 
                                                    key={'dataset-label-' + i}
                                                >
                                                    {label}
                                                    {(label === 'porcentaje' || label === 'ratio') && '(%)'}
                                                    {(label === 'ticket' || label === 'ticket medio' || label === 'importe' || label === 'facturacion') && '(€)'}
                                                </th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    { data.map((dataset, i) => {
                                        return (
                                            <tr key={'dataset-tipo-' + i}>
                                                { labels?.map((label, i) => {
                                                    return (
                                                        <td 
                                                            className={(label !== 'tipo' && label !== 'cliente') ? 'text-center' : ''} 
                                                            key={'dataset-tipo-data-' + i}
                                                        >
                                                            {dataset[label]} 
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        : <p className='text-center'>No hay datos</p>
                    }
                </div>
            </div>
        )
}