import React from "react";
import moment from "moment";

export default function DailyLlamadas({
  list,
  startOfWeek,
  endOfWeek
}) {
    return (
      <div className="content">
        <div className="w-100 d-flex flex-column align-items-end content-table">
          <p className="text-uppercase">{moment(startOfWeek).format("ddd DD MMM")} - {moment(endOfWeek).format("ddd DD MMM")}</p>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                    <th>nombre</th>
                    <th className="text-center">persona de contacto</th>
                    <th className="text-center">telefono</th>
                    <th className="text-center">telefono 2</th>
                    <th className="text-center">fecha</th>
                    <th className="text-center">tipo</th>
                </tr>
              </thead>

              <tbody>
                { list && list.length > 0 ? list?.map((contacto) => {
                    return (
                        <tr key={contacto.id}>
                            <td width="30%">{ contacto.nombre }</td>
                            <td width="15%" className="text-center">{ contacto.persona_contacto }</td>
                            <td className="text-center" width="10%">{ contacto.telefono }</td>
                            <td className="text-center" width="10%">{ contacto.telefono2 }</td>
                            <td className="text-center" width="10%">{ moment(contacto.proximo_contacto).format("DD-MM-YYYY") }</td>
                            <td width="15%" className="text-center text-uppercase">
                              {contacto.categoria}
                            </td>
                        </tr>
                    ); 
                  })
                  : <tr>
                        <td colSpan={6} className="text-center">No hay llamadas a realizar</td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
}