import React from "react";
import { useForm } from "react-hook-form";

export default function ImportForm({
    text,
    onSubmitImport
}) {
    const { register, handleSubmit } = useForm({
        defaultValues: {
            excel: '',
        }
    });

    const handleSubmitExcel = async (data, e) => {
        e.preventDefault();
        
        const excel = new FormData();
        excel.append('excel', data.excel[0]);
        onSubmitImport(excel);
    }

    return (
        <div className="w-100 d-flex mt-3">
            <div className="col-12 flex-column align-items-end">
                <form className="form align-items-center" onSubmit={handleSubmit(handleSubmitExcel)}>
                    { (text) &&
                        <div className="alert alert-info">
                            {text}
                        </div>
                    }
                    
                    <div className="d-flex align-items-center">
                        <div className="form-group">
                            <label htmlFor="excel">Excel</label>
                            <input 
                                type="file" 
                                className="form-control" 
                                {...register("excel", { maxLength: 255 })} 
                                defaultValue={''} />
                        </div>
                    </div>

                    <div className="d-flex align-items-center">
                        <button type="submit" className="btn btn-primary">Guardar</button>
                </div>
               </form>
            </div>
        </div>
    )
}