import { useEffect } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faCheck, faTimes, faBan } from "@fortawesome/free-solid-svg-icons";
import Pagination from '@mui/material/Pagination';
import { useSelector } from "react-redux";
import ActionsMenu from "../../../shared/components/ActionsMenu";
import { getActions } from "./actions";

export default function EntityTable({
    id,
    currPage,
    onEdit,
    onPageChange,
    onOpenFacturasCredito,
    onFusion
}) {
    const user = JSON.parse(localStorage.getItem('user'));
    const clientes = useSelector(state => {
        return state.clientes.entities
    });
 
    useEffect(() => {
    }, []);

    const handleChangePage = (event, newPage) => { onPageChange(newPage); }

    const getPaginationCount = () => {
      var totalPages = clientes.total_items < 15 ? 1 : Math.ceil(clientes.total_items / 15);
      return parseInt(totalPages);
    }

    const handleAction = (action, cliente) => {
        if(action === 'edit') {
            onEdit(cliente);
        } else  if(action === 'fusion') {
            onFusion(cliente)
        } else  if(action === 'invoices') {
            onOpenFacturasCredito(cliente)
        }
    }

    const getPoblacion = (cliente) => {
        if(cliente?.codigo_postal_facturacion !== null && cliente.poblacion_facturacion !== null) {
            return (cliente.codigo_postal_facturacion + " " + cliente.poblacion_facturacion)
        } else return '-'
    }

    return (
        <div className="w-100 d-flex flex-column align-items-end">
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>nombre</th>
                            <th>CIF</th>
                            <th>teléfono</th>
                            <th>email</th>
                            <th className="text-center">tipo</th>
                            <th>población</th>
                            <th className="text-center">país</th>
                            <th className="text-center">credito</th>
                            <th className="text-center"></th>
                        </tr>
                    </thead>

                    <tbody>
                        { clientes.clientes?.map((cliente) => {
                            return (
                                <tr key={cliente.id}>
                                    <td width="5%">{cliente.id}</td>
                                    <td width="15%">
                                        { (cliente.bloqueado) ?
                                            <span className="me-2">
                                                <FontAwesomeIcon icon={faBan} size="1x" color="#dc3545" />
                                            </span>
                                            : null
                                        }

                                        <Link target="_blank" rel="noopener noreferrer" to={"/cliente/ficha/" + cliente.id} title="Ficha Cliente" className="text-green text-uppercase">
                                            {cliente.nombre}
                                        </Link> 
                                    </td>
                                    <td width="6%">{cliente.cif}</td>
                                    <td width="12%">{cliente.telefono}</td>
                                    <td width="12%">{cliente.email}</td>
                                    <td className="text-center text-uppercase" width="8%">{cliente.tipo_cliente_id ? cliente.tipo_cliente.nombre : ''}</td>
                                    <td width="12%" className="text-uppercase">{getPoblacion(cliente)}</td>
                                    <td className="text-center text-uppercase" width="6%">{cliente.pais_facturacion_id ? cliente.pais_facturacion.nombre : ''}</td>
                                    <td className="text-center" width="6%">{cliente.credito ? <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#00986C" /> : <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />}</td>
                                    <td width="5%" className="text-center">
                                        <ActionsMenu 
                                            options={getActions(id, cliente, user)}
                                            onAction={(action) => handleAction(action, cliente)}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            

            <Pagination 
                id={id}
                className="mt-3" 
                count={getPaginationCount()} 
                page={currPage}
                onChange={handleChangePage} 
            />
        </div>
    )
}