
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import CustomStepper from './components/CustomStepper.js';
import moment from 'moment';

export default function EnvioStatusDetail({
    envio
}) {
    const [step, setStep] = useState(1);

    useEffect(() => {
        if(envio && !envio.cancelado) {
            const lastStatus = envio.estado[envio.estado?.length -1];

            if( 
                // SEUR
                lastStatus?.codigo === 'GO003' || 
                lastStatus?.codigo === 'GO002' ||
                lastStatus?.codigo === 'LI567' ||
                //MRW
                lastStatus?.codigo === '45' || 
                lastStatus?.codigo === '47' || 
                lastStatus?.codigo === '48'
                // DHL
            ) { setStep(1); }

            // ENVIADO
            if( 
                // SEUR
                lastStatus?.codigo === '' || 
                lastStatus?.codigo === '' ||
                //MRW
                lastStatus?.codigo === '57' || 
                lastStatus?.codigo === '58' || 
                lastStatus?.codigo === '79' ||
                lastStatus?.codigo === '16' ||
                lastStatus.codigo === '17'
                // DHL
            ) { setStep(2); }


            // EN CAMINO
            if(
                // SEUR
                lastStatus?.codigo === 'GL001' || 
                lastStatus?.codigo === 'LC003' ||
                lastStatus?.codigo === 'LO001' ||
                //MRW
                lastStatus?.codigo === '57'
                
                // DHL
            ) { setStep(3); }

            // ENTREGADO
            if( 
                // SEUR
                lastStatus?.codigo === 'LL003' || 
                lastStatus?.codigo === 'LL001' ||
                //MRW
                lastStatus?.codigo === '00'
                // DHL
            ) { setStep(4); }

             
        } else {
            setStep(0);
        }
    }, [envio]);

    return (
        <div className="mt-3 seguimiento-envio-item">
            <div className="w-100">
                <div className='w-100 d-flex flex-column mb-3'>
                    <h5 className="fw-bold text-uppercase">{envio.codigo_transporte}</h5>
                    <h6 className="fw-bold text-uppercase">{envio?.piezas?.[0]?.nombre}</h6>
                    <small>
                        Transportado por: &nbsp;
                        <span className='fw-bold text-uppercase'>{envio.transportista?.nombre}</span>
                    </small>
                    <small>
                        Núm de seguimiento: &nbsp;
                        <span className='fw-bold'>{envio.codigo_envio}</span>
                    </small>
                </div>

                <CustomStepper 
                    step={step}
                    envio={envio}
                />
            
                <div className="pt-4 pb-2">
                    <hr />

                    { (envio.transportista.id === 1) ?
                        <>
                        {(envio?.numero_seguimiento === '-' && !envio?.url_seguimiento) ?
                            <div className="w-100 d-flex align-items-center justify-content-between mt-2 p-2 border border-info border-1">
                                <FontAwesomeIcon icon={faInfoCircle} size="1x" className="action" color="#62B1F6" /> 
                                    <small className="mx-2 text-uppercase text-justify">
                                        Este envío lo gestiona el proveedor.  
                                        si deseas solicitar la información de este envío,
                                        llámanos al <a href="tel:+34644175425">+34 644 175 425</a>, facilitando el número de envío: 
                                        <span className='fw-bold ms-2'>{envio.codigo_transporte}</span>
                                    </small>
                                <FontAwesomeIcon icon={faInfoCircle} size="1x" className="action" color="#62B1F6" /> 
                            </div>
                            : 
                            <div className='w-100 d-flex flex-column align-items-center justify-content-between mt-2 p-2 border border-info border-1'>
                                <small className='text-uppercase'>Envío gestionado por el proveedor.</small>
                                <small>
                                    Número de seguimiento: &nbsp; 
                                    <span className='fw-bold'>{envio?.numero_seguimiento}</span>
                                </small>
                                <small>
                                    <a href={envio?.url_seguimiento} target="_blank" rel="noreferrer" className="link">
                                        {envio?.url_seguimiento}
                                    </a>
                                </small>
                            </div>
                        }
                        </>
                    : 
                    <>
                        <div className='w-100'>
                            <table className='w-100 table'>
                                <tbody>
                                    { envio.estado?.map((estado, i) => {
                                        return (
                                            <tr>
                                                <td className='text-center'>{estado.nombre}</td>
                                                <td className='text-center'>{moment(estado.fecha).format('DD-MM-YYYY H:mm:ss')}</td>
                                            </tr>
                                        )
                                    }).reverse()}
                                </tbody>
                            </table>
                        </div>
                    </>
                        
                    }
                </div>
            </div>
        </div>
    );
}

/*
    { (envio.transportista.id !== 1) ?
        <div className='w-100 mt-4'>
            <h5 className='fw-bold text-uppercase'>Estados del paquete</h5>

            <div className="table-responsive mt-2">
                <table className="table">
                    <tbody>
                        { (Array.isArray(envio.estado)) ? envio.estado?.map((status, i) => {
                            return (
                                <tr key={'envio-status-' + envio.transportista.id + '-' + i}>
                                    <td width="50%" className="text-center">
                                        <p className={'m-0 fw-bold text-uppercase ' + (getStatusColor(envio.transportista.id, status.codigo) !== 'success' ? 'text-' + getStatusColor(envio.transportista.id, status.codigo) : 'text-green')}>
                                            {status.nombre}
                                        </p>
                                    </td>

                                    <td width="50%" className="text-center">
                                        <div className='w-100 d-flex align-items-center justify-content-center'>
                                            <p className='m-0 me-2'>{status.fecha}</p>
                                            <small>{status.hora?.substring(0, status.hora.length - 3)}</small>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                            : 
                            <tr>
                                <td width="50%" className="text-center">
                                    <p className={'m-0 fw-bold text-uppercase ' + (getStatusColor(envio.transportista.id, envio.estado.codigo) !== 'success' ? 'text-' + getStatusColor(envio.transportista.id, envio.estado.codigo) : 'text-green')}>
                                        {envio.estado.nombre}
                                    </p>
                                </td>

                                <td width="50%" className="text-center">
                                    <div className='w-100 d-flex align-items-center justify-content-center'>
                                        <p className='m-0 me-2'>{envio.estado.fecha}</p>
                                        <small>{envio.estado.hora?.substring(0, envio.estado.hora.length - 3)}</small>
                                    </div>
                                </td>
                            </tr>
                        } 
                    </tbody>
                </table>
            </div>
        </div>
        : ''
    }
*/