import API from '../../../../../services/api';

export const getAvisos = async (startDate, endDate) => {
    const start = startDate !== null ? '?startDate=' + startDate : '';
    const end = endDate !== null && start !== '' ? '&endDate=' + endDate : '';
    return API.get('/avisos' + start + end).then(res => res.data);
}

export const getAvisosIncidencia = async (incidenciaId) => {
    return API.get('/avisos/incidencia/' + incidenciaId).then(res => res.data);
}

export const newAviso = async (aviso) => {
    return API.post('/avisos', aviso).then(res => res.data);
}

export const updateAviso = async (aviso) => {
    return API.put('/avisos/' + aviso.id, aviso).then(res => res.data);
}

export const markAsReadAviso = async (aviso) => {
    return API.put('/avisos/' + aviso.id + '/leido', {leido: true}).then(res => res.data);
}

export const deleteAviso = async (avisoId) => {
    return API.delete('/avisos/' + avisoId).then(res => res.data);
}