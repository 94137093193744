import React from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import DevolucionWizard from "../DevolucionWizard";

export default function DevolucionContainer() {
    const location = useLocation();
    const navigate = useNavigate();
    const [ventaId, setVentaId] = useState(location.state.ventaId);

    useEffect(() => {
        setVentaId(location.state.ventaId);
     }, []);

    useEffect(() => {
        setVentaId(location.state.ventaId);
     }, [location]);
   
    return (
        <div className="w-100 content">
            <div className="w-100">
                <div className="w-100 mb-4">
                    <button type="button" className="btn btn-add" onClick={() => navigate(-1)}>
                        <span className="add">
                            <FontAwesomeIcon icon={faLongArrowAltLeft} size="1x" className="action" color="#215732" />
                        </span>
                        Atrás
                    </button>
                </div>

                <DevolucionWizard ventaId={ventaId}></DevolucionWizard>
            </div>
      </div>
    );
}