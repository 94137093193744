import { useEffect, useState } from "react";
import React from "react";
import moment from 'moment';
import { Link } from "react-router-dom";
import VirtualizedAutocompletePresupuestos from "../../../../../../../shared/components/AutocompletePresupuestos";
import { useSelector } from "react-redux";

export default function StepForm({
    incidencia,
    item,
    tipoId,
    disabled,
    register,
    control,
    setValue,
    onChangeStep,
    onSetAlternativa
}) {
    const [selectOpt, setSelectOpt] = useState(-1);
    const [viewPresupuesto, setViewPresupuesto] = useState(false);
    let timeout = null;
    const incidenciaSteps = useSelector(state => {
        const { steps } = state;
        return steps.filter(s => s.tipo_incidencia_id === tipoId);
    });

    useEffect(() => {
        if(item) {
            if(item.nombre?.toLowerCase().includes('se envía otra pieza') && item.checked) {
                setViewPresupuesto(true);
            } else setViewPresupuesto(false);
        }
    }, [item]);

    const startTimeOut = (item, e) =>  timeout = setTimeout(() => { 
        timeout = null;
        onChangeStep(item, e.target.value)
    }, 600);
    const clearTimeOut = () => timeout ? clearTimeout(timeout) : null;

    const handleChangePresupuestoAlternativa = (presupuesto) => onSetAlternativa(presupuesto);
    
    return (
        <li key={item.id} style={{borderBottom: '1px solid #f4f4f4', paddingBottom: '20px', marginBottom: '20px'}}>
            <input type="hidden" 
                name={`step.${item.index}.id`}
                {...register(`step.${item.index}.id`)} 
                defaultValue={item.logId} 
            />
            <input type="hidden" 
                name={`step.${item.index}.incidencia_step_id`}
                {...register(`step.${item.index}.incidencia_step_id`)} 
                defaultValue={item.incidencia_step_id} 
            />

            <div className="w-100 d-flex flex-column align-items-center">
                <div className="w-100 d-flex align-items-center justify-content-between">
                    <div className="flex-row d-flex align-items-center mb-1">
                        <input
                            {...register(`step.${item.index}.checked`)} 
                            onChange={(e) => {
                                setValue(`step.${item.index}.checked`, e.target.checked);
                            }}
                            type="checkbox"
                            defaultChecked={item.checked}
                            disabled={disabled}
                        />
                        <label className="ms-2"> {item.nombre}</label>
                    </div>

                    { (viewPresupuesto) ?
                        <>
                            { (incidencia?.presupuesto_alternativa_id) ?
                                <Link target="_blank" rel="noopener noreferrer" to={"/presupuesto/" + incidencia?.alternativa?.id} title="Ficha presupuesto alternativa" className="text-green fw-bold">
                                    {incidencia?.alternativa?.numero}
                                </Link>
                                :
                                <div className="w-50">
                                    <VirtualizedAutocompletePresupuestos 
                                        id={'alternativa'}
                                        control={control}
                                        disabled={false}
                                        labels={['numero']}
                                        defaultValue={null}
                                        onChange={handleChangePresupuestoAlternativa}
                                    />
                                </div>
                            }
                        </>
                        : null
                    }
                </div>

                <div className="w-100 mt-2">
                    <div className="form-group">
                        <label htmlFor="observaciones">Mensaje predefinido</label>
                        <select 
                            value={selectOpt}
                            disabled={disabled}
                            onChange={(e) => {
                                setSelectOpt(parseInt(e.target.value))
                                setValue(
                                    `step.${item.index}.comentario`, 
                                    incidenciaSteps.find(s => s.id === item.incidencia_step_id)
                                    ?.respuestas[parseInt(e.target.value)]
                                );
                                if(tipoId === 5) onChangeStep(item, incidenciaSteps.find(s => s.id === item.incidencia_step_id)
                                    ?.respuestas[parseInt(e.target.value)])
                            }}>
                            <option key={'opt--1'} value={-1}></option>
                            {incidenciaSteps.find(s => s.id === item.incidencia_step_id)
                                ?.respuestas
                                .map((opt, i) => <option key={'opt-' + i} value={i}>{opt}</option>)}
                        </select>
                    </div>

                    { (item.comentario) &&
                        <div className="chat-info" style={{position: 'unset'}}>
                            <span className="me-3">{moment(item.fecha_comentario).format("DD-MM-YYYY hh:mm:ss")}</span>
                            <span className="text-uppercase">{item.created_by?.username}</span>
                        </div>
                    }
                    <textarea
                        className="w-100"
                        defaultValue={item.comentario}
                        disabled={disabled}
                        name={`step.${item.index}.comentario`}
                        {...register(`step.${item.index}.comentario`)} 
                        onKeyUp={(e) => {
                            e.preventDefault();
                            setValue(`step.${item.index}.comentario`, e.target.value);
                            
                            if(tipoId === 5) {
                                clearTimeOut();
                                startTimeOut(item, e);
                            }
                        }}
                    ></textarea>
                </div>
            </div>
        </li>
    )
}