import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

export default function ResumenGeneral({
    resumen
}) {
    const tipoIncidencias = useSelector(state => {
        const { tipo_incidencias } = state;
        return tipo_incidencias;
    });
    const [showDetail, setShowDetail] = useState(false);
    const [currentDetail, setCurrentDetail] = useState(null);

    const handleShowDetail = (type) => {
        let detail = {};

        if(type === 'externas') {
            detail.title = 'Culpa Externa';
            detail.items = resumen?.incidencias?.filter(i => ((i.culpa === 'cliente' || i.culpa === 'proveedor') && i.tipo_inciencia_id !== 6));
        } else if(type === 'internas') {
            detail.title = 'Culpa Interna';
            detail.items = resumen?.incidencias?.filter(i => ((i.culpa === 'recomotor' || i.culpa === 'atencion-comercial') && i.tipo_inciencia_id !== 6) || i.tipo_inciencia_id === 8);
        } else if(type === 'envios') {
            detail.title = 'Culpa Envío';
            detail.items = resumen?.incidencias?.filter(i => ((i.culpa === 'transporte-propio' || i.culpa === 'transporte-proveedor') && i.tipo_inciencia_id !== 6));
        } else {
            detail.title = 'Cascos';
            detail.items = resumen?.incidencias?.filter(i => i.tipo_incidencia_id === 6);
        }

        detail.items = detail.items?.sort((a, b) => -a.fecha.localeCompare(b.fecha))
        setCurrentDetail(detail);
        setShowDetail(true);
    }

    const getTipoIncidencia = (tipoIncidenciaId) => { return tipoIncidencias.find(f => f.id === tipoIncidenciaId)?.nombre }

    return (
        <div className="w-100 d-flex flex-wrap align-items-center justify-content-between mt-5">
            <h1>Resumen General</h1>
            
            <div className='w-100 d-flex flex-column align-items-center mt-3'>
                <div className='col-12'>
                    <div className='table-responsive'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th className='text-center'>ventas</th>
                                    <th className='text-center'>incidencias</th>
                                    <th className='text-center'>incidencias externas</th>
                                    <th className='text-center'>incidencias internas</th>
                                    <th className='text-center'>incidencias envio</th>
                                    <th className='text-center'>cascos</th>
                                    <th className='text-center'>pérdidas recomotor</th>
                                    <th className='text-center'>% fallo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='text-center'>{resumen.total_ventas}</td>
                                    <td className='text-center'>{resumen.total_incidencias}</td>
                                    <td className='text-center'>
                                        <span 
                                            title='Ver detalle' 
                                            style={{cursor: 'pointer'}} 
                                            onClick={() => handleShowDetail('externas')}>
                                            {resumen.total_incidencias_ext}
                                        </span>
                                    </td>
                                    <td className='text-center'>
                                        <span 
                                            title='Ver detalle' 
                                            style={{cursor: 'pointer'}} 
                                            onClick={() => handleShowDetail('internas')}>
                                            {resumen.total_incidencias_int}
                                        </span>
                                    </td>
                                    <td className='text-center'>
                                        <span 
                                            title='Ver detalle' 
                                            style={{cursor: 'pointer'}}  
                                            onClick={() => handleShowDetail('envios')}>
                                            {resumen.total_incidencias_envio}
                                        </span>
                                    </td>
                                    <td className='text-center'>
                                        <span 
                                            title='Ver detalle' 
                                            style={{cursor: 'pointer'}}  
                                            onClick={() => handleShowDetail('cascos')}>
                                            {resumen.total_cascos}
                                        </span>
                                    </td>
                                    <td className='text-center'>{resumen.total_perdidas_recomotor}€</td>
                                    <td className='text-center'>{resumen.fallo}%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                { (showDetail && currentDetail) &&
                    <div className='col-12 mt-3'>
                        <h1>{currentDetail.title}</h1>

                        <div className='table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Presupuesto</th>
                                        <th className='text-center'>Incidencia</th>
                                        <th className='text-center'>Fecha</th>
                                        <th className='text-center'>Proveedor</th>
                                        <th className='text-center'>tipo</th>
                                        <th className='text-center'>culpa</th>
                                        {currentDetail.title === 'Culpa Interna' ? <th className='text-center'>RESP</th> :  null}
                                    </tr>
                                </thead>
                                <tbody>
                                    { (currentDetail && currentDetail.items.length > 0) ? currentDetail.items?.map((dataset, i) => {
                                        return (
                                            <tr key={'dataset-resumen-detail-' + i}>
                                                <td>{dataset?.venta?.presupuesto?.numero}</td>
                                                <td className='text-center'>{dataset.numero}</td>
                                                <td className='text-center'>{moment(dataset.fecha).format('DD/MM/YYYY')}</td>
                                                <td className='text-center text-uppercase'>{dataset.proveedor?.nombre}</td>
                                                <td className='text-center text-uppercase'>{getTipoIncidencia(dataset.tipo_incidencia_id)}</td>
                                                <td className='text-center text-uppercase'>{dataset.culpa}</td>
                                                { currentDetail.title === 'Culpa Interna' ? 
                                                    <td className='text-center text-uppercase'>{dataset.created_by.username}</td> 
                                                    : null
                                                }
                                            </tr>
                                        )
                                    }) : <tr><td colSpan={currentDetail.title === 'Culpa Interna' ? 7 : 6} className='text-center'>No hay datos</td></tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}