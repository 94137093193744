import React from 'react';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { fetchClientesMetrics } from '../../../../../../../../redux/features/dashboard';
import '../../../Dashboard.scss';
import DateSelector from '../../../../../shared/components/DateSelector';
import ClientesMetrics from './components/ClientesMetrics';

export default function ClientesTab() {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(moment().date() >= 26 ?
        moment().date(26).format('YYYY-MM-DD')
    :   moment().subtract(1, 'month').date(26).format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().date() >= 26 ? 
        moment().add(1, 'month').date(25).format('YYYY-MM-DD')
    :   moment().date(25).format('YYYY-MM-DD'));
    const [view, setView] = useState('variable');
    const dashboard = useSelector(state => {
        return state.dashboard.entity.admin
    });

    useEffect(() => {
        dispatch(fetchClientesMetrics(startDate, endDate, view));
    }, []);

    const handleDateInterval = (startDate, endDate, view) => {
        setView(view);
        setStartDate(startDate);
        setEndDate(endDate);
        dispatch(fetchClientesMetrics(startDate, endDate, view));
    }

    return (
        <div className='w-100 d-flex flex-column align-items-center dashboard'>
            <div className='w-100 mt-4 summary'>
                <h1>Información de clientes</h1>

                <div className='w-100 d-flex align-items-center'>
                    <DateSelector onSelectedDate={handleDateInterval} /> 
                </div>

                { (dashboard.clientesMetrics) &&  
                    <ClientesMetrics 
                        view={view}
                        data={dashboard.clientesMetrics} />
                }
            </div>
        </div>
    )
}
