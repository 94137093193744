import { useEffect, useState } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faPen, faPhone, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

export default function DireccionesList({
    presupuestoId,
    direcciones, 
    selectedDireccionId,
    fromPresupuesto,
    onNewDireccion,
    onEditDirecion,
    onSelectedDireccion,
}) 
{
    const [list, setList] = useState([]);
    const [currentList, setCurrentList] = useState([]);
    const [search, setSearch] = useState("");

    const presupuesto = useSelector(state => {
        return state.presupuesto.entity
    })

    useEffect(() => {
        if(direcciones) { 
            setList(direcciones); 
            setCurrentList(direcciones);
        }
    }, [direcciones]);

    const setSelectDireccionEnvio = (direccion) => { onSelectedDireccion(direccion); }

    const editDireccion = (direccion) => onEditDirecion(direccion); 

    const newDireccion = () => onNewDireccion();

    const handleSearch = (e) => {
        const value = e.target.value;

        if(value !== "") {
            let auxList = direcciones.filter(d => {
                const nombre = d?.nombre?.toUpperCase();
                const telefono = d?.telefono?.toUpperCase();
                const direccion = d?.direccion?.toUpperCase();
                const cp = d?.codigo_postal.toUpperCase();
                const poblacion = d?.poblacion?.toUpperCase();

                if(nombre.includes(value.toUpperCase()) || 
                    telefono.includes(value.toUpperCase()) ||
                    direccion.includes(value.toUpperCase()) ||
                    cp.includes(value.toUpperCase()) ||
                    poblacion.includes(value.toUpperCase()) 
                ) return true;
                else return false;
            })
            setCurrentList(auxList);
        } else setCurrentList(direcciones);
        
        setSearch(value);
    }

    return (
        <div className="w-100">
            <div className="d-flex flex-column ms-0 ms-md-4">
                <div className="d-flex align-content-center justify-content-between">
                    <h1>Direcciones de envío:</h1>

                    <button type="button" className="btn btn-add mb-4" onClick={() => newDireccion()}>
                        <span className="add">
                            <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                        </span>
                        Nueva
                    </button>
                </div>

                { (list.length > 5) &&
                    <div className="w-100 d-flex align-items-center mb-4">
                        <input 
                            type="text" 
                            name="search" 
                            id="search" 
                            className="form-control" 
                            defaultValue={search}
                            onChange={handleSearch}
                            placeholder="Escribe una dirección"
                        />
                    </div>
                }
                
                { currentList.map(direccion => {
                    return (
                        <div 
                            key={'direccion-' + direccion.id} 
                            className={"card-item mb-3"}> 
                            <div className="d-flex justify-content-between align-items-center">
                               { (fromPresupuesto && presupuestoId > 0) &&
                                    <div className="me-3">
                                        <input
                                            type="radio"
                                            name="direccion_id"
                                            value={direccion.id}
                                            disabled={presupuesto.es_venta ? true : (direccion.active ? false : true)}
                                            style={{cursor: 'pointer'}}
                                            checked={direccion.id === selectedDireccionId}
                                            onChange={() => setSelectDireccionEnvio(direccion)}
                                        />
                                    </div>
                                }
                                
                                <div className="flex-column align-items-center flex-grow-1">
                                    <div className="d-flex align-items-center justify-content-between pe-3">
                                        <div>
                                            <p className="fw-bold m-0">{direccion.nombre}</p> 
                                            <p className="m-0">{direccion.direccion}, {direccion.codigo_postal} {direccion.poblacion}</p>
                                            <p className="m-0">{direccion.area}, {direccion.pais.nombre}</p>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            <FontAwesomeIcon icon={faPhone} size="1x" className="action" color="#215732" />
                                            <p className="m-0 ms-2">{direccion.telefono}</p>
                                        </div>
                                    </div>
                                   
                                    { (direccion?.horario_entrega_dia || direccion?.horario_entrega_tarde) ?
                                        <div className="w-100">
                                            { (direccion?.horario_entrega_dia) ?
                                                <small>Horario día:  <b>{direccion?.horario_entrega_dia}</b></small>
                                                : null
                                            }

                                            { (direccion?.horario_entrega_tarde) ?
                                                <>
                                                    <span className="mx-2">|</span>
                                                    <small>Horario tarde: <b>{direccion?.horario_entrega_tarde}</b></small>
                                                </>
                                                
                                                : null
                                            }
                                        </div>
                                        : null
                                    }
                                </div>

                                <span title="Editar" style={{cursor: 'pointer'}} onClick={() => editDireccion(direccion)}>
                                    <FontAwesomeIcon icon={faPen} size="1x" className="action" color="#215732" />
                                </span>
                            </div>

                            { (!direccion.active) &&
                                <small>
                                    <FontAwesomeIcon icon={faInfoCircle} size="1x" className="me-1" color="#62B1F6" />
                                    Dirección desactivada
                                </small>
                            }
                        </div>
                    );
                })}
            </div>
        </div>
    );
}