import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

export default function VariableForm({
    variable,
    onSubmitVariable
}) {
    const { register, formState: { errors }, handleSubmit, reset } = useForm({
        defaultValues: {
            id: variable.id,
            valor: '',
        }
    });

    useEffect(() => {
        reset(variable);
    }, [variable]);

    const handleSubmitTransportista = async(data, e) => {
        e.preventDefault();
       onSubmitVariable(data);
    }
    
    return (
        <div className="w-100 d-flex flex-column mt-4">
            <form className="form flex-row align-items-center mb-4" onSubmit={handleSubmit(handleSubmitTransportista)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={variable.id} />

                <div className="d-flex flex-grow-1">
                    <div className="form-group flex-grow-1 mb-0 me-3">
                        <label htmlFor="nombre">Valor</label>
                        <input 
                            type="text" 
                            className="form-control"
                            {...register("valor", { required: true })} 
                            defaultValue={variable.valor} />
                        
                        <div className="form-invalid">
                            {errors.nombre?.type === 'required' && "Nombre es obligatorio"}
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end ms-3">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    )
}