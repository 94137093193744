import { useEffect } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faDownload, faPen, faPlus, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export default function ProveedorItemsList({
    proveedor, 
    item,
    columnas,
    onNew,
    onEdit,
    onDelete,
    onDownload
}) {
    useEffect(() => {
        
    }, []);
   
    return (
        <div className="w-100 d-flex flex-column">
            <div className="w-100 d-flex flex-column">
                <div className="w-100 d-flex justify-content-end">
                    <button className="btn btn-add" onClick={() => onNew()}>
                        <span className="add">
                            <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                        </span>
                        Nuevo
                    </button>
                </div>
                    
                <div className="w-100 d-flex flex-column mt-4">
                    { (proveedor?.[item]?.length > 0) ?
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        { columnas.map(col => {
                                            return (<th key={'col-' + col} className="text-center">{col}</th>)
                                        })}
                                        <th className="text-center">acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { proveedor?.[item]?.map(itm => {
                                            return (
                                                <tr key={item + '-' + itm.id}>
                                                    { columnas.map((col, i) => {
                                                        return(
                                                            <td key={'col-' + col + '-' + i} className="text-center">
                                                                { (col === 'fecha_inicio' || col === 'fecha_fin' || col === 'fecha') ?
                                                                        moment(itm[col]).format('DD-MM-YYYY')
                                                                    :
                                                                    (col === 'transportista') ?
                                                                        itm.transportista.nombre
                                                                    :
                                                                    (col === 'tipo_piezas') ?
                                                                        (itm?.tipo_piezas && itm?.tipo_piezas?.length > 0) ?
                                                                            itm?.tipo_piezas?.map((tipo, i) => {
                                                                            return (
                                                                                <>
                                                                                    <span className="me-2">{tipo.nombre}</span>
                                                                                    {(i === 0 || i < (itm.tipo_piezas.length - 1)) ? <span className="me-2">|</span> : ''}
                                                                                </>
                                                                                
                                                                            )
                                                                        }) : '-'
                                                                    : 
                                                                    (col === 'descuento') ?
                                                                        itm[col] + '%'
                                                                    : 
                                                                    (col === 'poblacion') ?
                                                                        <span className="text-uppercase">{itm.codigo_postal + ' ' +  itm.poblacion}</span>
                                                                    :
                                                                    (col === 'pais') ?
                                                                    <span className="text-uppercase">{itm.pais.nombre}</span>
                                                                    :
                                                                    (col === 'hora_recogida') ?
                                                                        moment(itm?.hora_recogida, "HH:mm:ss").format('HH:mm')
                                                                    : 
                                                                    (itm[col] === null) ?
                                                                    '-'
                                                                    :
                                                                    (col === 'total' || col === 'gastado') ?
                                                                        itm[col] + '€'
                                                                    :
                                                                    (col === 'terminado' || col === 'active') ?
                                                                       <>
                                                                        { (itm[col]) ?
                                                                            <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#00986C" />
                                                                            : 
                                                                            <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                                                                        }
                                                                       </>
                                                                    : itm[col]
                                                                }
                                                            </td>
                                                        )
                                                    })}

                                                    <td className="text-center" width="12%">
                                                        { (item === 'vales') &&
                                                            <span title="Descargar" className="action me-2" style={{cursor: 'pointer'}} onClick={() => onDownload(itm)}>
                                                                <FontAwesomeIcon icon={faDownload} size="1x" color="#215732" />
                                                            </span>
                                                        }
                                                        <span title="Editar" style={{cursor: 'pointer'}} className="me-2">
                                                            <FontAwesomeIcon icon={faPen} size="1x" className="action" onClick={() => onEdit(itm)} color="#215732" />
                                                        </span>
                                                        <span title="Eliminar" style={{cursor: 'pointer'}}>
                                                            <FontAwesomeIcon icon={faTrash} size="1x" className="action" onClick={() => onDelete(itm.id)} color="#dc3545" />
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        
                        : <p>No hay {item} {item === 'vacaciones' ? 'añadidas' : 'añadidos'} para este proveedor</p>
                    }
                </div>
            </div>
        </div> 
    );
}