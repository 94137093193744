import { useState, useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";

export default function VentaFacturaProvForm({onSubmitVenta, venta}) {
    const { register, formState: { errors }, handleSubmit, reset } = useForm({
        defaultValues: {
            id: '',
            factura_proveedor: '',
        }
    });
    const [ currentVenta, setCurrentVenta ] = useState({});
    const [ isEdit, setIsEdit ] = useState(false);

    useEffect(() => {
        setCurrentVenta(venta);
        setIsEdit(true);
    }, []);

    useEffect(() => {
        if(venta) {
            setCurrentVenta(venta);
        }
    }, [venta]);

    const handleSubmitVenta = async (data, e) => {
        e.preventDefault();
        onSubmitVenta(data);
    };

 
    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            <div>
                <form className="form  mb-4" onSubmit={handleSubmit(handleSubmitVenta)}>
                    <input 
                        type="hidden" 
                        {...register("id")} 
                        defaultValue={venta.id} />

                    <div className="w-100 d-flex">
                        <div className="form-group">
                            <label htmlFor="factura_proveedor">Factura/s Proveedor:</label>
                            <input 
                                type="file" 
                                multiple
                                className="form-control" 
                                {...register("factura_proveedor")} 
                                defaultValue={''} />
                        </div>
                    </div>

                    <div className="d-flex align-items-center align-self-end">
                        <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}