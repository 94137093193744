import { getPresupuestos } from "../views/Dashboard/components/shared/services/presupuestos";
import { asyncMac, makeAsyncTypes, makeCrudReducer, makeFetchingReducer, makeSetReducer, reduceReducers } from "./utils";

const crudReducer = makeCrudReducer([
    'presupuestos/add',
    'presupuestos/edit',
    'presupuestos/delete'
])

const asyncPresupuestos = makeAsyncTypes('presupuestos');

const [setPending, setFullFilled, setError ] = asyncMac(asyncPresupuestos);

export const presupuestosFetchingReducer = makeFetchingReducer([
    'presupuestos/pending', 
    'presupuestos/fullfilled', 
    'presupuestos/rejected'
]);

const fullfilledReducer = makeSetReducer(['presupuestos/fullfilled']);

export const fetchPresupuestos = (page, search) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getPresupuestos(page, search)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const presupuestosReducer = reduceReducers(crudReducer, fullfilledReducer);
export const presupuestosStatusReducer = presupuestosFetchingReducer;