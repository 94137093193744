import React, {useEffect, useState} from 'react';
import PartCard from "../PartCard";
import PartDetails from "../PartDetails";
import {useSelector} from "react-redux";

export default function ResultsList ({
    results,
    onSelectedPart
}) {
    const [showPartsList, setPartsList] = useState(true);
    const [showPartDetails, setShowPartDetails] = useState(false);
    const [currentPart, setCurrentPart] = useState({});
    const [filteredResults, setFilteredResults] = useState([]);

    const handleSelectedPart = (part) => onSelectedPart(part);

    const handleShowMoreInfo = (part) => {
        setPartsList(false);
        setShowPartDetails(true);
        setCurrentPart(part);
    };

    const handleGoBack = () => {
        setShowPartDetails(false);
        setPartsList(true);
        setCurrentPart({});
    }

    const tipoPiezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });

    useEffect(() => {
        const getTipo = (tipo) => {
            tipo = tipo.includes(' / ') ? tipo.split(' / ')[0] : tipo;
            return tipoPiezas.find(tp => tp.nombre.toLowerCase()?.includes(tipo.toLowerCase()))?.id;
        }

        const getDescuento = (result) => {
            let descuentos = result.proveedor?.descuentos?.length > 0 ? result.proveedor?.descuentos.sort((a, b) => b.tipo_piezas?.length - a?.tipo_piezas.length) : result.proveedor?.descuentos;

            return descuentos?.length === 1 ? descuentos[0] : descuentos?.find(f => {
                let isIn = true;

                if (f.tipo_piezas && f.tipo_piezas.length > 0) {
                    let tipoPiezaId = getTipo(result.nombre);
                    let tipo = tipoPiezas.find(tp => tp.id === parseInt(tipoPiezaId));
                    isIn = tipo ? f.tipo_piezas.find(f => f.id === parseInt(tipo.id)) : false;
                }

                if (f.max !== null && f.min !== null) {
                    return (parseInt(result.precio) <= f.max && parseInt(result.precio) >= f.min && isIn)
                } else if (f.min !== null && f.max === null) {
                    return (parseInt(result.precio) >= f.min && isIn)
                } else if (f.max !== null && f.min === null) {
                    return (parseInt(result.precio) <= f.max && isIn)
                } else return null;
            });
        }

        const calculatePrecio = (result) => {
            const descuento = getDescuento(result);
            const descuentoFinal = descuento ? descuento.descuento : 0;

            const descuentoCompra = (descuentoFinal !== 0)
                ? (descuento.descuento * parseFloat(result.precio)) / 100
                : 0;

            return parseFloat(result.precio) - descuentoCompra;
        }

        const uniqueParts = new Set();

        const orderedResults = [
            ...results.filter(part => part.isFromIntegration),
            ...results.filter(part => !part.isFromIntegration),
        ];

        let resultsFiltered = orderedResults.filter(part => {
            if (part.ref_vendedor === '') {
                return true;
            }

            if (!uniqueParts.has(part.ref_vendedor)) {
                uniqueParts.add(part.ref_vendedor);
                return true;
            }
            return false;
        });

        resultsFiltered.forEach((result) => {
            if (result.isFromIntegration) {
                result.precio = calculatePrecio(result);
                result.descuento = getDescuento(result)?.descuento;
            }
        })

        const orderedParts = sortPartsByPrice(resultsFiltered);
        setFilteredResults(sortPartsByProveedor(orderedParts));
    }, [results, tipoPiezas]);

    const sortPartsByPrice = (parts) => parts.sort((a, b) => parseInt(a.precio) - parseInt(b.precio));

    const sortPartsByProveedor = (parts) => {
        const vinarosParts = parts.filter((part) => part.vendedor === 'vinaros');
        const otherProveedorParts = parts.filter((part) => part.vendedor !== 'vinaros');
        return [...vinarosParts, ...otherProveedorParts];
    }

    const getUniqueResults = () => {
        const uniqueParts = new Set();

        const orderedResults = [
            ...results.filter(part => part.isFromIntegration),
            ...results.filter(part => !part.isFromIntegration),
        ];

        const filteredResults = orderedResults.filter(part => {
            if (part.ref_vendedor === '') {
                return true;
            }

            if (!uniqueParts.has(part.ref_vendedor)) {
                uniqueParts.add(part.ref_vendedor);
                return true;
            }
            return false;
        });

        return sortPartsByProveedor(sortPartsByPrice(filteredResults));
    }

    const selectOem = (e) => toggleTypeCheckbox(e, 'oem');
    const selectIam = (e) => toggleTypeCheckbox(e, 'iam');
    const selectCat = (e) => toggleTypeCheckbox(e, 'cat');
    const selectAll = (e) => toggleTypeCheckbox(e, '')


    const toggleTypeCheckbox = (e, type) => {
        if (!e.target.checked || type === '') {
            setFilteredResults(getUniqueResults());

            return;
        }

        const newResults = getUniqueResults().filter(
            (result) => result.marketPlace !== 'recambiofacil' || result.type === type
        );

        console.log(newResults);
        setFilteredResults(newResults);
    }

    return (
        <>
            { showPartsList &&
                <div className="w-100 d-flex flex-column mt-4 part-searcher-results-list">
                    <div className={'d-flex row'}>
                        <div className={'col-4'}>
                            <div className={'mx-2 border rounded'}>
                                <p className={'mb-2 w-100 rounded p-2 results-list-filter text-success'}>Tipo</p>
                                <div className={'mx-2'}>
                                    <label className={'d-block mb-1'}><input type={'radio'} name={'rftype'} onChange={selectAll}/> Todos</label>
                                    <label className={'d-block mb-1'}><input type={'radio'} name={'rftype'} onChange={selectOem}/> Oem</label>
                                    <label className={'d-block mb-1'}><input type={'radio'} name={'rftype'} onChange={selectIam} /> Iam</label>
                                    <label className={'d-block mb-1'}><input type={'radio'} name={'rftype'} onChange={selectCat} /> Cat</label>
                                </div>
                            </div>
                        </div>
                        <div className={'col-8'}>
                            { filteredResults?.map((part, i) => {
                                return (
                                    <PartCard
                                        key={part.isFromIntegration ? 'integration' + i : part.marketPlace + i}
                                        part={part}
                                        onSelectedPart={handleSelectedPart}
                                        onShowMoreInfo={handleShowMoreInfo}
                                    />
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            }

            { showPartDetails &&
                <PartDetails part={currentPart} onGoBack={handleGoBack} onSelectedPart={handleSelectedPart} />
            }
        </>
    );
}