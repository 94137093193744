import API from '../../../../../services/api';
import { headers } from '../helpers/functionalities';


export const getProveedores = async (page = null, search = null) => {
    const pagination = page !== null ? '?page=' + page : '';
    const searcher = search !== null && page !== null ? '&' + search : search !== null ? '?' + search : '';
    return await API.get('/proveedores'+ pagination + searcher).then(res => res.data);
}

export const getAllProveedores = async () => {
    return await API.get('/proveedores-all').then(res => res.data);
}

export const getProveedor = async (idProveedor) => {
    return API.get('/proveedores/' + idProveedor).then(res => res.data);
}

export const getProveedorFicha = async (section, search = null, page, idProveedor) => {
    const pagination = '?page=' + page;
    const seccion = '&seccion=' + section;
    const searcher = search ? '&' + search : '';
    
    return API.get('/proveedores/' + idProveedor + pagination + seccion + searcher).then(res => res.data);
}

export const buscarProveedor = async (proveedor) => {
    return API.post('/proveedores/buscar', proveedor).then(res => res.data);
}

export const obtenerProveedorIntegracion = async (proveedor) => {
    return API.post('/proveedores/proveedor-integracion', proveedor).then(res => res.data);
}

export const newProveedor = async (proveedor) => {
    return API.post('/proveedores', proveedor).then(res => res.data);
}

export const associateProveedor = async (proveedor, name_to_associate) => {
    return API.post('/proveedores/associate', {proveedor: proveedor.id, posible: name_to_associate}).then(res => res.data);
}

export const downloadDocumentacion = async (doc) => {
    return API.post('/proveedores/download', {documento: doc}, {responseType: 'blob'}).then(res => res.data);
}

export const comprasExport = async (data) => {
    return API.post('/proveedores/export', data, {responseType: 'blob'}).then(res => res.data);
}

export const importarInfoProveedores = async (excel) => {
    return API.post('proveedores/import', excel, headers).then(res => res.data);
}

export const comprobarProveedor = async (proveedor) => {
    return API.post('/proveedores/comprobar', proveedor).then(res => res.data);
}

export const fusionarProveedores = async (data) => {
    return API.post('/proveedores/merge', data).then(res => res.data);
}

export const updateProveedor = async (proveedor) => {
    return API.post('/proveedores/' + (proveedor instanceof FormData ? proveedor.get("id") : proveedor.id) + '/update', proveedor, (proveedor instanceof FormData ? headers : null)).then(res => res.data);
}

export const deleteProveedor = async (idProveedor) => {
    return API.delete('/proveedores/' + idProveedor).then(res => res.data);
}

/****** DESCUENTOS ******/
export const newDescuento = async (descuento) => {
    return API.post('/descuentos-proveedor', descuento).then(res => res.data);
}

export const updateDescuento = async (descuento) => {
    return API.put('/descuentos-proveedor/' + descuento.id, descuento).then(res => res.data);
}

export const deleteDescuento = async (idDescuento) => {
    return API.delete('/descuentos-proveedor/' + idDescuento).then(res => res.data);
}


/******  TRANSPORTISTAS FAVORITOS ******/
export const newTransportistaFav = async (descuento) => {
    return API.post('/transportistas-proveedor', descuento).then(res => res.data);
}

export const updateTransportistaFav = async (descuento) => {
    return API.put('/transportistas-proveedor/' + descuento.id, descuento).then(res => res.data);
}

export const deleteTransportistaFav = async (idDescuento) => {
    return API.delete('/transportistas-proveedor/' + idDescuento).then(res => res.data);
}

/****** ALMACENES ******/
export const newAlmacen = async (almacen) => {
    return API.post('/almacenes-proveedores', almacen).then(res => res.data);
}

export const updateAlmacen = async (almacen) => {
    return API.put('/almacenes-proveedores/' + almacen.id, almacen).then(res => res.data);
}

export const deleteAlmacen = async (almacenId) => {
    return API.delete('/almacenes-proveedores/' + almacenId).then(res => res.data);
}

/****** VACACIONES ******/
export const newVacaciones = async (almacen) => {
    return API.post('/vacaciones-proveedores', almacen).then(res => res.data);
}

export const updateVacaciones = async (almacen) => {
    return API.put('/vacaciones-proveedores/' + almacen.id, almacen).then(res => res.data);
}

export const deleteVacaciones = async (almacenId) => {
    return API.delete('/vacaciones-proveedores/' + almacenId).then(res => res.data);
}

/****** VALES ******/
export const getVale = async (valeId) => {
    return await API.get('/vale-proveedores/' + valeId + '/download', {responseType: 'blob'}).then(res => res.data);
}

export const newVale = async (vale) => {
    return API.post('/vale-proveedores', vale, vale instanceof FormData ? headers : null).then(res => res.data);
}

export const discount = async (vale) => {
    return API.post('/vale-proveedores/' + vale.id + '/discount', vale).then(res => res.data);
}

export const updateVale = async (valeId, vale) => {
    return API.post('/vale-proveedores/' + valeId, vale, vale instanceof FormData ? headers : null).then(res => res.data);
}

export const deleteVale = async (valeId) => {
    return API.delete('/vale-proveedores/' + valeId).then(res => res.data);
}


/****** DETALLES ******/
export const newDetalle = async (detalle) => {
    return API.post('/detalles-proveedores', detalle).then(res => res.data);
}

export const updateDetalle = async (detalle) => {
    return API.put('/detalles-proveedores/' + detalle.id, detalle).then(res => res.data);
}

export const deleteDetalle = async (detalleId) => {
    return API.delete('/detalles-proveedores/' + detalleId).then(res => res.data);
}

/******** AVISOS PIEZAS *********/

export const newAvisoProveedor = async (aviso) => {
    return API.post('/avisos-proveedor', aviso).then(res => res.data);
}

export const updateAvisoProveedor = async (aviso) => {
    return API.put('/avisos-proveedor/' + aviso.id, aviso).then(res => res.data);
}

/******** BLOQUEO TIPO PIEZAS LOGS *********/

export const newBloqueoPiezaLog = async (log) => {
    return API.post('/bloqueo-tipo-piezas', log).then(res => res.data);
}