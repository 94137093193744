import API from "../../../../../services/api";

export const getCarroceriasPesadas = async () => {
  return await API.get("/carrocerias-pesadas").then((res) => res.data);
};

export const addCarroceriaPesada = async (data) => {
  return await API.post("/carrocerias-pesadas", data).then((res) => res.data);
};

export const editCarroceriaPesada = async (id, data) => {
  return await API.put(`/carrocerias-pesadas/${id}`, data).then(
    (res) => res.data
  );
};
