import { getDevoluciones } from "../views/Dashboard/components/shared/services/devoluciones";
import { asyncMac, makeAsyncTypes, makeCrudReducer, makeFetchingReducer, makeSetReducer, reduceReducers } from "./utils";

const crudReducer = makeCrudReducer([
    'devoluciones/add',
    'devoluciones/edit',
    'devoluciones/delete'
])

const asyncDevoluciones = makeAsyncTypes('devoluciones');

const [setPending, setFullFilled, setError ] = asyncMac(asyncDevoluciones);

export const devolucionesFetchingReducer = makeFetchingReducer([
    'devoluciones/pending', 
    'devoluciones/fullfilled', 
    'devoluciones/rejected'
]);

const fullfilledReducer = makeSetReducer(['devoluciones/fullfilled']);

export const fetchDevoluciones = (page, search) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getDevoluciones(page, search)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const devolucionesReducer = reduceReducers(crudReducer, fullfilledReducer);
export const devolucionesStatusReducer = devolucionesFetchingReducer;