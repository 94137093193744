import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

export default function Filters({ categories, filters, filterType, onFilter }) {
  /** LocalStorage **/
  const filtrosByScrapper =
    JSON.parse(localStorage.getItem("filtrosByScrapper")) || {};

  /** Form **/
  const { register, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: {
      pagina: JSON.stringify(filtrosByScrapper[filterType]?.pagina) || 1,
      categoria:
        JSON.stringify(filtrosByScrapper[filterType]?.categoria)
          ?.toString()
          .replace(/"/g, "") || -1,
      ...Object.entries(filters || {})?.reduce((acc, [key, filterBy]) => {
        if (filterBy?.length > 0) {
          acc[key] = JSON.stringify(filtrosByScrapper[filterType]?.[key]);
        }
        return acc;
      }, {}),
    },
  });

  /** Methods **/
  const handleSubmitFilter = (data, e) => {
    e.preventDefault();
    if (data.pagina < 1) {
      return toast.error("La página debe ser mayor a 0");
    }

    const processedFilters = Object.entries(data)?.reduce(
      (acc, [key, value]) => {
        if (value !== "-1") {
          try {
            acc[key] = JSON.parse(value);
          } catch {
            acc[key] = value;
          }
        }
        return acc;
      },
      {}
    );

    onFilter({
      [filterType]: processedFilters,
    });
  };

  const formatFilterKey = (filterKey) => {
    return filterKey?.replace(/_/g, " ");
  };

  /** Render **/
  return (
    <div className="w-100 mt-4 mt-md-0 mt-lg-0">
      <h1 className="p-2 pt-0">
        {filterType === "rrr"
          ? "Ovoko"
          : filterType.charAt(0).toUpperCase() + filterType.slice(1)}
      </h1>
      <form className="form" onSubmit={handleSubmit(handleSubmitFilter)}>
        {categories?.length > 0 && (
          <div className="w-100 p-2">
            <label htmlFor="categoria">Categorias</label>
            <select className="custom-select" {...register("categoria")}>
              <option key={0} value={-1}>
                Todos
              </option>
              {categories?.map((category) => (
                <>
                  <optgroup
                    key={`${category.name}-group`}
                    label={category.name}
                  />
                  {category.sub_categories.map((subcategory) => (
                    <optgroup
                      key={`${subcategory.name}-group`}
                      label={`└ ${subcategory.name}`}
                    >
                      {subcategory.sub_sub_categories.map((subSubCategory) => (
                        <option
                          key={`${subSubCategory.id}-option`}
                          value={subSubCategory.id}
                        >
                          {subSubCategory.name} ({subSubCategory.count})
                        </option>
                      ))}
                    </optgroup>
                  ))}
                </>
              ))}
            </select>
            <div
              style={{
                border: "1px solid #215731",
                width: "100%",
                marginTop: "1rem",
              }}
            />
          </div>
        )}
        <div className="ms-0 row w-100">
          <div className={`${filters && Object.entries(filters)?.length ? "col-12 col-sm-4" : "col-12"} p-2`}>
            <label htmlFor="pagina">Pagina</label>
            <input
              type="number"
              className="form-control"
              {...register("pagina")}
            />
          </div>
          {filters &&
            Object.entries(filters)
              ?.filter(([key]) => key !== "marcas")
              .map(([key, filterBy]) => {
                return (
                  filterBy?.length > 0 && (
                    <div key={key} className="col-12 col-sm-4 p-2">
                      <label htmlFor={key}>{formatFilterKey(key)}</label>
                      <select
                        {...register(key)}
                        defaultValue={JSON.stringify(
                          filtrosByScrapper[filterType]?.[key]
                        )}
                      >
                        <option key={0} value={-1}>
                          Todos
                        </option>
                        {filterBy.map((op, index) => (
                          <option
                            key={`${key}-${op.value || index}`}
                            value={JSON.stringify(op)}
                          >
                            {op.text || op.Descripcion}{" "}
                            {op.count && `(${op.count})`}
                          </option>
                        ))}
                      </select>
                    </div>
                  )
                );
              })}
          <div className="p-2 col-12">
            <button type="submit" className="btn btn-primary w-100">
              FILTRAR
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
