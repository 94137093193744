import { useState, useEffect } from "react";
import React from "react";
import Pagination from '@mui/material/Pagination';
import { getRespuestasSolicitud } from "../../../shared/services/respuestas_solicitud";
import moment from "moment";
import Loading from "../../../shared/components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faFileInvoice, faFilePdf } from "@fortawesome/free-solid-svg-icons";

export default function RespuestasList({
    solicitud,
    onNewPresupuesto,
    onSendEmailDesguaceOffline,
    onDownloadProforma
}) {
  const [currentPage, setPage] = useState(1);
  const [respuestas, setRespuestas] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    fetchData(currentPage);
  }, []);

  const fetchData = async (page) => {
    const response = await getRespuestasSolicitud(page, solicitud.id);

    if(response && response.success) {
        setRespuestas(response.data);
        setIsLoading(false);
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchData(newPage);
  }

  const getPaginationCount = () => {
    var totalPages = respuestas.total_items < 15 ? 1 : Math.ceil(respuestas.total_items / 15);
    return parseInt(totalPages);
  }

  if(isLoading) { return ( <Loading /> ); }

  if(!isLoading) {
    const pageCount = getPaginationCount();

    return (
        <div className="w-100 d-flex flex-column align-items-end content-table">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>desguace</th>
                <th>contacto</th>
                <th className="text-center">fecha</th>
                <th className="text-center">disponible</th>
                <th className="text-center">estado</th>
                <th className="text-center">precio</th>
                <th>observaciones</th>
                <th className="text-center">acciones</th>
              </tr>
            </thead>

            <tbody>
              { respuestas.respuestas?.map((respuesta) => {
                return (
                  <tr key={respuesta.id}>
                    <td>{solicitud.tipo === 'offline' ? respuesta.offline_desguace.nombre : respuesta.reconstruido.nombre}</td>
                    <td>{solicitud.tipo === 'offline' ? respuesta.offline_desguace?.contacto : respuesta.reconstruido?.contacto}</td>
                    <td className="text-center">{moment(respuesta.fecha).format('DD-MM-YYYY HH:mm')}</td>
                    <td className="text-center">
                        <span className={'fw-bold ' + (respuesta.disponible ? 'text-green' : 'text-danger')}>{respuesta.disponible ? 'SI' : 'NO'}</span>
                    </td>
                    <td className="text-center text-uppercase">{respuesta.estado ? respuesta.estado : '-'}</td>
                    <td className="text-center">{respuesta.precio ? respuesta.precio + '€' : '-'}</td>
                    <td width="30%">{respuesta.observaciones ? respuesta.observaciones : '-'}</td>
                    <td className="text-center">
                      { (solicitud.tipo === 'offline' && respuesta.disponible) ?
                        <>
                          <span title="Crear presupuesto" style={{cursor: 'pointer'}}>
                              <FontAwesomeIcon icon={faFileInvoice} size="1x" className="action" color="#215732" onClick={() => onNewPresupuesto(respuesta)} />
                          </span>
                          <span title="Enviar email" className="mx-2" onClick={() => onSendEmailDesguaceOffline(respuesta.offline_desguace)}>
                              <FontAwesomeIcon icon={faEnvelope} size="1x" className="action" color="#62B1F6" />
                          </span>
                          { (respuesta.proforma) &&
                            <span title="Descargar proforma" onClick={() => onDownloadProforma(respuesta.proforma)}>
                              <FontAwesomeIcon icon={faFilePdf} size="1x" className="action" color="#215732" />
                            </span>
                          }
                        </>
                        
                        : ''
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <Pagination 
          className="mt-3" 
          count={pageCount} 
          page={currentPage} 
          onChange={handleChangePage} 
        />
      </div>
    )
  }
}