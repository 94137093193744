import { useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import PersonasSubForm from "./components/PersonasSubForm";
import VirtualizedAutocompletePoblaciones from "../../../AutocompletePoblaciones";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { downloadDocumentacion } from "../../../../services/proveedores";
import GooglePlacesAutocomplete from "../../../GooglePlacesAutocomplete";

export default function ProveedorForm({
    proveedor, 
    isEdit,
    onSubmitProveedor
}) {
    const dispatch = useDispatch();
    const proveedorSchema = yup.object({
        nombre: yup.string().required("El nombre es obligatorio").nullable(),
        email: yup.string().required("El email es obligatorio").nullable(),
        cif: yup.string().nullable(),
        telefono: yup.string().required("El teléfono es obligatorio").nullable(),
        plataforma_id: yup.number().integer().required("La plataforma es obligatoria").nullable(),
        direccion:  yup.string().nullable().required("La dirección es obligatoria"),
        codigo_postal: yup.string().nullable().required("El código postal es obligatorio"),
        poblacion: yup.string().nullable().required("La población es obligatoria"),
        area: yup.string().nullable().required("La área es obligatoria"),
        pais_id: yup.number().integer().required("El país es obligatorio").nullable(),
      }).required();

    const { register, formState: { errors }, handleSubmit, setValue, reset, control, getValues, watch } = useForm({
        resolver: yupResolver(proveedorSchema),
        mode: "onChange",
        defaultValues: {
            id: 0,
            nombre: '',
            email: '',
            iban: '',
            tipo_banco_id: null,
            telefono: '',
            descuento: '',
            plataforma_id: 1,
            direccion: '',
            codigo_postal: '',
            poblacion: '',
            area: '',
            pais_id: 1,
            integracion: 0,
            credito: 0,
            bloqueado: 0,
            envios: 0,
            envios_propios_carroceria: 0,
            envios_baleares: 0,
            email_pedidos: 0,
            email_envios: 0,
        }
    });
    const paises = useSelector(state => {
        const { paises } = state;
        return paises;
    });
    const plataformas = useSelector(state => {
        const { plataformas } = state;
        return plataformas;
    });
    const tipoGarantias = useSelector(state => {
        const { tipo_garantias } = state;
        return tipo_garantias;
    });
    const tipoBancos = useSelector(state => {
        const { tipo_bancos } = state;
        return tipo_bancos;
    });
    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        if(proveedor) reset({
            ...proveedor,
            doc_sepa: null,
            doc_acuerdo_comercial: null
        });
    }, []);

    const handleSubmitProveedor = async (data, e) => {
        e.preventDefault();
        let dataAux;

        delete data.pais;
        delete data.plataforma;
        delete data.tipo_garantia;
        delete data.transportistas_favoritos;

        if(!isEdit) { delete data.id; }

        if(data.sepa_file?.length > 0 || data.ac_file?.length > 0) {
            dataAux = new FormData();

            Object.entries(data).forEach((value) => {
                if((value[0] === 'sepa_file' || value[0] === 'ac_file') && value[1]?.length > 0) {
                    dataAux.append(value[0], value[1][0])
                } else { dataAux.append(value[0], value[1]) }
            });
        } else { dataAux = data; }

        onSubmitProveedor(dataAux);
    };

    const handleChangeGarantia = (e) => {  setValue('tipo_garantia_id', parseInt(e.target.value));  }
    const handleChangePlataforma = (e) => { setValue('plataforma_id', parseInt(e.target.value)); }
    const handleChangeTipoBanco = (e) => setValue('tipo_banco_id', parseInt(e.target.value));

    const handleChangePais = async(e) => {
        const value = parseInt(e.target.value);

        setValue('poblacion', null);
        setValue('codigo_postal', null);
        setValue('area', null);
        setValue('pais_id', value);
    }

    const handleDownloadDocumentacion = async(doc) => {
        dispatch({type: 'loading/set', payload: true});
        const exportResult = await downloadDocumentacion(doc);

        if(exportResult) {
            dispatch({type: 'loading/set', payload: false});
            toast.success('Documento descargado correctamente!')
            
            const url = window.URL.createObjectURL(new Blob([exportResult]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', doc);
            document.body.appendChild(link);
            link.click();
        } else {
            toast.error('Ups! Algo ha ido mal...')
        }
    }

    const handleChangeDireccion = (direccion) => {
        Object.keys(direccion).forEach(function(key) {
            setValue(key, direccion[key])
        });
    }
   
    return (
        <div className="w-100 d-flex">
            <form className="form w-100" onSubmit={handleSubmit(handleSubmitProveedor)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={isEdit ? proveedor.id : ''} />

                <div className="w-100 d-flex">
                    <div className="w-100 d-flex flex-column">
                        <div className="w-100 table-responsive">
                            <table className="table">
                                <tbody>
                                    { (user.rol_id === 1 || user.rol_id === 4 || user.rol_id === 6 || user.rol_id === 5 || user.responsable) ?
                                        <>
                                            <tr>
                                                <td width="5%" className="text-center">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("bloqueado")} 
                                                        defaultChecked={proveedor? proveedor.bloqueado : false} />
                                                </td>
                                                <td>
                                                    <label className="me-3">Bloqueado</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="5%" className="text-center">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("envios_propios_carroceria")} 
                                                        disabled={watch('bloqueado')}
                                                        onClick={(e) => e.preventDefault()}
                                                        style={{cursor: 'not-allowed'}}
                                                        defaultChecked={proveedor? proveedor.envios_propios_carroceria : true} />
                                                </td>
                                                <td>
                                                    <label className="me-3">Envios propios carrocería</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="5%" className="text-center">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("envios")} 
                                                        disabled={watch('bloqueado')}
                                                        defaultChecked={proveedor? proveedor.envios : true} />
                                                </td>
                                                <td>
                                                    <label className="me-3">Envios</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="5%" className="text-center">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("envio_dia_siguiente")} 
                                                        defaultChecked={proveedor? proveedor.envio_dia_siguiente : false} />
                                                </td>
                                                <td>
                                                    <label className="me-3">Envios el dia siguiente?</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="5%" className="text-center">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("envios_baleares")} 
                                                        disabled={watch('bloqueado')}
                                                        defaultChecked={proveedor? proveedor.envios_baleares : true} />
                                                </td>
                                                <td>
                                                    <label className="me-3">Envios Baleares</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="5%" className="text-center">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("email_pedidos")} 
                                                        disabled={watch('bloqueado')}
                                                        defaultChecked={proveedor? proveedor.email_pedidos : true} />
                                                </td>
                                                <td>
                                                    <label className="me-3">Email de pedidos</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="5%" className="text-center">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("email_envios")} 
                                                        disabled={watch('bloqueado')}
                                                        defaultChecked={proveedor? proveedor.email_envios : true} />
                                                </td>
                                                <td>
                                                    <label className="me-3">Email de envíos</label>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td width="5%" className="text-center">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("credito")} 
                                                        disabled={watch('bloqueado')}
                                                        defaultChecked={proveedor? proveedor.credito : false} />
                                                </td>
                                                <td>
                                                    <label className="me-3">Pagamos a crédito?</label>
                                                </td>
                                            </tr>

                                            { (watch('credito')) ?
                                                <tr>
                                                    <td width="5%" className="text-center">
                                                        <input 
                                                            type="checkbox"
                                                            {...register("sepa")}
                                                            disabled={watch('bloqueado')}
                                                            defaultChecked={proveedor? proveedor.sepa : false} />
                                                    </td>
                                                    <td>
                                                        <label className="me-3">Es SEPA?</label>
                                                    </td>
                                                </tr>
                                                : null
                                            }

                                            <tr>
                                                <td width="5%" className="text-center">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("integracion")} 
                                                        disabled={watch('bloqueado')}
                                                        defaultChecked={proveedor? proveedor.integracion : false} />
                                                </td>
                                                <td>
                                                    <label className="me-3">Está integrado?</label>
                                                </td>
                                            </tr>
                                        </>
                                        
                                        : null
                                    }
                                </tbody>
                            </table>
                        </div>
                                                    
                        <h6 className="text-uppercase mt-3">Datos de facturación</h6>

                        <div className="w-100 d-flex align-items-center my-3">
                            <div className="w-100 d-flex align-items-center">
                                <label htmlFor="descuento">Tipo de descuento</label>

                                <div className="mx-3">
                                    <input
                                        {...register("descuento", { required: true })}
                                        type="radio"
                                        name="descuento"
                                        value={"fijo"}
                                        disabled={watch('bloqueado')}
                                        id="descuento"
                                    />
                                    &nbsp; <label>Fijo</label>
                                </div>

                                <div>
                                    <input
                                        {...register("descuento", { required: true })}
                                        type="radio"
                                        name="descuento"
                                        value={"variable"}
                                        disabled={watch('bloqueado')}
                                        id="descuento"
                                    />
                                    &nbsp; <label>Variable</label>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center">
                            <div className="form-group me-3">
                                <label htmlFor="nombre">Nombre</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    maxLength={50}
                                    disabled={watch('bloqueado')}
                                    {...register("nombre")} 
                                    defaultValue={isEdit ? proveedor.nombre : ''} />
                                
                                <div className="form-invalid">
                                    {(errors.nombre) && errors.nombre?.message}
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="nombre">Nombre FISCAL</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    disabled={watch('bloqueado')}
                                    {...register("nombre_fiscal")} 
                                    defaultValue={isEdit ? proveedor.nombre_fiscal : ''} />
                            </div>
                        </div>

                        <div className="d-flex align-items-center">
                            <div className="form-group me-3">
                                <label htmlFor="cif">CIF</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    {...register("cif")} 
                                    disabled={watch('bloqueado')}
                                    defaultValue={isEdit ? proveedor.cif : ''} />

                                <div className="form-invalid">
                                    {(errors.cif) && errors.cif?.message}
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="email">Email de comunicación</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    disabled={watch('bloqueado')}
                                    {...register("email", { required: true })} 
                                    defaultValue={isEdit ? proveedor.email : ''} />

                                <div className="form-invalid">
                                    {(errors.email) && errors.email?.message}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center">
                            <div className="form-group me-3">
                                <label htmlFor="telefono">Teléfono</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    disabled={watch('bloqueado')}
                                    {...register("telefono", { required: true })} 
                                    defaultValue={isEdit ? proveedor.telefono : ''} />

                                <div className="form-invalid">
                                    {(errors.telefono) && errors.telefono?.message}
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="plataforma_id">Plataforma</label>
                                { (plataformas && plataformas.length > 0) &&
                                    <select 
                                        {...register("plataforma_id")}
                                        disabled={watch('bloqueado')}
                                        defaultValue={isEdit ? proveedor.plataforma_id : 1}
                                        onChange={handleChangePlataforma}>
                                        {plataformas.map(op => <option key={'plataformas-' + op.id} value={op.id}>{op.nombre}</option>)}
                                    </select>
                                }

                                <div className="form-invalid">
                                    {(errors.plataforma_id) && errors.plataforma_id?.message}
                                </div>
                            </div>
                        </div>

                        <h6 className="text-uppercase mt-3">Dirección de facturación</h6>
                        <div className="d-flex align-items-center mb-3">
                            <div className="me-3">
                                { (paises && paises.length > 0) &&
                                    <select 
                                        disabled={watch('bloqueado')}
                                        {...register("pais_id", { required: true})}
                                        defaultValue={isEdit ? proveedor.pais_id : 1}
                                        onChange={handleChangePais}>
                                        {paises.map(op => <option key={'paises-' + op.id} value={op.id}>{op.nombre}</option>)}
                                    </select>
                                }

                                <div className="form-invalid">
                                    {(errors.pais_id) && errors.pais_id?.message}
                                </div>
                            </div>

                            <GooglePlacesAutocomplete 
                                type={'address'}
                                types={['street_address', 'route', 'car_dealer', 'car_rental', 'car_repair']}
                                country={watch('pais_id') ? paises?.find(p => p.id === parseInt(getValues('pais_id')))?.codigo : ''}
                                onSelectResult={handleChangeDireccion}
                            />
                        </div>

                        <div className="d-flex align-items-center">
                            <div className="d-flex flex-column mb-3 me-3">
                                <label htmlFor="codigo_postal" style={{marginBottom: '5px'}}>Código postal</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    {...register("codigo_postal", { maxLength: 150 })} 
                                    defaultValue={isEdit ? proveedor.codigo_postal : ''} />

                                <div className="form-invalid">
                                    {(errors.codigo_postal) && errors.codigo_postal?.message}
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="direccion">Dirección</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    disabled={watch('bloqueado')}
                                    {...register("direccion", { required: true, maxLength: 150 })} 
                                    defaultValue={isEdit ? proveedor.direccion : ''} />

                                <div className="form-invalid">
                                    {(errors.direccion) && errors.direccion?.message}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center">
                            <div className="form-group me-3">
                                <label htmlFor="poblacion">Población</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    {...register("poblacion", { maxLength: 150 })} 
                                    defaultValue={isEdit ? proveedor.poblacion : ''} />

                                <div className="form-invalid">
                                    {(errors.poblacion) && errors.poblacion?.message}
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="area">Area (Provincia, CCAA)</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Provincia, CCAA"
                                    {...register("area", { maxLength: 150 })} 
                                    defaultValue={isEdit ? proveedor.area : ''} />

                                <div className="form-invalid">
                                    {(errors.area) && errors.area?.message}
                                </div>
                            </div>
                        </div>

                        { ((user.rol_id === 1 || user.rol_id === 4 || user.responsable) && watch('credito')) ?
                            <div className="d-flex align-items-center my-3">
                                { watch('sepa') ?
                                    <div className="form-group me-3">
                                        <label htmlFor="sepa_file" className="w-100 d-flex align-items-center justify-content-between">
                                            Documento SEPA
                                            { (isEdit && proveedor?.doc_sepa) ?
                                                <span title="Documento SEPA" onClick={() => handleDownloadDocumentacion(proveedor.doc_sepa)}>
                                                    <FontAwesomeIcon icon={faFilePdf} size="lg" className="action" color="#215732"></FontAwesomeIcon>
                                                </span>
                                                : null
                                            }
                                        </label>
                                        <input 
                                            type="file" 
                                            className="form-control" 
                                            disabled={watch('bloqueado')}
                                            {...register("sepa_file")} 
                                            defaultValue={''} />
                                    </div>
                                    : null
                                }

                                <div className="form-group">
                                    <label htmlFor="ac_file" className="w-100 d-flex align-items-center justify-content-between">
                                        Documento de acuerdo comercial
                                        { (isEdit && proveedor.doc_acuerdo_comercial) ?
                                            <span title="Documento Acuerdo Comercial" onClick={() => handleDownloadDocumentacion(proveedor.doc_acuerdo_comercial)}>
                                                <FontAwesomeIcon icon={faFilePdf} size="lg" className="action" color="#215732"></FontAwesomeIcon>
                                            </span>
                                            : null
                                        }
                                    </label>
                                    <input 
                                        type="file" 
                                        className="form-control" 
                                        disabled={watch('bloqueado')}
                                        {...register("ac_file")} 
                                        defaultValue={''} />
                                </div>
                            </div>
                            : null
                        }

                        <h6 className="text-uppercase mt-3">Datos bancarios</h6>
                        <div className="d-flex align-items-center">
                            <div className="form-group me-3">
                                <label htmlFor="iban">IBAN</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    {...register("iban", { maxLength: 150 })} 
                                    disabled={watch('bloqueado')}
                                    defaultValue={isEdit ? proveedor.iban : ''} />

                                <div className="form-invalid">
                                    {(errors.iban) && errors.iban?.message}
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="tipo_banco_id">Tipo Banco</label>
                                { (tipoBancos && tipoBancos.length > 0) &&
                                    <select 
                                        {...register("tipo_banco_id", {required: true})}
                                        defaultValue={isEdit ? proveedor.tipo_banco_id : 1}
                                        disabled={watch('bloqueado')}
                                        onChange={handleChangeTipoBanco}>
                                        {tipoBancos.map(op => <option key={'tipo-banco-' + op.id} value={op.id}>{op.nombre}</option>)}
                                    </select>
                                }
                            </div>
                        </div>

                        <div className="w-100 d-flex flex-column">     
                            <h6 className="text-uppercase mt-3">Garantías</h6>                       
                            <div className="form-group">
                                <label htmlFor="tipo_garantia_id">Tipo de garantía</label>
                                { (tipoGarantias) &&
                                    <select 
                                        disabled={watch('bloqueado')}
                                        {...register("tipo_garantia_id")}
                                        defaultValue={isEdit ? proveedor?.tipo_garantia_id : 1}
                                        onChange={handleChangeGarantia}>
                                        {tipoGarantias.map(op => <option key={'garantias-' + op.id} value={op.id}>{op.duracion}</option>)}
                                    </select>
                                }
                            </div>
                        </div>

                        <h6 className="text-uppercase mt-3">Envíos</h6>

                        <div className="w-100 d-flex align-items-center flex-wrap flex-md-nowrap">
                            <div className="form-group flex-grow-1 me-3">
                                <label htmlFor="max">Envío Portón</label>
                                <input 
                                    type="number" 
                                    step="0.1"
                                    className="form-control" 
                                    min={0}
                                    {...register("envio_porton")} 
                                    disabled={watch('bloqueado')}
                                    defaultValue={isEdit ? proveedor.envio_porton : 0} />
                            </div>     

                            <div className="form-group flex-grow-1 me-3">
                                <label htmlFor="max">Envío Puertas</label>
                                <input 
                                    type="number" 
                                    step="0.1"
                                    className="form-control" 
                                    min={0}
                                    {...register("envio_puertas")} 
                                    disabled={watch('bloqueado')}
                                    defaultValue={isEdit ? proveedor.envio_puertas : 0} />
                            </div> 

                            <div className="form-group flex-grow-1">
                                <label htmlFor="max">Envío Capot</label>
                                <input 
                                    type="number" 
                                    step="0.1"
                                    className="form-control" 
                                    min={0}
                                    {...register("envio_capot")} 
                                    disabled={watch('bloqueado')}
                                    defaultValue={isEdit ? proveedor.envio_capot : 0} />
                            </div>
                        </div>

                        <div className="w-100 d-flex align-items-center flex-wrap flex-md-nowrap">
                            <div className="form-group flex-grow-1 me-3">
                                <label htmlFor="max">Envío Paragolpes</label>
                                <input 
                                    type="number" 
                                    step="0.1"
                                    className="form-control" 
                                    min={0}
                                    {...register("envio_paragolpes")} 
                                    disabled={watch('bloqueado')}
                                    defaultValue={isEdit ? proveedor.envio_paragolpes : 0} />
                            </div>
                            
                            <div className="form-group flex-grow-1 me-3">
                                <label htmlFor="max">Envío Aletas</label>
                                <input 
                                    type="number" 
                                    step="0.1"
                                    className="form-control" 
                                    min={0}
                                    {...register("envio_aleta")} 
                                    disabled={watch('bloqueado')}
                                    defaultValue={isEdit ? proveedor.envio_aleta : 0} />
                            </div>

                            <div className="form-group flex-grow-1">
                                <label htmlFor="max">Envío Transmisiones</label>
                                <input 
                                    type="number" 
                                    step="0.1"
                                    className="form-control" 
                                    min={0}
                                    {...register("envio_transmision")} 
                                    disabled={watch('bloqueado')}
                                    defaultValue={isEdit ? proveedor.envio_transmision : 0} />
                            </div>
                        </div>

                        <div className="w-100 d-flex align-items-center flex-wrap flex-md-nowrap">
                            <div className="form-group flex-grow-1">
                                <label htmlFor="max">Envío Refuerzo Paragolpes</label>
                                <input 
                                    type="number" 
                                    step="0.1"
                                    className="form-control" 
                                    min={0}
                                    {...register("envio_refuerzo_paragolpes")} 
                                    disabled={watch('bloqueado')}
                                    defaultValue={isEdit ? proveedor.envio_refuerzo_paragolpes : 0} />
                            </div>
                        </div>

                        { (watch('envios_baleares')) ?
                            <>
                                <h6 className="text-uppercase mt-3">Envíos baleares</h6>
                            
                                <div className="w-100 d-flex align-items-center flex-wrap flex-md-nowrap">
                                    <div className="form-group flex-grow-1 me-3">
                                        <label htmlFor="max">Envío Portón Baleares</label>
                                        <input 
                                            type="number" 
                                            step="0.1"
                                            className="form-control" 
                                            min={0}
                                            {...register("envio_porton_baleares")} 
                                            disabled={watch('bloqueado')}
                                            defaultValue={isEdit ? proveedor.envio_porton_baleares : 0} />
                                    </div>     

                                    <div className="form-group flex-grow-1 me-3">
                                        <label htmlFor="max">Envío Puertas Baleares</label>
                                        <input 
                                            type="number" 
                                            step="0.1"
                                            className="form-control" 
                                            min={0}
                                            {...register("envio_puertas_baleares")} 
                                            disabled={watch('bloqueado')}
                                            defaultValue={isEdit ? proveedor.envio_puertas_baleares : 0} />
                                    </div> 

                                    <div className="form-group flex-grow-1">
                                        <label htmlFor="max">Envío Capot Baleares</label>
                                        <input 
                                            type="number" 
                                            step="0.1"
                                            className="form-control" 
                                            min={0}
                                            {...register("envio_capot_baleares")} 
                                            disabled={watch('bloqueado')}
                                            defaultValue={isEdit ? proveedor.envio_capot_baleares : 0} />
                                    </div>
                                </div>

                                <div className="w-100 d-flex align-items-center flex-wrap flex-md-nowrap">
                                    <div className="form-group flex-grow-1 me-3">
                                        <label htmlFor="max">Envío Paragolpes Baleares</label>
                                        <input 
                                            type="number" 
                                            step="0.1"
                                            className="form-control" 
                                            min={0}
                                            {...register("envio_paragolpes_baleares")} 
                                            disabled={watch('bloqueado')}
                                            defaultValue={isEdit ? proveedor.envio_paragolpes_baleares : 0} />
                                    </div>

                                    <div className="form-group flex-grow-1 me-3">
                                        <label htmlFor="max">Envío Aletas Baleares</label>
                                        <input 
                                            type="number" 
                                            step="0.1"
                                            className="form-control" 
                                            min={0}
                                            {...register("envio_aleta_baleares")} 
                                            disabled={watch('bloqueado')}
                                            defaultValue={isEdit ? proveedor.envio_aleta_baleares : 0} />
                                    </div>

                                    <div className="form-group flex-grow-1">
                                        <label htmlFor="max">Envío Transmisiones Baleares</label>
                                        <input 
                                            type="number" 
                                            step="0.1"
                                            className="form-control" 
                                            min={0}
                                            {...register("envio_transmision_baleares")} 
                                            disabled={watch('bloqueado')}
                                            defaultValue={isEdit ? proveedor.envio_transmision_baleares : 0} />
                                    </div>
                                </div>

                                <div className="w-100 d-flex align-items-center flex-wrap flex-md-nowrap">
                                    <div className="form-group flex-grow-1">
                                        <label htmlFor="max">Envío Refuerzo Paragolpes Baleares</label>
                                        <input 
                                            type="number" 
                                            step="0.1"
                                            className="form-control" 
                                            min={0}
                                            {...register("envio_refuerzo_paragolpes_baleares")} 
                                            disabled={watch('bloqueado')}
                                            defaultValue={isEdit ? proveedor.envio_refuerzo_paragolpes_baleares : 0} />
                                    </div>
                                </div>
                            </>
                            : null
                        }

                        <h6 className="text-uppercase mt-3">Personas de contacto</h6>
                        <PersonasSubForm
                            proveedor={proveedor}
                            isEdit={isEdit}
                            register={register}
                            disabled={watch('bloqueado')}
                        />

                        <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                    </div>
                </div>
            </form>
        </div>
           
    );
}