import API from '../../../../../services/api';

export const getIncidenciaSteps = async (incidenciaId) => {
    const tipo = incidenciaId ? '?tipo_incidencia_id=' + incidenciaId : '';
    return API.get('/incidencia_steps' + tipo).then(res => res.data);
}

export const newStep = async (step) => {
    return API.post('/incidencia_steps', step).then(res => res.data);
}

export const updateStep = async (step) => {
    return API.put('/incidencia_steps/' + step.id, step)
        .then(res => res.data)
        .catch(error => error.response.data.message);
}

export const deleteStep = async (stepId) => {
    return API.delete('/incidencia_steps/' + stepId).then(res => res.data);
}