import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import moment from "moment";
import { editVacaciones, saveVacaciones } from "../../../../../../../../redux/features/currentProveedor";
import CustomDatePicker from "../../../CustomDatePicker";

export default function VacacionesForm({
    vacaciones, 
    proveedorId, 
    isEdit,
    onActionDone
}) {
    const { register, formState: { errors }, handleSubmit, reset } = useForm({
        mode: "onChange",
        defaultValues: {
            id: 0,
            fecha_inicio: '',
            fecha_fin: '',
            proveedor_id: proveedorId
        }
    });
    const dispatch = useDispatch();
    const [fechaInicio, setFechaInicio] = useState(null);
    const [fechaFin, setFechaFin] = useState(null);

    useEffect(() => {
        reset(vacaciones);

        if(vacaciones) {
            setFechaInicio(new Date(vacaciones.fecha_inicio));
            setFechaFin(new Date(vacaciones.fecha_fin));
        }
    }, [vacaciones]);

    const handleSubmitVacaciones = async(data, e) => {
        e.preventDefault();
        dispatch({type: 'loading/set', payload: true});
        data.fecha_inicio = moment(fechaInicio).format('YYYY-MM-DD');
        data.fecha_fin = moment(fechaFin).format('YYYY-MM-DD');
        
        if(isEdit) {
            dispatch(editVacaciones(data));
        } else {
            delete data.id;
            dispatch(saveVacaciones(data));
        }

        onActionDone();
    }
    
    return (
        <div className="w-100 d-flex flex-column">
            <form className="form mb-4" onSubmit={handleSubmit(handleSubmitVacaciones)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={isEdit ? vacaciones.id : ''} />
                <input 
                    type="hidden" 
                    {...register("proveedor_id")} 
                    defaultValue={isEdit ? vacaciones.proveedor_id : ''} />

                
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <div className="form-group me-0 me-md-3">
                            <CustomDatePicker 
                                label={"Fecha inicial"}
                                date={fechaInicio ? new Date(fechaInicio) : fechaInicio}
                                disableFuture={false}
                                minDateValue={null}
                                onChangeDate={setFechaInicio}
                            />
                        </div>
                        
                        <div className="form-group">
                            <CustomDatePicker 
                                label={"Fecha final"}
                                date={fechaFin ? new Date(fechaFin) : fechaFin}
                                disableFuture={false}
                                minDateValue={fechaInicio}
                                onChangeDate={setFechaFin}
                            />
                        </div>
                    </div>

                <div className="d-flex align-items-center align-self-end">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    )
}