import { getEnvios } from "../views/Dashboard/components/shared/services/envios";
import { asyncMac, makeAsyncTypes, makeFetchingReducer, makeSetReducer } from "./utils";

const asyncEnvios = makeAsyncTypes('envios');

const [setPending, setFullFilled, setError ] = asyncMac(asyncEnvios);

export const enviosFetchingReducer = makeFetchingReducer([
    'envios/pending', 
    'envios/fullfilled', 
    'envios/rejected'
]);

const fullfilledReducer = makeSetReducer(['envios/fullfilled']);

export const fetchEnvios = (page, search) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getEnvios(page, search)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const enviosReducer = fullfilledReducer;
export const enviosStatusReducer = enviosFetchingReducer;