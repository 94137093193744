import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

export default function DetalleForm({
    detalle,
    onSubmit
}) {    
    const { register, formState: { errors }, handleSubmit, reset } = useForm({
        defaultValues: {
            id: 0,
            observaciones: '',
            proveedor_id: ''
        }
    });

    useEffect(() => {
        if(detalle) reset(detalle);
    }, [detalle]);

    const handleSubmitDetalle = async(data, e) => {
        e.preventDefault();
        if(!detalle) delete data.id;
        onSubmit(data);
    }
    
    return (
        <div className="w-100 d-flex flex-column mt-4">
            <form className="form align-items-center mb-4" onSubmit={handleSubmit(handleSubmitDetalle)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={detalle ? detalle.id : ''} />
                <input 
                    type="hidden" 
                    {...register("proveedor_id")} 
                    defaultValue={detalle ? detalle.proveedor_id : ''} />

                <div className="form-group">
                    <label htmlFor="o">Observaciones</label>
                    <textarea
                        {...register("observaciones", {required: true})}
                        defaultValue={detalle ? detalle.observaciones : ""}
                    ></textarea>

                    <div className="form-invalid">
                        {errors.observaciones?.type === 'required' && "Debes introducir observaciones."}
                    </div>
                </div>

                <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
            </form>
        </div>
    )
}