import { asyncMac, makeAsyncTypes, makeFetchingReducer } from "../utils";
import { toast } from 'react-toastify';
import { getUser, newUser, updateUser } from "../../views/Dashboard/components/shared/services/users";

const initialCliente = {};

export const userReducer = (state = initialCliente, action) => {
    switch(action.type){
        case "user/fullfilled": {
            return action.payload;
        }
        default:
            return state
    }
}

const asyncUser = makeAsyncTypes('user');

const [setPending, setFullFilled, setError ] = asyncMac(asyncUser);

export const userFetchingReducer = makeFetchingReducer([
    'user/pending', 
    'user/fullfilled', 
    'user/rejected'
]);

export const fetchUser = (userId) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getUser(userId)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
}

export const saveUser = (user) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await newUser(user)
        const data = await response.data;
        toast.success(response.message);
        dispatch(setFullFilled(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
}

export const editUser = (user) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await updateUser(user)
        const data = await response.data;
        toast.success(response.message);
        dispatch(setFullFilled(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
}

export const currentUserReducer = userReducer;
export const currentUserStatusReducer = userFetchingReducer;