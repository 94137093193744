import { getNotasCargo } from "../views/Dashboard/components/shared/services/nota_cargo";
import { asyncMac, makeAsyncTypes, makeFetchingReducer, makeSetReducer } from "./utils";

const asyncAlbaranes  = makeAsyncTypes('notas_cargo');

const [setPending, setFullFilled, setError ] = asyncMac(asyncAlbaranes);

export const albaranesFetchingReducer = makeFetchingReducer([
    'notas_cargo/pending', 
    'notas_cargo/fullfilled', 
    'notas_cargo/rejected'
]);

const fullfilledReducer = makeSetReducer(['notas_cargo/fullfilled']);

export const fetchNotasCargo = (page, search) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getNotasCargo(page, search)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const notasCargoReducer = fullfilledReducer;
export const notasCargoStatusReducer = albaranesFetchingReducer;