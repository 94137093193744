import { useEffect } from "react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckSquare, faClock, faCross } from "@fortawesome/free-solid-svg-icons";

export default function PosiblesList({
    presupuestos,
    onSelectPresupuesto
}) {
    const navigate = useNavigate();

    useEffect(() => {
        
    }, [presupuestos]);

    const handleGoToPresupuestos = (id) => {
        navigate('/presupuesto/' + id, {replace : true});
    }

    return (
        <div className="content pt-3">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th className="text-center">fecha</th>
                  <th>cliente</th>
                  <th>telf</th>
                  <th><small>Pieza</small></th>
                  <th className="text-center"><small>compra</small></th>
                  <th className="text-center"><small>venta</small></th>
                  <th className="text-center"><small>casco</small></th>
                  <th className="text-center">total IVA</th>
                  <th className="text-center">aceptado</th>
                  <th className="text-center">resp</th>
                  <th className="text-center"></th>
                </tr>
              </thead>

              { presupuestos?.map((presupuesto, index) => {
                  const piezasValues = Object.values(presupuesto.piezas)
                  const piezasRows = piezasValues.map((pieza, i) => {
                
                    const numero = i === 0 ? <td rowSpan={piezasValues.length + 1} width="5%">
                      <span className="link" title="Presupuesto" onClick={() => handleGoToPresupuestos(presupuesto?.id)} >
                        {presupuesto?.numero}
                      </span>
                      
                    </td> : null
                    const fecha = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%" className="text-center">
                      <div className="w-100 d-flex flex-column">
                        <p>{moment(presupuesto.fecha).format("DD-MM-YYYY")}</p>
                      </div>
                    </td> : null
                    const cliente = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%">
                      <Link target="_blank" rel="noopener noreferrer" to={"/cliente/ficha/" + presupuesto.cliente?.id} title="Ficha Cliente" className="text-green text-uppercase">
                        {presupuesto.cliente?.nombre}
                      </Link>
                    </td> : null
                    const clienteTelf = i === 0 ?  <td rowSpan={piezasValues.length + 1}>{presupuesto.cliente.telefono}</td> : null
                    const aceptado = i === 0 ?  <td rowSpan={piezasValues.length + 1} className="text-center"> {presupuesto.expirado ? <FontAwesomeIcon icon={faCross} size="1x" className="action" color="#dc3545" /> : presupuesto.aceptado ? <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#215732" /> : <FontAwesomeIcon icon={faClock} size="1x" className="action" color="#ffc107" />}</td> : null
                    const totalIva = i === 0 ? <td rowSpan={piezasValues.length + 1} width="6%" className="text-center">{parseFloat(presupuesto.total_iva).toFixed(2)}</td> : null
                    const responsable = i === 0 ?  <td rowSpan={piezasValues.length + 1}  className="text-center text-uppercase"><small>{presupuesto.created_by?.username}</small></td> : null
                    const acciones = i === 0 ?  <td rowSpan={piezasValues.length + 1} className="text-center">
                      <span title="Asignar presupuesto" onClick={() => onSelectPresupuesto(presupuesto)}>
                        <FontAwesomeIcon icon={faCheckSquare} size="1x" className="action" color="#215732" />
                      </span>
                    </td> : null

                    return (
                      <tr key={i}>
                        {numero}
                        {fecha}
                        {cliente}
                        {clienteTelf}
                        <td>
                          <div className="w-100 d-flex flex-column text-uppercase">
                            <small className="w-100 d-flex flex-column">
                              <span>
                                {pieza?.venta_relationship?.unidades > 1 ? 
                                  <strong>{'x'+pieza?.venta_relationship?.unidades}</strong> : '' 
                                } 
                                {pieza.nombre}
                              </span>
                              <span>REF: <b>{pieza.referencia_pieza}</b></span>
                              { pieza?.casco > 0 ?
                                <span className="fw-bold text-uppercase highlight"><small>contiene casco</small></span>
                                : ''
                              }
                            </small>

                            <Link target="_blank" rel="noopener noreferrer" to={"/proveedor/ficha/" + pieza.proveedor?.id} title="Ficha Proveedor" className="text-green text-uppercase">
                              {pieza.proveedor.nombre}
                            </Link>
                          </div>
                        </td>
                        <td className="text-center">{parseFloat(pieza.precio_compra).toFixed(2)}€</td>
                        <td className="text-center">{parseFloat(pieza.precio_venta).toFixed(2)}€</td>
                        <td className="text-center">{pieza.casco > 0 ? parseFloat(pieza.casco).toFixed(2) + '€' : '-'}</td>
    
                        {totalIva}
                        {aceptado}
                        {responsable}
                        {acciones}
                      </tr>
                    )
                  })
                  return (
                    <tbody key={index}>
                      {piezasRows}
                    </tbody>
                  )
                }) 
              }
            </table>
          </div>
        </div>
    )
}