import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

export default function ModeloForm({
    modelo, 
    onSubmitModelo
}) {
    const descuentoSchema = yup.object({
        nombre: yup.string().nullable().required("El nombre es obligatorio"),
        marca_id: yup.number().integer()
      }).required();
    const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm({
        resolver: yupResolver(descuentoSchema),
        mode: "onChange",
        defaultValues: {
            id: 0,
            nombre: '',
            marca_id: null
        }
    });
    const marcas = useSelector(state => {
        const { marcas } = state;
        return marcas;
    });

    useEffect(() => {
        reset(modelo);
    }, [modelo]);

    const handleChangeMarca = (e) => {
        setValue('marca_id', parseInt(e.target.value));
    }

    const handleSubmitAlmacen = async(data, e) => {
        e.preventDefault();
        onSubmitModelo(data);
    }

    
    return (
        <div className="w-100 d-flex flex-column">
            <form className="form mb-4" onSubmit={handleSubmit(handleSubmitAlmacen)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={modelo ? modelo.id : ''} />

                <div className="w-100 d-flex flex-column">
                    <div className="form-group me-3">
                        <label htmlFor="nombre">Nombre</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            {...register("nombre")} 
                            defaultValue={modelo ? modelo.nombre : ''} />

                        <div className="form-invalid">
                            {(errors.nombre) && errors.nombre?.message}
                        </div>
                    </div>

                    <div className="form-group me-3">
                        <label htmlFor="nombre">Marca</label>
                        { (marcas && marcas.length > 0) &&
                            <select 
                                {...register("marca_id")}
                                defaultValue={modelo ? modelo?.marca_id : ""}
                                onChange={handleChangeMarca}>
                                <option key={'marcas-0'} value="">Selecciona</option>
                                {marcas.map(op => <option key={'marcas-' + op.id} value={op.id}>{op.nombre}</option>)}
                            </select>
                        }

                        <div className="form-invalid">
                            {(errors.nombre) && errors.nombre?.message}
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    )
}