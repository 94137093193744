import API from '../../../../../services/api';
import { headers } from '../helpers/functionalities';

export const getPresupuestos = async (page = null, search = null) => {
    const pagination = page !== null ? '?page=' + page : '';
    const searcher = search !== null && page !== null ? '&' + search : search !== null ? '?' + search : '';
    return await API.get('/presupuestos' + pagination + searcher).then(res => res.data);
}

export const getPresupuestoCompleto = async (idPresupuesto) => {
    return API.get('/presupuestos/' + idPresupuesto).then(res => res.data);
}

export const generatePresupuestoPDF = async (presupuestoId) => {
    return API.get('/presupuestos/generate_pdf/' + presupuestoId, {responseType: 'blob'}).then(res => res.data);
}

export const calcularTotal = async (presupuestoId) => {
    return API.get('/presupuestos/calcular_total/' + presupuestoId).then(res => res.data);
}

export const enablePresupuesto = async (presupuestoId) => {
    return API.get('/presupuestos/' + presupuestoId + '/enable').then(res => res.data);
}

export const disablePresupuesto = async (presupuestoId) => {
    return API.get('/presupuestos/' + presupuestoId + '/disable').then(res => res.data);
}

export const duplicatePresupuesto = async (presupuestoId) => {
    return API.get('/presupuestos/' + presupuestoId + '/duplicate').then(res => res.data);
}

export const searchPresupuesto = async(search) => {
    return await API.post('/presupuestos/buscar?search=' + search).then(res => res.data);
}

export const changePresupuestoCliente = async (presupuestoId, cliente) => {
    return API.post('/presupuestos/' + presupuestoId + '/cliente', cliente).then(res => res.data);
}

export const newPresupuesto = async (presupuesto) => {
    return API.post('/presupuestos', presupuesto).then(res => res.data);
}

export const actualizarPreciosCompra = async (presupuesto) => {
    return API.post('/presupuestos/' + presupuesto.id + '/precios_compra', presupuesto).then(res => res.data);
}

export const updatePresupuestoInfo = async (presupuesto, id) => {
    return API.post('/presupuestos/' + id + '/update', presupuesto, headers).then(res => res.data);
}

export const updatePresupuestoServicioEnvio = async (servicio, id) => {
    return API.post('/presupuestos/' + id + '/servicios_envio', servicio).then(res => res.data);
}

export const validatePresupuesto = async (presupuesto, id) => {
    return API.post('/presupuestos/' + id + '/validate', presupuesto).then(res => res.data);
}

export const checkIfExistsInOther = async (presupuesto) => {
    return API.post('/presupuestos/check/exists', presupuesto).then(res => res.data);
}

export const exportPresupuestosClientes = async (filters) => {
    return API.post('/presupuestos/export/clientes', filters, {responseType: 'blob'}).then(res => res.data);
}

export const updatePresupuestoDireccionEnvio = async (direccionId, id) => {
    let direccion = {};
    direccion.direccion_envio_id = direccionId;
    return API.put('/presupuestos/' + id, direccion).then(res => res.data);
}

/****** PRESUPUESTO NOTIFICACIONES ******/ 
export const enviarNotificacionResponsable = async (validacion) => {
    return API.post('/presupuestos/validate-notificacion', validacion).then(res => res.data);
}

export const enviarNotificacionRecambista = async (presupuestoId, validacion) => {
    return API.post('/presupuestos/' + presupuestoId + '/no-validate-notificacion',validacion).then(res => res.data);
}