import React from 'react';
import { StackedBarChart } from '../../../../../../../shared/components/Charts/components/StackedBar';
import { getPercentageAtcClass } from '../../../../../../../shared/helpers/functionalities';

export default function GeneralMetrics({view, data}) {
        return (
            <div className='w-100 d-flex flex-column align-items-center justify-content-between my-3'>
                 <div className='card-grid w-100 d-flex flex-wrap flex-md-nowrap'>
                    <div className='w-100 w-md-50 card-item d-flex flex-column align-items-center'>
                        { (view === 'dia') && <h2>Presupuestos diarios</h2>}
                        { (view === 'semana') && <h2>Presupuestos semanales</h2>}
                        { (view === 'mes' || view === 'variable') && <h2>Presupuestos mensuales</h2>}
                        { (view === 'año' || view === 'año-variable') && <h2>Presupuestos anuales</h2>}
                        { (view === 'ultimos-12meses') && <h2>Presupuestos</h2>}
                        <h1>{data.num_presupuestos}</h1>
                    </div>

                    <div className='w-100 w-md-50 card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                        { (view === 'dia') && <h2>Ventas diarias</h2>}
                        { (view === 'semana') && <h2>Ventas semanales</h2>}
                        { (view === 'mes' || view === 'variable') && <h2>Ventas mensuales</h2>}
                        { (view === 'año' || view === 'año-variable') && <h2>Ventas anuales</h2>}
                        { (view === 'ultimos-12meses') && <h2>Ventas</h2>}
                        <h1>{data.num_ventas}</h1>
                    </div>
                </div>

                <div className='card-grid w-100 d-flex mt-4 flex-wrap flex-md-nowrap'>
                    <div className='w-100 w-md-50 card-item d-flex flex-column align-items-center'>
                        { (view === 'dia') && <h2>Facturación diaria</h2>}
                        { (view === 'semana') && <h2>Facturación semanal</h2>}
                        { (view === 'mes' || view === 'variable') && <h2>Facturación mensual</h2>}
                        { (view === 'año' || view === 'año-variable') && <h2>Facturación anual</h2>}
                        { (view === 'ultimos-12meses') && <h2>Facturación</h2>}
                        <h1>{data.facturacion_total}€</h1>
                    </div>

                    <div className='w-100 w-md-50 card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                        { (view === 'dia') && <h2>Beneficio diario</h2>}
                        { (view === 'semana') && <h2>Beneficio semanal</h2>}
                        { (view === 'mes' || view === 'variable') && <h2>Beneficio mensual</h2>}
                        { (view === 'año' || view === 'año-variable') && <h2>Beneficio anual</h2>}
                        { (view === 'ultimos-12meses') && <h2>Beneficio</h2>}
                        <h1>{data.beneficio_total}€</h1>
                    </div>
                </div>

                <div className='card-grid w-100 d-flex mt-4 flex-wrap flex-md-nowrap'>
                    <div className='w-100 w-md-50 card-item d-flex flex-column align-items-center'>
                        { (view === 'dia') && <h2>Ratio de conversión diario</h2>}
                        { (view === 'semana') && <h2>Ratio de conversión semanal</h2>}
                        { (view === 'mes' || view === 'variable') && <h2>Ratio de conversión mensual</h2>}
                        { (view === 'año' || view === 'año-variable') && <h2>Ratio de conversión anual</h2>}
                        { (view === 'ultimos-12meses') && <h2>Ratio de conversión</h2>}
                        <h1 className={getPercentageAtcClass('conversion', data?.ratio_conversion)}>{data.ratio_conversion}%</h1>
                    </div>

                    <div className='w-100 w-md-50 card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                        { (view === 'dia') && <h2>Ratio de beneficio diario</h2>}
                        { (view === 'semana') && <h2>Ratio de beneficio semanal</h2>}
                        { (view === 'mes' || view === 'variable') && <h2>Ratio de beneficio mensual</h2>}
                        { (view === 'año' || view === 'año-variable') && <h2>Ratio de beneficio anual</h2>}
                        { (view === 'ultimos-12meses') && <h2>Ratio de beneficio</h2>}
                        <h1 className={getPercentageAtcClass('beneficio', data?.ratio_beneficio)}>{data.ratio_beneficio}%</h1>
                    </div>
                </div>

                <div className='card-grid w-100 d-flex mt-4 flex-wrap flex-md-nowrap'>
                    <div className='w-100 w-md-50 card-item d-flex flex-column align-items-center'>
                        { (view === 'dia') && <h2>Ratio de incidéncias diarias</h2>}
                        { (view === 'semana') && <h2>Ratio de incidéncias semanales</h2>}
                        { (view === 'mes' || view === 'variable') && <h2>Ratio de incidéncias mensuales</h2>}
                        { (view === 'año' || view === 'año-variable') && <h2>Ratio de incidéncias anuales</h2>}
                        { (view === 'ultimos-12meses') && <h2>Ratio de incidéncias</h2>}
                        <h1 className={getPercentageAtcClass('incidencia', data?.ratio_incidencias)}>{data.ratio_incidencias}%</h1>
                    </div>

                    <div className='w-100 w-md-50 card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                        { (view === 'dia') && <h2>Ratio de pieza no encontrada diarias</h2>}
                        { (view === 'semana') && <h2>Ratio de pieza no encontrada semanal</h2>}
                        { (view === 'mes' || view === 'variable') && <h2>Ratio de pieza no encontrada mensual</h2>}
                        { (view === 'año' || view === 'año-variable') && <h2>Ratio de pieza no encontrada anual</h2>}
                        { (view === 'ultimos-12meses') && <h2>Ratio de pieza no encontrada</h2>}
                        <h1 className={getPercentageAtcClass('no-encontrada', data?.ratio_pieza)}>{data.ratio_pieza}%</h1>
                    </div>
                </div>

                <div className="w-100 d-flex flex-wrap align-items-center justify-content-between mt-5">
                    <div className="col-12 col-md-6 pe-0 pe-md-3">
                        <StackedBarChart 
                            title={'Número de presupuestos'} 
                            dataset={data.num_presupuestos_chart}
                        />
                    </div>

                    <div className="col-12 col-md-6 ps-0 ps-md-3">
                        <StackedBarChart 
                            title={'Número de ventas'} 
                            dataset={data.num_ventas_chart}
                        />
                    </div>
                </div>

                <div className="w-100 d-flex flex-wrap align-items-center justify-content-between mt-5">
                    <div className="col-12 col-md-6 pe-0 pe-md-3">
                        <StackedBarChart 
                            title={'Número de devoluciones'} 
                            dataset={data.num_devoluciones_chart}
                        />
                    </div>

                    <div className="col-12 col-md-6 ps-0 ps-md-3">
                        <StackedBarChart 
                            title={'Número de incidencias'} 
                            dataset={data.num_incidencias_chart}
                        />
                    </div>
                </div>

                <div className="w-100 d-flex flex-wrap align-items-center justify-content-between mt-5">
                    <div className="col-12 col-md-6 pe-0 pe-md-3">
                        <StackedBarChart 
                            title={'Facturación'} 
                            dataset={data.facturacion_chart}
                        />
                    </div>

                    <div className="col-12 col-md-6 ps-0 ps-md-3">
                        <StackedBarChart 
                            title={'Beneficio'} 
                            dataset={data.beneficio_chart}
                        />
                    </div>
                </div>
            </div>
        )
}