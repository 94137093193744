import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";

export default function ContactosList({contactos, onEditContacto}) {

  const handleEdit = (contacto) => { onEditContacto(contacto); }

  return (
    <div className="content">
      <div className="w-100 d-flex flex-column align-items-end content-table">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>fecha</th>
                <th>observaciones</th>
                <th className="text-center">resp</th>
                <th className="text-center">acciones</th>
              </tr>
            </thead>

            <tbody>
              { contactos?.map((contacto) => {
                return (
                  <tr key={contacto.id}>
                    <td>{ moment(contacto.fecha).format('DD/MM/YYYY') }</td>
                    <td>{ contacto.observaciones }</td>
                    <td className="text-center text-uppercase">{ contacto?.owner ? contacto?.owner?.username : '-'}</td>
                    <td className="text-center">
                      <span title="Editar" onClick={() => handleEdit(contacto)}>
                        <FontAwesomeIcon icon={faPen} size="1x" className="action" color="#215732" />
                      </span> 
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}