export const getActions = () => {
    return {
        actions: [
            {
                label: 'Reeenviar Solicitud',
                icon: 'regenerate',
                action: 'regenerate'
            },
            {
                label: 'Ver respuestas',
                icon: 'comment',
                action: 'responses'
            },
            {
                label: 'Ver ficha técnica',
                icon: 'image',
                action: 'ficha'
            },
        ],
        extra: []
    };
}