import { useEffect, useState } from 'react'
import InfoDetail from './InfoDetail'
import InfoTabs from './InfoTabs'
import { getArticlesIAMCross, getArticlesOENCross, getPicturesSupplierArtNum, getPrdInfo } from '../../services/references'
import { toast } from 'react-toastify'
import './moreinfomodal.scss'

const MoreInfoModal = ({modalData, setIsOpenModal}) => {
  const [delayOpen, setDelayOpen] = useState(false)
  const { dataSupplierArticleNumber, supplierId, oemToSearch } = modalData;
  const [dataInfoDetail, setDataInfoDetail] = useState(null)
  const [dataImages, setDataImages] = useState(null)
  const [oemResults, setOemResults] = useState(null)
  const [iamResults, setIamResults] = useState(null)
  const [isLoadingImgs, setIsLoadingImgs] = useState(false)

  useEffect(() => {
    setDelayOpen(true)
  }, [])

  const infoDetailRequest = async() => {
    const res = await getPrdInfo(dataSupplierArticleNumber, supplierId)
    if(res?.data) {
      const dataToShow = res.data.find(item => item.oenbr === oemToSearch)
      return setDataInfoDetail(dataToShow ? dataToShow : res.data[0])
    }
    return toast.error('Error obteniendo información detallada')
  }
  const imgsRequest = async() => {
    setIsLoadingImgs(true)
    const res = await getPicturesSupplierArtNum(dataSupplierArticleNumber, supplierId)
    if(res?.data) {
      setDataImages(res.data)
      return setIsLoadingImgs(false)
    }
    setIsLoadingImgs(false)
    return toast.error('Error obteniendo imagenes')
  }
  const oemRequest = async() => {
    const res = await getArticlesOENCross(dataSupplierArticleNumber, supplierId)
    if(res?.data) {
      return setOemResults(res.data)
    }
    return toast.error('Error obteniendo resultados oem')
  }
  const iamRequest = async() => {
    const res = await getArticlesIAMCross(dataInfoDetail.oenbr)
    if(res?.data) {
      return setIamResults(res.data)
    }
    return toast.error('Error obteniendo resultados iam')
  }

  useEffect(() => {
    infoDetailRequest()
    imgsRequest()
    oemRequest()
  }, [])

  useEffect(() => {
    if(dataInfoDetail) iamRequest()
  }, [dataInfoDetail])
  
  return (
    <div className='more-info-modal'>
      <div className={`more-info-modal__container ${delayOpen ? 'show' : ''}`}>
        <div className='more-info-modal__container--container'>
          <span 
            onClick={() => setIsOpenModal(false)}
            className='more-info-modal__container--container--close'
            >&times;</span>
          <h2 className='more-info-modal__container--container--title'>Información detallada</h2>
          <InfoDetail data={dataInfoDetail} imgs={dataImages} isLoadingImgs={isLoadingImgs} />
          <InfoTabs modalData={modalData} oemResults={oemResults} iamResults={iamResults} dataInfoDetail={dataInfoDetail} />
        </div>
      </div>
      <div 
        className='more-info-modal__overlay'
        onClick={() => setIsOpenModal(false)}
      >
      </div>
    </div>
  )
}

export default MoreInfoModal
