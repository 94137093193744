import { useEffect, useState } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { faBolt, faPlus } from "@fortawesome/free-solid-svg-icons";
import TableSimple from "../shared/TableSimple";
import InfoModal from "../../InfoModal";
import ConfirmModal from "../../ConfirmModal";
import { deleteProcedimientoMejora, newProcedimientoMejora, updateProcedimientoMejora } from "../../../services/dynamicDashboard";
import { toast } from "react-toastify";
import FormSimple from "../shared/FormSimple";
import { setErrorMessage } from "../../../helpers/functionalities";

export default function ProcedimientosMejoras({
    userId,
    procedimientos,
    onActionDone
}) {

    const [currentProcedimiento, setCurrentProcedimiento] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    useEffect(() => {
        
    }, [procedimientos]);

    const handleOpenForm = (mejora) => {
        if(mejora) setCurrentProcedimiento(mejora);

        setOpenForm(true);
    }

    const handleCloseForm = () => {
        setCurrentProcedimiento(null);
        setOpenForm(false);
    }

    const handleSubmitMejora = async(data) => {
        let response;

        if(!currentProcedimiento) {
            response = await newProcedimientoMejora(data)
            .catch(function (error) {
                toast.error(setErrorMessage(error))
            });
        } else {
            response = await updateProcedimientoMejora(data)
            .catch(function (error) {
                toast.error(setErrorMessage(error))
            });
        }

        if(response && response.success) {
            toast.success(response.message);
            handleCloseForm();
            onActionDone();
        }
    }

    const handleOpenConfirmDelete = (mejora) => {
        setCurrentProcedimiento(mejora);
        setOpenConfirmDelete(true);
    }
    const handleCloseConfirmDelete = () => {
        setCurrentProcedimiento(null);
        setOpenConfirmDelete(false);
    }

    const handleConfirmDelete = async(confirm) => {
        if(confirm) {
            const response = await deleteProcedimientoMejora(currentProcedimiento.id)
            .catch(function (error) {
                toast.error(setErrorMessage(error))
            });

            if(response && response.success) {
                toast.success(response.message);
                onActionDone();
            }
        }

        handleCloseConfirmDelete();
    }
  
    return (
        <div className="w-100 d-flex flex-column">
            <div className="w-100 mt-4">
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1-content"
                        id="mejoras-metricas"
                    >
                        <FontAwesomeIcon icon={faBolt} size="1x" color="#215732" className="me-3"></FontAwesomeIcon>
                        Lista de procedimientos de mejoras
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="w-100">
                            { (userId) ?
                                <div className="w-100 d-flex align-items-center justify-content-end mb-4">
                                    <button className="btn btn-add" onClick={() => handleOpenForm()}>
                                        <span className="add">
                                            <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                                        </span>
                                        Nuevo procedimiento de mejora
                                    </button>
                                </div>
                                : null
                            }

                            <TableSimple 
                                id={'procedimiento'}
                                userId={userId}
                                label={'procedimientos de mejoras'}
                                items={procedimientos}
                                onOpenForm={handleOpenForm}
                                onDelete={handleOpenConfirmDelete}
                            />
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>

            { (openForm) && 
                <InfoModal
                    state={openForm}
                    title={(currentProcedimiento ? 'Editar' : 'Nuevo') + ' procedimiento de mejora'}
                    content={
                        <FormSimple 
                            id={'procedimientos-mejoras'}
                            item={currentProcedimiento}
                            userId={userId}
                            onSubmitItem={handleSubmitMejora}
                        />
                    }
                    width={'sm'}
                    onClose={handleCloseForm}
                ></InfoModal>
            }

            { openConfirmDelete && 
                <ConfirmModal 
                    onConfirmAction={handleConfirmDelete} 
                    title={'Eliminar'} 
                    description={'Estás seguro de eliminar?'}
                    state={openConfirmDelete}>
                </ConfirmModal>
            }
        </div>
    )
}