import { useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

export default function CodigoAutorizacionForm({
    devolucion,
    onSubmitCodigoAut
}) {
    const schema = yup.object({
       codigo_autorizacion: yup.string().nullable().required("El código de autorización es obligatorio")
    }).required();
    const { 
        register, 
        handleSubmit,
        formState: { errors },
    } = useForm({
            mode: "onChange",
            resolver: yupResolver(schema),
            defaultValues: {
                id: devolucion.id,
                codigo_autorizacion: devolucion.codigo_autorizacion || '',
            }
    });

    useEffect(() => {
    }, []);

    const onSubmit = async (data, e) => {
        e.preventDefault();
        onSubmitCodigoAut(data);
    }

    return (
        <div className="w-100">
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={devolucion.id} />


                <div className="w-100 d-flex flex-column">
                    <div className="form-group">
                        <label htmlFor="codigo_autorizacion">Codigo de autorización</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            {...register("codigo_autorizacion", { required: true, maxLength: 50 })} 
                            defaultValue={devolucion.codigo_autorizacion || ''} />

                        <div className="form-invalid">
                            {(errors.codigo_autorizacion) && errors.codigo_autorizacion?.message}
                        </div>
                    </div>
                            
                    <div className="d-flex w-100 justify-content-center">
                        <button type="submit" className="btn btn-primary">Actualizar</button>
                    </div>
                </div>
            </form>
        </div>
    );
}