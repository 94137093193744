export const getActions = (item) => {
    return {
        actions: [
            {
                label: item.avisado ? 'Habilitar' : 'Deshabilitar',
                icon: item.avisado ? 'check' : 'cancel',
                action: 'change'
            }
        ],
        extra: []
    };
}