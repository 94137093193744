import React from "react";
import { useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faPen, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import AvisoForm from "../../../shared/AvisoForm";
import moment from "moment";
import { markAsReadAviso, newAviso, updateAviso } from "../../../../../shared/services/avisos";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { fetchIncidencia } from "../../../../../../../../redux/features/fichaIncidencia";
import { setErrorMessage } from "../../../../../shared/helpers/functionalities";

export default function AvisosModal({
    title,  
    state, 
    incidencia,
    onClose,
}) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(state);
    const [currentAviso, setCurrentAviso] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [isNew, setIsNew] = useState(false);

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        
        setOpen(false);
        onClose();
    };
    

    const handleEditAviso = (aviso) => { setCurrentAviso(aviso); setIsEdit(true); }
    const handleCancel = () => { setCurrentAviso(null); setIsEdit(false); }
    const handleMarkAsRead = async(aviso) => {
        dispatch({type: 'loading/set', payload: true});
        const response = await markAsReadAviso(aviso);

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            dispatch(fetchIncidencia(incidencia.id))
        }
    }
    const handleSubmitAviso = async(data) => {
        dispatch({type: 'loading/set', payload: true});
        let response;

        if(isNew) {
            response = await newAviso(data).catch(function (error) {
                toast.error(setErrorMessage(error))
                dispatch({type: 'loading/set', payload: false});
              });
        } else {
            response = await updateAviso(data).catch(function (error) {
                toast.error(setErrorMessage(error))
                dispatch({type: 'loading/set', payload: false});
            });
        }
        
        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            setIsEdit(false);
            setCurrentAviso(null);
            dispatch(fetchIncidencia(incidencia.id))
        }
    }

    const handleNewAviso = () => setIsNew(true);
    const handleCancelNew = () => setIsNew(false);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="info-title"
            aria-describedby="info-description">
            <DialogTitle id="info-title">
                <div className="w-100 d-flex align-items-center justify-content-between">
                    <p className="fw-bold text-uppercase">{title}</p>

                    <span title="cerrar" className="action" onClick={() => handleClose()}>
                        <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#215732" />
                    </span>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="w-100">
                    <div className="w-100 d-flex align-items-center justify-content-end">
                        <button type="button" className="btn btn-add" onClick={() => handleNewAviso()}>
                            <span className="add">
                                <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                            </span>
                            Nuevo
                        </button>
                    </div>

                    <div>
                        { (isNew) &&
                            <AvisoForm 
                                incidencias={null}
                                incidenciaId={incidencia.id}
                                aviso={null} 
                                isEdit={false}
                                cancel={true}
                                onCancel={handleCancelNew}
                                onSubmitAviso={handleSubmitAviso}
                            />
                        }
                    </div>

                    { incidencia.avisos?.length ? 
                    incidencia.avisos?.sort((a, b) => -a?.fecha?.localeCompare(b?.fecha)).map((aviso, j) => {
                        return (
                            <div key={'avisos-incidencia-'+ j}>
                                { (isEdit && currentAviso.id === aviso.id) ?
                                    <AvisoForm 
                                        incidencias={null}
                                        incidenciaId={incidencia.id}
                                        aviso={aviso} 
                                        isEdit={isEdit}
                                        cancel={true}
                                        onCancel={handleCancel}
                                        onSubmitAviso={handleSubmitAviso}
                                    />
                                :
                                    <div className="w-100 d-flex flex-column aviso">
                                        <div className="w-100 d-flex align-items-center">
                                            <div>
                                                <p className="me-3">{moment(aviso.fecha).format('DD-MM-YYYY')}</p>
                                                <small className="text-uppercase">{aviso.created_by?.username}</small>
                                            </div>
                                            

                                            <div className="flex-grow-1 info">
                                                <p className="text-uppercase fw-bold">{aviso.asunto}</p>
                                                <small>{aviso.concepto}</small>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-center">
                                                <span title="Editar" style={{cursor: 'pointer'}} className="me-2">
                                                    <FontAwesomeIcon icon={faPen} size="1x" className="action" color="#215732" onClick={() => handleEditAviso(aviso)}  />
                                                </span>

                                                { (!aviso.leido) &&
                                                    <span title="Marcar como leído" style={{cursor: 'pointer'}}>
                                                        <FontAwesomeIcon icon={faCheckCircle} size="1x" className="action" color="#215732" onClick={() => handleMarkAsRead(aviso)} />
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                    }
                            </div>
                        )
                    })
                    : <p>No hay avisos</p>
                    }
                </div>
            </DialogContent>
        </Dialog>
    );
}