import { useEffect, useState } from "react";
import React from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import InfoModal from "../../../../../shared/components/InfoModal";
import MarcaForm from "./components/MarcaForm";
import { newMarca, updateMarca } from "../../../../../shared/services/marcas";

export default function Marcas() {
    const [marcasList, setMarcasList] = useState([]);
    const [search, setSearch] = useState(null);
    const [currentMarca, setCurrentMarca] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    
    const marcas = useSelector(state => {
        const { marcas } = state;
        return marcas;
    });


    useEffect(() => {
        setMarcasList(marcas);
    }, []);

    useEffect(() => {
        setMarcasList(marcas);
    }, [marcas]);

    const handleOpenForm = (marca = null) => {
        setCurrentMarca(marca);
        setOpenForm(true);
    }

    const handleCloseForm = () => { 
        setOpenForm(false);
        setCurrentMarca(null);
    }

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearch(value);

        if(value === "") {
            setMarcasList(marcas);
        } else {
            const newMarcas = marcas.filter(m => m.nombre?.toUpperCase().includes(value.toUpperCase()));
            setMarcasList(newMarcas);
        }
    }

    const handleSubmitMarca = async(data) => {
        let response;

        if(currentMarca) {
            response = await updateMarca(data);
        } else response = await newMarca(data);

        if(response && response.success) {
            toast.success(response.message);
            handleCloseForm();
        }
    }

    return (
        <div className="w-100 d-flex flex-column align-items-center"> 
            <div className="w-100 d-flex align-items-center justify-content-between mb-4">
                <div className="me-3">
                    <div className="position-relative">
                        <input 
                            type="text" 
                            className="form-control" 
                            defaultValue={search} 
                            id="filterBy" 
                            placeholder="Busca una marca..."
                            onChange={(e) => handleSearch(e)} 
                        />
                        <FontAwesomeIcon icon={faSearch} color="#215732" className="icon" />
                    </div>
                </div>

                <button className="btn btn-add" onClick={() => handleOpenForm()}>
                    <span className="add">
                        <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                    </span>
                    Nuevo
                </button>
            </div>

            <div className="w-100 table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th className="text-center">acciones</th>
                        </tr>
                    </thead>

                    <tbody>
                        
                        { marcasList?.length > 0 ? marcasList?.map((marca) => {
                                return (
                                    <tr key={'marca-' + marca.id}>
                                        <td>{marca.nombre}</td>
                                        <td width={'15%'} className="text-center">
                                            <span title="Editar" style={{cursor: 'pointer'}}>
                                                <FontAwesomeIcon icon={faPen} size="1x" className="action" color="#215732" onClick={() => handleOpenForm(marca)} />
                                            </span>
                                        </td>
                                    </tr>
                                )
                            })
                            : 
                            <tr>
                                <td colSpan={2} className="text-center">No hay resultados</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>

            { (openForm) && 
                <InfoModal
                    state={openForm}
                    title={(currentMarca ? 'Editar' : 'Nueva') + ' marca'}
                    content={
                        <MarcaForm 
                            marca={currentMarca}
                            onSubmitMarca={handleSubmitMarca}
                        />
                    }
                    width={'sm'}
                    onClose={handleCloseForm}
                ></InfoModal>
            }
        </div>
    )
    
}