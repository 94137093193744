export const getActions = (id, item, user) => {
    const actions = {
        actions: [
            {
                label: 'Editar',
                icon: 'edit',
                action: 'edit'
            }
        ],
        extra: []
    };


    if(((id === 'cliente' || id === 'cadena' || id === 'talleres-cadena') && (item?.credito == true) && (user.rol_id === 1 || user.rol_id === 4))) {
        actions.actions.push({
            label: 'Administrar facturas a credito',
            icon: 'pay',
            action: 'invoices'
        })
    }

    if((user.rol_id === 1 || user.rol_id === 4 || user.responsable)) {
        actions.actions.push({
            label: 'Fusionar',
            icon: 'fusion',
            action: 'fusion'
        })
    }

    return actions;
}