import { useEffect } from "react";
import React from "react";
import { faPen, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomTooltip } from "../../../../../../../../../../../shared/helpers/functionalities";

export default function ProveedorInfo({
    proveedor,
    onEdit
}) {

    useEffect(() => {

    }, [proveedor]);

    const isMissingInfo = () => {
        const requiredFields = Object.entries(proveedor).filter(value => {
            return value[0] === 'nombre' ||
            value[0] === 'cif' ||
            value[0] === 'email' ||
            value[0] === 'telefono' ||
            value[0] === 'direccion' ||
            value[0] === 'codigo_postal' ||
            value[0] === 'poblacion' ||
            value[0] === 'area' ||
            value[0] === 'pais_id' 
        })
        const isEmpty = requiredFields.find(x => {
            return x[1] === null || x[1] === ''
        });

        return isEmpty;
    }

    return (
        <div>
            <small className="text-uppercase">
                <span className="me-2">Proveedor: <b>{proveedor?.nombre}</b></span>
                {(proveedor?.score !== null && proveedor?.score >= 0 && proveedor?.score <= 6.99) && 
                    <CustomTooltip
                        title={
                        <React.Fragment>
                            <p className="mb-0">PROVEEDOR POCO FIABLE</p>
                            <p className={"mb-0 fw-bold " + (proveedor.score <= 6.49 ? 'text-danger' : 'text-warning')}>
                                {(proveedor.score <= 6.49) && "¡Prohibido comprar! Consulta con el responsable de recambios."}
                                {(proveedor.score >= 6.5) && "Tienes que prestar especial atención a este proveedor."}
                            </p>
                        </React.Fragment>
                    }>
                        <span>
                            <FontAwesomeIcon icon={faExclamationTriangle} size="1x" color="#dc3545"></FontAwesomeIcon>
                        </span>
                    </CustomTooltip>
                }

                {(proveedor?.detalles && proveedor?.detalles?.length > 0) && 
                    <CustomTooltip
                        title={
                        <React.Fragment>
                            <ul>
                                { proveedor.detalles?.map((detalle, i) => {
                                    return (
                                        <li key={'detalle-' + i}>{detalle.observaciones}</li>
                                    )
                                })}
                            </ul>
                        </React.Fragment>
                    }>
                        <span>
                            <FontAwesomeIcon icon={faExclamationTriangle} size="1x" color="#ffc107"></FontAwesomeIcon>
                        </span>
                    </CustomTooltip>
                }

                { (isMissingInfo()) &&
                    <CustomTooltip
                        title={
                        <React.Fragment>
                            <p>¡¡Faltan datos importantes del proveedor!!</p>
                        </React.Fragment>
                    }>
                        <span>
                            <FontAwesomeIcon icon={faExclamationTriangle} size="1x" color="#dc3545"></FontAwesomeIcon>
                        </span>
                    </CustomTooltip>
                }

                <span 
                    className="ms-2" 
                    title="Editar Proveedor" 
                    style={{cursor: 'pointer'}}
                    onClick={() => onEdit(proveedor)}
                >
                    <FontAwesomeIcon icon={faPen} size="1x" color="#215732"></FontAwesomeIcon>
                </span>
            </small>
        </div>
      
    )
}