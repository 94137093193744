import { useState, useEffect } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckCircle, faImage, faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import MessageForm from "./components/MessageForm";
import InfoModal from "../../../shared/components/InfoModal";
import { transferCheckoutDenied, transferCheckoutDone } from "../../../shared/services/checkout";
import { getComprobante } from "../../../shared/services/finanzas";
import { useDispatch } from "react-redux";

export default function Transferencias({
    from,
    presupuestos, 
    onApproval
}) {
    const dispatch = useDispatch();
    const [currentCheckoutId, setCheckoutId] = useState(null);
    const [isView, setIsView] = useState(null); 
    const [isDenied, setIsDenied] = useState(false);

    useEffect(() => {
        
    }, []);

    const showImage = async(comprobante, id) => { 
        dispatch({type: 'loading/set', payload: true});
        const comprobanteResult = await getComprobante(comprobante, from);

        if(comprobanteResult) {
            dispatch({type: 'loading/set', payload: false});
            setIsView(id);

            const url = window.URL.createObjectURL(new Blob([comprobanteResult]));
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.setAttribute('download', comprobante);
            document.body.appendChild(link);
            link.click();
        } else {
            toast.error('Ups! Algo ha ido mal...')
        }
    };

    const handleApproval = async(checkoutId, aprobado) => {
        dispatch({type: 'loading/set', payload: true});
        const response = await transferCheckoutDone(checkoutId, {
            aprobado: aprobado,
            type: from
        });

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            onApproval();
        }
    }

    const handleOpenDenied = (checkoutId) => {
        setCheckoutId(checkoutId);
        setIsDenied(true);
    }

    const handleDenied = async(data) => {
        dispatch({type: 'loading/set', payload: true});
        data.type = from;
        const response = await transferCheckoutDenied(currentCheckoutId, data);

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            setCheckoutId(null);
            setIsDenied(false);
        }
    }

    return (
        <div className="w-100 mt-3">
            <div className="w-100 table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>comprobante</th>
                            <th>cliente</th>
                            <th>teléfono</th>
                            <th>presupuesto</th>
                            <th className="text-center">total IVA</th>
                            <th className="text-center">Ecommerce</th>
                            <th className="text-center" width="6%">acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                    { presupuestos?.map((presupuesto, i) => {
                        return (
                            <tr key={'approval-' +  presupuesto.id}>
                                <td>
                                    <span title="Ver comprobante" style={{ cursor: 'pointer' }}>
                                        <FontAwesomeIcon icon={faImage} size="1x" className="action" onClick={() => showImage(presupuesto.comprobante_img, presupuesto.id)} color="#215732" />
                                    </span> 
                                </td>
                                <td>{presupuesto.cliente.nombre}</td>
                                <td>{presupuesto.cliente.telefono}</td>
                                <td>
                                    <span className="fw-bold">{presupuesto.numero}</span>
                                </td>
                                <td className="text-center">{presupuesto.total_iva}€</td>
                                <td className="text-center">
                                    { (presupuesto.ecommerce) ?
                                        <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#00986C" />
                                        : 
                                        <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                                    }
                                </td>
                                <td className="text-center">
                                    { isView === presupuesto.id &&
                                        <div className="d-flex align-items-center justify-content-center">
                                            <span title="Aceptar" style={{ cursor: 'pointer' }} className="me-2">
                                                <FontAwesomeIcon icon={faCheckCircle} size="1x" className="action" onClick={() => handleApproval(presupuesto.checkout_id, true)} color="#215732" />
                                            </span> 

                                            <span title="Rechazar" style={{ cursor: 'pointer' }}>
                                                <FontAwesomeIcon icon={faTimesCircle} size="1x" className="action" onClick={() => handleOpenDenied(presupuesto.checkout_id)} color="#dc3545" />
                                            </span> 
                                        </div>
                                    }
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>

            { isDenied &&
                <InfoModal
                    state={isDenied}
                    title="Aprobación de transferencia cancelada"
                    content={
                        <MessageForm 
                            checkoutId={currentCheckoutId}
                            onSubmitMessage={handleDenied} />
                    }
                    width={'sm'}
                    onClose={() => { setIsDenied(false); }}
                ></InfoModal>
            }
        </div>
    )
}