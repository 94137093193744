export const getActions = (item, user) => {
    const actions = {
        actions: [
            {
                label: 'Ver detalles',
                icon: 'details',
                action: 'details'
            },
            {
                label: 'Descargar PDF',
                icon: 'pdf',
                action: 'download'
            },
        ],
        extra: []
    };

    if(!item.ecommerce) {
        actions.actions.push({
            label: 'Duplicar',
            icon: 'duplicate',
            action: 'clone'
        });
    }

    if(item.expirado) {
        actions.actions.push({
            label: 'Reactivar',
            icon: 'enable',
            action: 'enable'
        })
    }

    if(!item.expirado && !item.es_venta && !item.ecommerce) {
        actions.actions.push({
            label: 'Desactivar',
            icon: 'disable',
            action: 'disable'
        })
    }

    if(((!item.validado && (user.responsable || user.rol_id === 1)) || (item.validado_integracion === false && (user.responsable || user.rol_id === 1)))) {
        actions.actions.push({
            label: 'Validar',
            icon: 'check',
            action: 'validate'
        })
    }

    if(((item.validado && (user.responsable || user.rol_id === 1 || user.rol_id === 5)))) {
        actions.actions.push({
            label: 'Invalidar',
            icon: 'forbidden',
            action: 'invalidate'
        })
    }

    return actions;
}