import { useState, useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { DatePicker, LocalizationProvider, PickersDay } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import moment from 'moment';
import { Box } from "@mui/material";

export default function ProximoContactoForm({
    onSubmitPC, 
    proximoContacto
}) {
    const { register, formState: { errors }, handleSubmit, reset } = useForm({
        defaultValues: {
            entity_id: proximoContacto.entity_id,
            proximo_contacto: '',
        }
    });
    const [startDateValue, setStartDateValue] = useState(proximoContacto);
    const [initialDateValue, setInitialDateValue] = useState(proximoContacto);
    useEffect(() => {
        if(proximoContacto) {
            setStartDateValue(proximoContacto.proximo_contacto);
            setInitialDateValue(proximoContacto.proximo_contacto);
            reset(proximoContacto);
        }
    }, [proximoContacto]);

    const handleSubmitProximoContacto = async (data, e) => {
        e.preventDefault();
        data.proximo_contacto = moment(startDateValue).format('YYYY-MM-DD');
        onSubmitPC(data);
    };

    const customDayRenderer = (
        date,
        selectedDates,
        pickersDayProps
      ) => {
        const isWeekend = moment(date).isoWeekday();

        if (isWeekend === 6 || isWeekend === 7) {
          return <PickersDay {...pickersDayProps} disabled />;
        }
        return <PickersDay {...pickersDayProps} />;
    };
 
    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            <form className="form" onSubmit={handleSubmit(handleSubmitProximoContacto)}>
                <input 
                    type="hidden" 
                    {...register("entity_id")} 
                    defaultValue={proximoContacto.entity_id} />

                <div className="w-100 d-flex align-items-center justify-content-between">
                    <div className="form-group me-0 me-md-3">
                        <label>Próximo contacto:</label>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                            <DatePicker
                                renderDay={customDayRenderer}
                                value={startDateValue}
                                onChange={(newValue) => {
                                    setStartDateValue(newValue);
                                }}
                                mask={'__/__/____'}
                                inputFormat="dd/MM/yyyy"
                                renderInput={({ inputRef, inputProps, InputProps }) => (
                                    <Box className="date-picker" sx={{ position: 'relative' }}>
                                        <input className="form-control" ref={inputRef} {...inputProps} />
                                        {InputProps?.endAdornment}
                                    </Box>
                                )}
                            />
                        </LocalizationProvider>
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end mt-3">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    );
}