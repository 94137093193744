import React from "react";

export default function ProveedorPersonasInfo({
    proveedor,
}) {

    return (
        <div className="w-100 d-flex flex-column align-items-stretch justify-content-between">
             <div className="d-flex flex-column">
                <label>Administracion</label>
                <p>Nombre: {proveedor.persona_administracion ? proveedor.persona_administracion : '-'}</p>
                <p>Teléfono: {proveedor.telefono_administracion ? proveedor.telefono_administracion : '-'}</p>
                <p>Email: {proveedor.email_administracion ? proveedor.email_administracion : '-'}</p>
            </div>

            <div className="d-flex flex-column mt-3">
                <label>Recambios</label>
                <p>Nombre: {proveedor.persona_recambios ? proveedor.persona_recambios : '-'}</p>
                <p>Teléfono: {proveedor.telefono_recambios ? proveedor.telefono_recambios : ''}</p>
                <p>Email: {proveedor.email_recambios ? proveedor.email_recambios : '-'}</p>
                
            </div>

            <div className="d-flex flex-column mt-3">
                <label>Incidencias</label>
                <p>Nombre: {proveedor.persona_incidencias ? proveedor.persona_incidencias : '-'}</p>
                <p>Teléfono: {proveedor.telefono_incidencias ? proveedor.telefono_incidencias : '-'}</p>
                <p>Email: {proveedor.email_incidencias ? proveedor.email_incidencias : '-'}</p>
            </div>
        </div>
    )
}