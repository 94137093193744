import { getPiezas } from "../views/Dashboard/components/shared/services/piezas";
import { asyncMac, makeAsyncTypes, makeCrudReducer, makeFetchingReducer, makeSetReducer, reduceReducers } from "./utils";

const crudReducer = makeCrudReducer([
    'piezas/add',
    'piezas/edit',
    'piezas/delete'
])

const asyncPiezas = makeAsyncTypes('piezas');

const [setPending, setFullFilled, setError ] = asyncMac(asyncPiezas);

export const piezasFetchingReducer = makeFetchingReducer([
    'piezas/pending', 
    'piezas/fullfilled', 
    'piezas/rejected'
]);

const fullfilledReducer = makeSetReducer(['piezas/fullfilled']);

export const fetchPiezas = (page, search) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getPiezas(page, search)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const piezasReducer = reduceReducers(crudReducer, fullfilledReducer);
export const piezasStatusReducer = piezasFetchingReducer;