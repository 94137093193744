import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import AutocompleteSelect from "../../../../../shared/components/AutocompleteSelect";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { editDescuento, saveDescuento } from "../../../../../../../../redux/features/currentProveedor";

export default function DescuentoForm({
    descuento, 
    proveedorId, 
    isEdit,
    onActionDone
}) {
    const descuentoSchema = yup.object({
        min: yup.number().min(0, "Tiene que ser 0 o más").required("El mínimo es obligatorio").nullable(),
        max: yup.number().min(0, "Tiene que ser 0 o más").nullable().transform((_, val) => val ? Number(val) : null),
        descuento: yup.number().min(0, "Tiene que ser 0 o más").required("El descuento es obligatorio").nullable(),
      }).required();
    const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm({
        resolver: yupResolver(descuentoSchema),
        mode: "onChange",
        defaultValues: {
            id: 0,
            min: 0,
            max: 0,
            descuento: 0,
            proveedor_id: '',
            tipo_piezas: []
        }
    });
    const dispatch = useDispatch();
    const tipoPiezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });

    useEffect(() => {
        reset(descuento);
    }, [descuento]);

    const handleSubmitDescuento = async(data, e) => {
        e.preventDefault();
        dispatch({type: 'loading/set', payload: true});

        if(data.tipo_piezas && data.tipo_piezas?.length > 0) {
            const piezasIds = data.tipo_piezas.map(p => p.id);
            data.tipo_piezas = piezasIds;
        }

       if(isEdit) { dispatch(editDescuento(data)) } 
       else {
            data.proveedor_id = proveedorId;
            dispatch(saveDescuento(data));
        }

        onActionDone();
    }

    const handleChangeTipoPiezas = (tipoPiezas) => { setValue('tipo_piezas', tipoPiezas); }
    
    return (
        <div className="w-100 d-flex flex-column">
            <form className="form mb-4" onSubmit={handleSubmit(handleSubmitDescuento)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={isEdit ? descuento.id : ''} />
                <input 
                    type="hidden" 
                    {...register("proveedor_id")} 
                    defaultValue={isEdit ? descuento.proveedor_id : ''} />

                <div className="w-100 d-flex">
                    <div className="form-group flex-grow-1 me-3">
                        <label htmlFor="min">Mínimo</label>
                        <input 
                            type="number" 
                            step="1"
                            className="form-control" 
                            min={0}
                            {...register("min", { required: true })} 
                            defaultValue={isEdit ? descuento.min : 0} />
                        
                        <div className="form-invalid">
                            {(errors.min) && errors.min?.message}
                        </div>
                    </div>

                    <div className="form-group flex-grow-1 me-3">
                        <label htmlFor="max">Máximo</label>
                        <input 
                            type="number" 
                            step="1"
                            className="form-control" 
                            min={0}
                            {...register("max")} 
                            defaultValue={isEdit ? descuento.max : 0} />
                        
                        <div className="form-invalid">
                            {(errors.max) && errors.max?.message}
                        </div>
                    </div>

                    <div className="form-group flex-grow-1 me-3">
                        <label htmlFor="descuento">Descuento</label>
                        <input 
                            type="number" 
                            step="1"
                            className="form-control" 
                            min={0}
                            {...register("descuento", { required: true })} 
                            defaultValue={isEdit ? descuento.descuento : 0} />
                        
                        <div className="form-invalid">
                            {(errors.descuento) && errors.descuento?.message}
                        </div>
                    </div>
                </div>

                <div className="w-100 d-flex">
                    <div className="form-group flex-grow-1">
                        <label>Tipo de piezas</label>
                        <AutocompleteSelect 
                            options={tipoPiezas}
                            id={'tipo_piezas'}
                            selectedValues={isEdit ? descuento.tipo_piezas : []}
                            onSetValue={handleChangeTipoPiezas}
                        />
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    )
}