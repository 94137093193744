
const SkeletonImgSlider = () => {
  return (
    <div className='skeleton-img-slider skeleton-animation-slider'>
        <span className='skeleton-img-slider__img'>
        </span>
    </div>
  )
}

export default SkeletonImgSlider
