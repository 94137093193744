import { getProveedores } from "../views/Dashboard/components/shared/services/proveedores";
import { asyncMac, makeAsyncTypes, makeCrudReducer, makeFetchingReducer, makeSetReducer, reduceReducers } from "./utils";

const crudReducer = makeCrudReducer([
    'proveedores/add',
    'proveedores/edit',
    'proveedores/delete'
])

const asyncProveedores = makeAsyncTypes('proveedores');

const [setPending, setFullFilled, setError ] = asyncMac(asyncProveedores);

export const proveedoresFetchingReducer = makeFetchingReducer([
    'proveedores/pending', 
    'proveedores/fullfilled', 
    'proveedores/rejected'
]);

const fullfilledReducer = makeSetReducer(['proveedores/fullfilled']);

export const fetchProveedores = (page, search) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getProveedores(page, search)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const proveedoresReducer = reduceReducers(crudReducer, fullfilledReducer);
export const proveedoresStatusReducer = proveedoresFetchingReducer;