import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

export default function MarcaForm({
    marca, 
    onSubmitMarca
}) {
    const marcaSchema = yup.object({
        nombre: yup.string().nullable().required("El nombre es obligatorio"),
      }).required();
    const { register, formState: { errors }, handleSubmit, reset } = useForm({
        resolver: yupResolver(marcaSchema),
        mode: "onChange",
        defaultValues: {
            id: 0,
            nombre: '',
        }
    });

    useEffect(() => {
        reset(marca);
    }, [marca]);


    const handleSubmitMarca = async(data, e) => {
        e.preventDefault();
        onSubmitMarca(data);
    }

    
    return (
        <div className="w-100 d-flex flex-column">
            <form className="form mb-4" onSubmit={handleSubmit(handleSubmitMarca)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={marca ? marca.id : ''} />

                <div className="w-100 d-flex flex-column">
                    <div className="d-flex align-items-center">
                        <div className="form-group me-3">
                            <label htmlFor="nombre">Nombre</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                {...register("nombre")} 
                                defaultValue={marca ? marca.nombre : ''} />

                            <div className="form-invalid">
                                {(errors.nombre) && errors.nombre?.message}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    )
}