import { useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import moment from 'moment';
import '../../../../incidencias.scss';
import { useDispatch } from "react-redux";
import { newIncidenciaLogs } from "../../../../../../../../redux/features/fichaIncidencia";

export default function ComentariosIncidencia({incidenciaId, historico}) {
    const dispatch = useDispatch();
    const { register, handleSubmit, setValue } = useForm({
        defaultValues: {
            incidenciaId: incidenciaId,
            comentario: ''
        }
    });
   
    useEffect(() => {
    }, []);

    const onSubmit = async (data, e) => {
        e.preventDefault();
        dispatch({type: 'loading/set', payload: true});
        dispatch(newIncidenciaLogs(data));
    };
 
    return (
        <div className="w-100 h-100 d-flex flex-column justify-content-between chat-container">
            <div className="w-100 h-100 chat-wrapper">
                <h1>Comentarios</h1>
                <div className="d-flex flex-column align-items-start">
                    { historico?.length > 0 && 
                        historico?.map((h, i) => {
                            return (
                                <div key={h.id} className={"w-100 d-flex align-items-center mb-2 chat " + (i % 2 !== 0 ? 'justify-content-end' : '' )}>
                                    <div className={"d-flex align-items-center mb-2 chat-message"}>
                                        <span className="chat-comment">{h.comentario}</span>
                                    </div>
                                    
                                    <div className="chat-info">
                                        <span className="me-3">{moment(h.fecha).format("DD-MM-YYYY hh:mm:ss")}</span>
                                        <span className="text-uppercase">{h.created_by.username}</span>
                                    </div>
                                </div>        
                            )
                        })
                    }   
                </div>
            </div>

            <form className="form mt-4 d-flex flex-column align-items-end" onSubmit={handleSubmit(onSubmit)}>
                <input 
                    type="hidden" 
                    {...register("incidencia_id")} 
                    defaultValue={incidenciaId} />

                <div className="form-group">
                    <label>Escribe un comentario: </label>
                    <textarea
                        {...register("comentario", { required: true })}
                        defaultValue={""}
                    ></textarea>
                </div>

                <button type="submit" className="btn btn-primary">Guardar</button>
            </form>
        </div>
    );
}