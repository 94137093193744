import { useState } from 'react'
import { getInfoByOENbr } from './services/references'
import { toast } from 'react-toastify'
import SearchContainer from './components/SearchContainer'
import MoreInfoModal from './components/MoreInfoModal'
import NoResults from './components/NoResults'
import './tecdoc.scss'

const TecDoc = () => {
  const [oemToSearch, setOemToSearch] = useState('')
  const [searchResults, setSearchResults] = useState(null)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [modalData, setModalData] = useState(null)

  const submitSearch = async () => {
    if(!oemToSearch) {
      setSearchResults(null)
      return toast.error('Introduce referencia OEM a buscar')
    }
    const res = await getInfoByOENbr(oemToSearch)
    if (res) {
      if(res.length > 0) return setSearchResults(res)
      
      const newRes = await getInfoByOENbr(oemToSearch.trim())
      if(newRes) return setSearchResults(newRes)
    }
    return toast.error('Algo ha ido mal...')
  }

  const handleMoreInfo = async (dataSupplierArticleNumber,supplierId) => {
    setModalData({
      dataSupplierArticleNumber,
      supplierId,
      oemToSearch
    })
    setIsOpenModal(true)
  }

  return (
    <div className="pt-3 tecdoc">
      <h1>Buscar referencia OEM</h1>
      <div className='tecdoc__search-container'>
        <SearchContainer 
          oemToSearch={oemToSearch} 
          setOemToSearch={setOemToSearch} 
          submitSearch={submitSearch}
          className='tecdoc__search-container--search' />
      </div>
      <div className='tecdoc__table-container'>
        {
          searchResults && searchResults?.length > 0 &&
          <div className='tecdoc__table-container--table'>
            <table>
              <thead>
                <tr><th>Marcas</th><th>Producto</th><th>NBR Articulo</th><th></th></tr>
              </thead>
              <tbody>
                {searchResults.map((result, idx) => 
                  <tr key={`${idx}-${result.supplierid}`}>
                    <td>{result.description}</td>
                    <td>{result.NormalizedDescription}</td>
                    <td>{result.nbrofarticles}</td>
                    <td><button onClick={() => handleMoreInfo(result.datasupplierarticlenumber, result.supplierid)}>+ info</button></td>
                  </tr>)}
              </tbody>
            </table>
          </div>
        }
        {
          searchResults && searchResults?.length === 0 && 
          <NoResults />
        }
      </div>
      {isOpenModal && <MoreInfoModal modalData={modalData} setIsOpenModal={setIsOpenModal}/>}
    </div>
  )
}

export default TecDoc
