import React, { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useForm } from "react-hook-form";
import VirtualizedAutocompletePersonas from "../../../../../../../../../../../shared/components/AutocompletePersonas";

export default function ProveedorSearcher({
    onNew,
    onProveedorSelected,
    onCancel
}) {
    const { 
        control } = useForm({
        defaultValues: {
            proveedor: null
        }
    });

    useEffect(() => { }, []);

    const handleChangeProveedor = (proveedor) => {
        if(proveedor !== "" || proveedor !== undefined || proveedor !== null) {
            onProveedorSelected(proveedor)
        }
    }
   
    return (
        <div className="w-100">
            <div className="w-100 d-flex align-items-center justify-content-center my-4">
                <VirtualizedAutocompletePersonas 
                    id={"proveedor_id"}
                    control={control}
                    item={'proveedor'}
                    labels={['nombre', 'telefono', 'score']}
                    defaultValue={null}
                    onChange={handleChangeProveedor}
                />

                <button type="button" className="btn btn-add mb-3"  onClick={() => onNew()}>
                    <span className="add">
                        <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                    </span>
                    Nuevo
                </button>

                <span title="Cancelar" className="ms-3" onClick={() => onCancel()}>
                    <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                </span>
            </div>
        </div>
    );
}