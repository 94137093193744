import { toast } from "react-toastify";
import { 
    getAdminData, 
    getGeneralMetrics, 
    getUserMetrics, 
    getPiezasMetrics, 
    getIncidenciasMetrics,
    getClientesMetrics,
    getProveedoresMetrics,
    getGeneralAtcMetrics,
    getAdminAtcData,
    getUserResume,
    getDynamicDashboard,
    getProveedorIncidenciasMetrics,
    filterProveedorIncidenciasMetrics
} from "../../views/Dashboard/components/shared/services/dashboard";
import { getMensajes } from "../../views/Dashboard/components/shared/services/mensajes";
import { asyncMac, makeAsyncTypes, makeFetchingReducer } from "../utils";
import { getMejoras, getMejorasMetricas, getProcedimientosMejoras, getPuntosImportantes } from "../../views/Dashboard/components/shared/services/dynamicDashboard";

const initialDashboard = { 
    admin: {
        general: null,
        userMetrics: null,
        generalMetrics: null,
        piezasMetrics: null,
        incidenciasMetrics: null,
        proveedoresMetrics: null,
        clientesMetrics: null,
        proveedoresIncidenciasMetrics: null
    }, 
    user: { 
        mensajes: null,
        dashboard_dinamico: {
            mejoras_recambista: null,
            mejoras_metricas: null,
            procedimientos_mejora: null,
            puntos_importantes: null,
            metricas: null
        },
        metrics: null
    }
};

export const superDashboardReducer = (state = initialDashboard, action) => {
    switch(action.type){
        case "dashboard/fullfilled": {
            if(action.payload.type === 'admin') {
                const newDash = {...state, admin: { ...state.admin, general: action.payload.data } }
                return newDash;
            } else {
                const mensajes = action.payload.data.filter(msj => msj.visible).sort((a, b )=> b.id - a.id);
                const newDash = {...state, user:  { ...state.user, mensajes: mensajes } }
                return newDash;
            }
        }
        case "general-metrics/fullfilled": {
            const newDash = {...state,  admin: { ...state.admin, generalMetrics: action.payload } }
            return newDash;
        }
        case "user-metrics/fullfilled": {
            const newDash = {...state, admin: { ...state.admin, userMetrics: action.payload } }
            return newDash;
        }
        case "general-atc-metrics/fullfilled": {
            const newDash = {...state,  admin: { ...state.admin, generalAtcMetrics: action.payload, userAtcMetrics: null  } }
            return newDash;
        }
        case "user-atc-metrics/fullfilled": {
            const newDash = {...state, admin: { ...state.admin, userAtcMetrics: action.payload, generalAtcMetrics: null } }
            return newDash;
        }
        case "clientes-metrics/fullfilled": {
            const newDash = {...state, admin: { ...state.admin, clientesMetrics: action.payload } }
            return newDash;
        }
        case "piezas-metrics/fullfilled": {
            const newDash = {...state, admin: { ...state.admin, piezasMetrics: action.payload } }
            return newDash;
        }
        case "incidencias-metrics/fullfilled": {
            const newDash = {...state, admin: { ...state.admin, incidenciasMetrics: action.payload } }
            return newDash;
        }
        case "proveedores-metrics/fullfilled": {
            const newDash = {...state, admin: { ...state.admin, proveedoresMetrics: action.payload } }
            return newDash;
        }
        case "proveedores-incidencias-metrics/fullfilled": {
            const newDash = {...state, admin: { ...state.admin, proveedoresIncidenciasMetrics: action.payload } }
            return newDash;
        }
        case "ranking-incidencias/fullfilled": {
            const newDash = {...state, admin: { ...state.admin, proveedoresIncidenciasMetrics: {...state.admin.proveedoresIncidenciasMetrics, ranking_incidencias: action.payload }} }
            return newDash;
        }


        case "recambistas-messages/fullfilled": {
            const mensajes = action.payload.filter(msj => msj.visible).sort((a, b )=> b.id - a.id);
            const newDash = {...state, user:  { ...state.user, mensajes: mensajes } }
            return newDash;
        }
        case "recambistas-dashboard/fullfilled": {
            const newDash = {...state, user:  { ...state.user, dashboard_dinamico: action.payload } }
            return newDash;
        }
        case "recambistas-dashboard-mejoras/fullfilled": {
            const newDash = {...state, user:  { ...state.user, dashboard_dinamico: { ...state.user.dashboard_dinamico, mejoras_recambista: action.payload } } }
            return newDash;
        }
        case "recambistas-dashboard-mejoras-metricas/fullfilled": {
            const newDash = {...state, user:  { ...state.user, dashboard_dinamico: { ...state.user.dashboard_dinamico, mejoras_metricas: action.payload } } }
            return newDash;
        }
        case "recambistas-dashboard-procedimientos/fullfilled": {
            const newDash = {...state, user:  { ...state.user, dashboard_dinamico: { ...state.user.dashboard_dinamico, procedimientos_mejora: action.payload } } }
            return newDash;
        }
        case "recambistas-dashboard-puntos-importantes/fullfilled": {
            const newDash = {...state, user:  { ...state.user, dashboard_dinamico: { ...state.user.dashboard_dinamico, puntos_importantes: action.payload } } }
            return newDash;
        }
        case "recambistas-metrics/fullfilled": {
            const newDash = {...state, user:  { ...state.user, metrics: action.payload } }
            return newDash;
        }
        default:
            return state
    }
}

const asyncDashboard = makeAsyncTypes('dashboard');
const [setPending, setFullFilled, setError ] = asyncMac(asyncDashboard);

export const dashboardFetchingReducer = makeFetchingReducer([
    'dashboard/pending', 
    'dashboard/fullfilled', 
    'dashboard/rejected'
]);

export const fetchDashboard = () => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getAdminData();
        const data = await response.data;
        dispatch(setFullFilled({type: 'admin', data: data}));
    } catch (e) {
        dispatch(setError(e.message))
        toast.error(e.message)
    }
}

export const fetchDashboardAtc = () => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getAdminAtcData();
        const data = await response.data;
        dispatch(setFullFilled({type: 'admin-atc', data: data}));
    } catch (e) {
        dispatch(setError(e.message))
        toast.error(e.message)
    }
}

export const fetchUserMetrics = (userId, startDate, endDate, view) => async dispatch => {
    try {
        dispatch({type: 'loading/set', payload: true});
        const response = await getUserMetrics(userId, startDate, endDate, view);
        const data = await response.data;
        dispatch({type: 'user-metrics/fullfilled', payload: data})
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(e.message)
    }
}

export const fetchGeneralMetrics = (startDate, endDate, view) => async dispatch => {
    try {
        dispatch({type: 'loading/set', payload: true});
        const response = await getGeneralMetrics(startDate, endDate, view);
        const data = await response.data;
        dispatch({type: 'general-metrics/fullfilled', payload: data})
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(e.message)
    }
}

export const fetchUserAtcMetrics = (userId, startDate, endDate, view, tipoIncidencia, culpa) => async dispatch => {
    try {
        dispatch({type: 'loading/set', payload: true});
        const response = await getGeneralAtcMetrics(startDate, endDate, view, tipoIncidencia, culpa, userId);
        const data = await response.data;
        dispatch({type: 'user-atc-metrics/fullfilled', payload: data})
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(e.message)
    }
}

export const fetchGeneralAtcMetrics = (startDate, endDate, view, tipoIncidencia, culpa) => async dispatch => {
    try {
        dispatch({type: 'loading/set', payload: true});
        const response = await getGeneralAtcMetrics(startDate, endDate, view, tipoIncidencia, culpa);
        const data = await response.data;
        dispatch({type: 'general-atc-metrics/fullfilled', payload: data})
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(e.message)
    }
}

export const fetchClientesMetrics = (startDate, endDate, view) => async dispatch => {
    try {
        dispatch({type: 'loading/set', payload: true});
        const response = await getClientesMetrics(startDate, endDate, view);
        const data = await response.data;
        dispatch({type: 'clientes-metrics/fullfilled', payload: data});
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(e.message)
    }
}

export const fetchProveedoresMetrics = (startDate, endDate, view) => async dispatch => {
    try {
        dispatch({type: 'loading/set', payload: true});
        const response = await getProveedoresMetrics(startDate, endDate, view);
        const data = await response.data;
        dispatch({type: 'proveedores-metrics/fullfilled', payload: data});
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(e.message)
    }
}

export const fetchPiezasMetrics = (startDate, endDate, view) => async dispatch => {
    try {
        dispatch({type: 'loading/set', payload: true});
        const response = await getPiezasMetrics(startDate, endDate, view);
        const data = await response.data;
        dispatch({type: 'piezas-metrics/fullfilled', payload: data})
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(e.message)
    }
}

export const fetchIncidenciasMetrics = (resp, startDate, endDate, view, proveedorId, tipoPiezaId, culpa) => async dispatch => {
    try {
        dispatch({type: 'loading/set', payload: true});
        const response = await getIncidenciasMetrics(resp, startDate, endDate, view, proveedorId, tipoPiezaId, culpa);
        const data = await response.data;
        dispatch({type: 'incidencias-metrics/fullfilled', payload: data})
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(e.message)
    }
}

export const fetchProveedorPiezasMetrics = (startDate, endDate, view, proveedorId) => async dispatch => {
    try {
        dispatch({type: 'loading/set', payload: true});
        const response = await getProveedorIncidenciasMetrics(startDate, endDate, view, proveedorId);
        const data = await response.data;
        dispatch({type: 'proveedores-incidencias-metrics/fullfilled', payload: data})
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(e.message)
    }
}

export const filterProveedorPiezasMetrics = (startDate, endDate, type, culpa, tipo) => async dispatch => {
    try {
        dispatch({type: 'loading/set', payload: true});
        const response = await filterProveedorIncidenciasMetrics(startDate, endDate, type, culpa, tipo);
        const data = await response.data;

        if(type === 'ranking_incidencias') {
            dispatch({type: 'ranking-incidencias/fullfilled', payload: data})
        } else {
            dispatch({type: '/fullfilled', payload: data})
        }
        
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(e.message)
    }
}

// *************** USER *****************

export const fetchRecambistasMessages = () => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getMensajes();
        const data = await response.data;
        dispatch(setFullFilled({type: 'user', data: data}));
    } catch (e) {
        dispatch(setError(e.message))
        toast.error(e.message)
    }
}

export const fetchUserResume = (startDate, endDate) => async dispatch => {
    try {
        dispatch({type: 'loading/set', payload: true});
        const response = await getUserResume(startDate, endDate);
        const data = await response.data;
        dispatch({type: 'recambistas-metrics/fullfilled', payload: data})
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(e.message)
    }
}

export const fetchDynamicDashboard = (startDate = null, endDate = null, userId = null) => async dispatch => {
    try {
        dispatch({type: 'loading/set', payload: true});
        const response = await getDynamicDashboard(startDate, endDate, userId);
        const data = await response.data;
        dispatch({type: 'recambistas-dashboard/fullfilled', payload: data})
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(e.message)
    }
}

export const fetchMejorasRecambista = (userId = null) => async dispatch => {
    try {
        dispatch({type: 'loading/set', payload: true});
        const response = await getMejoras(userId);
        const data = await response.data;
        dispatch({type: 'recambistas-dashboard-mejoras/fullfilled', payload: data})
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(e.message)
    }
}

export const fetchMejorasMetricasRecambista = (userId) => async dispatch => {
    try {
        dispatch({type: 'loading/set', payload: true});
        const response = await getMejorasMetricas(userId);
        const data = await response.data;
        dispatch({type: 'recambistas-dashboard-mejoras-metricas/fullfilled', payload: data})
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(e.message)
    }
}

export const fetcProcedimientosMejorasRecambista = (userId) => async dispatch => {
    try {
        dispatch({type: 'loading/set', payload: true});
        const response = await getProcedimientosMejoras(userId);
        const data = await response.data;
        dispatch({type: 'recambistas-dashboard-procedimientos/fullfilled', payload: data})
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(e.message)
    }
}

export const fetcPuntosImportantesRecambista = (userId) => async dispatch => {
    try {
        dispatch({type: 'loading/set', payload: true});
        const response = await getPuntosImportantes(userId);
        const data = await response.data;
        dispatch({type: 'recambistas-dashboard-puntos-importantes/fullfilled', payload: data})
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(e.message)
    }
}

export const dashboardReducer = superDashboardReducer;
export const dashboardStatusReducer = dashboardFetchingReducer