import { useEffect } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetcProcedimientosMejorasRecambista, fetcPuntosImportantesRecambista, fetchDynamicDashboard, fetchMejorasMetricasRecambista, fetchMejorasRecambista } from "../../../../../../redux/features/dashboard";
import MejorasRecambista from "./MejorasRecambista";
import MejorasMetricas from "./MejorasMetricas";
import ProcedimientosMejoras from "./ProcedimientosMejoras";
import PuntosImportantes from "./PuntosImportantes";

export default function DashboardDinamicoRecambista({
    userId,
    startDate,
    endDate
}) {
   const dispatch = useDispatch();
   const dashboard = useSelector(state => {
        return state.dashboard.entity.user.dashboard_dinamico
    });

    useEffect(() => {
        if(userId) {
            dispatch(fetchDynamicDashboard(startDate, endDate, userId));
        } else dispatch(fetchDynamicDashboard());
        
    }, []);

    const handleReloadMejorasRecambista = () => {
        if(userId) {
            dispatch(fetchMejorasRecambista(userId));
        } else dispatch(fetchMejorasRecambista());
    }

    const handleReloadMejorasMetricasRecambista = () => {
        dispatch(fetchMejorasMetricasRecambista(userId))
    }

    const handleReloadProcedimientosMejoraRecambista = () => {
        dispatch(fetcProcedimientosMejorasRecambista(userId));
    }

    const handleReloadPuntosImportantesRecambista = () => {
        dispatch(fetcPuntosImportantesRecambista(userId));
    }
   
    return (
        <div className="w-100 d-flex flex-column justify-content-stretch">
            <div className="w-100 mb-4">
                <h1>Mejoras</h1>

                <MejorasRecambista 
                    userId={userId}
                    mejoras={dashboard?.mejoras_recambista}
                    onActionDone={handleReloadMejorasRecambista}
                />

                <MejorasMetricas 
                    userId={userId}
                    metricas={dashboard?.mejoras_metricas}
                    userMetrics={dashboard?.metricas}
                    startDate={startDate}
                    endDate={endDate}
                    onActionDone={handleReloadMejorasMetricasRecambista}
                />

                <ProcedimientosMejoras 
                    userId={userId}
                    procedimientos={dashboard?.procedimientos_mejora}
                    onActionDone={handleReloadProcedimientosMejoraRecambista}
                />

                <PuntosImportantes 
                    userId={userId}
                    puntos={dashboard?.puntos_importantes}
                    onActionDone={handleReloadPuntosImportantesRecambista}
                />
            </div>
        </div>
    )
}