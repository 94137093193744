import API from '../../../../../services/api';

export const getDevoluciones = async (page = null, search = null) => {
    const pagination = page !== null ? '?page=' + page : '';
    const searcher = search !== null && page !== null ? '&' + search : search !== null ? '?' + search : '';
    return await API.get('/devoluciones' + pagination + searcher).then(res => res.data);
}

export const getDevolucion = async (devolucionId) => {
    return API.get('/devoluciones/' + devolucionId).then(res => res.data);
}

export const calcularTotal = async (devolucionId) => {
    return API.get('/devoluciones/calcular_total/' + devolucionId).then(res => res.data);
}

export const newDevolucion = async (devolucion) => {
    return API.post('/devoluciones', devolucion).then(res => res.data);
}

export const updateDevolucion = async (devolucion) => {
    return API.put('/devoluciones/' + devolucion.id, devolucion).then(res => res.data);
}

export const deleteDevolucion = async (devolucionId) => {
    return API.delete('/devoluciones/' + devolucionId).then(res => res.data);
}