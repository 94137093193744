import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import AvisoStockForm from './components/AvisoStockForm';

export default function AvisoStockModal({
    open,
    onClose,
    onSubmit, 
}) {

    const handleCloseModal = (event, reason) => { 
        if (reason && reason === "backdropClick") return;
        onClose(); 
    };

  return (
    <Dialog
        open={open}
        onClose={handleCloseModal}
        fullWidth={true}
        maxWidth={'md'}
        aria-labelledby="stock-form-title"
        aria-describedby="stock-form-description">
        <DialogTitle id="stock-form-title">
            <div className="w-100 d-flex align-items-center justify-content-between">
                <p className="fw-bold text-uppercase">Nuevo aviso de stock</p>

                <span title="cerrar" className="action" onClick={() => handleCloseModal()}>
                    <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#215732" />
                </span>
            </div>
        </DialogTitle>
        <DialogContent>
            <AvisoStockForm onSubmitAviso={onSubmit} />
        </DialogContent>
      </Dialog>
  );
}