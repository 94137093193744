import API from '../../../../../services/api';

export const getTransportistas = async () => {
    return await API.get('/transportistas').then(res => res.data);
}

export const getTransportista = async (idTarifa) => {
    return API.get('/transportistas/' + idTarifa).then(res => res.data);
}

export const newTransportista = async (tarifa) => {
    return API.post('/transportistas', tarifa).then(res => res.data);
}

export const updateTransportista = async (tarifa) => {
    return API.put('/transportistas/' + tarifa.id, tarifa).then(res => res.data);
}

export const deleteTransportista = async (idTarifa) => {
    return API.delete('/transportistas/' + idTarifa).then(res => res.data);
}