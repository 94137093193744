import API from '../../../../../services/api';

export const getNotificaciones = async () => {
    return await API.get('/notificaciones').then(res => res.data);
}

export const getRecordatorios = async () => {
    return await API.get('/recordatorios').then(res => res.data);
}

export const archivarNotificacion = async (notificacion, archived) => {
    return await API.put('/notificaciones/' + notificacion.id, {archivado: archived}).then(res => res.data);
}

export const archivarAllNotificaciones = async () => {
    return await API.post('/notificaciones/archivar', { type: 'all' }).then(res => res.data);
}