import API from '../../../../../services/api';

export const getTarifasValor = async (idTarifa) => {
    return await API.get('/tarifas-valores/' + idTarifa).then(res => res.data);
}

export const getTarifaValor = async (idValor) => {
    return API.get('/tarifas-valores/' + idValor).then(res => res.data);
}

export const newTarifaValor = async (valor) => {
    return API.post('/tarifas-valores', valor).then(res => res.data);
}

export const updateTarifaValor = async (valor) => {
    return API.put('/tarifas-valores/' + valor.id, valor).then(res => res.data);
}

export const deleteTarifaValor = async (idValor) => {
    return API.delete('/tarifas-valores/' + idValor).then(res => res.data);
}