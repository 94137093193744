import React, { useEffect } from "react";
import { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default function ConfirmModal({ 
    title, 
    description, 
    content,
    state,
    onConfirmAction,
    onClose
}) {
    const [open, setOpen] = useState(state);

    useEffect(() => {
        setOpen(state)
    }, [state])

    const handleConfirm = (confirm) => {
        setOpen(false);
        onConfirmAction(confirm);
    };

    const handleClose = (event, reason) => { 
        if (reason && reason === "backdropClick") return;
        setOpen(false); 
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="confirm-title"
            aria-describedby="confirm-description">
            <DialogTitle id="confirm-title">
                <div className="w-100 d-flex align-items-center justify-content-between">
                    <p className="fw-bold text-uppercase">{title}</p>

                    <span title="cerrar" className="action" onClick={() => handleClose()}>
                        <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#215732" />
                    </span>
                </div>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="confirm-description">
                    {description}
                </DialogContentText>

                <div className="w-100 mt-3">
                    {content ? content : ''}
                </div>
            </DialogContent>
            <DialogActions>
                <button type="button" className="btn btn-primary" onClick={() => handleConfirm(true)}>Si</button>
                <button type="button" className="btn btn-default" onClick={() => handleConfirm(false)}>No</button>
            </DialogActions>
        </Dialog>
    );
}