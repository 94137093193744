import { useState, useEffect } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import InfoModal from "../../InfoModal";
import MejorasForm from "./MejorasForm";
import { toast } from "react-toastify";
import { deleteMejora, newMejora, updateMejora } from "../../../services/dynamicDashboard";
import { Accordion, AccordionDetails, AccordionSummary, Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ExpandMore } from "@mui/icons-material";
import MejorasTable from "./MejorasTable";
import ConfirmModal from "../../ConfirmModal";
import { setErrorMessage } from "../../../helpers/functionalities";

export default function MejorasRecambista({
    userId,
    mejoras,
    onActionDone
}) {
    const [tab, setTab] = useState('pendientes');
    const [currentMejora, setCurrentMejora] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    useEffect(() => {
        
    }, [mejoras]);

    const handleChangeTab = (e, tab) => setTab(tab);

    const handleOpenForm = (mejora) => {
        if(mejora) setCurrentMejora(mejora);

        setOpenForm(true);
    }

    const handleCloseForm = () => {
        setCurrentMejora(null);
        setOpenForm(false);
    }

    const handleSubmitMejora = async(data) => {
        let response;

        if(!currentMejora) {
            response = await newMejora(data)
            .catch(function (error) {
                toast.error(setErrorMessage(error))
            });
        } else {
            response = await updateMejora(data)
            .catch(function (error) {
                toast.error(setErrorMessage(error))
            });
        }

        if(response && response.success) {
            toast.success(response.message);
            handleCloseForm();
            onActionDone();
        }
    }

    const handleValidate = async(mejora, validate) => {
        const response = await updateMejora({
            id: mejora.id,
            aprobado: validate
        })
        .catch(function (error) {
            toast.error(setErrorMessage(error))
        });

        if(response && response.success) {
            toast.success(response.message);
            onActionDone();
        }
    }

    const handleOpenConfirmDelete = (mejora) => {
        setCurrentMejora(mejora);
        setOpenConfirmDelete(true);
    }
    const handleCloseConfirmDelete = () => {
        setCurrentMejora(null);
        setOpenConfirmDelete(false);
    }

    const handleConfirmDelete = async(confirm) => {
        if(confirm) {
            const response = await deleteMejora(currentMejora.id)
            .catch(function (error) {
                toast.error(setErrorMessage(error))
            });

            if(response && response.success) {
                toast.success(response.message);
                onActionDone();
            }
        }

        handleCloseConfirmDelete();
    }
  
    return (
        <div className="w-100 d-flex flex-column">
            <div className="w-100 d-flex align-items-center justify-content-end">
                { (!userId) ?
                    <button className="btn btn-add" onClick={() => handleOpenForm()}>
                        <span className="add">
                            <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                        </span>
                        Nueva mejora
                    </button>
                    : null
                }
            </div>

            <div className="w-100 mt-4">
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <FontAwesomeIcon icon={faListCheck} size="1x" color="#215732" className="me-3"></FontAwesomeIcon>
                        {userId ? 'Mejoras Recambista' : 'Tus mejoras'}
                    </AccordionSummary>
                    <AccordionDetails>
                        <TabContext value={tab}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChangeTab} aria-label="Mis mejoras">
                                    <Tab label="Pendientes" value="pendientes" />
                                    <Tab label="Validadas" value="aprobadas" />
                                    <Tab label="Descartadas" value="descartadas" />
                                </TabList>
                            </Box>
                            <TabPanel value="pendientes">
                                <MejorasTable 
                                    id={'pendientes'}
                                    userId={userId}
                                    mejoras={mejoras?.filter(m => !m.aprobado && !m.descartado)}
                                    onOpenForm={handleOpenForm}
                                    onDelete={handleOpenConfirmDelete}
                                    onValidate={handleValidate}
                                />
                            </TabPanel>
                            <TabPanel value="aprobadas">
                                <MejorasTable 
                                    id={'aprobadas'}
                                    userId={userId}
                                    mejoras={mejoras?.filter(m => m.aprobado && !m.descartado)}
                                    onOpenForm={handleOpenForm}
                                    onDelete={handleOpenConfirmDelete}
                                />
                            </TabPanel>
                            <TabPanel value="descartadas">
                                <MejorasTable 
                                    id={'descartadas'}
                                    userId={userId}
                                    mejoras={mejoras?.filter(m => m.aprobado && m.descartado)}
                                    onOpenForm={handleOpenForm}
                                    onDelete={handleOpenConfirmDelete}
                                />
                            </TabPanel>
                        </TabContext>
                    </AccordionDetails>
                </Accordion>
            </div>

            { (openForm) && 
                <InfoModal
                    state={openForm}
                    title={(currentMejora ? 'Editar' : 'Nueva') + ' mejora'}
                    content={
                        <MejorasForm 
                            mejora={currentMejora}
                            onSubmitMejora={handleSubmitMejora}
                        />
                    }
                    width={'sm'}
                    onClose={handleCloseForm}
                ></InfoModal>
            }

            { openConfirmDelete && 
                <ConfirmModal 
                    onConfirmAction={handleConfirmDelete} 
                    title={'Eliminar'} 
                    description={'Estás seguro de eliminar?'}
                    state={openConfirmDelete}>
                </ConfirmModal>
            }
        </div>
    )
}