import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getInfoEnvios } from "../../services/envios";
import { setErrorMessage } from "../../helpers/functionalities";
import { toast } from "react-toastify";
import ConfirmModal from "../ConfirmModal";
import GooglePlacesAutocomplete from "../GooglePlacesAutocomplete";
import { useWatch } from "react-hook-form";
import ServicesList from "./ServicesList";
import PresupuestosServicesList from "./PresupuestosServicesList";

export default function EnvioSearcher({
    fromPresupuesto,
    fromSection,
    proveedor,
    pieza,
    tipoPiezaId,
    cliente,
    from,
    to,
    medidas,
    valor,
    direccionEnvio,
    setValue,
    getValues,
    register,
    onSelectServicio,
    onSeguroSelected
}) {
    const recogida = {
        etiqueta: true,
        img: null,
        informacion: null,
        nombre_servicio: "Recoge el cliente",
        nombre_transportista: "",
        porcentaje_seguro: 8,
        precio_tarifa: 0,
        precio_transporte: 0,
        recomendado: false,
        servicio_envio_id: 57,
        transportista_id: 1,
        recogida_id: null,
    };
    const [isLoading, setIsLoading] = useState(false);
    const [optionsList, setOptionsList] = useState(proveedor?.id === 409 ? [recogida] : []);
    const [serviciosEnvioList, setServicioEnvioList] = useState(proveedor?.id === 409 ? [recogida] : []);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedServicio, setSelectedServicio] = useState(null);
    const [origen, setOrigen] = useState(null);
    const [paisOrigen, setPaisOrigen] = useState(1);
    const [destino, setDestino] = useState(null);
    const [paisDestino, setPaisDestino] = useState(1);
    const [precioEnvioBaleares, setPrecioEnvioBaleares] = useState(0);
    const [seguro, setSeguro] = useState(false);
    const [seguroDisabled, setSeguroDisabled] = useState(false);
    const [isVoluminoso, setIsVoluminoso] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [checkedService, setCheckedService] = useState(false);
    const inputRef = useRef(null);
    const watchedValues = useWatch([
        'pais_origen_id',
        'pais_destino_id',
        'remitente_codigo_postal',
        'destinatario_codigo_postal'
    ]);
    const paises = useSelector(state => {
        const { paises } = state;
        return paises;
    });

    useEffect(() => {
        if(fromPresupuesto) {
            const destinoStorage = localStorage.getItem('destino_presupuesto') ?
            JSON.parse(localStorage.getItem('destino_presupuesto')) : null;

            const areaOrigen = proveedor.area?.split(', ');

            let poblacionOrigen = {
                codigo_postal: proveedor.codigo_postal,
                poblacion: proveedor.poblacion,
                provincia: areaOrigen[0],
                area: areaOrigen[1],
                pais_id: proveedor.pais_id,
                pais: paises.find(p => p.id === parseInt(proveedor.pais_id))?.codigo || 'ES'
            };
            
            if(proveedor.poblacion && proveedor.area) {
                setPaisOrigen(poblacionOrigen.pais);
                setValue('pais_origen_id', poblacionOrigen.pais_id);
                setOrigen(poblacionOrigen);
                setValue('origen', poblacionOrigen);
            }

            if(destinoStorage) {
                setDestino(destinoStorage);
                setValue('destino', destinoStorage);
                setPaisDestino(destinoStorage?.pais);
                setValue('pais_destino_id', destinoStorage?.pais_id);
            }

            if(isPuertasPortonesCapots(tipoPiezaId)) {
                setIsVoluminoso(true);
            }
    
            if(direccionEnvio || cliente) {
                let poblacionDestino = null;

                if(cliente) {
                    if(cliente.direcciones?.length === 1) {
                        const areaDestino = cliente.direcciones[0].area?.split(', ');
                        poblacionDestino =  {
                            codigo_postal: cliente.direcciones[0].codigo_postal,
                            poblacion: cliente.direcciones[0].poblacion,
                            provincia: areaDestino[0],
                            area: areaDestino[1],
                            pais_id: cliente.direcciones[0].pais_id,
                            pais: paises.find(p => p.id === parseInt(cliente.direcciones[0].pais_id))?.codigo || 'ES'
                        };
                        
                        setPaisDestino(poblacionDestino.pais_id);
                    }
                }

                if(direccionEnvio) {
                    const areaDestino = direccionEnvio.area?.split(', ');

                    poblacionDestino =  {
                        codigo_postal: direccionEnvio.codigo_postal,
                        poblacion: direccionEnvio.poblacion,
                        provincia: areaDestino[0],
                        area: areaDestino[1],
                        pais_id: direccionEnvio.pais_id,
                        pais: paises.find(p => p.id === parseInt(direccionEnvio.pais_id))?.codigo || 'ES'
                    };
                    setPaisDestino(poblacionDestino.pais_id);
                }
               
                if(poblacionDestino) {
                    setDestino(poblacionDestino);
                    setValue('destino', poblacionDestino);
                    setPaisDestino(poblacionDestino?.pais);
                    setValue('pais_destino_id', poblacionDestino?.pais_id);
                }
            }
        } 
        
    }, [proveedor, direccionEnvio, fromPresupuesto, fromSection]);

    useEffect(() => {
        if(!fromPresupuesto) {
            const areaOrigen = from.area?.split(', ');
            const areaDestino = to.area?.split(', ');     
            const poblacionOrigen = {
                ...from,
                provincia: areaOrigen[0],
                area: areaOrigen[1],
            };
            const poblacionDestino =  {
                ...to,
                provincia: areaDestino[0],
                area: areaDestino[1],
            };
    
            setOrigen(poblacionOrigen);
            setValue('origen', poblacionOrigen);
            setPaisOrigen(from.pais_id);
            setValue('pais_origen_id', poblacionOrigen.pais_id);
            
            setDestino(poblacionDestino);
            setValue('destino', poblacionDestino);
            setPaisDestino(to.pais_id);
            setValue('pais_destino_id', poblacionDestino.pais_id);
        }
    }, [from, to, fromSection, fromPresupuesto]);


    const handleChangeSeguro = (e) => { 
        setValue('seguro', e.target.checked);
        setSelectedOption(null);
        setSeguro(e.target.checked); 
        let optionsListAux;
        const checked = e.target.checked;

        if(checked) {
            optionsListAux = optionsList.map(option => {
                if(option.transportista_id >= 2) {
                    let valorPaquete, precioTransporte, porcentajeSeguro = option.porcentaje_seguro/100;
    
                    if(fromPresupuesto) valorPaquete = pieza.precio_venta;
                    else valorPaquete = valor;
    
                    precioTransporte = (parseFloat(option.precio_transporte) + (porcentajeSeguro * valorPaquete));
                    return {...option, precio_transporte: parseFloat(precioTransporte).toFixed(2) }
                } else return {...option}
            })
        } else optionsListAux = optionsList;
        
        setServicioEnvioList(optionsListAux);
        if(!fromPresupuesto) onSeguroSelected(checked);
    }

    const handleSearchOpcionesEnvio = async() => {
        setIsLoading(true);
        let info = {};
        info.from = fromPresupuesto ? 'presupuesto' : (fromSection ? fromSection : 'envio');
        info.medidas = medidas;
        info.origen = origen;
        info.destino = destino;
        info.proveedor_id = proveedor ? proveedor.id : null;

        if(fromPresupuesto) {
            localStorage.setItem('destino_presupuesto', JSON.stringify(info.destino));
        }

        const response = await getInfoEnvios(info)
        .catch(function (error) {
            toast.error(setErrorMessage(error.message))
        });

        if(response.success) {
            let options;

            if(fromPresupuesto) {
                let servicios = comprobarServicios(response.data);
                options = servicios;
            } else options = [...response.data];

            options = options.sort((a,b) => a.precio_transporte - b.precio_transporte);
            proveedor?.id === 409 && options.unshift(recogida);

            setServicioEnvioList(options);
            setOptionsList(options);
            setIsLoading(false);

            if(proveedor) {
                const withSeguro = ((tipoPiezaId >= 29 && tipoPiezaId <= 35) || (tipoPiezaId >= 1307 && tipoPiezaId <= 1311)) || 
                    ((tipoPiezaId >= 38 && tipoPiezaId <= 50) || (tipoPiezaId >= 1473 && tipoPiezaId <= 1488));
                
                if(withSeguro) {
                    inputRef.current.click();
                    setSeguroDisabled(true);
                } else { setSeguroDisabled(false); }
            }
        }
    }

    const isPuertasPortonesCapots = (tipoPiezaId) => {
        return (tipoPiezaId === 80 || (tipoPiezaId >= 146 && tipoPiezaId <= 151) ||
        (tipoPiezaId >= 174 && tipoPiezaId <= 176) || tipoPiezaId === 184);
    }

    const comprobarServicios = (servicios) => {
        let serviciosPrecios = servicios.map(servicio => {
            if(servicio.transportista_id === 1) {
                if(servicio.servicio_envio_id === 1 || servicio.servicio_envio_id === 6) {
                    if(!isPuertasPortonesCapots(tipoPiezaId)) {
                        servicio.precio_transporte = parseFloat(pieza.precio_transporte);
                    }
                }
            }  

            return servicio;
        });

        return serviciosPrecios
    }

    const handleConfirmServicio = (confirm) => {
        setCheckedService(true);

        if(confirm) {
            setOpenConfirm(false);
            handleSelectServicio(selectedServicio, selectedOption);
        } else {
            setSelectedServicio(null);
            setSelectedOption(null);
            setOpenConfirm(false);
        }
    }

    const handleSelectServicio = (servicio, i) => {
        servicio.seguro = seguro;

        if(fromPresupuesto) {
            if(proveedor.credito) {
                if(!servicio.recomendado && selectedOption !== i) {
                    setSelectedServicio(servicio);
                    setSelectedOption(i);
                    setOpenConfirm(true);
                    return
                }
            }

            if(seguro) {
                servicio.valor_asegurado = pieza.precio_venta
            } else servicio.valor_asegurado = 0;

            setSelectedOption(i);
            onSelectServicio(servicio, proveedor);
        } else {
            if(seguro) {
                servicio.valor_asegurado = valor;
            } else servicio.valor_asegurado = 0;

            setSelectedOption(i);
            onSelectServicio(servicio);
        }
    }

    const handleChangePaisOrigen = async(e) => { 
        const value = parseInt(e.target.value);
        setPaisOrigen(value); 
        setValue('pais_origen_id', value);
        setOrigen(null);
        setValue('origen', null);
    }
    const handleChangePaisDestino = async(e) => { 
        const value = parseInt(e.target.value);
        setPaisDestino(value); 
        setValue('pais_destino_id', value);
        setDestino(null);
        setValue('destino', null);
    }

    const handleChangePrecioEnvio = (e) => {
        const value = parseFloat(e.target.value);
        setPrecioEnvioBaleares(value);

        const optionsListAux = optionsList.map(option => {
            if(option.transportista_id === 1 && option.servicio_envio_id === 1) {
                return {...option, precio_transporte: value.toFixed(2) }
            } else return {...option}
        });

        setServicioEnvioList(optionsListAux);
    }

    const handleChangeRecodigaID = (e, option) => {
        const value = e.target.value;

        const optionsListAux = optionsList.map(op => {
            if(op.transportista_id === option.transportista_id && op.servicio_envio_id === option.servicio_envio_id) {
                return {...op, recogida_id: value }
            } else return {...op}
        });

        setServicioEnvioList(optionsListAux);
    }

    const handleChangeOrigen = (origen) => {
        origen.pais_id = getValues('pais_origen_id');
        origen.pais = paises.find(p => p.id === parseInt(origen.pais_id))?.codigo
        setOrigen(origen);
        setValue('origen', origen);
        setPaisOrigen(origen.pais_id); 
        setValue('pais_origen_id', origen.pais_id);
    }

    const handleChangeDestino = (destino) => {
        destino.pais_id = getValues('pais_destino_id');
        destino.pais = paises.find(p => p.id === parseInt(destino.pais_id))?.codigo
        setDestino(destino);
        setValue('destino', destino);
        setPaisDestino(destino.pais_id); 
        setValue('pais_destino_id', destino.pais_id);
    }

    return (
        <div className="w-100">
            <div className="w-100 d-flex flex-column">
                <div className="w-100 d-flex align-items-center">
                    <div className="form-group">
                        <label className="mb-2" htmlFor="pais_origen_id">ORIGEN</label>
                        <div className="w-100 mb-3">
                            <select
                                {...register("pais_origen_id")}
                                defaultValue={paisOrigen}
                                disabled={true}
                                onChange={handleChangePaisOrigen}>
                                {paises?.map(op => <option key={'paises-' + op.id} value={op.id}>{op.nombre}</option>)}
                            </select>
                        </div>

                        <GooglePlacesAutocomplete
                            type={'postal_code'}
                            types={['(regions)']}
                            defaultValue={origen ? (origen?.codigo_postal + ' - ' + origen?.poblacion) : ''}
                            disabled={true}
                            country={watchedValues ? paises?.find(p => p.id === parseInt(getValues('pais_origen_id')))?.codigo : 'ES'}
                            onSelectResult={handleChangeOrigen}
                        />

                        { (fromPresupuesto && !origen) &&
                            <p className="my-2">Falta información de origen</p>
                        }
                    </div>

                    <div className="form-group ms-3">
                        <label className="mb-2" htmlFor="pais_destino_id">DESTINO</label>

                        <div className="w-100 mb-3">
                            <select
                                {...register("pais_destino_id")}
                                defaultValue={paisDestino}
                                onChange={handleChangePaisDestino}>
                                {paises.map(op => <option key={'paises-' + op.id} value={op.id}>{op.nombre}</option>)}
                            </select>
                        </div>

                        <GooglePlacesAutocomplete
                            type={'postal_code'}
                            types={['(regions)']}
                            defaultValue={destino ? (destino?.codigo_postal + ' - ' + destino?.poblacion) : ''}
                            country={watchedValues? paises?.find(p => p.id === parseInt(getValues('pais_destino_id')))?.codigo : 'ES'}
                            onSelectResult={handleChangeDestino}
                        />
                    </div>

                    <button 
                        type="button" 
                        className="btn btn-primary ms-3" 
                        onClick={() => handleSearchOpcionesEnvio()}
                        disabled={(origen === null || destino === null)}
                    >BUSCAR</button>
                </div>

                <div className="w-100">
                    <div className="d-flex align-items-center justify-content-end">
                        <div className="border border-warning rounded p-2 d-flex align-items-center">
                            <input 
                                type="checkbox"
                                name="seguro"
                                id="seguro"
                                ref={inputRef}
                                disabled={seguroDisabled}
                                onChange={(e) => handleChangeSeguro(e)}
                                checked={seguro} />

                            <label className="mb-0 ms-3">
                                Seguro de envío
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-100 mt-3">
                <div className="w-100">
                    { (fromPresupuesto) ?
                        <PresupuestosServicesList
                            isLoading={isLoading}
                            isVoluminoso={isVoluminoso}
                            destino={destino}
                            proveedor={proveedor}
                            selectedOption={selectedOption}
                            serviciosEnvioList={serviciosEnvioList}
                            precioEnvioBaleares={precioEnvioBaleares}
                            onSelectServicio={handleSelectServicio}
                            onChangePrecioEnvio={handleChangePrecioEnvio}
                        />
                    : 
                        <ServicesList 
                            isLoading={isLoading}
                            isVoluminoso={isVoluminoso}
                            destino={destino}
                            selectedOption={selectedOption}
                            serviciosEnvioList={serviciosEnvioList}
                            precioEnvioBaleares={precioEnvioBaleares}
                            onSelectServicio={handleSelectServicio}
                            onChangePrecioEnvio={handleChangePrecioEnvio}
                        />
                    }
                </div>
            </div>

            { (openConfirm) &&
                <ConfirmModal 
                    onConfirmAction={handleConfirmServicio} 
                    onClose={() => handleConfirmServicio(false)}
                    title={'Seleccion de servicio'} 
                    description={'Estás seguro de seleccionar este servicio y no el recomendado?'}
                    state={openConfirm}>
                </ConfirmModal>
            }
        </div>
     )
}