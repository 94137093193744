import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRecambistasMessages } from '../../../../../../redux/features/dashboard';
import Loading from '../../../shared/components/Loading';
import '../Dashboard.scss';
import MessagesList from './components/MessagesList';

export default function UserDashboard() {
  const dispatch = useDispatch();
  const dashboard = useSelector(state => {
    return state.dashboard.entity.user
  });
  const dashboardStatus = useSelector(state => {
      return state.dashboard.status
  });

  useEffect(() => {
    dispatch(fetchRecambistasMessages());
  }, []);

  
  if(dashboardStatus.loading === 'pending' || dashboardStatus.loading === 'idle') {
    return ( <Loading /> );
  }

  if(dashboardStatus.loading === 'succeed' || dashboardStatus.loading === 'rejected') {
      return (
        <div className='w-100 d-flex flex-column dashboard pb-5'>
            <div className='w-100 d-flex flex-column align-items-start summary mb-4'>
              <MessagesList mensajes={dashboard?.mensajes} />
            </div>
        </div>
      )
  }
}
