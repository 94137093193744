import API from '../../../../../services/api';

export const getHistorico = async (incidenciaId) => {
    return await API.get('/incidencias_logs/historico/' + incidenciaId).then(res => res.data);
}

export const newIncidenciaLog = async (log) => {
    return API.post('/incidencias_logs', log).then(res => res.data);
}

