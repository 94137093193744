export const getActions = (item, user) => {
    const actions = {
        actions: [
            {
                label: 'Ver detalles',
                icon: 'details',
                action: 'details'
            }
        ],
        extra: []
    };

    if(item.active) {
        actions.actions.push({
            label: 'Desactivar',
            icon: 'user-slash',
            action: 'disable'
        })
    } else {
        actions.actions.push({
            label: 'Activar',
            icon: 'user',
            action: 'enable'
        })
    }

    return actions;
}