import React, { useEffect, useState } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';

export default function AutocompleteSelect({
  options, 
  id, 
  groupBy,
  selectedValues, 
  onSetValue
}) {

  const [value, setValue] = useState(selectedValues);
  const handleChangeOptions = (value) => { setValue(value); onSetValue(value); }

  useEffect(() => {
    if(selectedValues) {
      setValue(selectedValues);
    }
  }, [selectedValues]);

  return (
    <div className="w-100">
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          handleChangeOptions(newValue);
        }}
        getOptionLabel={(option) =>  option.nombre}
        multiple
        id={id}
        options={options}
        freeSolo
        groupBy={groupBy ? (option) => `${option[groupBy]}` : null}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option.nombre}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            placeholder="Buscar"
          />
        )}
      />
    </div>
  );
}