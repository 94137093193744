import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { fetchDashboard } from '../../../../../../redux/features/dashboard';
import Loading from '../../../shared/components/Loading';
import '../Dashboard.scss';
import ClientesTab from './components/ClientesTab';
import IncidenciasTab from './components/IncidenciasTab';
import PiezasTab from './components/PiezasTab';
import ProveedoresTab from './components/ProveedoresTab';
import UsersTab from './components/UsersTab';
import AtencionClientesTab from './components/AtencionClientesTab';
import InfoGeneralIncidencias from './components/shared/InfoGeneralIncidencias';
import InfoGeneralRecambistas from './components/shared/InfoGeneralRecambistas';
import ProveedoresIncidenciasTab from './components/ProveedoresIncidenciasTab';

export default function AdminDashboard() {
    const dispatch = useDispatch();
    const [tab, setTab] = useState('usuarios');
    const dashboard = useSelector(state => {
        return state.dashboard.entity.admin
    });

    const dashboardStatus = useSelector(state => {
        return state.dashboard.status
    });

    useEffect(() => {
        dispatch(fetchDashboard());
    }, []);


    const handleChangeTab = (e, tab) => { setTab(tab); }

    if(dashboardStatus.loading === 'pending' || dashboardStatus.loading === 'idle') {
        return ( <Loading /> );
    }
    
    if(dashboardStatus.loading === 'succeed' || dashboardStatus.loading === 'rejected') {
        return (
            <div className='w-100 d-flex flex-column dashboard pb-5'>
                <TabContext value={tab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChangeTab} aria-label="Ajustes">
                            <Tab label="Información general" value="general" />
                            <Tab label="Usuarios" value="usuarios" />
                            <Tab label="Atención al cliente" value="atencion-cliente" />
                            <Tab label="Clientes" value="clientes" />
                            <Tab label="Piezas" value="piezas" />
                            <Tab label="Incidencias" value="incidencias" />
                            <Tab label="Proveedores" value="proveedores" />
                            <Tab label="Proveedores Incidencias" value="proveedores-incidencias" />
                        </TabList>
                    </Box>
                    <TabPanel value="general">
                        <div className='w-100'>
                            <InfoGeneralRecambistas
                                data={dashboard?.general}
                            />

                            <InfoGeneralIncidencias
                                data={dashboard?.general}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel value="usuarios">
                        <UsersTab />
                    </TabPanel>
                    <TabPanel value="atencion-cliente">
                        <AtencionClientesTab />
                    </TabPanel>
                    <TabPanel value="clientes">
                        <ClientesTab />
                    </TabPanel>
                    <TabPanel value="piezas">
                        <PiezasTab />
                    </TabPanel>
                    <TabPanel value="incidencias">
                        <IncidenciasTab />
                    </TabPanel>
                    <TabPanel value="proveedores">
                        <ProveedoresTab />
                    </TabPanel>
                    <TabPanel value="proveedores-incidencias">
                        <ProveedoresIncidenciasTab />
                    </TabPanel>
                </TabContext>
            </div>
        
        )
    }
}
