import { faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState, useEffect } from "react";
import './file_grid.scss';
import LightBoxImages from "../LightBoxImages";
import placeholderVideo from '../../../../../../images/placeholder_video.png';

export default function FilesGrid({
    filesList,
    incidenciaId, 
    canDelete, 
    onDelete,
    onDownload
}) {
    const [files, setFiles] = useState(filesList);
    const [openImage, setOpenImage] = useState(false);
    const [imagesSRCs, setImagesSRCs] = useState([]);
    const [imageIndex, setImageIndex] = useState(0);

    useEffect(() => {
        if(filesList) {
            const imgSRCs = filesList.filter(f => f.ext === 'jpg' || f.ext === 'jpeg' || f.ext === 'png' || f.ext === 'mov' || f.ext === 'mp4')
            .map((img) => {
                if(img.ext === 'jpg' || img.ext === 'jpeg' || img.ext === 'png') {
                    return `data:image/jpeg;base64,${img.file}`;
                } else {
                    if(img.ext === 'mov') {
                        return `data:video/mov;base64,${img.file}`;
                    } else {
                        return `data:video/mp4;base64,${img.file}`;
                    }
                }
            })
        
            setImagesSRCs(imgSRCs);
            setFiles(filesList);
        }
    }, [filesList]);

    const handleDeleteFile = (file) => onDelete(file);
    const handleDownload = (file) => onDownload(file);

    const handleOpenLightBox = (index) => {
        setImageIndex(index);
        setOpenImage(true);
    }

    const handleCloseImages = () => {
        setOpenImage(false);
        setImageIndex(null);
    }

    return (
        <div className="w-100 mt-5">
            <hr />

            <div className="w-100 file-grid mt-4">
                { files && files.length > 0 &&
                    files.map((file, i) => {
                        return (
                            <div className="item mb-4" key={'file' + i}>
                                <span style={{cursor: 'pointer'}}>
                                    <img 
                                        src={(file.ext === 'mp4' || file.ext === 'mov') ? placeholderVideo : `data:image/jpeg;base64,${file.file}`} 
                                        alt={'Imagen incidéncia ' + incidenciaId} 
                                        onClick={() => handleOpenLightBox(i)}
                                    />
                                </span>

                                <div className="w-100 d-flex justify-content-center mb-3">
                                    <label className="fw-bold me-2">{file.name}</label>

                                    <span title="Descargar" style={{cursor: 'pointer'}} onClick={() => handleDownload(file)}>
                                        <FontAwesomeIcon icon={faDownload} size="1x" className="action" color="#215732" />
                                    </span>
                                    { canDelete && 
                                        <span title="Eliminar" className="ms-3" style={{cursor: 'pointer'}} onClick={() => handleDeleteFile(file)}>
                                            <FontAwesomeIcon icon={faTrash} size="1x" className="action" color="#dc3545" />
                                        </span>
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            { (openImage) &&
                <LightBoxImages
                    images={imagesSRCs}
                    startIndex={imageIndex}
                    pieza={"Devolución mercancía"}
                    onClose={handleCloseImages}
                />
            }
        </div>
    );
}