import API from './api';

export const authenticate = async (credentials) => {
    return API.post('/login', credentials, {withCredentials: true,}).then(res => res.data);
}

export const refresh = async (credentials) => {
  return API.post('/refresh', credentials, {withCredentials: true,}).then(res => res.data);
}

export const logout = async (credentials) => {
  return API.post('/logout', credentials).then(res => res.data);
}