import { FaCheck } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

export default function SearchProgress({
    marketPlacesToSearch
}) {
    return (
        <div className={'mx-2'}>
            <h3 className={'mb-2'}>Progreso:</h3>
            { marketPlacesToSearch?.map((marketPlace) => {
                return (
                    <p key={marketPlace.name} className={`mb-1 ${marketPlace.received ? 'text-success' : 'text-default'}`}>
                        { marketPlace.received ? <FaCheck /> : <FontAwesomeIcon icon={faSpinner} size="1x" spin /> }
                        <span className={'ms-1'}>{ marketPlace.name }</span>
                    </p>
                )
            })
            }
        </div>
    );
}