export const initialMarketPlacesOptions = [
    { id: 1, marketPlace: 'recambiofacil', checked: false },
    { id: 2, marketPlace: 'recambioverde', checked: false },
    { id: 3, marketPlace: 'ecooparts', checked: false },
    { id: 4, marketPlace: 'azelerrecambios', checked: false },
    { id: 5, marketPlace: 'recambioseuropiezas', checked: false },
    { id: 6, marketPlace: 'proxyparts', checked: false },
    { id: 7, marketPlace: 'gpa26', checked: false },
    { id: 8, marketPlace: 'lagabasen', checked: false },
    { id: 9, marketPlace: 'bildelsbasen', checked: false },
    { id: 10, marketPlace: 'autoparts24', checked: false },
];

export const nationalMarketPlaces = [
    'recambiofacil',
    'recambioverde',
    'ecooparts',
    'azelerrecambios',
    'recambioseuropiezas'
];
