import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs } from "@fortawesome/free-solid-svg-icons";

export default function PiezasInfo({
    piezas
}) {
    return (
        piezas?.map(pieza => {
            return (
                <div className="w-100 d-flex flex-wrap flex-lg-nowrap align-items-stretch justify-content-between" key={'pieza-' + pieza.id}>
                    <div className="d-flex align-items-start">
                        <div className="d-flex flex-column me-3">
                            <span className="icon">
                                <FontAwesomeIcon icon={faCogs} size="1x" color="#215732" />
                            </span>
                        </div>

                        <div className="d-flex flex-column">
                            <p>{pieza.nombre}</p>
                            <p>Referencia: <b>{pieza.referencia_pieza}</b></p>
                            <p>ID: <b>{pieza.id_referencia_plataforma}</b></p>
                            { pieza.observaciones ? <p><b>Observaciones:</b> {pieza.observaciones}</p> : ''}
                        </div>
                    </div>

                    <div className="d-flex flex-column">
                        <p>Precio compra: { pieza.precio_compra }€</p>
                        <p className="fw-bold">Precio venta: { pieza.precio_venta }€</p>
                        <p>Precio envío: {pieza.precio_transporte}€</p>
                        <p>Beneficio: {parseFloat(pieza.precio_venta - pieza.precio_compra).toFixed(2)}</p>
                    </div>
                

                    <div className="d-flex flex-column">
                    </div>
                </div>
            )
        }) 
    )
}