// LOAD ALL THE READ ONLY DATA
import { getTarifas } from "../views/Dashboard/components/shared/services/tarifas";
import { makeFetchingReducer, makeAsyncTypes, asyncMac, makeSetReducer } from "./utils";

const asyncTarifas = makeAsyncTypes('tarifas');

const [setPending, setFullFilled, setError ] = asyncMac(asyncTarifas);

export const tarifasFullfilledReducer = makeSetReducer(['tarifas/fullfilled']);

export const fetchingReducer = makeFetchingReducer([
    'tarifas/pending', 
    'tarifas/fullfilled', 
    'tarifas/rejected'
]);

export const fetchTarifas = () => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getTarifas()
        const data = await response.data;
        dispatch(setFullFilled(data));
        const readOnly = JSON.parse(localStorage.getItem('read_only'));
        readOnly.tarifas = data;
        localStorage.setItem('read_only', JSON.stringify(readOnly));
    } catch (e) {
        dispatch(setError(e.message))
    }
}