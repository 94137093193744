import { useState, useEffect } from "react";
import React from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faPen, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import TipoPiezaForm from "./components/TipoPiezaForm";
import InfoModal from "../../../shared/components/InfoModal";
import AvisoForm from "./components/AvisoForm";

export default function TipoPiezas() {
    const [tipoPiezasList, setTipoPiezasList] = useState(null);
    const [currentTipoPieza, setCurrentTipoPieza] = useState(null);
    const [currentAviso, setCurrentAviso] = useState(null);
    const [search, setSearch] = useState('');
    const [filterFamilia, setFilterFamilia] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [openAvisoForm, setOpenAvisoForm] = useState(false);

    const familiaPiezas = useSelector(state => {
        const { familia_piezas } = state;
        return familia_piezas;
    });
    const tipoPiezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });

    useEffect(() => {
        setTipoPiezasList(tipoPiezas);
    }, [tipoPiezas]);

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearch(value);

        if(value === "") {
            if(filterFamilia) {
                const newModelos = tipoPiezas.filter(m => m.familia_tipo_pieza_id === filterFamilia);
                setTipoPiezasList(newModelos);
            } else setTipoPiezasList(tipoPiezas);
        } else {
            if(filterFamilia) {
                const newModelos = tipoPiezas.filter(m => m.nombre?.toUpperCase().includes(value.toUpperCase()) && m.familia_tipo_pieza_id === filterFamilia);
                setTipoPiezasList(newModelos);
            } else {
                const newModelos = tipoPiezas.filter(m => m.nombre?.toUpperCase().includes(value.toUpperCase()));
                setTipoPiezasList(newModelos);
            }
        }
    }

    const handleChangeFamilia = (e) => {
        const value = e.target.value === "" ? e.target.value : parseInt(e.target.value);
        setFilterFamilia(value);

        if(value === "") {
            if(search) {
                const newTipoPiezas = tipoPiezas.filter(m => m.nombre?.toUpperCase().includes(search.toUpperCase()));
                setTipoPiezasList(newTipoPiezas);
            } else setTipoPiezasList(tipoPiezas);
        } else {
            if(search) {
                const newTipoPiezas = tipoPiezas.filter(m => m.nombre?.toUpperCase().includes(search.toUpperCase()) && m.familia_tipo_pieza_id === value);
                setTipoPiezasList(newTipoPiezas);
            } else {
                const newTipoPiezas = tipoPiezas.filter(m => m.familia_tipo_pieza_id === value);
                setTipoPiezasList(newTipoPiezas);
            }
        }
    }

    const handleOpenForm = (tipoPieza = null) => {
        setCurrentTipoPieza(tipoPieza);
        setOpenForm(true);
    }

    const handleCloseForm = () => { 
        setOpenForm(false);
        setCurrentTipoPieza(null);
    }

    const handleOpenAvisoForm = (tipoPieza) => {
        setCurrentTipoPieza(tipoPieza);
        setCurrentAviso(tipoPieza ? tipoPieza?.aviso : null);
        setOpenAvisoForm(true);
    }

    const handleCloseAvisoForm = () => {
        setOpenAvisoForm(false);
        setCurrentTipoPieza(null);
        setCurrentAviso(null);
    }

    const getFamilia = (familiaId) => { return familiaPiezas.find(f => f.id === familiaId)?.nombre }

    return (
        <div className="w-100 d-flex flex-column align-items-start"> 
            <div className="w-100 d-flex justify-content-center">
                <div className="col-12 col-md-8 d-flex flex-column align-items-center order-1 order-md-0">
                    <div className="w-100 d-flex align-content-center justify-content-between mb-4">
                        <div className="flex-grow-1 d-flex align-items-center ">
                            <div className="position-relative">
                                <input 
                                    type="text" 
                                    name="searcher" 
                                    className="form-control" 
                                    value={search} 
                                    placeholder="Busca un tipo de pieza..."
                                    onChange={(e) => handleSearch(e)}
                                />
                                <FontAwesomeIcon icon={faSearch} color="#215732" className="icon" />
                            </div>

                            <div className="ms-3">
                                <select 
                                    id="familia_tipo_pieza_id"
                                    defaultValue={filterFamilia}
                                    onChange={handleChangeFamilia}>
                                    <option key={'familia-0'} value="">Selecciona una familia</option>
                                    {familiaPiezas.map(op => <option key={'familia-' + op.id} value={op.id}>{op.nombre}</option>)}
                                </select>
                            </div>
                        </div>

                        <button type="button" className="btn btn-add" onClick={() => handleOpenForm()}>
                            <span className="add">
                                <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                            </span>
                            Nuevo
                        </button>
                    </div>
                    

                    <div className="w-100 table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th className="text-center">Peso (KG)</th>
                                    <th className="text-center">Largo (CM)</th>
                                    <th className="text-center">Ancho (CM)</th>
                                    <th className="text-center">Alto (CM)</th>
                                    <th className="text-center">Familia</th>
                                    <th className="text-center">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                { tipoPiezasList?.map((tipo, i) => {
                                   return (
                                        <tr key={'valor-' + tipo.id}>
                                            <td>{tipo.nombre}</td>
                                            <td className="text-center" width="10%">{tipo?.peso ? tipo.peso : '-'}</td>
                                            <td className="text-center" width="10%">{tipo?.largo ? tipo.largo : '-'}</td>
                                            <td className="text-center" width="10%">{tipo?.ancho ? tipo.ancho : '-'}</td>
                                            <td className="text-center" width="10%">{tipo?.alto ? tipo.alto : '-'}</td>
                                            <td className="text-center text-uppercase" width="20%">{getFamilia(tipo.familia_tipo_pieza_id)}</td>
                                            <td className="text-center" width="10%">
                                                <span title="Editar" className="me-2" style={{cursor: 'pointer'}} onClick={() => handleOpenForm(tipo)}>
                                                    <FontAwesomeIcon icon={faPen} size="1x" className="action" color="#215732" />
                                                </span>

                                                <span title={tipo?.aviso ? "Editar aviso" : "Crear aviso"} style={{cursor: 'pointer'}} onClick={() => handleOpenAvisoForm(tipo)}>
                                                    <FontAwesomeIcon icon={faBell} size="1x" className="action" color="#215732" />
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>


                { (openForm) && 
                    <InfoModal
                        state={openForm}
                        title={(currentTipoPieza ? 'Editar' : 'Nueva') + ' tipo pieza'}
                        content={
                            <TipoPiezaForm 
                                tipoPieza={currentTipoPieza}
                                onActionDone={handleCloseForm}
                            />
                        }
                        width={'sm'}
                        onClose={handleCloseForm}
                    ></InfoModal>
                }

                { (openAvisoForm) && 
                    <InfoModal
                        state={openAvisoForm}
                        title={(currentAviso ? 'Editar' : 'Nuevo') + ' aviso de tipo pieza'}
                        content={
                            <AvisoForm 
                                tipoPieza={currentTipoPieza}
                                aviso={currentAviso}
                                onActionDone={handleCloseAvisoForm}
                            />
                        }
                        width={'sm'}
                        onClose={handleCloseAvisoForm}
                    ></InfoModal>
                }
            </div>           
        </div>
    )
    
}