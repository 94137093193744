const ShowEcommerceBtn = ({setShowEcommerce}) => {
  return (
    <div className='show-ecommerce-btn'>
      <label className='shitchBtn'>
        <input type='checkbox' onChange={(e) => setShowEcommerce(e.target.checked)} />
        <span className='slide round'>Ecommerce</span>
      </label>
    </div>
  )
}

export default ShowEcommerceBtn
