import moment from "moment";
import { useEffect } from "react";
import React from "react";

export default function WhatsappLogs({logs}) {
  
    useEffect(() => {
        
    }, [logs]);

    return (
        <div className="w-100 d-flex flex-column align-items-end content-table">
            <div className="table-responsive">
                { (logs.length > 0) ?
                    <table className="table">
                        <thead>
                            <tr>
                                <th>fecha</th>
                                <th>mensaje</th>
                            </tr>
                        </thead>

                        <tbody>
                            { logs?.map((log) => {
                                return (
                                    <tr key={log.id}>
                                        <td>{moment(log.fecha).format('DD-MM-YYYY HH:mm')}</td>
                                        <td>{log.log}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    : <p className="text-center">No hay logs</p>
                }
            </div>
        </div>
    )
}