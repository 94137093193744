import { useState, useEffect } from "react";
import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import PedidoPiezas from "./components/PedidoPiezas";

export default function VentaEstadosForm({
    venta,
    onSubmitEstado
}) {
    const { register, formState: { errors }, control, handleSubmit, reset, setValue, watch, getValues } = useForm({
        defaultValues: {
            id: '',
            estado_venta_id: null,
            tipo_incidencia_id: null
        }
    });
    const { fields, append } = useFieldArray({ control, name: 'piezas' });

    const [currentVenta, setCurrentVenta] = useState({});
    const estadosVenta = useSelector(state => {
        const { estados_venta } = state;
        return estados_venta;
    });
    const tipoIncidencias = useSelector(state => {
        const { tipo_incidencias } = state;
        return tipo_incidencias;
    });

    useEffect(() => {
        if(venta) {
            setCurrentVenta(venta);
            reset({estado_venta_id: venta.estado_venta_id});

            if(venta.estado_venta_id === 5) { getPiezasPedido(); }
        }
    }, [venta]);

    const handleChangeEstado = (e) => { 
        const value = parseInt(e.target.value);
        setValue('estado_venta_id', value);
        
        if(value === 5) { getPiezasPedido(); }
    }
    
    const handleChangeTipoIncidencia = (e) => { 
        setValue('tipo_incidencia_id', parseInt(e.target.value));
    }

    const getPiezasPedido = () => {
        venta.piezas?.filter(pieza => 
            (
                (!pieza.proveedor?.integracion && pieza.proveedor.credito) ||
                (pieza.proveedor?.integracion && !pieza.proveedor.credito) ||
                (!pieza.proveedor?.integracion && !pieza.proveedor.credito)
            )
        ).forEach(pieza => {
            const itHasCodigo = venta?.historico?.find(h => h.estado_venta_id === 5 && h.pieza_id === pieza.id);

            append({
                pieza_id: pieza.id, 
                nombre: pieza.nombre,
                codigo_pedido: itHasCodigo ? itHasCodigo.codigo_pedido : '',
                tipo_pedido: itHasCodigo ? itHasCodigo.tipo_pedido : '',
                comentario: itHasCodigo ? itHasCodigo.comentario : ''
            })
        });
    }

    const handleSubmitVenta = async (data, e) => {
        e.preventDefault();

        if(parseInt(getValues('estado_venta_id')) !== 6) {
            const valid = data.piezas.filter(f => f.codigo_pedido !== null && f.codigo_pedido !== "");
            onSubmitEstado({...data, piezas: valid});
        } else {
            onSubmitEstado(data);
        }
    };
 
    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            <form className="form" onSubmit={handleSubmit(handleSubmitVenta)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={venta.id} />

                <div className="w-100 d-flex">
                    <div className="form-group">
                        <label htmlFor="rol">Estado de la venta:</label>
                        { (estadosVenta && estadosVenta.length > 0) &&
                            <select 
                                {...register("estado_venta_id")}
                                defaultValue={currentVenta.estado_venta_id || -1}
                                onChange={handleChangeEstado}>
                                <option value={-1}>Selecciona un estado...</option>
                                {
                                    estadosVenta.filter(estado => estado.id !== 1 && estado.id !== 2 && estado.id !== 3 && estado.id !== 4)
                                    .map(op => <option key={'estados-' + op.id} value={op.id}>{op.nombre}</option>)
                                }
                            </select>
                        }
                    </div> 
                </div>

                { (watch('estado_venta_id') === 5) ? 
                    <div className="w-100">
                        <PedidoPiezas 
                            fields={fields}
                            setValue={setValue}
                            register={register}
                        />
                    </div>
                    : ''
                }

                { (watch('estado_venta_id') === 6) ? 
                    <div className="form-group">
                        <label htmlFor="tipo_incidencia_id">Tipo de incidencia</label>
                        { (tipoIncidencias && tipoIncidencias.length > 0) &&
                            <select 
                                {...register("tipo_incidencia_id", {required: true})}
                                defaultValue={currentVenta.tipo_incidencia_id || null}
                                onChange={handleChangeTipoIncidencia}>
                                {tipoIncidencias.map(op => <option key={'tipo-incidencias-' + op.id} value={op.id}>{op.nombre}</option>)}
                            </select>
                        }
                    </div>
                    : ''
                }

                <div className="d-flex align-items-center align-self-end">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    );
}