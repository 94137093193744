import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

export default function MejorasForm({
    mejora, 
    onSubmitMejora
}) {
    const mejoraSchema = yup.object({
        descripcion: yup.string().required("La descripción es obligatoria"),
        tipo: yup.string().required("El tipo de mejora es obligatorio"),
      }).required();
    const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm({
        resolver: yupResolver(mejoraSchema),
        mode: "onChange",
        defaultValues: {
            id: 0,
            descripcion: '',
            tipo: ''
        }
    });

    useEffect(() => {
        reset(mejora);
    }, [mejora]);


    const handleChangeTipo = (e) => {
        setValue('tipo', e.target.value);
    }

    const handleSubmiMejora = async(data, e) => {
        e.preventDefault();
        onSubmitMejora(data);
    }

    
    return (
        <div className="w-100 d-flex flex-column">
            <form className="form mb-4" onSubmit={handleSubmit(handleSubmiMejora)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={mejora ? mejora.id : ''} />

                <div className="w-100 d-flex flex-column">
                    <div className="form-group me-3">
                        <label htmlFor="descripcion">Descripción</label>
                        <textarea
                            {...register("descripcion")}
                            defaultValue={mejora ? mejora.descripcion : ""}
                            rows={5}
                        ></textarea>

                        <div className="form-invalid">
                            {(errors.descripcion) && errors.descripcion?.message}
                        </div>
                    </div>

                    <div className="form-group me-3">
                        <label htmlFor="nombre">Tipo</label>
                        <select 
                            {...register("tipo")}
                            defaultValue={mejora ? mejora?.tipo : ""}
                            onChange={handleChangeTipo}>
                            <option value="">Selecciona</option>
                            <option value="crm">CRM</option>
                            <option value="general">General</option>
                        </select>

                        <div className="form-invalid">
                            {(errors.tipo) && errors.tipo?.message}
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    )
}