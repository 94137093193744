import React from 'react';
import { useEffect } from "react";

export default function AtencionClienteResume({
    resumen
}) {

    useEffect(() => {
        
    }, [resumen]);

    return (
        <div className='w-100 my-4'>
            <h1>Resumen</h1>

            <div className='w-100 table-responsive'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>usuario</th>
                            <th className='text-center'>incidencias abiertas</th>
                            <th className='text-center'>incidencias cerradas</th>
                            <th className='text-center'>tiempo medio</th>
                            <th className='text-center'>rápidez y eficacia</th>
                            <th className='text-center'>solución satisfactoria</th>
                            <th className='text-center'>experiencia general ATC</th>
                            <th className='text-center'>general</th>
                        </tr>
                        <tr>
                            <th colSpan={4} className='fw-bold'>finanzas</th>
                        </tr>
                    </thead>
                    <tbody>
                        { resumen.filter(res => res.rol_id === 4).map((res, i) => {
                            return (
                                <tr key={'resumen-' + res.nombre}>
                                    <td>{res.nombre.toUpperCase()}</td>
                                    <td className='text-center'>{res.num_abiertas}</td>
                                    <td className='text-center'>{res.num_cerradas}</td>
                                    <td className='text-center'>{res.tiempo_medio} <b>en finanzas</b></td>
                                    <td className='text-center'>
                                        {res.efectividad}
                                    </td>
                                    <td className='text-center'>
                                        {res.satisfaccion_cliente}
                                    </td>
                                    <td className='text-center'>
                                        {res.experiencia_general}
                                    </td>
                                    <td className='text-center'>
                                        <span className={'fw-bold ' + (res.general === 0 || res.general <= 2 ? 'text-danger' : (res.general > 2 && res.general <= 3.5 ? 'text-warning' : 'text-green'))}>
                                            {res.general}
                                        </span>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                    <thead>
                        <tr>
                            <th colSpan={4} className='fw-bold'>incidencias</th>
                        </tr>
                    </thead>
                    <tbody>
                        { resumen.filter(res => res.rol_id === 5).map((res) => {
                            return (
                                <tr key={'resumen-' + res.nombre}>
                                    <td className='text-uppercase'>{res.nombre}</td>
                                    <td className='text-center'>{res.num_abiertas}</td>
                                    <td className='text-center'>{res.num_cerradas}</td>
                                    <td className='text-center'>{res.tiempo_medio} <b>en curso</b></td>
                                    <td className='text-center'>
                                        {res.efectividad}
                                    </td>
                                    <td className='text-center'>
                                        {res.satisfaccion_cliente}
                                    </td>
                                    <td className='text-center'>
                                        {res.experiencia_general}
                                    </td>
                                    <td className='text-center'>
                                        <span className={'fw-bold ' + (res.general === 0 || res.general <= 2 ? 'text-danger' : (res.general > 2 && res.general <= 3.5 ? 'text-warning' : 'text-green'))}>
                                            {res.general}
                                        </span>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
