import { getVentas } from "../views/Dashboard/components/shared/services/ventas";
import { asyncMac, makeAsyncTypes, makeCrudReducer, makeFetchingReducer, makeSetReducer, reduceReducers } from "./utils";

const crudReducer = makeCrudReducer([
    'ventas/add',
    'ventas/edit',
    'ventas/delete'
])

const asyncVentas = makeAsyncTypes('ventas');

const [setPending, setFullFilled, setError ] = asyncMac(asyncVentas);

export const ventasFetchingReducer = makeFetchingReducer([
    'ventas/pending', 
    'ventas/fullfilled', 
    'ventas/rejected'
]);

const fullfilledReducer = makeSetReducer(['ventas/fullfilled']);

export const fetchVentas = (isArchived, page, search) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getVentas(isArchived, page, search)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const ventasReducer = reduceReducers(crudReducer, fullfilledReducer);
export const ventasStatusReducer = ventasFetchingReducer;