import React from "react";
import moment from 'moment';


export default function PresupuestoLogsInfo({logs}) {
    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="text-center">fecha</th>
                            <th className="text-center">resp</th>
                            <th className="text-center"></th>
                        </tr>
                    </thead>
                    <tbody>
                        { logs.length > 0 && 
                            logs.map((log, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="text-center" width="30%">{moment(log?.fecha).format("DD-MM-YYYY HH:mm:ss")}</td>
                                        <td className="text-center text-uppercase" width="15%">{log?.created_by?.username}</td>
                                        <td>{log?.log}</td>
                                    </tr>         
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}
