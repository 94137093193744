import { getEntidades } from "../views/Dashboard/components/shared/services/clientes";
import { asyncMac, makeAsyncTypes, makeCrudReducer, makeFetchingReducer, makeSetReducer, reduceReducers } from "./utils";

const crudReducer = makeCrudReducer([
    'clientes/add',
    'clientes/edit',
    'clientes/delete'
])

const asyncClientes = makeAsyncTypes('clientes');

const [setPending, setFullFilled, setError ] = asyncMac(asyncClientes);

export const clientesFetchingReducer = makeFetchingReducer([
    'clientes/pending', 
    'clientes/fullfilled', 
    'clientes/rejected'
]);

const fullfilledReducer = makeSetReducer(['clientes/fullfilled']);

export const fetchClientes = (page, search) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getEntidades(page, search)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const clientesReducer = reduceReducers(crudReducer, fullfilledReducer);
export const clientesStatusReducer = clientesFetchingReducer;