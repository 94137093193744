import { useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

export default function EmailForm({
    onSubmitEmail
}) {
    const piezasSchema = yup.object({   
        asunto: yup.string().required("El asunto es obligatorio"),
        mensaje: yup.string().required("El mensaje es obligatorio")
    });
    const { register, handleSubmit, formState: {errors} } = useForm({
        resolver: yupResolver(piezasSchema),
        mode: "onChange",
        defaultValues: {
            asunto: '',
            mensaje: '',
        }
    });

    useEffect(() => {
        
    }, []);

    const onSubmit = async (data, e) => {
        e.preventDefault();
        onSubmitEmail(data);
    };
 
    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            <form className="form mt-4 d-flex flex-column align-items-end" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group ms-0 ms-md-3">
                    <label htmlFor="bastidor">Asunto</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        maxLength={50}
                        {...register("asunto", { required: true, maxLength: 50 })} 
                        defaultValue={''} />
                    
                    <div className="form-invalid">
                        {(errors.asunto) && errors.asunto?.message}
                    </div>
                </div>

                <div className="w-100 d-flex flex-column flex-md-row flex-wrap flex-md-nowrap">
                    <div className="form-group">
                        <label htmlFor="message">Mensaje</label>
                        <textarea 
                            className="form-control" 
                            {...register("mensaje", { maxLength: 255 })} 
                            defaultValue={''} ></textarea>
                    </div>

                    <div className="form-invalid">
                        {(errors.mensaje) && errors.mensaje?.message}
                    </div>
                </div>
                
                <div className="d-flex align-items-center align-self-end ms-3">
                    <button 
                        type="submit"
                        className="btn btn-primary align-self-end">Enviar</button>
                </div>
            </form>
        </div>
    );
}