import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { newTipoPieza, updateTipoPieza } from "../../../../../shared/services/tipo_piezas";
import { useDispatch, useSelector } from "react-redux";

export default function TipoPiezaForm({
    tipoPieza,
    onActionDone
}) {
    const dispatch = useDispatch();
    const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm({
        defaultValues: {
            id: 0,
            nombre: '',
            peso: 0,
            largo: 0,
            alto: 0,
            ancho: 0,
            familia_tipo_pieza_id: null
        }
    });
    const familiaPiezas = useSelector(state => {
        const { familia_piezas } = state;
        return familia_piezas;
    });

    useEffect(() => {
        reset(tipoPieza);
    }, [tipoPieza]);

    const handleChangeFamilia = (e) => {
        const value = parseInt(e.target.value);
        setValue('familia_tipo_pieza_id', value);
    }

    const handleSubmitTipoPieza = async(data, e) => {
        e.preventDefault();
        dispatch({type: 'loading/set', payload: true});
        let response;

        if(tipoPieza) {
            response = await updateTipoPieza(data);
        } else {
            response = await newTipoPieza(data);
        }

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            onActionDone();
        }
    }
    
    return (
        <div className="w-100 d-flex flex-column mt-4">
            <form className="form align-items-center mb-4" onSubmit={handleSubmit(handleSubmitTipoPieza)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={tipoPieza ? tipoPieza.id : ''} />

                <div className="w-100 d-flex flex-column flex-grow-1">
                    <div className="form-group flex-grow-1">
                        <label htmlFor="nombre">Nombre</label>
                        <input
                            type="text" 
                            className="form-control"
                            {...register("nombre")} 
                            defaultValue={tipoPieza ? tipoPieza.nombre : ''}
                            disabled={tipoPieza ? true : false} />
                    </div>

                    <div className="form-group flex-grow-1">
                        <label htmlFor="familia_tipo_pieza_id">Familia</label>
                        { (familiaPiezas && familiaPiezas.length > 0) &&
                            <select 
                                {...register("familia_tipo_pieza_id")}
                                defaultValue={tipoPieza ? tipoPieza?.familia_tipo_pieza_id : ""}
                                disabled={tipoPieza ? true : false}
                                onChange={handleChangeFamilia}>
                                {familiaPiezas.map(op => <option key={'familia-' + op.id} value={op.id}>{op.nombre}</option>)}
                            </select>
                        }
                    </div>

                    <div className="form-group flex-grow-1 me-3">
                        <label htmlFor="peso">Peso</label>
                        <div className="position-relative">
                            <input 
                                type="number" 
                                step="1"
                                className="form-control" 
                                min={0}
                                {...register("peso", { required: true })} 
                                defaultValue={tipoPieza ? tipoPieza?.peso : 0} />
                            <span className="text-success icon">
                                <b>KG</b>
                            </span>
                        </div>
                        
                        <div className="form-invalid">
                            {errors.peso?.type === 'required' && "Peso es obligatorio"}
                        </div>
                    </div>

                    <div className="form-group flex-grow-1 me-3">
                        <label htmlFor="largo">Largo</label>
                        <div className="position-relative">
                            <input 
                                type="number" 
                                step="1"
                                className="form-control" 
                                min={0}
                                {...register("largo", { required: true })} 
                                defaultValue={tipoPieza ? tipoPieza?.largo : 0} />
                            <span className="text-success icon">
                                <b>CM</b>
                            </span>
                        </div>
                        
                        <div className="form-invalid">
                            {errors.largo?.type === 'required' && "Largo es obligatorio"}
                        </div>
                    </div>

                    <div className="form-group flex-grow-1 me-3">
                        <label htmlFor="ancho">Ancho</label>
                        <div className="position-relative">
                            <input 
                                type="number" 
                                step="1"
                                className="form-control" 
                                min={0}
                                {...register("ancho", { required: true })} 
                                defaultValue={tipoPieza ? tipoPieza?.ancho : 0} />
                            <span className="text-success icon">
                                <b>CM</b>
                            </span>
                        </div>
                        
                        <div className="form-invalid">
                            {errors.ancho?.type === 'required' && "Largo es obligatorio"}
                        </div>
                    </div>

                    <div className="form-group flex-grow-1 me-3">
                        <label htmlFor="alto">Alto</label>
                        <div className="position-relative">
                            <input 
                                type="number" 
                                step="1"
                                className="form-control" 
                                min={0}
                                {...register("alto", { required: true })} 
                                defaultValue={tipoPieza ? tipoPieza?.alto : 0} />
                            <span className="text-success icon">
                                <b>CM</b>
                            </span>
                        </div>
                        
                        <div className="form-invalid">
                            {errors.alto?.type === 'required' && "Alto es obligatorio"}
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end ms-3 mt-4">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    )
}