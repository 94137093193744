import API from '../../../../../services/api';

export const getFinanzas = async () => {
    return await API.get('/finanzas').then(res => res.data);
}

export const getTransferenciasParaAprobar = async (type) => {
    return await API.get('/finanzas/transferencias?type=' + type).then(res => res.data);
}

export const getPedidosPendientes = async (type) => {
    return await API.get('/finanzas/pedidos?type='+ type).then(res => res.data);
}

export const getComprobante = async (comprobante, type) => {
    return await API.get('/finanzas/comprobante/' + comprobante + '?type=' + type, {responseType: 'blob'}).then(res => res.data);
}

export const aprobarPedidoPieza = async (ventaId, piezaId, type) => {
    return await API.get('/finanzas/' + ventaId + '/confirmacion/' + piezaId + '?type=' + type).then(res => res.data);
}