import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";

export default function FeedbacksList({feedbacks}) {
    const tipoFeedback = useSelector(state => {
        const { tipo_feedback } = state;
        return tipo_feedback;
    });

    return (
        <div className="content">
            <div className="w-100 d-flex flex-column align-items-end content-table">
                <div className="table-responsive">
                <table className="table">
                    <thead>
                    <tr>
                        <th>fecha</th>
                        <th className="text-center">respondido</th>
                        <th>a mejorar</th>
                        <th>observaciones</th>
                    </tr>
                    </thead>

                    <tbody>
                    { feedbacks?.map((feedback) => {
                        return (
                        <tr key={feedback.id}>
                            <td>{ moment(feedback.fecha).format('DD/MM/YYYY') }</td>
                            <td className="text-center">
                                { feedback.respondido ?
                                    <span className="text-green fw-bold">SI</span>
                                    : <span className="text-danger fw-bold">NO</span>
                                }
                            </td>
                            <td className="text-uppercase">
                                {feedback.tipo_feedback_id ? 
                                    tipoFeedback.find(tf => tf.id === feedback.tipo_feedback_id)?.nombre
                                    : '-'
                                }
                            </td>
                            <td>{ feedback.observaciones }</td>
                        </tr>
                        );
                    })}
                    </tbody>
                </table>
                </div>
            </div>
        </div>
    )
}