import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { newTarifaValor, updateTarifaValor } from "../../../../../shared/services/tarifaValores";
import { useDispatch } from "react-redux";

export default function ValoresForm({valor, tarifaId, isEdit, onCancel, onActionDone}) {
    const dispatch = useDispatch();
    const { register, formState: { errors }, handleSubmit, reset } = useForm({
        defaultValues: {
            id: 0,
            min: 0,
            max: 0,
            valor: 0,
            tipo: ''
        }
    });

    useEffect(() => {
        reset(valor);
    }, [valor]);

    const handleSubmitValor = async(data, e) => {
        dispatch({type: 'loading/set', payload: true});
        e.preventDefault();
        let response;

       if(isEdit) {
            response = await updateTarifaValor(data);
        } else {
            data.tarifa_cliente_id = tarifaId;
            response = await newTarifaValor(data);
        }

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            onActionDone();
        }
    }

    const handleCancel = () => onCancel('valor');
    
    return (
        <div className="w-100 d-flex flex-column">
            <form className="form mb-4" onSubmit={handleSubmit(handleSubmitValor)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={isEdit ? valor.id : ''} />
                <input 
                    type="hidden" 
                    {...register("tarifa_cliente_id")} 
                    defaultValue={isEdit ? valor.tarifa_cliente_id : ''} />

                <div className="form-group">
                    <label htmlFor="min">Mínimo</label>
                    <input 
                        type="number" 
                        step="0.01"
                        className="form-control" 
                        min={0}
                        {...register("min", { required: true })} 
                        defaultValue={isEdit ? valor.min : 0} />
                    
                    <div className="form-invalid">
                        {errors.min?.type === 'required' && "Mínimo es obligatorio"}
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="max">Máximo</label>
                    <input 
                        type="number" 
                        step="0.01"
                        className="form-control" 
                        min={0}
                        {...register("max")} 
                        defaultValue={isEdit ? valor.max : 0} />
                    
                    <div className="form-invalid">
                        {errors.max?.type === 'required' && "Máximo es obligatorio"}
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="valor">Valor</label>
                    <input 
                        type="number" 
                        step="1"
                        className="form-control" 
                        min={0}
                        {...register("valor", { required: true })} 
                        defaultValue={isEdit ? valor.valor : 0} />
                    
                    <div className="form-invalid">
                        {errors.valor?.type === 'required' && "Valor es obligatorio"}
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="tipo" className="me-3">Tipo</label>
                    <div className="d-flex align-items-center mt-2">
                        <div className="me-3">
                            <input
                                {...register("tipo", { required: true })}
                                type="radio"
                                name="tipo"
                                value={"porcentage"}
                                id="tipo"
                            />
                            &nbsp; Porcentage
                        </div>

                        <div>
                            <input
                                {...register("tipo", { required: true })}
                                type="radio"
                                name="tipo"
                                value={"euro"}
                                id="tipo"
                            />
                            &nbsp; Euro
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                    <button type="button" className="btn btn-default align-self-end ms-3" onClick={handleCancel}>Cancelar</button>
                </div>
            </form>
        </div>
    )
}