import { useEffect } from "react";
import React from "react";
import PedidoPiezasForm from "./components/PedidoPiezasForm";

export default function PedidoPiezas({
    fields,
    register,
    setValue
}) {
    useEffect(() => {
 
    }, []);
 
    return (
        <div className="w-100 d-flex flex-column justify-content-center">
           {fields.map((field, index) => (
                <div className="w-100" key={'field-' + field.id}>
                    <small className="text-uppercase fw-bold">{field.nombre}</small>

                    <PedidoPiezasForm 
                        field={field}
                        register={register}
                        setValue={setValue}
                        index={index}
                    />

                    {(index >= 0 && index < fields.length) && <hr />}
                </div>
            ))}
        </div>
    );
}