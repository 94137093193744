import { useEffect, useState } from "react";
import React from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import moment from 'moment';
import AutocompleteSelect from "../../../shared/components/AutocompleteSelect";
import { getAllProveedores } from "../../../shared/services/proveedores";
import { Box } from "@mui/material";

export default function ExportForm({onSubmitExport}) {
    const [startDateValue, setStartDateValue] = useState(null);
    const [endDateValue, setEndDateValue] = useState(null);
    const [proveedores, setProveedores] = useState(null);
    const [exportProveedores, setExportProveedores] = useState([]);

    useEffect(() => {
        const fetchData = async() => {
            const response = await getAllProveedores();

            if(response && response.success) setProveedores(response.data);
        }
        fetchData();
    }, []);

    const onSubmit = () => {
        var data = {
            startDate: moment(startDateValue).format('YYYY-MM-DD'),
            endDate: moment(endDateValue).format('YYYY-MM-DD'),
            proveedores: exportProveedores.map(p => p.id)
        };

        onSubmitExport(data);
    };


    return (
        <div className="w-100">
            <div className="w-100 d-flex flex-column">
                <div className="w-100 d-flex align-items-center justify-content-between mb-4">
                    <div className="w-100 d-flex">
                        <div className="form-group flex-grow-1">
                            <label>Proveedor/es:</label>
                            <AutocompleteSelect 
                                options={proveedores ? proveedores.map(proveedor => { return {id: proveedor.id, nombre: proveedor.nombre}}) : []}
                                id={'proveedores'}
                                selectedValues={[]}
                                onSetValue={(newValue) => {
                                    setExportProveedores(newValue)
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="w-100 d-flex align-items-center justify-content-between">
                    <div className="form-group me-0 me-md-3">
                        <label>Fecha inicial:</label>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                            <DatePicker
                                disableFuture
                                label=""
                                value={startDateValue}
                                onChange={(newValue) => {
                                    setStartDateValue(newValue);
                                }}
                                mask={'__/__/____'}
                                inputFormat="dd/MM/yyyy"
                                renderInput={({ inputRef, inputProps, InputProps }) => (
                                    <Box className="date-picker" sx={{ position: 'relative' }}>
                                        <input className="form-control" ref={inputRef} {...inputProps} />
                                        {InputProps?.endAdornment}
                                    </Box>
                                )}
                            />
                        </LocalizationProvider>
                    </div>

                    <div className="form-group">
                        <label>Fecha final:</label>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                            <DatePicker
                                disableFuture
                                label=""
                                value={endDateValue}
                                onChange={(newValue) => {
                                    setEndDateValue(newValue);
                                }}
                                mask={'__/__/____'}
                                inputFormat="dd/MM/yyyy"
                                minDate={startDateValue}
                                renderInput={({ inputRef, inputProps, InputProps }) => (
                                    <Box className="date-picker" sx={{ position: 'relative' }}>
                                        <input className="form-control" ref={inputRef} {...inputProps} />
                                        {InputProps?.endAdornment}
                                    </Box>
                                )}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
            </div>

            <div className="w-100 d-flex align-items-center justify-content-end mt-4">
                <button type="button" className="btn btn-primary" onClick={onSubmit}>Exportar</button>
            </div>
        </div>
    );
}