import API from '../../../../../services/api';

export const getUsers = async (page = null, search = null) => {
    const pagination = page !== null ? '?page=' + page : '';
    const searcher = search !== null && page !== null ? '&' + search : search !== null ? '?' + search : '';
    return await API.get('/users' + pagination + searcher).then(res => res.data);
}

export const getRecambistas = async () => {
    return await API.get('/recambistas').then(res => res.data);
}

export const getComerciales = async () => {
    return await API.get('/comerciales').then(res => res.data);
}

export const getUser = async (userId) => {
    return await API.get('/users/' + userId).then(res => res.data);
}

export const newUser = async (user) => {
    return API.post('/users', user).then(res => res.data);
}

export const userChangePassword = async (user) => {
    return API.post('/users/' + user.id + '/change_password', user).then(res => res.data);
}

export const enableDisableUser = async (user) => {
    return API.post('/users/estado', user).then(res => res.data);
}

export const updateUser = async (user) => {
    return API.put('/users/' + user.id, user).then(res => res.data);
}

export const updateUserPorcentaje = async (user) => {
    return API.put('/users/' + user.id + '/porcentaje', user).then(res => res.data);
}

export const reportCrmProblem = async (problema) => {
    return API.post('/users/reportar-problema', problema).then(res => res.data);
}