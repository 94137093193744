import { getSolicitudes } from "../views/Dashboard/components/shared/services/solicitudes";
import { asyncMac, makeAsyncTypes, makeFetchingReducer, makeSetReducer } from "./utils";


const asyncSolicitudes = makeAsyncTypes('solicitudes');

const [setPending, setFullFilled, setError ] = asyncMac(asyncSolicitudes);

export const solicitudesFetchingReducer = makeFetchingReducer([
    'solicitudes/pending', 
    'solicitudes/fullfilled', 
    'solicitudes/rejected'
]);

const fullfilledReducer = makeSetReducer(['solicitudes/fullfilled']);

export const fetchSolicitudes = (page, search) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getSolicitudes(page, search)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const solicitudesReducer = fullfilledReducer;
export const solicitudesStatusReducer = solicitudesFetchingReducer;