import { useState, useEffect } from "react";
import React from "react";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from '../shared/components/Header';
import Loading from "../shared/components/Loading";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import AutorizacionesTable from "./componentes/AutorizacionesTable";
import { fetchAutorizacionesCredito } from "../../../../redux/autorizacionesCredito";
import InfoModal from "../shared/components/InfoModal";
import { actualizarPedidosCredito, updatePedido } from "../shared/services/autorizacionesCredito";
import { setErrorMessage } from "../shared/helpers/functionalities";
import PosiblesList from "./componentes/PosiblesList";
import ConfirmModal from "../shared/components/ConfirmModal";

export default function AutorizacionesCredito() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { page } = useParams();
  const currPage = parseInt(page); 
  const [currentPage, setPage] = useState(currPage);
  const [tab, setTab] = useState('encurso');
  const [currentPedido, setCurrentPedido] = useState(null);
  const [currentPresupuesto, setCurrentPresupuesto] = useState(null);
  const [openAssignPedido, setOpenAssignPedido] = useState(false);
  const [openAssignConfirm, setOpenAssignConfirm] = useState(false);
  
  const autorizaciones = useSelector(state => {
    return state.autorizaciones.entities
  });
  const status = useSelector(state => {
    return state.autorizaciones.status
  });
  const searcher = useSelector(state => {
    return state.search
  })

  useEffect(() => {
    dispatch(fetchAutorizacionesCredito(currentPage, getQuery(tab)));
  }, [searcher]);


  const getQuery = (section = null) => {
    let query;
    let isEmpty = true;

    if(searcher) {
      isEmpty = Object.values(searcher).every(x => x === null || x === '');
      query = !isEmpty ? 'search=' + (searcher?.search ? searcher?.search : '') 
      : null;
    }

    if(section) {
      if(!isEmpty) {
        query +=  '&archived=' + (section === 'encurso' ? 'false' : 'true');
      } else {
        query =  'archived=' + (section === 'encurso' ? 'false' : 'true');
      }
    }

    return query;
  }

  const handleChangeTab = (e, newTab) => { 
    setTab(newTab);
    setPage(1);
    navigate(`/autorizaciones-credito/${1}`);
    dispatch(fetchAutorizacionesCredito(1, getQuery(newTab)));
  }

  const handleSearch = (searchData) => {
    setPage(1);
    navigate(`/autorizaciones-credito/${1}`);
    dispatch({type: "search/set", payload: searchData});
  }

  const handleChangePage = (newPage, id) => {
    setPage(newPage);
    navigate(`/autorizaciones-credito/${newPage}`);
    dispatch(fetchAutorizacionesCredito(newPage, getQuery(tab)));
  }

  const handleActualizarPedidosCredito = async() => {
    dispatch({type: 'loading/set', payload: true});
    const response = await actualizarPedidosCredito()
    .catch(function (error) {
        toast.error(setErrorMessage(error.message))
        dispatch({type: 'loading/set', payload: false});
    });

    if(response && response.success) {
        dispatch({type: 'loading/set', payload: false});
        toast.success(response.message);
        dispatch(fetchAutorizacionesCredito(currentPage, getQuery(tab)));
    }
  }

  const handleOpenAssignPresupuesto = (pedido) => {
    setCurrentPedido(pedido);
    setOpenAssignPedido(true);
  }
  const handleCloseAssignPedido = () => {
    setOpenAssignPedido(false);
    setCurrentPedido(null);
  }

  const handleOpenConfirmAssign = (presupuesto) => {
    setCurrentPresupuesto(presupuesto);
    setOpenAssignConfirm(true);
  }
  const handleCloseConfirmAssign = () => {
    setOpenAssignConfirm(false);
    setCurrentPresupuesto(null);
  }

  const handleAssignPedido = async(confirm) => {
    if(confirm) {
      dispatch({type: 'loading/set', payload: true});
      const response = await updatePedido({
        id: currentPedido.id, 
        presupuesto_id: currentPresupuesto.id
      }).catch(function (error) {
        toast.error(setErrorMessage(error))
      });

      if(response && response.success) {
          toast.success(response.message);
          dispatch({type: 'loading/set', payload: false});
          dispatch(fetchAutorizacionesCredito(currentPage, getQuery(tab)));
          handleCloseConfirmAssign();
          handleCloseAssignPedido();
          return;
      }
    }

    handleCloseConfirmAssign();
  }

  const handleValidatePedido = async(pedido) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await updatePedido({id: pedido.id, validado: true})
    .catch(function (error) {
        toast.error(setErrorMessage(error))
    });

    if(response && response.success) {
        toast.success(response.message);
        dispatch({type: 'loading/set', payload: false});
        dispatch(fetchAutorizacionesCredito(currentPage, getQuery(tab)));
        handleCloseConfirmAssign();
        handleCloseAssignPedido();
        return;
    }
  }

  if(status.loading === 'pending' || status.loading === 'idle') {
    return ( <Loading /> );
  }

  if(status.loading === 'succeed' || status.loading === 'rejected') {
    return (
      <div className="content pt-3">
        <Header 
          viewSearch={true}
          onSearch={handleSearch}
          onActualizarPedidos={handleActualizarPedidosCredito}
        ></Header>

        <div className="w-100 mt-3">
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label="Estados de autorizaciones a credito">
              <Tab label="En curso" value="encurso" />
              <Tab label="Archivadas" value="archivadas" />
            </TabList>
            </Box>
            <TabPanel value="encurso">
                <AutorizacionesTable 
                    id={'encurso'}
                    currPage={currentPage}
                    pedidos={autorizaciones}
                    onPageChange={handleChangePage}
                    onValidate={handleValidatePedido}
                    onAssingPresupuesto={handleOpenAssignPresupuesto}
                />
            </TabPanel>
            <TabPanel value="archivadas">
                <AutorizacionesTable 
                    id={'archivadas'}
                    currPage={currentPage}
                    pedidos={autorizaciones}
                    onPageChange={handleChangePage}
                />
            </TabPanel>
          </TabContext>
        </div>

        { (openAssignPedido) &&
            <InfoModal
                state={openAssignPedido}
                title={"Assignar presupuesto al pedido " + currentPedido.pedido}
                content={
                    <PosiblesList 
                        presupuestos={currentPedido.posibles}
                        onSelectPresupuesto={handleOpenConfirmAssign}
                    />
                }
                width={'lg'}
                onClose={handleCloseAssignPedido}
            ></InfoModal>
        }

        { openAssignConfirm && 
            <ConfirmModal 
                onConfirmAction={handleAssignPedido} 
                title={'Assignar presupuesto ' + currentPresupuesto.numero + ' a pedido ' + currentPedido.pedido} 
                description={'Estás seguro de asignar?'}
                state={openAssignConfirm}>
            </ConfirmModal>
        }
      </div>
    )
  }
}