export const getSecciones = (pais) => {
    if (pais) {
        return [
            {
              nombre: 'Pendientes',
              value: 'pendiente'
            },
            {
              nombre: 'No contesta',
              value: 'no-contesta'
            },
            {
              nombre: 'Interesados',
              value: 'interesado'
            },
            {
              nombre: 'Leads',
              value: 'lead'
            },
            {
              nombre: 'Clientes',
              value: 'cliente'
            },
            {
              nombre: 'Clientes Ecommerce',
              value: 'cliente-ecommerce'
            },
            {
              nombre: 'Cliente Perdido',
              value: 'cliente-perdido'
            },
            {
              nombre: 'Grandes Cuentas',
              value: 'cliente-grande'
            }
        ];
    } else {
        return [
            {
              nombre: 'Pendientes',
              value: 'pendiente'
            },
            {
              nombre: 'RF',
              value: 'rf'
            },
            {
              nombre: 'RF2',
              value: 'rf2'
            },
            {
              nombre: 'No contesta',
              value: 'no-contesta'
            },
            {
              nombre: 'Interesados',
              value: 'interesado'
            },
            {
              nombre: 'Leads',
              value: 'lead'
            },
            {
              nombre: 'Clientes',
              value: 'cliente'
            },
            {
              nombre: 'Clientes Ecommerce',
              value: 'cliente-ecommerce'
            },
            {
              nombre: 'Cliente Perdido',
              value: 'cliente-perdido'
            },
            {
              nombre: 'Grandes Cuentas',
              value: 'cliente-grande'
            }
        ]
    }
}