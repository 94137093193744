import { useEffect } from "react";
import React from "react";
import moment from 'moment';

export default function HistoricoVenta({historico}) {
    useEffect(() => {}, []);
 
    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="text-center">estado</th>
                            <th className="text-center">fecha</th>
                            <th className="text-center">pieza</th>
                            <th className="text-center">tipo</th>
                            <th className="text-center">codigo pedido</th>
                            <th className="text-center">responsable</th>
                        </tr>
                    </thead>
                    <tbody>
                        { historico?.length > 0 && 
                            historico.map(hv => {
                                return (
                                    <tr key={hv.id}>
                                        <td className="text-center text-uppercase">{hv.estado.nombre}</td>
                                        <td className="text-center">{moment(hv.fecha).format("DD-MM-YYYY")}</td>
                                        <td className="text-center text-uppercase">{hv?.pieza ? hv?.pieza?.nombre : '-'}</td>
                                        <td className="text-center text-uppercase">{hv?.tipo_pedido ? hv?.tipo_pedido : '-'}</td>
                                        <td className="text-center">{hv?.codigo_pedido ? hv?.codigo_pedido : '-'}</td>
                                        <td className="text-center text-uppercase">{hv.created_by.username}</td>
                                    </tr>         
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}