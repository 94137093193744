import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import Advanced from "./components/Advanced";

export default function Searcher({
    detalleTab,
    pageName,
    onChange,
}) {
    const searcher = useSelector(state => {
        return state.search
      })
    const [reset, setReset] = useState(false);

    useEffect(() => {
    }, [searcher, detalleTab]);

    const handleReset = () => { 
        setReset(true); 
        onChange({});
    }

    return (
        <div className="w-100 d-flex flex-wrap flex-lg-nowrap align-items-stretch justify-content-between mt-3"> 
            <Advanced 
                reset={reset}
                pageName={pageName}
                detalleTab={detalleTab}
                onChange={onChange}
                onReset={handleReset}
            />
        </div> 
    );
}