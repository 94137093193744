import { toast } from "react-toastify";
import { setErrorMessage } from "../../views/Dashboard/components/shared/helpers/functionalities";
import { getCheckoutInfo, newDireccionClienteCheckout, saveSelectedDireccionCheckout, updateClienteCheckout, updateDireccionClienteCheckout } from "../../views/Dashboard/components/shared/services/checkout";
import { asyncMac, makeAsyncTypes, makeFetchingReducer } from "../utils";

const initialCheckout = { 
    presupuesto: {},
    cliente: {},
    has_ventas: 0,
    direcciones: [],
    poblaciones: [],
    paises: [],
    tipo_clientes: [],
    is_venta: null,
    telefono_recambista: null,
    selected_direccion_id: null
};

export const checkoutReducer = (state = initialCheckout, action) => {
    switch(action.type){
        case "checkout/fullfilled": {
            if(action.payload.action === 'total') {
                const newCheckout = {...action.payload.item};
                return newCheckout;
            } else if(action.payload.action === 'cliente') {
                const newCheckout = {...state,  cliente: action.payload.item, direcciones: action.payload.item.direcciones }
                return newCheckout;
            } else if(action.payload.action === 'new-direccion') {
                const newDirecciones = state.direcciones;
                newDirecciones.push(action.payload.item);

                return {
                    ...state, 
                    direcciones: newDirecciones,
                    selected_direccion_id: action.payload.item.id 
                }
            } else if(action.payload.action === 'update-direccion') {
                const newDirecciones = state.direcciones.map(dir => {
                    if(dir.id === action.payload.item.id) {
                        return  action.payload.item
                    }
    
                    return dir;
                })

                return {
                    ...state,
                    direcciones: newDirecciones,
                    selected_direccion_id: action.payload.item.id
                }
            } 
            
        }
        default:
            return state
    }
}

const asyncCheckout = makeAsyncTypes('checkout');

const [setPending, setFullFilled, setError ] = asyncMac(asyncCheckout);

export const checkoutFetchingReducer = makeFetchingReducer([
    'checkout/pending', 
    'checkout/fullfilled', 
    'checkout/rejected'
]);

export const fetchCheckout = (checkoutId) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getCheckoutInfo(checkoutId)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const fetchUpdateClienteCheckout = (checkoutId, cliente) => async dispatch => {
    try {
        const response = await updateClienteCheckout(checkoutId, cliente)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const fetchNewDireccionCheckout = (checkoutId, direccion) => async dispatch => {
    try {
        const response = await newDireccionClienteCheckout(checkoutId, direccion)
        .catch(function (error) {
            toast.error(setErrorMessage(error.message))
        });
        const data = await response.data;
        dispatch(setFullFilled(data));
        dispatch(fetchSaveSelectedDireccion(checkoutId, data.item?.id));
    } catch (e) {
        dispatch(setError(e.message));
        toast.error(e.message);
    }
}

export const fetchUpdateDireccionCheckout = (checkoutId, direccion) => async dispatch => {
    try {
        const response = await updateDireccionClienteCheckout(checkoutId, direccion)
        .catch(function (error) {
            toast.error(setErrorMessage(error.message))
        });
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
        toast.error(e.message);
    }
}

export const fetchSaveSelectedDireccion = (checkoutId, direccionId) => async dispatch => {
    try {
        const response = await saveSelectedDireccionCheckout(checkoutId, { direccion_envio_id: direccionId})
        const data = await response.data;
        dispatch(setFullFilled(data));
        toast.success(response.message);
    } catch (e) {
        dispatch(setError(e.message))
        toast.error(e.message);
    }
}

export const checkoutStatusReducer = checkoutFetchingReducer;