import { getEntidad, newEntidad, updateEntidad } from "../../views/Dashboard/components/shared/services/clientes";
import { newDireccion, updateDireccion } from "../../views/Dashboard/components/shared/services/direcciones";
import { asyncMac, makeAsyncTypes, makeFetchingReducer, reduceReducers } from "../utils";
import { toast } from 'react-toastify';

const initialCliente = {};

export const clienteReducer = (state = initialCliente, action) => {
    switch(action.type){
        case "cliente/fullfilled": {
            return action.payload;
        }
        case "cliente/empty": {
            return action.payload;
        }
        case "direccion-cliente/fullfilled": {
            const isIn = state.direcciones.find(d => d.id === action.payload.id)

            if(!isIn) {
                const newCliente = {
                    ...state, 
                    direcciones: state.direcciones.concat({ ...action.payload })
                }
                return newCliente
            } else {
                const newDirecciones = state.direcciones.map(dir => {
                    if(dir.id === action.payload.id) {
                        return  action.payload
                    }
    
                    return dir;
                })
    
                return {...state, direcciones: newDirecciones }
            }
        }
        default:
            return state
    }
}

const asyncCliente = makeAsyncTypes('cliente');

const [setPending, setFullFilled, setError ] = asyncMac(asyncCliente);

const asyncClienteDireccion = makeAsyncTypes('direccion-cliente');

const [setPendingDireccion, setFullFilledDireccion, setErrorDireccion ] = asyncMac(asyncClienteDireccion);

export const clienteFetchingReducer = makeFetchingReducer([
    'cliente/pending', 
    'cliente/fullfilled', 
    'cliente/rejected'
]);

export const clienteDireccionFetchingReducer = makeFetchingReducer([
    'direccion-cliente/pending', 
    'direccion-cliente/fullfilled', 
    'direccion-cliente/rejected'
]);


export const fetchCliente = (clienteId) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getEntidad(clienteId)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
}

export const saveCliente = (cliente) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await newEntidad(cliente)
        const data = await response.data;
        toast.success(response.message);
        dispatch(setFullFilled(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
}

export const editCliente = (cliente) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await updateEntidad(cliente)
        const data = await response.data;
        toast.success(response.message);
        dispatch(setFullFilled(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
}

export const newDireccionCliente = (direccion) => async dispatch => {
    dispatch(setPendingDireccion());

    try {
        const response = await newDireccion(direccion)
        const data = await response.data;
        toast.success(response.message);
        dispatch(setFullFilledDireccion(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
        dispatch(setErrorDireccion(e.message))
    }
}

export const updateDireccionCliente = (direccion) => async dispatch => {
    dispatch(setPendingDireccion());

    try {
        const response = await updateDireccion(direccion)
        const data = await response.data;
        toast.success(response.message);
        dispatch(setFullFilledDireccion(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
        dispatch(setErrorDireccion(e.message))
    }
}

export const currentClienteReducer = clienteReducer;
export const currentClienteStatusReducer = reduceReducers(
    clienteFetchingReducer,
    clienteDireccionFetchingReducer
);