import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function Loading() {
    return (
        <div className="content">
            <div className="w-100 h-100 d-flex align-items-center justify-content-center loading">
                <FontAwesomeIcon icon={faSpinner} size="2x" spin /> <span className="ms-2">Cargando</span>
            </div>
        </div>
    )
}