import { useEffect, useState } from "react";
import React from "react";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { Box } from "@mui/material";

export default function CustomDatePicker({
    label,
    date,
    minDateValue,
    disableFuture,
    onChangeDate
}) {
    const [dateValue, setDateValue] = useState(date || null);

    useEffect(() => {
        if(date) {
            setDateValue(date);
        }
    }, [date]);

    return (
        <div className="form-group">
            <label>{label}</label>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                <DatePicker
                    disableFuture={disableFuture}
                    label=""
                    value={dateValue}
                    onChange={(newValue) => {
                        setDateValue(newValue);
                        onChangeDate(newValue)
                    }}
                    mask={'__/__/____'}
                    inputFormat="dd/MM/yyyy"
                    minDate={minDateValue}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                        <Box className="date-picker" sx={{ position: 'relative' }}>
                            <input className="form-control" ref={inputRef} {...inputProps} />
                            {InputProps?.endAdornment}
                        </Box>
                    )}
                />
            </LocalizationProvider>
        </div>
    );
}