import { useState, useEffect } from "react";
import React from "react";
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faPen } from "@fortawesome/free-solid-svg-icons";
import '../../../../incidencias.scss';
import { useDispatch } from "react-redux";
import { editAviso, fetchAvisosList, markAsRead } from "../../../../../../../../redux/features/avisosList";
import { Link } from "react-router-dom";
import AvisoForm from "../../../shared/AvisoForm";
import './avisos_list.scss';

export default function AvisosList({
    type,
    today,
    startDate,
    endDate,
    search,
    incidencias,
    avisos
}) {
    const dispatch = useDispatch();
    const [list, setList] = useState(avisos);
    const [currentAviso, setCurrentAviso] = useState(null);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if(search && search !== "") {
            const newAvisos = avisos.filter((fecha) => fecha.avisos.find(aviso => aviso.incidencia.numero?.includes(search)));;
            
            if(newAvisos?.length > 0) {
                newAvisos.forEach(avisos => {
                    avisos.avisos = avisos.avisos.filter(aviso => aviso.incidencia.numero?.includes(search));
                });
            }
            
            setList(newAvisos);
        } else {
            setList(avisos);
        }
    }, [type, startDate, endDate, incidencias, search, avisos]);

    const handleEditAviso = (aviso) => {
        setCurrentAviso(aviso);
        setIsEdit(true);
    }

    const handleCancel = () => {
        setCurrentAviso(null);
        setIsEdit(false);
    }

    const handleSubmit = async(data) => {
        dispatch({type: 'loading/set', payload: true});
        dispatch(editAviso(data));
        setIsEdit(false);
        setCurrentAviso(null);
        dispatch(fetchAvisosList(startDate, endDate));
    }

    const handleMarkAsRead = async(aviso) => { 
        dispatch({type: 'loading/set', payload: true});
        dispatch(markAsRead(aviso)); 
        dispatch(fetchAvisosList(startDate, endDate));
    }
 
    return (
        <div className="w-100 d-flex flex-column justify-content-center avisos">
            { type === 'today' &&
                <div className="w-100 d-flex align-items-end legend-day mb-4">
                    <h5>
                        <small className="text-uppercase me-2">{moment().format('dddd')}</small>
                        {moment().format('DD MMM')}
                    </h5>
                </div>
            }

            <div className="w-100">
                { list.length > 0 && list.map((fecha, i) => {
                    return (
                        <div className="avisos-fecha" key={'fecha-avisos-'+ i}>
                            { type === 'future' &&
                                <div className="w-100 d-flex align-items-end legend-day mb-4">
                                    <h5>
                                        <small className="text-uppercase me-2">{moment(fecha.fecha).format('dddd')}</small>
                                        {moment(fecha.fecha).format('DD MMM')}
                                    </h5>
                                </div>
                            }

                            { fecha.avisos.map((aviso, j) => {
                                return (
                                    <>
                                        { (isEdit && currentAviso.id === aviso.id) ?
                                            <AvisoForm 
                                                incidencias={incidencias}
                                                aviso={aviso} 
                                                isEdit={isEdit}
                                                onCancel={handleCancel}
                                                onSubmitAviso={handleSubmit}
                                            />
                                        :
                                            <div className="w-100 d-flex flex-column aviso" key={'avisos-'+ j}>
                                                <div className="w-100 d-flex">
                                                    <div className="flex-grow-1 info pe-3">
                                                        <div className="w-100 d-flex align-items-end">
                                                            <div className="w-100">
                                                                <div className="w-100 d-flex align-items-center justify-content-between">
                                                                    <Link target="_blank" rel="noopener noreferrer" to={"/incidencia/" + aviso.incidencia.id} title="Ficha" className="text-green">
                                                                        {aviso.incidencia.numero} 
                                                                    </Link> 

                                                                    <p>Creado por: <b className="text-uppercase">{aviso.created_by ? aviso.created_by.username : '-'}</b></p>
                                                                </div>
                                                                
                                                                <div className="w-100 table-responsive">
                                                                    <table className="table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Cliente</th>
                                                                                <th>Proveedor</th>
                                                                                <th>Pieza</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td width={'30%'}>
                                                                                    <b>{aviso.incidencia.cliente.nombre}</b>
                                                                                </td>
                                                                                <td width={'30%'}>
                                                                                    <b>{aviso.incidencia.proveedor.nombre}</b>
                                                                                </td>
                                                                                <td>
                                                                                    {aviso.incidencia.piezas.length > 0 ? aviso.incidencia.piezas[0].nombre : '-'}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="w-100 d-flex align-items-center">
                                                            <div className="w-100 mt-2">
                                                                <label className="fw-bolder">Persona de contacto</label>
                                                                <p>{aviso.nombre ? aviso.nombre : '-'}</p>
                                                            </div>

                                                            <div className="w-100 mt-2">
                                                                <label className="fw-bolder">Canal</label>
                                                                <p>{aviso.canal ? aviso.canal : '-'}</p>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="w-100 mt-2">
                                                            <label className="fw-bolder">Asunto</label>
                                                            <p>{aviso.asunto}</p>
                                                        </div>

                                                        <div className="w-100 mt-2">
                                                            <label className="fw-bold">Mensaje</label>
                                                            <p>{aviso.concepto}</p>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex flex-column justify-content-end">
                                                        <span title="Editar" style={{cursor: 'pointer'}} className="me-3">
                                                            <FontAwesomeIcon icon={faPen} size="1x" className="action" color="#215732" onClick={() => handleEditAviso(aviso)}  />
                                                        </span>
                                                        <span title="Marcar como leído" style={{cursor: 'pointer'}}>
                                                            <FontAwesomeIcon icon={faCheckCircle} size="1x" className="action" color="#215732" onClick={() => handleMarkAsRead(aviso)} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                            )})}
                        </div>
                    )
                }) }

                { (!avisos || avisos.length === 0) && <p>No hay avisos.</p>}
            </div>
        </div>
    );
}