
const obtainCats = () => {
  return [
      {
        "nombre": "ABS",
        "idcrm": 450
      },
      {
        "nombre": "ABSORBEDOR DELANTERO",
        "idcrm": 69
      },
      {
        "nombre": "ACC AUTO",
        "idcrm": 262
      },
      {
        "nombre": "ACC. LUNAS",
        "idcrm": 262
      },
      {
        "nombre": "ACCESORIOS AUTO",
        "idcrm": 15
      },
      {
        "nombre": "ACEITE",
        "idcrm": 28
      },
      {
        "nombre": "ACEITE 2 TIEMPOS",
        "idcrm": 28
      },
      {
        "nombre": "ACEITE ATF",
        "idcrm": 28
      },
      {
        "nombre": "ACEITE DIRECCION",
        "idcrm": 28
      },
      {
        "nombre": "ACEITE HIDRAULICO 68",
        "idcrm": 28
      },
      {
        "nombre": "ACEITE MOTOR",
        "idcrm": 28
      },
      {
        "nombre": "ACUMULADOR CENTRAL AMORTIGUACION",
        "idcrm": 451
      },
      {
        "nombre": "ADBLUE",
        
        "Familia": 13,
        
      },
      {
        "nombre": "ADITIVO",
        
        "Familia": 13,
        
      },
      {
        "nombre": "ADITIVO COMPRESION MOTOR",
        
        "Familia": 13,
        
      },
      {
        "nombre": "AFLOJALOTODO",
        
        "Familia": 13,
        
      },
      {
        "nombre": "AFORADOR",
        "idcrm": 363
      },
      {
        "nombre": "AGARRADOR",
        
        "Familia": 10,
        
      },
      {
        "nombre": "AGARRAMANO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "AGUA DESTILADA",
        
        "Familia": 1,
        
      },
      {
        "nombre": "AIRBAG ACOMPA�ANTE",
        "idcrm": 300
      },
      {
        "nombre": "AIRBAG ASIENTO DELANTERO DERECHO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "AIRBAG ASIENTO DELANTERO IZQUIERDO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "AIRBAG CONDUCTOR",
        "idcrm": 301
      },
      {
        "nombre": "AIRBAG CORTINA DELANTERO DERECHO",
        "idcrm": 295
      },
      {
        "nombre": "AIRBAG CORTINA DELANTERO IZQUIERDO",
        "idcrm": 296
      },
      {
        "nombre": "AIRBAG CORTINA LUNA TRASERA",
        "idcrm": 297
      },
      {
        "nombre": "AIRBAG CORTINA TRASERO DERECHO",
        "idcrm": 298
      },
      {
        "nombre": "AIRBAG CORTINA TRASERO IZQUIERDO",
        "idcrm": 299
      },
      {
        "nombre": "AIRBAG DELANTERO DERECHO",
        "idcrm": 300
      },
      {
        "nombre": "AIRBAG DELANTERO IZQUIERDO",
        "idcrm": 301
      },
      {
        "nombre": "AIRBAG LATERAL DELANTERO DERECHO",
        "idcrm": 302
      },
      {
        "nombre": "AIRBAG LATERAL DELANTERO IZQUIERDO",
        "idcrm": 303
      },
      {
        "nombre": "AIRBAG LATERAL DERECHO",
        "idcrm": 304
      },
      {
        "nombre": "AIRBAG LATERAL IZQUIERDO",
        "idcrm": 305
      },
      {
        "nombre": "AIRBAG LATERAL TRASERO DERECHO",
        "idcrm": 306
      },
      {
        "nombre": "AIRBAG LATERAL TRASERO IZQUIERDO",
        "idcrm": 307
      },
      {
        "nombre": "AIRBAG RODILLA",
        
        "Familia": 10,
        
      },
      {
        "nombre": "AIRBAG RODILLAS",
        
        "Familia": 10,
        
      },
      {
        "nombre": "AIREADOR",
        
        "Familia": 7,
        
      },
      {
        "nombre": "ALERON TRASERO",
        "idcrm": 158
      },
      {
        "nombre": "ALETA DELANTERA DERECHA",
        "idcrm": 70
      },
      {
        "nombre": "ALETA DELANTERA IZQUIERDA",
        "idcrm": 71
      },
      {
        "nombre": "ALETA TRASERA DERECHA",
        
        "Familia": 6,
        
      },
      {
        "nombre": "ALETIN DELANTERO DERECHO",
        "idcrm": 72
      },
      {
        "nombre": "ALETIN DELANTERO DERECHO",
        "idcrm": 72
      },
      {
        "nombre": "ALETIN DELANTERO IZQUIERDO",
        "idcrm": 73
      },
      {
        "nombre": "ALETIN TRASERA DERECHA",
        "idcrm": 159
      },
      {
        "nombre": "ALETIN TRASERA IZQUIERDA",
        "idcrm": 160
      },
      {
        "nombre": "ALETIN TRASERO DERECHO",
        "idcrm": 159
      },
      {
        "nombre": "ALETIN TRASERO IZQUIERDO",
        "idcrm": 160
      },
      {
        "nombre": "ALOJAMIENTO LLAVE CONTACTO",
        "idcrm": 3
      },
      {
        "nombre": "ALTAVOZ",
        
        "Familia": 9,
        
      },
      {
        "nombre": "ALTAVOZ/ALTAVOCES",
        
        "Familia": 9,
        
      },
      {
        "nombre": "ALTERNADOR",
        "idcrm": 225
      },
      {
        "nombre": "AMBIENTADOR COCHE",
        
        "Familia": 13,
        
      },
      {
        "nombre": "AMORTIGUADOR CAPO",
        "idcrm": 74
      },
      {
        "nombre": "AMORTIGUADOR DE DIRECCION",
        "idcrm": 199
      },
      {
        "nombre": "AMORTIGUADOR DELANTERO",
        "idcrm": 452
      },
      {
        "nombre": "AMORTIGUADOR DELANTERO DERECHO",
        "idcrm": 453
      },
      {
        "nombre": "AMORTIGUADOR DELANTERO IZQUIERDO",
        "idcrm": 454
      },
      {
        "nombre": "AMORTIGUADOR LUNA ABATIBLE",
        
        "Familia": 12,
        
      },
      {
        "nombre": "AMORTIGUADOR MALETERO / PORTON",
        "idcrm": 455
      },
      {
        "nombre": "AMORTIGUADOR TRASERO",
        "idcrm": 455
      },
      {
        "nombre": "AMORTIGUADOR TRASERO DERECHO",
        "idcrm": 456
      },
      {
        "nombre": "AMORTIGUADOR TRASERO IZQUIERDO",
        "idcrm": 457
      },
      {
        "nombre": "AMORTIGUADORES CAPO",
        "idcrm": 74
      },
      {
        "nombre": "AMORTIGUADORES DE PORTON",
        "idcrm": 455
      },
      {
        "nombre": "AMORTIGUADORES IMPACTO DELANTEROS",
        
        "Familia": 12,
        
      },
      {
        "nombre": "AMORTIGUADORES MALETERO / PORTON",
        "idcrm": 455
      },
      {
        "nombre": "AMORTIGUADORES PORTON",
        "idcrm": 455
      },
      {
        "nombre": "AMPLIFICADOR",
        "idcrm": 2
      },
      {
        "nombre": "AMPLIFICADOR ANTENA",
        "idcrm": 2
      },
      {
        "nombre": "ANAGRAMA",
        
        "Familia": 4,
        
      },
      {
        "nombre": "ANCLAJE CINTURON",
        
        "Familia": 10,
        
      },
      {
        "nombre": "ANCLAJE CINTURON DELANTERO DEREC",
        "idcrm": 321
      },
      {
        "nombre": "ANCLAJE CINTURON DELANTERO DERECHO",
        "idcrm": 321
      },
      {
        "nombre": "ANCLAJE CINTURON DELANTERO IZQUIE",
        "idcrm": 322
      },
      {
        "nombre": "ANCLAJE CINTURON DELANTERO IZQUIERDO",
        "idcrm": 322
      },
      {
        "nombre": "ANCLAJE CINTURON TRASERO CENTR",
        "idcrm": 324
      },
      {
        "nombre": "ANCLAJE CINTURON TRASERO CENTRAL",
        "idcrm": 324
      },
      {
        "nombre": "ANCLAJE CINTURON TRASERO DEREC",
        "idcrm": 325
      },
      {
        "nombre": "ANCLAJE CINTURON TRASERO DERECHO",
        "idcrm": 325
      },
      {
        "nombre": "ANCLAJE CINTURON TRASERO IZQUIE",
        "idcrm": 326
      },
      {
        "nombre": "ANCLAJE CINTURON TRASERO IZQUIERDO",
        "idcrm": 326
      },
      {
        "nombre": "ANILLO AIRBAG",
        "idcrm": 226
      },
      {
        "nombre": "ANILLO AIRBAG RENAULT CON CONTROL DE CRUZERO",
        "idcrm": 226
      },
      {
        "nombre": "ANTENA",
        "idcrm": 2
      },
      {
        "nombre": "ANTENA GPS",
        "idcrm": 2
      },
      {
        "nombre": "ANTICONGELANTE",
        "idcrm": 11
      },
      {
        "nombre": "ANTIRROBO",
        "idcrm": 3
      },
      {
        "nombre": "APOYABRAZOS CENTRAL",
        "idcrm": 308
      },
      {
        "nombre": "ARA?A",
        "idcrm": ""
      },
      {
        "nombre": "ARBOL DE LEVAS",
        "idcrm": 364
      },
      {
        "nombre": "ARTICULACION LIMPIA DELANTERA",
        
        "Familia": 4,
        
      },
      {
        "nombre": "ASIDERO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "ASIENTO",
        "idcrm": 309
      },
      {
        "nombre": "ASIENTO CENTRAL",
        "idcrm": 312
      },
      {
        "nombre": "ASIENTO DELANTERO DERECHO",
        "idcrm": 310
      },
      {
        "nombre": "ASIENTO DELANTERO IZQUIERDO",
        "idcrm": 311
      },
      {
        "nombre": "ASIENTO TRASERO DERECHO",
        "idcrm": 314
      },
      {
        "nombre": "ASIENTO TRASERO IZQUIERDO",
        "idcrm": 315
      },
      {
        "nombre": "ASIENTO TRASERO MEDIO",
        "idcrm": 312
      },
      {
        "nombre": "ASIENTOS TERCERA FILA",
        "idcrm": 313
      },
      {
        "nombre": "ASIENTOS TRASERO DERECHO",
        "idcrm": 314
      },
      {
        "nombre": "ASIENTOS TRASERO IZQUIERDO",
        "idcrm": 315
      },
      {
        "nombre": "ASIENTOS TRASEROS",
        "idcrm": 316
      },
      {
        "nombre": "AUTO-ARRANQUE",
        "idcrm": 250
      },
      {
        "nombre": "AUTOMATICO ARRANQUE",
        "idcrm": 250
      },
      {
        "nombre": "BACA",
        "idcrm": 23
      },
      {
        "nombre": "BALANCIN",
        "idcrm": 365
      },
      {
        "nombre": "BALLESTA DELANTERA",
        "idcrm": 458
      },
      {
        "nombre": "BALLESTA TRASERA",
        "idcrm": 459
      },
      {
        "nombre": "BANDEJA TRASERA",
        "idcrm": 317
      },
      {
        "nombre": "BARRA CAMBIO",
        "idcrm": 52
      },
      {
        "nombre": "BARRA DIRECCION",
        "idcrm": 200
      },
      {
        "nombre": "BARRA ESTABILIZADORA DELANTERA",
        "idcrm": 460
      },
      {
        "nombre": "BARRA ESTABILIZADORA TRASERA",
        "idcrm": 461
      },
      {
        "nombre": "BARRA LED",
        
        "Familia": 2,
        
      },
      {
        "nombre": "BARRA TORSION",
        "idcrm": 462
      },
      {
        "nombre": "BARRAS DE TECHO",
        
        "Familia": 1,
        
      },
      {
        "nombre": "BARRAS TECHO",
        "idcrm": 4
      },
      {
        "nombre": "BASE DE ANTENA",
        "idcrm": 5
      },
      {
        "nombre": "BATERIA",
        "idcrm": 6
      },
      {
        "nombre": "BATERIA 60 AMPERIOS",
        "idcrm": 6
      },
      {
        "nombre": "BATERIA 74 AMPERIOS",
        "idcrm": 6
      },
      {
        "nombre": "BATERIAS 70 AMPERIOS",
        "idcrm": 6
      },
      {
        "nombre": "BENDIX MOTOR DE ARRANQUE",
        
        "Familia": 9,
        
      },
      {
        "nombre": "BIELA",
        "idcrm": 366
      },
      {
        "nombre": "BIELETA BARRA ESTABILIZADORA",
        
        "Familia": 12,
        
      },
      {
        "nombre": "BIELETA ESTABILIZADORA DELANTERA DERECHA",
        "idcrm": 463
      },
      {
        "nombre": "BIELETA ESTABILIZADORA DELANTERA IZQUIERDA",
        "idcrm": 464
      },
      {
        "nombre": "BIELETA ESTABILIZADORA TRASERA DERECHA",
        "idcrm": 465
      },
      {
        "nombre": "BIELETA ESTABILIZADORA TRASERA IZQUIERDA",
        "idcrm": 466
      },
      {
        "nombre": "BIELETA SUSPENSION",
        
        "Familia": 1,
        
      },
      {
        "nombre": "BISAGRA CAPOT",
        
        "Familia": 1,
        
      },
      {
        "nombre": "BISAGRA DERECHA PORTON",
        
        "Familia": 1,
        
      },
      {
        "nombre": "BISAGRA IZQUIERDA PORTON",
        
        "Familia": 1,
        
      },
      {
        "nombre": "BISAGRA PORTON",
        
        "Familia": 1,
        
      },
      {
        "nombre": "BISAGRA PUERTA",
        
        "Familia": 1,
        
      },
      {
        "nombre": "BISAGRA PUERTA TRASERA DERECHA",
        
        "Familia": 1,
        
      },
      {
        "nombre": "BISAGRA PUERTA TRASERA IZQUIERDA",
        
        "Familia": 1,
        
      },
      {
        "nombre": "BISAGRA TRASERA DERECHA SUPERIOR",
        
        "Familia": 1,
        
      },
      {
        "nombre": "BISAGRAS CAPO",
        
        "Familia": 1,
        
      },
      {
        "nombre": "BISAGRAS PUERTAS TRASERAS",
        
        "Familia": 1,
        
      },
      {
        "nombre": "BLOQUE",
        "idcrm": 367
      },
      {
        "nombre": "BOBINA ENCENDIDO",
        "idcrm": 227
      },
      {
        "nombre": "BOMBA ACEITE",
        "idcrm": 368
      },
      {
        "nombre": "BOMBA AGUA",
        "idcrm": 369
      },
      {
        "nombre": "BOMBA AGUA ADICIONAL",
        
        "Familia": 11,
        
      },
      {
        "nombre": "BOMBA AIRE SECUNDARIA",
        
        "Familia": 11,
        
      },
      {
        "nombre": "BOMBA ALTA PRESION",
        
        "Familia": 11,
        
      },
      {
        "nombre": "BOMBA CIERRE CENTRALIZADO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "BOMBA COMBUSTIBLE",
        "idcrm": 370
      },
      {
        "nombre": "BOMBA COMBUSTIBLE ADICIONAL",
        
        "Familia": 11,
        
      },
      {
        "nombre": "BOMBA DE AGUA ADICIONAL",
        
        "Familia": 11,
        
      },
      {
        "nombre": "BOMBA DE AGUA CALEFACCION",
        
        "Familia": 7,
        
      },
      {
        "nombre": "BOMBA DE AIRE SECUNDARIA",
        
        "Familia": 11,
        
      },
      {
        "nombre": "BOMBA DIRECCION",
        "idcrm": 201
      },
      {
        "nombre": "BOMBA DIRECCION ELECTRICA",
        
        "Familia": 8,
        
      },
      {
        "nombre": "BOMBA EMBRAGUE",
        "idcrm": 53
      },
      {
        "nombre": "BOMBA FRENO",
        "idcrm": 467
      },
      {
        "nombre": "BOMBA GASOIL AUXILIAR",
        
        "Familia": 11,
        
      },
      {
        "nombre": "BOMBA INYECCION",
        "idcrm": 371
      },
      {
        "nombre": "BOMBA INYECTORA",
        
        "Familia": 11,
        
      },
      {
        "nombre": "BOMBA LIMPIA",
        "idcrm": 228
      },
      {
        "nombre": "BOMBA SECUNDARIA ADICIONAL",
        
        "Familia": 11,
        
      },
      {
        "nombre": "BOMBA SERVODIRECCION",
        "idcrm": 468
      },
      {
        "nombre": "BOMBA SERVODIRECCION / MECANICA",
        
        "Familia": 8,
        
      },
      {
        "nombre": "BOMBA VACIO",
        
        "Familia": 11,
        
      },
      {
        "nombre": "BOMBILLAS",
        "idcrm": 7
      },
      {
        "nombre": "BOMBIN EMBRAGUE",
        "idcrm": 54
      },
      {
        "nombre": "BOMBIN FRENO TAMBOR",
        "idcrm": 470
      },
      {
        "nombre": "BOMBIN PORTON TRASERO",
        "idcrm": 162
      },
      {
        "nombre": "BOMBIN PUERTA DELANTERA DERECHA",
        "idcrm": 110
      },
      {
        "nombre": "BOMBIN PUERTA DELANTERA IZQUIERDA",
        "idcrm": 111
      },
      {
        "nombre": "BOTON AIRBAG",
        
        "Familia": 9,
        
      },
      {
        "nombre": "BOTON APERTURA DEPOSITO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "BOTON EMERGENCIA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "BOTON FRENO MANO ELECTRICO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "BOTON LUNETA",
        
        "Familia": 10,
        
      },
      {
        "nombre": "BOTON REGULADOR LUCES-NO USAR",
        
        "Familia": 9,
        
      },
      {
        "nombre": "BOTON START/STOP",
        
        "Familia": 9,
        
      },
      {
        "nombre": "BOTONERA DE ELEVALUNAS",
        
        "Familia": 10,
        
      },
      {
        "nombre": "BOTONERA ELEVALUNAS DELANTERO DERECHO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "BOTONERA ELEVALUNAS DELANTERO IZQUIERDO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "BOTONERA ELEVALUNAS TRASERO DERECHO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "BOTONERA ELEVALUNAS TRASERO IZQUIERDO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "BOTONERA RETROVISOR",
        
        "Familia": 10,
        
      },
      {
        "nombre": "BOTONES SALPICADERO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "BRAZO LIMPIA DELANTERO DERECHO",
        "idcrm": 77
      },
      {
        "nombre": "BRAZO LIMPIA DELANTERO IZQUIERDO",
        "idcrm": 78
      },
      {
        "nombre": "BRAZO LIMPIA TRASERO",
        "idcrm": 163
      },
      {
        "nombre": "BRAZO SUSPENSION DELANTERO DERECHO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "BRAZO SUSPENSION DELANTERO IZQUIERDO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "BRAZO SUSPENSION INFERIOR DELANTERO DERECHO",
        "idcrm": 471
      },
      {
        "nombre": "BRAZO SUSPENSION INFERIOR DELANTERO IZQUIERDO",
        "idcrm": 472
      },
      {
        "nombre": "BRAZO SUSPENSION INFERIOR TRASERO DERECHO",
        "idcrm": 473
      },
      {
        "nombre": "BRAZO SUSPENSION INFERIOR TRASERO IZQUIERDO",
        "idcrm": 474
      },
      {
        "nombre": "BRAZO SUSPENSION SUPERIOR DELANTERO DERECHO",
        "idcrm": 475
      },
      {
        "nombre": "BRAZO SUSPENSION SUPERIOR DELANTERO IZQUIERDO",
        "idcrm": 476
      },
      {
        "nombre": "BRAZO SUSPENSION SUPERIOR TRASERO DERECHO",
        "idcrm": 477
      },
      {
        "nombre": "BRAZO SUSPENSION SUPERIOR TRASERO IZQUIERDO",
        "idcrm": 478
      },
      {
        "nombre": "BRAZO SUSPENSION TRASERO DERECHO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "BRAZO SUSPENSION TRASERO IZQUIERDO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "BUJE",
        "idcrm": 202
      },
      {
        "nombre": "BUJE TRASERO DERECHO",
        
        "Familia": 8,
        
      },
      {
        "nombre": "BUJIA AUTO",
        
        "Familia": 11,
        
      },
      {
        "nombre": "BUJIA ENCENDIDO",
        
        "Familia": 11,
        
      },
      {
        "nombre": "CABALLETE LATERAL",
        "idcrm": 8
      },
      {
        "nombre": "CABEZAL INYECCION",
        "idcrm": 372
      },
      {
        "nombre": "CABLE ACELERADOR",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CABLE BATERIA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CABLE DE CAPO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CABLE DE EMBRAGUE",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CABLE FRENO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CABLE KM",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CABLE PALANCA CAMBIO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CABLEADO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CABLEADO INYECTORES",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CABLEADO SENSORES PARKING",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CABLES BUJIAS",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CABLES CAMBIO",
        
        "Familia": 3,
        
      },
      {
        "nombre": "CABLES DE ARRANQUE",
        
        "Familia": 1,
        
      },
      {
        "nombre": "CADENAS",
        "idcrm": 9
      },
      {
        "nombre": "CAJA CALENTADORES",
        
        "Familia": 11,
        
      },
      {
        "nombre": "CAJA CAMBIOS",
        "idcrm": 55
      },
      {
        "nombre": "CAJA CAMBIOS CON TRANSFER",
        "idcrm": 55
      },
      {
        "nombre": "CAJA CAMBIOS LINEAL",
        "idcrm": 55
      },
      {
        "nombre": "CAJA CAMBIOS PARA DESPIECE",
        "idcrm": 55
      },
      {
        "nombre": "CAJA CAMBIOS PARA DESPIECES",
        "idcrm": 55
      },
      {
        "nombre": "CAJA DE DIRECCION",
        
        "Familia": 8,
        
      },
      {
        "nombre": "CAJA DIRECCION",
        "idcrm": 204
      },
      {
        "nombre": "CAJA ELECTRONICA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CAJA FILTRO AIRE",
        
        "Familia": 11,
        
      },
      {
        "nombre": "CAJA FILTRO DE AIRE",
        
        "Familia": 11,
        
      },
      {
        "nombre": "CAJA FILTRO GASOIL",
        
        "Familia": 11,
        
      },
      {
        "nombre": "CAJA FUSIBLES",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CAJA MARIPOSA",
        "idcrm": 373
      },
      {
        "nombre": "CAJA MARIPOSA EGR",
        "idcrm": 373
      },
      {
        "nombre": "CAJA PRECALENTAMIENTO",
        "idcrm": 229
      },
      {
        "nombre": "CAJA RELES / FUSIBLES",
        "idcrm": 230
      },
      {
        "nombre": "CAJA TRANSFER",
        "idcrm": 56
      },
      {
        "nombre": "CAJA TRANSMISION",
        "idcrm": 205
      },
      {
        "nombre": "CAJA VALVULAS",
        
        "Familia": 13,
        
      },
      {
        "nombre": "CAJON BATERIA",
        
        "Familia": 1,
        
      },
      {
        "nombre": "CALAMINA INTERIOR",
        
        "Familia": 13,
        
      },
      {
        "nombre": "CALANDRA DELANTERA DE RADIADOR",
        "idcrm": 79
      },
      {
        "nombre": "CALANDRA DELANTERA RADIADOR",
        
        "Familia": 4,
        
      },
      {
        "nombre": "CALEFACCION AUXILIAR",
        
        "Familia": 7,
        
      },
      {
        "nombre": "CALEFACCION ENTERA NORMAL",
        "idcrm": 185
      },
      {
        "nombre": "CALENTADOR AGUA",
        
        "Familia": 11,
        
      },
      {
        "nombre": "CALENTADORES",
        "idcrm": 231
      },
      {
        "nombre": "CAMARA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CAMARA DELANTERA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CAMARA TRASERA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CAMARA VISION TRASERA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CA�A / COLUMNA DIRECCION",
        "idcrm": 207
      },
      {
        "nombre": "CAPOT",
        "idcrm": 80
      },
      {
        "nombre": "CAPOTA",
        
        "Familia": 1,
        
      },
      {
        "nombre": "CAPTADOR ABS DELANTERO DERECHO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "CAPTADOR ABS DELANTERO IZQUIERDO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "CAPTADOR ABS TRASERO DERECHO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "CAPTADOR ABS TRASERO IZQUIERDO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "CAPTADOR ARBOL LEVAS",
        
        "Familia": 11,
        
      },
      {
        "nombre": "CAPTADOR VOLANTE MOTOR",
        "idcrm": 232
      },
      {
        "nombre": "CARBURADOR",
        "idcrm": 374
      },
      {
        "nombre": "CARCASA DE ESPEJO",
        
        "Familia": 5,
        
      },
      {
        "nombre": "CARCASA FILTRO AIRE",
        
        "Familia": 11,
        
      },
      {
        "nombre": "CARCASA FILTRO DE AIRE",
        
        "Familia": 11,
        
      },
      {
        "nombre": "CARCASA LLAVE CONTACTO",
        
        "Familia": 1,
        
      },
      {
        "nombre": "CARCASA RADIADOR",
        
        "Familia": 11,
        
      },
      {
        "nombre": "CARCASA RETROVISOR DERECHO",
        "idcrm": 112
      },
      {
        "nombre": "CARCASA RETROVISOR IZQUIERDO",
        "idcrm": 113
      },
      {
        "nombre": "CARCASA VENTILADOR",
        
        "Familia": 7,
        
      },
      {
        "nombre": "CARDAN DELANTERO",
        "idcrm": 479
      },
      {
        "nombre": "CARDAN TRASERO",
        "idcrm": 480
      },
      {
        "nombre": "CARENADO DELANTERO DERECHO",
        "idcrm": 81
      },
      {
        "nombre": "CARENADO DELANTERO IZQUIERDO",
        "idcrm": 82
      },
      {
        "nombre": "CARGADOR CD",
        
        "Familia": 1,
        
      },
      {
        "nombre": "CARGADOR DE BATERIAS",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CARRACA REVERSIBLE",
        
        "Familia": 1,
        
      },
      {
        "nombre": "CARRO HERRAMIENTAS",
        
        "Familia": 1,
        
      },
      {
        "nombre": "CARROCERIA COMPLETA",
        
        "Familia": 6,
        
      },
      {
        "nombre": "CARTER",
        "idcrm": 375
      },
      {
        "nombre": "CARTER ACEITE",
        "idcrm": 375
      },
      {
        "nombre": "CARTER C/AGUJERO VAG",
        "idcrm": 375
      },
      {
        "nombre": "CARTER S/AGUJERO VAG",
        "idcrm": 375
      },
      {
        "nombre": "CARTUCHO  TURBO",
        
        "Familia": 11,
        
      },
      {
        "nombre": "CATADRIOPTICO / REFLECTANTE",
        
        "Familia": 2,
        
      },
      {
        "nombre": "CATALIZADOR",
        "idcrm": 376
      },
      {
        "nombre": "CAUDALIMETRO",
        "idcrm": 377
      },
      {
        "nombre": "CENICERO",
        "idcrm": 319
      },
      {
        "nombre": "CENTRALITA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CENTRALITA ABS",
        "idcrm": 233
      },
      {
        "nombre": "CENTRALITA AIRBAG",
        "idcrm": 234
      },
      {
        "nombre": "CENTRALITA AIRE ACONDICIONADO",
        "idcrm": 235
      },
      {
        "nombre": "CENTRALITA ALARMA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CENTRALITA ANTENA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CENTRALITA ASD",
        "idcrm": 236
      },
      {
        "nombre": "CENTRALITA ASIENTOS",
        "idcrm": 237
      },
      {
        "nombre": "CENTRALITA ASR",
        "idcrm": 238
      },
      {
        "nombre": "CENTRALITA BATERIA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CENTRALITA BOMBA COMBUSTIBLE",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CENTRALITA BSI",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CENTRALITA BSM",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CENTRALITA CAJA CAMBIOS",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CENTRALITA CALEFACCION",
        "idcrm": 239
      },
      {
        "nombre": "CENTRALITA CAMBIO AUTOMATICO",
        "idcrm": 240
      },
      {
        "nombre": "CENTRALITA CARROCERIA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CENTRALITA CAS",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CENTRALITA CHECK CONTROL",
        "idcrm": 241
      },
      {
        "nombre": "CENTRALITA CIERRE",
        "idcrm": 242
      },
      {
        "nombre": "CENTRALITA CONTROL ESTABILIDAD",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CENTRALITA CUADRO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CENTRALITA DIRECCION",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CENTRALITA DYNAMIC DRIVE",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CENTRALITA ELECTROVALVULAS",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CENTRALITA FARO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CENTRALITA FAROS",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CENTRALITA FAROS XENON",
        "idcrm": 243
      },
      {
        "nombre": "CENTRALITA INMOVILIZADOR",
        "idcrm": 244
      },
      {
        "nombre": "CENTRALITA INYECCION",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CENTRALITA LIMPIA",
        "idcrm": 245
      },
      {
        "nombre": "CENTRALITA LUCES",
        "idcrm": 246
      },
      {
        "nombre": "CENTRALITA LUZ DIURNA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CENTRALITA MANDO A DISTANCIA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CENTRALITA MOTOR UCE",
        "idcrm": 247
      },
      {
        "nombre": "CENTRALITA PARKING",
        "idcrm": ""
      },
      {
        "nombre": "CENTRALITA PARKTRONIC",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CENTRALITA SUSPENSION",
        "idcrm": 249
      },
      {
        "nombre": "CENTRALITA TELEFONO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CENTRALITA VENTILADOR",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CERQUILLO FARO",
        
        "Familia": 2,
        
      },
      {
        "nombre": "CERRADURA ASIENTOS TRASEROS",
        
        "Familia": 1,
        
      },
      {
        "nombre": "CERRADURA CAPOT",
        "idcrm": 83
      },
      {
        "nombre": "CERRADURA LATERAL DERECHO",
        
        "Familia": 1,
        
      },
      {
        "nombre": "CERRADURA MALETERO / PORTON",
        "idcrm": 165
      },
      {
        "nombre": "CERRADURA PUERTA CORREDERA DERECHA",
        
        "Familia": 1,
        
      },
      {
        "nombre": "CERRADURA PUERTA CORREDERA IZQUIERDA",
        
        "Familia": 1,
        
      },
      {
        "nombre": "CERRADURA PUERTA DEL. DERECHA",
        "idcrm": 114
      },
      {
        "nombre": "CERRADURA PUERTA DEL. IZQUIERDA",
        "idcrm": 115
      },
      {
        "nombre": "CERRADURA PUERTA DELANTERA DERECHA",
        "idcrm": 114
      },
      {
        "nombre": "CERRADURA PUERTA DELANTERA IZQUIERDA",
        "idcrm": 115
      },
      {
        "nombre": "CERRADURA PUERTA DEPOSITO COMBUSTIBLE",
        
        "Familia": 1,
        
      },
      {
        "nombre": "CERRADURA PUERTA LATERAL DERECHA",
        "idcrm": 116
      },
      {
        "nombre": "CERRADURA PUERTA LATERAL IZQUIERDA",
        "idcrm": 117
      },
      {
        "nombre": "CERRADURA PUERTA TRASERA DERECHA",
        "idcrm": 116
      },
      {
        "nombre": "CERRADURA PUERTA TRASERA IZQUIERDA",
        "idcrm": 117
      },
      {
        "nombre": "CHASIS LATERAL IZQUIERDO",
        
        "Familia": 5,
        
      },
      {
        "nombre": "CIGUE�AL",
        "idcrm": 378
      },
      {
        "nombre": "CINTA AISLANTE NEGRA",
        
        "Familia": 1,
        
      },
      {
        "nombre": "CINTURON SEGURIDAD CENTRAL",
        "idcrm": 324
      },
      {
        "nombre": "CINTURON SEGURIDAD DELANTERO",
        "idcrm": 320
      },
      {
        "nombre": "CINTURON SEGURIDAD DELANTERO DERECHO",
        "idcrm": 321
      },
      {
        "nombre": "CINTURON SEGURIDAD DELANTERO IZQUIERDO",
        "idcrm": 322
      },
      {
        "nombre": "CINTURON SEGURIDAD TRASERO",
        "idcrm": 323
      },
      {
        "nombre": "CINTURON SEGURIDAD TRASERO CENTRAL",
        "idcrm": 324
      },
      {
        "nombre": "CINTURON SEGURIDAD TRASERO DERECHO",
        "idcrm": 325
      },
      {
        "nombre": "CINTURON SEGURIDAD TRASERO IZQUIERDO",
        "idcrm": 326
      },
      {
        "nombre": "CINTURON SEGURIDAD TRASERO TRASERO DERECHO",
        "idcrm": 325
      },
      {
        "nombre": "CINTURON SEGURIDAD TRASERO TRASERO IZQUIERDO",
        "idcrm": 326
      },
      {
        "nombre": "CLAUSOR CON LLAVE",
        
        "Familia": 1,
        
      },
      {
        "nombre": "CLAUSOR DE TARJETA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CLAXON",
        "idcrm": 10
      },
      {
        "nombre": "COLECTOR ADMISION",
        "idcrm": 379
      },
      {
        "nombre": "COLECTOR ESCAPE",
        "idcrm": 380
      },
      {
        "nombre": "COLLARIN HIDRAULICO EMBRAGUE",
        
        "Familia": 3,
        
      },
      {
        "nombre": "COLUMNA DE DIRECCION",
        "idcrm": 207
      },
      {
        "nombre": "COLUMNA DIRECCION",
        "idcrm": 207
      },
      {
        "nombre": "COM 2000",
        
        "Familia": 13,
        
      },
      {
        "nombre": "COMPRESOR AIRE",
        "idcrm": 186
      },
      {
        "nombre": "COMPRESOR AIRE ACONDICIONADO",
        "idcrm": 186
      },
      {
        "nombre": "COMPRESOR DE CIERRE",
        
        "Familia": 1,
        
      },
      {
        "nombre": "COMPRESOR INFLADO DE NEUMATICOS",
        
        "Familia": 1,
        
      },
      {
        "nombre": "COMPRESOR SUSPENSION",
        
        "Familia": 1,
        
      },
      {
        "nombre": "COMPRESOR VOLUMETRICO",
        "idcrm": 381
      },
      {
        "nombre": "CONDENSADOR / RADIADOR  AIRE ACONDICIONADO",
        "idcrm": 187
      },
      {
        "nombre": "CONDENSADOR / RADIADOR AIRE ACONDICIONADO",
        "idcrm": 187
      },
      {
        "nombre": "soporte caja cambios",
        "idcrm": 67
      },
      {
        "nombre": "CONDENSADOR A/A",
        "idcrm": 187
      },
      {
        "nombre": "aireador interior",
        "idcrm": 1082
      },
      {
        "nombre": "PINZA FRENO DELANTERA DERECHA",
        "idcrm": 494,
      },
      {
        "nombre": "PINZA FRENO DELANTERA IZQUIERDA",
        "idcrm": 495,
      },
      {
        "nombre": "PINZA FRENO TRASERA DERECHA",
        "idcrm": 496,
      },
      {
        "nombre": "PINZA FRENO TRASERA IZQUIERDA",
        "idcrm": 497,
      },
      {
        "nombre": "CONDUCTO AIRE",
        
        "Familia": 11,
        
      },
      {
        "nombre": "CONECTOR ENTRADA AUXILIAR",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CONECTOR UNIVERSAL ISO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CONEXION USB/AUX",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CONJUNTO CALEFACCION",
        
        "Familia": 7,
        
      },
      {
        "nombre": "CONJUNTO ELECTROS",
        
        "Familia": 9,
        
      },
      {
        "nombre": "CONJUNTO SUSPENSI�N",
        
        "Familia": 12,
        
      },
      {
        "nombre": "CONJUNTO VOLANTE MOTOR",
        
        "Familia": 11,
        
      },
      {
        "nombre": "CONMUTADOR DE ARRANQUE",
        "idcrm": 250
      },
      {
        "nombre": "CONSOLA",
        
        "Familia": 10,
        
      },
      {
        "nombre": "CONSOLA CENTRAL",
        
        "Familia": 10,
        
      },
      {
        "nombre": "CONSOLA CENTRAL SALPICADERO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "CONSOLA DE FRENO MANO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "CONTROL DE CRUCERO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "COPELA AMORTIGUADOR DELANTERO DERECHO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "COPELA AMORTIGUADOR DELANTERO IZQUIERDO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "CORREA ACCESORIOS",
        "idcrm": 382
      },
      {
        "nombre": "CORREA ALTERNADOR",
        
        "Familia": 1,
        
      },
      {
        "nombre": "CORREA DISTRIBUCION",
        "idcrm": 383
      },
      {
        "nombre": "CORTINA PARASOL",
        
        "Familia": 5,
        
      },
      {
        "nombre": "CORTINA TRASERA",
        
        "Familia": 5,
        
      },
      {
        "nombre": "CORTINA TRASERA ELECTRICA",
        
        "Familia": 5,
        
      },
      {
        "nombre": "CREMALLERA DE DIRECCION",
        "idcrm": 208
      },
      {
        "nombre": "CREMALLERA DIRECCION",
        "idcrm": 208
      },
      {
        "nombre": "CRISTAL ESPEJO DCHO.",
        
        "Familia": 5,
        
      },
      {
        "nombre": "CRISTAL ESPEJO DERECHO",
        
        "Familia": 5,
        
      },
      {
        "nombre": "CRISTAL ESPEJO IZQ.",
        
        "Familia": 5,
        
      },
      {
        "nombre": "CRISTAL ESPEJO IZQUIERDO",
        
        "Familia": 5,
        
      },
      {
        "nombre": "CRISTAL RETROVISOR DERECHO",
        "idcrm": 120
      },
      {
        "nombre": "CRISTAL RETROVISOR IZQUIERDO",
        "idcrm": 121
      },
      {
        "nombre": "CUADRO INSTRUMENTOS",
        "idcrm": 251
      },
      {
        "nombre": "CUBIERTA MOTOR",
        "idcrm": 384
      },
      {
        "nombre": "CUBO DE BLOQUEO",
        
        "Familia": 1,
        
      },
      {
        "nombre": "CUBOS DE BLOQUEO",
        "idcrm": 209
      },
      {
        "nombre": "CUBREBAJOS",
        "idcrm": 84
      },
      {
        "nombre": "CUBRECARTER",
        "idcrm": 84
      },
      {
        "nombre": "CUBRECARTER LATERAL",
        "idcrm": 84
      },
      {
        "nombre": "CUERPO TERMOSTATO",
        
        "Familia": 11,
        
      },
      {
        "nombre": "CULATA",
        
        "Familia": 11,
        
      },
      {
        "nombre": "CULATA MOTOR",
        
        "Familia": 11,
        
      },
      {
        "nombre": "CULATIN",
        "idcrm": 386
      },
      {
        "nombre": "CUNA MOTOR",
        
        "Familia": 12,
        
      },
      {
        "nombre": "CUNA MOTOR / SUBCHASIS",
        
        "Familia": 12,
        
      },
      {
        "nombre": "CUNA TRASERA",
        
        "Familia": 12,
        
      },
      {
        "nombre": "CUPULA",
        "idcrm": 85
      },
      {
        "nombre": "DEFENSA DELANTERA",
        "idcrm": 86
      },
      {
        "nombre": "DELCO",
        "idcrm": 252
      },
      {
        "nombre": "DEPOSITO  DIRECCION",
        
        "Familia": 9,
        
      },
      {
        "nombre": "DEPOSITO  LIQUIDO DIRECCION",
        
        "Familia": 8,
        
      },
      {
        "nombre": "DEPOSITO ADBLUE",
        "idcrm": 514
      },
      {
        "nombre": "DEPOSITO ADITIVO",
        
        "Familia": 13,
        
      },
      {
        "nombre": "DEPOSITO ADITIVO FILTRO PARTICULAS",
        
        "Familia": 13,
        
      },
      {
        "nombre": "DEPOSITO COMBUSTIBLE",
        "idcrm": 387
      },
      {
        "nombre": "DEPOSITO DE DIRECCION",
        
        "Familia": 8,
        
      },
      {
        "nombre": "DEPOSITO EXPANSION",
        "idcrm": 388
      },
      {
        "nombre": "DEPOSITO FRENO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "DEPOSITO LIMPIA",
        "idcrm": 87
      },
      {
        "nombre": "DEPOSITO LIQUIDO FRENOS",
        
        "Familia": 12,
        
      },
      {
        "nombre": "DEPOSITO SERVO",
        "idcrm": 481
      },
      {
        "nombre": "DEPRESOR CULATA",
        
        "Familia": 11,
        
      },
      {
        "nombre": "DEPRESOR FRENO / BOMBA VACIO",
        "idcrm": 483
      },
      {
        "nombre": "DEPRESOR GASES",
        
        "Familia": 11,
        
      },
      {
        "nombre": "DESCONECTADOR DE BATERIAS",
        
        "Familia": 9,
        
      },
      {
        "nombre": "DESPIECE",
        
        "Familia": 13,
        
      },
      {
        "nombre": "DESPIECE CAJA CAMBIOS",
        "idcrm": 57
      },
      {
        "nombre": "DESPIECE MOTOR",
        "idcrm": 389
      },
      {
        "nombre": "DIFERENCIAL CENTRAL",
        "idcrm": 58
      },
      {
        "nombre": "DIFERENCIAL DELANTERO",
        "idcrm": 59
      },
      {
        "nombre": "DIFERENCIAL TRASERO",
        "idcrm": 60
      },
      {
        "nombre": "DINAMO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "DISCO EMBRAGUE",
        
        "Familia": 3,
        
      },
      {
        "nombre": "DISCO FRENO DELANTERO",
        "idcrm": 484
      },
      {
        "nombre": "DISCO FRENO DELANTERO DERECHO",
        
        "Familia": 2,
        
      },
      {
        "nombre": "DISCO FRENO DELANTERO IZQUIERDO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "DISCO FRENO TRASERO",
        "idcrm": 485
      },
      {
        "nombre": "DISCO FRENO TRASERO DERECHO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "DISCO FRENO TRASERO IZQUIERDO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "EJE BALANCINES",
        "idcrm": 390
      },
      {
        "nombre": "ELECTROVALVULA VACIO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "ELECTROVENTILADOR",
        "idcrm": 253
      },
      {
        "nombre": "ELECTROVENTILADOR RADIADOR AIRE ACONDICIONADO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "ELEVALUNAS DELANTERO DERECHO",
        "idcrm": 122
      },
      {
        "nombre": "ELEVALUNAS DELANTERO IZQUIERDO",
        "idcrm": 123
      },
      {
        "nombre": "ELEVALUNAS ELECTRICO DELANTERO DERECHO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "ELEVALUNAS ELECTRICO DELANTERO IZQUIERDO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "ELEVALUNAS ELECTRICO TRASERO DERECHO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "ELEVALUNAS ELECTRICO TRASERO IZQUIERDO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "ELEVALUNAS MANUAL DELANTERO DERECHO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "ELEVALUNAS MANUAL DELANTERO IZQUIERDO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "ELEVALUNAS MANUAL TRASERO DERECHO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "ELEVALUNAS MANUAL TRASERO IZQUIERDO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "ELEVALUNAS TRASERO DERECHO",
        "idcrm": 124
      },
      {
        "nombre": "ELEVALUNAS TRASERO IZQUIERDO",
        "idcrm": 125
      },
      {
        "nombre": "EMBELLECEDOR",
        
        "Familia": 4,
        
      },
      {
        "nombre": "EMULADOR",
        
        "Familia": 9,
        
      },
      {
        "nombre": "ENCHUFE",
        
        "Familia": 9,
        
      },
      {
        "nombre": "ENFRIADOR ACEITE DIRECCION",
        "idcrm": 210
      },
      {
        "nombre": "ENFRIADOR ACEITE MOTOR",
        "idcrm": 391
      },
      {
        "nombre": "ENFRIADOR DE COMBUSTIBLE",
        
        "Familia": 11,
        
      },
      {
        "nombre": "ENFRIADOR DE GASES",
        
        "Familia": 11,
        
      },
      {
        "nombre": "ENFRIADOR EGR",
        "idcrm": 392
      },
      {
        "nombre": "ENFRIADOR GASES EGR",
        "idcrm": 392
      },
      {
        "nombre": "ENFRIADOR GASOIL",
        
        "Familia": 11,
        
      },
      {
        "nombre": "ENGANCHE CINTURON",
        
        "Familia": 1,
        
      },
      {
        "nombre": "ENGANCHE CINTURON DELANTERO DERECHO",
        
        "Familia": 1,
        
      },
      {
        "nombre": "ENGANCHE CINTURON DELANTERO IZQUIERDO",
        
        "Familia": 1,
        
      },
      {
        "nombre": "ENGANCHE CINTURON TRASERO CENTRAL",
        
        "Familia": 1,
        
      },
      {
        "nombre": "ENGANCHE CINTURON TRASERO DERECHO",
        
        "Familia": 1,
        
      },
      {
        "nombre": "ENGANCHE CINTURON TRASERO IZQUIERDO",
        
        "Familia": 1,
        
      },
      {
        "nombre": "ENGANCHE DELANTERO DERECHO",
        
        "Familia": 4,
        
      },
      {
        "nombre": "ENGANCHE DELANTERO IZQUIERDO",
        
        "Familia": 4,
        
      },
      {
        "nombre": "ENGANCHE REMOLQUE",
        "idcrm": 11
      },
      {
        "nombre": "ENGANCHE TRASERO DERECHO",
        "idcrm": 11
      },
      {
        "nombre": "ENGANCHE TRASERO IZQUIERDO",
        "idcrm": 11
      },
      {
        "nombre": "ESCOBILLA LIMPIA",
        "idcrm": 12
      },
      {
        "nombre": "ESCOBILLA LIMPIA 530 M.M",
        "idcrm": 12
      },
      {
        "nombre": "ESCOBILLA LIMPIA METALICA ANKO 45 CMS",
        "idcrm": 12
      },
      {
        "nombre": "ESCOBILLA LIMPIA TRASERA",
        "idcrm": 12
      },
      {
        "nombre": "ESPEJO DERECHO ELECTRICO",
        
        "Familia": 5,
        
      },
      {
        "nombre": "ESPEJO DERECHO MANUAL",
        
        "Familia": 5,
        
      },
      {
        "nombre": "ESPEJO INTERIOR",
        "idcrm": 327
      },
      {
        "nombre": "ESPEJO IZQUIERDO ELECTRICO",
        
        "Familia": 5,
        
      },
      {
        "nombre": "ESPEJO MANUAL IZQUIERDO",
        
        "Familia": 5,
        
      },
      {
        "nombre": "ESPEJO UNIVERSAL",
        
        "Familia": 5,
        
      },
      {
        "nombre": "ESTRIBO",
        "idcrm": 126
      },
      {
        "nombre": "ESTRIBO DERECHO",
        "idcrm": 126
      },
      {
        "nombre": "ESTRIBO IZQUIERDO",
        "idcrm": 126
      },
      {
        "nombre": "ESTRIBO TRASERO",
        "idcrm": 126
      },
      {
        "nombre": "ESTRIBOS LATERALES",
        "idcrm": 126
      },
      {
        "nombre": "ETAPA DE POTENCIA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "EVAPORADOR AIRE ACONDICIONADO",
        "idcrm": 189
      },
      {
        "nombre": "FALDON DELANTERO",
        "idcrm": 88
      },
      {
        "nombre": "FALDON LATERAL",
        "idcrm": 127
      },
      {
        "nombre": "FALDON TRASERO",
        "idcrm": 167
      },
      {
        "nombre": "FARO ANTINIEBLA DELANTERO DERECHO",
        "idcrm": 29
      },
      {
        "nombre": "FARO ANTINIEBLA DELANTERO IZQUIERDO",
        "idcrm": 30
      },
      {
        "nombre": "FARO ANTINIEBLA DERECHO",
        "idcrm": 29
      },
      {
        "nombre": "FARO ANTINIEBLA IZQUIERDO",
        "idcrm": 30
      },
      {
        "nombre": "FARO ANTINIEBLA TRASERO CENTRAL",
        
        "Familia": 2,
        
      },
      {
        "nombre": "FARO ANTINIEBLA TRASERO DERECHO",
        "idcrm": 31
      },
      {
        "nombre": "FARO ANTINIEBLA TRASERO IZQUIERDO",
        "idcrm": 32
      },
      {
        "nombre": "FARO DELANTERO",
        "idcrm": 33
      },
      {
        "nombre": "FARO DERECHO",
        "idcrm": 34
      },
      {
        "nombre": "FARO DERECHO XENON",
        "idcrm": 34
      },
      {
        "nombre": "FARO DIURNO DERECHO",
        "idcrm": 34
      },
      {
        "nombre": "FARO DIURNO IZQUIERDO",
        "idcrm": 35
      },
      {
        "nombre": "FARO GIRATORIO MAGNETICO 12V",
        
        "Familia": 2,
        
      },
      {
        "nombre": "FARO IZQUIERDO",
        "idcrm": 35
      },
      {
        "nombre": "FARO IZQUIERDO XENON",
        "idcrm": 35
      },
      {
        "nombre": "FARO LUZ CORTA DERECHO",
        
        "Familia": 2,
        
      },
      {
        "nombre": "FARO LUZ LARGA DERECHO",
        
        "Familia": 2,
        
      },
      {
        "nombre": "FARO TRABAJO LED",
        
        "Familia": 2,
        
      },
      {
        "nombre": "FERRETERIA",
        
        "Familia": 1,
        
      },
      {
        "nombre": "FILTRO ACEITE",
        "idcrm": 393
      },
      {
        "nombre": "FILTRO AIRE",
        "idcrm": 394
      },
      {
        "nombre": "FILTRO ANTIPOLEN",
        "idcrm": 13
      },
      {
        "nombre": "FILTRO CARBONO ACTIVO",
        "idcrm": 395
      },
      {
        "nombre": "FILTRO COMBUSTIBLE",
        "idcrm": 13
      },
      {
        "nombre": "FILTRO DE PARTICULAS",
        "idcrm": 396
      },
      {
        "nombre": "FILTRO DESHIDRATADOR",
        "idcrm": 13
      },
      {
        "nombre": "FILTRO DESHIDRATANTE",
        "idcrm": 13
      },
      {
        "nombre": "FILTRO GAS-OIL",
        "idcrm": 397
      },
      {
        "nombre": "FILTRO GASOIL",
        "idcrm": 397
      },
      {
        "nombre": "FILTRO GASOLINA",
        "idcrm": 398
      },
      {
        "nombre": "FILTRO HABITACULO",
        "idcrm": 13
      },
      {
        "nombre": "FILTRO HABITACULO ANTIPOLEN",
        "idcrm": 13
      },
      {
        "nombre": "FILTROS GAS-OIL",
        "idcrm": 13
      },
      {
        "nombre": "FLECTOR CARDAN",
        "idcrm": 211
      },
      {
        "nombre": "FRENO DE MANO ELECTRICO",
        "idcrm": 486
      },
      {
        "nombre": "FRENTE",
        
        "Familia": 12,
        
      },
      {
        "nombre": "FRENTE DELANTERO",
        
        "Familia": 4,
        
      },
      {
        "nombre": "FRENTE SUPERIOR",
        
        "Familia": 4,
        
      },
      {
        "nombre": "FUELLE DIRECCION UNIVERSAL",
        "idcrm": 212
      },
      {
        "nombre": "FUELLE PALANCA CAMBIOS",
        "idcrm": 212
      },
      {
        "nombre": "FUELLE TRANSMISION",
        "idcrm": 212
      },
      {
        "nombre": "GATEWAY",
        
        "Familia": 9,
        
      },
      {
        "nombre": "GATO",
        "idcrm": 14
      },
      {
        "nombre": "GEOMETRIA VARIABLE TURBO",
        
        "Familia": 11,
        
      },
      {
        "nombre": "GOMAS CONTORNO PUERTA",
        "idcrm": 128
      },
      {
        "nombre": "GRASA MULTIUSO",
        
        "Familia": 1,
        
      },
      {
        "nombre": "GRASA SPRAY MULTIUSO",
        
        "Familia": 1,
        
      },
      {
        "nombre": "GRUPO DIFERENCIAL DEL.",
        
        "Familia": 3,
        
      },
      {
        "nombre": "GRUPO OPTICA",
        
        "Familia": 2,
        
      },
      {
        "nombre": "GUANTE NITRILO",
        
        "Familia": 1,
        
      },
      {
        "nombre": "GUANTERA",
        "idcrm": 328
      },
      {
        "nombre": "GUANTERA SUPERIOR",
        
        "Familia": 10,
        
      },
      {
        "nombre": "GUARDABARROS",
        "idcrm": 89
      },
      {
        "nombre": "GUARDABARROS TRASERO",
        "idcrm": 168
      },
      {
        "nombre": "GUARNECIDO PORTON TRASERO",
        "idcrm": 329
      },
      {
        "nombre": "GUARNECIDO PUERTA DELANTERA DERECHA",
        "idcrm": 330
      },
      {
        "nombre": "GUARNECIDO PUERTA DELANTERA IZQUIERDA",
        "idcrm": 331
      },
      {
        "nombre": "GUARNECIDO PUERTA TRASERA DERECHA",
        "idcrm": 332
      },
      {
        "nombre": "GUARNECIDO PUERTA TRASERA IZQUIERDA",
        "idcrm": 333
      },
      {
        "nombre": "GUARNECIDOS MALETERO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "GUARNECIDOS PALANCA CAMBIO",
        "idcrm": 334
      },
      {
        "nombre": "GUIAS PUERTA CORREDERA",
        
        "Familia": 5,
        
      },
      {
        "nombre": "HARDTOP",
        
        "Familia": 6,
        
      },
      {
        "nombre": "HERRAMIENTAS",
        "idcrm": 15
      },
      {
        "nombre": "HORQUILLA BRAZO DERECHA",
        
        "Familia": 12,
        
      },
      {
        "nombre": "HORQUILLA COMPLETA",
        "idcrm": 213
      },
      {
        "nombre": "INDICADOR / RELOJ",
        
        "Familia": 9,
        
      },
      {
        "nombre": "INDICADOR PRESION TURBO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "INDICADOR TEMPERATURA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "INSIGNIA",
        
        "Familia": 5,
        
      },
      {
        "nombre": "INSTALACION SENSORES PARKING",
        
        "Familia": 9,
        
      },
      {
        "nombre": "INTERCOOLER",
        "idcrm": 399
      },
      {
        "nombre": "INTERFACE AUDIO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "INTERRUPTOR",
        "idcrm": 254
      },
      {
        "nombre": "INTERRUPTOR AVERIA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "INTERRUPTOR FRENO MANO  ELECTRICO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "INTERRUPTOR STAR/STOP",
        
        "Familia": 9,
        
      },
      {
        "nombre": "INTERRUPTOR START/STOP",
        
        "Familia": 9,
        
      },
      {
        "nombre": "INTERRUPTOR TECHO ELECTRICO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "INTERRUPTOR WARNING",
        
        "Familia": 9,
        
      },
      {
        "nombre": "INTERRUPTORES CONSOLA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "INYECTOR",
        "idcrm": 400
      },
      {
        "nombre": "INYECTOR ADBLUE",
        
        "Familia": 11,
        
      },
      {
        "nombre": "INYECTOR LAVAFAROS",
        
        "Familia": 11,
        
      },
      {
        "nombre": "INYECTOR MONOPUNTO",
        "idcrm": 401
      },
      {
        "nombre": "JGO. ALFOMBRA A MEDIDA",
        
        "Familia": 10,
        
      },
      {
        "nombre": "JGO. BARRAS TECHO",
        
        "Familia": 1,
        
      },
      {
        "nombre": "JGO. PASTILLAS FRENO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "JUEGO ALFOMBRAS",
        
        "Familia": 10,
        
      },
      {
        "nombre": "JUEGO ALFOMBRILLAS",
        
        "Familia": 10,
        
      },
      {
        "nombre": "JUEGO AMORTIGUADORES DELANTERO",
        "idcrm": 488
      },
      {
        "nombre": "JUEGO AMORTIGUADORES TRASERO",
        "idcrm": 489
      },
      {
        "nombre": "JUEGO ASIENTOS COMPLETO",
        "idcrm": 335
      },
      {
        "nombre": "JUEGO BOMBINES",
        "idcrm": 129
      },
      {
        "nombre": "JUEGO CINTURONES DELANTERO",
        "idcrm": 336
      },
      {
        "nombre": "JUEGO CINTURONES TRASERO",
        "idcrm": 337
      },
      {
        "nombre": "JUEGO ESCOBILLAS",
        "idcrm": 12
      },
      {
        "nombre": "JUEGO HERRAMIENTAS DE CARRACA",
        "idcrm": 15
      },
      {
        "nombre": "JUEGO LLANTAS",
        "idcrm": 16
      },
      {
        "nombre": "JUEGO LLANTAS ALUMINIO 16 PULGADAS",
        "idcrm": 16
      },
      {
        "nombre": "JUEGO LLANTAS ALUMINIO 17 PULGADAS",
        "idcrm": 16
      },
      {
        "nombre": "JUEGO PASTILLAS DE FRENOS",
        
        "Familia": 12,
        
      },
      {
        "nombre": "JUEGO PEDALES",
        
        "Familia": 10,
        
      },
      {
        "nombre": "JUEGO RETENES",
        
        "Familia": 1,
        
      },
      {
        "nombre": "JUEGO TAPACUBOS",
        
        "Familia": 1,
        
      },
      {
        "nombre": "JUEGO TAPIZADOS / CARTONERAS",
        "idcrm": 338
      },
      {
        "nombre": "JUNTA CULATA",
        
        "Familia": 11,
        
      },
      {
        "nombre": "JUNTA HOMOCINETICA",
        "idcrm": 214
      },
      {
        "nombre": "JUNTA HOMOCINETICA ADAPTABLE",
        
        "Familia": 11,
        
      },
      {
        "nombre": "KIT AIRBAG",
        "idcrm": 339
      },
      {
        "nombre": "KIT BOMBILLAS LEDS",
        
        "Familia": 2,
        
      },
      {
        "nombre": "KIT DISTRIBUCION",
        "idcrm": 402
      },
      {
        "nombre": "KIT DISTRIBUCION CON BOMBA DE AGUA",
        "idcrm": 402
      },
      {
        "nombre": "KIT EMBRAGUE",
        "idcrm": 61
      },
      {
        "nombre": "KIT FRENOS",
        
        "Familia": 12,
        
      },
      {
        "nombre": "KIT JUNTAS",
        
        "Familia": 13,
        
      },
      {
        "nombre": "KIT JUNTAS ESCAPE TURBO",
        
        "Familia": 11,
        
      },
      {
        "nombre": "KIT REPARACION BOMBA INYECTORA",
        
        "Familia": 11,
        
      },
      {
        "nombre": "KIT REPARACION BOMBIN PUERTA",
        
        "Familia": 1,
        
      },
      {
        "nombre": "KIT SOPORTE FAROS",
        
        "Familia": 2,
        
      },
      {
        "nombre": "KIT TRANSMISION",
        
        "Familia": 8,
        
      },
      {
        "nombre": "LAMPARA",
        
        "Familia": 2,
        
      },
      {
        "nombre": "LAMPARA XENON",
        
        "Familia": 2,
        
      },
      {
        "nombre": "LATIGUILLO DE FRENO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "LATIGUILLOS DE FRENO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "LAVAFAROS",
        
        "Familia": 2,
        
      },
      {
        "nombre": "LAVAFAROS DERECHO",
        
        "Familia": 2,
        
      },
      {
        "nombre": "LAVAFAROS IZQUIERDO",
        
        "Familia": 2,
        
      },
      {
        "nombre": "LAVAMANOS FADEIN",
        
        "Familia": 2,
        
      },
      {
        "nombre": "LIMPIA FAROS XENON",
        "idcrm": 90
      },
      {
        "nombre": "LIMPIAPARABRISAS",
        
        "Familia": 1,
        
      },
      {
        "nombre": "LIQUIDO DE FRENOS",
        "idcrm": 28
      },
      {
        "nombre": "LIQUIDO DIRECCION",
        "idcrm": 28
      },
      {
        "nombre": "LIQUIDO LHM",
        
        "Familia": 1,
        
      },
      {
        "nombre": "LLANTA",
        "idcrm": 18
      },
      {
        "nombre": "LLANTA ALUMINIO 15 PULGADAS",
        "idcrm": 18
      },
      {
        "nombre": "LLANTA ALUMINIO 16 PULGADAS",
        "idcrm": 18
      },
      {
        "nombre": "LLANTA ALUMINIO 17 PULGADAS",
        "idcrm": 18
      },
      {
        "nombre": "LLAVE CONTACTO",
        
        "Familia": 1,
        
      },
      {
        "nombre": "LLAVE DE ARRANQUE",
        
        "Familia": 9,
        
      },
      {
        "nombre": "LOCTITE",
        
        "Familia": 1,
        
      },
      {
        "nombre": "LUCES XENON",
        
        "Familia": 2,
        
      },
      {
        "nombre": "LUNA CUSTODIA DELANTERA DERECHA",
        "idcrm": 130
      },
      {
        "nombre": "LUNA CUSTODIA DELANTERA IZQUIERDA",
        "idcrm": 131
      },
      {
        "nombre": "LUNA CUSTODIA TRASERA DERECHA",
        "idcrm": 132
      },
      {
        "nombre": "LUNA CUSTODIA TRASERA IZQUIERDA",
        "idcrm": 133
      },
      {
        "nombre": "LUNA DELANTERA",
        "idcrm": 134
      },
      {
        "nombre": "LUNA DELANTERA DERECHA",
        "idcrm": 134
      },
      {
        "nombre": "LUNA DELANTERA IZQUIERDA",
        "idcrm": 135
      },
      {
        "nombre": "LUNA TECHO SOLAR",
        
        "Familia": 2,
        
      },
      {
        "nombre": "LUNA TRASERA",
        "idcrm": 169
      },
      {
        "nombre": "LUNA TRASERA DERECHA",
        "idcrm": 136
      },
      {
        "nombre": "LUNA TRASERA IZQUIERDA",
        "idcrm": 137
      },
      {
        "nombre": "LUZ CENTRAL DE FRENO",
        "idcrm": 36
      },
      {
        "nombre": "LUZ DIA DERECHO",
        
        "Familia": 2,
        
      },
      {
        "nombre": "LUZ DIURNA DERECHO",
        
        "Familia": 2,
        
      },
      {
        "nombre": "LUZ DIURNA IZQUIERDO",
        
        "Familia": 2,
        
      },
      {
        "nombre": "LUZ INTERIOR",
        "idcrm": 37
      },
      {
        "nombre": "LUZ INTERIOR CENTRAL",
        
        "Familia": 2,
        
      },
      {
        "nombre": "LUZ INTERIOR DELANTERA",
        
        "Familia": 2,
        
      },
      {
        "nombre": "MANDO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MANDO 4 ELEVALUNAS",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MANDO ASIENTO IZQUIERDO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MANDO CALEFACCION /  AIRE ACONDICIONADO",
        "idcrm": 190
      },
      {
        "nombre": "MANDO CALEFACCION / AIRE ACONDICIONADO",
        "idcrm": 190
      },
      {
        "nombre": "MANDO CLIMATIZADOR",
        "idcrm": 191
      },
      {
        "nombre": "MANDO CONTROL TRACCION",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MANDO CONTROL VELOCIDAD",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MANDO ELEVALUNAS DEL IZQ",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MANDO ELEVALUNAS DELANTERO DERECHO",
        "idcrm": 255
      },
      {
        "nombre": "MANDO ELEVALUNAS DELANTERO IZQUIERDO",
        "idcrm": 256
      },
      {
        "nombre": "MANDO ELEVALUNAS TRASERO DERECHO",
        "idcrm": 257
      },
      {
        "nombre": "MANDO ELEVALUNAS TRASERO IZQUIERDO",
        "idcrm": 258
      },
      {
        "nombre": "MANDO IDRIVE",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MANDO INTERMITENTES",
        "idcrm": 259
      },
      {
        "nombre": "MANDO LIMPIA",
        "idcrm": 260
      },
      {
        "nombre": "MANDO LUCES",
        "idcrm": 261
      },
      {
        "nombre": "MANDO LUCES SALPICADERO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MANDO LUCES Y LIMPIA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MANDO MULTIFUNCION",
        "idcrm": 262
      },
      {
        "nombre": "MANDO RADIO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MANDO RADIO VOLANTE",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MANDO REGULACION ALTURA FAROS",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MANDO RETROVISOR",
        "idcrm": 263
      },
      {
        "nombre": "MANDO TECHO SOLAR",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MANDO VOLANTE",
        "idcrm": 264
      },
      {
        "nombre": "MANDOS CALEFACCION",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MANDOS SALPICADERO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MANDOS VOLANTE",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MANETA ELEVALUNAS",
        
        "Familia": 1,
        
      },
      {
        "nombre": "MANETA ELEVALUNAS DELANTERA DERECHA",
        "idcrm": 340
      },
      {
        "nombre": "MANETA ELEVALUNAS DELANTERA DERECHA_NO_USAR",
        "idcrm": 340
      },
      {
        "nombre": "MANETA ELEVALUNAS DELANTERA IZQUIERDA",
        "idcrm": 341
      },
      {
        "nombre": "MANETA ELEVALUNAS DELANTERA IZQUIERDA_NO_USAR",
        "idcrm": 341
      },
      {
        "nombre": "MANETA ELEVALUNAS TRASERA DERECHA",
        "idcrm": 342
      },
      {
        "nombre": "MANETA ELEVALUNAS TRASERA DERECHA_NO_USAR",
        "idcrm": 342
      },
      {
        "nombre": "MANETA ELEVALUNAS TRASERA IZQUIERDA",
        "idcrm": 343
      },
      {
        "nombre": "MANETA ELEVALUNAS TRASERA IZQUIERDA_NO_USAR",
        "idcrm": 343
      },
      {
        "nombre": "MANETA EXTERIOR  LATERAL IZQUIERDA",
        
        "Familia": 5,
        
      },
      {
        "nombre": "MANETA EXTERIOR DELANTERA DERECHA",
        "idcrm": 138
      },
      {
        "nombre": "MANETA EXTERIOR DELANTERA IZQUIERDA",
        "idcrm": 139
      },
      {
        "nombre": "MANETA EXTERIOR LATERAL DERECHA",
        "idcrm": 140
      },
      {
        "nombre": "MANETA EXTERIOR LATERAL IZQUIERDA",
        "idcrm": 141
      },
      {
        "nombre": "MANETA EXTERIOR PORTON",
        "idcrm": 170
      },
      {
        "nombre": "MANETA EXTERIOR PUERTA CORREDERA",
        
        "Familia": 5,
        
      },
      {
        "nombre": "MANETA EXTERIOR TRASERA DERECHA",
        "idcrm": 142
      },
      {
        "nombre": "MANETA EXTERIOR TRASERA IZQUIERDA",
        "idcrm": 143
      },
      {
        "nombre": "MANETA INTERIOR DELANTERA DERECHA",
        "idcrm": 344
      },
      {
        "nombre": "MANETA INTERIOR DELANTERA IZQUIERDA",
        
        "Familia": 5,
        
      },
      {
        "nombre": "MANETA INTERIOR LATERAL DERECHA",
        "idcrm": 346
      },
      {
        "nombre": "MANETA INTERIOR LATERAL IZQUIERDA",
        "idcrm": 347
      },
      {
        "nombre": "MANETA INTERIOR TRASERA DERECHA",
        "idcrm": 348
      },
      {
        "nombre": "MANETA INTERIOR TRASERA IZQUIERDA",
        "idcrm": 349
      },
      {
        "nombre": "MANGUERA RADIADOR",
        
        "Familia": 11,
        
      },
      {
        "nombre": "MANGUERAS INTERCOOLER",
        "idcrm": 403
      },
      {
        "nombre": "MANGUETA DELANTERA DERECHA",
        "idcrm": 215
      },
      {
        "nombre": "MANGUETA DELANTERA IZQUIERDA",
        "idcrm": 216
      },
      {
        "nombre": "MANGUETA TRASERA DERECHA",
        "idcrm": 217
      },
      {
        "nombre": "MANGUETA TRASERA IZQUIERDA",
        "idcrm": 218
      },
      {
        "nombre": "MANGUITO",
        
        "Familia": 11,
        
      },
      {
        "nombre": "MANGUITO INTERCOOLER",
        
        "Familia": 11,
        
      },
      {
        "nombre": "MANGUITO TURBO",
        
        "Familia": 11,
        
      },
      {
        "nombre": "MANOS LIBRES",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MANTENIMIENTO DEL VEHICULO",
        
        "Familia": 1,
        
      },
      {
        "nombre": "MARCO RADIO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "MAZA EMBRAGUE VISCOSO",
        "idcrm": 404
      },
      {
        "nombre": "MECANISMO LIMPIAPARABRISAS DELANTERO",
        
        "Familia": 1,
        
      },
      {
        "nombre": "MECANISMO RUEDA RECAMBIO",
        
        "Familia": 1,
        
      },
      {
        "nombre": "MODULO BLUETOOTH",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MODULO CONFORT",
        "idcrm": 265
      },
      {
        "nombre": "MODULO CONTROL PUERTA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MODULO DE SENSOR VELOCIDAD GIRO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MODULO ELECTRONICO",
        "idcrm": 266
      },
      {
        "nombre": "MODULO ELEVALUNAS DELANTERO DERECHO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MODULO ELEVALUNAS DELANTERO IZQUIERDO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MODULO ELEVALUNAS TRASERO DERECHO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MODULO ELEVALUNAS TRASERO IZQUIERDO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MODULO ENCENDIDO",
        "idcrm": 267
      },
      {
        "nombre": "MOLDURA",
        "idcrm": 144
      },
      {
        "nombre": "MOLDURA ALETA DELANTERA DERECHA",
        
        "Familia": 4,
        
      },
      {
        "nombre": "MOLDURA ALETA TRASERA DERECHA",
        
        "Familia": 4,
        
      },
      {
        "nombre": "MOLDURA ALETA TRASERA IZQUIERDA",
        
        "Familia": 4,
        
      },
      {
        "nombre": "MOLDURA BAJO FARO DERECHA",
        
        "Familia": 4,
        
      },
      {
        "nombre": "MOLDURA BAJO FARO IZQUIERDA",
        
        "Familia": 4,
        
      },
      {
        "nombre": "MOLDURA COSTADO DERECHO",
        
        "Familia": 5,
        
      },
      {
        "nombre": "MOLDURA COSTADO IZQUIERDO",
        
        "Familia": 5,
        
      },
      {
        "nombre": "MOLDURA INTERIOR",
        
        "Familia": 10,
        
      },
      {
        "nombre": "MOLDURA PILOTO TRASERO DERECHO",
        
        "Familia": 5,
        
      },
      {
        "nombre": "MOLDURA PILOTO TRASERO IZQUIERDO",
        
        "Familia": 5,
        
      },
      {
        "nombre": "MOLDURA PUERTA CORREDERA IZQUIERDA",
        
        "Familia": 5,
        
      },
      {
        "nombre": "MOLDURA PUERTA DELANTERA DERECHA",
        
        "Familia": 4,
        
      },
      {
        "nombre": "MOLDURA PUERTA DELANTERA IZQUIERDA",
        
        "Familia": 4,
        
      },
      {
        "nombre": "MOLDURAS DELANTERAS",
        "idcrm": 91
      },
      {
        "nombre": "MOLDURAS INTERIORES",
        
        "Familia": 10,
        
      },
      {
        "nombre": "MOLDURAS TRASERAS",
        "idcrm": 171
      },
      {
        "nombre": "MOQUETA MALETERO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "MOTOR APERTURA TRAMPILLAS CLIMATIZADOR",
        "idcrm": 268
      },
      {
        "nombre": "MOTOR ARRANQUE",
        "idcrm": 269
      },
      {
        "nombre": "MOTOR CALEFACCION",
        "idcrm": 192
      },
      {
        "nombre": "MOTOR CIERRE CENTRALIZADO",
        "idcrm": 270
      },
      {
        "nombre": "MOTOR CIERRE CENTRALIZADO DELANTERO DERECHO",
        "idcrm": 270
      },
      {
        "nombre": "MOTOR CIERRE CENTRALIZADO DELANTERO IZQUIERDO",
        "idcrm": 271
      },
      {
        "nombre": "MOTOR CIERRE CENTRALIZADO DEPOSITO",
        "idcrm": 272
      },
      {
        "nombre": "MOTOR CIERRE CENTRALIZADO PORTON",
        "idcrm": 272
      },
      {
        "nombre": "MOTOR CIERRE CENTRALIZADO TRASERO DERECHO",
        "idcrm": 273
      },
      {
        "nombre": "MOTOR CIERRE CENTRALIZADO TRASERO IZQUIERDO",
        "idcrm": 274
      },
      {
        "nombre": "MOTOR COMPLETO",
        "idcrm": 406
      },
      {
        "nombre": "MOTOR DEPOSITO LIMPIA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MOTOR DIRECCION ELECTRO MECANICA",
        
        "Familia": 8,
        
      },
      {
        "nombre": "MOTOR ELECTRICO DE AGUA",
        "idcrm": 407
      },
      {
        "nombre": "MOTOR ELEVALUNAS DELANTERO DERECHO",
        "idcrm": 275
      },
      {
        "nombre": "MOTOR ELEVALUNAS DELANTERO IZQUIERDO",
        "idcrm": 276
      },
      {
        "nombre": "MOTOR ELEVALUNAS TRASERO DERECHO",
        "idcrm": 277
      },
      {
        "nombre": "MOTOR ELEVALUNAS TRASERO IZQUIERDO",
        "idcrm": 278
      },
      {
        "nombre": "MOTOR LIMPIA DELANTERO",
        "idcrm": 279
      },
      {
        "nombre": "MOTOR LIMPIA DELANTERO DERECHO",
        "idcrm": 279
      },
      {
        "nombre": "MOTOR LIMPIA DELANTERO IZQUIERDO",
        "idcrm": 279
      },
      {
        "nombre": "MOTOR LIMPIA TRASERO",
        "idcrm": 280
      },
      {
        "nombre": "MOTOR PARA DESPIECE",
        
        "Familia": 11,
        
      },
      {
        "nombre": "MOTOR REGULACION FAROS",
        "idcrm": 281
      },
      {
        "nombre": "MOTOR REGULACION TRANSFER",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MOTOR REGULACION VENTANA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MOTOR TAPA DEPOSITO COMBUSTIBLE",
        
        "Familia": 9,
        
      },
      {
        "nombre": "MOTOR TECHO ELECTRICO",
        "idcrm": 282
      },
      {
        "nombre": "MOTOR TRAMPILLA",
        
        "Familia": 7,
        
      },
      {
        "nombre": "MUELLE AMORTIGUACION",
        "idcrm": 490
      },
      {
        "nombre": "MUELLE AMORTIGUACION DELANTERO DERECHO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "MUELLES DELANTEROS",
        
        "Familia": 12,
        
      },
      {
        "nombre": "MUELLES TRASEROS",
        
        "Familia": 12,
        
      },
      {
        "nombre": "NAVEGADOR / GPS",
        
        "Familia": 10,
        
      },
      {
        "nombre": "NEUMATICO",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO 185-55-15",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO 205-55-16",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO CONTINENTAL",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO DAYTON",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO DEBICA",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO DUNLOP",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO FEDERAL",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO FIRESTONE",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO GENERAL",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO GOODRICH",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO GOODYEAR",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO GT RADIAL",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO HANDKOOK",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO KORMORAN",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO KUMHO",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO MABOR",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO MATADOR",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO NANKANG",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO NORAUTO",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO REPUESTO",
        "idcrm": 21
      },
      {
        "nombre": "NEUMATICO VREDESTEIN",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO YOKOHAMA",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO/S",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO/S  BARUM",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO/S  BRIDGESTONE",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO/S  MICHELIN",
        "idcrm": 20
      },
      {
        "nombre": "NEUMATICO/S PIRELLI",
        "idcrm": 20
      },
      {
        "nombre": "NO IDENTIFICADO",
        
        "Familia": 13,
        
      },
      {
        "nombre": "NO USAR---PLASTICO",
        
        "Familia": 13,
        
      },
      {
        "nombre": "NUCLEO ABS",
        
        "Familia": 12,
        
      },
      {
        "nombre": "PALANCA CAMBIO",
        "idcrm": 63
      },
      {
        "nombre": "PALANCA CAMBIO COMPLETA",
        "idcrm": 63
      },
      {
        "nombre": "PALANCA CAP�",
        
        "Familia": 4,
        
      },
      {
        "nombre": "PALANCA FRENO",
        "idcrm": 491
      },
      {
        "nombre": "PALANCA FRENO DE MANO",
        "idcrm": 492
      },
      {
        "nombre": "PALIER DEL. DCHO.",
        
        "Familia": 8,
        
      },
      {
        "nombre": "PALIER DEL. IZDO.",
        
        "Familia": 8,
        
      },
      {
        "nombre": "PALIER DELANTERO DERECHA",
        
        "Familia": 8,
        
      },
      {
        "nombre": "PALIER DELANTERO IZQUIERDA",
        
        "Familia": 8,
        
      },
      {
        "nombre": "PALIER TRASERO DERECHO",
        
        "Familia": 8,
        
      },
      {
        "nombre": "PALIER TRASERO IZQUIERDO",
        
        "Familia": 8,
        
      },
      {
        "nombre": "PALIER UNIVERSAL",
        
        "Familia": 8,
        
      },
      {
        "nombre": "PANEL DELANTERO",
        
        "Familia": 8,
        
      },
      {
        "nombre": "PANEL FRONTAL",
        "idcrm": 92
      },
      {
        "nombre": "PANEL LATERAL DERECHO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "PANEL LATERAL IZQUIERDO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "PANEL PUERTA DELANTERA DERECHA",
        
        "Familia": 10,
        
      },
      {
        "nombre": "PANEL PUERTA DELANTERA IZQUIERDA",
        
        "Familia": 10,
        
      },
      {
        "nombre": "PANEL PUERTA TRASERA DERECHA",
        
        "Familia": 10,
        
      },
      {
        "nombre": "PANEL PUERTA TRASERA IZQUIERDA",
        
        "Familia": 10,
        
      },
      {
        "nombre": "PANJAR TRASERO",
        
        "Familia": 13,
        
      },
      {
        "nombre": "PANTALLA",
        
        "Familia": 10,
        
      },
      {
        "nombre": "PANTALLA MULTIFUNCION",
        "idcrm": 283
      },
      {
        "nombre": "PARABRISAS",
        "idcrm": 93
      },
      {
        "nombre": "PARAGOLPES DELANTERO",
        "idcrm": 94
      },
      {
        "nombre": "PARAGOLPES TRASERO",
        "idcrm": 172
      },
      {
        "nombre": "PARASOL DERECHO",
        "idcrm": 350
      },
      {
        "nombre": "PARASOL IZQUIERDO",
        "idcrm": 351
      },
      {
        "nombre": "PASE DE RUEDA DELANTERO DERECHO",
        
        "Familia": 4,
        
      },
      {
        "nombre": "PASE DE RUEDA DELANTERO IZQUIERDO",
        
        "Familia": 4,
        
      },
      {
        "nombre": "PASE RUEDA DELANTERO IZQUIERDO",
        
        "Familia": 4,
        
      },
      {
        "nombre": "PASO RUEDA",
        "idcrm": 145
      },
      {
        "nombre": "PASO RUEDA DELANTERO DERECHO",
        
        "Familia": 4,
        
      },
      {
        "nombre": "PASO RUEDA DELANTERO IZQUIERDO",
        
        "Familia": 4,
        
      },
      {
        "nombre": "PASO RUEDA TRASERO DERECHO",
        
        "Familia": 6,
        
      },
      {
        "nombre": "PASO RUEDA TRASERO IZQUIERDO",
        
        "Familia": 6,
        
      },
      {
        "nombre": "PASTILLAS DE FRENO",
        "idcrm": 493
      },
      {
        "nombre": "PEDAL ACELERADOR",
        "idcrm": 352
      },
      {
        "nombre": "PEDAL EMBRAGUE",
        "idcrm": 353
      },
      {
        "nombre": "PEDAL FRENO",
        "idcrm": 354
      },
      {
        "nombre": "PEGAMENTO",
        
        "Familia": 1,
        
      },
      {
        "nombre": "PEGAMENTO LUNAS",
        
        "Familia": 1,
        
      },
      {
        "nombre": "PI?A LUCES DERECHA",
        "idcrm": 284
      },
      {
        "nombre": "PI?A LUCES IZQUIERDA",
        "idcrm": 285
      },
      {
        "nombre": "PILOTO ANTINIEBLA TRASERO DERECHO",
        
        "Familia": 2,
        
      },
      {
        "nombre": "PILOTO DELANTERO DERECHO",
        "idcrm": 38
      },
      {
        "nombre": "PILOTO DELANTERO IZQUIERDO",
        "idcrm": 39
      },
      {
        "nombre": "PILOTO INTERMITENTE ALETA",
        
        "Familia": 2,
        
      },
      {
        "nombre": "PILOTO INTERMITENTE DELANTERO DERECHO",
        
        "Familia": 2,
        
      },
      {
        "nombre": "PILOTO INTERMITENTE DELANTERO IZQUIERDO",
        
        "Familia": 2,
        
      },
      {
        "nombre": "PILOTO INTERMITENTE TRASERO DERECHO",
        
        "Familia": 2,
        
      },
      {
        "nombre": "PILOTO LATERAL DERECHO",
        "idcrm": 40
      },
      {
        "nombre": "PILOTO LATERAL IZQUIERDO",
        "idcrm": 41
      },
      {
        "nombre": "PILOTO MALETERO DERECHO",
        
        "Familia": 2,
        
      },
      {
        "nombre": "PILOTO MALETERO IZQUIERDO",
        
        "Familia": 2,
        
      },
      {
        "nombre": "PILOTO MARCHA ATRAS",
        "idcrm": 42
      },
      {
        "nombre": "PILOTO MATRICULA",
        "idcrm": 43
      },
      {
        "nombre": "PILOTO PARAGOLPES TRAS DCHO",
        
        "Familia": 2,
        
      },
      {
        "nombre": "PILOTO PARAGOLPES TRAS IZDO",
        
        "Familia": 2,
        
      },
      {
        "nombre": "PILOTO PORTON DERECHO",
        
        "Familia": 2,
        
      },
      {
        "nombre": "PILOTO PORTON IZQUIERDO",
        
        "Familia": 2,
        
      },
      {
        "nombre": "PILOTO PORTON TRASERO DERECHO",
        
        "Familia": 2,
        
      },
      {
        "nombre": "PILOTO PORTON TRASERO IZQUIERDO",
        
        "Familia": 2,
        
      },
      {
        "nombre": "PILOTO TRASERO AMBOS LADOS",
        
        "Familia": 2,
        
      },
      {
        "nombre": "PILOTO TRASERO ANTINIEBLA",
        
        "Familia": 2,
        
      },
      {
        "nombre": "PILOTO TRASERO CENTRAL",
        "idcrm": 44
      },
      {
        "nombre": "PILOTO TRASERO DERECHO",
        "idcrm": 45
      },
      {
        "nombre": "PILOTO TRASERO DERECHO DE PARAGOLPES",
        "idcrm": 47
      },
      {
        "nombre": "PILOTO TRASERO DERECHO INTERIOR",
        "idcrm": 46
      },
      {
        "nombre": "PILOTO TRASERO DERECHO PARAGOLPES",
        "idcrm": 45
      },
      {
        "nombre": "PILOTO TRASERO DERECHO PORTON",
        "idcrm": 45
      },
      {
        "nombre": "PILOTO TRASERO IZQUIERDO",
        "idcrm": 48
      },
      {
        "nombre": "PILOTO TRASERO IZQUIERDO DE PARAGOLPES",
        "idcrm": 50
      },
      {
        "nombre": "PILOTO TRASERO IZQUIERDO INTERIOR",
        "idcrm": 49
      },
      {
        "nombre": "PILOTO TRASERO IZQUIERDO PARAGOLPES",
        "idcrm": 50
      },
      {
        "nombre": "PILOTO TRASERO IZQUIERDO PORTON",
        "idcrm": 50
      },
      {
        "nombre": "PILOTO TRASERO LATERAL IZQUIERDO",
        "idcrm": 41
      },
      {
        "nombre": "PILOTO TRASERO TUNING",
        
        "Familia": 2,
        
      },
      {
        "nombre": "PINZA FRENO DELANTERA DERECHA",
        "idcrm": 494
      },
      {
        "nombre": "PINZA FRENO DELANTERA IZQUIERDA",
        "idcrm": 495
      },
      {
        "nombre": "PINZA FRENO JUEGO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "PINZA FRENO TRASERA DERECHA",
        "idcrm": 496
      },
      {
        "nombre": "PINZA FRENO TRASERA IZQUIERDA",
        "idcrm": 497
      },
      {
        "nombre": "PINZAS DE ARRANQUE",
        
        "Familia": 1,
        
      },
      {
        "nombre": "PIPA TERMOSTATO",
        
        "Familia": 11,
        
      },
      {
        "nombre": "PISTOLA IMPACTO",
        
        "Familia": 1,
        
      },
      {
        "nombre": "PISTON",
        "idcrm": 408
      },
      {
        "nombre": "PLANTILLA",
        
        "Familia": 13,
        
      },
      {
        "nombre": "PLASTICO PASE RUEDA DEL. DCH.",
        "idcrm": 145
      },
      {
        "nombre": "PLASTICO PASE RUEDA DEL. IZQ.",
        "idcrm": 145
      },
      {
        "nombre": "PLASTICO PASE RUEDA TRAS. DCH.",
        "idcrm": 145
      },
      {
        "nombre": "PLASTICO PASE RUEDA TRAS. IZQ.",
        "idcrm": 145
      },
      {
        "nombre": "PLASTICO PASO RUEDA",
        "idcrm": 145
      },
      {
        "nombre": "POLEA",
        "idcrm": 409
      },
      {
        "nombre": "POLEA ALTERNADOR",
        "idcrm": 409
      },
      {
        "nombre": "POLEA ARBOL LEVAS",
        "idcrm": 410
      },
      {
        "nombre": "POLEA BOMBA INYECTORA",
        "idcrm": 411
      },
      {
        "nombre": "POLEA CIGUE?AL",
        "idcrm": 412
      },
      {
        "nombre": "POLEA CIGUE�AL",
        "idcrm": 412
      },
      {
        "nombre": "POMO PALANCA CAMBIO",
        "idcrm": 64
      },
      {
        "nombre": "PORTAEQUIPAJES",
        "idcrm": 23
      },
      {
        "nombre": "PORTALAMPARA PILOTO TRASERO DERECHO",
        
        "Familia": 2,
        
      },
      {
        "nombre": "PORTALAMPARAS",
        "idcrm": 51
      },
      {
        "nombre": "PORTAMATRICULAS",
        "idcrm": 173
      },
      {
        "nombre": "PORTATIL LUZ",
        
        "Familia": 1,
        
      },
      {
        "nombre": "PORTON TRASERO",
        "idcrm": 174
      },
      {
        "nombre": "PORTON TRASERO DERECHO",
        "idcrm": 175
      },
      {
        "nombre": "PORTON TRASERO IZQUIERDO",
        "idcrm": 176
      },
      {
        "nombre": "POSA-VASOS",
        
        "Familia": 10,
        
      },
      {
        "nombre": "POTENCIOMETRO PEDAL",
        "idcrm": 286
      },
      {
        "nombre": "PRETENSOR AIRBAG DERECHO",
        "idcrm": 355
      },
      {
        "nombre": "PRETENSOR AIRBAG IZQUIERDO",
        "idcrm": 356
      },
      {
        "nombre": "PRETENSOR AIRBAG TRASERO DERECHO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "PRETENSOR AIRBAG TRASERO IZQUIERDO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "PRETENSOR DEL DCH",
        
        "Familia": 10,
        
      },
      {
        "nombre": "PRETENSOR DEL IZQ",
        
        "Familia": 10,
        
      },
      {
        "nombre": "PRETENSOR TRASERO DERECHO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "PRETENSOR TRASERO IZQUIERDO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "PUENTE DELANTERO",
        "idcrm": 498
      },
      {
        "nombre": "PUENTE TRASERO",
        "idcrm": 499
      },
      {
        "nombre": "PUERTA DELANTERA DERECHA",
        "idcrm": 146
      },
      {
        "nombre": "PUERTA DELANTERA IZQUIERDA",
        "idcrm": 147
      },
      {
        "nombre": "PUERTA LAT CORR DERECHA",
        "idcrm": 148
      },
      {
        "nombre": "PUERTA LAT CORR IZQUIERDA",
        "idcrm": 149
      },
      {
        "nombre": "PUERTA LATERAL CORREDERA DERECHA",
        "idcrm": 148
      },
      {
        "nombre": "PUERTA LATERAL CORREDERA IZQUIERDA",
        "idcrm": 149
      },
      {
        "nombre": "PUERTA TRASERA DERECHA",
        "idcrm": 150
      },
      {
        "nombre": "PUERTA TRASERA DERECHA CARGA",
        "idcrm": 150
      },
      {
        "nombre": "PUERTA TRASERA DERECHA DE CARGA",
        "idcrm": 150
      },
      {
        "nombre": "PUERTA TRASERA IZQUIERDA",
        "idcrm": 151
      },
      {
        "nombre": "PUERTA TRASERA IZQUIERDA CARGA",
        "idcrm": 151
      },
      {
        "nombre": "PULPO DE GOMA",
        
        "Familia": 6,
        
      },
      {
        "nombre": "PULSADOR PORTON",
        
        "Familia": 6,
        
      },
      {
        "nombre": "PUNTA CHASIS DELANTERA",
        "idcrm": 95
      },
      {
        "nombre": "PUNTA DCHA PARAGOLPES TRASERO",
        
        "Familia": 6,
        
      },
      {
        "nombre": "PUNTA DE PUNTA DE CHASIS DERECHA",
        
        "Familia": 6,
        
      },
      {
        "nombre": "PUNTA DE PUNTA DE CHASIS IZQUIERDA",
        
        "Familia": 6,
        
      },
      {
        "nombre": "PUNTA IZDA PARAGOLPES TRASERO",
        "idcrm": 178
      },
      {
        "nombre": "PUNTERA PARAGOLPES TRASERA DERECHA",
        "idcrm": 177
      },
      {
        "nombre": "PUNTERA PARAGOLPES TRASERA IZQUIERDA",
        "idcrm": 178
      },
      {
        "nombre": "RADIADOR ACEITE",
        "idcrm": 414
      },
      {
        "nombre": "RADIADOR AGUA",
        "idcrm": 415
      },
      {
        "nombre": "RADIADOR AGUA ADICIONAL",
        "idcrm": 415
      },
      {
        "nombre": "RADIADOR AIRE ACONDICIONADO",
        "idcrm": 193
      },
      {
        "nombre": "RADIADOR AUXILIAR",
        
        "Familia": 11,
        
      },
      {
        "nombre": "RADIADOR CAJA CAMBIOS",
        "idcrm": 65
      },
      {
        "nombre": "RADIADOR CALEFACCION",
        "idcrm": 193
      },
      {
        "nombre": "RADIADOR CALEFACCION / AIRE ACONDICIONADO",
        "idcrm": 193
      },
      {
        "nombre": "RADIADOR COMBUSTIBLE",
        
        "Familia": 11,
        
      },
      {
        "nombre": "RADIADOR GASOIL",
        "idcrm": 416
      },
      {
        "nombre": "RADIADOR MOTOR",
        
        "Familia": 11,
        
      },
      {
        "nombre": "RADIO CD",
        "idcrm": 290
      },
      {
        "nombre": "RAMPA CARBURACION",
        
        "Familia": 11,
        
      },
      {
        "nombre": "RAMPA INYECTORA",
        "idcrm": 417
      },
      {
        "nombre": "RAMPAS DE GASOLINA",
        
        "Familia": 11,
        
      },
      {
        "nombre": "RECAMBIO POMO  CON GUARNECIDO",
        
        "Familia": 3,
        
      },
      {
        "nombre": "RECORTE",
        
        "Familia": 5,
        
      },
      {
        "nombre": "RED OFICIAL 5W30",
        
        "Familia": 28,
        
      },
      {
        "nombre": "REDUCTORA",
        "idcrm": 66
      },
      {
        "nombre": "REENVIO DIRECCION",
        
        "Familia": 8,
        
      },
      {
        "nombre": "REFLECTOR TRASERO DERECHO",
        
        "Familia": 6,
        
      },
      {
        "nombre": "REFLECTOR TRASERO IZQUIERDO",
        
        "Familia": 6,
        
      },
      {
        "nombre": "REFUERZO LATERAL",
        
        "Familia": 5,
        
      },
      {
        "nombre": "REFUERZO PARAGOLPES DELANTERO",
        "idcrm": 96
      },
      {
        "nombre": "REFUERZO PARAGOLPES SUPERIOR",
        
        "Familia": 4,
        
      },
      {
        "nombre": "REFUERZO PARAGOLPES TRASERO",
        "idcrm": 179
      },
      {
        "nombre": "REGULADOR",
        
        "Familia": 1,
        
      },
      {
        "nombre": "REGULADOR ARBOL DE LEVAS",
        
        "Familia": 11,
        
      },
      {
        "nombre": "REJILLA AIREADOR DERECHO",
        
        "Familia": 4,
        
      },
      {
        "nombre": "REJILLA AIREADOR IZQUIERDO",
        
        "Familia": 4,
        
      },
      {
        "nombre": "REJILLA AIREADORA",
        "idcrm": 357
      },
      {
        "nombre": "REJILLA ANTINIEBLA",
        
        "Familia": 4,
        
      },
      {
        "nombre": "REJILLA ANTINIEBLAS DERECHO",
        
        "Familia": 4,
        
      },
      {
        "nombre": "REJILLA ANTINIEBLAS IZQUIERDO",
        
        "Familia": 4,
        
      },
      {
        "nombre": "REJILLA CAPOT",
        
        "Familia": 4,
        
      },
      {
        "nombre": "REJILLA CLIMATIZADOR",
        
        "Familia": 4,
        
      },
      {
        "nombre": "REJILLA DELANTERA",
        "idcrm": 97
      },
      {
        "nombre": "REJILLA DELANTERA DERECHA",
        "idcrm": 97
      },
      {
        "nombre": "REJILLA DELANTERA IZQUIERDA",
        "idcrm": 97
      },
      {
        "nombre": "REJILLA PARAGOLPES CENTRAL",
        "idcrm": 98
      },
      {
        "nombre": "REJILLA PARAGOLPES DELANTERO",
        "idcrm": 99
      },
      {
        "nombre": "REJILLA PARAGOLPES DERECHA",
        "idcrm": 100
      },
      {
        "nombre": "REJILLA PARAGOLPES IZQUIERDA",
        "idcrm": 101
      },
      {
        "nombre": "RELE",
        "idcrm": 287
      },
      {
        "nombre": "RELE INYECCION",
        "idcrm": 288
      },
      {
        "nombre": "RELE VENTILADOR",
        
        "Familia": 11,
        
      },
      {
        "nombre": "RELE VENTILADORES MOTOR",
        
        "Familia": 11,
        
      },
      {
        "nombre": "RELOJ",
        
        "Familia": 11,
        
      },
      {
        "nombre": "RELOJ CUENTA KILOMETROS",
        "idcrm": 358
      },
      {
        "nombre": "RELOJ DE PRESION",
        
        "Familia": 11,
        
      },
      {
        "nombre": "RELOJ PRESION TURBO",
        
        "Familia": 11,
        
      },
      {
        "nombre": "REPARTIDOR DE FRENO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "REPOSACABEZAS",
        "idcrm": 359
      },
      {
        "nombre": "REPOSAPIES",
        
        "Familia": 10,
        
      },
      {
        "nombre": "REPOSAVASOS",
        
        "Familia": 10,
        
      },
      {
        "nombre": "RESISTENCIA CALEFACCION",
        "idcrm": 194
      },
      {
        "nombre": "RESONADOR AIRE",
        
        "Familia": 11,
        
      },
      {
        "nombre": "RETEN",
        
        "Familia": 11,
        
      },
      {
        "nombre": "RETEN CIGUE�AL",
        
        "Familia": 11,
        
      },
      {
        "nombre": "RETENEDOR DELANTERO DERECHO",
        "idcrm": 152
      },
      {
        "nombre": "RETENEDOR DELANTERO IZQUIERDO",
        "idcrm": 152
      },
      {
        "nombre": "RETENEDOR PUERTA",
        "idcrm": 152
      },
      {
        "nombre": "RETENEDOR TRASERO DERECHO",
        "idcrm": 152
      },
      {
        "nombre": "RETENEDOR TRASERO IZQUIERDO",
        "idcrm": 152
      },
      {
        "nombre": "RETENES",
        
        "Familia": 11,
        
      },
      {
        "nombre": "RETROVISOR DERECHO",
        "idcrm": 153
      },
      {
        "nombre": "RETROVISOR DERECHO ELECTRICO",
        "idcrm": 153
      },
      {
        "nombre": "RETROVISOR DERECHO MANUAL",
        "idcrm": 153
      },
      {
        "nombre": "RETROVISOR IZQUIERDO",
        "idcrm": 154
      },
      {
        "nombre": "RETROVISOR IZQUIERDO ELECTRICO",
        "idcrm": 154
      },
      {
        "nombre": "RETROVISOR IZQUIERDO MANUAL",
        "idcrm": 154
      },
      {
        "nombre": "RIGHT FRONT SHOCK ABSORBER",
        
        "Familia": 12,
        
      },
      {
        "nombre": "RIGHT FRONT SUSPENSION ARM",
        
        "Familia": 12,
        
      },
      {
        "nombre": "ROTATIVO - PUENTE LUCES",
        
        "Familia": 2,
        
      },
      {
        "nombre": "ROTULA AXIAL",
        "idcrm": 501
      },
      {
        "nombre": "ROTULA DIRECCION",
        
        "Familia": 8,
        
      },
      {
        "nombre": "ROTULA SUSPENSION",
        "idcrm": 502
      },
      {
        "nombre": "RUEDA",
        "idcrm": 24
      },
      {
        "nombre": "RUEDA DE GALLETA",
        "idcrm": 24
      },
      {
        "nombre": "SALPICADERO",
        "idcrm": 360
      },
      {
        "nombre": "SENSOR",
        "idcrm": 289,
        "Familia": 9,
        
      },
      {
        "nombre": "SENSOR ABS",
        
        "Familia": 9,
        
      },
      {
        "nombre": "SENSOR ACEITE DE CARTER VOLKWAGEN",
        
        "Familia": 9,
        
      },
      {
        "nombre": "SENSOR ACEITE MOTOR",
        
        "Familia": 9,
        
      },
      {
        "nombre": "SENSOR AIRBAG",
        
        "Familia": 9,
        
      },
      {
        "nombre": "SENSOR ANGULO GIRO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "SENSOR APARCAMIENTO DELANTERO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "SENSOR APARCAMIENTO TRASERO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "SENSOR CALIDAD AIRE",
        
        "Familia": 9,
        
      },
      {
        "nombre": "SENSOR CONTROL ESTABILIDAD",
        
        "Familia": 9,
        
      },
      {
        "nombre": "SENSOR DE APARCAMIENTO",
        "idcrm": 180
      },
      {
        "nombre": "SENSOR DE LUZ SOLAR",
        
        "Familia": 9,
        
      },
      {
        "nombre": "SENSOR DEPOSITO AGUA PSA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "SENSOR GIRO VOLANTE",
        
        "Familia": 9,
        
      },
      {
        "nombre": "SENSOR IMPACTO",
        
        "Familia": 9,
        
      },
      {
        "nombre": "SENSOR IMPACTO AIRBAG",
        
        "Familia": 9,
        
      },
      {
        "nombre": "SENSOR LLUVIA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "SENSOR PRESION",
        "idcrm": 419
      },
      {
        "nombre": "SENSOR PRESION DIFERENCIAL / PRESION GASES DE ESCAPE",
        
        "Familia": 9,
        
      },
      {
        "nombre": "SENSOR REGULACION FAROS XENON",
        
        "Familia": 9,
        
      },
      {
        "nombre": "SENSOR TEMPERATURA REFRIGERANTE",
        
        "Familia": 9,
        
      },
      {
        "nombre": "SENSOR VELOCIDAD",
        
        "Familia": 9,
        
      },
      {
        "nombre": "SENSORES PARKING DELANTEROS",
        
        "Familia": 9,
        
      },
      {
        "nombre": "SENSORES PARKING TRASEROS",
        
        "Familia": 9,
        
      },
      {
        "nombre": "SEPARADOR DE CARGA",
        
        "Familia": 1,
        
      },
      {
        "nombre": "SERVOFRENO",
        "idcrm": 503
      },
      {
        "nombre": "SERVOFRENO / MASTER DE FRENO",
        "idcrm": 503
      },
      {
        "nombre": "SILENBLOCK",
        
        "Familia": 11,
        
      },
      {
        "nombre": "SILENCIADOR TRASERO",
        "idcrm": 420
      },
      {
        "nombre": "SILENCIOSO FINAL ESCAPE",
        "idcrm": 420
      },
      {
        "nombre": "SISTEMA AUDIO / RADIO",
        "idcrm": 290
      },
      {
        "nombre": "SISTEMA AUDIO / RADIO CD",
        "idcrm": 290
      },
      {
        "nombre": "SISTEMA AUDIO / RADIOCASSET",
        "idcrm": 290
      },
      {
        "nombre": "SISTEMA DE AUDIO/ RADIO USB",
        "idcrm": 290
      },
      {
        "nombre": "SISTEMA DVD",
        "idcrm": 291
      },
      {
        "nombre": "SISTEMA NAVEGACION GPS",
        "idcrm": 292
      },
      {
        "nombre": "SOBRECARTER",
        "idcrm": 421
      },
      {
        "nombre": "SONDA LAMBDA",
        "idcrm": 293
      },
      {
        "nombre": "SOPORTE",
        
        "Familia": 1,
        
      },
      {
        "nombre": "SOPORTE / GUIA PUERTA CORREDERA",
        "idcrm": 155
      },
      {
        "nombre": "SOPORTE ALTERNADOR",
        "idcrm": 422
      },
      {
        "nombre": "SOPORTE ASIENTO TRASERO DERECHO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "SOPORTE ASIENTO TRASERO IZQUIERDO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "SOPORTE BATERIA",
        
        "Familia": 9,
        
      },
      {
        "nombre": "SOPORTE BOMBA INYECCION",
        "idcrm": 423
      },
      {
        "nombre": "SOPORTE BRAZO SUSPENSION DELANTERO DERECHO",
        "idcrm": 504
      },
      {
        "nombre": "SOPORTE CAJA",
        "idcrm": 67
      },
      {
        "nombre": "SOPORTE CAMBIO",
        "idcrm": 67
      },
      {
        "nombre": "SOPORTE DIFERENCIAL TRASERO",
        
        "Familia": 8,
        
      },
      {
        "nombre": "SOPORTE FARO DERECHO",
        "idcrm": 102
      },
      {
        "nombre": "SOPORTE FARO IZQUIERDO",
        "idcrm": 103
      },
      {
        "nombre": "SOPORTE FILTRO ACEITE",
        "idcrm": 424
      },
      {
        "nombre": "SOPORTE FILTRO GASOIL",
        "idcrm": 425
      },
      {
        "nombre": "SOPORTE MOTOR",
        "idcrm": 426
      },
      {
        "nombre": "SOPORTE MOTOR DELANTERO",
        "idcrm": 427
      },
      {
        "nombre": "SOPORTE MOTOR DERECHO",
        "idcrm": 428
      },
      {
        "nombre": "SOPORTE MOTOR IZQUIERDO",
        "idcrm": 429
      },
      {
        "nombre": "SOPORTE MOTOR TRASERO",
        "idcrm": 430
      },
      {
        "nombre": "SOPORTE PARAGOLPES",
        
        "Familia": 4,
        
      },
      {
        "nombre": "SOPORTE RADIADORES",
        
        "Familia": 11,
        
      },
      {
        "nombre": "SOPORTE RUEDA REPUESTO",
        "idcrm": 181
      },
      {
        "nombre": "SPOILER PARAGOLPES DELANTERO",
        "idcrm": 104
      },
      {
        "nombre": "SPOILER PARAGOLPES TRASERO",
        "idcrm": 182
      },
      {
        "nombre": "SPOILER-TALONERA LAT. DCHA.",
        
        "Familia": 5,
        
      },
      {
        "nombre": "SPOILER-TALONERA LAT. IZDA.",
        
        "Familia": 5,
        
      },
      {
        "nombre": "SPRAY REPARA PINCHAZOS",
        
        "Familia": 1,
        
      },
      {
        "nombre": "SUBCARTER",
        "idcrm": 183
      },
      {
        "nombre": "SUBCHASIS",
        "idcrm": 183
      },
      {
        "nombre": "SUBWOOFER ALTAVOZ",
        
        "Familia": 9,
        
      },
      {
        "nombre": "SUSTITUTIVO UNIVERSAL DE JUNTAS",
        
        "Familia": 13,
        
      },
      {
        "nombre": "TACO MOTOR DERECHO",
        
        "Familia": 11,
        
      },
      {
        "nombre": "TACO MOTOR IZQUIERDO",
        
        "Familia": 11,
        
      },
      {
        "nombre": "TALONERA DERECHA",
        
        "Familia": 5,
        
      },
      {
        "nombre": "TALONERA IZQUIERDA",
        
        "Familia": 5,
        
      },
      {
        "nombre": "TAMBOR FRENO TRASERO",
        "idcrm": 508
      },
      {
        "nombre": "TAMBOR FRENO TRASERO DERECHO",
        
        "Familia": 12
      },
      {
        "nombre": "TAMBOR FRENO TRASERO IZQUIERDO",
        
        "Familia": 12
      },
      {
        "nombre": "TAPA BALANCINES",
        "idcrm": 431
      },
      {
        "nombre": "TAPA CENTRAL GUANTERA",
        
        "Familia": 10
      },
      {
        "nombre": "TAPA DISTRIBUCION",
        "idcrm": 432
      },
      {
        "nombre": "TAPA EXTERIOR COMBUSTIBLE",
        "idcrm": 156
      },
      {
        "nombre": "TAPA FUGAS RADIADOR",
        
        "Familia": 11
      },
      {
        "nombre": "TAPA MALETERO",
        "idcrm": 184
      },
      {
        "nombre": "TAPA MOTOR",
        
        "Familia": 11
      },
      {
        "nombre": "TAPA MOTOR PLASTICO",
        
        "Familia": 11
      },
      {
        "nombre": "TAPA MOTOR SUPERIOR",
        
        "Familia": 11
      },
      {
        "nombre": "TAPA RUEDA REPUESTO",
        
        "Familia": 1
      },
      {
        "nombre": "TAPA TERMOSTATO",
        
        "Familia": 11
      },
      {
        "nombre": "TAPACUBOS",
        "idcrm": 25
      },
      {
        "nombre": "TAPACUBOS RUEDA",
        "idcrm": 25
      },
      {
        "nombre": "TAPIZADO DELANTERO DERECHO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "TAPIZADO DELANTERO IZQUIERDO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "TAPIZADO MALETERO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "TAPIZADO TRASERO DERECHO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "TAPIZADO TRASERO IZQUIERDO",
        
        "Familia": 10,
        
      },
      {
        "nombre": "TAPON COMBUSTIBLE",
        "idcrm": 433
      },
      {
        "nombre": "TAPON DE ACEITE",
        "idcrm": 434
      },
      {
        "nombre": "TAPON DEPOSITO EXPANSION",
        
        "Familia": 11,
        
      },
      {
        "nombre": "TECHO",
        "idcrm": 105
      },
      {
        "nombre": "TECHO ELECTRICO",
        "idcrm": 106
      },
      {
        "nombre": "TECHO INTERIOR",
        "idcrm": 361
      },
      {
        "nombre": "TECHO SOLAR",
        "idcrm": 106
      },
      {
        "nombre": "TECHO SOLAR ELECTRICO",
        "idcrm": 106
      },
      {
        "nombre": "TENSOR  DISTRIBUCION",
        "idcrm": 435
      },
      {
        "nombre": "TENSOR AUXILIAR",
        "idcrm": 435
      },
      {
        "nombre": "TENSOR CORREA AUXILIAR",
        "idcrm": 435
      },
      {
        "nombre": "TERMOSTATO",
        "idcrm": 436
      },
      {
        "nombre": "TIRADOR DE CAPOT",
        
        "Familia": 4,
        
      },
      {
        "nombre": "TIRANTE BARRA ESTABELIZADORA IZQUIERDO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "TIRANTE DELANTERO DERECHO",
        "idcrm": 509
      },
      {
        "nombre": "TIRANTE DELANTERO IZQUIERDO",
        "idcrm": 510
      },
      {
        "nombre": "TIRANTE INFERIOR TRASERO DERECHO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "TIRANTE INFERIOR TRASERO IZQUIERDO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "TIRANTE SUPERIOR TRASERO DERECHO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "TIRANTE SUPERIOR TRASERO IZQUIERDO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "TIRANTE TRASERO DERECHO",
        "idcrm": 511
      },
      {
        "nombre": "TIRANTE TRASERO IZQUIERDO",
        "idcrm": 512
      },
      {
        "nombre": "TOMA DE AIRE",
        
        "Familia": 11,
        
      },
      {
        "nombre": "TOMACORRIENTE 12V",
        
        "Familia": 9,
        
      },
      {
        "nombre": "TORNILLO",
        
        "Familia": 1,
        
      },
      {
        "nombre": "TORPEDO",
        "idcrm": 107
      },
      {
        "nombre": "TRANSDUCTOR DE PRESION",
        
        "Familia": 13,
        
      },
      {
        "nombre": "TRANSMISION",
        "idcrm": 219
      },
      {
        "nombre": "TRANSMISION CENTRAL",
        "idcrm": 219
      },
      {
        "nombre": "TRANSMISION CENTRAL DELANTERA",
        "idcrm": 219
      },
      {
        "nombre": "TRANSMISION DELANTERA",
        "idcrm": 220
      },
      {
        "nombre": "TRANSMISION DELANTERA DERECHA",
        "idcrm": 220
      },
      {
        "nombre": "TRANSMISION DELANTERA IZQUIERDA",
        "idcrm": 221
      },
      {
        "nombre": "TRANSMISION TRANSFER",
        "idcrm": 222
      },
      {
        "nombre": "TRANSMISION TRASERA DERECHA",
        "idcrm": 222
      },
      {
        "nombre": "TRANSMISION TRASERA IZQUIERDA",
        "idcrm": 223
      },
      {
        "nombre": "TRAPECIO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "TRATAMIENTO DPF",
        
        "Familia": 13,
        
      },
      {
        "nombre": "TRAVESA?O INFERIOR",
        "idcrm": 108
      },
      {
        "nombre": "TRAVESA?O SUPERIOR",
        "idcrm": 109
      },
      {
        "nombre": "TRAVESA�O INFERIOR",
        "idcrm": 108
      },
      {
        "nombre": "TRAVESA�O SUPERIOR",
        "idcrm": 109
      },
      {
        "nombre": "TRAVIESA",
        
        "Familia": 13,
        
      },
      {
        "nombre": "TUBERIA AIRE ACONDICIONADO",
        
        "Familia": 7,
        
      },
      {
        "nombre": "TUBERIA DE DIRECCION",
        
        "Familia": 8,
        
      },
      {
        "nombre": "TUBERIA INTERCOOLER",
        
        "Familia": 11,
        
      },
      {
        "nombre": "TUBERIAS",
        "idcrm": 437
      },
      {
        "nombre": "TUBERIAS HIDRAULICO",
        
        "Familia": 12,
        
      },
      {
        "nombre": "TUBO",
        "idcrm": 437
      },
      {
        "nombre": "TUBO AIRE ACONDICIONADO",
        
        "Familia": 7,
        
      },
      {
        "nombre": "TUBO ESCAPE CENTRAL",
        "idcrm": 438
      },
      {
        "nombre": "TUBO ESCAPE COMPLETO",
        "idcrm": 439
      },
      {
        "nombre": "TUBO ESCAPE DELANTERO",
        "idcrm": 440
      },
      {
        "nombre": "TUBO ESCAPE FLEXIBLE",
        
        "Familia": 11,
        
      },
      {
        "nombre": "TUBO ESCAPE TRASERO",
        "idcrm": 441
      },
      {
        "nombre": "TUBO FILTRO DE AIRE",
        
        "Familia": 11,
        
      },
      {
        "nombre": "TUBO PRESION DIRECCION ASISTIDA",
        "idcrm": 224
      },
      {
        "nombre": "TUBO PRESION TURBOCOMPRESOR",
        "idcrm": 442
      },
      {
        "nombre": "TUBOS AIRE ACONDICIONADO",
        "idcrm": 195
      },
      {
        "nombre": "TURBO INTERCAMBIO",
        "idcrm": 443
      },
      {
        "nombre": "TURBO RECONSTRUIDO",
        "idcrm": 443
      },
      {
        "nombre": "TURBOCOMPRESOR",
        "idcrm": 443
      },
      {
        "nombre": "UNIDAD BLUETHOOTH",
        
        "Familia": 9,
        
      },
      {
        "nombre": "UTIL PUERTA CORREDERA",
        
        "Familia": 1,
        
      },
      {
        "nombre": "UTIL RUEDA REPUESTO",
        
        "Familia": 1,
        
      },
      {
        "nombre": "VALVULA",
        "idcrm": 11
      },
      {
        "nombre": "VALVULA AIRE ACONDICIONADO",
        "idcrm": 11
      },
      {
        "nombre": "VALVULA AIRE ADICIONAL",
        "idcrm": 444
      },
      {
        "nombre": "VALVULA ANTIRRETORNO UNIVERSAL",
        "idcrm": 11
      },
      {
        "nombre": "VALVULA AVANCE",
        "idcrm": 11
      },
      {
        "nombre": "VALVULA EGR",
        "idcrm": 445
      },
      {
        "nombre": "VALVULA ESCAPE",
        "idcrm": 11
      },
      {
        "nombre": "VALVULA EXPANSION",
        "idcrm": 196
      },
      {
        "nombre": "VALVULA INTERCAMBIO DE CALEFACCION",
        "idcrm": 197
      },
      {
        "nombre": "VALVULA PRESION / ACTUADOR PRESION",
        "idcrm": 11
      },
      {
        "nombre": "VALVULA PRESION/ACTUADOR DE TURBO",
        "idcrm": 11
      },
      {
        "nombre": "VALVULA RALENTI",
        "idcrm": 446
      },
      {
        "nombre": "VALVULA TURBO",
        "idcrm": 11
      },
      {
        "nombre": "VALVULA VACIO",
        "idcrm": 11
      },
      {
        "nombre": "VALVULA VACIO TURBO",
        "idcrm": 11
      },
      {
        "nombre": "VALVULINA CAJA CAMBIOS",
        "idcrm": 28
      },
      {
        "nombre": "VARILLA ACEITE",
        "idcrm": 447
      },
      {
        "nombre": "VARILLAJE CAMBIO",
        "idcrm": 68
      },
      {
        "nombre": "VARIOS",
        
        "Familia": 13,
        
      },
      {
        "nombre": "VASO EXPANSION",
        "idcrm": 196
      },
      {
        "nombre": "VENTILADOR CALEFACCION",
        "idcrm": 198
      },
      {
        "nombre": "VENTILADOR CALEFACCION / MOTOR CALEFACCION",
        "idcrm": 198
      },
      {
        "nombre": "VENTILADOR VISCOSO MOTOR",
        "idcrm": 448
      },
      {
        "nombre": "VESTUARIO",
        
        "Familia": 13,
        
      },
      {
        "nombre": "VISCOSO DE VENTILADOR",
        
        "Familia": 9,
        
      },
      {
        "nombre": "VOLANTE",
        "idcrm": 362
      },
      {
        "nombre": "VOLANTE BIMASA",
        "idcrm": 449
      },
      {
        "nombre": "VOLANTE MOTOR",
        "idcrm": 449
      },
      {
        "nombre": "VOLANTE MOTOR / BIMASA",
        "idcrm": 449
      },
      {
        "nombre": "WARNING",
        "idcrm": 294
      },
      {
        "nombre": "ZAPATAS",
        
        "Familia": 12,
        
      },
      {
        "nombre": "ZAPATAS DE FRENO TRASERAS",
        
        "Familia": 12,
        
      },
        {
          "nombre": "DI?M. EXT.: 78 MM, DI?M. INT.: 12 MM, ALTURA: 136 MM",
          "idcrm": 15,
          
        },
        {
          "nombre": "20789 LQM TOP TEC 6200 0W20 5 L",
          "idcrm": 15,
          
        },
        {
          "nombre": "20790 PRO-LINE DIESELFILTERADD 500 M",
          "idcrm": 15,
          
        },
        {
          "nombre": "21411 LQM ACEITE TOP TEC 6600 0W20 5 L",
          "idcrm": 15,
          
        },
        {
          "nombre": "3648 ACEITE DE TRANSMISION TOP TEC",
          "idcrm": 15,
          
        },
        {
          "nombre": "3687 LQM TOP TEC ATF 1800 1 L",
          "idcrm": 15,
          
        },
        {
          "nombre": "ABRAZADERA PORTAFILTRO GASOIL",
          "idcrm": 425,
          
        },
        {
          "nombre": "ABS SOLENOIDE",
          "idcrm": 450,
          
        },
        {
          "nombre": "ABSORBEDOR TRASERO",
          "idcrm": 157,
          
        },
        {
          "nombre": "ACCIONADOR DE COLECTOR ADMISION",
          "idcrm": 379,
          
        },
        {
          "nombre": "ACCIONADOR SELECTOR VELOCIDAD",
          "idcrm": 1386,
          
        },
        {
          "nombre": "ACCIONADOR TAPA TURBULENCIA",
          "idcrm": 379,
          
        },
        {
          "nombre": "ACCIONAMIENTO CAMBIO",
          "idcrm": 63,
          
        },
        {
          "nombre": "ACEITE 10W-40",
          "idcrm": 28,
          
        },
        {
          "nombre": "ACEITE 5W 40 FORMULA PD MANNOL",
          "idcrm": 28,
          
        },
        {
          "nombre": "ACEITE 5W-30",
          "idcrm": 28,
          
        },
        {
          "nombre": "ACEITE 5W-40",
          "idcrm": 28,
          
        },
        {
          "nombre": "ACEITE MOTOR CASTROL 10W40",
          "idcrm": 28,
          
        },
        {
          "nombre": "ACEITE MOTOR CASTROL 10W40.SINTETICO",
          "idcrm": 28,
          
        },
        {
          "nombre": "ACEITE MOTOR CASTROL 15W40 SINTETICO",
          "idcrm": 28,
          
        },
        {
          "nombre": "ACEITE MOTOR CASTROL EDGE FTS 5W30 SINTETICO.",
          "idcrm": 28,
          
        },
        {
          "nombre": "ACEITE MULT",
          "idcrm": 28,
          
        },
        {
          "nombre": "ACEITERA INDUSTRIAL",
          "idcrm": 28,
          
        },
        {
          "nombre": "ACTUADOR AIRE",
          "idcrm": 1230,
          
        },
        {
          "nombre": "ACTUADOR CAJA TRANSFER",
          "idcrm": 1436,
          
        },
        {
          "nombre": "ACTUADOR CILINDRO RECEPTOR EMBRAGUE",
          "idcrm": 54,
          
        },
        {
          "nombre": "ACTUADOR DEL TURBOCOMPRESOR",
          "idcrm": 443,
          
        },
        {
          "nombre": "ACTUADOR PALOMILLA ESCAPE",
          "idcrm": 380,
          
        },
        {
          "nombre": "ACTUADOR POSTERIOR DERECHO",
          
          "Familia": 9
        },
        {
          "nombre": "ACTUADOR POSTERIOR IZQUIERDO",
          
          "Familia": 9
        },
        {
          "nombre": "ACTUADOR TAPA COMBUSTIBLE",
          "idcrm": 1438,
          
        },
        {
          "nombre": "ACUMULADO0R PRESION SITEMA DE SUSPENSION",
          "idcrm": 482,
          
        },
        {
          "nombre": "ACUMULADOR AMORTIGUACION",
          "idcrm": 482,
          
        },
        {
          "nombre": "ACUMULADOR DE AIRE",
          
          "Familia": 11
        },
        {
          "nombre": "ACUMULADOR DE PRESION",
          
          "Familia": 11
        },
        {
          "nombre": "ACUMULADOR DE VACIO",
          
          "Familia": 11
        },
        {
          "nombre": "ACUMULADOR SUSPENSION",
          "idcrm": 482,
          
        },
        {
          "nombre": "ACUMULADOR SUSPENSION NEUMATICA",
          "idcrm": 482,
          
        },
        {
          "nombre": "ADAPTADOR",
          
          "Familia": 9
        },
        {
          "nombre": "ADAPTADOR MAGNETICO",
          
          "Familia": 9
        },
        {
          "nombre": "ADAPTADOR MAURER",
          
          "Familia": 9
        },
        {
          "nombre": "ADAPTADOR SALPICADERO 52 MM",
          
          "Familia": 9
        },
        {
          "nombre": "ADDTOBLUE",
          "idcrm": 514,
          
        },
        {
          "nombre": "ADITIVO Y SELLANTE ATF.CAMBIOS AUTOMATICOS",
          "idcrm": 1072,
          
        },
        {
          "nombre": "AFLOJALOTODO",
          "idcrm": 1074,
          
        },
        {
          "nombre": "AFORADOR BOMBA",
          "idcrm": 363,
          
        },
        {
          "nombre": "AGARRADOR TECHO",
          "idcrm": 1076,
          
        },
        {
          "nombre": "AIRBAG",
          
          "Familia": 10
        },
        {
          "nombre": "AIRBAG ACOMPA�ANTE",
          "idcrm": 1078,
          
        },
        {
          "nombre": "AIRBAG CENTRAL",
          
          "Familia": 10
        },
        {
          "nombre": "AIRBAG DE PEATON",
          
          "Familia": 10
        },
        {
          "nombre": "AIRBAG DE RODILLA DELANTERO IZQUIERDO",
          "idcrm": 1080,
          
        },
        {
          "nombre": "AIRBAG DELANTERO",
          "idcrm": 301,
          
        },
        {
          "nombre": "AIRBAG DELANTERO IZQUIERDO INFERIOR",
          "idcrm": 1081,
          
        },
        {
          "nombre": "AIRBAG INFERIOR DCH. (PIERNAS)",
          "idcrm": 1080,
          
        },
        {
          "nombre": "AIRBAG INFERIOR SALPICADERO",
          "idcrm": 1080,
          
        },
        {
          "nombre": "AIRBAG SALPICADERO",
          
          "Familia": 10
        },
        {
          "nombre": "AIRBAG VOLANTE",
          "idcrm": 301,
          
        },
        {
          "nombre": "AIRBAIG DELANTERO IZQUIERDO DE PIES",
          "idcrm": 1080,
          
        },
        {
          "nombre": "AIREADOR CALEFACCION",
          "idcrm": 1082,
          
        },
        {
          "nombre": "AIREADOR CALEFACCI�N CENTRAL",
          "idcrm": 357,
          
        },
        {
          "nombre": "AIREADOR CALEFACCI�N DERECHO",
          "idcrm": 1522,
          
        },
        {
          "nombre": "AIREADOR CALEFACCI�N IZQUIERDO",
          "idcrm": 1523,
          
        },
        {
          "nombre": "AIREADOR CENTRAL",
          "idcrm": 357,
          
        },
        {
          "nombre": "AIREADOR DERECHO",
          "idcrm": 1522,
          
        },
        {
          "nombre": "AIREADOR IZQUIERDO",
          "idcrm": 1523,
          
        },
        {
          "nombre": "AIREADOR SALPICADERO IZQUIERDO",
          "idcrm": 1526,
          
        },
        {
          "nombre": "AIREADOR TRASERO",
          "idcrm": 1082,
          
        },
        {
          "nombre": "AIREADOR TRASERO CENTRAL",
          "idcrm": 1082,
          
        },
        {
          "nombre": "AJUSTADOR CINTURON DER.",
          "idcrm": 355,
          
        },
        {
          "nombre": "ALAMBRE PLASTIFICADO",
          "idcrm": 15,
          
        },
        {
          "nombre": "ALARGADERA MAURER",
          "idcrm": 15,
          
        },
        {
          "nombre": "ALARMA",
          
          "Familia": 9
        },
        {
          "nombre": "ALARMA ELECTRONICA",
          "idcrm": 1193,
          
        },
        {
          "nombre": "ALCACHOFA DE ACEITE MOTOR",
          "idcrm": 437,
          
        },
        {
          "nombre": "ALCOTANA 814/",
          "idcrm": 15,
          
        },
        {
          "nombre": "ALETA DELANTERO DERECHA",
          "idcrm": 70,
          
        },
        {
          "nombre": "ALETA DELANTERO IZQUIERDA",
          "idcrm": 71,
          
        },
        {
          "nombre": "ALETA TRASERA IZQUIERDA",
          
          
        },
        {
          "nombre": "ALETA TRASERO DERECHA",
          "idcrm": 1083,
          
        },
        {
          "nombre": "ALETA TRASERO IZQUIERDA",
          
          "Familia": 6
        },
        {
          "nombre": "ALFOMBRA GOMA NEGRA 4 PIEZAS",
          "idcrm": 1343,
          
        },
        {
          "nombre": "ALFOMBRA INTERIOR",
          "idcrm": 1346,
          
        },
        {
          "nombre": "ALFOMBRILLAS",
          "idcrm": 1374,
          
        },
        {
          "nombre": "ALICATES MAURER",
          "idcrm": 15,
          
        },
        {
          "nombre": "ALICATES MINI",
          "idcrm": 15,
          
        },
        {
          "nombre": "ALMOHADILLA INTERIOR",
          "idcrm": 15,
          
        },
        {
          "nombre": "ALOJAMIENTO DE FARO",
          "idcrm": 102,
          
        },
        {
          "nombre": "ALTAVOCES",
          "idcrm": 1084,
          
        },
        {
          "nombre": "ALTAVOZ APROXIMACION APARCAMIENTO",
          "idcrm": 1084,
          
        },
        {
          "nombre": "ALTAVOZ PUERTA DELANTERO DERECHA",
          "idcrm": 1084,
          
        },
        {
          "nombre": "ALTAVOZ PUERTA DELANTERO IZQUIERDA",
          "idcrm": 1084,
          
        },
        {
          "nombre": "AMORTIGUADOR",
          "idcrm": 452,
          
        },
        {
          "nombre": "AMORTIGUADOR BOMBA INYECCTORA",
          "idcrm": 371,
          
        },
        {
          "nombre": "AMORTIGUADOR DE IMPACTO",
          
          "Familia": 4
        },
        {
          "nombre": "AMORTIGUADOR DELANTERO COMPLETO",
          "idcrm": 452,
          
        },
        {
          "nombre": "AMORTIGUADOR DERECHO CAPO",
          "idcrm": 74,
          
        },
        {
          "nombre": "AMORTIGUADOR NEUMATICO CABINA DELANTERO",
          "idcrm": 74,
          
        },
        {
          "nombre": "AMORTIGUADOR NEUMATICO CABINA TRASERO",
          "idcrm": 74,
          
        },
        {
          "nombre": "AMORTIGUADOR PORTON",
          "idcrm": 74,
          
        },
        {
          "nombre": "AMORTIGUADOR PORTON TRASERO DERECHO",
          "idcrm": 74,
          
        },
        {
          "nombre": "AMORTIGUADOR PORTON TRASERO IZQUIERDO",
          "idcrm": 74,
          
        },
        {
          "nombre": "AMORTIGUADOR PUERTA",
          "idcrm": 74,
          
        },
        {
          "nombre": "AMORTIGUADOR PUERTA TRAS DCHA",
          "idcrm": 74,
          
        },
        {
          "nombre": "AMORTIGUADOR PUERTA TRAS IZDA",
          "idcrm": 74,
          
        },
        {
          "nombre": "AMORTIGUADOR TAPA MALETERO",
          "idcrm": 74,
          
        },
        {
          "nombre": "AMORTIGUADOR TRASERO COMPLETO",
          "idcrm": 455,
          
        },
        {
          "nombre": "AMORTIGUADORES IMPACTO TRASEROS",
          "idcrm": 455,
          
        },
        {
          "nombre": "AMORTIGUADORES PORTON TRASERO",
          "idcrm": 74,
          
        },
        {
          "nombre": "AMPLIFICADOR AUDIO",
          "idcrm": 1333,
          
        },
        {
          "nombre": "AMPLIFICADOR DE ANTENA",
          "idcrm": 2,
          
        },
        {
          "nombre": "AMPLIFICADOR DE RADIO",
          "idcrm": 2,
          
        },
        {
          "nombre": "AMPLIFICADOR RADIO",
          "idcrm": 2,
          
        },
        {
          "nombre": "AMPLIFICADOR SIRENA",
          
          "Familia": 9
        },
        {
          "nombre": "AMPLIVERSAL",
          
          "Familia": 9
        },
        {
          "nombre": "ANAGRAMA DELANTERO",
          "idcrm": 1089,
          
        },
        {
          "nombre": "ANCLAJE CINTURON 3� FILA CENTRAL",
          "idcrm": 1090,
          
        },
        {
          "nombre": "ANCLAJE CINTURON DELANTERO CENTRAL",
          "idcrm": 1090,
          
        },
        {
          "nombre": "ANCLAJE CINTURON TR TR DER",
          "idcrm": 1090,
          
        },
        {
          "nombre": "ANCLAJE CINTURON TR TR IZQ",
          "idcrm": 1090,
          
        },
        {
          "nombre": "ANCLAJE CINTURON TRASERO",
          "idcrm": 1090,
          
        },
        {
          "nombre": "ANCLAJE CINTUROTRASERO CENTRAL",
          "idcrm": 1090,
          
        },
        {
          "nombre": "ANCLAJE DE CINTURON DELANTERO IZQ.",
          "idcrm": 1090,
          
        },
        {
          "nombre": "ANCLAJE DE CINTURON TRASERO CENTRAL",
          "idcrm": 1090,
          
        },
        {
          "nombre": "ANCLAJE DE PARASOL",
          "idcrm": 350,
          
        },
        {
          "nombre": "ANGULO PARAGOLPES DERECHO",
          "idcrm": 1505,
          
        },
        {
          "nombre": "ANILLO CONTACTO VOLANTE",
          "idcrm": 226,
          
        },
        {
          "nombre": "ANTENA ELECTRICA",
          "idcrm": 2,
          
        },
        {
          "nombre": "ANTENA INMOVILISADOR",
          "idcrm": 2,
          
        },
        {
          "nombre": "ANTENA INMOVILIZADOR",
          "idcrm": 2,
          
        },
        {
          "nombre": "ANTICONGELANTE 5L 10% ROSA TEQUINA",
          "idcrm": 1077,
          
        },
        {
          "nombre": "ANTICONGELANTE 5L 10% VERDE TEQUINA",
          "idcrm": 1077,
          
        },
        {
          "nombre": "ANTIDESGASTE Y ANTIFUGA DIRECCION",
          "idcrm": 1072,
          
        },
        {
          "nombre": "ANTIHUMO ITV DIESEL 200 ML",
          "idcrm": 1072,
          
        },
        {
          "nombre": "ANTIHUMO ITV GASOLINA 200 ML",
          "idcrm": 1072,
          
        },
        {
          "nombre": "ARA?A",
          "idcrm": 75,
          
        },
        {
          "nombre": "ARBOL DE LEVAS ADMISION",
          "idcrm": 364,
          
        },
        {
          "nombre": "ARBOL DE LEVAS ESCAPE",
          "idcrm": 364,
          
        },
        {
          "nombre": "ARCO SIERRA",
          "idcrm": 15,
          
        },
        {
          "nombre": "ARTICULACION AXIAL",
          "idcrm": 501,
          
        },
        {
          "nombre": "ARTICULACION DE PUERTA LATERAL CORREDERA",
          "idcrm": 15,
          
        },
        {
          "nombre": "ARTICULACION LIMPIA DELANTERO",
          "idcrm": 76,
          
        },
        {
          "nombre": "ARTICULACION UNIVERSAL",
          "idcrm": 15,
          
        },
        {
          "nombre": "ASIDERO DE PUETA TRASERA IZQUIERDA",
          "idcrm": 1092,
          
        },
        {
          "nombre": "ASIDERO PUERTA DELANTERA DERECHA",
          "idcrm": 1092,
          
        },
        {
          "nombre": "ASIDERO PUERTA DELANTERA IZQUIERDA",
          "idcrm": 1092,
          
        },
        {
          "nombre": "ASIDERO PUERTA DELANTERO DERECHA",
          "idcrm": 1092,
          
        },
        {
          "nombre": "ASIDERO PUERTA DELANTERO IZQUIERDA",
          "idcrm": 1092,
          
        },
        {
          "nombre": "ASIDERO SUPERIOR DELANTERO DERECHO",
          "idcrm": 1092,
          
        },
        {
          "nombre": "ASIDERO TECHO DEL DCHO",
          "idcrm": 1092,
          
        },
        {
          "nombre": "ASIDERO TECHO DEL IZDO",
          "idcrm": 1092,
          
        },
        {
          "nombre": "ASIDERO TECHO TRAS DCHO",
          "idcrm": 1092,
          
        },
        {
          "nombre": "ASIDERO TECHO TRAS IZDO",
          "idcrm": 1092,
          
        },
        {
          "nombre": "ASIENTOS",
          "idcrm": 309,
          
        },
        {
          "nombre": "ASPAS CENTRIFUGO",
          "idcrm": 253,
          
        },
        {
          "nombre": "ASPAS VENTILADOR",
          "idcrm": 253,
          
        },
        {
          "nombre": "ASPAS VENTILADOR RADIADOR",
          "idcrm": 253,
          
        },
        {
          "nombre": "AUTO ALARMA",
          "idcrm": 1193,
          
        },
        {
          "nombre": "BALIZA DE EMERGENCIA LED 53721",
          
          "Familia": 9
        },
        {
          "nombre": "BALLESTA DELANTERO",
          "idcrm": 458,
          
        },
        {
          "nombre": "BALLESTA TRASERO",
          "idcrm": 459,
          
        },
        {
          "nombre": "BALLESTA TRASERO DERECHA",
          "idcrm": 459,
          
        },
        {
          "nombre": "BALLESTA TRASERO IZQUIERDA",
          "idcrm": 459,
          
        },
        {
          "nombre": "BALONA DERECHA SUSPENSION",
          "idcrm": 452,
          
        },
        {
          "nombre": "BALONA IZQUIERDA SUSPENSION",
          "idcrm": 452,
          
        },
        {
          "nombre": "BALONA O COJIN SUSPENSION NEUMATICA TRASERO DERECHO",
          "idcrm": 452,
          
        },
        {
          "nombre": "BANDEJA CARTER ACEITE",
          "idcrm": 375,
          
        },
        {
          "nombre": "BANDEJA POSAVASOS CENTRAL",
          "idcrm": 1536,
          
        },
        {
          "nombre": "BANDEJA TRASERO",
          "idcrm": 317,
          
        },
        {
          "nombre": "BARILLA ACEITE",
          "idcrm": 447,
          
        },
        {
          "nombre": "BARRA ANTIVUELCO",
          "idcrm": 318,
          
        },
        {
          "nombre": "BARRA ESTABILIZADORA",
          "idcrm": 460,
          
        },
        {
          "nombre": "BARRA ESTABILIZADORA DELANTERO",
          "idcrm": 460,
          
        },
        {
          "nombre": "BARRA ESTABILIZADORA TRASERO",
          "idcrm": 461,
          
        },
        {
          "nombre": "BARRA PALANCA CAMBIO",
          "idcrm": 63,
          
        },
        {
          "nombre": "BARRA PANHARD",
          "idcrm": 15,
          
        },
        {
          "nombre": "BARRA SUSPENSION",
          "idcrm": 460,
          
        },
        {
          "nombre": "BARRA TECHO UNIVERSAL",
          "idcrm": 1094,
          
        },
        {
          "nombre": "BARRA TORSION DELANTERA DERECHA",
          "idcrm": 462,
          
        },
        {
          "nombre": "BARRA TORSION DELANTERA IZQUIERDA",
          "idcrm": 462,
          
        },
        {
          "nombre": "BARRAS ANTIVUELCO",
          "idcrm": 4,
          
        },
        {
          "nombre": "BARRAS PORTA EQUIPAJE",
          "idcrm": 4,
          
        },
        {
          "nombre": "BARRAS VACA",
          "idcrm": 4,
          
        },
        {
          "nombre": "BASCULANTE",
          
          "Familia": 12
        },
        {
          "nombre": "BASE SOPORTE MOTOR",
          "idcrm": 426,
          
        },
        {
          "nombre": "BATERIA <5KWH (HEV)",
          "idcrm": 6,
          
        },
        {
          "nombre": "BATERIA ATEHI 45 AMPERIOS",
          "idcrm": 6,
          
        },
        {
          "nombre": "BATERIA ATEHI 90 AMP",
          "idcrm": 6,
          
        },
        {
          "nombre": "BATERIA BAJA",
          "idcrm": 6,
          
        },
        {
          "nombre": "BATERIA BAJA 75A 640AH",
          "idcrm": 6,
          
        },
        {
          "nombre": "BATERIA ELECTRICA 5KWH20KWH (PHEV)",
          "idcrm": 6,
          
        },
        {
          "nombre": "BATERIA HIBRIDA",
          "idcrm": 6,
          
        },
        {
          "nombre": "BATERIA HIBRIDO",
          "idcrm": 6,
          
        },
        {
          "nombre": "BATERIA MOTO",
          "idcrm": 6,
          
        },
        {
          "nombre": "BATERIA NUEVA 60 AMP",
          "idcrm": 6,
          
        },
        {
          "nombre": "BATERIA NUEVA 70 AMP",
          "idcrm": 6,
          
        },
        {
          "nombre": "BATERIA NUEVA 75 AMP",
          "idcrm": 6,
          
        },
        {
          "nombre": "BATERIA NUEVA 90 AMP",
          "idcrm": 6,
          
        },
        {
          "nombre": "BATERIA TOP ENERGY 45 AMP",
          "idcrm": 6,
          
        },
        {
          "nombre": "BATERIA TOP ENERGY 70 AMP DCHO",
          "idcrm": 6,
          
        },
        {
          "nombre": "BATERIA TOP ENERGY 70 AMP IZDO",
          "idcrm": 6,
          
        },
        {
          "nombre": "BATERIA TOP ENERGY 95 AMP",
          "idcrm": 6,
          
        },
        {
          "nombre": "BATERIA USADA 45",
          "idcrm": 6,
          
        },
        {
          "nombre": "BATERIA USADA 60/55 A",
          "idcrm": 6,
          
        },
        {
          "nombre": "BATERIA USADA 70/75 A",
          "idcrm": 6,
          
        },
        {
          "nombre": "BATERIA VIPIEM-ME 45 AMP",
          "idcrm": 6,
          
        },
        {
          "nombre": "BATERIA VIPIEM-ME 60 AMP",
          "idcrm": 6,
          
        },
        {
          "nombre": "BATERIA VIPIEM-ME 75 AMP",
          "idcrm": 6,
          
        },
        {
          "nombre": "BATERIA VIPIEM-ME 95 AMP",
          "idcrm": 6,
          
        },
        {
          "nombre": "BCM",
          
          "Familia": 9
        },
        {
          "nombre": "BENDIX ARRANQUE",
          "idcrm": 269,
          
        },
        {
          "nombre": "BIDON CARBURANTE 10 LTS. METALICO",
          "idcrm": 15,
          
        },
        {
          "nombre": "BIDON GASOLINA DOBLE",
          "idcrm": 15,
          
        },
        {
          "nombre": "BIELETA BRAZO SUSPENSION TRASERO DERECHA",
          "idcrm": 465,
          
        },
        {
          "nombre": "BIELETA CAMBIO",
          "idcrm": 63,
          
        },
        {
          "nombre": "BIELETA DERECHA DIRECCION",
          "idcrm": 463,
          
        },
        {
          "nombre": "BIELETA DERECHA ESTABILIZADORA DELANTERO",
          "idcrm": 1096,
          
        },
        {
          "nombre": "BIELETA IZQUIERDA DIRECCION",
          "idcrm": 1544,
          
        },
        {
          "nombre": "BIELETA IZQUIERDA ESTABILIZADORA DELANTERO",
          "idcrm": 464,
          
        },
        {
          "nombre": "BIELETA LATERAL",
          "idcrm": 1096,
          
        },
        {
          "nombre": "BIMASA CAMBIO AUTOMATICO",
          "idcrm": 449,
          
        },
        {
          "nombre": "BISAGRA",
          
          "Familia": 5
        },
        {
          "nombre": "BISAGRA CAPO DERECHA",
          "idcrm": 1098,
          
        },
        {
          "nombre": "BISAGRA CAPO IZQUIERDA",
          "idcrm": 1098,
          
        },
        {
          "nombre": "BISAGRA CAPOT DERECHA",
          "idcrm": 1098,
          
        },
        {
          "nombre": "BISAGRA CAPOT IZQUIERDA",
          "idcrm": 1098,
          
        },
        {
          "nombre": "BISAGRA DELANTERA DERECHA CAPO*",
          "idcrm": 1098,
          
        },
        {
          "nombre": "BISAGRA DELANTERA DERECHA SUPERIOR",
          "idcrm": 1098,
          
        },
        {
          "nombre": "BISAGRA DELANTERA IZQUIERDA INFERIOR",
          "idcrm": 1098,
          
        },
        {
          "nombre": "BISAGRA DELANTERA IZQUIERDA SUPERIOR",
          "idcrm": 1098,
          
        },
        {
          "nombre": "BISAGRA DERECHA PORTON TRASERO",
          "idcrm": 1101,
          
        },
        {
          "nombre": "BISAGRA DERECHA TAPA  MALETA",
          "idcrm": 1101,
          
        },
        {
          "nombre": "BISAGRA INF. PUERTA DELANTERO DERECHA",
          "idcrm": 1102,
          
        },
        {
          "nombre": "BISAGRA INF. PUERTA DELANTERO IZQUIERDA",
          "idcrm": 1102,
          
        },
        {
          "nombre": "BISAGRA INF. PUERTA TRASERO DERECHA",
          "idcrm": 1102,
          
        },
        {
          "nombre": "BISAGRA INF. PUERTA TRASERO IZQUIERDA",
          "idcrm": 1102,
          
        },
        {
          "nombre": "BISAGRA INFERIOR DERECHA",
          "idcrm": 1102,
          
        },
        {
          "nombre": "BISAGRA INFERIOR IZQUIERDA",
          "idcrm": 1102,
          
        },
        {
          "nombre": "BISAGRA IZQUIERDA PORTON",
          "idcrm": 1101,
          
        },
        {
          "nombre": "BISAGRA IZQUIERDA PORTON TRASERO",
          "idcrm": 1101,
          
        },
        {
          "nombre": "BISAGRA IZQUIERDA TAPA MALETA",
          "idcrm": 1101,
          
        },
        {
          "nombre": "BISAGRA PUERTA DELANTERA DERECHA",
          "idcrm": 1102,
          
        },
        {
          "nombre": "BISAGRA PUERTA DELANTERA IZQUIERDA",
          "idcrm": 1102,
          
        },
        {
          "nombre": "BISAGRA PUERTA TRASERA DERECHA",
          "idcrm": 1102,
          
        },
        {
          "nombre": "BISAGRA SUP. PUERTA DELANTERO DERECHA",
          "idcrm": 1102,
          
        },
        {
          "nombre": "BISAGRA SUP. PUERTA DELANTERO IZQUIERDA",
          "idcrm": 1102,
          
        },
        {
          "nombre": "BISAGRA SUP. PUERTA TRASERO DERECHA",
          "idcrm": 1102,
          
        },
        {
          "nombre": "BISAGRA SUP. PUERTA TRASERO IZQUIERDA",
          "idcrm": 1102,
          
        },
        {
          "nombre": "BISAGRA SUPERIOR DERECHA",
          "idcrm": 1102,
          
        },
        {
          "nombre": "BISAGRA SUPERIOR IZQUIERDA",
          "idcrm": 1102,
          
        },
        {
          "nombre": "BISAGRA TRASERA DERECHA",
          "idcrm": 1102,
          
        },
        {
          "nombre": "BISAGRA TRASERA IZQUIERDA",
          "idcrm": 1102,
          
        },
        {
          "nombre": "BISAGRA TRASERA IZQUIERDA INFERIOR",
          "idcrm": 1102,
          
        },
        {
          "nombre": "BISAGRA TRASERA IZQUIERDA SUPERIOR",
          "idcrm": 1102,
          
        },
        {
          "nombre": "BISAGRAS CAPOT DELANTERO",
          "idcrm": 1098,
          
        },
        {
          "nombre": "BISAGRAS CAPOT TRASERO",
          "idcrm": 1098,
          
        },
        {
          "nombre": "BISAGRAS PUERTA DELANTERA DERECHA",
          "idcrm": 1102,
          
        },
        {
          "nombre": "BISEL BAJO FARO DERECHO",
          
          "Familia": 4
        },
        {
          "nombre": "BISEL BAJO FARO IZQUIERDO",
          
          "Familia": 4
        },
        {
          "nombre": "BLOQUE ARMADO",
          "idcrm": 367,
          
        },
        {
          "nombre": "BLOQUE MOTOR",
          "idcrm": 367,
          
        },
        {
          "nombre": "BLOQUEADOR DE BATERIA",
          "idcrm": 6,
          
        },
        {
          "nombre": "BLOQUEO DIRECCION",
          "idcrm": 207,
          
        },
        {
          "nombre": "BLOQUEO ELECTRONICO DE DIRECCION",
          "idcrm": 207,
          
        },
        {
          "nombre": "BOCA ADMISION",
          "idcrm": 379,
          
        },
        {
          "nombre": "BOCA DE LLENADO",
          "idcrm": 437,
          
        },
        {
          "nombre": "BOCA DEPOSITO COMBUSTIBLE",
          "idcrm": 437,
          
        },
        {
          "nombre": "BOCA LLENADO COMBUSTIBLE",
          "idcrm": 437,
          
        },
        {
          "nombre": "BOCINA",
          
          "Familia": 9
        },
        {
          "nombre": "BOLA DE ENGANCHE",
          "idcrm": 11,
          
        },
        {
          "nombre": "BOLSA",
          
          "Familia": 1
        },
        {
          "nombre": "BOMBA ABATIMIENTO CABINA",
          
          "Familia": 13
        },
        {
          "nombre": "BOMBA ABS",
          "idcrm": 450,
          
        },
        {
          "nombre": "BOMBA ACOMULADOR FRENOS",
          "idcrm": 450,
          
        },
        {
          "nombre": "BOMBA AD BLUE",
          
          "Familia": 11
        },
        {
          "nombre": "Bomba aditivo bomba adblue bomba fap",
          
          "Familia": 11
        },
        {
          "nombre": "BOMBA AGUA ELECTRICA",
          "idcrm": 369,
          
        },
        {
          "nombre": "BOMBA AGUA RADIADOR",
          "idcrm": 369,
          
        },
        {
          "nombre": "BOMBA AIRE",
          "idcrm": 1109,
          
        },
        {
          "nombre": "BOMBA APERTURA MALETERO",
          
          "Familia": 6
        },
        {
          "nombre": "BOMBA AUXILIAR DE AGUA",
          "idcrm": 1108,
          
        },
        {
          "nombre": "BOMBA CALEFACCION",
          "idcrm": 369,
          
        },
        {
          "nombre": "BOMBA CAPOTA",
          
          "Familia": 6
        },
        {
          "nombre": "BOMBA COMBUSTIBLE ADICIONAL",
          "idcrm": 370,
          
        },
        {
          "nombre": "BOMBA CONTROL ESTABILIDAD",
          
          "Familia": 12
        },
        {
          "nombre": "BOMBA DE ACEITE",
          "idcrm": 368,
          
        },
        {
          "nombre": "BOMBA DE AGUA",
          "idcrm": 369,
          
        },
        {
          "nombre": "BOMBA DE AIRE",
          "idcrm": 1109,
          
        },
        {
          "nombre": "Bomba de aire de gases de escape",
          "idcrm": 1109,
          
        },
        {
          "nombre": "BOMBA DE ALTA",
          "idcrm": 1110,
          
        },
        {
          "nombre": "BOMBA DE ALTA PRESION",
          "idcrm": 1110,
          
        },
        {
          "nombre": "BOMBA DE CIERRE",
          
          "Familia": 10
        },
        {
          "nombre": "BOMBA DE CIRCULACION DE AGUA",
          "idcrm": 369,
          
        },
        {
          "nombre": "BOMBA DE EMBRAGUE",
          "idcrm": 53,
          
        },
        {
          "nombre": "BOMBA DE LIMPIAPARABRISAS",
          "idcrm": 228,
          
        },
        {
          "nombre": "BOMBA DIRECCION ELECTRICA HIDRAULICA",
          "idcrm": 468,
          
        },
        {
          "nombre": "BOMBA DIRECCION ELECTROHIDRAULICA",
          "idcrm": 468,
          
        },
        {
          "nombre": "BOMBA ELECTRICA",
          "idcrm": 468,
          
        },
        {
          "nombre": "BOMBA ELECTRICA AGUA",
          "idcrm": 369,
          
        },
        {
          "nombre": "BOMBA ELECTRICA CAJA DIRECCION",
          "idcrm": 1116,
          
        },
        {
          "nombre": "BOMBA FRENO MIXTO",
          "idcrm": 467,
          
        },
        {
          "nombre": "BOMBA HIDRAULICA",
          "idcrm": 53,
          
        },
        {
          "nombre": "BOMBA HIDRAULICA DIFERENCIAL TRASERO",
          
          "Familia": 8
        },
        {
          "nombre": "BOMBA HIDRAULICO/CAPOTA",
          
          "Familia": 10
        },
        {
          "nombre": "BOMBA INFLAR",
          
          "Familia": 1
        },
        {
          "nombre": "BOMBA LIMPIAPARABRISAS",
          "idcrm": 228,
          
        },
        {
          "nombre": "BOMBA PRESION COMAND-RAID",
          "idcrm": 1110,
          
        },
        {
          "nombre": "BOMBA RECIRCULACION AGUA - CALEFACCION",
          "idcrm": 1108,
          
        },
        {
          "nombre": "BOMBA SERVODIRECCIONN",
          "idcrm": 468,
          
        },
        {
          "nombre": "BOMBA SUSPENSION",
          "idcrm": 469,
          
        },
        {
          "nombre": "BOMBA SUSPENSION HIDROACTIVA",
          "idcrm": 469,
          
        },
        {
          "nombre": "BOMBA VACIO / DEPRESOR FRENO",
          "idcrm": 1121,
          
        },
        {
          "nombre": "BOMBA/MOTORCILLO AGUA LIMPIAPARABRISAS",
          "idcrm": 228,
          
        },
        {
          "nombre": "BOMBILLA",
          "idcrm": 7,
          
        },
        {
          "nombre": "BOMBIN CAPOTA",
          
          
        },
        {
          "nombre": "BOMBIN DE ARRANQUE",
          
          "Familia": 10
        },
        {
          "nombre": "BOMBIN DE TAPA COMBUSTIBLE",
          
          "Familia": 9
        },
        {
          "nombre": "BOMBIN FRENO",
          "idcrm": 470,
          
        },
        {
          "nombre": "BOMBIN PEDAL EMBRAGUE",
          "idcrm": 54,
          
        },
        {
          "nombre": "BOMBIN PUERTA CORREDERA DERECHA",
          "idcrm": 110,
          
        },
        {
          "nombre": "BOMBIN PUERTA DELANTERO DERECHA",
          "idcrm": 110,
          
        },
        {
          "nombre": "BOMBIN PUERTA DELANTERO IZQUIERDA",
          "idcrm": 111,
          
        },
        {
          "nombre": "BOMBIN TRASERO TAPA MALETERO",
          "idcrm": 162,
          
        },
        {
          "nombre": "BOMBINES DELANTEROS CON LLAVE",
          
          "Familia": 9
        },
        {
          "nombre": "BOMBONA ANTICONGELANTE ROSA 30%",
          "idcrm": 1077,
          
        },
        {
          "nombre": "BOMBONA ANTICONGELANTE VERDE 30%",
          "idcrm": 1077,
          
        },
        {
          "nombre": "BOMBONA LAVAPARABRISAS 5 LTS",
          
          "Familia": 13
        },
        {
          "nombre": "BORRIQUETAS",
          
          "Familia": 13
        },
        {
          "nombre": "BOTA DE SEGURIDAD",
          "idcrm": 15,
          
        },
        {
          "nombre": "BOTAS GOMA",
          "idcrm": 15,
          
        },
        {
          "nombre": "BOTAS SEG.P.NEGRA",
          "idcrm": 15,
          
        },
        {
          "nombre": "BOTE 1 LTS. MEZCLA 2 TIEMPOS FUCHS",
          "idcrm": 28,
          
        },
        {
          "nombre": "BOTE 1LTS. LIQUIDO SERVODIRECCION VERDE",
          "idcrm": 28,
          
        },
        {
          "nombre": "BOTE DE DIRECCION",
          "idcrm": 28,
          
        },
        {
          "nombre": "BOTE DE LIQUIDO DE FRENO",
          "idcrm": 28,
          
        },
        {
          "nombre": "BOTE LIQUIDO FRENOS DOT-4 500 C.C.",
          "idcrm": 15,
          
        },
        {
          "nombre": "BOTELLA AGUA LIMPIA 1L",
          "idcrm": 15,
          
        },
        {
          "nombre": "BOTELLA AGUA LIMPIA 5L",
          "idcrm": 15,
          
        },
        {
          "nombre": "BOTELLA CON MANGUITOS AIRE",
          "idcrm": 15,
          
        },
        {
          "nombre": "BOTELLA EXPANSION",
          "idcrm": 388,
          
        },
        {
          "nombre": "BOTON ASIENTOS",
          
          "Familia": 10
        },
        {
          "nombre": "BOTON CRISTAL PORTON TRASERO",
          "idcrm": 1129,
          
        },
        {
          "nombre": "BOTON DE SALPICADERO",
          "idcrm": 1129,
          
        },
        {
          "nombre": "BOTON FRENO MANO ELECTRICO",
          "idcrm": 1125,
          
        },
        {
          "nombre": "BOTON PORTON/MALETERO",
          "idcrm": 170,
          
        },
        {
          "nombre": "BOTON REGULACION FAROS",
          "idcrm": 281,
          
        },
        {
          "nombre": "BOTON SALPICADERO",
          "idcrm": 1135,
          
        },
        {
          "nombre": "BOTON SOS",
          "idcrm": 1124,
          
        },
        {
          "nombre": "BOTONERA",
          "idcrm": 1129,
          
        },
        {
          "nombre": "BOTONERA PUERTA DELANTERO IZQUIERDA",
          "idcrm": 1131,
          
        },
        {
          "nombre": "BOTONERA RETROVISOR",
          "idcrm": 1134,
          
        },
        {
          "nombre": "BRAZO DE SUSPENSION",
          "idcrm": 1136,
          
        },
        {
          "nombre": "BRAZO INFERIOR DELANTERO DERECHO",
          "idcrm": 1136,
          
        },
        {
          "nombre": "BRAZO INFERIOR DELANTERO IZQUIERDO",
          "idcrm": 1137,
          
        },
        {
          "nombre": "BRAZO INFERIOR TRASERO DERECHO",
          "idcrm": 473,
          
        },
        {
          "nombre": "BRAZO INFERIOR TRASERO IZQUIERDO",
          "idcrm": 474,
          
        },
        {
          "nombre": "BRAZO LIMPIA DELANTERO",
          "idcrm": 77,
          
        },
        {
          "nombre": "BRAZO LIMPIAPARABRISAS DELANTERO",
          "idcrm": 77,
          
        },
        {
          "nombre": "BRAZO LIMPIAPARABRISAS DELANTERO DERECHO",
          "idcrm": 1136,
          
        },
        {
          "nombre": "BRAZO LIMPIAPARABRISAS DELANTERO IZQUIERDO",
          "idcrm": 1137,
          
        },
        {
          "nombre": "BRAZO LIMPIAPARABRISAS TRASERO",
          "idcrm": 163,
          
        },
        {
          "nombre": "BRAZO SUPERIOR DELANTERO DCHO",
          "idcrm": 475,
          
        },
        {
          "nombre": "BRAZO SUPERIOR DELANTERO IZQUIERDO",
          "idcrm": 476,
          
        },
        {
          "nombre": "BRAZO SUPERIOR TRASERO DERECHO",
          "idcrm": 1138,
          
        },
        {
          "nombre": "BRAZO SUPERIOR TRASERO IZQUIERDO",
          "idcrm": 1139,
          
        },
        {
          "nombre": "BRAZO SUS INF DELANTERO DERECHO DELANTERO",
          "idcrm": 471,
          
        },
        {
          "nombre": "BRAZO SUS INF DELANTERO IZQUIERDO DELANTERO",
          "idcrm": 472,
          
        },
        {
          "nombre": "BRAZO SUSPENSION INFERIOR TRASERO CENTRAL",
          "idcrm": 473,
          
        },
        {
          "nombre": "BRAZO SUSPENSION SUPERIOR TRASERO CENTRAL",
          "idcrm": 474,
          
        },
        {
          "nombre": "BRIDA DE REFRIGERANTE",
          "idcrm": 15,
          
        },
        {
          "nombre": "BRIDA NYLON",
          "idcrm": 15,
          
        },
        {
          "nombre": "BRIDAS NYLON 100%",
          "idcrm": 15,
          
        },
        {
          "nombre": "BROCA",
          "idcrm": 15,
          
        },
        {
          "nombre": "BSI BOITE",
          "idcrm": 15,
          
        },
        {
          "nombre": "BUJE DELANTERO",
          "idcrm": 202,
          
        },
        {
          "nombre": "BUJE DELANTERO DERECHO",
          "idcrm": 202,
          
        },
        {
          "nombre": "BUJE DELANTERO IZQUIERDO",
          "idcrm": 202,
          
        },
        {
          "nombre": "BUJE RASERO",
          "idcrm": 202,
          
        },
        {
          "nombre": "BUJE TRASERO",
          "idcrm": 202,
          
        },
        {
          "nombre": "BUJE TRASERO IZQUIERDA",
          "idcrm": 202,
          
        },
        {
          "nombre": "BUJE TRASERO IZQUIERDO",
          "idcrm": 202,
          
        },
        {
          "nombre": "BUJE/RODAMIENTO DELANTERO",
          "idcrm": 202,
          
        },
        {
          "nombre": "BUJE/RODAMIENTO TRASERO",
          "idcrm": 202,
          
        },
        {
          "nombre": "BUJIA DE PRECALENTAMIENTO",
          "idcrm": 231,
          
        },
        {
          "nombre": "BUMPER",
          "idcrm": 94,
          
        },
        {
          "nombre": "CABALLETE",
          "idcrm": 8,
          
        },
        {
          "nombre": "CABEZAL",
          "idcrm": 15,
          
        },
        {
          "nombre": "CABINA COMPLETA",
          
          "Familia": 4
        },
        {
          "nombre": "CABLE",
          "idcrm": 1150,
          
        },
        {
          "nombre": "CABLE ACCIONADOR CERRADURA",
          
          "Familia": 10
        },
        {
          "nombre": "CABLE ADAPTADOR DE RADIO",
          "idcrm": 15,
          
        },
        {
          "nombre": "CABLE APERTURA CAPO DELANTERO",
          "idcrm": 1145,
          
        },
        {
          "nombre": "CABLE BUJIA",
          "idcrm": 1153,
          
        },
        {
          "nombre": "CABLE CAPOT FRONTAL",
          "idcrm": 1145,
          
        },
        {
          "nombre": "CABLE CUENTAKILOMETROS",
          "idcrm": 1148,
          
        },
        {
          "nombre": "CABLE DE ACELERADOR",
          "idcrm": 1143,
          
        },
        {
          "nombre": "CABLE DE C-KILOMETROS",
          "idcrm": 1148,
          
        },
        {
          "nombre": "CABLE DERECHO MANDO CAMBIO VEL.",
          "idcrm": 1149,
          
        },
        {
          "nombre": "CABLE EMBRAGUE",
          "idcrm": 1146,
          
        },
        {
          "nombre": "CABLE EMBRAQUE",
          "idcrm": 1146,
          
        },
        {
          "nombre": "CABLE FRENO DE MANO",
          "idcrm": 1147,
          
        },
        {
          "nombre": "CABLE FRENO MANO D",
          "idcrm": 1147,
          
        },
        {
          "nombre": "CABLE FRENO MANO I",
          "idcrm": 1147,
          
        },
        {
          "nombre": "CABLE MOTOR",
          "idcrm": 1150,
          
        },
        {
          "nombre": "CABLE PALANCA CAMBIO",
          "idcrm": 1149,
          
        },
        {
          "nombre": "CABLE PIROTECNICO",
          "idcrm": 1150,
          
        },
        {
          "nombre": "CABLE POSITIVO BATERIA",
          "idcrm": 1144,
          
        },
        {
          "nombre": "CABLE RUEDA DE REPUESTO",
          "idcrm": 1150,
          
        },
        {
          "nombre": "CABLEADO CARGA",
          "idcrm": 1144,
          
        },
        {
          "nombre": "CABLEADO MOTOR",
          "idcrm": 1150,
          
        },
        {
          "nombre": "CABLES DE BUJIA",
          "idcrm": 1153,
          
        },
        {
          "nombre": "CABLES DE CAMBIO",
          "idcrm": 1149,
          
        },
        {
          "nombre": "CABLES SELECTORES DE VELODIDAD",
          "idcrm": 1148,
          
        },
        {
          "nombre": "CABRESTANTE",
          
          "Familia": 13
        },
        {
          "nombre": "CADENA",
          "idcrm": 208,
          
        },
        {
          "nombre": "CADENA LIQUIDA",
          "idcrm": 15,
          
        },
        {
          "nombre": "CADENA MOTO",
          "idcrm": 15,
          
        },
        {
          "nombre": "CAJA ALMACENAMIENTO",
          "idcrm": 15,
          
        },
        {
          "nombre": "CAJA BSM",
          "idcrm": 1198,
          
        },
        {
          "nombre": "CAJA CAMBIOS 4X4",
          "idcrm": 55,
          
        },
        {
          "nombre": "CAJA CAMBIOS AUTOMATICA",
          "idcrm": 55,
          
        },
        {
          "nombre": "CAJA CAMBIOS COMPLETO 4X4",
          "idcrm": 55,
          
        },
        {
          "nombre": "CAJA CAMBIOS MANUAL",
          "idcrm": 55,
          
        },
        {
          "nombre": "CAJA CASCO",
          "idcrm": 55,
          
        },
        {
          "nombre": "CAJA CERRADA",
          "idcrm": 55,
          
        },
        {
          "nombre": "CAJA DE AUTOCLE",
          "idcrm": 15,
          
        },
        {
          "nombre": "CAJA DE CONTACTORES",
          "idcrm": 15,
          
        },
        {
          "nombre": "CAJA DE DIRECCION ASISTIDA",
          "idcrm": 1157,
          
        },
        {
          "nombre": "CAJA DE PRECALENTAMIENTO",
          "idcrm": 229,
          
        },
        {
          "nombre": "CAJA DIRECCION ASISTIDA",
          "idcrm": 1157,
          
        },
        {
          "nombre": "CAJA DIRECCION ELECTRICA",
          "idcrm": 1157,
          
        },
        {
          "nombre": "CAJA FILTRO DE ACEITE",
          "idcrm": 424,
          
        },
        {
          "nombre": "CAJA FILTRO GASOIL",
          "idcrm": 425,
          
        },
        {
          "nombre": "CAJA FUSIBLES/RELE",
          "idcrm": 1162,
          
        },
        {
          "nombre": "CAJA HERRAMIENTA 38 PIEZAS MANNESMAN",
          "idcrm": 15,
          
        },
        {
          "nombre": "CAJA HERRAMIENTAS 108 PIEZAS JBM",
          "idcrm": 15,
          
        },
        {
          "nombre": "CAJA HERRAMIENTAS 115 PIEZAS",
          "idcrm": 15,
          
        },
        {
          "nombre": "CAJA HERRAMIENTAS 215 PIEZAS",
          "idcrm": 15,
          
        },
        {
          "nombre": "CAJA HERRAMIENTAS JBM 113 PIEZAS",
          "idcrm": 15,
          
        },
        {
          "nombre": "CAJA HERRAMIENTAS MANNESMANN 40 PIEZAS",
          "idcrm": 15,
          
        },
        {
          "nombre": "CAJA HTAS.MAURER",
          "idcrm": 15,
          
        },
        {
          "nombre": "CAJA MARIPOSA AIRE",
          "idcrm": 373,
          
        },
        {
          "nombre": "CAJA REENVIO",
          
          "Familia": 11
        },
        {
          "nombre": "CAJA RELES",
          "idcrm": 230,
          
        },
        {
          "nombre": "CAJA TERMOSTATO",
          "idcrm": 1254,
          
        },
        {
          "nombre": "CAJA TRASERA",
          "idcrm": 164,
          
        },
        {
          "nombre": "CAJA VALVULAS SUSPENSION",
          "idcrm": 1163,
          
        },
        {
          "nombre": "CAJA VALVULAS VACIO",
          "idcrm": 1163,
          
        },
        {
          "nombre": "CAJ�N FILTRO AIRE",
          "idcrm": 1159,
          
        },
        {
          "nombre": "CAJON PORTAOBJETOS",
          "idcrm": 328,
          
        },
        {
          "nombre": "CALAMINA INTERIOR",
          "idcrm": 1165,
          
        },
        {
          "nombre": "CALAMINA INTERIOR DELANTERA DERECHA",
          "idcrm": 1165,
          
        },
        {
          "nombre": "CALAMINA INTERIOR DELANTERA IZQUIERDA",
          "idcrm": 1165,
          
        },
        {
          "nombre": "CALAMINA INTERIOR TRASERA DERECHA",
          "idcrm": 1165,
          
        },
        {
          "nombre": "CALAMINA INTERIOR TRASERA IZQUIERDA",
          "idcrm": 1165,
          
        },
        {
          "nombre": "CALDER�N",
          "idcrm": 1129,
          
        },
        {
          "nombre": "CALDERIN TRASERO DERECHO",
          "idcrm": 1129,
          
        },
        {
          "nombre": "CALDERIN TRASERO IZQUIERDO",
          "idcrm": 1129,
          
        },
        {
          "nombre": "CALEFACCION AUTONOMA",
          "idcrm": 1234,
          
        },
        {
          "nombre": "CALEFACCION AUXILIAR (2)",
          "idcrm": 1234,
          
        },
        {
          "nombre": "CALEFACCION COMPLETA AIRE ACONDICIONADO",
          "idcrm": 1234,
          
        },
        {
          "nombre": "CALEFACCION COMPLETA TRASERA",
          "idcrm": 1234,
          
        },
        {
          "nombre": "CALEFACCION ENTERA AIRE ACOND.",
          "idcrm": 1234,
          
        },
        {
          "nombre": "CALEFACCION ESTATICO",
          "idcrm": 1234,
          
        },
        {
          "nombre": "CALENTADOR",
          "idcrm": 231,
          
        },
        {
          "nombre": "CALISTER",
          "idcrm": 398,
          
        },
        {
          "nombre": "CAMARA DE FRENO",
          "idcrm": 1169,
          
        },
        {
          "nombre": "CAMARA RADAR DELANTERA",
          "idcrm": 1169,
          
        },
        {
          "nombre": "CAMISA DE PISTON",
          
          "Familia": 11
        },
        {
          "nombre": "CANALIZACION ENTRADA AIRE",
          "idcrm": 1230,
          
        },
        {
          "nombre": "CANALIZADOR",
          "idcrm": 1230,
          
        },
        {
          "nombre": "CANALIZADOR AIRE",
          "idcrm": 1230,
          
        },
        {
          "nombre": "CANALIZADOR DE ELECTROS",
          "idcrm": 1230,
          
        },
        {
          "nombre": "CANISTER",
          "idcrm": 398,
          
        },
        {
          "nombre": "CA�A / COLUMNA DIRECCION",
          "idcrm": 207,
          
        },
        {
          "nombre": "CAPO",
          "idcrm": 80,
          
        },
        {
          "nombre": "CAPOT TRASERO",
          "idcrm": 184,
          
        },
        {
          "nombre": "CAPOT XL",
          "idcrm": 80,
          
        },
        {
          "nombre": "CAPTADOR ABS",
          "idcrm": 1545,
          
        },
        {
          "nombre": "CAPTADOR C-KM",
          "idcrm": 1545,
          
        },
        {
          "nombre": "CAPTADOR DE ABS",
          "idcrm": 1545,
          
        },
        {
          "nombre": "CAPTADOR GIRO MOTOR",
          "idcrm": 289,
          
        },
        {
          "nombre": "CAPTADORES ABS DELANTERO",
          "idcrm": 1545,
          
        },
        {
          "nombre": "CAPTADORES DE ABS DELANTEROS",
          "idcrm": 1545,
          
        },
        {
          "nombre": "CAPTADORES DE ABS TRASEROS",
          "idcrm": 1545,
          
        },
        {
          "nombre": "CARCASA",
          
          "Familia": 13
        },
        {
          "nombre": "CARCASA CAJA CAMBIOS",
          "idcrm": 57,
          
        },
        {
          "nombre": "CARCASA CALEFACCION",
          "idcrm": 1234,
          
        },
        {
          "nombre": "CARCASA DE CUADRO DE LUCES",
          "idcrm": 251,
          
        },
        {
          "nombre": "CARCASA DE ELECTROVENTILADOR",
          "idcrm": 253,
          
        },
        {
          "nombre": "CARCASA DE VOLANTE",
          "idcrm": 1586,
          
        },
        {
          "nombre": "CARCASA DEL FILTRO AIRE",
          "idcrm": 1159,
          
        },
        {
          "nombre": "CARCASA DIFERENCIAL DEL",
          "idcrm": 59,
          
        },
        {
          "nombre": "CARCASA ELECTROVENTILADOR",
          "idcrm": 253,
          
        },
        {
          "nombre": "CARCASA ELECTROVENTILADOR REFRIGERACION",
          "idcrm": 253,
          
        },
        {
          "nombre": "CARCASA ELECTROVENTILADORES",
          "idcrm": 253,
          
        },
        {
          "nombre": "CARCASA ESPEJO DER.",
          "idcrm": 112,
          
        },
        {
          "nombre": "CARCASA ESPEJO IZQ",
          "idcrm": 113,
          
        },
        {
          "nombre": "CARCASA ESPEJO IZQUERDO",
          "idcrm": 113,
          
        },
        {
          "nombre": "CARCASA FILTRO COMBUSTIBLE CON BOMBA ALIMENTACION MANUAL",
          "idcrm": 425,
          
        },
        {
          "nombre": "CARCASA FILTRO POLEN",
          "idcrm": 144,
          
        },
        {
          "nombre": "CARCASA MANDOS COLUMNA DIRECCION",
          "idcrm": 144,
          
        },
        {
          "nombre": "CARCASA RADIO",
          "idcrm": 144,
          
        },
        {
          "nombre": "CARCASA REVESTIMIENTO CUADRO",
          "idcrm": 144,
          
        },
        {
          "nombre": "CARDAN",
          "idcrm": 206,
          
        },
        {
          "nombre": "CARDANCILLO DIRECCION",
          "idcrm": 1515,
          
        },
        {
          "nombre": "CARGA BATERIA",
          "idcrm": 1186,
          
        },
        {
          "nombre": "CARGADOR",
          "idcrm": 1186,
          
        },
        {
          "nombre": "CARGADOR BATERIA",
          "idcrm": 1186,
          
        },
        {
          "nombre": "CARGADOR BATERIAS COCHE/MOTO CAR2120",
          "idcrm": 1186,
          
        },
        {
          "nombre": "CARGADOR DE C.D.",
          "idcrm": 1185,
          
        },
        {
          "nombre": "CARGADOR INDUCTIVO",
          "idcrm": 1186,
          
        },
        {
          "nombre": "CARRACA REVERSIBLE",
          "idcrm": 15,
          
        },
        {
          "nombre": "CARRETES DE AIRBAG",
          "idcrm": 289,
          
        },
        {
          "nombre": "CARROCERIA",
          "idcrm": 1189,
          
        },
        {
          "nombre": "CARTEL BEBE A BORDO CON  VENTOSA",
          
          "Familia": 13
        },
        {
          "nombre": "CARTER ACEITE PSA",
          "idcrm": 375,
          
        },
        {
          "nombre": "CARTUCHO TURBO",
          "idcrm": 1190,
          
        },
        {
          "nombre": "CASCO BOMBA INYECTORA",
          "idcrm": 1118,
          
        },
        {
          "nombre": "CASCO CAJA CAMBIOS",
          "idcrm": 55,
          
        },
        {
          "nombre": "CASCO COMPRESOR AIRE",
          "idcrm": 186,
          
        },
        {
          "nombre": "CASCO MOTOR COMPLETO",
          "idcrm": 406,
          
        },
        {
          "nombre": "CATADIOPTRICO TRAS IZQ",
          "idcrm": 1517,
          
        },
        {
          "nombre": "CATADRIOPTICO DER",
          "idcrm": 1516,
          
        },
        {
          "nombre": "CATADRIOPTICO TRAS. DCHO.",
          "idcrm": 1516,
          
        },
        {
          "nombre": "CEBADOR DE COMBUSTIBLE",
          "idcrm": 370,
          
        },
        {
          "nombre": "CEBADOR DE GASOIL",
          "idcrm": 370,
          
        },
        {
          "nombre": "CEBADOR Y PORTAFILTRO DE COMBUSTIBLE",
          "idcrm": 370,
          
        },
        {
          "nombre": "CENTRALITA (PIEZA EXENTA DE GARANT�A Y DEVOLUCI�N)",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA AFS",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA AMPLIFICADOR ANTENA",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA ARRANQUE STAR STOP",
          "idcrm": 248,
          
        },
        {
          "nombre": "CENTRALITA AUDIO",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA AUTO CRUISER",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA BCM",
          "idcrm": 1198,
          
        },
        {
          "nombre": "CENTRALITA BLUETOOTH",
          "idcrm": 1411,
          
        },
        {
          "nombre": "CENTRALITA BOMBA INYECCION",
          "idcrm": 1209,
          
        },
        {
          "nombre": "CENTRALITA CAMARA",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA CAMARA TRASERA",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA CAMBIO",
          "idcrm": 1199,
          
        },
        {
          "nombre": "CENTRALITA COMFORT",
          "idcrm": 265,
          
        },
        {
          "nombre": "CENTRALITA CONFORT",
          "idcrm": 265,
          
        },
        {
          "nombre": "CENTRALITA CONTROL DE INYECCION",
          "idcrm": 1209,
          
        },
        {
          "nombre": "CENTRALITA CONTROL DE TRACCION",
          "idcrm": 1385,
          
        },
        {
          "nombre": "CENTRALITA CONTROL DE VOZ",
          "idcrm": 1198,
          
        },
        {
          "nombre": "CENTRALITA CONTROL ENGANCHE REMOLQUE",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA CONTROL PUERTA",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA CONTROL TRANSMISION",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA CONTROL VELOCIDAD",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA DE AIRBAG",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA DE DIRECCION",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA DE ELECTROS",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA DE FUSIBLES",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA DE INYECCION",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA DIRECCION ASISTIDA",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA EBS",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA ELECTRICA PRINCIPAL CABINA",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA ELECTROS",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA ELECTROVENTILADORES",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA ELEVALUNAS",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA ENCENDIDO",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA ENGANCHE",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA ESP",
          "idcrm": 233,
          
        },
        {
          "nombre": "CENTRALITA FARO XENON IZQUIERDO",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA FRENO DE MANO ESTACIONAMIENTO",
          "idcrm": 1241,
          
        },
        {
          "nombre": "CENTRALITA FRENO ESTACIONAMIENTO AUTOMATICO",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA HIDRAULICA",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA INTARDER",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA LED DERECHA",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA LED IZQUIERDA",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA LIMITADOR DE VELOCIDAD",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA PALANCA DE CAMBIO AUTOMATICA",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA PORTON TRASERO",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA POTENCIA POWERBOX",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA SONIDO",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA START / STOP",
          "idcrm": 248,
          
        },
        {
          "nombre": "CENTRALITA TECHO ELECTRICO",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA TRANSFER",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA TRANSMISION",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA UNIDAD CONTROL",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA UNIDAD CONTROL TELEFONO",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA USB AUXILIAR",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRALITA VALVULAS FRENO",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CENTRIFUGO",
          "idcrm": 448,
          
        },
        {
          "nombre": "CENTRIFUGO+TENSOR",
          "idcrm": 448,
          
        },
        {
          "nombre": "CEPILLO MAURER",
          "idcrm": 15,
          
        },
        {
          "nombre": "CERA RAPIDA",
          "idcrm": 15,
          
        },
        {
          "nombre": "CERQUILLO FARO DERECHO",
          "idcrm": 1215,
          
        },
        {
          "nombre": "CERQUILLO FARO IZQUIERDO",
          "idcrm": 1215,
          
        },
        {
          "nombre": "CERRADURA CAPO",
          "idcrm": 83,
          
        },
        {
          "nombre": "CERRADURA LATERAL CORREDERA DERECHA",
          "idcrm": 1218,
          
        },
        {
          "nombre": "CERRADURA LATERAL CORREDERA IZQUIERDA",
          "idcrm": 1219,
          
        },
        {
          "nombre": "CERRADURA LATERAL DERECHA",
          "idcrm": 116,
          
        },
        {
          "nombre": "CERRADURA LATERAL IZQUIERDA",
          "idcrm": 117,
          
        },
        {
          "nombre": "CERRADURA LATERAL IZQUIERDO",
          "idcrm": 117,
          
        },
        {
          "nombre": "CERRADURA PORTON",
          "idcrm": 165,
          
        },
        {
          "nombre": "CERRADURA PUERTA DEL. IZQUIERDA",
          "idcrm": 115,
          
        },
        {
          "nombre": "CERRADURA PUERTA DELANTERO DERECHA",
          "idcrm": 114,
          
        },
        {
          "nombre": "CERRADURA PUERTA DELANTERO IZQUIERDA",
          "idcrm": 114,
          
        },
        {
          "nombre": "CERRADURA PUERTA LATERAL CORREDERA  IZQUIERDA",
          "idcrm": 1219,
          
        },
        {
          "nombre": "CERRADURA PUERTA LATERAL CORREDERA DERECHA",
          "idcrm": 1218,
          
        },
        {
          "nombre": "CERRADURA PUERTA LATERAL CORREDERA IZQUIERDA",
          "idcrm": 1219,
          
        },
        {
          "nombre": "CERRADURA PUERTA TRASERO DERECHA",
          "idcrm": 118,
          
        },
        {
          "nombre": "CERRADURA PUERTA TRASERO IZQUIERDA",
          "idcrm": 119,
          
        },
        {
          "nombre": "CHALECO MULTIBOLSILLOS",
          "idcrm": 15,
          
        },
        {
          "nombre": "CHALECO REFLECTANTE",
          "idcrm": 15,
          
        },
        {
          "nombre": "CHAMP� CAR",
          "idcrm": 15,
          
        },
        {
          "nombre": "CHAQUETA GRIS/AMARILLO",
          "idcrm": 15,
          
        },
        {
          "nombre": "CHASIS LATERAL DERECHO",
          "idcrm": 1221,
          
        },
        {
          "nombre": "CHASIS TRASERO",
          "idcrm": 499,
          
        },
        {
          "nombre": "CHUPONA ACEITE",
          "idcrm": 368,
          
        },
        {
          "nombre": "CIERRE CAPOTA",
          "idcrm": 165,
          
        },
        {
          "nombre": "CIERRE DE GUANTERA",
          "idcrm": 328,
          
        },
        {
          "nombre": "CIERRE DE PUER.CORRED.DCHA",
          "idcrm": 1218,
          
        },
        {
          "nombre": "CIERRE DE PUER.CORRED.IZQU.",
          "idcrm": 1219,
          
        },
        {
          "nombre": "CIERRE ELECTROMAG. DELANTERO IZDO",
          "idcrm": 115,
          
        },
        {
          "nombre": "CIERRE ELECTROMAG. TRASERO DCHO",
          "idcrm": 114,
          
        },
        {
          "nombre": "CIERRE ELECTROMAGNETICO DELANTERO DERECHO",
          "idcrm": 114,
          
        },
        {
          "nombre": "CIERRE ELECTROMAGNETICO DELANTERO IZQUIERDO",
          "idcrm": 115,
          
        },
        {
          "nombre": "CIERRE ELECTROMAGNETICO PORTON",
          "idcrm": 165,
          
        },
        {
          "nombre": "CIERRE ELECTROMAGNETICO TRASERO DERECHO",
          "idcrm": 118,
          
        },
        {
          "nombre": "CIERRE ELECTROMAGNETICO TRASERO IZQUIERDO",
          "idcrm": 119,
          
        },
        {
          "nombre": "CIERRE LATERAL IZQUIERDO",
          "idcrm": 118,
          
        },
        {
          "nombre": "CIERRE TAPA DEPOSITO COMBUSTIBLE",
          "idcrm": 1220,
          
        },
        {
          "nombre": "CIGUE�AL",
          "idcrm": 378,
          
        },
        {
          "nombre": "CINTA AISLANTE",
          "idcrm": 1222,
          
        },
        {
          "nombre": "CINTA EMBALAR",
          "idcrm": 15,
          
        },
        {
          "nombre": "CINTURON 3� FILA DERECHO",
          "idcrm": 323,
          
        },
        {
          "nombre": "CINTURON 3� FILA IZQUIERDO",
          "idcrm": 323,
          
        },
        {
          "nombre": "CINTURON SEGURIDAD 3A FILA DERECHO",
          "idcrm": 323,
          
        },
        {
          "nombre": "CINTURON SEGURIDAD 3� FILA DERECHO",
          "idcrm": 323,
          
        },
        {
          "nombre": "CINTURON SEGURIDAD 3A FILA IZQUIERDO",
          "idcrm": 323,
          
        },
        {
          "nombre": "CINTURON SEGURIDAD 3� FILA IZQUIERDO",
          "idcrm": 323,
          
        },
        {
          "nombre": "CINTURON SEGURIDAD DELANTERO CENTRAL",
          "idcrm": 320,
          
        },
        {
          "nombre": "CINTURON SEGURIDAD JUEGO COMPLETO",
          "idcrm": 320,
          
        },
        {
          "nombre": "CINTURON TERCER FILA TRASERO DERECHO",
          "idcrm": 323,
          
        },
        {
          "nombre": "CINTURON TERCER FILA TRASERO IZQUIERDO",
          "idcrm": 323,
          
        },
        {
          "nombre": "CLAUSOR",
          "idcrm": 1223,
          
        },
        {
          "nombre": "CLIMATIZADOR DE AIRE",
          "idcrm": 191,
          
        },
        {
          "nombre": "COFRE TECHO",
          "idcrm": 23,
          
        },
        {
          "nombre": "COFRE TECHO PORTA EQUIPAJES",
          "idcrm": 23,
          
        },
        {
          "nombre": "COJINETE CAJA CAMBIOS",
          "idcrm": 57,
          
        },
        {
          "nombre": "COLECTOR DE SUCCION",
          "idcrm": 437,
          
        },
        {
          "nombre": "COLIN TRASERO",
          "idcrm": 166,
          
        },
        {
          "nombre": "COLLARIN",
          "idcrm": 54,
          
        },
        {
          "nombre": "COLLARIN DE EMBRAGUE",
          "idcrm": 54,
          
        },
        {
          "nombre": "COLUMNA DE DIRECCION ELECTRICA",
          "idcrm": 207,
          
        },
        {
          "nombre": "COLUMNA DIRECCION ELECTRICA",
          "idcrm": 207,
          
        },
        {
          "nombre": "COM2000",
          "idcrm": 261,
          
        },
        {
          "nombre": "COMPARTIMENTO DEL CASCO",
          "idcrm": 328,
          
        },
        {
          "nombre": "COMPENSADOR DE FRENO",
          "idcrm": 450,
          
        },
        {
          "nombre": "COMPENSADOR DE FRENOS",
          "idcrm": 450,
          
        },
        {
          "nombre": "COMPRESOR AIRE ACOND.",
          "idcrm": 186,
          
        },
        {
          "nombre": "COMPRESOR CALEFACCION ESTATICA",
          "idcrm": 186,
          
        },
        {
          "nombre": "COMPRESOR CIERRE CENTRALIZADO",
          "idcrm": 1227,
          
        },
        {
          "nombre": "COMPRESOR DE AIRE",
          "idcrm": 1228,
          
        },
        {
          "nombre": "COMPRESOR DE AIRE DE 120 PSI",
          "idcrm": 1228,
          
        },
        {
          "nombre": "COMPRESOR SUSPENSION NEUMATICA",
          "idcrm": 1229,
          
        },
        {
          "nombre": "CONDENSADOR / RADIADOR A/A",
          "idcrm": 187,
          
        },
        {
          "nombre": "CONDENSADOR STAR/STOP",
          "idcrm": 1336,
          
        },
        {
          "nombre": "CONDUCTO ADMISION",
          "idcrm": 437,
          
        },
        {
          "nombre": "CONDUCTO AIRE DE MOTOR",
          "idcrm": 437,
          
        },
        {
          "nombre": "CONDUCTO VENTILACION DERECHO",
          "idcrm": 437,
          
        },
        {
          "nombre": "CONDUCTO VENTILACION INFERIOR",
          "idcrm": 437,
          
        },
        {
          "nombre": "CONDUCTO VENTILACION IZQUIERDO",
          "idcrm": 437,
          
        },
        {
          "nombre": "CONECTOR",
          "idcrm": 1232,
          
        },
        {
          "nombre": "CONJUNTO BIMASA",
          "idcrm": 1237,
          
        },
        {
          "nombre": "CONJUNTO INTERRUPTORES",
          "idcrm": 254,
          
        },
        {
          "nombre": "CONJUNTO PALANCA CAMBIO VELOCIDAD",
          "idcrm": 63,
          
        },
        {
          "nombre": "CONJUNTO SUSPENSI�N",
          
          "Familia": 12
        },
        {
          "nombre": "CONMUTADOR DE ARRANQUE/ENCENDIDO",
          "idcrm": 250,
          
        },
        {
          "nombre": "CONMUTADOR DE LUCES",
          "idcrm": 261,
          
        },
        {
          "nombre": "CONSOLA CENTRAL RADIO",
          "idcrm": 1239,
          
        },
        {
          "nombre": "CONSOLA TECHO",
          "idcrm": 1238,
          
        },
        {
          "nombre": "CONTACTO PEDAL FRENO",
          "idcrm": 254,
          
        },
        {
          "nombre": "CONTACTOR ARRANQUE",
          "idcrm": 250,
          
        },
        {
          "nombre": "CONTRARROTANTE",
          "idcrm": 378,
          
        },
        {
          "nombre": "CONTROL DE MODULO",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CONTROL NAVE IDRIVE",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CONTROL PANEL START STOP",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CONTROL VELOCIDAD",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CONTROLADOR DE MOTOR",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CONVERTIDOR",
          "idcrm": 57,
          
        },
        {
          "nombre": "CONVERTIDOR CAJA CAMBIOS",
          "idcrm": 57,
          
        },
        {
          "nombre": "CONVERTIDOR CORRIENTE",
          
          "Familia": 9
        },
        {
          "nombre": "CONVERTIDOR DE PAR",
          "idcrm": 57,
          
        },
        {
          "nombre": "CONVERTIDOR HIBRIDO",
          
          "Familia": 9
        },
        {
          "nombre": "CONVERTIDOR POTENCIA",
          
          "Familia": 11
        },
        {
          "nombre": "COPELA",
          "idcrm": 1243,
          
        },
        {
          "nombre": "COPELA AMORTIGUADOR TRASERO DERECHO",
          "idcrm": 1243,
          
        },
        {
          "nombre": "COPELA AMORTIGUADOR TRASERO IZQUIERDO",
          "idcrm": 1244,
          
        },
        {
          "nombre": "COPELA DE AMORTIGUADOR",
          "idcrm": 1243,
          
        },
        {
          "nombre": "CORONA MOTOR",
          "idcrm": 412,
          
        },
        {
          "nombre": "CORREA SERVICIO",
          "idcrm": 382,
          
        },
        {
          "nombre": "CORREA TRAPEZOIDAL",
          "idcrm": 382,
          
        },
        {
          "nombre": "CORRETJA ACCESORIS",
          "idcrm": 382,
          
        },
        {
          "nombre": "CORTA CORRIENTE",
          "idcrm": 1192,
          
        },
        {
          "nombre": "CORTE CHAPA",
          "idcrm": 1514,
          
        },
        {
          "nombre": "CORTINA ELECTRICA TRASERA",
          "idcrm": 1248,
          
        },
        {
          "nombre": "CORTINA LATERAL PLEGABLE",
          "idcrm": 1246,
          
        },
        {
          "nombre": "CORTINA PARASOL TRASERA",
          "idcrm": 1246,
          
        },
        {
          "nombre": "CORTINA PUERTA",
          "idcrm": 1246,
          
        },
        {
          "nombre": "CORTINILLA",
          "idcrm": 1246,
          
        },
        {
          "nombre": "CORTINILLAS",
          "idcrm": 1246,
          
        },
        {
          "nombre": "CREMALLERA DIRECCION ASISTIDA",
          "idcrm": 208,
          
        },
        {
          "nombre": "CREMALLERA DIRECCION HIDRAULICA",
          "idcrm": 208,
          
        },
        {
          "nombre": "CREMALLERA DIRECCION NORMAL",
          "idcrm": 208,
          
        },
        {
          "nombre": "CRISTAL RETROVISOR DCHO",
          "idcrm": 1249,
          
        },
        {
          "nombre": "CRISTAL RETROVISOR IZDO",
          "idcrm": 1251,
          
        },
        {
          "nombre": "CRISTAL TRIANGULO DELANTERO DERECHO",
          "idcrm": 130,
          
        },
        {
          "nombre": "CRISTAL TRIANGULO PUERTA TRASERA DERECHA",
          "idcrm": 132,
          
        },
        {
          "nombre": "CRUCERO CONTROL",
          "idcrm": 1242,
          
        },
        {
          "nombre": "CRUCETA COLUMNA DIRECCION",
          "idcrm": 1515,
          
        },
        {
          "nombre": "CUADRO COMPLETO",
          "idcrm": 251,
          
        },
        {
          "nombre": "CUADRO CUENTA KILOMETROS",
          "idcrm": 251,
          
        },
        {
          "nombre": "CUADRO CUENTA-REVOLUCIONES",
          "idcrm": 251,
          
        },
        {
          "nombre": "CUADRO DE INDICADORES",
          "idcrm": 251,
          
        },
        {
          "nombre": "CUBIERTA",
          "idcrm": 384,
          
        },
        {
          "nombre": "CUBIERTA CENTRAL",
          "idcrm": 384,
          
        },
        {
          "nombre": "CUBIERTA CINTURON",
          "idcrm": 144,
          
        },
        {
          "nombre": "CUBIERTA DE BATERIA",
          "idcrm": 144,
          
        },
        {
          "nombre": "CUBIERTA GUANTERA",
          "idcrm": 144,
          
        },
        {
          "nombre": "CUBIERTA TRASERA",
          "idcrm": 144,
          
        },
        {
          "nombre": "CUBO DE RUEDA",
          "idcrm": 1253,
          
        },
        {
          "nombre": "CUBO DELANTERO DERECHO",
          "idcrm": 1253,
          
        },
        {
          "nombre": "CUBO DELANTERO IZQUIERDO",
          "idcrm": 1253,
          
        },
        {
          "nombre": "CUBRECARTER LATERAL",
          "idcrm": 84,
          
        },
        {
          "nombre": "CUBREDEPOSITO",
          "idcrm": 84,
          
        },
        {
          "nombre": "CUENTA REVOLUCIONES",
          "idcrm": 251,
          
        },
        {
          "nombre": "CUERPO DE MARIPOSA",
          "idcrm": 373,
          
        },
        {
          "nombre": "CUERPO MARIPOSA",
          "idcrm": 373,
          
        },
        {
          "nombre": "CULATA DERECHA",
          "idcrm": 385,
          
        },
        {
          "nombre": "CULATA IZQUIERDA",
          "idcrm": 385,
          
        },
        {
          "nombre": "CULATA MOTOR",
          "idcrm": 385,
          
        },
        {
          "nombre": "CUTTER MAURER",
          "idcrm": 15,
          
        },
        {
          "nombre": "DECANTADOR ACEITE",
          "idcrm": 15,
          
        },
        {
          "nombre": "DECANTADOR DE ACEITE",
          "idcrm": 15,
          
        },
        {
          "nombre": "DECANTADOR SUPERIOR ACEITE",
          "idcrm": 15,
          
        },
        {
          "nombre": "DEFENSA DE LA CAJA TRASERO",
          "idcrm": 172,
          
        },
        {
          "nombre": "DEFENSA IZQUIERDA DE PARAGOLPES TRASERO",
          "idcrm": 172,
          
        },
        {
          "nombre": "DEFENSA TRASERA",
          "idcrm": 172,
          
        },
        {
          "nombre": "DEPORTIVA SEG.",
          "idcrm": 15,
          
        },
        {
          "nombre": "DEPORTIVA SEG.MAURER",
          "idcrm": 15,
          
        },
        {
          "nombre": "DEPOSITO",
          "idcrm": 387,
          
        },
        {
          "nombre": "DEPOSITO ACEITE HIDRAULICO",
          "idcrm": 1264,
          
        },
        {
          "nombre": "DEPOSITO AD BLUE | UREA",
          "idcrm": 514,
          
        },
        {
          "nombre": "DEPOSITO ADITIVO FAP ADBLUE",
          "idcrm": 514,
          
        },
        {
          "nombre": "DEPOSITO BOMBA DIRECCION",
          "idcrm": 1264,
          
        },
        {
          "nombre": "DEPOSITO BOMBA FRENO",
          "idcrm": 1265,
          
        },
        {
          "nombre": "DEPOSITO DE AD-BLUE",
          "idcrm": 514,
          
        },
        {
          "nombre": "DEPOSITO DE AGUA DE MOTOR",
          "idcrm": 388,
          
        },
        {
          "nombre": "DEPOSITO DE GAS",
          
          "Familia": 11
        },
        {
          "nombre": "DEPOSITO DIRECCION ASISTIDA",
          "idcrm": 1264,
          
        },
        {
          "nombre": "DEPOSITO FAP",
          "idcrm": 514,
          
        },
        {
          "nombre": "DEPOSITO FAP (*)",
          "idcrm": 514,
          
        },
        {
          "nombre": "DEPOSITO FILTRO PARTICULAS",
          "idcrm": 514,
          
        },
        {
          "nombre": "DEPOSITO LIMPIAPARABRISAS",
          "idcrm": 1264,
          
        },
        {
          "nombre": "DEPOSITO LIQUIDO DIRECCION",
          "idcrm": 1264,
          
        },
        {
          "nombre": "DEPOSITO LIQUIDO DIRECCION ASISTIDA",
          "idcrm": 1264,
          
        },
        {
          "nombre": "DEPOSITO LIQUIDO FRENO",
          "idcrm": 1265,
          
        },
        {
          "nombre": "DEPOSITO SUSPENSION",
          "idcrm": 482,
          
        },
        {
          "nombre": "DEPOSITO VAC�O",
          "idcrm": 1268,
          
        },
        {
          "nombre": "DEPRESOR DE ACEITE",
          "idcrm": 1267,
          
        },
        {
          "nombre": "DEPRESOR DE FRENO",
          "idcrm": 483,
          
        },
        {
          "nombre": "DEPRESOR FRENO",
          "idcrm": 483,
          
        },
        {
          "nombre": "DEPRESOR GASES",
          "idcrm": 483,
          
        },
        {
          "nombre": "DERIVABRISAS",
          
          "Familia": 4
        },
        {
          "nombre": "DESBLOQUEADOR INYECTORES",
          "idcrm": 15,
          
        },
        {
          "nombre": "DESBLOQUEADORES RUEDA 4X4",
          "idcrm": 15,
          
        },
        {
          "nombre": "DESCARBONIZANTE",
          "idcrm": 15,
          
        },
        {
          "nombre": "DESCONECTOR BATERIAS",
          "idcrm": 1269,
          
        },
        {
          "nombre": "DESCONGELADOR PARABRISAS",
          "idcrm": 15,
          
        },
        {
          "nombre": "DESPIECE CAJA TRANSFER",
          "idcrm": 56,
          
        },
        {
          "nombre": "DESRON II-PARA CAMBIOS AUTOMATICOS",
          "idcrm": 57,
          
        },
        {
          "nombre": "DESTORNILLADOR",
          "idcrm": 15,
          
        },
        {
          "nombre": "DESTORNILLADOR GOLPE",
          "idcrm": 15,
          
        },
        {
          "nombre": "DESTORNILLADOR MAURER",
          "idcrm": 15,
          
        },
        {
          "nombre": "DETECTOR DE RADAR",
          "idcrm": 1192,
          
        },
        {
          "nombre": "DIELECTRICO",
          "idcrm": 15,
          
        },
        {
          "nombre": "DIFERENCIAL TRASERO ELECTRICO",
          "idcrm": 60,
          
        },
        {
          "nombre": "DIFUSOR",
          "idcrm": 144,
          
        },
        {
          "nombre": "DIFUSOR AGUA CAPOT",
          "idcrm": 144,
          
        },
        {
          "nombre": "DIFUSOR PARAGOLPES",
          "idcrm": 144,
          
        },
        {
          "nombre": "DIFUSOR TRASERO",
          "idcrm": 144,
          
        },
        {
          "nombre": "DIRECCION ASISTIDA",
          "idcrm": 1157,
          
        },
        {
          "nombre": "DISCO DE EMBRAGUE",
          "idcrm": 1272,
          
        },
        {
          "nombre": "DISCO DE FRENO",
          "idcrm": 484,
          
        },
        {
          "nombre": "DISCO DIAMANTE",
          "idcrm": 15,
          
        },
        {
          "nombre": "DISCO MATERIALES",
          "idcrm": 15,
          
        },
        {
          "nombre": "DISCO WOLFPACK",
          "idcrm": 15,
          
        },
        {
          "nombre": "DISCOS FRENOS DELANTEROS",
          "idcrm": 484,
          
        },
        {
          "nombre": "DISIPADOR CALOR",
          "idcrm": 384,
          
        },
        {
          "nombre": "DISIPADOR DE CALOR",
          "idcrm": 384,
          
        },
        {
          "nombre": "DISPLAY",
          "idcrm": 1463,
          
        },
        {
          "nombre": "DISPLAY CENTRAL",
          "idcrm": 1463,
          
        },
        {
          "nombre": "DISPOSITIVO DE CONTROL VALVULA SOLENOIDE",
          "idcrm": 1192,
          
        },
        {
          "nombre": "DISPOSITIVO ECO-CARS",
          "idcrm": 15,
          
        },
        {
          "nombre": "DISPOSITIVO MEC�NICO ANTI-OSCILACI�N",
          
          "Familia": 13
        },
        {
          "nombre": "DISPOSITIVO TENSOR",
          "idcrm": 435,
          
        },
        {
          "nombre": "DISTRIBUIDOR",
          "idcrm": 252,
          
        },
        {
          "nombre": "DISTRIBUIDOR ACEITE HIDRAULICO",
          "idcrm": 437,
          
        },
        {
          "nombre": "EGR",
          "idcrm": 445,
          
        },
        {
          "nombre": "EJE BALANCEADOR CORTO",
          "idcrm": 390,
          
        },
        {
          "nombre": "EJE BALANCEADOR LARGO",
          "idcrm": 390,
          
        },
        {
          "nombre": "EJE TRANSMISION TRASERO",
          "idcrm": 219,
          
        },
        {
          "nombre": "EJE TRASERO",
          "idcrm": 219,
          
        },
        {
          "nombre": "ELECTROIMAN",
          
          "Familia": 9
        },
        {
          "nombre": "ELECTROVALVULA DE PARE",
          "idcrm": 1277,
          
        },
        {
          "nombre": "ELECTROVALVULA EGR",
          "idcrm": 1277,
          
        },
        {
          "nombre": "ELECTROVALVULA TURBO",
          "idcrm": 1277,
          
        },
        {
          "nombre": "ELECTROVALVULAS TRACCION",
          "idcrm": 1277,
          
        },
        {
          "nombre": "ELECTROVENTILADOR AIRE ACONDICIONADO / AUXILIAR",
          "idcrm": 253,
          
        },
        {
          "nombre": "ELECTROVENTILADOR CAJA FUSIBLES",
          "idcrm": 253,
          
        },
        {
          "nombre": "ELECTROVENTILADOR INTERCOOLER",
          "idcrm": 253,
          
        },
        {
          "nombre": "ELEVADOR RUEDA REPUESTO",
          
          "Familia": 10
        },
        {
          "nombre": "ELEVALUNAS DELANTERO DERECHO ELECTRICO",
          "idcrm": 122,
          
        },
        {
          "nombre": "ELEVALUNAS DELANTERO DERECHO MANUAL",
          "idcrm": 122,
          
        },
        {
          "nombre": "ELEVALUNAS DELANTERO IZQUIERDO ELECTRICO",
          "idcrm": 123,
          
        },
        {
          "nombre": "ELEVALUNAS DELANTERO IZQUIERDO MANUAL",
          "idcrm": 123,
          
        },
        {
          "nombre": "ELEVALUNAS ELECTRICO DERECHO",
          "idcrm": 122,
          
        },
        {
          "nombre": "ELEVALUNAS ELECTRICO IZQUIERDO",
          "idcrm": 123,
          
        },
        {
          "nombre": "ELEVALUNAS ELECTRICO TRASERO DE",
          "idcrm": 1281,
          
        },
        {
          "nombre": "ELEVALUNAS ELECTRICO TRASERO IZQ",
          "idcrm": 1282,
          
        },
        {
          "nombre": "ELEVALUNAS MANUAL DELANTERO DE",
          "idcrm": 122,
          
        },
        {
          "nombre": "ELEVALUNAS MANUAL DELANTERO IZQ",
          "idcrm": 123,
          
        },
        {
          "nombre": "ELEVALUNAS MANUAL TRASERO DE",
          "idcrm": 1281,
          
        },
        {
          "nombre": "ELEVALUNAS MANUAL TRASERO IZQ",
          "idcrm": 1282,
          
        },
        {
          "nombre": "ELEVALUNAS PORTON",
          "idcrm": 124,
          
        },
        {
          "nombre": "ELEVALUNAS TRASERO",
          "idcrm": 125,
          
        },
        {
          "nombre": "ELEVALUNAS TRASERO DERECHO ELECTRICO",
          "idcrm": 1281,
          
        },
        {
          "nombre": "ELEVALUNAS TRASERO DERECHO MANUAL",
          "idcrm": 1281,
          
        },
        {
          "nombre": "ELEVALUNAS TRASERO IZQUIERDO ELECTRICO",
          "idcrm": 1282,
          
        },
        {
          "nombre": "ELEVALUNAS TRASERO IZQUIERDO MANUAL",
          "idcrm": 1282,
          
        },
        {
          "nombre": "EMBELLECEDOR COLUMNA DIRECCION",
          "idcrm": 144,
          
        },
        {
          "nombre": "EMBELLECEDOR CONSOLA CENTRAL",
          "idcrm": 144,
          
        },
        {
          "nombre": "EMBELLECEDOR CONSOLA SALPICADERO",
          "idcrm": 144,
          
        },
        {
          "nombre": "EMBELLECEDOR CUADRO",
          "idcrm": 144,
          
        },
        {
          "nombre": "EMBELLECEDOR CUADRO INSTRUMENTOS",
          "idcrm": 144,
          
        },
        {
          "nombre": "EMBELLECEDOR DE ESPEJO",
          "idcrm": 144,
          
        },
        {
          "nombre": "EMBELLECEDOR LATERAL",
          "idcrm": 144,
          
        },
        {
          "nombre": "EMBELLECEDOR PALANCA CAMBIO",
          "idcrm": 144,
          
        },
        {
          "nombre": "EMBELLECEDOR PALANCA DE CAMBIO",
          "idcrm": 144,
          
        },
        {
          "nombre": "EMBELLECEDOR PILOTOS TRASEROS",
          "idcrm": 144,
          
        },
        {
          "nombre": "EMBELLECEDOR SALPICADERO",
          "idcrm": 144,
          
        },
        {
          "nombre": "EMBLEMA",
          
          "Familia": 4
        },
        {
          "nombre": "EMBLEMA DELANTERO",
          
          "Familia": 4
        },
        {
          "nombre": "EMBLEMA LOGO",
          
          "Familia": 4
        },
        {
          "nombre": "EMBLEMA TRASERO",
          
          "Familia": 6
        },
        {
          "nombre": "EMBRAGE AUTOMATICO",
          "idcrm": 1272,
          
        },
        {
          "nombre": "EMBRAGUE(MAZA-DISCO)",
          "idcrm": 1272,
          
        },
        {
          "nombre": "EMISORA",
          "idcrm": 290,
          
        },
        {
          "nombre": "EMISORA MOVIL",
          "idcrm": 290,
          
        },
        {
          "nombre": "EMPU�ADURA",
          "idcrm": 413,
          
        },
        {
          "nombre": "EMULADOR",
          "idcrm": 1192,
          
        },
        {
          "nombre": "ENFRIADOR ACEITE",
          "idcrm": 414,
          
        },
        {
          "nombre": "ENFRIADOR ACEITE CAMBIO",
          "idcrm": 414,
          
        },
        {
          "nombre": "ENFRIADOR CAJA CAMBIOS",
          "idcrm": 414,
          
        },
        {
          "nombre": "ENFRIADOR DE GASOIL",
          "idcrm": 1509,
          
        },
        {
          "nombre": "ENFRIADOR GASES ESCAPE",
          "idcrm": 392,
          
        },
        {
          "nombre": "ENFRIADOR LIQUIDO DIRECCION",
          "idcrm": 210,
          
        },
        {
          "nombre": "ENFRIADOR VALVULA EGR",
          "idcrm": 392,
          
        },
        {
          "nombre": "ENGANCHE CABINA TRASERA",
          "idcrm": 1293,
          
        },
        {
          "nombre": "ENGANCHE CABINA TRASERO DERECHO",
          "idcrm": 1293,
          
        },
        {
          "nombre": "ENGANCHE CABINA TRASERO IZQUIEROD",
          "idcrm": 1293,
          
        },
        {
          "nombre": "ENGANCHE CINTURON DELANTERO CENTRAL",
          "idcrm": 1293,
          
        },
        {
          "nombre": "ENGANCHE DE CINTURON D.D",
          "idcrm": 1293,
          
        },
        {
          "nombre": "ENGANCHE DE CINTURON D.I",
          "idcrm": 1293,
          
        },
        {
          "nombre": "ENGANCHE DE CINTURON T.C",
          "idcrm": 1293,
          
        },
        {
          "nombre": "ENGANCHE DE CINTURON T.D.",
          "idcrm": 1293,
          
        },
        {
          "nombre": "ENGANCHE DE CINTURON T.I",
          "idcrm": 1293,
          
        },
        {
          "nombre": "ENGANCHE MALETERO",
          "idcrm": 1293,
          
        },
        {
          "nombre": "ENGANCHE RUEDA REPUESTO",
          "idcrm": 1293,
          
        },
        {
          "nombre": "ENGANCHE TRASERO CENTRAL",
          "idcrm": 1293,
          
        },
        {
          "nombre": "ENGRASADOR AUTOMATICO",
          
          "Familia": 11
        },
        {
          "nombre": "ENGRASADOR INTERIOR DE PISTON",
          
          "Familia": 11
        },
        {
          "nombre": "EQUILIBRADOR CIGUE�AL",
          "idcrm": 378,
          
        },
        {
          "nombre": "EQUILIBRADOR MOTOR",
          "idcrm": 378,
          
        },
        {
          "nombre": "EQUIPO DE FRIO",
          "idcrm": 186,
          
        },
        {
          "nombre": "ESCOBILLA ATEHI HIERRO 35 CM - 14''",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA ATEHI HIERRO 38 CM - 15''",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA ATEHI HIERRO 41 CM - 16''",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA ATEHI HIERRO 43 CM - 17''",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA ATEHI HIERRO 51 CM - 20''",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA GOMA ACMA 350 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA GOMA ACMA 410 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA GOMA ACMA 430 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA GOMA ACMA 450 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA GOMA ACMA 480 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA GOMA ACMA 500 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA GOMA ACMA 510 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA GOMA ACMA 525 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA GOMA ACMA 530 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA GOMA ACMA 550 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA GOMA ACMA 580 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA GOMA ACMA 600 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA GOMA ACMA 650 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA GOMA ACMA 700 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA GOMA ACMA 760 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA HIERRO ACMA 410 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA HIERRO ACMA 430 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA HIERRO ACMA 450 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA HIERRO ACMA 480 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA HIERRO ACMA 510 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA HIERRO ACMA 530 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA HIERRO ACMA 550 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA HIERRO ACMA 600 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA HIERRO ACMA 650 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA HIERRO ACMA 700 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA LIMPIA 410 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA LIMPIA 450 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA LIMPIA 550",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA LIMPIA 600",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA LIMPIA METALICA ANKO  35 CMS",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA LIMPIA METALICA ANKO 48 CMS",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA LIMPIA METALICA ANKO 51 CMS",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA LIMPIA METALICA ANKO 53 CMS",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA LIMPIA METALICA ANKO 55 CMS",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA LIMPIA METALICA ANKO 60 CMS",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA NEOVISION 380 M.M.",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA NEOVISION 480",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA PLANA F. PLUS",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLA TRASERA ACMA 305 MM",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCOBILLAS GY ULTIMA",
          "idcrm": 12,
          
        },
        {
          "nombre": "ESCUDO DELANTERO",
          "idcrm": 15,
          
        },
        {
          "nombre": "ESFERA DE SUSPENCION",
          "idcrm": 482,
          
        },
        {
          "nombre": "ESFERA DE SUSPENSION",
          "idcrm": 482,
          
        },
        {
          "nombre": "ESFERA DE SUSPENSION DELANTERO",
          "idcrm": 482,
          
        },
        {
          "nombre": "ESFERA DE SUSPENSION TRASERO",
          "idcrm": 482,
          
        },
        {
          "nombre": "ESFERA DELANTERA DERECHA",
          "idcrm": 482,
          
        },
        {
          "nombre": "ESFERA DELANTERA IZQUIERDA",
          "idcrm": 482,
          
        },
        {
          "nombre": "ESFERA DERECHA SUSPENSION DELANTERO",
          "idcrm": 482,
          
        },
        {
          "nombre": "ESFERA SUSPENSION",
          "idcrm": 482,
          
        },
        {
          "nombre": "ESFERA SUSPENSION DEL DERE",
          "idcrm": 482,
          
        },
        {
          "nombre": "ESFERA SUSPENSION DEL IZ",
          "idcrm": 482,
          
        },
        {
          "nombre": "ESFERA SUSPENSION DELANTERA",
          "idcrm": 482,
          
        },
        {
          "nombre": "ESFERA SUSPENSION TRASERA",
          "idcrm": 482,
          
        },
        {
          "nombre": "ESFERA TRASERA CENTRAL",
          "idcrm": 482,
          
        },
        {
          "nombre": "ESPEJO",
          "idcrm": 327,
          
        },
        {
          "nombre": "ESPEJO UNIVERSAL",
          "idcrm": 327,
          
        },
        {
          "nombre": "ESPONJA MICROMAGIC",
          "idcrm": 15,
          
        },
        {
          "nombre": "ESPUMA POLIUR.WOLFPACK",
          "idcrm": 15,
          
        },
        {
          "nombre": "ESTABILIZADOR DE VISCOSIDAD",
          "idcrm": 15,
          
        },
        {
          "nombre": "ESTRIBERAS",
          "idcrm": 126,
          
        },
        {
          "nombre": "ESTUCHE BROCAS",
          "idcrm": 15,
          
        },
        {
          "nombre": "ETAPA POTENCIA",
          "idcrm": 1306,
          
        },
        {
          "nombre": "EVAPORADOR ACEITE",
          "idcrm": 437,
          
        },
        {
          "nombre": "EVAPORADOR AIRE ACOND.",
          "idcrm": 437,
          
        },
        {
          "nombre": "EXPORTAVEHICULO",
          
          "Familia": 13
        },
        {
          "nombre": "EXTENSION DE BANDEJA",
          "idcrm": 317,
          
        },
        {
          "nombre": "EYECTOR LAVAFAROS DERECHO",
          "idcrm": 1342,
          
        },
        {
          "nombre": "EYECTOR LAVAFAROS IZQUIERDO",
          "idcrm": 1342,
          
        },
        {
          "nombre": "EYECTOR LIMPIA PARABRISAS",
          "idcrm": 1370,
          
        },
        {
          "nombre": "FALDILLA DELANTERA DERECHA",
          "idcrm": 88,
          
        },
        {
          "nombre": "FALDILLA DELANTERA IZQUIERDA",
          "idcrm": 88,
          
        },
        {
          "nombre": "FALDILLA TRASERA DERECHA",
          "idcrm": 167,
          
        },
        {
          "nombre": "FALDILLA TRASERA IZQUIERDA",
          "idcrm": 167,
          
        },
        {
          "nombre": "FALDON LATERAL DCH (CORTE)",
          "idcrm": 167,
          
        },
        {
          "nombre": "FARO ANTINIEBLA",
          "idcrm": 29,
          
        },
        {
          "nombre": "FARO ANTINIEBLA DELANTERO",
          "idcrm": 29,
          
        },
        {
          "nombre": "FARO CENTRAL",
          "idcrm": 33,
          
        },
        {
          "nombre": "FARO DELANTERO DCHO",
          "idcrm": 34,
          
        },
        {
          "nombre": "FARO DELANTERO DERECHO XENON",
          "idcrm": 34,
          
        },
        {
          "nombre": "FARO DELANTERO IZQUIERDO",
          "idcrm": 35,
          
        },
        {
          "nombre": "FARO DELANTERO IZQUIERDO XENON",
          "idcrm": 35,
          
        },
        {
          "nombre": "FARO INTERIOR DERECHO",
          "idcrm": 34,
          
        },
        {
          "nombre": "FARO LARGO ALCANCE",
          "idcrm": 34,
          
        },
        {
          "nombre": "FARO LARGO ALCANCE DERECHO",
          "idcrm": 34,
          
        },
        {
          "nombre": "FARO LARGO ALCANCE IZQUIERDO",
          "idcrm": 35,
          
        },
        {
          "nombre": "FARO LUZ CORTA IZQUIERDO",
          "idcrm": 35,
          
        },
        {
          "nombre": "FARO LUZ DIURNA DERECHO",
          "idcrm": 35,
          
        },
        {
          "nombre": "FARO LUZ DIURNA IZQUIERDO",
          "idcrm": 35,
          
        },
        {
          "nombre": "FARO LUZ LARGA IZQUIERDO",
          "idcrm": 35,
          
        },
        {
          "nombre": "FARO SIN MANO",
          "idcrm": 35,
          
        },
        {
          "nombre": "FARO UNIVERSAL",
          "idcrm": 35,
          
        },
        {
          "nombre": "FARO XENON DERECHO",
          "idcrm": 34,
          
        },
        {
          "nombre": "FARO XENON IZQUIERDO",
          "idcrm": 35,
          
        },
        {
          "nombre": "FILTRO AIRE SECUNDARIO",
          "idcrm": 394,
          
        },
        {
          "nombre": "FILTRO ANTIPARTICULAS",
          "idcrm": 396,
          
        },
        {
          "nombre": "FILTRO CONDENSADOR",
          "idcrm": 13,
          
        },
        {
          "nombre": "FILTRO DESHIDRATANTE AIRE ACONDICIONADO",
          "idcrm": 13,
          
        },
        {
          "nombre": "FILTRO SECADOR AIRE ACONDICIONADO",
          "idcrm": 13,
          
        },
        {
          "nombre": "FILTRO SECADOR DE AIRE",
          "idcrm": 13,
          
        },
        {
          "nombre": "FILTRO SECANTE AIRE ACONDICIONADO",
          "idcrm": 13,
          
        },
        {
          "nombre": "FILTROS",
          "idcrm": 13,
          
        },
        {
          "nombre": "FILTROS ACEITE",
          "idcrm": 393,
          
        },
        {
          "nombre": "FILTROS AIRE",
          "idcrm": 394,
          
        },
        {
          "nombre": "FILTROS COOPERS FIAAM",
          "idcrm": 13,
          
        },
        {
          "nombre": "FLEXIBLE",
          "idcrm": 1617,
          
        },
        {
          "nombre": "FLEXIBLE ESCAPE",
          "idcrm": 1617,
          
        },
        {
          "nombre": "FLEXOMETRO AMARILLO",
          "idcrm": 13,
          
        },
        {
          "nombre": "FLEXOMETRO GRIP. TOPLINE",
          "idcrm": 13,
          
        },
        {
          "nombre": "FLEXOMETRO GRIP.TOPLINE",
          "idcrm": 13,
          
        },
        {
          "nombre": "FLEXOMETRO SPECIALLIST",
          "idcrm": 13,
          
        },
        {
          "nombre": "FORMADOR DE JUNTAS NEGRO 200ML",
          "idcrm": 13,
          
        },
        {
          "nombre": "FORMULA HORQUILLAS",
          "idcrm": 13,
          
        },
        {
          "nombre": "FRENO DE PUERTA",
          
          "Familia": 5
        },
        {
          "nombre": "FRENO DE PUERTA DD",
          
          "Familia": 5
        },
        {
          "nombre": "FRENO DE PUERTA TRASERA",
          
          "Familia": 5
        },
        {
          "nombre": "FRENO DE PUERTA TRASERO DERECHO",
          
          "Familia": 5
        },
        {
          "nombre": "FRENO DE PUERTA TRASERO IZQUIERDO",
          
          "Familia": 5
        },
        {
          "nombre": "FRENO DE TAMBOR",
          "idcrm": 487,
          
        },
        {
          "nombre": "FRENO MANO ELECTRICO",
          "idcrm": 1335,
          
        },
        {
          "nombre": "FRENO PUERTA",
          
          "Familia": 5
        },
        {
          "nombre": "FRENO PUERTA DELANTERO DERECHO",
          
          "Familia": 5
        },
        {
          "nombre": "FRENO PUERTA DELANTERO IZQUIERDO",
          
          "Familia": 5
        },
        {
          "nombre": "FRENO PUERTA DER DELANT",
          
          "Familia": 5
        },
        {
          "nombre": "FRENO PUERTA IZQ DELANT",
          
          "Familia": 5
        },
        {
          "nombre": "FRENO PUERTA TRASERO DERECHO",
          
          "Familia": 5
        },
        {
          "nombre": "FRENO PUERTA TRASERO IZQUIERDO",
          
          "Familia": 5
        },
        {
          "nombre": "FRENTE DE FIBRA",
          "idcrm": 1313,
          
        },
        {
          "nombre": "FRIO ISOTERMO",
          
          "Familia": 13
        },
        {
          "nombre": "FRONTAL DELANTERO",
          "idcrm": 1313,
          
        },
        {
          "nombre": "FUELLE",
          "idcrm": 212,
          
        },
        {
          "nombre": "FUELLE CREMALLERA",
          "idcrm": 212,
          
        },
        {
          "nombre": "FUELLE FRENO DE MANO",
          "idcrm": 212,
          
        },
        {
          "nombre": "FUELLE SUSPENSION NEUMATICA",
          "idcrm": 212,
          
        },
        {
          "nombre": "FUNDA",
          "idcrm": 212,
          
        },
        {
          "nombre": "FUNDA ASIENTO",
          
          "Familia": 10
        },
        {
          "nombre": "FUNDA ASIENTO INDIVIDUAL",
          
          "Familia": 10
        },
        {
          "nombre": "FUNDA DE PALANCA DE MARCHAS",
          "idcrm": 212,
          
        },
        {
          "nombre": "FUNDA FRENO DE MANO",
          "idcrm": 212,
          
        },
        {
          "nombre": "FUNDA FRENO MANO",
          "idcrm": 212,
          
        },
        {
          "nombre": "FUNDA PALANCA CAMBIO",
          "idcrm": 212,
          
        },
        {
          "nombre": "FUNDA PALANCA FRENO MANO",
          "idcrm": 212,
          
        },
        {
          "nombre": "FUNDA TEXTIL ANTIDESLIZANTE",
          
          "Familia": 10
        },
        {
          "nombre": "FUNDA VOLANTE",
          
          "Familia": 10
        },
        {
          "nombre": "FUNDA VOLANTE FURGONETA",
          
          "Familia": 10
        },
        {
          "nombre": "FUNDA VOLANTE NEGRA COSIDA",
          
          "Familia": 10
        },
        {
          "nombre": "FUNDA VOLANTE TURISMO",
          
          "Familia": 10
        },
        {
          "nombre": "FUSIBLE BATERIA",
          "idcrm": 1162,
          
        },
        {
          "nombre": "GAFAS PROTEC.EN166",
          "idcrm": 15,
          
        },
        {
          "nombre": "GENERICA",
          "idcrm": 15,
          
        },
        {
          "nombre": "GOMA BARRA ESTABILIZADORA",
          "idcrm": 212,
          
        },
        {
          "nombre": "GOMA CONTORNO PUERTA DELANTERA IZQUIERDA",
          "idcrm": 128,
          
        },
        {
          "nombre": "GOMA DE CONTORNO",
          "idcrm": 128,
          
        },
        {
          "nombre": "GOMA ESTRIBERA",
          "idcrm": 128,
          
        },
        {
          "nombre": "GOMA PORTON",
          "idcrm": 128,
          
        },
        {
          "nombre": "GOMA PUERTA DELANTERA DERECHA",
          "idcrm": 128,
          
        },
        {
          "nombre": "GOMA PUERTA DELANTERA IZQUIERDA",
          "idcrm": 128,
          
        },
        {
          "nombre": "GOMAS CONTORNO CAJA",
          "idcrm": 128,
          
        },
        {
          "nombre": "GRASA",
          "idcrm": 15,
          
        },
        {
          "nombre": "GRUPO DIFERENCIAL",
          "idcrm": 60,
          
        },
        {
          "nombre": "GRUPO DIFERENCIAL DEL.",
          "idcrm": 1320,
          
        },
        {
          "nombre": "GRUPO DIFERENCIAL DELANTERO",
          "idcrm": 1320,
          
        },
        {
          "nombre": "GRUPO DIFERENCIAL TRA.",
          "idcrm": 60,
          
        },
        {
          "nombre": "GRUPO DIFERENCIAL TRASERO",
          "idcrm": 60,
          
        },
        {
          "nombre": "GRUPO TRASERO",
          "idcrm": 60,
          
        },
        {
          "nombre": "GUANTE NYLON POLIURETANO",
          "idcrm": 15,
          
        },
        {
          "nombre": "GUANTE PIEL",
          "idcrm": 15,
          
        },
        {
          "nombre": "GUANTE POLIURETANO/NYLON",
          "idcrm": 15,
          
        },
        {
          "nombre": "GUANTERA SALPICADERO",
          "idcrm": 328,
          
        },
        {
          "nombre": "GUANTES DESECHABLES NITRILO",
          "idcrm": 15,
          
        },
        {
          "nombre": "GUARDABARRO TRASERO DERECHO",
          "idcrm": 89,
          
        },
        {
          "nombre": "GUARDABARRO TRASERO IZQUIERDO",
          "idcrm": 89,
          
        },
        {
          "nombre": "GUARDABARROS DELANTERO",
          "idcrm": 89,
          
        },
        {
          "nombre": "GUARDABARROS DELANTERO DERECHO",
          "idcrm": 89,
          
        },
        {
          "nombre": "GUARDAMANO",
          "idcrm": 89,
          
        },
        {
          "nombre": "GUARDAPOLVO PALANCA CAMBIO",
          "idcrm": 212,
          
        },
        {
          "nombre": "GUARDAPOLVOS",
          "idcrm": 212,
          
        },
        {
          "nombre": "GUARNECIDO LATERAL INTERIOR MALETERO",
          "idcrm": 1324,
          
        },
        {
          "nombre": "GUARNECIDO MALETERO",
          "idcrm": 1324,
          
        },
        {
          "nombre": "GUARNECIDO PALANCA CAMBIO",
          "idcrm": 334,
          
        },
        {
          "nombre": "GUARNECIDO PALANCA FRENO MANO",
          "idcrm": 334,
          
        },
        {
          "nombre": "GUARNECIDO PASE RUEDA IZQUIERDA",
          "idcrm": 89,
          
        },
        {
          "nombre": "GUARNECIDO PORTON",
          "idcrm": 1324,
          
        },
        {
          "nombre": "GUARNECIDO PROTECTOR MOTOR",
          "idcrm": 1588,
          
        },
        {
          "nombre": "GUARNECIDO SALPICADERO INTERIOR",
          "idcrm": 144,
          
        },
        {
          "nombre": "GUARNECIDOS INTERIORES",
          "idcrm": 332,
          
        },
        {
          "nombre": "GUIA AMORTIGUADOR",
          "idcrm": 452,
          
        },
        {
          "nombre": "GUIA RESBALON",
          "idcrm": 1325,
          
        },
        {
          "nombre": "GUIA VARILLA ACEITE",
          "idcrm": 447,
          
        },
        {
          "nombre": "GUIA VARILLA ACEITE VOLKWAGEN",
          "idcrm": 447,
          
        },
        {
          "nombre": "GULF ENERGY SAVING 5W-30 5L",
          "idcrm": 28,
          
        },
        {
          "nombre": "GULF SUPER S 10W-40 5L",
          "idcrm": 28,
          
        },
        {
          "nombre": "HEAD UP",
          "idcrm": 1463,
          
        },
        {
          "nombre": "HIDRAULICO CAPOTA",
          "idcrm": 74,
          
        },
        {
          "nombre": "HIDROLIMPIADORA YAMATO",
          "idcrm": 15,
          
        },
        {
          "nombre": "HOJA CUCHILLO",
          "idcrm": 15,
          
        },
        {
          "nombre": "HOJA CUTTER",
          "idcrm": 15,
          
        },
        {
          "nombre": "HOJA SIERRA",
          "idcrm": 15,
          
        },
        {
          "nombre": "HORQUILLA",
          "idcrm": 1097,
          
        },
        {
          "nombre": "HORQUILLA BRAZO IZQUIERDA",
          "idcrm": 1097,
          
        },
        {
          "nombre": "INDICADOR CAMBIO AUTOMATICO",
          "idcrm": 240,
          
        },
        {
          "nombre": "INDICADOR OPTICA",
          "idcrm": 1321,
          
        },
        {
          "nombre": "INMOVILIZADOR",
          "idcrm": 244,
          
        },
        {
          "nombre": "INSTALACION ELECTRICA",
          "idcrm": 1150,
          
        },
        {
          "nombre": "INSTALACION ELECTRICA DE MOTOR",
          "idcrm": 1150,
          
        },
        {
          "nombre": "INSTALACION ELECTRICA DEL SALPICADERO",
          "idcrm": 1150,
          
        },
        {
          "nombre": "INSTALACION ELECTRICA MOTOR",
          "idcrm": 1150,
          
        },
        {
          "nombre": "INSTALACION MOTOR",
          "idcrm": 1150,
          
        },
        {
          "nombre": "INSTLACION ELECTRICA",
          "idcrm": 1150,
          
        },
        {
          "nombre": "INSULATOR",
          
          "Familia": 10
        },
        {
          "nombre": "INTERCAMBIADOR AGUA CALEFACION",
          "idcrm": 197,
          
        },
        {
          "nombre": "INTERCAMBIADOR AGUA GASES",
          "idcrm": 392,
          
        },
        {
          "nombre": "INTERCAMBIADOR DE AGUA-GASES",
          "idcrm": 392,
          
        },
        {
          "nombre": "INTERMITENCIA ALETA DCH",
          "idcrm": 1474,
          
        },
        {
          "nombre": "INTERMITENCIA ALETA IZQ",
          "idcrm": 1474,
          
        },
        {
          "nombre": "INTERMITENTE DELANTERO DCHO",
          "idcrm": 1475,
          
        },
        {
          "nombre": "INTERMITENTE DELANTERO IZQUIERDO",
          "idcrm": 1475,
          
        },
        {
          "nombre": "INTERMITENTE DERECHO",
          "idcrm": 1475,
          
        },
        {
          "nombre": "INTERMITENTE IZQUIERDO",
          "idcrm": 1476,
          
        },
        {
          "nombre": "INTERMITENTE RETROVISOR IZQUIERDO",
          "idcrm": 1476,
          
        },
        {
          "nombre": "INTERRUPTOR  LUNA TERMICA",
          "idcrm": 254,
          
        },
        {
          "nombre": "INTERRUPTOR AIRBAG ACOMPA�ANTE",
          "idcrm": 254,
          
        },
        {
          "nombre": "INTERRUPTOR AIRBAG DEL. DER.",
          "idcrm": 254,
          
        },
        {
          "nombre": "INTERRUPTOR ALUMBRADO",
          "idcrm": 254,
          
        },
        {
          "nombre": "INTERRUPTOR APERTURA COMBUSTIBLE",
          "idcrm": 254,
          
        },
        {
          "nombre": "INTERRUPTOR APERTURA MALETERO",
          "idcrm": 254,
          
        },
        {
          "nombre": "INTERRUPTOR APERTURA TECHO SOLAR",
          "idcrm": 254,
          
        },
        {
          "nombre": "INTERRUPTOR AVERIAS",
          "idcrm": 254,
          
        },
        {
          "nombre": "INTERRUPTOR CALEFACCION LUNA TRASERA",
          "idcrm": 254,
          
        },
        {
          "nombre": "INTERRUPTOR CIERRE CENTRALIZADO",
          "idcrm": 254,
          
        },
        {
          "nombre": "INTERRUPTOR CIERRE PUERTAS",
          "idcrm": 254,
          
        },
        {
          "nombre": "INTERRUPTOR DE INERCIA",
          "idcrm": 254,
          
        },
        {
          "nombre": "INTERRUPTOR DEL ELEVALUNAS",
          "idcrm": 254,
          
        },
        {
          "nombre": "INTERRUPTOR LUCES EMERGENCIA",
          "idcrm": 254,
          
        },
        {
          "nombre": "INTERRUPTOR LUZ ANTINIEBLA",
          "idcrm": 254,
          
        },
        {
          "nombre": "INTERRUPTOR REGULACION ALTURA FAROS",
          "idcrm": 254,
          
        },
        {
          "nombre": "INTERRUPTOR TECHO",
          "idcrm": 254,
          
        },
        {
          "nombre": "INTERUPTOR ASIENTO CALEFACTABLE",
          "idcrm": 254,
          
        },
        {
          "nombre": "INVERSOR",
          "idcrm": 1192,
          
        },
        {
          "nombre": "INVERTER",
          "idcrm": 1192,
          
        },
        {
          "nombre": "INVERTER ELECTRICO",
          "idcrm": 1192,
          
        },
        {
          "nombre": "INVERTER/CONVERTER",
          "idcrm": 1192,
          
        },
        {
          "nombre": "INVERTIDOR",
          "idcrm": 1192,
          
        },
        {
          "nombre": "INYECTOR PILOTO",
          "idcrm": 400,
          
        },
        {
          "nombre": "JGO ALFOMBRAS MOQUETA NEUTRA",
          "idcrm": 1343,
          
        },
        {
          "nombre": "JGO. CADENAS NIEVE",
          "idcrm": 9,
          
        },
        {
          "nombre": "JGO. DISCOS FRENO DEL.",
          "idcrm": 484,
          
        },
        {
          "nombre": "JGO. FUNDAS",
          "idcrm": 15,
          
        },
        {
          "nombre": "JGO. JUNTA CULATA",
          "idcrm": 1352,
          
        },
        {
          "nombre": "JGO. SOPORTES BARRAS",
          "idcrm": 1094,
          
        },
        {
          "nombre": "JGO. TAPACUBOS RACING 16",
          "idcrm": 1351,
          
        },
        {
          "nombre": "JGO. TRIANGULOS AVERIA",
          "idcrm": 26,
          
        },
        {
          "nombre": "JGO.DESTORNILL.MAURER 8",
          "idcrm": 15,
          
        },
        {
          "nombre": "JGO.DESTORNILL.MAURER PRECIS.PROF.6PZAS.",
          "idcrm": 15,
          
        },
        {
          "nombre": "JGO.DESTORNILL.PRECIS UTILEX",
          "idcrm": 15,
          
        },
        {
          "nombre": "JGO.FRESAS P/AMOLADORA",
          "idcrm": 15,
          
        },
        {
          "nombre": "JGO.LLAVES ALLEN",
          "idcrm": 15,
          
        },
        {
          "nombre": "JGO.LLAVES COMBINADAS",
          "idcrm": 15,
          
        },
        {
          "nombre": "JGO.LLAVES FIJAS",
          "idcrm": 15,
          
        },
        {
          "nombre": "JGO.LLAVES TORX",
          "idcrm": 15,
          
        },
        {
          "nombre": "JGO.PUNTAS MAURER",
          "idcrm": 15,
          
        },
        {
          "nombre": "JGO.PUNTAS MAURER+ADAPT.20PZ.",
          "idcrm": 15,
          
        },
        {
          "nombre": "JGO.PUNTAS MAURER+VASOS+ADAP",
          "idcrm": 15,
          
        },
        {
          "nombre": "JGO.RODILLERAS PROTECTORAS",
          "idcrm": 15,
          
        },
        {
          "nombre": "JUEGO ASIENTOS",
          "idcrm": 335,
          
        },
        {
          "nombre": "JUEGO BIELETAS SUSPENSION",
          "idcrm": 1097,
          
        },
        {
          "nombre": "JUEGO BOMBILLAS H1",
          "idcrm": 7,
          
        },
        {
          "nombre": "JUEGO BOMBILLAS H7",
          "idcrm": 7,
          
        },
        {
          "nombre": "JUEGO BOMBINES PUERTAS DELANTERAS",
          "idcrm": 129,
          
        },
        {
          "nombre": "JUEGO CABLES BUJIA",
          "idcrm": 1153,
          
        },
        {
          "nombre": "JUEGO CABLES BUJIAS",
          "idcrm": 1153,
          
        },
        {
          "nombre": "JUEGO COJINETES PUERTA CORREDERE DERECHA",
          
          "Familia": 5
        },
        {
          "nombre": "JUEGO COMPLETO DE JUNTAS MOTOR",
          "idcrm": 1356,
          
        },
        {
          "nombre": "JUEGO DE 2 CABALLETES 3 TONES 53779",
          "idcrm": 8,
          
        },
        {
          "nombre": "JUEGO DE ALTAVOCES",
          "idcrm": 1085,
          
        },
        {
          "nombre": "JUEGO DE CABLES DE BUJIAS",
          "idcrm": 1153,
          
        },
        {
          "nombre": "JUEGO DE LLANTAS",
          "idcrm": 16,
          
        },
        {
          "nombre": "JUEGO DE LLAVES DE VASO Y PUNTAS",
          "idcrm": 15,
          
        },
        {
          "nombre": "JUEGO DE MOLDURAS NUEVAS",
          "idcrm": 144,
          
        },
        {
          "nombre": "JUEGO DE PARASOLES",
          "idcrm": 350,
          
        },
        {
          "nombre": "JUEGO DE PEDALES",
          "idcrm": 352,
          
        },
        {
          "nombre": "JUEGO DE RUEDAS",
          "idcrm": 24,
          
        },
        {
          "nombre": "JUEGO DESTORNILLADORES Y LLAVE THOR",
          "idcrm": 15,
          
        },
        {
          "nombre": "JUEGO FUNDAS MODENA",
          "idcrm": 15,
          
        },
        {
          "nombre": "JUEGO LLANTAS ALUMINIO 15 PULGADAS",
          "idcrm": 16,
          
        },
        {
          "nombre": "JUEGO LLAVES  VASO  11 PIEZAS",
          "idcrm": 15,
          
        },
        {
          "nombre": "JUEGO MANDOS DIRECCION",
          "idcrm": 1382,
          
        },
        {
          "nombre": "JUEGO PASTILLAS FRENO",
          "idcrm": 493,
          
        },
        {
          "nombre": "JUEGO PEDALES COMPLETO",
          "idcrm": 1349,
          
        },
        {
          "nombre": "JUEGO REPRACION MANDO ELEVALUNAS",
          "idcrm": 1382,
          
        },
        {
          "nombre": "JUEGO TALONERAS",
          "idcrm": 1581,
          
        },
        {
          "nombre": "JUEGO TAPIZADOS",
          "idcrm": 1593,
          
        },
        {
          "nombre": "JUEGO TOBERA VENTILACION SALPICADERO",
          "idcrm": 437,
          
        },
        {
          "nombre": "JUEGO TUBERIAS INYECTORES A BOMBA",
          "idcrm": 437,
          
        },
        {
          "nombre": "JUNTA",
          "idcrm": 1356,
          
        },
        {
          "nombre": "JUNTA ANULAR TURBOCOMPRESOR",
          "idcrm": 1356,
          
        },
        {
          "nombre": "JUNTA COLECTOR DE ESCAPE",
          "idcrm": 1356,
          
        },
        {
          "nombre": "JUNTA COLESTOR DE ESCAPE",
          "idcrm": 1356,
          
        },
        {
          "nombre": "JUNTA DE CULATA",
          "idcrm": 1352,
          
        },
        {
          "nombre": "JUNTA TAPA BALANCINES",
          "idcrm": 1356,
          
        },
        {
          "nombre": "JUNTA TUBO DE ESCAPE",
          "idcrm": 1356,
          
        },
        {
          "nombre": "KIT ACCESORIOS",
          "idcrm": 382,
          
        },
        {
          "nombre": "KIT AIRE ACONDICIONADO",
          "idcrm": 1616,
          
        },
        {
          "nombre": "KIT ANTINIEBLAS",
          "idcrm": 29,
          
        },
        {
          "nombre": "KIT ARRANQUE",
          
          "Familia": 9
        },
        {
          "nombre": "KIT BOMBILLAS LEDS",
          "idcrm": 7,
          
        },
        {
          "nombre": "KIT CONVERSION LED",
          "idcrm": 7,
          
        },
        {
          "nombre": "KIT DE EMERGENCIA",
          "idcrm": 26,
          
        },
        {
          "nombre": "KIT DE FRENOS",
          "idcrm": 1355,
          
        },
        {
          "nombre": "KIT DISTRIBUCION CON BOMBA DE AGUA",
          "idcrm": 383,
          
        },
        {
          "nombre": "KIT EMBRAGUE BIMASA",
          "idcrm": 61,
          
        },
        {
          "nombre": "KIT EMBRAGUE COMPLETO",
          "idcrm": 61,
          
        },
        {
          "nombre": "KIT FILTROS",
          "idcrm": 17,
          
        },
        {
          "nombre": "KIT FILTROS KOSHIMO",
          "idcrm": 17,
          
        },
        {
          "nombre": "KIT HUMOS LIMPIA INYECTORESY ACEITE  150ML.",
          "idcrm": 15,
          
        },
        {
          "nombre": "KIT INMOVILIZADOR",
          "idcrm": 244,
          
        },
        {
          "nombre": "KIT JUNTAS",
          "idcrm": 1356,
          
        },
        {
          "nombre": "KIT JUNTAS TURBO",
          "idcrm": 1356,
          
        },
        {
          "nombre": "KIT LIMPIEZA VEHICULO 5 ARTICULOS",
          "idcrm": 15,
          
        },
        {
          "nombre": "KIT MAGNETICO 2 PILOTOS LED 52839",
          "idcrm": 38,
          
        },
        {
          "nombre": "KIT POLEA",
          "idcrm": 409,
          
        },
        {
          "nombre": "KIT REJILLAS ANTINIEBLAS",
          "idcrm": 1524,
          
        },
        {
          "nombre": "KIT REPARACION",
          "idcrm": 1359,
          
        },
        {
          "nombre": "KIT REPARACION ELEVALUNAS",
          "idcrm": 1359,
          
        },
        {
          "nombre": "KIT RESTAURACION DE FAROS",
          "idcrm": 1359,
          
        },
        {
          "nombre": "LAMPARA 1 POLO AMBAR",
          "idcrm": 7,
          
        },
        {
          "nombre": "LAMPARA 1 POLO DESCENTRADA 12V 2",
          "idcrm": 7,
          
        },
        {
          "nombre": "LAMPARA 12V 21/5W DOBLE POLO",
          "idcrm": 7,
          
        },
        {
          "nombre": "LAMPARA AMOLUX AMBAR DESCENT.1",
          "idcrm": 7,
          
        },
        {
          "nombre": "LAMPARA CU�A S/CASQUILLO 12 V",
          "idcrm": 7,
          
        },
        {
          "nombre": "LAMPARA H4 12V",
          "idcrm": 7,
          
        },
        {
          "nombre": "LAMPARA HALOGENA H1",
          "idcrm": 7,
          
        },
        {
          "nombre": "LAMPARA POSICION 2 POLOS DESCENTRADA",
          "idcrm": 7,
          
        },
        {
          "nombre": "LATA 1 LTS.REPSOL PREMIUN 5W40 SINTETICO",
          "idcrm": 28,
          
        },
        {
          "nombre": "LATA 5 LTS. REPSOL PREMIUM 5W30",
          "idcrm": 28,
          
        },
        {
          "nombre": "LATA 5L PETRONAS",
          "idcrm": 28,
          
        },
        {
          "nombre": "LATA ACEITE",
          "idcrm": 28,
          
        },
        {
          "nombre": "LATA ACEITE 5 L CASTROL",
          "idcrm": 28,
          
        },
        {
          "nombre": "LATA ACEITE 5 L. SORT 5W40 C3 100% SINTETICO",
          "idcrm": 28,
          
        },
        {
          "nombre": "LATA ACEITE 5L",
          "idcrm": 28,
          
        },
        {
          "nombre": "LATERAL DER.",
          "idcrm": 1456,
          
        },
        {
          "nombre": "LATERAL IZQ",
          "idcrm": 1457,
          
        },
        {
          "nombre": "LATIGUILLO DE FRENO TRASERO IZQUIERDO",
          "idcrm": 1364,
          
        },
        {
          "nombre": "LATIGUILLO LIQUIDO DE FRENOS",
          "idcrm": 1364,
          
        },
        {
          "nombre": "LAVA FAROS",
          "idcrm": 1366,
          
        },
        {
          "nombre": "LAVAFARO DER",
          "idcrm": 1366,
          
        },
        {
          "nombre": "LAVAFARO DERECHO",
          "idcrm": 1366,
          
        },
        {
          "nombre": "LAVAFARO IZQ",
          "idcrm": 1366,
          
        },
        {
          "nombre": "LAVAFARO IZQUIERDO",
          "idcrm": 1366,
          
        },
        {
          "nombre": "LECTOR LLAVE ARRANQUE",
          "idcrm": 1224,
          
        },
        {
          "nombre": "LECTOR NAVEGADOR",
          "idcrm": 1443,
          
        },
        {
          "nombre": "LECTOR TARJETA-LLAVE",
          "idcrm": 1224,
          
        },
        {
          "nombre": "LEVAS",
          "idcrm": 264,
          
        },
        {
          "nombre": "LEVAS VOLANTE",
          "idcrm": 264,
          
        },
        {
          "nombre": "LIBRO MANUAL DE USUARIO",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIJA VELCRO",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIJADORA YAMATO",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIMITADOR PUERTA D.D",
          "idcrm": 1102,
          
        },
        {
          "nombre": "LIMITADOR PUERTA D.I",
          "idcrm": 1102,
          
        },
        {
          "nombre": "LIMITADOR PUERTA MALETERO",
          "idcrm": 1102,
          
        },
        {
          "nombre": "LIMITADOR PUERTA T.D",
          "idcrm": 1102,
          
        },
        {
          "nombre": "LIMITADOR PUERTA T.I",
          "idcrm": 1102,
          
        },
        {
          "nombre": "LIMPIA CATALIZADOR",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIMPIA CONTACTOS",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIMPIA CRISTALES",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIMPIA INYECCION GASOLINA",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIMPIA INYECTOR MOTUL",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIMPIA INYECTORES DIESEL TRATAUTO 300ML.",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIMPIA INYECTORES DIESELTRATAUTO 150 ML.",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIMPIA PARABRISAS",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIMPIA RADIADOR",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIMPIA RADIADORES",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIMPIA VALVULAS EGR",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIMPIACRISTALES PARABRISAS CONCENTRADO",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIMPIADOR",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIMPIADOR AUTO TEJIDOS",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIMPIADOR CARROCERIA AUTO",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIMPIADOR DE FRENOS Y EMBRAGUE LOCTITE",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIMPIADOR FRENOS",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIMPIADOR INYECTORES",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIMPIADOR LLANTAS AUTO",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIMPIADOR NEUMATICOS AUTO",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIMPIADOR SALPICADEROS",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIMPIADOR TURBO/EGR",
          "idcrm": 15,
          
        },
        {
          "nombre": "LINTERNA",
          "idcrm": 15,
          
        },
        {
          "nombre": "LINTERNA LED CON IMAN A PILAS",
          "idcrm": 15,
          
        },
        {
          "nombre": "LINTERNA LED RECARGABLE CON IMAN Y GANCHO 500 LUMENES RECARGABLE",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIQUIDO DE FRENOS BRAKE FLUID DOT-4",
          "idcrm": 15,
          
        },
        {
          "nombre": "LIQUIDO DE FRENOS DOT-4",
          "idcrm": 28,
          
        },
        {
          "nombre": "LIQUIDO DE FRENOS DOT4",
          "idcrm": 28,
          
        },
        {
          "nombre": "LIQUIDO DIRECCIO LHM",
          "idcrm": 1371,
          
        },
        {
          "nombre": "LIQUIDO DIRECCION ASISTIDA",
          "idcrm": 28,
          
        },
        {
          "nombre": "LIQUIDO DIRECCION ATF DII (ROJO)",
          "idcrm": 28,
          
        },
        {
          "nombre": "LIQUIDO DIRECCION MANNOL (VERDE)",
          "idcrm": 28,
          
        },
        {
          "nombre": "LIQUIDO DIRECCION ROSA",
          "idcrm": 28,
          
        },
        {
          "nombre": "LIQUIDO FRENOS BRAKE FLUID DOT 4",
          "idcrm": 28,
          
        },
        {
          "nombre": "LIQUIDO LIMPIA-CATALIZADOR 1L.",
          "idcrm": 28,
          
        },
        {
          "nombre": "LIQUIDO LIMPIAPARABRISAS",
          "idcrm": 28,
          
        },
        {
          "nombre": "LLANTA ALUMINIO",
          "idcrm": 18,
          
        },
        {
          "nombre": "LLANTA ALUMINIO 14 PULGADAS",
          "idcrm": 18,
          
        },
        {
          "nombre": "LLANTA ALUMINIO 18 PULGADAS",
          "idcrm": 18,
          
        },
        {
          "nombre": "LLANTA ALUMINIO 19 PUGADAS",
          "idcrm": 18,
          
        },
        {
          "nombre": "LLANTA ALUMINIO 20 PULGADAS",
          "idcrm": 18,
          
        },
        {
          "nombre": "LLANTA CITROEN C4 R16",
          "idcrm": 18,
          
        },
        {
          "nombre": "LLANTA FOCUS R16",
          "idcrm": 18,
          
        },
        {
          "nombre": "LLANTA HIERRO",
          "idcrm": 18,
          
        },
        {
          "nombre": "LLANTA IBIZA R15",
          "idcrm": 18,
          
        },
        {
          "nombre": "LLANTA LEON R15",
          "idcrm": 18,
          
        },
        {
          "nombre": "LLANTA LLANTA HIERRO 15 PULGADA (TRANSPORTE T4)",
          "idcrm": 18,
          
        },
        {
          "nombre": "LLANTA OCTAVIA R15",
          "idcrm": 18,
          
        },
        {
          "nombre": "LLANTA R14",
          "idcrm": 18,
          
        },
        {
          "nombre": "LLANTA R15",
          "idcrm": 18,
          
        },
        {
          "nombre": "LLANTA R16",
          "idcrm": 18,
          
        },
        {
          "nombre": "LLANTA RUEDA REPUESTO",
          "idcrm": 18,
          
        },
        {
          "nombre": "LLAVE",
          "idcrm": 1372,
          
        },
        {
          "nombre": "LLAVE ACODADA",
          "idcrm": 15,
          
        },
        {
          "nombre": "LLAVE AJUSTABLE",
          "idcrm": 15,
          
        },
        {
          "nombre": "LLAVE CADENA",
          "idcrm": 15,
          
        },
        {
          "nombre": "LLAVE CARRACA",
          "idcrm": 15,
          
        },
        {
          "nombre": "LLAVE CARRACA MAURER PLUS",
          "idcrm": 15,
          
        },
        {
          "nombre": "LLAVE CINTA",
          "idcrm": 15,
          
        },
        {
          "nombre": "LLAVE COMBINADA",
          "idcrm": 15,
          
        },
        {
          "nombre": "LLAVE CRUZ",
          "idcrm": 15,
          
        },
        {
          "nombre": "LLAVE DE CONTACTO",
          "idcrm": 15,
          
        },
        {
          "nombre": "LLAVE ESTRELLA ABIERTA",
          "idcrm": 15,
          
        },
        {
          "nombre": "LLAVE FIJA",
          "idcrm": 15,
          
        },
        {
          "nombre": "LLAVE FILTRO DE ACEITE",
          "idcrm": 15,
          
        },
        {
          "nombre": "LLAVE FRENOS",
          "idcrm": 15,
          
        },
        {
          "nombre": "LLAVE INTELIGENTE",
          "idcrm": 15,
          
        },
        {
          "nombre": "LLAVE RUEDA",
          "idcrm": 19,
          
        },
        {
          "nombre": "LLAVE RUEDA TELESCOPICA 19/17/21/23",
          "idcrm": 19,
          
        },
        {
          "nombre": "LLAVE STILLSON",
          "idcrm": 15,
          
        },
        {
          "nombre": "LLAVE TUBO",
          "idcrm": 15,
          
        },
        {
          "nombre": "LLAVE VASO",
          "idcrm": 15,
          
        },
        {
          "nombre": "LUBRICANTE LOCTITE LB 8201",
          "idcrm": 28,
          
        },
        {
          "nombre": "LUCES INTERIOR",
          "idcrm": 37,
          
        },
        {
          "nombre": "LUNA FIJA DELANTERO DERECHA",
          "idcrm": 130,
          
        },
        {
          "nombre": "LUNA FIJA DELANTERO IZQUIERDA",
          "idcrm": 131,
          
        },
        {
          "nombre": "LUNA LATERAL IZQUIERDA",
          "idcrm": 135,
          
        },
        {
          "nombre": "LUNA MALETERO LAT. DERECHO",
          "idcrm": 130,
          
        },
        {
          "nombre": "LUNA MALETERO LAT. IZQUIERDO",
          "idcrm": 130,
          
        },
        {
          "nombre": "LUNA PUERTA CORREDERA DERECHA",
          "idcrm": 169,
          
        },
        {
          "nombre": "LUNA PUERTA CORREDERA IZQUIERDA",
          "idcrm": 169,
          
        },
        {
          "nombre": "LUNA PUERTA DELANTERO DERECHA",
          "idcrm": 130,
          
        },
        {
          "nombre": "LUNA PUERTA DELANTERO IZQUIERDA",
          "idcrm": 131,
          
        },
        {
          "nombre": "LUNA PUERTA TRASERO DERECHA",
          "idcrm": 169,
          
        },
        {
          "nombre": "LUNA PUERTA TRASERO IZQUIERDA",
          "idcrm": 169,
          
        },
        {
          "nombre": "LUNA TRASERA DERECHA ABATIBLE",
          "idcrm": 169,
          
        },
        {
          "nombre": "LUNA TRASERO",
          "idcrm": 169,
          
        },
        {
          "nombre": "LUNAS",
          "idcrm": 169,
          
        },
        {
          "nombre": "LUNETA TRASERO",
          "idcrm": 169,
          
        },
        {
          "nombre": "LUZ DELANTERO CENTRAL TECHO",
          "idcrm": 37,
          
        },
        {
          "nombre": "LUZ DIA IZQUIERDO",
          "idcrm": 1379,
          
        },
        {
          "nombre": "LUZ DIURNA",
          "idcrm": 1377,
          
        },
        {
          "nombre": "LUZ EMERGENCIA",
          "idcrm": 1124,
          
        },
        {
          "nombre": "LUZ EMERGENCIA V16",
          "idcrm": 1124,
          
        },
        {
          "nombre": "LUZ INTERIOR MALETERO",
          "idcrm": 37,
          
        },
        {
          "nombre": "LUZ INTERIOR TECHO",
          "idcrm": 37,
          
        },
        {
          "nombre": "LUZ TRASERO CENTRAL TECHO",
          "idcrm": 37,
          
        },
        {
          "nombre": "MACETA MANGO",
          "idcrm": 15,
          
        },
        {
          "nombre": "MALETA",
          "idcrm": 15,
          
        },
        {
          "nombre": "MANDO ANTINIEBLAS",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO APERTURA PUERTA",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO ASIENTO DERECHO",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO AUTOCRUISER",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO CALEFACCION ASIENTO",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO CONTROL DE TRACCION",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO CONTROL RADIO",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO DE INTERMITENTES",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO DE LUCES",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO DE LUCES Y LIMPIA",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO DE NAVEGADOR",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO DE RADIO",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO DE REGULACION DE ALTURA DEL FARO DELANTERO",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO ELEVALUNAS",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO ELEVALUNAS CENTRAL",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO ELEVALUNAS DELANT. IZQU.",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO ELEVALUNAS DELANT.DCH.",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO ELEVALUNAS TRA. IZQ.",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO FRENO MANO",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO INTERMITENCIA",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO INTERMITENTES Y LIMPIA",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO LEVAS",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO LIMITADOR DE VELOCIDAD",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO LIMITADOR VELOCIDAD",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO LIMPIAPARABRISAS",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO LUCES Y LIMPIAPARABRISAS",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO MALETERO",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO NAVEGADOR",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO REGULACION ALTURA ASIENTO DELANTERO IZQUIERDO",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO REGULACION ASIENTO",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO REGULACION COLUMNA DIRECCION",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO REGULACION FAROS",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO REGULACION LUCES",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO REGULACION LUMBAR ASIENTO DERECHO",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO REGULADOR FAROS",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO RETROVISOR ELECTRICO",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO SUSPENSION",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO TECHO ELECTRICO",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDO TRANSFER",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDOS CALEFACCION / A.A.",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDOS CLIMATIZADOR",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDOS PALANCA DE CAMBIO",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANDOS TECHO SOLAR",
          "idcrm": 1382,
          
        },
        {
          "nombre": "MANETA APERTURA CAPO",
          "idcrm": 170,
          
        },
        {
          "nombre": "MANETA ASIENTO",
          "idcrm": 170,
          
        },
        {
          "nombre": "MANETA CAPOT*",
          "idcrm": 170,
          
        },
        {
          "nombre": "MANETA EMBRAGUE",
          "idcrm": 62,
          
        },
        {
          "nombre": "MANETA EXTERIOR DELANTERO DERECHA",
          "idcrm": 138,
          
        },
        {
          "nombre": "MANETA EXTERIOR DELANTERO IZQUIERDA",
          "idcrm": 139,
          
        },
        {
          "nombre": "MANETA EXTERIOR DERECHO PUERTA LATERAL CORREDERA",
          "idcrm": 138,
          
        },
        {
          "nombre": "MANETA EXTERIOR IZQUIERDO PUERTA LATERAL CORREDERA",
          "idcrm": 139,
          
        },
        {
          "nombre": "MANETA EXTERIOR LATERAL CORREDERA DERECHA",
          "idcrm": 138,
          
        },
        {
          "nombre": "MANETA EXTERIOR LATERAL CORREDERA IZQUIERDA",
          "idcrm": 1399,
          
        },
        {
          "nombre": "MANETA EXTERIOR TAPA MALETERO (*)",
          "idcrm": 170,
          
        },
        {
          "nombre": "MANETA EXTERIOR TRASERO DERECHA",
          "idcrm": 170,
          
        },
        {
          "nombre": "MANETA EXTERIOR TRASERO IZQUIERDA",
          "idcrm": 170,
          
        },
        {
          "nombre": "MANETA FRENO",
          "idcrm": 491,
          
        },
        {
          "nombre": "MANETA INTERIOR",
          "idcrm": 347,
          
        },
        {
          "nombre": "MANETA INTERIOR APERTURA CAPO",
          "idcrm": 347,
          
        },
        {
          "nombre": "MANETA INTERIOR APERTURA TAPA EXTERIOR COMBUSTIBLE",
          "idcrm": 347,
          
        },
        {
          "nombre": "MANETA INTERIOR CAPO",
          "idcrm": 347,
          
        },
        {
          "nombre": "MANETA INTERIOR CAPOT",
          "idcrm": 347,
          
        },
        {
          "nombre": "MANETA INTERIOR LATERAL CORREDERA DERECHA",
          "idcrm": 347,
          
        },
        {
          "nombre": "MANETA INTERIOR LATERAL CORREDERA IZQUIERDA",
          "idcrm": 347,
          
        },
        {
          "nombre": "MANETA INTERIOR LATERAL DERECHO",
          "idcrm": 347,
          
        },
        {
          "nombre": "MANETA INTERIOR LATERAL IZQUIERDO",
          "idcrm": 347,
          
        },
        {
          "nombre": "MANETA INTERIOR PORTON",
          "idcrm": 347,
          
        },
        {
          "nombre": "MANETA INTERIOR PORTON TRASERO",
          "idcrm": 347,
          
        },
        {
          "nombre": "MANETA INTERIOR PUERTA LATERAL CORREDERA DERECHA",
          "idcrm": 347,
          
        },
        {
          "nombre": "MANETA INTERIOR PUERTA LATERAL CORREDERA IZQUIERDO",
          "idcrm": 347,
          
        },
        {
          "nombre": "MANGO CORREDIZO",
          "idcrm": 15,
          
        },
        {
          "nombre": "MANGO DE CONTROL INFERIOR TRASERO",
          "idcrm": 15,
          
        },
        {
          "nombre": "MANGUETA COMPLETA DELANTERO DERECHA",
          "idcrm": 215,
          
        },
        {
          "nombre": "MANGUETA COMPLETA DELANTERO IZQUIERDO",
          "idcrm": 216,
          
        },
        {
          "nombre": "MANGUETA DELANTERO DERECHA",
          "idcrm": 215,
          
        },
        {
          "nombre": "MANGUETA DELANTERO IZQUIERDA",
          "idcrm": 216,
          
        },
        {
          "nombre": "MANGUETA TRASERO DERECHA",
          "idcrm": 217,
          
        },
        {
          "nombre": "MANGUETA TRASERO IZQUIERDA",
          "idcrm": 218,
          
        },
        {
          "nombre": "MANGUITO DEPOSITO COMBUSTIBLE",
          "idcrm": 1403,
          
        },
        {
          "nombre": "MANGUITO INFERIOR REFIGERACION TURBO",
          "idcrm": 1403,
          
        },
        {
          "nombre": "MANGUITO INTERCOOLER MOTOR",
          "idcrm": 1403,
          
        },
        {
          "nombre": "MANGUITO REFRIGERACION",
          "idcrm": 1403,
          
        },
        {
          "nombre": "MANGUITO SALIDA TURBO",
          "idcrm": 1403,
          
        },
        {
          "nombre": "MANGUTO REFRIGERACION",
          "idcrm": 1403,
          
        },
        {
          "nombre": "MANILLA",
          "idcrm": 62,
          
        },
        {
          "nombre": "MANILLA ELEVALUNAS DELANTERO DERECHA",
          "idcrm": 1398,
          
        },
        {
          "nombre": "MANILLA ELEVALUNAS DELANTERO IZQUIERDA",
          "idcrm": 1398,
          
        },
        {
          "nombre": "MANILLA ELEVALUNAS TRASERO IZQUIERDA",
          "idcrm": 1398,
          
        },
        {
          "nombre": "MANILLA INT. PUERTA DELANTERO DERECHA",
          "idcrm": 344,
          
        },
        {
          "nombre": "MANILLA INT. PUERTA DELANTERO IZQUIERDA",
          "idcrm": 1401,
          
        },
        {
          "nombre": "MANILLA INT. PUERTA TRASERO DERECHA",
          "idcrm": 348,
          
        },
        {
          "nombre": "MANILLA INT. PUERTA TRASERO IZQUIERDA",
          "idcrm": 349,
          
        },
        {
          "nombre": "MANILLAR",
          "idcrm": 15,
          
        },
        {
          "nombre": "MARCO LUNA",
          "idcrm": 15,
          
        },
        {
          "nombre": "MARCO REJILLA NIEBLA",
          "idcrm": 15,
          
        },
        {
          "nombre": "MARTILLO CARPINTERO",
          "idcrm": 15,
          
        },
        {
          "nombre": "MARTILLO YAMATO",
          "idcrm": 15,
          
        },
        {
          "nombre": "MASA EMBRAGUE VISCOSO",
          "idcrm": 404,
          
        },
        {
          "nombre": "MASCARA CENTRAL DE SALPICADERO",
          "idcrm": 144,
          
        },
        {
          "nombre": "MASCARA CUADRO INSTRUMENTOS",
          "idcrm": 144,
          
        },
        {
          "nombre": "MASCARA DE RADIO",
          "idcrm": 144,
          
        },
        {
          "nombre": "MASCARA DE SALPICADERO",
          "idcrm": 144,
          
        },
        {
          "nombre": "MASCARILLA KN95",
          "idcrm": 15,
          
        },
        {
          "nombre": "MASCARILLA MAURER",
          "idcrm": 15,
          
        },
        {
          "nombre": "MASILLA WOLFPACK",
          "idcrm": 15,
          
        },
        {
          "nombre": "MATRICULA TRASERO",
          "idcrm": 15,
          
        },
        {
          "nombre": "MECANISMO ELEVALUNAS DELANTERO DERECHO",
          "idcrm": 122,
          
        },
        {
          "nombre": "MECANISMO ELEVALUNAS DELANTERO IZQUIERDO",
          "idcrm": 123,
          
        },
        {
          "nombre": "MECANISMO ELEVALUNAS SIN MOTOR",
          "idcrm": 122,
          
        },
        {
          "nombre": "MECANISMO ELEVALUNAS TRASERO DERECHO",
          "idcrm": 124,
          
        },
        {
          "nombre": "MECANISMO ELEVALUNAS TRASERO IZQUIERDO",
          "idcrm": 125,
          
        },
        {
          "nombre": "MECHERO",
          "idcrm": 1606,
          
        },
        {
          "nombre": "MEDIDOR DE LA MASA DE AIRE",
          "idcrm": 377,
          
        },
        {
          "nombre": "METAL LUBE  FORM. SISTEMA GASOLINA",
          "idcrm": 15,
          
        },
        {
          "nombre": "METAL LUBE FORM.SISTEMA DIESEL",
          "idcrm": 15,
          
        },
        {
          "nombre": "METAL LUBE FORMULA DIRECCION ASISTIDA",
          "idcrm": 28,
          
        },
        {
          "nombre": "METAL LUBE FORMULA MOTOR",
          "idcrm": 28,
          
        },
        {
          "nombre": "METAL LUBE FORMULA MOTOR 236",
          "idcrm": 28,
          
        },
        {
          "nombre": "METAL LUBE TRANSMISION AUTOMATICA 236 ML",
          "idcrm": 28,
          
        },
        {
          "nombre": "METAL LUBE TRANSMISION MANUAL 120 ML",
          "idcrm": 28,
          
        },
        {
          "nombre": "METAL LUBE TRANSMISION MANUAL 236 ML",
          "idcrm": 28,
          
        },
        {
          "nombre": "MODULADOR ABS",
          "idcrm": 1446,
          
        },
        {
          "nombre": "MODULO  FRENO MANO",
          "idcrm": 486,
          
        },
        {
          "nombre": "MODULO AIRBAGS",
          "idcrm": 234,
          
        },
        {
          "nombre": "MODULO BLUETOOFH",
          "idcrm": 1411,
          
        },
        {
          "nombre": "MODULO BOMBA COMBUSTIBLE",
          "idcrm": 1196,
          
        },
        {
          "nombre": "MODULO CIERRE CENTRALIZADO DELANTERO DERECHO",
          "idcrm": 242,
          
        },
        {
          "nombre": "MODULO CIERRE CENTRALIZADO DELANTERO IZQUIERDO",
          "idcrm": 242,
          
        },
        {
          "nombre": "MODULO CLIMATIZADOR",
          "idcrm": 1199,
          
        },
        {
          "nombre": "MODULO CONTROL ELECTRONICO ECU",
          "idcrm": 247,
          
        },
        {
          "nombre": "MODULO CONTROL FRENO ELECTRICO",
          "idcrm": 486,
          
        },
        {
          "nombre": "MODULO CONTROL MANDO",
          "idcrm": 1211,
          
        },
        {
          "nombre": "MODULO CONTROL PARKING",
          "idcrm": 1212,
          
        },
        {
          "nombre": "MODULO CONTROL PUNTO MUERTO DERECHO",
          "idcrm": 1169,
          
        },
        {
          "nombre": "MODULO CONTROL PUNTO MUERTO IZQUIERDO",
          "idcrm": 1169,
          
        },
        {
          "nombre": "MODULO CONTROL TRASERO",
          "idcrm": 1169,
          
        },
        {
          "nombre": "MODULO DE ANTENA",
          "idcrm": 1194,
          
        },
        {
          "nombre": "MODULO DE BLUETOOTH",
          "idcrm": 1411,
          
        },
        {
          "nombre": "MODULO DE CAMARA",
          "idcrm": 1171,
          
        },
        {
          "nombre": "MODULO DE DATA",
          "idcrm": 284,
          
        },
        {
          "nombre": "M�DULO DE UNIDAD DE CONTROL DE FAROS",
          "idcrm": 243,
          
        },
        {
          "nombre": "MODULO ECU-ETWIS",
          "idcrm": 284,
          
        },
        {
          "nombre": "MODULO EGR",
          "idcrm": 1206,
          
        },
        {
          "nombre": "MODULO ELECTRONICO BLUETOOTH",
          "idcrm": 1411,
          
        },
        {
          "nombre": "MODULO EURO 6",
          "idcrm": 243,
          
        },
        {
          "nombre": "MODULO OPS APARCAMIENTO",
          "idcrm": 1212,
          
        },
        {
          "nombre": "MODULO PARKING",
          "idcrm": 1212,
          
        },
        {
          "nombre": "MODULO PRESION NEUMATICO",
          "idcrm": 419,
          
        },
        {
          "nombre": "MODULO RUEDA COLUMNA",
          "idcrm": 272,
          
        },
        {
          "nombre": "MODULOS",
          "idcrm": 1192,
          
        },
        {
          "nombre": "MOLDURA ALETA DELANTERA IZQUIERDA",
          "idcrm": 73,
          
        },
        {
          "nombre": "MOLDURA ALETA DELANTERO DERECHA",
          "idcrm": 72,
          
        },
        {
          "nombre": "MOLDURA ALETA DELANTERO IZQUIERDA",
          "idcrm": 73,
          
        },
        {
          "nombre": "MOLDURA ALETA TRASERO DERECHA",
          "idcrm": 73,
          
        },
        {
          "nombre": "MOLDURA ALETA TRASERO IZQUIERDA",
          "idcrm": 159,
          
        },
        {
          "nombre": "MOLDURA ANTENA",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA BAJO FARO",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA BAJO FARO DERECHO",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA BAJO FARO IZQUIERDO",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA CUADRO DE MANDOS",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA DE PLASTICO",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA DELANTERA DERECHA",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA DELANTERA IZQUIERDA",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA DERECHA",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA ENTRADA PUERTA",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA IZQUIERDA DE PARABRISAS DELANTERO",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA IZQUIERDA PILAR DELANTERO",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA MARCO PUERTA DELANTERO DERECHA",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA MARCO PUERTA DELANTERO IZQUIERDA",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA MARCO PUERTA TRASERO DERECHA",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA MARCO PUERTA TRASERO IZQUIERDA",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA PANEL",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA PARABRISAS DERECHA",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA PARAGOLPES DELANTERO",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA PILAR DELANTERO DERECHO",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA PILAR DERECHO",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA PILOTO MATRICULA",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA PORTON TRASERO",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA PUERTA CORREDERA DERECHA",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA PUERTA DELANTERO DERECHA",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA PUERTA DELANTERO IZQUIERDA",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA PUERTA TRASERA DERECHA",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA PUERTA TRASERA IZQUIERDA",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA PUERTA TRASERO DERECHA",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA PUERTA TRASERO IZQUIERDA",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA TRASERA DEL PORTON",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA VIERTEAGUAS DERECHA",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURA VIERTEAGUAS IZQUIERDA",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURAS EXTERIORES",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURAS PARAGOLPES DELANTERAS",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURAS PARAGOLPES TRASERA",
          "idcrm": 144,
          
        },
        {
          "nombre": "MOLDURAS TECHO",
          "idcrm": 144,
          
        },
        {
          "nombre": "MONOPUNTO",
          "idcrm": 401,
          
        },
        {
          "nombre": "MOQUETA",
          "idcrm": 1432,
          
        },
        {
          "nombre": "MOQUETA SUELO INTERIOR",
          "idcrm": 1432,
          
        },
        {
          "nombre": "MORDAZA",
          "idcrm": 15,
          
        },
        {
          "nombre": "MORDAZA MAURER",
          "idcrm": 15,
          
        },
        {
          "nombre": "MOTOR APERTURA CALEFAC.",
          "idcrm": 1439,
          
        },
        {
          "nombre": "MOTOR APERTURA PORTON",
          "idcrm": 161,
          
        },
        {
          "nombre": "MOTOR ASIENTOS",
          "idcrm": 237,
          
        },
        {
          "nombre": "MOTOR BLOQUEO TAPA COMBUSTIBLE",
          "idcrm": 1438,
          
        },
        {
          "nombre": "MOTOR CAJA TRANSFE",
          "idcrm": 1436,
          
        },
        {
          "nombre": "MOTOR CAPOTA",
          "idcrm": 282,
          
        },
        {
          "nombre": "MOTOR CIERRE LATERAL DERECHO",
          "idcrm": 272,
          
        },
        {
          "nombre": "MOTOR CIERRE PORTON",
          "idcrm": 272,
          
        },
        {
          "nombre": "MOTOR CIERRE TAPA COMBUSTIBLE",
          "idcrm": 1438,
          
        },
        {
          "nombre": "MOTOR COLUMNA DIRECCION",
          "idcrm": 1434,
          
        },
        {
          "nombre": "MOTOR DE ARRANQUE",
          "idcrm": 269,
          
        },
        {
          "nombre": "MOTOR DE CAPOTA",
          "idcrm": 282,
          
        },
        {
          "nombre": "MOTOR DE CIERRE CENTRALIZADO",
          "idcrm": 272,
          
        },
        {
          "nombre": "MOTOR DE CONTROL (LIMITADOR DE VELOCIDAD)",
          "idcrm": 268,
          
        },
        {
          "nombre": "MOTOR DE LA BOMBA DE DIRECCION ASISTIDA",
          "idcrm": 1434,
          
        },
        {
          "nombre": "MOTOR DE TRAMPILLAS",
          "idcrm": 268,
          
        },
        {
          "nombre": "MOTOR DESNUDO",
          "idcrm": 406,
          
        },
        {
          "nombre": "MOTOR DIRECCI�N ELECTRICA",
          "idcrm": 1434,
          
        },
        {
          "nombre": "MOTOR ELECTRICO",
          "idcrm": 269,
          
        },
        {
          "nombre": "MOTOR ELECTRICO CORTINA",
          "idcrm": 1439,
          
        },
        {
          "nombre": "MOTOR ELECTRICO DE AGUA - LIMPIA CRISTAL",
          "idcrm": 1433,
          
        },
        {
          "nombre": "MOTOR ELECTRICO TAPA MALETERO/ PORTON",
          "idcrm": 272,
          
        },
        {
          "nombre": "MOTOR ELECTRICO TECHO SOLAR",
          "idcrm": 282,
          
        },
        {
          "nombre": "MOTOR ELECTRICO TRANSFER",
          "idcrm": 1436,
          
        },
        {
          "nombre": "MOTOR ELEVALUNAS PORTON",
          "idcrm": 278,
          
        },
        {
          "nombre": "MOTOR FRENO ELECTRICO",
          "idcrm": 1241,
          
        },
        {
          "nombre": "MOTOR LAVAFAROS DERECHO",
          "idcrm": 279,
          
        },
        {
          "nombre": "MOTOR LAVAFAROS IZQUIERDO",
          "idcrm": 279,
          
        },
        {
          "nombre": "MOTOR LIMPIAPARABRISAS DELANTERO",
          "idcrm": 279,
          
        },
        {
          "nombre": "MOTOR LIMPIAPARABRISAS TRASERO",
          "idcrm": 280,
          
        },
        {
          "nombre": "MOTOR PORTON TRASERO",
          "idcrm": 272,
          
        },
        {
          "nombre": "MOTOR PUERTA CORREDERA",
          "idcrm": 272,
          
        },
        {
          "nombre": "MOTOR PUERTA CORREDERA DERECHA",
          "idcrm": 272,
          
        },
        {
          "nombre": "MOTOR PUERTA CORREDERA IZQUIERDA",
          "idcrm": 272,
          
        },
        {
          "nombre": "MOTOR PUERTA ELECTRICA",
          "idcrm": 272,
          
        },
        {
          "nombre": "MOTOR PUERTA LATERAL",
          "idcrm": 272,
          
        },
        {
          "nombre": "MOTOR REFRIGERADOR BATERIA",
          "idcrm": 15,
          
        },
        {
          "nombre": "MOTOR REGULACION AIREADORES",
          "idcrm": 268,
          
        },
        {
          "nombre": "MOTOR REGULACION ASIENTO",
          "idcrm": 237,
          
        },
        {
          "nombre": "MOTOR TAPA COMBUSTIBLE",
          "idcrm": 1438,
          
        },
        {
          "nombre": "MOTOR TAPA EXTERIOR COMBUSTIBLE",
          "idcrm": 1438,
          
        },
        {
          "nombre": "MOTOR TECHO",
          "idcrm": 282,
          
        },
        {
          "nombre": "MOTOR TRACCI�N",
          "idcrm": 406,
          
        },
        {
          "nombre": "MOTOR TRAMPILLA CALEFACCION",
          "idcrm": 268,
          
        },
        {
          "nombre": "MOTOR TRAMPILLAS",
          "idcrm": 268,
          
        },
        {
          "nombre": "MUEBLE CALEFACCION",
          "idcrm": 1234,
          
        },
        {
          "nombre": "MUELLE AMORTIGUACION TRASERO DERECHO",
          "idcrm": 490,
          
        },
        {
          "nombre": "MUELLE AMORTIGUACION TRASERO IZQUIERDO",
          "idcrm": 490,
          
        },
        {
          "nombre": "MUELLE DERECHO SUSPENSION TRASERO",
          "idcrm": 490,
          
        },
        {
          "nombre": "MUELLE IZQUIERDO SUSPENSION TRASERO",
          "idcrm": 490,
          
        },
        {
          "nombre": "MUELLE SUSPENSION DELANTERA",
          "idcrm": 490,
          
        },
        {
          "nombre": "MUELLE SUSPENSION TRASERA",
          "idcrm": 490,
          
        },
        {
          "nombre": "MULTIHERRAMIENTA YAMATO+MAL+ACC",
          "idcrm": 15,
          
        },
        {
          "nombre": "NAVEGADOR",
          "idcrm": 1443,
          
        },
        {
          "nombre": "NEUMATICO 175-65-14",
          "idcrm": 20,
          
        },
        {
          "nombre": "NEUMATICO 175-70-13",
          "idcrm": 20,
          
        },
        {
          "nombre": "NEUMATICO 185-60-14",
          "idcrm": 20,
          
        },
        {
          "nombre": "NEUMATICO 185-65-14",
          "idcrm": 20,
          
        },
        {
          "nombre": "NEUMATICO 185-65-15",
          "idcrm": 20,
          
        },
        {
          "nombre": "NEUMATICO 195-65-15",
          "idcrm": 20,
          
        },
        {
          "nombre": "NEUMATICO 205-60-15",
          "idcrm": 20,
          
        },
        {
          "nombre": "NEUMATICO 205-65-15",
          "idcrm": 20,
          
        },
        {
          "nombre": "NEUMATICO 215-45-17",
          "idcrm": 20,
          
        },
        {
          "nombre": "NEUMATICO 225-45-17",
          "idcrm": 20,
          
        },
        {
          "nombre": "NEUMATICO GISLAVED",
          "idcrm": 20,
          
        },
        {
          "nombre": "NEUMATICO KLEBER",
          "idcrm": 20,
          
        },
        {
          "nombre": "NEUMATICO NANKANG",
          "idcrm": 20,
          
        },
        {
          "nombre": "NEUMATICO NEXEN",
          "idcrm": 20,
          
        },
        {
          "nombre": "NEUMATICO PAREJA",
          "idcrm": 20,
          
        },
        {
          "nombre": "NEUMATICO RIKEN MAYSTORM",
          "idcrm": 20,
          
        },
        {
          "nombre": "NEUMATICO SAVA",
          "idcrm": 20,
          
        },
        {
          "nombre": "NEUMATICO UNIROYAL",
          "idcrm": 20,
          
        },
        {
          "nombre": "NEUMATICO WESTLAKE",
          "idcrm": 20,
          
        },
        {
          "nombre": "NEUMATICO/S ACCELERA",
          "idcrm": 20,
          
        },
        {
          "nombre": "NEUMATICO/S ZEETEX",
          "idcrm": 20,
          
        },
        {
          "nombre": "NEUMATICOS REVISADOS CONFORME A NORMA UNE 69051",
          "idcrm": 20,
          
        },
        {
          "nombre": "NO USAR---PLASTICO",
          "idcrm": 15,
          
        },
        {
          "nombre": "NURAL-",
          "idcrm": 15,
          
        },
        {
          "nombre": "NURAL- 22",
          "idcrm": 15,
          
        },
        {
          "nombre": "NURAL- 28",
          "idcrm": 15,
          
        },
        {
          "nombre": "NURAL- 34",
          "idcrm": 15,
          
        },
        {
          "nombre": "OJAL PILOTO TRASERO DERECHO",
          "idcrm": 144,
          
        },
        {
          "nombre": "OJAL PILOTO TRASERO IZQUIERDO",
          "idcrm": 144,
          
        },
        {
          "nombre": "ORDENADOR DE ABORDO",
          "idcrm": 1463,
          
        },
        {
          "nombre": "PALANCA CAMBIO AUTOMATICA",
          "idcrm": 63,
          
        },
        {
          "nombre": "PALANCA CAPOT",
          "idcrm": 1447,
          
        },
        {
          "nombre": "PALANCA DE CAMBIO",
          "idcrm": 63,
          
        },
        {
          "nombre": "PALANCA REDUCTORA",
          "idcrm": 63,
          
        },
        {
          "nombre": "PALANCA SELECTORA",
          "idcrm": 63,
          
        },
        {
          "nombre": "PALANCA SENSOR ALTURA TRASERO DERECHO",
          "idcrm": 513,
          
        },
        {
          "nombre": "PALANCA SENSOR ALTURA TRASERO IZQUIERDO",
          "idcrm": 513,
          
        },
        {
          "nombre": "PALANCA SUSPENSION",
          "idcrm": 513,
          
        },
        {
          "nombre": "PALANCAS DISTRIBUIDOR",
          "idcrm": 513,
          
        },
        {
          "nombre": "PALIER DELANTERO DERECHO",
          "idcrm": 1448,
          
        },
        {
          "nombre": "PALIER DELANTERO IZQUIERDO",
          "idcrm": 1449,
          
        },
        {
          "nombre": "PANEL CENTRAL",
          "idcrm": 1455,
          
        },
        {
          "nombre": "PANEL CONTROL RADIO",
          "idcrm": 144,
          
        },
        {
          "nombre": "PANEL CONTROL TACTIL",
          "idcrm": 144,
          
        },
        {
          "nombre": "PANEL DE INSTRUMENTOS",
          "idcrm": 144,
          
        },
        {
          "nombre": "PANEL FILTRO DE AIRE",
          "idcrm": 144,
          
        },
        {
          "nombre": "PANEL PUERTA D.D.",
          "idcrm": 1458,
          
        },
        {
          "nombre": "PANEL PUERTA D.I.",
          "idcrm": 1459,
          
        },
        {
          "nombre": "PANEL PUERTA T.D.",
          "idcrm": 1460,
          
        },
        {
          "nombre": "PANEL PUERTA T.I.",
          "idcrm": 1461,
          
        },
        {
          "nombre": "PANEL REVESTIMIENTO",
          "idcrm": 144,
          
        },
        {
          "nombre": "PANEL TRA.",
          "idcrm": 144,
          
        },
        {
          "nombre": "PANJAR DELANTERO",
          "idcrm": 144,
          
        },
        {
          "nombre": "PANTALLA CLIMATIZADOR",
          "idcrm": 283,
          
        },
        {
          "nombre": "PANTALLA DE NAVEGACION GPS",
          "idcrm": 283,
          
        },
        {
          "nombre": "PANTALLA DE TELEFONO",
          "idcrm": 283,
          
        },
        {
          "nombre": "PANTALLA DVD",
          "idcrm": 283,
          
        },
        {
          "nombre": "PANTALLA PARKING",
          "idcrm": 283,
          
        },
        {
          "nombre": "PANTALLA RELOJ",
          "idcrm": 283,
          
        },
        {
          "nombre": "PANTALLA RELOJ-TEMPERATURA",
          "idcrm": 283,
          
        },
        {
          "nombre": "PARASOL",
          "idcrm": 350,
          
        },
        {
          "nombre": "PARASOL CENTRO",
          "idcrm": 350,
          
        },
        {
          "nombre": "PAREJA NEUMATICOS",
          "idcrm": 20,
          
        },
        {
          "nombre": "PASE DE RUEDA DELAN DCHO",
          "idcrm": 1464,
          
        },
        {
          "nombre": "PASE DE RUEDA DELAN IZQUI",
          "idcrm": 1465,
          
        },
        {
          "nombre": "PASE DE RUEDA TRAS.DCHO",
          "idcrm": 1468,
          
        },
        {
          "nombre": "PASE DE RUEDA TRAS.IZQUI",
          "idcrm": 1469,
          
        },
        {
          "nombre": "PASE RUEDA TRASERO IZQUIERDO",
          "idcrm": 1469,
          
        },
        {
          "nombre": "PASO DE RUEDA DELANTERO DERECHO",
          "idcrm": 1464,
          
        },
        {
          "nombre": "PASO DE RUEDA DELANTERO IZQUIERDO",
          "idcrm": 1465,
          
        },
        {
          "nombre": "PASO DE RUEDA TRASERO DERECHO",
          "idcrm": 1468,
          
        },
        {
          "nombre": "PASO DE RUEDA TRASERO IZQUIERDO",
          "idcrm": 1469,
          
        },
        {
          "nombre": "PASO RUEDA DEL. IZQ.",
          "idcrm": 1465,
          
        },
        {
          "nombre": "PASO RUEDA DERECHA",
          "idcrm": 1464,
          
        },
        {
          "nombre": "PASO RUEDA DERECHO",
          "idcrm": 1464,
          
        },
        {
          "nombre": "PASO RUEDA IZQUIERDA",
          "idcrm": 1465,
          
        },
        {
          "nombre": "PASO RUEDA IZQUIERDO",
          "idcrm": 1465,
          
        },
        {
          "nombre": "PASO RUEDA PLASTICO DELANTERO DERECHO",
          "idcrm": 1464,
          
        },
        {
          "nombre": "PASO RUEDA PLASTICO DELANTERO IZQUIERDO",
          "idcrm": 1465,
          
        },
        {
          "nombre": "PASO RUEDA PLASTICO TRASERO DERECHO",
          "idcrm": 1469,
          
        },
        {
          "nombre": "PASO RUEDA PLASTICO TRASERO IZQUIERDO",
          "idcrm": 1470,
          
        },
        {
          "nombre": "PASO RUEDA TRASERO DCH.",
          "idcrm": 1469,
          
        },
        {
          "nombre": "PASO RUEDA TRASERO IZQ.",
          "idcrm": 1470,
          
        },
        {
          "nombre": "PASTA LAVAMANOS",
          "idcrm": 15,
          
        },
        {
          "nombre": "PASTILLA ENCENDIDO",
          "idcrm": 15,
          
        },
        {
          "nombre": "PATA DE CABRA",
          "idcrm": 22,
          
        },
        {
          "nombre": "PATA LATERAL",
          "idcrm": 15,
          
        },
        {
          "nombre": "PEDAL FRENO ESTACIONAMIENTO",
          "idcrm": 354,
          
        },
        {
          "nombre": "PEDAL POTENCIOMETRO",
          "idcrm": 352,
          
        },
        {
          "nombre": "PEGATINA",
          "idcrm": 15,
          
        },
        {
          "nombre": "PELACABLES",
          "idcrm": 15,
          
        },
        {
          "nombre": "PERFUMADOR PINO",
          "idcrm": 15,
          
        },
        {
          "nombre": "PIEZAS VARIAS",
          "idcrm": 15,
          
        },
        {
          "nombre": "PILA MAURER ALCALINA AA LR-6",
          "idcrm": 15,
          
        },
        {
          "nombre": "PILOTO ANTINIEBLA TRASERO IZQUIERDO",
          "idcrm": 48,
          
        },
        {
          "nombre": "PILOTO DE ALETA",
          "idcrm": 40,
          
        },
        {
          "nombre": "PILOTO DE RETROVISOR DERECHO",
          "idcrm": 1475,
          
        },
        {
          "nombre": "PILOTO DE RETROVISOR IZQUIERDO",
          "idcrm": 1476,
          
        },
        {
          "nombre": "PILOTO DEL PORTON TRAS. DCHO.",
          "idcrm": 45,
          
        },
        {
          "nombre": "PILOTO DEL PORTON TRAS. IZQUI.",
          "idcrm": 46,
          
        },
        {
          "nombre": "PILOTO INTERIOR DERECHO",
          "idcrm": 44,
          
        },
        {
          "nombre": "PILOTO INTERIOR IZQUIERDO",
          "idcrm": 44,
          
        },
        {
          "nombre": "PILOTO INTERMITENTE LAT. DELANTERO DERECHO",
          "idcrm": 44,
          
        },
        {
          "nombre": "PILOTO INTERMITENTE LAT. DELANTERO IZQUIERDO",
          "idcrm": 44,
          
        },
        {
          "nombre": "PILOTO INTERMITENTE TRASERO IZQUIERDO",
          "idcrm": 44,
          
        },
        {
          "nombre": "PILOTO MATRICULA TRASERO DERECHO",
          "idcrm": 44,
          
        },
        {
          "nombre": "PILOTO MATRICULA TRASERO IZQUIERDO",
          "idcrm": 44,
          
        },
        {
          "nombre": "PILOTO POSICION",
          "idcrm": 44,
          
        },
        {
          "nombre": "PILOTO RETROVISOR DERECHO",
          "idcrm": 44,
          
        },
        {
          "nombre": "PILOTO SUPERIOR TRASERO DERECHO",
          "idcrm": 44,
          
        },
        {
          "nombre": "PILOTO SUPERIOR TRASERO IZQUIERDO",
          "idcrm": 44,
          
        },
        {
          "nombre": "PILOTO TRASERO AMBOS LADOS",
          "idcrm": 44,
          
        },
        {
          "nombre": "PILOTO TRASERO CENTRAL ANTINIEBLAS",
          "idcrm": 44,
          
        },
        {
          "nombre": "PILOTO TRASERO CENTRAL PARAGOLPES",
          "idcrm": 44,
          
        },
        {
          "nombre": "PILOTO TRASERO DERECHO DEFENSA",
          "idcrm": 44,
          
        },
        {
          "nombre": "PILOTO TRASERO DERECHO NUEVO",
          "idcrm": 44,
          
        },
        {
          "nombre": "PILOTO TRASERO IZQUIERDO DEFENSA",
          "idcrm": 44,
          
        },
        {
          "nombre": "PILOTO TRASERO LATERAL IZQUIERDO",
          "idcrm": 44,
          
        },
        {
          "nombre": "PINZA DE FRENO DELANTERA",
          "idcrm": 494,
          
        },
        {
          "nombre": "PINZA FRENO DELANTERO DERECHA",
          "idcrm": 494,
          
        },
        {
          "nombre": "PINZA FRENO DELANTERO IZQUIERDA",
          "idcrm": 495,
          
        },
        {
          "nombre": "PINZA FRENO TRASERO DERECHA",
          "idcrm": 496,
          
        },
        {
          "nombre": "PINZA FRENO TRASERO IZQUIERDA",
          "idcrm": 497,
          
        },
        {
          "nombre": "PINZA TRASERA DERECHA ELECTRICA",
          "idcrm": 496,
          
        },
        {
          "nombre": "PINZAS BATERIA",
          "idcrm": 1490,
          
        },
        {
          "nombre": "PI�A LUCES DERECHA",
          "idcrm": 284,
          
        },
        {
          "nombre": "PI�ON",
          
          "Familia": 10
        },
        {
          "nombre": "PI�ON CADENA DISTRIBUCION",
          
          "Familia": 10
        },
        {
          "nombre": "PIRULO LUMINOSO",
          "idcrm": 1380,
          
        },
        {
          "nombre": "PISTOLA SILICONA",
          "idcrm": 15,
          
        },
        {
          "nombre": "PISTOLA SOPLADO",
          "idcrm": 15,
          
        },
        {
          "nombre": "PISTOLA TERMOCOL.YAMATO",
          "idcrm": 15,
          
        },
        {
          "nombre": "PIST�N ABATIMIENTO CABINA",
          "idcrm": 408,
          
        },
        {
          "nombre": "PI?A LUCES DERECHA",
          "idcrm": 284,
          
        },
        {
          "nombre": "PI?A LUCES IZQUIERDA",
          "idcrm": 285,
          
        },
        {
          "nombre": "PLACA MATRICULA CORTA",
          "idcrm": 173,
          
        },
        {
          "nombre": "PLACA MATRICULA LARGA",
          "idcrm": 173,
          
        },
        {
          "nombre": "PLACA PROTECTORA CENTRAL",
          "idcrm": 144,
          
        },
        {
          "nombre": "PLAFON INTERIOR",
          "idcrm": 1458,
          
        },
        {
          "nombre": "PLAFONES",
          "idcrm": 1458,
          
        },
        {
          "nombre": "PLASTICO",
          "idcrm": 144,
          
        },
        {
          "nombre": "PLASTICO INTERIOR",
          "idcrm": 144,
          
        },
        {
          "nombre": "PLASTICO SALPICADERO",
          "idcrm": 144,
          
        },
        {
          "nombre": "PLASTICOS",
          "idcrm": 144,
          
        },
        {
          "nombre": "PLATAFORMA ELEVADORA",
          "idcrm": 15,
          
        },
        {
          "nombre": "PLATO CAUCHO",
          "idcrm": 15,
          
        },
        {
          "nombre": "POLEA A/A",
          "idcrm": 409,
          
        },
        {
          "nombre": "POLEA BOMBA",
          "idcrm": 409,
          
        },
        {
          "nombre": "POLEA BOMBA DE AGUA",
          "idcrm": 409,
          
        },
        {
          "nombre": "POLEA BOMBA DIRECCION",
          "idcrm": 409,
          
        },
        {
          "nombre": "POLEA BOMBA DIRECCION ASISTIDA",
          "idcrm": 409,
          
        },
        {
          "nombre": "POLEA BOMBA SERVODIRECCION",
          "idcrm": 409,
          
        },
        {
          "nombre": "POLEA CIGUE�AL",
          "idcrm": 412,
          
        },
        {
          "nombre": "POLEA DEL TENSOR CORREA AUXILIAR",
          "idcrm": 409,
          
        },
        {
          "nombre": "POLIMETRO DIGITAL",
          "idcrm": 15,
          
        },
        {
          "nombre": "POMO CAMBIO NEGRO",
          "idcrm": 64,
          
        },
        {
          "nombre": "POMO CORTO PIEL NEGRA",
          "idcrm": 64,
          
        },
        {
          "nombre": "POMO PALANCA CON FUNDA",
          "idcrm": 64,
          
        },
        {
          "nombre": "POMO PALANCA DE CAMBIO",
          "idcrm": 64,
          
        },
        {
          "nombre": "POMO PALANCA TRACCIONES",
          "idcrm": 64,
          
        },
        {
          "nombre": "PORTA BICIS",
          "idcrm": 23,
          
        },
        {
          "nombre": "PORTA OBJETOS",
          "idcrm": 23,
          
        },
        {
          "nombre": "PORTA RUEDA",
          "idcrm": 23,
          
        },
        {
          "nombre": "PORTA VASOS",
          "idcrm": 1496,
          
        },
        {
          "nombre": "PORTABICICLETAS",
          "idcrm": 23,
          
        },
        {
          "nombre": "PORTAFILTRO DE ACEITE",
          "idcrm": 424,
          
        },
        {
          "nombre": "PORTAFILTRO GASOIL",
          "idcrm": 425,
          
        },
        {
          "nombre": "PORTALAMPARA PILOTO TRASERO IZQUIERDO",
          "idcrm": 51,
          
        },
        {
          "nombre": "PORTALAMPARA PORTON TRASERO DERECHO",
          "idcrm": 51,
          
        },
        {
          "nombre": "PORTALAMPARA PORTON TRASERO IZQUIERDO",
          "idcrm": 51,
          
        },
        {
          "nombre": "PORTALAMPARAS PILOTO TRASERO DERECHO",
          "idcrm": 51,
          
        },
        {
          "nombre": "PORTALAMPARAS PILOTO TRASERO IZQUIERDO",
          "idcrm": 51,
          
        },
        {
          "nombre": "PORTALAMPARAS TRASERO DERECHO",
          "idcrm": 51,
          
        },
        {
          "nombre": "PORTALAMPARAS TRASERO IZQUIERDO",
          "idcrm": 51,
          
        },
        {
          "nombre": "PORTAMATRICULA",
          "idcrm": 173,
          
        },
        {
          "nombre": "PORTAOBJETO",
          "idcrm": 23,
          
        },
        {
          "nombre": "PORTAOBJETOS CONSOLA CENTRAL",
          "idcrm": 1239,
          
        },
        {
          "nombre": "PORTAOBJETOS REPOSABRAZOS",
          "idcrm": 308,
          
        },
        {
          "nombre": "PORTAPINZA",
          "idcrm": 1489,
          
        },
        {
          "nombre": "PORTAPINZAS",
          "idcrm": 1489,
          
        },
        {
          "nombre": "PORTAVASOS",
          "idcrm": 1496,
          
        },
        {
          "nombre": "POSAVASOS",
          "idcrm": 1496,
          
        },
        {
          "nombre": "POTENCIADOR AUDIO",
          "idcrm": 1306,
          
        },
        {
          "nombre": "POTENCIOMETRO PEDAL GAS",
          "idcrm": 286,
          
        },
        {
          "nombre": "PRE CALENTADOR CALEFACCION",
          "idcrm": 1168,
          
        },
        {
          "nombre": "PRE ITV",
          "idcrm": 15,
          
        },
        {
          "nombre": "PRECALENTADOR DIESEL",
          "idcrm": 229,
          
        },
        {
          "nombre": "PREFILTRO ACEITE TD5 / TRANSIT 2.4 TDCI",
          "idcrm": 393,
          
        },
        {
          "nombre": "PRENSA EMBRAGUE",
          
          "Familia": 10
        },
        {
          "nombre": "PRETENSOR AIRBAG DELANTERO DERECHO",
          "idcrm": 355,
          
        },
        {
          "nombre": "PRETENSOR AIRBAG DELANTERO IZQUIERDO",
          "idcrm": 356,
          
        },
        {
          "nombre": "PRETENSOR AIRBAG TRASERO CENTRAL",
          "idcrm": 355,
          
        },
        {
          "nombre": "PRETENSOR CENTRAL",
          "idcrm": 355,
          
        },
        {
          "nombre": "PRETENSOR DELANTERO CENTRAL",
          "idcrm": 355,
          
        },
        {
          "nombre": "PRETENSOR DELANTERO DERECHO",
          "idcrm": 1499,
          
        },
        {
          "nombre": "PRETENSOR DELANTERO IZQUIERDO",
          "idcrm": 1500,
          
        },
        {
          "nombre": "PRETENSOR TASERO CENTRAL",
          "idcrm": 355,
          
        },
        {
          "nombre": "PRETENSOR TRASERO CENTRAL",
          "idcrm": 355,
          
        },
        {
          "nombre": "PROLONGADOR ALTERNADOR",
          "idcrm": 15,
          
        },
        {
          "nombre": "PROTECCION LATERAL INF. DER.",
          "idcrm": 144,
          
        },
        {
          "nombre": "PROTECCION LATERAL INF. IZQ.",
          "idcrm": 144,
          
        },
        {
          "nombre": "PROTECTOR ANTIROC.COCHER.",
          "idcrm": 144,
          
        },
        {
          "nombre": "PROTECTOR ANTIROC.COCHER.FLEX.32X40CM",
          "idcrm": 144,
          
        },
        {
          "nombre": "PROTECTOR AUDITIVO",
          "idcrm": 144,
          
        },
        {
          "nombre": "PROTECTOR DEPOSITO COMBUSTIBLE",
          "idcrm": 144,
          
        },
        {
          "nombre": "PROTECTOR FILTRO PARTICULAS",
          "idcrm": 144,
          
        },
        {
          "nombre": "PROTECTOR PASE RUEDA DELANTERO DERECHO",
          "idcrm": 144,
          
        },
        {
          "nombre": "PROTECTOR PASE RUEDA DELANTERO IZQUIERDO",
          "idcrm": 144,
          
        },
        {
          "nombre": "PROTECTOR PASE RUEDA TRASERO DERECHO",
          "idcrm": 144,
          
        },
        {
          "nombre": "PROTECTOR PASE RUEDA TRASERO IZQUIERDO",
          "idcrm": 144,
          
        },
        {
          "nombre": "PROTECTOR PILOTO",
          "idcrm": 144,
          
        },
        {
          "nombre": "PROTECTOR ROTULA MANGUETA DELANTERO DERECHA",
          "idcrm": 144,
          
        },
        {
          "nombre": "PROTECTOR TURBO",
          "idcrm": 144,
          
        },
        {
          "nombre": "PROYECTOR VELOCIMETRO",
          "idcrm": 144,
          
        },
        {
          "nombre": "PUERTA DELANTERO DERECHA",
          "idcrm": 146,
          
        },
        {
          "nombre": "PUERTA DELANTERO IZQUIERDA",
          "idcrm": 147,
          
        },
        {
          "nombre": "PUERTA ELEVADORA RETRACTIL",
          "idcrm": 146,
          
        },
        {
          "nombre": "PUERTA LAT. CORREDERA DERECHA",
          "idcrm": 148,
          
        },
        {
          "nombre": "PUERTA LAT. CORREDERA IZQUIERDA",
          "idcrm": 149,
          
        },
        {
          "nombre": "PUERTA MALETERO D",
          "idcrm": 174,
          
        },
        {
          "nombre": "PUERTA MALETERO I",
          "idcrm": 174,
          
        },
        {
          "nombre": "PUERTA TRASERA IZQUIERDA DE CARGA",
          "idcrm": 151,
          
        },
        {
          "nombre": "PUERTA TRASERO DERECHA",
          "idcrm": 150,
          
        },
        {
          "nombre": "PUERTA TRASERO IZQUIERDA",
          "idcrm": 151,
          
        },
        {
          "nombre": "PUERTO USB",
          "idcrm": 1233,
          
        },
        {
          "nombre": "PULIMENTO",
          "idcrm": 15,
          
        },
        {
          "nombre": "PULMON FRENO",
          "idcrm": 15,
          
        },
        {
          "nombre": "PULMON REDUCTOR DE PRESION DE GAS",
          "idcrm": 15,
          
        },
        {
          "nombre": "PULMON SUSPENSION",
          "idcrm": 15,
          
        },
        {
          "nombre": "PULMON SUSPENSION CABINA TRASERO DERECHO",
          "idcrm": 15,
          
        },
        {
          "nombre": "PULMON SUSPENSION CABINA TRASERO IZQUIERDO",
          "idcrm": 15,
          
        },
        {
          "nombre": "PULMON SUSPENSION NEUMATICO DELANTERO DERECHO",
          "idcrm": 15,
          
        },
        {
          "nombre": "PULMON SUSPENSION NEUMATICO DELANTERO IZQUIERDO",
          "idcrm": 15,
          
        },
        {
          "nombre": "PULMON SUSPENSION NEUMATICO TRASERO DERECHO",
          "idcrm": 15,
          
        },
        {
          "nombre": "PULMON SUSPENSION NEUMATICO TRASERO IZQUIERDO",
          "idcrm": 15,
          
        },
        {
          "nombre": "PULSADOR ANTINIEBLAS",
          "idcrm": 1135,
          
        },
        {
          "nombre": "PULSADOR ARRANQUE",
          "idcrm": 1135,
          
        },
        {
          "nombre": "PULSADOR CIERRE",
          "idcrm": 1135,
          
        },
        {
          "nombre": "PULSADOR ESP",
          "idcrm": 1135,
          
        },
        {
          "nombre": "PULSADOR MALETERO",
          "idcrm": 1135,
          
        },
        {
          "nombre": "PUNTA CHASIS DELANTERA IZQUIERDA",
          "idcrm": 95,
          
        },
        {
          "nombre": "PUNTA CHASIS TRASERA DERECHA",
          "idcrm": 95,
          
        },
        {
          "nombre": "PUNTA PARAGOLPES DEL DCHA",
          "idcrm": 1506,
          
        },
        {
          "nombre": "PUNTA PARAGOLPES DEL IZDA",
          "idcrm": 1507,
          
        },
        {
          "nombre": "PUNTA PARAGOLPES DELANTERA DERECHA",
          "idcrm": 1506,
          
        },
        {
          "nombre": "PUNTA PARAGOLPES DELANTERA IZQUIERDA",
          "idcrm": 1507,
          
        },
        {
          "nombre": "PUNTA PARAGOLPES TRAS. DCH.",
          "idcrm": 1506,
          
        },
        {
          "nombre": "PUNTA PARAGOLPES TRAS. IZQ.",
          "idcrm": 1507,
          
        },
        {
          "nombre": "PUNTERA CHASIS DELANTERA DERECHA",
          "idcrm": 95,
          
        },
        {
          "nombre": "PUNTERA DELANTERO IZQUIERDA",
          "idcrm": 95,
          
        },
        {
          "nombre": "PUNTERA PARAGOLPES DELANTERA DERECHA",
          "idcrm": 1506,
          
        },
        {
          "nombre": "PUNTERA PARAGOLPES DELANTERA IZQUIERDA",
          "idcrm": 1507,
          
        },
        {
          "nombre": "PUNTERA PARAGOLPES DELANTERA IZQUIERDO",
          "idcrm": 1507,
          
        },
        {
          "nombre": "PUNTERA TRASERO DERECHA",
          "idcrm": 1506,
          
        },
        {
          "nombre": "PUNTERA TRASERO IZQUIERDA",
          "idcrm": 1507,
          
        },
        {
          "nombre": "PU�O ACELERADOR",
          "idcrm": 413,
          
        },
        {
          "nombre": "PU�O MOTO",
          "idcrm": 413,
          
        },
        {
          "nombre": "QUINTA RUEDA",
          "idcrm": 24,
          
        },
        {
          "nombre": "RACCORD AGUA",
          "idcrm": 437,
          
        },
        {
          "nombre": "RACCORD VACIO GASOIL",
          "idcrm": 437,
          
        },
        {
          "nombre": "RACOR RAPIDO",
          "idcrm": 437,
          
        },
        {
          "nombre": "RADAR DISTANCE SENSOR DISTRONIC",
          "idcrm": 289,
          
        },
        {
          "nombre": "RADAR FRONTAL",
          "idcrm": 289,
          
        },
        {
          "nombre": "RADIADOR",
          "idcrm": 415,
          
        },
        {
          "nombre": "RADIADOR ACEITE HIDRAULICO",
          "idcrm": 1508,
          
        },
        {
          "nombre": "RADIADOR AIRE ACONDICIONADO INTERIOR",
          "idcrm": 187,
          
        },
        {
          "nombre": "RADIADOR CALEFACCION INTERIOR",
          "idcrm": 193,
          
        },
        {
          "nombre": "RADIADOR LIQ HIDRAULICO",
          "idcrm": 1508,
          
        },
        {
          "nombre": "RADIO / CD",
          "idcrm": 290,
          
        },
        {
          "nombre": "RADIO CASETTE",
          "idcrm": 290,
          
        },
        {
          "nombre": "RADIO MP3,USB/SD BLUETHOOTH",
          "idcrm": 290,
          
        },
        {
          "nombre": "RADIOCD MP3-USB-BLUETOOTH",
          "idcrm": 290,
          
        },
        {
          "nombre": "RAMPA INYECTORES",
          "idcrm": 417,
          
        },
        {
          "nombre": "RECEPTOR SE�AL MANDO",
          "idcrm": 289,
          
        },
        {
          "nombre": "RECUBRIMIENTO BATERIA",
          "idcrm": 1164,
          
        },
        {
          "nombre": "RED MULTIUSOS MALETERO",
          "idcrm": 184,
          
        },
        {
          "nombre": "RED SEPARACION CARGA",
          "idcrm": 184,
          
        },
        {
          "nombre": "RED SUJETA EQUIPAJE",
          "idcrm": 184,
          
        },
        {
          "nombre": "REDUCTOR HUMO DIESEL ITV",
          "idcrm": 15,
          
        },
        {
          "nombre": "REDUCTOR RUIDO DE TAQUES HIDRAULICOS",
          "idcrm": 15,
          
        },
        {
          "nombre": "REFLECTANTE DELANTERO",
          "idcrm": 1191,
          
        },
        {
          "nombre": "REFLECTANTE PARAGOLPES TRASERO",
          "idcrm": 1191,
          
        },
        {
          "nombre": "REFLECTANTE TRASERO DERECHO",
          "idcrm": 1191,
          
        },
        {
          "nombre": "REFLECTANTE TRASERO IZQUIERDO",
          "idcrm": 1191,
          
        },
        {
          "nombre": "REFLECTOR",
          "idcrm": 1191,
          
        },
        {
          "nombre": "REFLECTOR DE PARAGOLPES TRASERO",
          "idcrm": 1191,
          
        },
        {
          "nombre": "REFLECTOR DERECHO DE PARAGOLPES TRASERO",
          "idcrm": 1191,
          
        },
        {
          "nombre": "REFLECTOR IZQUIERDO PARAGOLPES TRASERO",
          "idcrm": 1191,
          
        },
        {
          "nombre": "REFRIGERADOR ACEITE",
          "idcrm": 414,
          
        },
        {
          "nombre": "REFUERZO",
          "idcrm": 96,
          
        },
        {
          "nombre": "REFUERZO PARAGOLPES",
          "idcrm": 96,
          
        },
        {
          "nombre": "REFUERZO PARAGOLPES IZQUIERDO",
          "idcrm": 96,
          
        },
        {
          "nombre": "REFUERZO SUSPENSION",
          "idcrm": 96,
          
        },
        {
          "nombre": "REGULADOR ALTERNADOR",
          "idcrm": 1520,
          
        },
        {
          "nombre": "REGULADOR ALTURA DE FAROS",
          "idcrm": 1520,
          
        },
        {
          "nombre": "REGULADOR ALTURA FAROS XENON",
          "idcrm": 1520,
          
        },
        {
          "nombre": "REGULADOR DE CORRIENTE",
          "idcrm": 1520,
          
        },
        {
          "nombre": "REGULADOR DE TENSI�N",
          "idcrm": 1520,
          
        },
        {
          "nombre": "REGULADOR LUCES",
          "idcrm": 1520,
          
        },
        {
          "nombre": "REGULADOR LUCES FARO DELANTERO",
          "idcrm": 1520,
          
        },
        {
          "nombre": "REGULADOR LUCES FAROS",
          "idcrm": 1520,
          
        },
        {
          "nombre": "REGULADOR LUCES FAROS TRASERO",
          "idcrm": 1520,
          
        },
        {
          "nombre": "REGULADOR PRESION COMBUSTIBLE",
          "idcrm": 1520,
          
        },
        {
          "nombre": "REGULADOR PRESION COMMON RAIL",
          "idcrm": 1520,
          
        },
        {
          "nombre": "REGULADOR PRESION INYECCION",
          "idcrm": 1520,
          
        },
        {
          "nombre": "REGULADOR VENTILADOR CALEFACCION",
          "idcrm": 1520,
          
        },
        {
          "nombre": "REGULADOR VENTILADOR CLIMATIZACION",
          "idcrm": 1520,
          
        },
        {
          "nombre": "REJILLA AIREADORA DERECHA",
          "idcrm": 357,
          
        },
        {
          "nombre": "REJILLA AIREADORA IZQUIERDA",
          "idcrm": 357,
          
        },
        {
          "nombre": "REJILLA CAPO",
          "idcrm": 1527,
          
        },
        {
          "nombre": "REJILLA CAPO DER",
          "idcrm": 1527,
          
        },
        {
          "nombre": "REJILLA CAPO DERECHA",
          "idcrm": 1527,
          
        },
        {
          "nombre": "REJILLA CAPO IZQ",
          "idcrm": 1527,
          
        },
        {
          "nombre": "REJILLA CAPO IZQUIERDA",
          "idcrm": 1527,
          
        },
        {
          "nombre": "REJILLA CAPOT",
          "idcrm": 1527,
          
        },
        {
          "nombre": "REJILLA CENTRAL PARAGOLPES",
          "idcrm": 98,
          
        },
        {
          "nombre": "REJILLA DER PARAGOLPES",
          "idcrm": 1525,
          
        },
        {
          "nombre": "REJILLA ENTRADA DE AIRE",
          "idcrm": 97,
          
        },
        {
          "nombre": "REJILLA FRENTE DERECHA",
          "idcrm": 100,
          
        },
        {
          "nombre": "REJILLA FRENTE IZQUIERDA",
          "idcrm": 101,
          
        },
        {
          "nombre": "REJILLA INTERIOR MALETERO",
          "idcrm": 357,
          
        },
        {
          "nombre": "REJILLA IZQ PARAGOLPES",
          "idcrm": 101,
          
        },
        {
          "nombre": "REJILLA LATERAL IZQ. NUEVA",
          "idcrm": 101,
          
        },
        {
          "nombre": "REJILLA NUEVA",
          "idcrm": 357,
          
        },
        {
          "nombre": "REJILLA PARAGOLPES DELANTERO DERECHA",
          "idcrm": 100,
          
        },
        {
          "nombre": "REJILLA PARAGOLPES DELANTERO IZQUIERDA",
          "idcrm": 101,
          
        },
        {
          "nombre": "REJILLA PARAGOLPES DERECHO",
          "idcrm": 100,
          
        },
        {
          "nombre": "REJILLA PARAGOLPES IZQUIERDO",
          "idcrm": 101,
          
        },
        {
          "nombre": "REJILLA PARAGOLPES IZQUIRDA",
          "idcrm": 101,
          
        },
        {
          "nombre": "REJILLA SEPARACION CARGA",
          "idcrm": 184,
          
        },
        {
          "nombre": "REJILLA SEPARADOR CARGA",
          "idcrm": 184,
          
        },
        {
          "nombre": "RELE ARRANQUE",
          "idcrm": 287,
          
        },
        {
          "nombre": "RELE BOMBA COMBUSTIBLE",
          "idcrm": 287,
          
        },
        {
          "nombre": "REL� ELECTROVENTILADORES",
          "idcrm": 287,
          
        },
        {
          "nombre": "RELE LIMPIAPARABRISAS",
          "idcrm": 287,
          
        },
        {
          "nombre": "RELE MANDO CALEFACCION",
          "idcrm": 287,
          
        },
        {
          "nombre": "RELE MANDO DE CALEFACCION",
          "idcrm": 287,
          
        },
        {
          "nombre": "RELE RALENTI",
          "idcrm": 287,
          
        },
        {
          "nombre": "RELE TEAMPORIZADOR",
          "idcrm": 287,
          
        },
        {
          "nombre": "RELE VENTILADORES MOTOR",
          "idcrm": 287,
          
        },
        {
          "nombre": "RELOJ CUENTA REVOLUCIONES",
          "idcrm": 1531,
          
        },
        {
          "nombre": "RELOJ DIGITAL",
          "idcrm": 1531,
          
        },
        {
          "nombre": "RELOJ HORARIO",
          "idcrm": 1531,
          
        },
        {
          "nombre": "RELOJ MULTIFUNCION",
          "idcrm": 1531,
          
        },
        {
          "nombre": "RELOJ PRESION ACEITE",
          "idcrm": 1531,
          
        },
        {
          "nombre": "RELOJES CONSOLA",
          "idcrm": 1531,
          
        },
        {
          "nombre": "REPOSA CABEZAS",
          "idcrm": 359,
          
        },
        {
          "nombre": "REPOSA VASOS",
          "idcrm": 1536,
          
        },
        {
          "nombre": "REPOSABRAZOS",
          "idcrm": 308,
          
        },
        {
          "nombre": "REPSOL LEADER A3/B4 10W-40 5L",
          "idcrm": 15,
          
        },
        {
          "nombre": "RESBALON",
          "idcrm": 15,
          
        },
        {
          "nombre": "RESISTENCIA DE CALEFACCION",
          "idcrm": 194,
          
        },
        {
          "nombre": "RESISTENCIA ELECTROVENTILADOR",
          "idcrm": 194,
          
        },
        {
          "nombre": "RESISTENCIA ELECTROVENTILADORES",
          "idcrm": 194,
          
        },
        {
          "nombre": "RESISTENCIA VENTILADOR",
          "idcrm": 194,
          
        },
        {
          "nombre": "RESISTENCIA VENTILADOR MOTOR",
          "idcrm": 194,
          
        },
        {
          "nombre": "RESPALDO ASIENTO TRASERO",
          "idcrm": 194,
          
        },
        {
          "nombre": "RETEN ARBOL DE LEVAS",
          "idcrm": 1538,
          
        },
        {
          "nombre": "RETEN CIGUE�AL",
          "idcrm": 1538,
          
        },
        {
          "nombre": "RETEN CIGUE�AL DERECHO",
          "idcrm": 1538,
          
        },
        {
          "nombre": "RETEN CIGUE�AL IZQUIERDO",
          "idcrm": 1538,
          
        },
        {
          "nombre": "RETEN DIFERENCIAL",
          "idcrm": 1538,
          
        },
        {
          "nombre": "RETEN TRANSMISION",
          "idcrm": 1538,
          
        },
        {
          "nombre": "RETENEDOR DELANTERO DERECHO",
          "idcrm": 152,
          
        },
        {
          "nombre": "RETENEDOR PUERTA DELANTERA DERECHA",
          "idcrm": 152,
          
        },
        {
          "nombre": "RETENEDOR PUERTA DELANTERA IZQUIERDA",
          "idcrm": 152,
          
        },
        {
          "nombre": "RETENEDOR PUERTA TRASERA DERECHA",
          "idcrm": 152,
          
        },
        {
          "nombre": "RETENEDOR PUERTA TRASERA IZQUIERDA",
          "idcrm": 152,
          
        },
        {
          "nombre": "RETOVISOR INTERIOR",
          "idcrm": 327,
          
        },
        {
          "nombre": "RETROVISOR DERECHO NO",
          "idcrm": 153,
          
        },
        {
          "nombre": "RETROVISOR ELECTRICO DERECHO",
          "idcrm": 153,
          
        },
        {
          "nombre": "RETROVISOR ELECTRICO IZQUIERDO",
          "idcrm": 154,
          
        },
        {
          "nombre": "RETROVISOR ELECTRICO IZQUIERDO NO",
          "idcrm": 154,
          
        },
        {
          "nombre": "RETROVISOR INTERIOR",
          "idcrm": 327,
          
        },
        {
          "nombre": "RETROVISOR IZQ.",
          "idcrm": 154,
          
        },
        {
          "nombre": "REVESTIMIENTO BAJOS",
          "idcrm": 144,
          
        },
        {
          "nombre": "RODAMIENTO TRASERO",
          "idcrm": 15,
          
        },
        {
          "nombre": "RODAMIENTOS",
          "idcrm": 15,
          
        },
        {
          "nombre": "RODILLO TENSOR",
          "idcrm": 435,
          
        },
        {
          "nombre": "RUEDA COMPLETA",
          "idcrm": 24,
          
        },
        {
          "nombre": "RUEDA COMPLETA REPUESTO",
          "idcrm": 24,
          
        },
        {
          "nombre": "RUEDA DE GALLETA",
          "idcrm": 24,
          
        },
        {
          "nombre": "RUEDA DE RECAMBIO",
          "idcrm": 24,
          
        },
        {
          "nombre": "RUEDA GALLETA",
          "idcrm": 24,
          
        },
        {
          "nombre": "SALIDA COLECTOR",
          "idcrm": 418,
          
        },
        {
          "nombre": "SECCIONADOR BATERIA",
          "idcrm": 1269,
          
        },
        {
          "nombre": "SELECTOR CAJA CAMBIOS",
          "idcrm": 57,
          
        },
        {
          "nombre": "SELECTOR CAMBIO AUTOMATICO",
          "idcrm": 57,
          
        },
        {
          "nombre": "SELECTOR DE CAMBIO",
          "idcrm": 57,
          
        },
        {
          "nombre": "SELLADOR DE FUGAS CON ADITIVO UV",
          "idcrm": 15,
          
        },
        {
          "nombre": "SELLADOR NEUMATICOS",
          "idcrm": 15,
          
        },
        {
          "nombre": "SELLANTE BOMBA COMBUSTIBLE",
          "idcrm": 15,
          
        },
        {
          "nombre": "SENSON CONTROL ESTABILIDAD",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR ABS CUENTARREVOLUCIONES TRASERO",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR ABS DELANTERO DERECHO",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR ABS TRASERO DERECHO",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR ABS TRASERO IZQUIERDO",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR ACEITE MOTOR",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR AIRBAG LATERAL DELANTERO DERECHO",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR AIRBAG LATERAL DELANTERO IZQUIERDO",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR AIRBAG SALPICADERO",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR ALTURA XENON",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR ANGULO DE GIRO",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR ARBOL DE LEVAS",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR ARBOL VALVETRONIC",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR BOMBA INYECTORA",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR CENTRAL ESTABILIDAD ESP",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR CIGUE�AL",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR CIQUE�AL",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR CONTROL DE AMORTIGUACION",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR CUENTA KILOMETROS",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR CUENTAKILOMETROS",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR DE ABS DELANTERO IZQUIERDO",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR DE BUJIA",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR DE LLUVIA",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR DE PRESION, COLECTOR ADMISION",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR DE VACIO AIRE",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR DECELERACION AIRBAG",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR ESP",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR FRENO",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR GIRO",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR IMPACTO LATERAL",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR LLUVIA",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR LUCES",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR NIVEL ACEITE MOTOR",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR NIVEL REFRIGERANTE",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR PEDAL FRENO",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR PRESION AIRE ACONDICIONADO ( A/C)",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR PRESION COMBUSTIBLE",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR PRESION DE ACEITE",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR PRESION NEUMATICO",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR RAMPA INYECCION",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR SUSPENSION",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR TEMPERATURA CLIMA",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR TEMPERATURA ESCAPE",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR TEMPERATURA REFRIGERANTE",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR VALVULA EGR",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR, CIGUE�AL",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR, NIVEL DE ACEITE DEL MOTOR",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR, PRESION COLECTOR DE ADMISION",
          "idcrm": 289,
          
        },
        {
          "nombre": "SENSOR, VELOCIDAD",
          "idcrm": 289,
          
        },
        {
          "nombre": "SEPARADOR",
          "idcrm": 1566,
          
        },
        {
          "nombre": "SEPARADOR CARGA",
          "idcrm": 1566,
          
        },
        {
          "nombre": "SEPARADOR DE ACEITE,AIREACION CARTER ACEITE",
          "idcrm": 424,
          
        },
        {
          "nombre": "SIERRA VAIVEN",
          "idcrm": 15,
          
        },
        {
          "nombre": "SILEMBLOG BRAZO SUSPENSION DELANTERO",
          
          "Familia": 12
        },
        {
          "nombre": "SILENBLOKS DEL MOTOR",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SILENBLOKS MOTOR",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SILENCIADOR DE AIRE",
          "idcrm": 420,
          
        },
        {
          "nombre": "SILENCIADOR ESCAPE TRASERO",
          "idcrm": 420,
          
        },
        {
          "nombre": "SILENCIADOR INTERMEDIO",
          "idcrm": 420,
          
        },
        {
          "nombre": "SILENCIADOR TURBO",
          "idcrm": 420,
          
        },
        {
          "nombre": "SILICONA EN",
          "idcrm": 15,
          
        },
        {
          "nombre": "SILICONA WOLFPACK",
          "idcrm": 15,
          
        },
        {
          "nombre": "SILLA COXYGEN",
          "idcrm": 15,
          
        },
        {
          "nombre": "Sineblock amortiguador delantero",
          "idcrm": 15,
          
        },
        {
          "nombre": "SINEMBLOK DE BRAZO",
          "idcrm": 15,
          
        },
        {
          "nombre": "SIRGA DE EMBRAGUE",
          "idcrm": 1146,
          
        },
        {
          "nombre": "SIRGAS DEL CAMBIO",
          "idcrm": 1149,
          
        },
        {
          "nombre": "SIRGAS PALANCA CAMBIOS",
          "idcrm": 1149,
          
        },
        {
          "nombre": "SISTEMA AUDIO / CD",
          "idcrm": 290,
          
        },
        {
          "nombre": "SISTEMA DE CONTROL ELECTROHIDR�ULICO",
          "idcrm": 1192,
          
        },
        {
          "nombre": "SISTEMA ELECTRICO ENGANCHE REMOLQUE",
          "idcrm": 1192,
          
        },
        {
          "nombre": "SISTEMA TELEFONO",
          "idcrm": 1192,
          
        },
        {
          "nombre": "SONDA LAMBDA MOTOR -RFK",
          "idcrm": 293,
          
        },
        {
          "nombre": "SOPLETE MAURER",
          "idcrm": 15,
          
        },
        {
          "nombre": "SOPORTE AIRE ACONDICIONADO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE ALETA DELANTERA IZQUIERDA",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE ALETA DERECHARA",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE ALETA IZQUIERDA",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE AMORTIGUADOR",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE AMORTIGUADOR DELANTERO DERECHO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE AMORTIGUADOR DELANTERO IZQUIERDO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE ARBOL DE LEVAS",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE BANDEJA TRASERA",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE BOMBA AGUA",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE BOMBA DIRECCION",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE BOMBA SERVODIRECCION",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE BRAZO SUSPENSION DELANTERO IZQUIERDO",
          "idcrm": 505,
          
        },
        {
          "nombre": "SOPORTE BRAZO SUSPENSION TRASERO DERECHO",
          "idcrm": 506,
          
        },
        {
          "nombre": "SOPORTE BRAZO SUSPENSION TRASERO IZQUIERDO",
          "idcrm": 507,
          
        },
        {
          "nombre": "SOPORTE CABINA TRASERO DERECHO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE CABINA TRASERO IZQUIERDO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE CABRESTANTE",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE CAJA CAMBIO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE CAJA CAMBIOS INFERIOR",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE CAJA CAMBIOS IZQUIERDO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE CAJA CAMBIOS SUPERIOR",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE CAMBIO IZQUIERDO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE CENTRAL SALPICADERO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE COMPRESOR AIRE ACONDICIONADO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE COMPRESOR DE AIRE ACONDICIONADO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE CONJUNTO MANDOS",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE CONSOLA CENTRAL",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE CUADRO INSTRUMENTOS",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE DE BATERIA",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE DE CABINA DELANTERO DERECHO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE DE CABINA DELANTERO IZQUIERDO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE DE PARAGOLPE",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE DE PARAGOLPE TRASERO DERECHO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE DE PARAGOLPE TRASERO IZQUIERDO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE DE RUEDA REPUESTO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE DEPRESOR FRENO/BOMBA VACIO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE DERECHO DE PARAGOLPES",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE DERECHO MOTOR",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE DERECHO PARAGOLPES DELANTERO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE DIFERENCIAL",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE EJE TRANSMISION",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE ELECTROVENTILADOR",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE EMBLEMA REJILLA",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE ENFRIADOR DE GASES",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE ESCAPE",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE FILTRO AIRE",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE IZQUIERDO PARAGOLPES DELANTERO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE IZQUIERDO PUENTE TRASERO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE MALETERO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE MANETA EXTERIOR DELANTERA DERECHA",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE MANETA EXTERIOR DELANTERA IZQUIERDA",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE MANETA EXTERIOR TRASERA DERECHA",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE MANETA EXTERIOR TRASERA IZQUIERDA",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE MANGUETA DELANTERA DERECHA (*)",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE MANGUETA DELANTERA IZQUIERDA (*)",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE MOTOR DERECHO INFERIOR",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE MOTOR DERECHO SUPERIOR",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE MOTOR IZQUIERDO INFERIOR",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE MOTOR IZQUIERDO SUPERIOR",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE P/",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE PARA MOTORES 50840",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE PARAGOLPES DELANTERO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE PARAGOLPES TRASERO DERECHO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE PARAGOLPES TRASERO IZQUIERDA",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE PARAGOPES TRASERO INFERIOR",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE PARAGOPES TRASERO SUPERIOR",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE PEDALERA",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE PUERTA CORREDERA",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE QUINTA RUEDA",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE RADIADOR",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE RADIO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE RAMPA INYECTORA",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE REFRIGERADOR ACEITE MOTOR",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE RETROVISOR",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE RUEDA RECAMBIOS",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE SILENCIOSO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE SUBCHASIS",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE TELEFONO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE TENAZAS",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE TOPE DE GOMA",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE TRANSMISION",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE TRANSMISION CENTRAL",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE TURBOCOMPRESOR",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE VALVULA EGR",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SOPORTE VERTICAL",
          "idcrm": 1568,
          
        },
        {
          "nombre": "SPOILER CABINA",
          "idcrm": 104,
          
        },
        {
          "nombre": "SPOILER TALONERA LAT. DERECHA",
          "idcrm": 1575,
          
        },
        {
          "nombre": "SPOILER TALONERA LAT. IZQUIERDA",
          "idcrm": 1576,
          
        },
        {
          "nombre": "SPRAY ADMISION",
          "idcrm": 15,
          
        },
        {
          "nombre": "SUBCHASIS TRASERO",
          "idcrm": 1259,
          
        },
        {
          "nombre": "SUBWOOFER",
          "idcrm": 1578,
          
        },
        {
          "nombre": "SUPER ADITIVO DIESEL  PRE ITV",
          "idcrm": 15,
          
        },
        {
          "nombre": "SURTIDOR AGUA",
          "idcrm": 437,
          
        },
        {
          "nombre": "SURTIDOR DE AIRE",
          "idcrm": 437,
          
        },
        {
          "nombre": "SUSPENCION NEOMATICA",
          "idcrm": 488,
          
        },
        {
          "nombre": "SUSPENSION NEUMATICA",
          "idcrm": 488,
          
        },
        {
          "nombre": "TACO",
          "idcrm": 1568,
          
        },
        {
          "nombre": "TACO MOTOR DE DISTRIBUCION",
          "idcrm": 1568,
          
        },
        {
          "nombre": "TAC�GRAFO",
          "idcrm": 15,
          
        },
        {
          "nombre": "TALADRO YAMATO",
          "idcrm": 15,
          
        },
        {
          "nombre": "TALONERA DELANTERA IZQUIERDA",
          "idcrm": 1575,
          
        },
        {
          "nombre": "TALONERA LATERAL",
          "idcrm": 1575,
          
        },
        {
          "nombre": "TAMBOR FRENO DELANTERO",
          "idcrm": 508,
          
        },
        {
          "nombre": "TANQUE RECEPTOR",
          "idcrm": 87,
          
        },
        {
          "nombre": "TAPA",
          "idcrm": 1588,
          
        },
        {
          "nombre": "TAPA CAJA FUSIBLES",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA CAJA RELES",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA CILINDRO",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA CUADRO INSTRUMENTOS",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA DE ANTINIEBLA",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA DE FARO",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA DE FARO NIEBLA DD",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA DELCO",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA DEPOSITO COMBUSTIBLE",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA DEPOSITO LIMPIA PARABRISAS",
          "idcrm": 156,
          
        },
        {
          "nombre": "Tapa depososito limpia parabrisa",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA DISTRIBUCION EXTERIOR",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA DISTRIBUCION INTERIOR",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA EXTERIOR CONECTOR CARGA",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA EYECTOR LAVAFAROS DERECHO",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA FILTRO ACEITE",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA FUGAS DE ACEITE",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA FUSIBLES",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA GUANTERA",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA PARAGOLPES TRASERO",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA RETROVISOR DERECHO",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA RETROVISOR IZQUIERDO",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA RUEDA DE REPUESTO",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA SUPERIOR MOTOR",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA VOLANTE",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA VOLANTE INFERIOR",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPA VOLANTE SUPERIOR",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPACUBO",
          "idcrm": 25,
          
        },
        {
          "nombre": "TAPACUBO LLANTA ALUMINIO",
          "idcrm": 25,
          
        },
        {
          "nombre": "TAPACUBOS GY FLEXO 20 PLATA 14",
          "idcrm": 25,
          
        },
        {
          "nombre": "TAPACUBOS GY FLEXO 30 PLATA 14",
          "idcrm": 25,
          
        },
        {
          "nombre": "TAPACUBOS LLANTA ALUMINIO",
          "idcrm": 25,
          
        },
        {
          "nombre": "TAPACUBOS R15",
          "idcrm": 25,
          
        },
        {
          "nombre": "TAPACUBOS R16",
          "idcrm": 25,
          
        },
        {
          "nombre": "TAPIZADO DE TECHO",
          "idcrm": 105,
          
        },
        {
          "nombre": "TAPIZADO LATERAL DERECHO",
          "idcrm": 1593,
          
        },
        {
          "nombre": "TAPIZADO LATERAL IZQUIERDO",
          "idcrm": 1594,
          
        },
        {
          "nombre": "TAPIZADO TECHO",
          "idcrm": 105,
          
        },
        {
          "nombre": "TAPON AMORTIGUADOR",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPON DE ACEITE MOTOR",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPON DE BOTE EXPANSOR",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPON DE COMBUSTIBLE",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPON RADIADOR AGUA",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPON REFRIGERANTE",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPON VASO EXPANSION",
          "idcrm": 156,
          
        },
        {
          "nombre": "TAPONES TORNILLOS RUEDA",
          "idcrm": 156,
          
        },
        {
          "nombre": "TARJETERO",
          "idcrm": 15,
          
        },
        {
          "nombre": "TECHO CHAPA (CORTE)",
          "idcrm": 105,
          
        },
        {
          "nombre": "TECHO INTERIOR ELECTRICO",
          "idcrm": 106,
          
        },
        {
          "nombre": "TENAZAS ABERT.MULTI.MAURER",
          "idcrm": 15,
          
        },
        {
          "nombre": "TENAZAS RUSAS",
          "idcrm": 15,
          
        },
        {
          "nombre": "TENSOR CORREA DENTADA",
          "idcrm": 435,
          
        },
        {
          "nombre": "TENSOR CORREA DISTRIBUCION",
          "idcrm": 435,
          
        },
        {
          "nombre": "TENSOR DE CORREA DITRIBUCION",
          "idcrm": 435,
          
        },
        {
          "nombre": "TENSOR HIDRAULICO DISTRIBUCION",
          "idcrm": 435,
          
        },
        {
          "nombre": "TERCERA LUZ DE FRENO",
          "idcrm": 44,
          
        },
        {
          "nombre": "TIJA",
          "idcrm": 15,
          
        },
        {
          "nombre": "TIJERA CHAPA",
          "idcrm": 15,
          
        },
        {
          "nombre": "TIRA REPARADORA DE ESCAPES",
          "idcrm": 15,
          
        },
        {
          "nombre": "TIRADOR APERTURA COMBUSTIBLE",
          "idcrm": 1599,
          
        },
        {
          "nombre": "TIRADOR APERTURA TAPA COMBUSTIBLE",
          "idcrm": 1599,
          
        },
        {
          "nombre": "TIRADOR ASIENTO",
          "idcrm": 1599,
          
        },
        {
          "nombre": "TIRADOR ASIENTO DERECHO",
          "idcrm": 1599,
          
        },
        {
          "nombre": "TIRADOR ASIENTO IZQUIERDO",
          "idcrm": 1599,
          
        },
        {
          "nombre": "TIRADOR CAPOT",
          "idcrm": 1599,
          
        },
        {
          "nombre": "TIRADOR DE CAPOT INTERIOR",
          "idcrm": 1599,
          
        },
        {
          "nombre": "TIRADOR PORTON",
          "idcrm": 1599,
          
        },
        {
          "nombre": "TIRADOR TECHO TRASERO DERECHO",
          "idcrm": 1599,
          
        },
        {
          "nombre": "TIRADOR TECHO TRASERO IZQUIERDO",
          "idcrm": 1599,
          
        },
        {
          "nombre": "TIRANTA MOTOR INFERIOR",
          "idcrm": 15,
          
        },
        {
          "nombre": "TIRANTE APERTURA CAPOT",
          "idcrm": 15,
          
        },
        {
          "nombre": "TIRANTE DELANTERO",
          "idcrm": 509,
          
        },
        {
          "nombre": "TIRANTE EJE TRASERO",
          "idcrm": 511,
          
        },
        {
          "nombre": "TIRANTE SUSPENSION",
          "idcrm": 509,
          
        },
        {
          "nombre": "TIRANTI BARRA ESTABELIZADOR DERECHO",
          "idcrm": 1600,
          
        },
        {
          "nombre": "TOBERA CENTRAL IZQUIERDA VENTILACION SALPICADERO",
          "idcrm": 437,
          
        },
        {
          "nombre": "TOBERA DERECHA VENTILACION SALPICADERO",
          "idcrm": 437,
          
        },
        {
          "nombre": "TOBERA IZQUIERDA VENTILACION SALPICADERO",
          "idcrm": 437,
          
        },
        {
          "nombre": "TOBERA SUPERIOR CENTRAL VENTILACION SALPICADERO",
          "idcrm": 437,
          
        },
        {
          "nombre": "TOMA 12 V MECHERO",
          "idcrm": 1606,
          
        },
        {
          "nombre": "TOMA DE CORRIENTE 12V",
          "idcrm": 1606,
          
        },
        {
          "nombre": "TOPE AMORTIGUADOR",
          "idcrm": 1243,
          
        },
        {
          "nombre": "TOPE DE PUERTA DELANTERA DERECHA",
          "idcrm": 152,
          
        },
        {
          "nombre": "TOPE DE PUERTA TRASERO DERECHO",
          "idcrm": 152,
          
        },
        {
          "nombre": "TOPE DE PUERTA TRASERO IZQUIERDO",
          "idcrm": 152,
          
        },
        {
          "nombre": "TOPE DE SUSPENSION",
          "idcrm": 1243,
          
        },
        {
          "nombre": "TOPE PUERTA",
          "idcrm": 152,
          
        },
        {
          "nombre": "TOPE PUERTA TRASERA DERECHA",
          "idcrm": 152,
          
        },
        {
          "nombre": "TOPE PUERTA TRASERA IZQUIERDAD",
          "idcrm": 152,
          
        },
        {
          "nombre": "TOPE SUSPENSION TRASERO DERECHO",
          "idcrm": 152,
          
        },
        {
          "nombre": "TOPE SUSPENSION TRASERO IZQUIERDO",
          "idcrm": 152,
          
        },
        {
          "nombre": "TORNILLERIA",
          "idcrm": 1607,
          
        },
        {
          "nombre": "TORNILLO BANCO",
          "idcrm": 1607,
          
        },
        {
          "nombre": "TORNILLO RUEDA",
          "idcrm": 1607,
          
        },
        {
          "nombre": "TORNILLOS ANTIRROBO",
          "idcrm": 1607,
          
        },
        {
          "nombre": "TORNILLOS CULATA",
          "idcrm": 1607,
          
        },
        {
          "nombre": "TORNILLOS NORMALES",
          "idcrm": 1607,
          
        },
        {
          "nombre": "TORNIQUETE PROF.",
          "idcrm": 15,
          
        },
        {
          "nombre": "TRADUCTOR DE PRESION TURBO",
          "idcrm": 442,
          
        },
        {
          "nombre": "TRAMO ESCAPE",
          "idcrm": 438,
          
        },
        {
          "nombre": "TRAMPA AVISPAS",
          "idcrm": 15,
          
        },
        {
          "nombre": "TRANSDUCTOR DE PRESION, CONTROL DE GASES DE ESCAPE",
          "idcrm": 442,
          
        },
        {
          "nombre": "TRANSEJE HYBRID",
          "idcrm": 55,
          
        },
        {
          "nombre": "TRANSFERENCIA DE VEHICULO",
          "idcrm": 55,
          
        },
        {
          "nombre": "TRANSFORMADOR 24V A 12V",
          "idcrm": 1192,
          
        },
        {
          "nombre": "TRANSFORMADOR HIBRIDO",
          "idcrm": 1192,
          
        },
        {
          "nombre": "TRANSMISION CENTRAL TRASERA",
          "idcrm": 206,
          
        },
        {
          "nombre": "TRANSMISION DEL CORTA",
          "idcrm": 219,
          
        },
        {
          "nombre": "TRANSMISION DELANTERO DERECHA",
          "idcrm": 220,
          
        },
        {
          "nombre": "TRANSMISION DELANTERO IZQUIERDA",
          "idcrm": 221,
          
        },
        {
          "nombre": "TRANSMISION TRASERA",
          "idcrm": 206,
          
        },
        {
          "nombre": "TRATAMIENTO ANTIFRICCION",
          "idcrm": 15,
          
        },
        {
          "nombre": "TRATAMIENTO INYECCION GASOLINA",
          "idcrm": 15,
          
        },
        {
          "nombre": "TRAVESA�O INFERIOR",
          "idcrm": 108,
          
        },
        {
          "nombre": "TRAVESA�O SUPERIOR",
          "idcrm": 109,
          
        },
        {
          "nombre": "TRAVIESA DE PARAGOLPES",
          "idcrm": 1611,
          
        },
        {
          "nombre": "TRAVIESA DEL PARAGOLPES",
          "idcrm": 1611,
          
        },
        {
          "nombre": "TRAVIESA DELANTERA",
          "idcrm": 1611,
          
        },
        {
          "nombre": "TRAVIESA TRASERA",
          "idcrm": 1611,
          
        },
        {
          "nombre": "TRIANGULO EMERGENCIAS",
          "idcrm": 26,
          
        },
        {
          "nombre": "TRINQUETE",
          "idcrm": 15,
          
        },
        {
          "nombre": "TUBERIA",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBERIA AIRE",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBERIA COMBUSTIBLE",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBERIA DE TURBO A INTERCOOLER",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBERIA DIRECCI�N",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBERIA REFRIGERACION",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBERIA SALIDA COMBUSTIBLE",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBERIAS AIRE ACONDICIONADO",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBO ACEITE TURBOCOMPRESOR",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBO ADMISION",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBO ASPIRACION DE ACEITE",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBO DE ADMISION DE AIRE",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBO DE AIRE",
          "idcrm": 437,
          
        },
        {
          "nombre": "tubo de filtro de aire",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBO DE GASES",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBO DE SUSPENSION DE BOMBA DEL COMPRESOR",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBO DE TURBO",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBO DIRECCION",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBO EGR",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBO ENTRADA AIRE",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBO FLEXIBLE DE FRENOS",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBO INTERCOOLER",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBO LLENADO",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBO RETORNO SOBRANTE INYECTORES",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBO TURBOCOMPRESOR",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBO VACIO DE BLOQUE DE VALVULAS",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBO VACIO FRENO",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBO VAPORES ACEITE",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUBULADORA DE ASPIRACION",
          "idcrm": 437,
          
        },
        {
          "nombre": "TUERCAS RUEDAS",
          "idcrm": 1607,
          
        },
        {
          "nombre": "TULIPA",
          "idcrm": 44,
          
        },
        {
          "nombre": "TULIPA PILOTO",
          "idcrm": 44,
          
        },
        {
          "nombre": "TULIPA PILOTO TRAS. IVECO CHASIS CABINA",
          "idcrm": 44,
          
        },
        {
          "nombre": "TULIPA PILOTO TRASERO ( DERECHO E IZQUIERDO )",
          "idcrm": 44,
          
        },
        {
          "nombre": "TULIPA PILOTO TRASERO IZQUIERDO",
          "idcrm": 44,
          
        },
        {
          "nombre": "TUNING",
          "idcrm": 27,
          
        },
        {
          "nombre": "TURBO",
          "idcrm": 443,
          
        },
        {
          "nombre": "UCH",
          "idcrm": 15,
          
        },
        {
          "nombre": "undefined",
          "idcrm": 15,
          
        },
        {
          "nombre": "UNIDAD BISAGRA CAPOT 15E",
          "idcrm": 1098,
          
        },
        {
          "nombre": "UNIDAD CONTROL",
          "idcrm": 1192,
          
        },
        {
          "nombre": "UNIDAD CONTROL CRUCERO",
          "idcrm": 1192,
          
        },
        {
          "nombre": "UNIDAD CONTROL ECU",
          "idcrm": 1192,
          
        },
        {
          "nombre": "UNIDAD CONTROL FARO",
          "idcrm": 1192,
          
        },
        {
          "nombre": "UNIDAD CONTROL FARO DERECHO",
          "idcrm": 1192,
          
        },
        {
          "nombre": "UNIDAD CONTROL FARO IZQUIERDO",
          "idcrm": 1192,
          
        },
        {
          "nombre": "UNIDAD CONTROL XENON",
          "idcrm": 1192,
          
        },
        {
          "nombre": "UTIL ACELEDADOR",
          "idcrm": 15,
          
        },
        {
          "nombre": "VACUUM RESERVOIR",
          "idcrm": 483,
          
        },
        {
          "nombre": "VALE DESCUENTO",
          "idcrm": 15,
          
        },
        {
          "nombre": "VALVULA AIRE",
          "idcrm": 444,
          
        },
        {
          "nombre": "VALVULA ANTIRETORNO COMBUSTIBLE",
          "idcrm": 444,
          
        },
        {
          "nombre": "VALVULA ARRANQUE FRIO",
          "idcrm": 446,
          
        },
        {
          "nombre": "VALVULA CANISTER",
          "idcrm": 444,
          
        },
        {
          "nombre": "VALVULA DE RELENTI",
          "idcrm": 446,
          
        },
        {
          "nombre": "VALVULA DE TURBO",
          "idcrm": 444,
          
        },
        {
          "nombre": "VALVULA DE VACIO",
          "idcrm": 444,
          
        },
        {
          "nombre": "VALVULA DESCARGA TURBO",
          "idcrm": 444,
          
        },
        {
          "nombre": "VALVULA LIMITADOR",
          "idcrm": 444,
          
        },
        {
          "nombre": "VALVULA NEUMATICA SUSPENSION",
          "idcrm": 444,
          
        },
        {
          "nombre": "VALVULA PRESION DEL TURBO",
          "idcrm": 444,
          
        },
        {
          "nombre": "VALVULA REGULACI�N TURBO",
          "idcrm": 444,
          
        },
        {
          "nombre": "VALVULA RELE",
          "idcrm": 444,
          
        },
        {
          "nombre": "VALVULA SUSPENSION HIDRAULICA",
          "idcrm": 444,
          
        },
        {
          "nombre": "VALVULA SUSPENSION NEMATICA",
          "idcrm": 444,
          
        },
        {
          "nombre": "VALVULA SUSPENSION NEUMATICA",
          "idcrm": 444,
          
        },
        {
          "nombre": "VALVULINA 5L",
          "idcrm": 15,
          
        },
        {
          "nombre": "VAPOR CANISTER",
          "idcrm": 444,
          
        },
        {
          "nombre": "VARIADOR",
          "idcrm": 1622,
          
        },
        {
          "nombre": "VARILLA CAPOT",
          "idcrm": 447,
          
        },
        {
          "nombre": "VARILLA NIVEL ACEITE",
          "idcrm": 447,
          
        },
        {
          "nombre": "VARILLA SUSPENSION",
          "idcrm": 447,
          
        },
        {
          "nombre": "VARILLAJE LIMPIA DELANTERO",
          "idcrm": 77,
          
        },
        {
          "nombre": "VARILLAJE LIMPIAPARABRISAS",
          "idcrm": 77,
          
        },
        {
          "nombre": "VARILLAS DE CAMBIO",
          "idcrm": 68,
          
        },
        {
          "nombre": "VELOCIMETRO",
          "idcrm": 251,
          
        },
        {
          "nombre": "VENTA DOCUMENTACION",
          "idcrm": 15,
          
        },
        {
          "nombre": "VENTILADOR",
          "idcrm": 253,
          
        },
        {
          "nombre": "VENTILADOR BATERIA",
          "idcrm": 253,
          
        },
        {
          "nombre": "VENTILADOR DE BATERIA HYBRIDA",
          "idcrm": 253,
          
        },
        {
          "nombre": "VENTILADOR DEL CARTER",
          "idcrm": 253,
          
        },
        {
          "nombre": "VENTILADOR INTERCOOLER",
          "idcrm": 253,
          
        },
        {
          "nombre": "VENTILADOR RADIADOR A.A.",
          "idcrm": 253,
          
        },
        {
          "nombre": "VENTILADOR RADIADOR MECANICO",
          "idcrm": 253,
          
        },
        {
          "nombre": "VENTILADOR VISCOSO",
          "idcrm": 253,
          
        },
        {
          "nombre": "VESTIDURA INTERIOR",
          "idcrm": 144,
          
        },
        {
          "nombre": "VISEL DERECHO",
          "idcrm": 15,
          
        },
        {
          "nombre": "VISEL FARO DERECHO",
          "idcrm": 15,
          
        },
        {
          "nombre": "VISEL IZQUIERDO",
          "idcrm": 15,
          
        },
        {
          "nombre": "VOLANTE DIRECCION",
          "idcrm": 362,
          
        },
        {
          "nombre": "VOLANTE MOTOR BIMASA",
          "idcrm": 449,
          
        },
        {
          "nombre": "VOLANTE MOTOR FIJO",
          "idcrm": 449,
          
        },
        {
          "nombre": "WARNING CON MASCARA CENTRAL",
          "idcrm": 294,
          
        },
        {
          "nombre": "XENIUS BALLAST",
          "idcrm": 1363,
          
        },
        {
          "nombre": "ZAPATAS",
          "idcrm": 1625,
          
        },
        {
          "nombre": "ZAPATOS SEG.",
          "idcrm": 15,
          
        },
        {
          "nombre": "ZAPATOS SEG.P.NEGRA",
          "idcrm": 15,
          
        }
    ]
}

module.exports = {
  obtainCats
}