import React, { useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import UserForm from "../UserForm";
import { editUser, fetchUser, saveUser } from "../../../../../../redux/features/currentUser";
import Loading from "../../../shared/components/Loading";

export default function UserDetail() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userId } = useParams();

    const user = useSelector(state => { return state.currentUser.entity });
    const status = useSelector(state => { return state.currentUser.status });

    useEffect(() => {
        if(userId > 0) {                      // user === 0 is a new user
            dispatch(fetchUser(userId))
        } 
    }, []);

    const onSubmit = async (data) => {
        dispatch({type: 'loading/set', payload: true});
        if(userId > 0) {
           dispatch(editUser(data))
        } else {
            dispatch(saveUser(data))
        }

        navigate('/users/1', { replace: true });
    }

    if(status.loading === 'succeed' || (status.loading === 'idle' && parseInt(userId) === 0)  || status.loading === 'rejected') {
        return (
            <div className="w-100 content">
                <div className="w-100 mb-4">
                    <button type="button" className="btn btn-add" onClick={() => navigate(-1)}>
                        <span className="add">
                            <FontAwesomeIcon icon={faLongArrowAltLeft} size="1x" className="action" color="#215732" />
                        </span>
                        Atrás
                    </button>
                </div>

                <UserForm 
                    user={userId > 0 ? user : null}
                    isEdit={userId > 0 ? true : false}
                    onSubmitUser={onSubmit}
                ></UserForm>
            </div>
        );
    }

    if(status.loading === 'pending' || status.loading === 'idle') {
        return ( <Loading /> );
    }
}