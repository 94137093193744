import React from "react";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import moment from 'moment';
import { Switch } from "@mui/material";
import { useSelector } from "react-redux";

export default function ContactoForm({
    onSubmitContacto,
    detalle,
    contacto,
    isEdit
}) {
    const { register, watch, handleSubmit, reset, setValue } = useForm({
        defaultValues: {
            entity_id: detalle.entidad.id,
            observaciones: '',
            volver_contactar: 1,
            bienvenida: null,
        }
    });
    const [defaultOpt, setDefaultOpt] = useState(-1);
    const [defaultOptNoContesta, setDefaultOptNoContesta] = useState(-1);
    const [mensajeBienvenida, setMensajeBienvenida ] = useState(false);
    const [optionsList, setOptionsList] = useState(null);
    const [optionsNoContactarList, setOptionsNoContactar] = useState(null);
    const options = [
        "Primer contacto, le explico todo, ahora no le hace falta nada, le envió contacto nuevo.",
        "Primer contacto, le explico todo, ahora nos pedirá un REC, le envió contacto nuevo.",
        "Primer contacto, llamo a los dos teléfonos y no cogen llamar mañana, le envió contacto nuevo.",
        "Primer contacto, llamo a los dos teléfonos y no cogen llamar siguiente mes, le envió contacto nuevo.",
        "No se acordaban de nosotros, se lo vuelvo a explicar y nos tendrán en cuenta, insistir.",
        "No coge, llamar siguiente mes.",
        "Ahora no le hace falta nada, seguir insistiendo.",
        "Le llamo y nos pide un rec, llamar mes siguiente.",
        "No cogen por tercera vez, no llamar más. Tienen el contacto.",
        "Nos pidió un presu hace un tiempo pero al final no lo pidio, ahora no le hace falta nada, seguir insistiendo.",
        "Llamo y le vuelvo a explicar todo por que no se acodaba de nosotros y nos pide un REC, ir insistiendo.",
        "Nos pidió un presu hace un tiempo pero al final no lo pidio, ahora nos pide un REC.",
        "Pide presupuesto hace unos días. Llamar mes siguiente.",
        "Compra un recambio hace unos días. Llamar mes siguiente.",
        "Nos pide un recambio hace unos días y no lo teníamos, llamar mes siguiente."
    ];
    const noOptions = [
        "No contesta al teléfono.",
        "No coge, llamar siguiente mes.",
        "No quiere probar el servicio.",
        "Nunca necesita piezas.",
        "No quiere repetir.",
        "No llamar más",
        "Llamo varias veces y no contesta.",
        "No pide nunca"
    ];

    useEffect(() => {
        setOptionsList(options);
        
        if(contacto) {
            contacto.volver_contactar = 1;
            reset(contacto);
        }

        if(detalle.categoria === 'cliente') {
            noOptions.push("Cliente perdido");
        }

        setOptionsNoContactar(noOptions);
    }, [contacto]);

    const handleSubmitContacto = async (data, e) => {
        e.preventDefault();   
        data.fecha = isEdit ? contacto.fecha :  moment().format('YYYY-MM-DD');
        
        if(data.bienvenida !== null) {
            data.bienvenida = (data.bienvenida === 'true');
        } else delete(data.bienvenida_pieza);

        onSubmitContacto(data);
    };
 
    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            <form className="form" onSubmit={handleSubmit(handleSubmitContacto)}>
                <input 
                    type="hidden" 
                    {...register("entity_id")} 
                    defaultValue={isEdit ? contacto.entity_id : detalle.entidad.id} />

                <div className="w-100 d-flex flex-column">
                    { !isEdit &&
                        <div className="form-group flex-row align-items-center justify-content-between mb-0 me-3">
                            <label className="m-0">Volver a contactar?:</label>
                            <div className="d-flex align-items-center">
                                <input 
                                    type="checkbox"
                                    {...register("volver_contactar")}
                                    defaultChecked={true} />
                                <label className="ms-3 mb-0">{watch('volver_contactar') ? 'Si' : 'No'}</label>
                            </div>
                        </div>
                    }

                    { (optionsList && optionsNoContactarList) &&
                        <div className="w-100">
                            { (watch('volver_contactar')) ?
                                <div className="form-group mt-3">
                                    <label htmlFor="observaciones">Contesta?</label>
                                    <select value={defaultOpt}
                                        onChange={(e) => {
                                            setDefaultOpt(parseInt(e.target.value));
                                            setValue('observaciones', optionsList[parseInt(e.target.value)]);
                                        }}>
                                        <option key={'opt--1'} value={-1}></option>
                                        {optionsList.map((opt, i) => <option key={'opt-' + i} value={i}>{opt}</option>)}
                                    </select>
                                </div>
                                :
                                <div className="form-group mt-3">
                                    <label htmlFor="observaciones">No contesta?</label>
                                    <select value={defaultOptNoContesta}
                                        onChange={(e) => {
                                            setDefaultOptNoContesta(parseInt(e.target.value));
                                            setValue('observaciones', optionsNoContactarList[parseInt(e.target.value)]);
                                        }}>
                                        <option key={'opt--1'} value={-1}></option>
                                        {optionsNoContactarList.map((opt, i) => <option key={'opt-' + i} value={i}>{opt}</option>)}
                                    </select>
                                </div>
                            }
                        </div>
                    }

                    <div className="w-100">
                        <div className="d-flex align-items-center">
                            <div className="form-group flex-grow-1">
                                <label htmlFor="observaciones">Observaciones</label>
                                <textarea
                                    rows={3}
                                    {...register("observaciones")}
                                    defaultValue={isEdit ? contacto.observaciones : ''} >
                                </textarea>
                            </div>
                        </div>
                    </div>

                    { (!isEdit && (detalle.categoria === 'pendiente' && watch('volver_contactar'))) &&
                        <div className="w-100 d-flex flex-column">
                            <div className="w-100 d-flex align-items-center">
                                <Switch 
                                    color="success"
                                    checked={mensajeBienvenida}
                                    onChange={(e) => {
                                        const checked = e.target.checked;
                                        setMensajeBienvenida(checked);
                                        setValue('bienvenida', checked);
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }} /> 
                                <label>Mensaje de bienvenida?</label>
                            </div>
                        </div>
                    }
                </div>

                    
                <div className="d-flex align-items-center align-self-end mt-3">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    );
}