import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { getAllowedExt } from "../../../../../../../../../../../shared/helpers/functionalities";

export default function UploadForm({
    onSubmit
}) {
    const MAX_LENGTH = 5;
    const { register, handleSubmit } = useForm({
        defaultValues: {
            images: null
        }
    });

    useEffect(() => {
    }, []);


    const handleSubmitImages = async(data, e) => {
        e.preventDefault();

        if(data?.images?.length > MAX_LENGTH) {
            toast.warning(`No se pueden subir más de ${MAX_LENGTH} imágenes!!`);
            return;
        } else {
            const upload = new FormData();
    
            if(data.images.length > 0) {
                for (let i = 0; i < data.images?.length; i++) {
                    upload.append(`images[${i}]`, data.images[i])
                }
            }
            
            onSubmit(upload);
        }
    }
    
    return (
        <div className="w-100 d-flex flex-column">
            <form className="form align-items-center mb-4" onSubmit={handleSubmit(handleSubmitImages)}>
                <div className="w-100 d-flex flex-column flex-grow-1">
                    <div className="form-group flex-grow-1">
                        <label htmlFor="images">Imágenes, <b>máximo 5</b> (opcional)</label>
                        <input 
                            type="file" 
                            className="form-control" 
                            multiple
                            accept={getAllowedExt('image')}
                            {...register("images")} 
                            defaultValue={null} />
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end ms-3 mt-4">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    )
}