import { useEffect, useState } from "react";
import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";

export default function NewVersionModal() {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const reload = useSelector(state => {
    return state.reload
  })

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch({type: 'reload/set', payload: true});
    }, 1000*60*3);

    if(!reload) {
      clearInterval(interval)
      setOpenModal(false);
    } else setOpenModal(true);

    return () => clearInterval(interval);
  }, [reload]);

  
  const handleConfirm = (isConfirm) => {
    if (isConfirm){
      dispatch({type: 'reload/set', payload: false});
      window.location.reload(true);
    } else {
        setOpenModal(false)
    }
  }

  const handleCloseModal = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpenModal(false);
  }

  return (
    <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth={true}
        maxWidth={'sm'}
        id="new-version"
        aria-labelledby="new-version-title"
        aria-describedby="new-version-description">
      <DialogTitle id="new-version-title">
          <div className="w-100 d-flex align-items-center justify-content-between">
              <p className="fw-bold text-uppercase">¡Nueva versión disponible!</p>

              <span title="cerrar" className="action" onClick={() => handleCloseModal()}>
                  <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#215732" />
              </span>
          </div>
        
      </DialogTitle>
      <DialogContent>
          <div className="w-100 h-100">
              <p className="m-0">
                Si quieres usar la nueva versión ya, pulsa <b>SI</b>. <br /> 
                Si estás en medio de algún proceso, pulsa <b>NO</b>. <br />
                En 3 minutos volverá a avisarte.
              </p>

              <div className="w-100 d-flex align-items-center justify-content-center mt-3">
                <button type="button" className="btn btn-primary me-3" onClick={() => handleConfirm(true)}>SI</button>
                <button type="button" className="btn btn-danger" onClick={() => handleConfirm(false)}>NO</button>
              </div>
          </div>
      </DialogContent>
    </Dialog>
  )
}