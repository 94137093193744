import API from '../../../../../services/api';

export const getMarcas = async () => {
    return await API.get('/marcas').then(res => res.data);
}

export const getMarca = async (idMarca) => {
    return API.get('/marcas/' + idMarca).then(res => res.data);
}

export const newMarca = async (marca) => {
    return API.post('/marcas', marca).then(res => res.data);
}

export const updateMarca = async (marca) => {
    return API.put('/marcas/' + marca.id, marca).then(res => res.data);
}

export const deleteMarca = async (idMarca) => {
    return API.delete('/marcas/' + idMarca).then(res => res.data);
}