import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import { fetchFichaCliente } from "../../../../../../redux/features/fichaCliente";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ItemsTable from "./components/ItemsTable";
import ClienteMetrics from "./components/ClienteMetrics";
import Loading from "../../../shared/components/Loading";
import EntityCard from "../../../shared/components/EntityCard";
import ValesDescuentosTable from "./components/ValesDescuentoTable";
import Filters from "./components/Filters";

export default function ClienteSheet() {
    const dispatch = useDispatch();
    const { clienteId } = useParams();
    let navigate = useNavigate();
    const [tab, setTab] = useState('compras');
    const [page, setPage] = useState(1);
    const cliente = useSelector(state => {
        return state.fichaCliente.entity
    });
      const status = useSelector(state => {
        return state.fichaCliente.status
    });
    const fichaSearcher = useSelector(state => {
        return state.ficha_search
    });
      
    useEffect(() => {
        dispatch(fetchFichaCliente('compras', getQuery(tab), page, clienteId));
    }, []);

    useEffect(() => {
        return () => {
            dispatch({type: "ficha_search/set", payload: ""})
        };
    }, []);
    
    const getQuery = () => {
        const isEmpty = Object.values(fichaSearcher).every(x => x === null || x === '');
        const query = !isEmpty ? 'search=' + (fichaSearcher?.search ? fichaSearcher?.search : '') 
          + (fichaSearcher?.start_date ? '&start_date=' + fichaSearcher?.start_date : '')
          + (fichaSearcher?.end_date ? '&end_date=' + fichaSearcher?.end_date : '')
          + (fichaSearcher?.min ? '&min=' + fichaSearcher?.min : '')
          + (fichaSearcher?.max ? '&max=' + fichaSearcher?.max : '')
          + (fichaSearcher?.responsable ? '&responsable=' + fichaSearcher?.responsable : '')
        : null;
    
        return query;
      }

    const handleSearch = (search, searchData) => {
        setPage(1);
        dispatch({type: "ficha_search/set", payload: searchData});
        dispatch(fetchFichaCliente('compras', search, 1, clienteId));  
    }

    const handleChangeTab = (e, tab) => {
        setTab(tab);
        setPage(1);

        if(tab !== 'metricas' && tab !== 'vales' && tab !== 'comentarios') {
            dispatch(fetchFichaCliente(tab,  getQuery(tab), page, clienteId));
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        dispatch(fetchFichaCliente(tab,  getQuery(tab), newPage, clienteId))
    }
    
    if(status.loading === 'pending' || status.loading === 'idle') {
        return ( <Loading /> );
    }
    
    if(status.loading === 'succeed' || status.loading === 'rejected') {
        return (
            <div className="w-100 d-flex content">
                <div className="w-100">
                    <div className="w-100 mb-4">
                        <button type="button" className="btn btn-add" onClick={() => navigate(-1)}>
                            <span className="add">
                                <FontAwesomeIcon icon={faLongArrowAltLeft} size="1x" className="action" color="#215732" />
                            </span>
                            Atrás
                        </button>
                    </div>

                    <div className="w-100 d-flex flex-column align-items-start justify-content-start">
                        <EntityCard
                            cliente={cliente.cliente} 
                            detalle={cliente.detalle}
                            metrics={{
                                ratio_conversion: cliente.ratio_conversion,
                                ticket_medio: cliente.ticket_medio,
                                margen: cliente.margen
                            }}
                            canEdit={false}
                            canChange={false}
                        />
                        
                        <div className="col-12 mt-4">
                            <Filters 
                                onChange={handleSearch}
                            />

                            { (cliente.cliente.regalo) ?
                                <div className="w-100 d-flex justify-content-start">
                                    Regalo enviado 
                                    <FontAwesomeIcon icon={faGift} size="1x" className="action" color="#9e9b9b" />
                                </div>
                                : ''
                            }

                            <div className="w-100 sheet">
                                <TabContext value={tab}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChangeTab} aria-label="Ficha cliente tabs">
                                        <Tab label="Compras" value="compras" />
                                        {cliente.cliente.credito && cliente.cliente.albaranes && cliente.cliente.cadena_id === null ?  <Tab label="Credito" value="credito" /> : null}
                                        <Tab label="Presupuestos" value="presupuestos" />
                                        <Tab label="Devoluciones" value="devoluciones" />
                                        <Tab label="Incidencias" value="incidencias" />
                                        <Tab label="Vales descuento" value="vales" />
                                        {cliente.cliente.tipo_cliente_b2b_id !== 2 && <Tab label="Comentarios" value="comentarios" />}
                                        <Tab label="Métricas" value="metricas" />
                                    </TabList>
                                    </Box>
                                    <TabPanel value="compras">
                                        { cliente.items && cliente.items.length > 0 ?
                                            <ItemsTable 
                                                cliente={cliente.cliente} 
                                                list={cliente.items}
                                                page={page}
                                                count={cliente.count}
                                                item={'venta'}
                                                onPageChange={handleChangePage}
                                            />
                                            : <p>No hay ventas.</p>
                                        }
                                    </TabPanel>
                                    <TabPanel value="credito">
                                        { cliente.items && cliente.items.length > 0 ?
                                            <ItemsTable 
                                                cliente={cliente.cliente} 
                                                list={cliente.items}
                                                page={page}
                                                count={cliente.count}
                                                item={'credito'}
                                                onPageChange={handleChangePage}
                                            />
                                            : <p>No hay facturas a credito.</p>
                                        }
                                    </TabPanel>
                                    <TabPanel value="presupuestos">
                                        { cliente.items && cliente.items.length > 0 ?
                                            <ItemsTable 
                                                cliente={cliente.cliente} 
                                                list={cliente.items}
                                                item={'presupuesto'}
                                                page={page}
                                                count={cliente.count}
                                                onPageChange={handleChangePage}
                                            />
                                            : <p>No hay presupuestos.</p>
                                        }
                                    </TabPanel>
                                    <TabPanel value="devoluciones">
                                        { cliente.items && cliente.items.length > 0 ?
                                            <ItemsTable 
                                                cliente={cliente.cliente} 
                                                list={cliente.items}
                                                item={'devolucion'}
                                                page={page}
                                                count={cliente.count}
                                                onPageChange={handleChangePage}
                                            />
                                            : <p>No hay devoluciones.</p>
                                        }
                                    </TabPanel>
                                    <TabPanel value="incidencias">
                                        { cliente.items && cliente.items.length > 0 ?
                                            <ItemsTable 
                                                cliente={cliente.cliente} 
                                                list={cliente.items}
                                                item={'incidencia'}
                                                page={page}
                                                count={cliente.count}
                                                onPageChange={handleChangePage}
                                            />
                                            : <p>No hay incidencias.</p>
                                        }
                                    </TabPanel>
                                    <TabPanel value="vales">
                                        { cliente.vales && cliente.vales.length > 0 ?
                                            <ValesDescuentosTable 
                                                list={cliente.vales}
                                            />
                                            : <p>No hay vales de decuento.</p>
                                        }
                                    </TabPanel>
                                    <TabPanel value="comentarios">
                                        { cliente.comentarios && cliente.comentarios.length > 0 ?
                                           <div className="w-100 d-flex flex-column">
                                                { cliente.comentarios.map(comentario => {
                                                    return (
                                                        <div className="d-flex flex-column comentario mb-3" key={'comentario-'+cliente.cliente.id + '-' + comentario.id}>
                                                            <span className="mb-1">{comentario.fecha}</span>
                                                            <p>{comentario.observaciones}</p>
                                                        </div>
                                                    )
                                                })}
                                           </div>
                                            : <p>No hay comentarios.</p>
                                        }
                                    </TabPanel>
                                    <TabPanel value="metricas">
                                        { <ClienteMetrics clienteId={cliente.cliente.id} />}
                                    </TabPanel>
                                </TabContext>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}