import { getFinanzas, getPedidosPendientes, getTransferenciasParaAprobar } from "../../views/Dashboard/components/shared/services/finanzas";
import { asyncMac, makeAsyncTypes, makeFetchingReducer } from "../utils";

const initialState = {
    comprobantes_crm: [],
    pedidos_crm: [],
    comprobantes_vo: [],
    pedidos_vo: []
}

export const finanzasReducer = (state = initialState, action) => {
    switch(action.type){
        case "finanzas/fullfilled": {
            return action.payload;
        }
        case "finanzas/comprobantes": {
            return {...state, comprobantes_crm: action.payload }
        }
        case "finanzas/comprobantes_vo": {
            return {...state, comprobantes_vo: action.payload }
        }
        case "finanzas/pedidos": {
            return {...state, pedidos_crm: action.payload }
        }
        case "finanzas/pedidos_vo": {
            return {...state, pedidos_vo: action.payload }
        }
        default:
            return state
    }
}

const asyncFinanzas = makeAsyncTypes('finanzas');

const [setPending, setFullFilled, setError ] = asyncMac(asyncFinanzas);

export const finanzasFetchingReducer = makeFetchingReducer([
    'finanzas/pending', 
    'finanzas/fullfilled', 
    'finanzas/rejected'
]);

export const fetchFinanzas = () => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getFinanzas()
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const fetchTransferenciasPorAprobar = (type) => async dispatch => {
    try {
        const response = await getTransferenciasParaAprobar(type)
        const data = await response.data;
        dispatch({type: type === 'crm' ? "finanzas/comprobantes" : "finanzas/comprobantes_vo", payload: data}); 
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const fetchPedidosPendientes = (type) => async dispatch => {
    try {
        const response = await getPedidosPendientes(type)
        const data = await response.data;
        dispatch({type: type === 'crm' ? "finanzas/pedidos" : "finanzas/pedidos_vo", payload: data}); 
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const finanzasTopReducer = finanzasReducer;
export const finanzasStatusReducer = finanzasFetchingReducer;