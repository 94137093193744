export const reduceReducers = (...reducers) => (state, action) => {
    return reducers.reduce((acc, el) => el(acc, action), state)
}

const initialFetching = { loading: 'idle', error: null};
// actions = ['entity/pending', 'entity/fullfilled', 'entity/error']
export const makeFetchingReducer = actions => (state = initialFetching, action) => {
    switch(action.type){
        case actions[0]: {
            return {...state, loading: 'pending'}
        }
        case actions[1]: {
            return {...state, loading: 'succeed'}
        }
        case actions[2]: {
            return {error: action.error, loading: 'rejected'}
        }
        default: return state;
    }
}

export const makeSetReducer = actions => (state = [], action) => {
    switch(action.type) {
        case actions[0]: {
            return action.payload
        }
        default: return state;
    }
}

// actions = ['entity/add', 'entity/edit', 'entity/delete']
export const makeCrudReducer = actions => (state = [], action) => {
    switch(action.type){
        case actions[0]: {                                      // CREATE
            return state.concat({ ...action.payload })
        }
        case actions[1]: {                                      // UPDATE
            const newEntities = state.map(entity => {
                if(entity.id === action.payload.id) {
                    return  action.payload
                }

                return entity;
            })

            return newEntities;
        }
        case actions[2]: {                                      // DELETE
            const newEntities = state.filter(entity  => entity.id !== action.payload.id);
            return newEntities;
        }
        default:
            return state
    }
}

export const makeAsyncTypes = entity => ([
    `${entity}/pending`, 
    `${entity}/fullfilled`, 
    `${entity}/rejected`
])

//ex makeActionCreator('entity/fullfilled', 'payload')
export const mac = (type, ...argNames) => (...args) => {
    const action = { type }
    argNames.forEach((arg, index) => {
        action[argNames[index]] = args[index]
    })
    return action;
}

export const asyncMac = asyncTypes => ([
    mac(asyncTypes[0]),
    mac(asyncTypes[1], 'payload'),
    mac(asyncTypes[2], 'error'),
])
