import { useState } from "react";
import React from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Marcas from "./components/Marcas";
import Modelos from "./components/Modelos";


export default function MarcasModelos() {
    const [tab, setTab] = useState('marcas');

    const handleChangeTab = (e, tab) => setTab(tab);

    return (
        <div className="w-100 d-flex flex-column align-items-center justify-content-center"> 
            <div className="col-12 col-md-8">
                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChangeTab} aria-label="Ajustes">
                        <Tab label="Marcas" value="marcas" />
                        <Tab label="Modelos" value="modelos" />
                    </TabList>
                    </Box>
                    <TabPanel value="marcas">
                        <Marcas />
                    </TabPanel>
                    <TabPanel value="modelos">
                        <Modelos />
                    </TabPanel>
                </TabContext>
            </div>
        </div>
    )
    
}