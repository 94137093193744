import { useEffect } from "react";
import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

export default function TableSimple({
    id,
    userId,
    label,
    items,
    onOpenForm,
    onDelete
}) {

    useEffect(() => {
    }, [items]);
  
    return (
        <div className="w-100 d-flex flex-column mt-4">
            <div className="w-100 table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>fecha</th>
                            <th>descripción</th>
                            { (userId) && <th></th>}
                        </tr>
                    </thead>

                    <tbody>
                        { (items && items?.length > 0) ?
                            items.map(item => {
                                return (
                                    <tr key={`${id}-${item.id}`}>
                                        <td width={'8%'}>{moment(item.fecha).format('DD-MM-YYYY')}</td>
                                        <td>{item.descripcion}</td>
                                        { (userId) ?
                                            <td width={'8%'} className="text-center">
                                                <span title="Editar" style={{cursor: 'pointer'}} onClick={() => onOpenForm(item)}>
                                                    <FontAwesomeIcon icon={faEdit} size="1x" className="action" color="#215732"></FontAwesomeIcon>
                                                </span>

                                                <span title="Eliminar" className="ms-2" style={{cursor: 'pointer'}} onClick={() => onDelete(item)}>
                                                    <FontAwesomeIcon icon={faTrash} size="1x" className="action" color="#dc3545" />
                                                </span>
                                            </td>
                                            : null
                                        }
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td colSpan={5} className="text-center">No hay {label} en este momento</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}