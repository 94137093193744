import API from '../../../../../services/api';

export const getServiciosEnvio = async (idTransportista) => {
    return await API.get('/servicios-envios/' + idTransportista).then(res => res.data);
}

export const getServicioEnvio = async (idServicio) => {
    return API.get('/servicios-envios/' + idServicio).then(res => res.data);
}

export const newServicioEnvio = async (servicio) => {
    return API.post('/servicios-envios', servicio).then(res => res.data);
}

export const updateServicioEnvio = async (servicio) => {
    return API.put('/servicios-envios/' + servicio.id, servicio).then(res => res.data);
}

export const deleteServicioEnvio = async (idServicio) => {
    return API.delete('/servicios-envios/' + idServicio).then(res => res.data);
}