import { useState, useEffect } from "react";
import React from "react";
import { toast } from 'react-toastify';
import { useNavigate, useParams } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import Header from '../shared/components/Header'
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { fetchAlbaranes } from "../../../../redux/albaranes";
import { generateAlbaranPDF, regenerateAlbaran, sendAlbaranByEmail } from "../shared/services/albaranes";
import Loading from "../shared/components/Loading";
import ActionsMenu from "../shared/components/ActionsMenu";
import { getActions } from "./actions";
import ConfirmModal from "../shared/components/ConfirmModal";
import { setErrorMessage } from "../shared/helpers/functionalities";

export default function Albaranes() {
  const dispatch = useDispatch();
  const { page } = useParams();
  const currPage = parseInt(page);
  let navigate = useNavigate();
  const [currentAlbaran, setCurrentAlbaran] = useState(null);
  const [openConfirmSend, setOpenConfirmSend] = useState(false);
  const [currentPage, setPage] = useState(currPage);
  const tipoFacturas = useSelector(state => {
    const { tipo_facturas } = state;
    return tipo_facturas;
  });
  const albaranes = useSelector(state => {
    return state.albaranes.entities
  });
  const status = useSelector(state => {
    return state.albaranes.status
  });
  const searcher = useSelector(state => {
    return state.search
  })

  useEffect(() => {
    dispatch(fetchAlbaranes(currPage, getQuery()));
  }, [searcher]);

  const getQuery = () => {
    const isEmpty = Object.values(searcher).every(x => x === null || x === '');
    let query = !isEmpty ? 'search=' + (searcher?.search ? searcher?.search : '')
    : null;

    return query;
  }

  const handleSearch = (searchData) => {
    setPage(1);
    navigate(`/albaranes/${1}`);
    dispatch({type: "search/set", payload: searchData});     
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    navigate(`/albaranes/${newPage}`);
    dispatch(fetchAlbaranes(newPage, getQuery()));
  }

  const getPaginationCount = () => {
    var totalPages = albaranes.total_items < 15 ? 1 : Math.ceil(albaranes.total_items / 15);
    return parseInt(totalPages);
  }

  const handleAction = (action, albaran) => {
    if(action === 'download') {
      generateThePdf(albaran)
    } else  if(action === 'regenerate') {
      regenerate(albaran)
    } else if(action === 'sendByEmail') {
      handleOpenConfirmSend(albaran);
    }
  }

  const generateThePdf = async (albaran) => {
      dispatch({type: 'loading/set', payload: true});
      const pdfResult = await generateAlbaranPDF(albaran.id);

      if(pdfResult) {
        dispatch({type: 'loading/set', payload: false});
        toast.success('PDF generado correctamente!')
        const url = window.URL.createObjectURL(new Blob([pdfResult]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', albaran.numero + '.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
      } else {
        toast.error('Ups! Algo ha ido mal...')
      }
  }

  const regenerate = async(albaran) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await regenerateAlbaran({albaran_id: albaran.id});

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
    }
  }

  const getTipoFacturaName = (tipoClienteId) => tipoFacturas.find(tp => tp.id === tipoClienteId)?.nombre;

  const handleView = (item) => { 
    localStorage.removeItem('searcher');

    if(item === 'facturas') {
      navigate('/facturas/1', { replace: true });
    } else {
      navigate('/notas-cargo/1', { replace: true });
    }
  }

  const getSubtotal = (albaran) => {
    const subtotal = albaran.total + albaran.total_envio + albaran.total_casco;
    return parseFloat(subtotal).toFixed(2);
  }

  const getTotalIVA = (albaran, conDescuento) => {
    let totalIVA;

    if(conDescuento) {
      totalIVA = albaran.total_iva;
    } else {
      totalIVA = albaran.total_iva + albaran.total_descuento_iva
    }
    
    return parseFloat(totalIVA).toFixed(2);
  }

  const handleOpenConfirmSend = (factura) => {
    setCurrentAlbaran(factura);
    setOpenConfirmSend(true);
  }
  const handleCloseConfirmSend = () => {
    setOpenConfirmSend(false);
    setCurrentAlbaran(null);
  }

  const handleSendAlbaranByEmail = async(confirm) => {
    if(confirm) {
      dispatch({type: 'loading/set', payload: true});
      const response = await sendAlbaranByEmail(currentAlbaran.id)
      .catch(function (error) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(setErrorMessage(error))
      });

      if(response && response.success) {
        dispatch({type: 'loading/set', payload: false});
        toast.success(response.message);
      }
    }

    handleCloseConfirmSend();
  }

  if(status.loading === 'pending' || status.loading === 'idle') {
    return ( <Loading /> );
  }

  if(status.loading === 'succeed' || status.loading === 'rejected') {
    const pageCount = getPaginationCount();

    return (
      <div className="content pt-3">
        <Header viewSearch={true} onSearch={handleSearch}></Header>

        <div className="w-100 d-flex flex-column align-items-end content-table">
          <div className="d-flex align-items-center mb-3">
            <button className="btn btn-default me-3" onClick={() => handleView('notas')}>Notas de cargo</button>
            <button className="btn btn-default me-3" onClick={() => handleView('facturas')}>Facturas</button>
          </div>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Nº</th>
                  <th className="text-center">tipo</th>
                  <th className="text-center">fecha</th>
                  <th className="text-center">venta</th>
                  <th className="text-center">devolucion</th>
                  <th className="text-center">total</th>
                  <th className="text-center">total envío</th>
                  <th className="text-center">total casco</th>
                  <th className="text-center">subtotal</th>
                  <th className="text-center">I.V.A</th>
                  <th className="text-center">total I.V.A</th>
                  <th className="text-center">descuento</th>
                  <th className="text-center">total I.V.A</th>
                  <th className="text-center"></th>
                </tr>
              </thead>

              <tbody>
                { albaranes.albaranes?.map((albaran) => {
                  return (
                    <tr key={albaran.id}>
                      <td>{albaran.numero}</td>
                      <td className="text-center text-uppercase">{tipoFacturas?.length > 0 ? getTipoFacturaName(albaran.tipo_factura_id) : ''}</td>
                      <td className="text-center">{moment(albaran.fecha).format("DD-MM-YYYY")}</td>
                      <td className="text-center">{albaran.venta_id}</td>
                      <td className="text-center">{albaran.devolucion_id !== null ? albaran.devolucion_id : ''}</td>
                      <td className="text-center">{parseFloat(albaran.total).toFixed(2)}€</td>
                      <td className="text-center">{parseFloat(albaran.total_envio).toFixed(2)}€</td>
                      <td className="text-center">{parseFloat(albaran.total_casco).toFixed(2)}€</td>
                      <td className="text-center">{getSubtotal(albaran)}€</td>
                      <td className="text-center">{albaran.iva}%</td>
                      <td className="text-center">{getTotalIVA(albaran, false)}€</td>
                      <td className="text-center">
                        { (albaran.vale_descuento) ?
                          <div className="d-flex flex-column">
                            { (albaran.tipo_factura_id === 2 || albaran.tipo_factura_id === 3) ? 
                                +parseFloat(albaran.total_descuento_iva).toFixed(2) + '€'
                                : 
                                -parseFloat(albaran.total_descuento_iva).toFixed(2) + '€'
                            }
                            <span className="fw-bold text-green">{albaran?.vale_descuento.codigo}</span>
                          </div>
                          : '-'
                        }
                      </td>
                      <td className="text-center">{getTotalIVA(albaran, true)}€</td>
                      <td className="text-center">
                        <ActionsMenu 
                            options={getActions()}
                            onAction={(action) => handleAction(action, albaran)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <Pagination 
            className="mt-3" 
            count={pageCount} 
            page={currentPage} 
            onChange={handleChangePage} 
          />
        </div>

        { openConfirmSend && 
          <ConfirmModal 
              onConfirmAction={handleSendAlbaranByEmail} 
              title={'Enviar albarán ' + currentAlbaran.numero} 
              description={'Estás seguro de enviarlo por email?'}
              state={openConfirmSend}>
          </ConfirmModal>
        }
      </div>
    )
  }
}