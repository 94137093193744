import API from '../../../../../services/api';
import { headers } from '../helpers/functionalities';

export const getTipoPiezas = async () => {
    return await API.get('/tipo_piezas').then(res => res.data);
}

export const getTipoPieza = async (idTipoPieza) => {
    return API.get('/tipo_piezas/' + idTipoPieza).then(res => res.data);
}

export const newTipoPieza = async (tipoPieza) => {
    return API.post('/tipo_piezas', tipoPieza).then(res => res.data);
}

export const importarMedidas = async (excel) => {
    return API.post('tipo_piezas/import', excel, headers).then(res => res.data);
}

export const updateTipoPieza = async (tipoPieza) => {
    return API.put('/tipo_piezas/' + tipoPieza.id, tipoPieza).then(res => res.data);
}

export const deleteTipoPieza = async (idTipoPieza) => {
    return API.delete('/tipo_piezas/' + idTipoPieza).then(res => res.data);
}