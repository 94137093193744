export const getActions = (item) => {
    const actions = {
        actions: [
            {
                label: 'Descargar PDF',
                icon: 'pdf',
                action: 'download'
            }
        ],
        extra: []
    }

    if((item.cliente.credito == true && item.cliente.con_codigo == true)) {
        actions.actions.push({
            label: 'Cambiar código de devolución',
            icon: 'edit',
            action: 'edit'
        })
    }

    return actions;
}