import React from 'react';
import { useState, useEffect } from "react";
import moment from 'moment';
import 'moment/locale/es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import ViewerSelector from './components/ViewerSelector';

export default function DateSelector({onSelectedDate}) {
    const [initialDateInterval, setInitial] = useState(moment().date() >= 26 ?
        moment().date(26).format('YYYY-MM-DD')
    :   moment().subtract(1, 'month').date(26).format('YYYY-MM-DD'));
    const [finalDateInterval, setFinal] = useState(moment().date() >= 26 ? 
        moment().add(1, 'month').date(25).format('YYYY-MM-DD')
    :   moment().date(25).format('YYYY-MM-DD'));
    const [visualization, setVisualization] = useState('variable');
    let counterPrev = 0;
    let counterNext = 0;

    useEffect(() => {
        moment.locale('es');
    }, []);


    const toggleDateInterval = (view) => {
        setVisualization(view);
        setCurrentPeriod(view);
    }

    const setCurrentPeriod = (view) => {
        let start;
        let end; 

        if(view === 'dia') {
            start = moment(initialDateInterval).format('YYYY-MM-DD')
            end = start;
        } else if(view === 'semana') {
            const curr = new Date(initialDateInterval);
            const curr2 = new Date(initialDateInterval);
            curr.setHours(0);
            curr.setMinutes(0);
            curr.setSeconds(0);

            let first = curr.getDate() - curr.getDay();
            first = first + 1;

            const last = first + 6;

            const monday = moment(new Date(curr.setDate(first))).format('YYYY-MM-DD');
            const sunday = moment(new Date(curr2.setDate(last))).format('YYYY-MM-DD');

            start = monday;
            end = sunday;
        } else if(view === 'mes') {
            start = moment(initialDateInterval).startOf('month').format('YYYY-MM-DD')
            end = moment(initialDateInterval).endOf('month').format('YYYY-MM-DD');
        } else if(view === 'variable') {
            start = moment(initialDateInterval).subtract(1, 'month').date(26).format('YYYY-MM-DD')
            end = moment(initialDateInterval).date(25).format('YYYY-MM-DD');
        } else if(view === 'año-variable') {
            start = moment(initialDateInterval).startOf('year').subtract(1, 'month').date(26).format('YYYY-MM-DD');
            end = moment(initialDateInterval).endOf('year').date(25).format('YYYY-MM-DD')
        } else if(view === 'ultimos-12meses') {
            start = moment().subtract(12, 'month').startOf('month').format('YYYY-MM-DD');
            end = moment().endOf('month').format('YYYY-MM-DD')
        } else {
            start = moment(initialDateInterval).startOf('year').format('YYYY-MM-DD');
            end = moment(initialDateInterval).endOf('year').format('YYYY-MM-DD')
        }

        setInitial(start);
        setFinal(end);
        onSelectedDate(start, end, view);
    }

    const periodSelection = (period) => {
        if(period === 'prev') {                         // previous date
            if(visualization !== 'semana' || visualization !== 'dia') {
                if (counterPrev > 0) counterPrev = 0;
                counterPrev++;
            } else counterPrev++;
            

            prevDate();
        } else {                                        // next date
            if(visualization !== 'semana' || visualization !== 'dia') {
                if (counterNext > 0) counterNext = 0;
                counterNext++;
            } else counterNext++;

            nextDate();
        }
    }

    const nextDate = () => {
        let start;
        let end;

        if(visualization === 'dia') {
            start = moment(initialDateInterval).add(counterNext, 'day').format('YYYY-MM-DD');
            end = start;
        } else if(visualization === 'semana') {
            start = moment(initialDateInterval).add(counterNext, 'week').format('YYYY-MM-DD');
            end = moment(finalDateInterval).add(counterNext, 'week').format('YYYY-MM-DD');
        } else if(visualization === 'mes') {
            start = moment(initialDateInterval).add(counterNext, 'month').startOf('month').format('YYYY-MM-DD')
            end = moment(initialDateInterval).add(counterNext, 'month').endOf('month').format('YYYY-MM-DD');
        } else if(visualization === 'variable') {
            start = moment(initialDateInterval).add(counterNext, 'month').date(26).format('YYYY-MM-DD')
            end = moment(initialDateInterval).add(counterNext +1, 'month').date(25).format('YYYY-MM-DD');
        } else if(visualization === 'año-variable') {
            end = moment(initialDateInterval).add(counterNext +1, 'year').endOf('year').date(25)
            start = moment(end).startOf('year').subtract(1, 'month').date(26).format('YYYY-MM-DD');
            end = end.format('YYYY-MM-DD')
        }  else {
            start = moment(initialDateInterval).add(counterNext, 'year').startOf('year').format('YYYY-MM-DD');
            end = moment(initialDateInterval).add(counterNext, 'year').endOf('year').format('YYYY-MM-DD')
        }

        setInitial(start);
        setFinal(end);
        onSelectedDate(start, end, visualization);
    }

    const prevDate = () => {
        let start;
        let end;

        if(visualization === 'dia') {
            start = moment(initialDateInterval).subtract(counterPrev, 'day').format('YYYY-MM-DD');
            end = start;    
        } else if(visualization === 'semana') {
            start = moment(initialDateInterval).subtract(counterPrev, 'week').format('YYYY-MM-DD');
            end = moment(finalDateInterval).subtract(counterPrev, 'week').format('YYYY-MM-DD');    
        } else if(visualization === 'mes') {
            start = moment(initialDateInterval).subtract(counterPrev, 'month').startOf('month').format('YYYY-MM-DD');
            end = moment(initialDateInterval).subtract(counterPrev, 'month').endOf('month').format('YYYY-MM-DD');
        } else if(visualization === 'variable') {
            start = moment(initialDateInterval).subtract(counterPrev, 'month').date(26).format('YYYY-MM-DD');
            end = moment(initialDateInterval).date(25).format('YYYY-MM-DD');
        } else if(visualization === 'año-variable') {
            end = moment(initialDateInterval).endOf('year').date(25)
            start = moment(end).startOf('year').subtract(1, 'month').date(26).format('YYYY-MM-DD');
            end = end.format('YYYY-MM-DD')
        } else {
            start = moment(initialDateInterval).subtract(counterPrev, 'year').startOf('year').format('YYYY-MM-DD');
            end = moment(initialDateInterval).subtract(counterPrev, 'year').endOf('year').format('YYYY-MM-DD');
        }

        setInitial(start);
        setFinal(end);
        onSelectedDate(start, end, visualization);
    }

    return (
        <div className='w-100 d-flex align-items-center justify-content-end'>
            <div className="d-flex align-items-center mr-3">
                <div className="d-flex align-items-center date-selector">
                    <button 
                        type="button" 
                        className={"btn btn-arrow " + (visualization === 'ultimos-12meses' ? ' d-none' : '')}
                        onClick={() => periodSelection('prev')}>
                            <FontAwesomeIcon icon={faChevronLeft} size="1x" />
                    </button>

                    { (visualization === 'dia') && <p className="mb-0">{moment(initialDateInterval).format("D")}</p>}
                    { (visualization === 'mes') && <p className="mb-0">{moment(initialDateInterval).format("MMMM")}</p>}
                    { (visualization === 'año') && <p className="mb-0">{moment(initialDateInterval).format("yyyy")}</p>}
                    { (visualization === 'año-variable') &&  <p className="mb-0">{moment(finalDateInterval).format("yyyy")}</p>}
                    { (visualization === 'semana' || visualization === 'variable') && <p className="mb-0">{moment(initialDateInterval).format("D MMM")} - {moment(finalDateInterval).format("D MMM")}</p>}

                    <button type="button"
                        className={"btn btn-arrow " + (visualization === 'ultimos-12meses' ? ' d-none' : '')}
                        onClick={() => periodSelection('next')}>
                            <FontAwesomeIcon icon={faChevronRight} size="1x" />
                    </button>
                </div>
            </div>

            <ViewerSelector onViewSelector={toggleDateInterval} /> 
        </div>
    )
}
