import React from 'react';

export default function InfoGeneralIncidencias({
    data
}) {
    return (
        <div className='w-100 d-flex flex-column align-items-start summary mb-5'>
            <h1>Información general de Atención al cliente</h1>

            <div className='card-grid w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between mt-4'>
                <div className='card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                    <h2>Incidencias abiertas diarias</h2>
                    <h1>{data.incidencias_abiertas_diaria}</h1>
                </div>

                <div className='card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                    <h2>Incidencias abiertas semanales</h2>
                    <h1>{data.incidencias_abiertas_semanal}</h1>
                </div>

                <div className='card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                    <h2>Incidencias abiertas mensuales</h2>
                    <h1>{data.incidencias_abiertas_mensual}</h1>
                </div>

                <div className='card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                    <h2>Incidencias abiertas anuales</h2>
                    <h1>{data.incidencias_abiertas_anual}</h1>
                </div>
            </div>

            <div className='card-grid w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between mt-4'>
                <div className='card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                    <h2>Incidencias cerradas diarias</h2>
                    <h1>{data.incidencias_cerradas_diaria}</h1>
                </div>

                <div className='card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                    <h2>Incidencias cerradas semanales</h2>
                    <h1>{data.incidencias_cerradas_semanal}</h1>
                </div>

                <div className='card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                    <h2>Incidencias cerradas mensuales</h2>
                    <h1>{data.incidencias_cerradas_mensual}</h1>
                </div>

                <div className='card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                    <h2>Incidencias cerradas anuales</h2>
                    <h1>{data.incidencias_cerradas_anual}</h1>
                </div>
            </div>

            <div className='card-grid w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between mt-4'>
                <div className='card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                    <h2>Incidencias en curso</h2>
                    <h1>{data.incidencias_encurso}</h1>
                </div>

                <div className='card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                    <h2>Incidencias en finanzas</h2>
                    <h1>{data.incidencias_finanzas}</h1>
                </div>
            </div>
        </div>
    );
}