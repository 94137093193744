import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function ServiciosTable({servicios, onEdit, onDelete}) {

    const handleEdit = (servicioId) => { onEdit('servicio', servicioId); }

    const handleDelete = (servicioId) => { onDelete('servicio', servicioId); }

    const getNombreTipoPiezas = (tipo_piezas) => { return tipo_piezas.map(tp => tp.nombre).join(', '); }

    return (
        <div className="w-100 d-flex flex-column">
            <div className="w-100 table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="text-center">Nombre</th>
                            <th className="text-center">Código</th>
                            <th className="text-center">Precio base</th>
                            <th className="text-center">Tipos</th>
                            <th className="text-center">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        { servicios?.map(servicio => {
                              return (
                                  <tr key={'valor-' + servicio.id}>
                                      <td className="text-center">{servicio.nombre}</td>
                                      <td className="text-center">{servicio.codigo ? servicio.codigo : '-'}</td>
                                      <td className="text-center">{servicio.precio_base}€</td>
                                      <td className="text-center" width="35%">{getNombreTipoPiezas(servicio.tipo_piezas)}</td>
                                      <td className="text-center" width="10%">
                                        <span title="Editar" style={{cursor: 'pointer'}} className="me-3">
                                            <FontAwesomeIcon icon={faPen} size="1x" className="action" color="#215732" onClick={() => handleEdit(servicio.id)} />
                                        </span>
                                        <span title="Eliminar" style={{cursor: 'pointer'}}>
                                            <FontAwesomeIcon icon={faTrash} size="1x" className="action" color="#dc3545" onClick={() => handleDelete(servicio.id)} />
                                        </span>
                                      </td>
                                  </tr>
                              )
                          })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}