import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFinanzas, fetchPedidosPendientes, fetchTransferenciasPorAprobar } from "../../../../redux/features/finanzas";
import Header from "../shared/components/Header";
import Loading from "../shared/components/Loading";
import PedidosPendientes from "./components/PedidosPendientes";
import Transferencias from "./components/Transferencias";


export default function Finanzas() {
    const dispatch = useDispatch();
    const finanzas = useSelector(state => {
        return state.finanzas.entity
    });
    const finanzasStatus = useSelector(state => {
        return state.finanzas.status
    });

    useEffect(() => {
        dispatch(fetchFinanzas());
    }, []);

    const handleApprovalTransferencia = () => { dispatch(fetchTransferenciasPorAprobar()); }

    const handleApprovalPedido = () => { dispatch(fetchPedidosPendientes()); }

    if(finanzasStatus.loading === 'pending' || finanzasStatus.loading === 'idle') {
        return ( <Loading /> );
    }
    
    if(finanzasStatus.loading === 'succeed' || finanzasStatus.loading === 'rejected') {
        return (
            <div className="content pt-3">
                <Header viewSearch={false} />

                <h1 className="mt-3">CRM</h1>

                <div className="w-100 d-flex flex-column align-items-end content-table">
                    <div className='w-100 mb-4'>
                        <h6 className="fw-bold">Transferencias pendientes de aprobación</h6>

                        <div className='w-100 mt-3'>
                            { finanzas?.comprobantes_crm?.length > 0 ?
                                <Transferencias 
                                    from={'crm'}
                                    presupuestos={finanzas?.comprobantes_crm}
                                    onApproval={handleApprovalTransferencia}
                                />

                                : <small>No hay transferencias para aprobar.</small>
                            }
                        </div>
                    </div>

                    <hr className="w-100 d-block" />
                    
                    <div className='w-100 mb-4'>
                        <h6 className="fw-bold">Pedidos pendientes</h6>

                        <div className='w-100 mt-3'>
                            { finanzas?.pedidos_crm?.length > 0 ?
                                <PedidosPendientes 
                                    from={'crm'}
                                    ventas={finanzas?.pedidos_crm}
                                    onApproval={handleApprovalPedido}
                                />

                                : <small>No hay pedidos pendientes.</small>
                            }
                        </div>
                    </div>
                </div>

                <h1>VENTA ONLINE</h1>
                
                <div className="w-100 d-flex flex-column align-items-end content-table">
                    <div className='w-100 mb-4'>
                        <h6 className="fw-bold">Transferencias pendientes de aprobación</h6>

                        <div className='w-100 mt-3'>
                            { finanzas?.comprobantes_vo?.length > 0 ?
                                <Transferencias 
                                    from={'vo'}
                                    presupuestos={finanzas?.comprobantes_vo}
                                    onApproval={handleApprovalTransferencia}
                                />

                                : <small>No hay transferencias para aprobar.</small>
                            }
                        </div>
                    </div>

                    <hr className="w-100 d-block" />
                    
                    <div className='w-100 mb-4'>
                        <h6 className="fw-bold">Pedidos pendientes</h6>

                        <div className='w-100 mt-3'>
                            { finanzas?.pedidos_vo?.length > 0 ?
                                <PedidosPendientes 
                                    from={'vo'}
                                    ventas={finanzas?.pedidos_vo}
                                    onApproval={handleApprovalPedido}
                                />

                                : <small>No hay pedidos pendientes.</small>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}