import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

export default function PorcentajeForm({
    porcentaje, 
    userId,
    onSubmitItem
}) {
    const [calculado, setCalculado] = useState(false);
    let formSimpleSchema = yup.object({
        porcentaje: yup.number().required("El porcentaje es obligatorio"),
      }).required();

    const { register, handleSubmit, reset, getValues, setValue, watch } = useForm({
        resolver: yupResolver(formSimpleSchema),
        mode: "onChange",
        defaultValues: {
            id: userId,
            porcentaje: porcentaje ? porcentaje : 0,
            presupuesto_enviado: 0,
            pieza_no_encontrada: 0
        }
    });

    useEffect(() => {
        reset({porcentaje: porcentaje ? porcentaje : 0});
    }, [porcentaje]);

    const handleCalcularPorcentaje = () => {
        const presupuestoEnviado = parseFloat(getValues('presupuesto_enviado'));
        const piezaNoEncontrada = parseFloat(getValues('pieza_no_encontrada'));
        const porcentaje = (piezaNoEncontrada / (piezaNoEncontrada + presupuestoEnviado)) * 100;
        
        setValue('porcentaje', porcentaje.toFixed(2));
        setCalculado(true);
    }

    const handleSubmitPorcentaje = async(data, e) => {
        e.preventDefault();
        data.porcentaje_pieza_no_encontrada = data.porcentaje;
        delete data.porcentaje;
        delete data.presupuesto_enviado;
        delete data.pieza_no_encontrada;
        onSubmitItem(data);
    }

    return (
        <div className="w-100 d-flex flex-column">
            <form className="form mb-4" onSubmit={handleSubmit(handleSubmitPorcentaje)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={userId} />

                <div className="w-100 d-flex align-items-center">
                    <div className="form-group me-3">
                        <label htmlFor="porcentaje">Presupuesto enviado</label>
                        <input 
                            type="number" 
                            step="0.01"
                            className="form-control" 
                            min={0}
                            {...register("presupuesto_enviado")} 
                            defaultValue={0} />
                    </div>

                    <div className="form-group">
                        <label htmlFor="porcentaje">Pieza no encontrada</label>
                        <input 
                            type="number" 
                            step="0.01"
                            className="form-control" 
                            min={0}
                            {...register("pieza_no_encontrada")} 
                            defaultValue={0} />
                    </div>
                </div>

                <div className="w-100 d-flex align-items-center justify-content-between">
                    <div>
                        <p className="fw-bold">
                            { (watch('porcentaje')) &&
                                getValues('porcentaje') + '%'
                            }
                        </p>
                    </div>

                    <div className="d-flex align-items-center align-self-end">
                        { (!calculado) && 
                            <button 
                                type="button" 
                                className="btn btn-primary align-self-end"
                                onClick={() => handleCalcularPorcentaje()}
                            >Calcular</button> 
                        }

                        { (calculado) && 
                            <button 
                                type="submit" 
                                className="btn btn-primary align-self-end"
                            >Guardar</button> 
                        }
                    </div>
                </div>
            </form>
        </div>
    )
}