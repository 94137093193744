import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setErrorMessage } from "../../../../../../../../../../../shared/helpers/functionalities";
import { scrappingBlatURL } from "../../../../../../../../../../../shared/services/scrapping";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import Loading from "../../../../../../../../../../../shared/components/Loading";
import noImage from "../../../../../../../../../../../../../../images/no_image.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faSearch, faXmark } from "@fortawesome/free-solid-svg-icons";

export default function ScrappingPropioForm({
  piezasList,
  currentReferencia,
  onScrapping,
}) {
  const gpaUrlId = "gpa26-com-24a0d8b8";
  const urlProviders = [
    {
      key: "gpa26.com",
      id: "gpa26-com-24a0d8b8",
    },
    {
      key: "azelerecambios.com",
      id: "azelerecambios-com-ff65d73e",
    },
    {
      key: "rrr.lt",
      id: "rrr-lt-d81b321c",
    },
    {
      key: "opisto.com",
      id: "opisto-com-d0ecaa48",
    },
  ];

  const marcas = useSelector((state) => {
    const { marcas } = state;
    return marcas;
  });

  const modelos = useSelector((state) => {
    const { modelos } = state;
    return modelos;
  });

  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [listKeys, setListKeys] = useState([]);
  const [piezaActual, setPiezaActual] = useState(null);
  const [accordeonExpanded, setAccordeonExpanded] = useState(false);
  const [searchAgainDialog, setSearchAgainDialog] = useState(false);
  const [search, setSearch] = useState({
    azelerecambios: "",
    gpa: "",
    opisto: "",
    rrr: "",
  });
  const [referenciaPieza, setReferenciaPieza] = useState({
    azelerecambios: "-1",
    gpa: "-1",
    opisto: "-1",
    rrr: "-1",
  });
  const [proveedorPieza, setProveedorPieza] = useState({
    azelerecambios: "-1",
    gpa: "-1",
    opisto: "-1",
    rrr: "-1",
  });
  const [kmMin, setKmMin] = useState({
    azelerecambios: "",
    gpa: "",
    opisto: "",
    rrr: "",
  });
  const [kmMax, setKmMax] = useState({
    azelerecambios: "",
    gpa: "",
    opisto: "",
    rrr: "",
  });

  useEffect(() => {
    setList(piezasList.list);
    setListKeys(Object.keys(piezasList.list));
  }, [piezasList]);

  const onSubmit = async (pieza, referencia) => {
    if (pieza && referencia && !pieza.referencia_pieza) {
      pieza.referencia_pieza = referencia;
    }

    setAccordeonExpanded(false);
    setPiezaActual(pieza);

    const urlId = generateUrlId(pieza.url);

    if (urlId === gpaUrlId) {
      return onScrapping(pieza, pieza.url, gpaUrlId);
    }

    setIsLoading(true);
    const scrappingResponse = await scrappingBlatURL(pieza.url, urlId).catch(
      function (error) {
        toast.error(
          setErrorMessage(
            error
              ? error
              : "ERROR! Ha ocurrido un problema o el anuncio no está disponible."
          )
        );
      }
    );
    setIsLoading(false);

    if (scrappingResponse.message?.includes("400")) {
      setSearchAgainDialog(true);
    }

    if (scrappingResponse && scrappingResponse.success) {
      let scrappingData = scrappingResponse.data;
      onScrapping(scrappingData, pieza.url, urlId);
    }
  };

  const generateUrlId = (url) => {
    return urlProviders.find((provider) => {
      if (url.includes(provider.key)) {
        return provider.id;
      }
    })?.id;
  };

  const getMarca = (pieza) => {
    return marcas.find((marca) => marca.id === pieza?.marca_id)?.nombre || "";
  };

  const getModelo = (pieza) => {
    return (
      modelos.find((modelo) => modelo.id === pieza?.modelo_id)?.nombre || ""
    );
  };

  const getPrecio = (pieza) => {
    if (pieza.precio_original) return `${pieza.precio_original} €`;

    if (pieza.precio) {
      let price;

      if (pieza.precio?.price_tax_incl) {
        const formatedPrice = parseFloat(
          pieza.precio?.price_tax_incl
            ?.replace("€", "")
            .replace(",", ".")
            .trim()
        );
        const priceWithoutVAT = (formatedPrice / 1.21).toFixed(2);
        price = priceWithoutVAT;
      }
      if (pieza.precio?.price_tax_excl) {
        if (Number.isNaN(parseFloat(pieza.precio.price_tax_excl))) {
          return (
            <a href={`tel:${pieza.telefono}`}>
              {pieza.telefono && (
                <FontAwesomeIcon
                  className="action"
                  color="#215732"
                  icon={faPhone}
                  size="1x"
                />
              )}
              {pieza.proveedor && (
                <span className="ms-2">{pieza.proveedor}</span>
              )}
            </a>
          );
        }
        const formatedPrice = parseFloat(
          pieza.precio.price_tax_excl?.replace("€", "").replace(",", ".").trim()
        );
        price = formatedPrice;
      }
      if (pieza.precio?.discount) {
        price = price - (price * pieza.precio?.discount) / 100;
      }

      return `${price} €`;
    }
  };

  const handleChange = (panel) => {
    if (accordeonExpanded === panel) {
      setAccordeonExpanded(false);
      return;
    }
    setAccordeonExpanded(panel);
  };

  const handleYesClick = () => {
    setSearchAgainDialog(false);
    onSubmit(piezaActual);
  };

  const obtenerReferenciasUnicas = (lista) => {
    const referenciasUnicas = new Set();
    lista?.forEach((item) => {
      item.referencia_pieza?.split(",")?.forEach((ref) => {
        const referenciaLimpia = ref.trim();
        if (referenciaLimpia) {
          referenciasUnicas.add(referenciaLimpia);
        }
      });
    });
    return Array.from(referenciasUnicas);
  };

  const obtenerProveedoresUnicos = (lista) => {
    const proveedoresUnicos = new Set();
    lista?.forEach((item) => {
      (item.proveedor?.nombre &&
        proveedoresUnicos.add(item.proveedor.nombre)) ||
        (item.proveedor && proveedoresUnicos.add(item.proveedor));
    });
    return Array.from(proveedoresUnicos);
  };

  const filtrarPiezas = (
    piezas,
    referenciaPieza,
    proveedorPieza,
    kmMin,
    kmMax,
    search
  ) => {
    return piezas?.filter((pieza) => {
      const referencias = pieza.referencia_pieza
        ? pieza.referencia_pieza?.split(",")?.map((ref) => ref.trim())
        : [];

      const referenciaCoincide =
        referenciaPieza === "-1" || referencias.includes(referenciaPieza);

      const proveedorCoincide =
        proveedorPieza === "-1" ||
        proveedorPieza === pieza.proveedor?.nombre ||
        proveedorPieza === pieza.proveedor;

      const kmMinCoincide =
        !kmMin || parseInt(pieza.km_motor) >= parseInt(kmMin);
      const kmMaxCoincide =
        kmMax === "" || parseInt(pieza.km_motor) <= parseInt(kmMax);

      const busquedaCoincide =
        typeof search === "string" &&
        (pieza.vehicle?.toLowerCase().includes(search.toLowerCase()) ||
          pieza.modelo?.nombre?.toLowerCase().includes(search.toLowerCase()) ||
          pieza.modelo?.marca?.toLowerCase().includes(search.toLowerCase()));

      return (
        referenciaCoincide &&
        busquedaCoincide &&
        proveedorCoincide &&
        kmMinCoincide &&
        kmMaxCoincide
      );
    });
  };

  return (
    <>
      {list ? (
        <div className="position-relative">
          {isLoading && (
            <>
              <div
                className="position-absolute bg-white"
                style={{
                  width: "102%",
                  height: "105%",
                  left: "-1%",
                  top: "-1%",
                  opacity: 0.8,
                  zIndex: 1000,
                }}
              />
              <div
                className="d-flex flex-column justify-content-center align-items-center position-absolute w-100 h-100"
                style={{
                  zIndex: 1000,
                  gap: "1rem",
                  top: listKeys.length === 1 ? "-30%" : "-10%",
                }}
              >
                <Loading />
                <span className="text-center">
                  El scrapper está recuperando la pieza
                  <strong> {piezaActual.name}</strong>
                  {piezaActual.vehicle && (
                    <>
                      <span> del coche </span>
                      <strong> {piezaActual.vehicle}</strong>
                    </>
                  )}
                  .
                </span>
              </div>
            </>
          )}
          <div>
            <h5 className="text-uppercase mb-3">
              Proveedores de piezas disponibles
            </h5>
            {list &&
              Object.keys(list)?.map((proveedor, index) => (
                <Accordion
                  key={index}
                  expanded={accordeonExpanded === "panel" + index}
                  onChange={() => handleChange("panel" + index)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1-content"
                    id="mejoras-metricas"
                  >
                    <span>
                      <strong style={{ color: "#215731" }}>
                        {proveedor.toUpperCase()}
                      </strong>{" "}
                      <span style={{ color: "#28A745" }}>
                        ({list[proveedor]?.length || "0"} piezas)
                      </span>
                    </span>
                  </AccordionSummary>
                  <AccordionDetails>
                    {list[proveedor]?.length ? (
                      <div className="d-flex gap-3 mb-3">
                        <div
                          className="position-relative w-100 d-flex flex-column"
                          style={{ maxWidth: "250px" }}
                        >
                          <small>Buscador</small>
                          <input
                            type="text"
                            name="searcher"
                            className="form-control"
                            value={search[proveedor]}
                            onChange={(e) =>
                              setSearch((prev) => ({
                                ...prev,
                                [proveedor]: e.target.value,
                              }))
                            }
                            placeholder="Buscar por marca o modelo ..."
                          />
                          <FontAwesomeIcon
                            icon={faSearch}
                            color="#215732"
                            className="icon mt-2"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        {obtenerReferenciasUnicas(list[proveedor]).length >
                          1 && (
                          <div style={{ maxWidth: "250px" }}>
                            <label>Referencia</label>
                            <select
                              id="referenciaPieza"
                              value={referenciaPieza[proveedor]}
                              onChange={(e) =>
                                setReferenciaPieza((prev) => ({
                                  ...prev,
                                  [proveedor]: e.target.value,
                                }))
                              }
                            >
                              <option key="referenciaPieza-0" value={-1}>
                                Todos
                              </option>
                              {obtenerReferenciasUnicas(list[proveedor]).map(
                                (referencia) => (
                                  <option key={referencia} value={referencia}>
                                    {referencia}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        )}
                        {obtenerProveedoresUnicos(list[proveedor]).length >
                          1 && (
                          <div style={{ maxWidth: "250px" }}>
                            <label>Proveedor</label>
                            <select
                              id="proveedorPieza"
                              value={proveedorPieza[proveedor]}
                              onChange={(e) =>
                                setProveedorPieza((prev) => ({
                                  ...prev,
                                  [proveedor]: e.target.value,
                                }))
                              }
                            >
                              <option key="proveedorPieza-0" value={-1}>
                                Todos
                              </option>
                              {obtenerProveedoresUnicos(list[proveedor]).map(
                                (proveedorPieza) => (
                                  <option
                                    key={proveedorPieza}
                                    value={proveedorPieza}
                                  >
                                    {proveedorPieza}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        )}
                        {proveedor === "gpa" && (
                          <div>
                            <label>Kilometraje</label>
                            <div className="w-100 d-flex align-items-center gap-2">
                              <span>Desde</span>
                              <div className="position-relative w-100 d-flex flex-column">
                                <input
                                  type="text"
                                  name="kmMin"
                                  value={kmMin[proveedor]}
                                  onChange={(e) =>
                                    setKmMin((prev) => ({
                                      ...prev,
                                      [proveedor]: e.target.value,
                                    }))
                                  }
                                  placeholder="Min"
                                  style={{ width: "100px" }}
                                />
                                {kmMin[proveedor] && (
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    color="#215732"
                                    className="icon"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      setKmMin((prev) => ({
                                        ...prev,
                                        [proveedor]: "",
                                      }))
                                    }
                                  />
                                )}
                              </div>
                              <span>Hasta</span>
                              <div className="position-relative w-100 d-flex flex-column">
                                <input
                                  type="text"
                                  name="kmMax"
                                  value={kmMax[proveedor]}
                                  onChange={(e) =>
                                    setKmMax((prev) => ({
                                      ...prev,
                                      [proveedor]: e.target.value,
                                    }))
                                  }
                                  placeholder="Max"
                                  style={{ width: "100px" }}
                                />
                                {kmMax[proveedor] && (
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    color="#215732"
                                    className="icon"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      setKmMax((prev) => ({
                                        ...prev,
                                        [proveedor]: "",
                                      }))
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    <div className="d-flex flex-column gap-3">
                      {filtrarPiezas(
                        list[proveedor],
                        referenciaPieza[proveedor],
                        proveedorPieza[proveedor],
                        kmMin[proveedor],
                        kmMax[proveedor],
                        search[proveedor]
                      )?.map((pieza, index) => (
                        <div
                          className="d-flex justify-content-between rounded"
                          style={{
                            boxShadow:
                              "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
                            backgroundColor: "#F6F5F8",
                            height: "200px",
                          }}
                          key={index}
                        >
                          <div className="d-flex">
                            <img
                              src={
                                !pieza.imagenes?.length ||
                                !pieza.imagenes[0] ||
                                !pieza.imagenes[0].includes("http")
                                  ? noImage
                                  : pieza.imagenes[0]
                              }
                              alt="Imagen pieza"
                              className="rounded-start"
                              style={{
                                width: "200px",
                                minWidth: "200px",
                                objectFit: "cover",
                                backgroundColor:
                                  !pieza.imagenes?.length ||
                                  !pieza.imagenes[0]?.includes("http")
                                    ? "#fff"
                                    : "transparent",
                              }}
                            />
                            <div className="p-4 d-flex flex-column gap-1 justify-content-between">
                              <div>
                                {pieza.name && (
                                  <div className="fs-5 fw-semibold">
                                    {pieza.name?.charAt(0).toUpperCase() +
                                      pieza.name?.slice(1)}
                                  </div>
                                )}
                                <div style={{ fontSize: "14px" }}>
                                  <div>
                                    {(pieza.referencia_pieza ||
                                      pieza.oem_code ||
                                      currentReferencia) && (
                                      <p className="text-muted">
                                        {`Referencia pieza: ${
                                          pieza.referencia_pieza ||
                                          pieza.oem_code ||
                                          currentReferencia
                                        }`}
                                      </p>
                                    )}
                                    {pieza.id_referencia_plataforma && (
                                      <p className="text-muted">
                                        {`Referencia plataforma: ${pieza.id_referencia_plataforma}`}
                                      </p>
                                    )}
                                    {pieza.proveedor && (
                                      <p className="text-muted fw-bold">
                                        {`Proveedor: ${
                                          pieza.proveedor?.nombre ||
                                          pieza.proveedor
                                        }`}
                                      </p>
                                    )}
                                  </div>
                                  {pieza.km_motor ? (
                                    <div className="my-2">
                                      <p>Detalles:</p>
                                      <ul style={{ padding: 0, margin: 0 }}>
                                        {pieza.km_motor && (
                                          <li>
                                            • Kilometraje: {pieza.km_motor}
                                          </li>
                                        )}
                                      </ul>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <span
                                className="fw-bold"
                                style={{ color: "#215731" }}
                              >
                                {pieza.vehicle
                                  ? pieza.vehicle
                                  : `${getMarca(pieza)} ${getModelo(pieza)}`}
                              </span>
                            </div>
                          </div>
                          <div
                            className="d-flex flex-column justify-content-between p-4"
                            style={{ gap: "3rem" }}
                          >
                            <div className="d-flex flex-column align-items-end fw-bold">
                              <div className="fs-4">{getPrecio(pieza)}</div>
                            </div>
                            <button
                              type="button"
                              disabled={isLoading}
                              className="btn btn-primary ms-auto"
                              style={{ maxWidth: "171px" }}
                              onClick={() => onSubmit(pieza, currentReferencia)}
                            >
                              SELECCIONAR
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
          </div>
        </div>
      ) : (
        <p>No se encontraron piezas para esta busqueda.</p>
      )}
      <Dialog
        open={searchAgainDialog}
        onClose={() => setSearchAgainDialog(false)}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="search-again-dialog-title"
        aria-describedby="search-again-dialog-title-description"
      >
        <DialogTitle id="search-again-dialog-title">
          <strong>Información pieza</strong>
        </DialogTitle>
        <DialogContent id="search-again-dialog-title-description">
          <p>
            El scrapper de Blat no pudo obtener la información de la pieza,
            quieres reintentar la petición?
          </p>
          <div className="d-flex justify-content-end mt-2">
            <button
              type="button"
              className="btn btn-primary align-self-end"
              onClick={handleYesClick}
            >
              Si
            </button>
            <button
              type="button"
              className="btn btn-default align-self-end ms-3"
              onClick={() => setSearchAgainDialog(false)}
            >
              No
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
