import API from '../../../../../services/api';


/**************** MEJORAS RECAMBISTAS ****************/
export const getMejoras = async (userId) => {
    const params = userId ? '?user_id=' + userId : '';
    return API.get('/mejoras-recambista' + params).then(res => res.data);
}

export const newMejora = async (mejora) => {
    return API.post('/mejoras-recambista', mejora).then(res => res.data);
}

export const updateMejora = async (mejora) => {
    return API.put('/mejoras-recambista/' + mejora.id, mejora).then(res => res.data);
}

export const deleteMejora = async (mejoraId) => {
    return API.delete('/mejoras-recambista/' + mejoraId).then(res => res.data);
}

/**************** MEJORAS METRICAS ****************/
export const getMejorasMetricas = async (userId) => {
    const params = userId ? '?user_id=' + userId : '';
    return API.get('/mejoras-metricas-recambista' + params).then(res => res.data);
}

export const newMejoraMetrica = async (mejora) => {
    return API.post('/mejoras-metricas-recambista', mejora).then(res => res.data);
}

export const updateMejoraMetrica = async (mejora) => {
    return API.put('/mejoras-metricas-recambista/' + mejora.id, mejora).then(res => res.data);
}

export const deleteMejoraMetrica = async (mejoraId) => {
    return API.delete('/mejoras-metricas-recambista/' + mejoraId).then(res => res.data);
}

/************** PROCEDIMIENTOS DE MEJORAS **************/
export const getProcedimientosMejoras = async (userId) => {
    const params = userId ? '?user_id=' + userId : '';
    return API.get('/procedimientos-mejoras' + params).then(res => res.data);
}

export const newProcedimientoMejora = async (procedimiento) => {
    return API.post('/procedimientos-mejoras', procedimiento).then(res => res.data);
}

export const updateProcedimientoMejora = async (procedimiento) => {
    return API.put('/procedimientos-mejoras/' + procedimiento.id, procedimiento).then(res => res.data);
}

export const deleteProcedimientoMejora = async (procedimientoId) => {
    return API.delete('/procedimientos-mejoras/' + procedimientoId).then(res => res.data);
}


/***************** PUNTOS IMPORTANTES *****************/
export const getPuntosImportantes = async (userId) => {
    const params = userId ? '?user_id=' + userId : '';
    return API.get('/puntos-importantes' + params).then(res => res.data);
}

export const newPuntoImportante = async (punto) => {
    return API.post('/puntos-importantes', punto).then(res => res.data);
}

export const updatePuntoImportante = async (punto) => {
    return API.put('/puntos-importantes/' + punto.id, punto).then(res => res.data);
}

export const deletePuntoImportante = async (puntoId) => {
    return API.delete('/puntos-importantes/' + puntoId).then(res => res.data);
}
