import API from '../../../../../services/api';

export const getAvisosProveedor = async (page, startDate, endDate, type = null, proveedorId = null) => {
    const pagination = '?page=' + page;
    const dates = '&start_date=' + startDate + '&end_date=' + endDate;
    const search = type !== null ? '&search=' + type : '&search=';
    const proveedor = proveedorId !== null ? '&proveedor_id=' + proveedorId : '';
    return API.get('/avisos-proveedor' + pagination + dates + search + proveedor).then(res => res.data);
}

export const bloquearTodosTipoPiezas = (tipo) => {
    return API.post('/avisos-proveedor/bloquear-todos', tipo).then(res => res.data);
}