// LOAD ALL THE READ ONLY DATA
import { getCarroceriasPesadas } from "../views/Dashboard/components/shared/services/carroceriasPesadas";
import {
  makeFetchingReducer,
  makeAsyncTypes,
  asyncMac,
  makeSetReducer,
} from "./utils";

const asyncTipos = makeAsyncTypes("carrocerias_pesadas");

const [setPending, setFullFilled, setError] = asyncMac(asyncTipos);

export const carroceriasPesadasFullfilledReducer = makeSetReducer([
  "carrocerias_pesadas/fullfilled",
]);

export const fetchingReducer = makeFetchingReducer([
  "carrocerias_pesadas/pending",
  "carrocerias_pesadas/fullfilled",
  "carrocerias_pesadas/rejected",
]);

export const fetchCarroceriasPesadas = () => async (dispatch) => {
  dispatch(setPending());

  try {
    const response = await getCarroceriasPesadas()
    const data = await response.data;
    dispatch({ type: "carrocerias_pesadas/fullfilled", payload: data });      
    setFullFilled(data);
    const readOnly = JSON.parse(localStorage.getItem('read_only'));
    readOnly.carrocerias_pesadas = data;
    localStorage.setItem('read_only', JSON.stringify(readOnly));
  } catch (e) {
    dispatch(setError(e.message));
  }
};
