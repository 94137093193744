import { getUsers } from "../views/Dashboard/components/shared/services/users";
import { asyncMac, makeAsyncTypes, makeCrudReducer, makeFetchingReducer, makeSetReducer, reduceReducers } from "./utils";

const crudReducer = makeCrudReducer([
    'users/add',
    'users/edit',
    'users/delete'
])

const asyncUsers = makeAsyncTypes('users');

const [setPending, setFullFilled, setError ] = asyncMac(asyncUsers);

export const usersFetchingReducer = makeFetchingReducer([
    'users/pending', 
    'users/fullfilled', 
    'users/rejected'
]);

const fullfilledReducer = makeSetReducer(['users/fullfilled']);

export const fetchUsers = (page, search) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getUsers(page, search)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const usersReducer = reduceReducers(crudReducer, fullfilledReducer);
export const usersStatusReducer = usersFetchingReducer;