import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

export default function FormSimple({
    id,
    item, 
    userId,
    onSubmitItem
}) {
    let formSimpleSchema;
    let defaultValues;

    if(id === 'mejoras-metricas') {
        formSimpleSchema = yup.object({
            descripcion: yup.string().required("La descripción es obligatoria"),
            tipo: yup.string().required("El tipo de mejora es obligatorio"),
          }).required();
          defaultValues = {
            id: 0,
            descripcion: '',
            tipo: '',
          }
    } else if(id === 'procedimientos-mejoras') {
        formSimpleSchema = yup.object({
            descripcion: yup.string().required("La descripción es obligatoria"),
          }).required();
          defaultValues = {
            id: 0,
            descripcion: '',
          }
    } else {
        formSimpleSchema = yup.object({
            descripcion: yup.string().required("La descripción es obligatoria"),
            importancia: yup.string().required("La importancia del punto importante es obligatoria"),
          }).required();
          defaultValues = {
            id: 0,
            descripcion: '',
            importancia: '',
          }
    }

    const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm({
        resolver: yupResolver(formSimpleSchema),
        mode: "onChange",
        defaultValues: defaultValues
    });

    useEffect(() => {
        reset(item);
    }, [item]);


    const handleChangeTipo = (e) => { setValue('tipo', e.target.value) }
    const handleChangeImportancia = (e) =>  { setValue('importancia', e.target.value) }

    const handleSubmitItem = async(data, e) => {
        e.preventDefault();
        data.created_by = userId;
        onSubmitItem(data);
    }

    
    return (
        <div className="w-100 d-flex flex-column">
            <form className="form mb-4" onSubmit={handleSubmit(handleSubmitItem)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={item ? item.id : ''} />

                <div className="w-100 d-flex flex-column">
                    <div className="form-group me-3">
                        <label htmlFor="descripcion">Descripción</label>
                        <textarea
                            {...register("descripcion")}
                            defaultValue={item ? item.descripcion : ""}
                            rows={5}
                        ></textarea>

                        <div className="form-invalid">
                            {(errors.descripcion) && errors.descripcion?.message}
                        </div>
                    </div>

                    { (id === 'mejoras-metricas') ?
                        <div className="form-group me-3">
                            <label htmlFor="tipo">Tipo</label>
                            <select 
                                {...register("tipo")}
                                defaultValue={item ? item?.tipo : ""}
                                onChange={handleChangeTipo}>
                                <option value="">Selecciona</option>
                                <option value="conversion">Conversión</option>
                                <option value="incidencia">Incidéncia</option>
                                <option value="beneficio">Beneficio</option>
                                <option value="no_encontrada">Pieza no encontrada</option>
                            </select>

                            <div className="form-invalid">
                                {(errors.tipo) && errors.tipo?.message}
                            </div>
                        </div>
                        : null
                    }

                    { (id === 'puntos-importantes') ?
                        <div className="form-group me-3">
                            <label htmlFor="importancia">Importancia</label>
                            <select 
                                {...register("importancia")}
                                defaultValue={item ? item?.importancia : ""}
                                onChange={handleChangeImportancia}>
                                <option value="">Selecciona</option>
                                <option value="1">Leve</option>
                                <option value="2">Media</option>
                                <option value="3">Alta</option>
                            </select>

                            <div className="form-invalid">
                                {(errors.importancia) && errors.importancia?.message}
                            </div>
                        </div>
                        : null
                    }
                </div>

                <div className="d-flex align-items-center align-self-end">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    )
}