import React from 'react';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { fetchProveedorPiezasMetrics, filterProveedorPiezasMetrics } from '../../../../../../../../redux/features/dashboard';
import '../../../Dashboard.scss';
import DateSelector from '../../../../../shared/components/DateSelector';
import InformacionProveedores from './InformacionProveedores';
import InformacionGeneralProveedores from './InformacionGeneralProveedores';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import InfoModal from '../../../../../shared/components/InfoModal';
import HistoryList from './HistoryList';

export default function ProveedoresIncidenciasTab() {
    const dispatch = useDispatch();
    const [currentProveedor, setCurrentProveedor] = useState(null);
    const [startDate, setStartDate] = useState(moment().date() >= 26 ?
        moment().date(26).format('YYYY-MM-DD')
    :   moment().subtract(1, 'month').date(26).format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().date() >= 26 ? 
        moment().add(1, 'month').date(25).format('YYYY-MM-DD')
    :   moment().date(25).format('YYYY-MM-DD'));
    const [view, setView] = useState('variable');
    const[openHistory, setOpenHistory] = useState(false);

    const dashboard = useSelector(state => {
        return state.dashboard.entity.admin
    });


    useEffect(() => {
        dispatch(fetchProveedorPiezasMetrics(startDate, endDate, view, null));
    }, [])

    const handleDateInterval = (startDate, endDate, view) => {
        setView(view);
        setStartDate(startDate);
        setEndDate(endDate);

        dispatch(fetchProveedorPiezasMetrics(startDate, endDate, view, (currentProveedor ? currentProveedor?.id :  null)));
    }

    const handleChangeProveedor = async(proveedor) => {
        if(proveedor !== "" || proveedor !== undefined || proveedor !== null) {
            await dispatch(fetchProveedorPiezasMetrics(startDate, endDate, view, proveedor.id));
            setCurrentProveedor(proveedor);
            return;
        } 

        setCurrentProveedor(null);
        await dispatch(fetchProveedorPiezasMetrics(startDate, endDate, view, null));
    }

    const handleFilter = async(type, value) =>{
        if(type === 'ranking_incidencias') {
            await dispatch(filterProveedorPiezasMetrics(startDate, endDate, type, (value === 'todos' ? null : value), null));
        } else {
            await dispatch(filterProveedorPiezasMetrics(startDate, endDate, type, null, value));
        }
    }

    const handleOpenHistory = () => setOpenHistory(true);
    const handleCloseHistory = () => setOpenHistory(false);

    const handleReload = () => {
        dispatch(fetchProveedorPiezasMetrics(startDate, endDate, view, (currentProveedor ? currentProveedor?.id :  null)));
    }

    return (
        <div className='w-100 d-flex flex-column align-items-center dashboard'>
            <div className='w-100 mt-4 summary'>
                <div className='w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between'>
                    <h1 className='flex-grow-1'>Información de proveedores</h1>

                    <div className='flex-grow1 d-flex align-items-center'>
                        <DateSelector onSelectedDate={handleDateInterval} /> 

                        <button className="btn btn-add ms-3" onClick={() => handleOpenHistory()}>
                            <span className="add">
                                <FontAwesomeIcon icon={faClock} size="1x" className="action" color="#215732" />
                            </span>
                            Historial
                        </button>
                    </div>
                </div>

                { (dashboard.proveedoresIncidenciasMetrics) &&  
                    <InformacionGeneralProveedores 
                        view={view}
                        data={dashboard.proveedoresIncidenciasMetrics}
                        onFilterData={handleFilter}
                    />
                }

                { (dashboard.proveedoresIncidenciasMetrics) &&  
                   <div className='w-100'>
                        <InformacionProveedores
                            view={view}
                            data={dashboard.proveedoresIncidenciasMetrics}
                            proveedor={currentProveedor}
                            onSelectProveedor={handleChangeProveedor}
                            onActionDone={handleReload}
                        />
                   </div>
                }
            </div>

            { (openHistory) &&
                <InfoModal
                    state={openHistory}
                    title={'Historico de avisos y bloqueos'}
                    content={
                        <HistoryList
                            startDate={startDate}
                            endDate={endDate}
                        />
                    }
                    width={'lg'}
                    onClose={handleCloseHistory}
                ></InfoModal>
            }
        </div>
    )
}
