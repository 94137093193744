import React, { useState } from 'react';
import FsLightbox from "fslightbox-react";

export default function ImagesSlider ({
    images
}) {
    const [openImage, setOpenImage] = useState(false);
    const [imagesSRCs, setImagesSRCs] = useState([]);
    const [imageIndex, setImageIndex] = useState(0);

    const showImage = (src, images) => {
        if (!src) {
            return;
        }

        setImagesSRCs(images);
        let index = images.findIndex(img => img === src);
        setImageIndex(index);
        setOpenImage(!openImage);
    }

    if (!Array.isArray(images) || images.length <= 0) {
        return null;
    }

    return (
        <div className={'image-wrapper d-flex align-items-center'}>
            {images.map((image, index) => {
                return index !== 0 ? null : (
                    <div className={'d-flex justify-content-center row image-wrapper__img-container'} key={image + index}>
                        <img
                            src={image}
                            alt='Imagen no disponible'
                            className='image col-11'
                            onClick={() => showImage(image, images)}
                            style={{cursor: 'pointer', marginRight: '8px'}}
                        />
                    </div>
                );
            })}

            <FsLightbox
                type={'image'}
                toggler={openImage}
                sources={imagesSRCs}
                sourceIndex={imageIndex}
            />
        </div>
    );
}