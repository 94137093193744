import { getVariables } from "../views/Dashboard/components/shared/services/variables";
import { asyncMac, makeAsyncTypes, makeFetchingReducer, makeSetReducer } from "./utils";

const asyncVariables = makeAsyncTypes('variables');

const [setPending, setFullFilled, setError ] = asyncMac(asyncVariables);

export const variablesFetchingReducer = makeFetchingReducer([
    'variables/pending', 
    'variables/fullfilled', 
    'variables/rejected'
]);

export const variablesFullfilledReducer = makeSetReducer(['variables/fullfilled']);

export const fetchVariables = () => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getVariables()
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}