import { getAvisosStock } from "../views/Dashboard/components/shared/services/avisosStock";
import { asyncMac, makeAsyncTypes, makeFetchingReducer, makeSetReducer } from "./utils";

const asyncSolicitudes = makeAsyncTypes('avisos-stock');

const [setPending, setFullFilled, setError ] = asyncMac(asyncSolicitudes);

export const avisosStockFetchingReducer = makeFetchingReducer([
    'avisos-stock/pending', 
    'avisos-stock/fullfilled', 
    'avisos-stock/rejected'
]);

const fullfilledReducer = makeSetReducer(['avisos-stock/fullfilled']);

export const fetchAvisosStock = (page, search) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getAvisosStock(page, search)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const avisosStockReducer = fullfilledReducer;
export const avisosStockStatusReducer = avisosStockFetchingReducer;