import { useEffect, useState } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faStar } from "@fortawesome/free-solid-svg-icons";
import { Accordion, AccordionDetails, AccordionSummary, Box, Tab } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import TableSimple from "../shared/TableSimple";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import InfoModal from "../../InfoModal";
import FormSimple from "../shared/FormSimple";
import ConfirmModal from "../../ConfirmModal";
import { deletePuntoImportante, newPuntoImportante, updatePuntoImportante } from "../../../services/dynamicDashboard";
import { toast } from "react-toastify";
import { setErrorMessage } from "../../../helpers/functionalities";

export default function PuntosImportantes({
    userId,
    puntos,
    onActionDone
}) {

    const [tab, setTab] = useState('avisos-3');
    const [currentPunto, setCurrentPunto] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    useEffect(() => {
        
    }, [puntos]);

    const handleChangeTab = (e, tab) => setTab(tab);

    const handleOpenForm = (mejora) => {
        if(mejora) setCurrentPunto(mejora);

        setOpenForm(true);
    }

    const handleCloseForm = () => {
        setCurrentPunto(null);
        setOpenForm(false);
    }

    const handleSubmitMejora = async(data) => {
        let response;

        if(!currentPunto) {
            response = await newPuntoImportante(data)
            .catch(function (error) {
                toast.error(setErrorMessage(error))
            });
        } else {
            response = await updatePuntoImportante(data)
            .catch(function (error) {
                toast.error(setErrorMessage(error))
            });
        }

        if(response && response.success) {
            toast.success(response.message);
            handleCloseForm();
            onActionDone();
        }
    }

    const handleOpenConfirmDelete = (mejora) => {
        setCurrentPunto(mejora);
        setOpenConfirmDelete(true);
    }
    const handleCloseConfirmDelete = () => {
        setCurrentPunto(null);
        setOpenConfirmDelete(false);
    }

    const handleConfirmDelete = async(confirm) => {
        if(confirm) {
            const response = await deletePuntoImportante(currentPunto.id)
            .catch(function (error) {
                toast.error(setErrorMessage(error))
            });

            if(response && response.success) {
                toast.success(response.message);
                onActionDone();
            }
        }

        handleCloseConfirmDelete();
    }
  
    return (
        <div className="w-100 d-flex flex-column">
            <div className="w-100 mt-4">
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="puntos-importantes-content"
                        id="puntos-importantes"
                    >
                        <FontAwesomeIcon icon={faStar} size="1x" color="#215732" className="me-3"></FontAwesomeIcon>
                        Lista de puntos importantes
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="w-100">
                            { (userId) ?
                                <div className="w-100 d-flex align-items-center justify-content-end mb-4">
                                    <button className="btn btn-add" onClick={() => handleOpenForm()}>
                                        <span className="add">
                                            <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                                        </span>
                                        Nuevo punto importante
                                    </button>
                                </div>
                                : null
                            }

                            <TabContext value={tab}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChangeTab} aria-label="Puntos importantes">
                                        <Tab label={<span className="fw-bold text-danger">AVISO 3</span>} value="avisos-3" />
                                        <Tab label={<span className="fw-bold text-warning">AVISO 2</span>} value="avisos-2" />
                                        <Tab label={ <span className="fw-bold text-green">AVISO 1</span>} value="avisos-1" />
                                    </TabList>
                                </Box>
                                <TabPanel value="avisos-3">
                                    <TableSimple 
                                        id={'puntos-importantes-3'}
                                        userId={userId}
                                        label={'puntos importantes'}
                                        items={puntos?.filter(m => m.importancia === 3)}
                                        onOpenForm={handleOpenForm}
                                        onDelete={handleOpenConfirmDelete}
                                    />
                                </TabPanel>
                                <TabPanel value="avisos-2">
                                    <TableSimple 
                                        id={'puntos-importantes-2'}
                                        userId={userId}
                                        label={'puntos importantes'}
                                        items={puntos?.filter(m => m.importancia === 2)}
                                        onOpenForm={handleOpenForm}
                                        onDelete={handleOpenConfirmDelete}
                                    />
                                </TabPanel>
                                <TabPanel value="avisos-1">
                                    <TableSimple 
                                        id={'puntos-importantes-1'}
                                        userId={userId}
                                        label={'puntos importantes'}
                                        items={puntos?.filter(m => m.importancia === 1)}
                                        onOpenForm={handleOpenForm}
                                        onDelete={handleOpenConfirmDelete}
                                    />
                                </TabPanel>
                            </TabContext>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>

            { (openForm) && 
                <InfoModal
                    state={openForm}
                    title={(currentPunto ? 'Editar' : 'Nuevo') + ' punto importante'}
                    content={
                        <FormSimple 
                            id={'puntos-importantes'}
                            item={currentPunto}
                            userId={userId}
                            onSubmitItem={handleSubmitMejora}
                        />
                    }
                    width={'sm'}
                    onClose={handleCloseForm}
                ></InfoModal>
            }

            { openConfirmDelete && 
                <ConfirmModal 
                    onConfirmAction={handleConfirmDelete} 
                    title={'Eliminar'} 
                    description={'Estás seguro de eliminar?'}
                    state={openConfirmDelete}>
                </ConfirmModal>
            }
        </div>
    )
}