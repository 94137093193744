import React, { useState} from 'react';
import { useForm } from 'react-hook-form';
import './scrapping_by_ref_form.scss';
import { initialMarketPlacesOptions, nationalMarketPlaces } from "../helpers/searcherHelper";

export default function ScrappingByRefForm({
    onSearch
}) {
    const {
        register,
        formState: { errors },
        handleSubmit: handleSubmitRef,
        setValue,
        getValues,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            id_original: '',
            ref: '',
            marketPlaces: [],
        }
    });

    const [marketPlacesOptions, setMarketPlacesOptions] = useState(initialMarketPlacesOptions);
    const [btnDisabled, setBtnDisabled] = useState(true);

    const setInitialState = () => setMarketPlacesOptions(initialMarketPlacesOptions);

    const onSubmit = async (data, e) => {
        e.preventDefault();

        data.marketPlaces = getSelectedMarketPlaces();
        setInitialState();
        onSearch(data);
    };

    const getSelectedMarketPlaces = () =>
        marketPlacesOptions.filter(option => option.checked === true).map(marketPlace => marketPlace.marketPlace);

    const handleRefChange = (e) => handleFormChange(e, 'ref');

    const handleChangeID = (e) => handleFormChange(e, 'id_original');

    const handleFormChange = (e, param) => {
        const value = e.target.value.trim();
        setValue(param, value);

        checkIfIsEnabled();
    }

    const checkIfIsEnabled = () => setBtnDisabled(!(getValues('partRef') !== ''));

    const handleSelectNationalWebs = () => {
        const updateCheckboxes = marketPlacesOptions.map((option) =>
            Object.assign({}, option, {checked: nationalMarketPlaces.includes(option.marketPlace)})
        );
        setMarketPlacesOptions(updateCheckboxes);
    }

    const handleSelectInternationalWebs = () => {
        const updateCheckboxes = marketPlacesOptions.map((option) =>
            Object.assign({}, option, {checked: !nationalMarketPlaces.includes(option.marketPlace)})
        );
        setMarketPlacesOptions(updateCheckboxes);
    }

    const handleSelectionInBulk = (condition) => {
        const updatedCheckboxes = marketPlacesOptions.map(
            (option) => Object.assign({}, option, {checked: condition})
        );
        setMarketPlacesOptions(updatedCheckboxes);
    }

    const handleCheckboxChange = (id) => {
        const updatedCheckboxes = marketPlacesOptions.map(option => {
            return option.id === id
                ? Object.assign({}, option, {checked: !option.checked})
                : option;
        });

        setMarketPlacesOptions(updatedCheckboxes);
    }

    return (
        <form className={'scrapping-by-ref-form'} onSubmit={handleSubmitRef(onSubmit)}>
            <div className="d-flex align-self-center mt-2">
                <div className="form-group me-0 me-md-3">
                    <label>
                        Referencia pieza:
                        <input
                            type="text"
                            className="form-control"
                            {...register("ref", { required: true })}
                            onChange={handleRefChange}
                            defaultValue=''
                        />
                    </label>
                    <div className="form-invalid">
                        {errors.ref?.type === 'required' && "La referencia es obligatoria"}
                    </div>
                </div>
                <div className="form-group me-0 me-md-3">
                    <label>
                        ID (opcional)
                        <input
                            type="text"
                            className="form-control"
                            {...register("id_original", { required: false })}
                            onChange={handleChangeID}
                            defaultValue=''
                        />
                    </label>
                </div>
            </div>
            <div>
                <p className={'w-25 mb-2'}>Selecciona una o varias Webs: </p>
                <label><input type={'radio'} name={'rftype'} onChange={() => handleSelectionInBulk(true)}/> Todas</label>
                <label className={'mx-4'}><input type={'radio'} name={'rftype'} onChange={handleSelectNationalWebs}/> Nacionales</label>
                <label><input type={'radio'} name={'rftype'} onChange={handleSelectInternationalWebs} /> Internacionales</label>
                <label className={'mx-4'}><input type={'radio'} name={'rftype'} onChange={() => handleSelectionInBulk(false)} /> No seleccionar ninguna</label>
                <div className={'market-places-wrapper mt-2'}>
                    { marketPlacesOptions.map((option) => {
                        return (
                            <div className={'my-1'} key={option.id + option.marketPlace}>
                                <label>
                                    <input
                                        type={'checkbox'}
                                        id={option.marketPlace}
                                        {...register('marketPlaces', { required: false })}
                                        value={option.marketPlace}
                                        checked={option.checked}
                                        onChange={() => handleCheckboxChange(option.id)}
                                    />
                                    {option.marketPlace}
                                </label>
                            </div>
                        );
                    })
                    }
                </div>
            </div>
            <div className={'d-flex justify-content-end'}>
                <button type="submit" disabled={btnDisabled} className="btn btn-primary mt-2 me-4">Buscar</button>
            </div>
        </form>
    )
}