
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import SwiperSlider from './SwiperSlider';

import './infodetail.scss'

const InfoDetail = ({data, imgs, isLoadingImgs}) => {
  return (
    <>
      <div className='info-detail'>
        <div className='info-detail__text'>
          <p className='two-items'>
            <span><b>OEMBR:</b> {data?.oenbr}</span> 
            <span><b>EAN:</b> {data?.ean} </span> 
          </p>
          <p className='two-items'>
            <span><b>Grupo:</b> {data?.assemblygroupdescription} </span> 
            <span><b>Descripción:</b> {data?.description} </span> 
          </p>
          <p className='three-items'>
            <span><b>Marca:</b> {data?.brand} </span> 
            <span><b>Uso:</b> {data?.usagedescription} </span> 
            <span><b>Estado:</b> {data?.state} </span> 
          </p>
          <p className='two-items'>
            <span><b>Valido:</b> {data?.isvalid} </span> 
            <span><b>Unidades:</b> {data?.packingunit}</span>
          </p>
        </div>
      </div>
      <div className='info-detail__imgs'>
        <p><b>Archivos adjuntos:</b></p>
        <SwiperSlider fotos={imgs} isLoadingImgs={isLoadingImgs} />
      </div>
    </>
  )
}

export default InfoDetail
