import { useState } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faEye, faEyeSlash, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import ConfirmModal from "../../../shared/components/ConfirmModal";
import MensajeForm from "./components/MensajeForm";
import { deleteMensaje, enableDisableMensaje } from "../../../shared/services/mensajes";
import { fetchRecambistasMessages } from "../../../../../../redux/features/dashboard";
import InfoModal from "../../../shared/components/InfoModal";

export default function MensajesRecambistas() {
    const dispatch = useDispatch();
    const [messageId, setMessageId] = useState(null);
    const [currentMessage, setCurrentMessage] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [isForDelete, setIsForDelete] = useState(false);
    const [openForm, setOpenForm] = useState(false);
   
    const messages = useSelector(state => {
        return state.dashboard.entity.user.mensajes
    });

    const handleOpenForm = (message = null) => {
        setCurrentMessage(message);

        if(message) {
            setIsEdit(true);
        } else {
            setIsEdit(false); 
        }

        setOpenForm(true);
    }

    const handleCloseForm = () => { 
        setOpenForm(false);
        setCurrentMessage(null);
        setIsEdit(false);
    }

    const handleActionDone = () => {
        dispatch(fetchRecambistasMessages());
        handleCloseForm();
    }

    const handleDeleteElement = async(messageId) => {
        dispatch({type: 'loading/set', payload: true});
        const response = await deleteMensaje(messageId);
        setCurrentMessage(null);

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            dispatch(fetchRecambistasMessages());
        }
    }

    const handleDelete = (id) => { 
        setMessageId(id);
        setIsForDelete(true); 
    }

    const handleConfirmDelete = (confirm) => {
        if(confirm) {
            handleDeleteElement(messageId)
        } else setIsForDelete(false);
    }

    const handleStatus = async(msg, visible) => {
        dispatch({type: 'loading/set', payload: true});
        const response = await enableDisableMensaje({id: msg, visible: visible});
        setCurrentMessage(null);

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            dispatch(fetchRecambistasMessages());
        }
    }

    return (
        <div className="w-100 d-flex flex-column align-items-start"> 
            <div className="w-100 d-flex justify-content-center">
                <div className="col-12 col-md-8 d-flex flex-column align-items-end order-1 order-md-0">
                    <button type="button" className="btn btn-add mb-4" onClick={() => handleOpenForm()}>
                        <span className="add">
                            <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                        </span>
                        Nuevo
                    </button>

                    <div className="w-100 table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Título</th>
                                    <th>Descripción</th>
                                    <th className="text-center">tipo</th>
                                    <th className="text-center">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                { messages?.map((msg, i) => {
                                    return (
                                        <tr key={'valor-' + msg.id}>
                                            <td>{msg.titulo}</td>
                                            <td>{msg.descripcion.length > 50 ? msg.descripcion.substring(0, 25) : msg.descripcion}</td>
                                            <td className="text-center">
                                                <p className={
                                                    msg.tipo === 'info' ? 'text-info'
                                                    : msg.tipo === 'aviso' ? 'text-warning'
                                                    : 'text-danger'
                                                }><b className="text-uppercase">{msg.tipo}</b></p>
                                            </td>
                                            <td className="text-center" width="15%">
                                                <span title="Editar" style={{cursor: 'pointer'}} className="me-3">
                                                    <FontAwesomeIcon icon={faPen} size="1x" className="action" color="#215732" onClick={() => handleOpenForm(msg)} />
                                                </span>
                                                { msg.visible ?
                                                    <span title="Deshabilitar" style={{cursor: 'pointer'}} className="me-3">
                                                        <FontAwesomeIcon icon={faEyeSlash} size="1x" className="action" color="#dc3545" onClick={() => handleStatus(msg.id, false)} />
                                                    </span>
                                                  :
                                                  <span title="Habilitar" style={{cursor: 'pointer'}} className="me-3">
                                                    <FontAwesomeIcon icon={faEye} size="1x" className="action" color="#215732" onClick={() => handleStatus(msg.id, true)} />
                                                    </span>
                                                }
                                                <span title="Eliminar" style={{cursor: 'pointer'}}>
                                                    <FontAwesomeIcon icon={faTrash} size="1x" className="action" color="#dc3545" onClick={() => handleDelete(msg.id)} />
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            { (openForm) && 
                <InfoModal
                    state={openForm}
                    title={(currentMessage ? 'Editar' : 'Nueva') + ' mensaje'}
                    content={
                        <MensajeForm 
                            msg={currentMessage}
                            isEdit={isEdit}
                            onActionDone={handleActionDone}
                        />
                    }
                    width={'sm'}
                    onClose={handleCloseForm}
                ></InfoModal>
            }

            { isForDelete && 
                <ConfirmModal 
                    onConfirmAction={handleConfirmDelete} 
                    title={'Eliminar'} 
                    description={'Estás seguro de eliminar?'}
                    state={isForDelete}>
                </ConfirmModal>
            }
        </div>
    )
    
}