import { useEffect } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEdit, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export default function MejorasTable({
    id,
    userId,
    mejoras,
    onOpenForm,
    onDelete,
    onValidate
}) {
    useEffect(() => {
        
    }, [mejoras]);

  
    return (
        <div className="w-100 d-flex flex-column mt-4">
            <div className="w-100 table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>fecha</th>
                            <th>descripción</th>
                            <th className="text-center">tipo</th>
                            <th className="text-center">aprobada</th>
                            { (id === 'aprobadas' || id === 'descartadas') && <th className="text-center">fecha {id === 'aprobadas' ? 'aprobada' : 'descartada'}</th>}
                            { (id === 'pendientes') && <th></th> }
                        </tr>
                    </thead>

                    <tbody>
                        { (mejoras && mejoras?.length > 0) ?
                            mejoras.map(mejora => {
                                return (
                                    <tr key={`mejora-recambista-${mejora.id}`}>
                                        <td width={'8%'}>{moment(mejora.fecha).format('DD-MM-YYYY')}</td>
                                        <td>{mejora.descripcion}</td>
                                        <td  width={'10%'} className="text-center text-uppercase">{mejora.tipo}</td>
                                        <td  width={'8%'} className="text-center">
                                            { (id === 'pendientes' || id === 'aprobadas') ?
                                                <>
                                                    { mejora.aprobado ? 
                                                    <FontAwesomeIcon icon={faCheck} size="1x" color="#00986C" />
                                                    :
                                                    <FontAwesomeIcon icon={faTimes} size="1x" color="#dc3545" />
                                                    }
                                                </>
                                                :
                                                <FontAwesomeIcon icon={faTimes} size="1x" color="#dc3545" />
                                            }
                                            
                                        </td>
                                        { (id === 'aprobadas' || id === 'descartadas') &&
                                            <td width={'8%'} className="text-center">
                                                {mejora.fecha_decision ? moment(mejora.fecha_decision).format('DD-MM-YYYY') : '-'}
                                            </td>
                                        }
                                        { (!userId) ?
                                            <td width={'8%'} className="text-center">
                                                { (id === 'pendientes') &&
                                                    <>
                                                        <span title="Editar" style={{cursor: 'pointer'}} onClick={() => onOpenForm(mejora)}>
                                                            <FontAwesomeIcon icon={faEdit} size="1x" className="action" color="#215732"></FontAwesomeIcon>
                                                        </span>

                                                        <span title="Eliminar" className="ms-2" style={{cursor: 'pointer'}} onClick={() => onDelete(mejora)}>
                                                            <FontAwesomeIcon icon={faTrash} size="1x" className="action" color="#dc3545" />
                                                        </span>
                                                    </>
                                                }
                                            </td>
                                            : 
                                            <>
                                            { (id === 'pendientes') &&
                                                <td width={'5%'} className="text-center">
                                                    <span title="Validar" style={{cursor: 'pointer'}} className="me-3" onClick={() => onValidate(mejora, true)}>
                                                        <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#00986C" />
                                                    </span>

                                                    <span title="Denegar" style={{cursor: 'pointer'}} onClick={() => onValidate(mejora, false)}>
                                                        <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                                                    </span>
                                                </td>
                                            }
                                            </>
                                        }
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td colSpan={5} className="text-center">No hay mejoras en este momento</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}