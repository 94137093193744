import React from "react";
import { toast } from "react-toastify";
import ImportForm from "../../../shared/components/ImportForm";
import { importDesguacesOffline } from "../../../shared/services/desguaces_offline";
import { setErrorMessage } from "../../../shared/helpers/functionalities";
import { importarInfoProveedores } from "../../../shared/services/proveedores";
import { importarEntidades } from "../../../shared/services/clientes";
import { useDispatch } from "react-redux";
import { importarMedidas } from "../../../shared/services/tipo_piezas";

export default function Excels() {
    const dispatch = useDispatch();

    const handleImportEntidades = async (excel) => {
        dispatch({type: 'loading/set', payload: false});
        const excelResult = await importarEntidades(excel)
        .catch(function (error) {
            dispatch({type: 'loading/set', payload: false});
            toast.error(setErrorMessage(error))
        });

        if(excelResult) {
            dispatch({type: 'loading/set', payload: false});
            toast.success('Importación hecha correctamente!');
        } else {
            toast.error('Ups! Algo ha ido mal...')
        }
    }

    const handleImportDesguacesOffline = async (excel) => {
        dispatch({type: 'loading/set', payload: true});
        const excelResult = await importDesguacesOffline(excel)
        .catch(function (error) {
            dispatch({type: 'loading/set', payload: false});
            toast.error(setErrorMessage(error))
          });

        if(excelResult && excelResult.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success('Importación hecha correctamente!');
        } else toast.error(excelResult.message);
    }

    const handleImportProveedores = async (excel) => {
        dispatch({type: 'loading/set', payload: true});
        const excelResult = await importarInfoProveedores(excel)
        .catch(function (error) {
            dispatch({type: 'loading/set', payload: false});
            toast.error(setErrorMessage(error))
        });

        if(excelResult && excelResult.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success('Importación hecha correctamente!');
        } else toast.error(excelResult.message);
    }

    const handleImportMedidas = async (excel) => {
        dispatch({type: 'loading/set', payload: true});
        const excelResult = await importarMedidas(excel)
        .catch(function (error) {
            dispatch({type: 'loading/set', payload: false});
            toast.error(setErrorMessage(error))
        });

        if(excelResult && excelResult.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success('Importación hecha correctamente!');
        } else toast.error(excelResult.message);
    }

    return (
        <div className="w-100 d-flex flex-wrap">
            <div className="flex-grow-1 d-flex flex-column align-items-center mb-5">
                <label>IMPORTACIÓN DE ENTIDADES</label>

                <ImportForm 
                    onSubmitImport={handleImportEntidades}
                /> 
            </div>

            <div className="flex-grow-1 d-flex flex-column align-items-center mb-5">
                <label>IMPORTACIÓN DE DESGUACES OFFLINE</label>

                <ImportForm 
                    onSubmitImport={handleImportDesguacesOffline}
                /> 
            </div>

            <div className="flex-grow-1 d-flex flex-column align-items-center mb-5">
                <label>IMPORTACIÓN DE INFORMACIÓN DE PROVEEDORES</label>

                <ImportForm 
                    onSubmitImport={handleImportProveedores}
                /> 
            </div>

            <div className="flex-grow-1 d-flex flex-column align-items-center mb-5">
                <label>IMPORTACIÓN DE MEDIDAS TIPO PIEZAS</label>

                <ImportForm 
                    onSubmitImport={handleImportMedidas}
                /> 
            </div>
        </div>
    )
}