import React from 'react';
import { StackedBarChart } from '../../../../../../../shared/components/Charts/components/StackedBar';

export default function GeneralMetrics({
    view, data
}) {
        return (
            <div className='w-100 d-flex flex-column align-items-center justify-content-between my-3'>
                <div className='card-grid w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between mt-3'>
                    <div className='card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                        { (view === 'dia') && <h2>Incidencias abiertas diarias</h2>}
                        { (view === 'semana') && <h2>Incidencias abiertas semanales</h2>}
                        { (view === 'mes' || view === 'variable') && <h2>Incidencias abiertas mensuales</h2>}
                        { (view === 'año' || view === 'año-variable') && <h2>Incidencias abiertas anuales</h2>}
                        { (view === 'ultimos-12meses') && <h2>Incidencias abiertas</h2>}
                        <h1>{data.incidencias_abiertas}</h1>
                    </div>

                    <div className='card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                        { (view === 'dia') && <h2>Incidencias cerradas diarias</h2>}
                        { (view === 'semana') && <h2>Incidencias cerradas semanales</h2>}
                        { (view === 'mes' || view === 'variable') && <h2>Incidencias cerradas mensuales</h2>}
                        { (view === 'año' || view === 'año-variable') && <h2>Incidencias cerradas anuales</h2>}
                        { (view === 'ultimos-12meses') && <h2>Incidencias cerradas</h2>}
                        <h1>{data.incidencias_cerradas}</h1>
                    </div>

                    <div className='card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                        <h2>Tiempo medio general</h2>
                        <h1>{data.media_pendiente_validacion}</h1>
                    </div>
                </div>

                <div className='card-grid w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between mt-4'>
                    <div className='card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                        <h2>Efectividad</h2>
                        <h1>{data.efectividad}</h1>
                    </div>

                    <div className='card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                        <h2>Satisfacción cliente</h2>
                        <h1>{data.satisfaccion_cliente}</h1>
                    </div>

                    <div className='card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                        <h2>Experiencia general</h2>
                        <h1>{data.experiencia_general}</h1>
                    </div>
                </div>

                <div className="w-100 d-flex flex-wrap align-items-center justify-content-between mt-5">
                    <div className="col-12 col-md-6 pe-0 pe-md-3">
                        <StackedBarChart 
                            title={'Número de incidencias abiertas'} 
                            dataset={data.num_abiertas_chart}
                        />
                    </div>

                    <div className="col-12 col-md-6 ps-0 ps-md-3">
                        <StackedBarChart 
                            title={'Número de incidencias cerradas'} 
                            dataset={data.num_cerradas_chart}
                        />
                    </div>
                </div>
            </div>
        )
}