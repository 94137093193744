import { useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";

export default function MessageForm({
    envioId,
    onSubmitMessage,
}) {
    const { 
        register, 
        formState: { errors }, 
        handleSubmit,  
        control } = useForm({
            defaultValues: {
                id: envioId,
                message: '',
            }
    });

    useEffect(() => {
        
    }, []);

    const onSubmit = async (data, e) => {
        e.preventDefault();
        onSubmitMessage(data);
    };

    return (
        <div className="w-100">
            <div className="w-100 d-flex flex-wrap flex-md-nowrap">
                <form className="form col-12 mb-4" onSubmit={handleSubmit(onSubmit)}>
                    <input 
                        type="hidden" 
                        {...register("id")} 
                        defaultValue={envioId} />

                    <div className="w-100">
                        <div className="form-group">
                            <label htmlFor="message">Mensaje:</label>
                            <textarea 
                                className="form-control" 
                                {...register("message", { maxLength: 255 })} 
                                defaultValue={''} ></textarea>
                        </div>

                        <div className="form-invalid">
                            {errors.message && 'El mensaje es obligatorio'}
                        </div>
                    </div>

                    <div className="mt-5">
                        <button type="submit" className="btn btn-primary">Enviar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}