import React, { useState } from 'react';
import { useEffect } from "react";
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import VirtualizedAutocompletePersonas from '../../../../../../../shared/components/AutocompletePersonas';


export default function Filter({
    proveedor,
    tipo,
    reset,
    onChangeProveedor,
    onChangeTipo,
    onReset
}) {
    const { 
        control
    } = useForm({
        defaultValues: {
            proveedor: null,
            tipo: null
        }
    });

    useEffect(() => {
    }, [proveedor, tipo, reset])

    return (
        <div className='w-100 d-flex align-items-center mb-4'>
            <VirtualizedAutocompletePersonas 
                id={"proveedor"}
                control={control}
                item={'proveedor'}
                labels={['nombre', 'telefono']}
                defaultValue={proveedor}
                onChange={onChangeProveedor}
            />

            <div className='w-100 ms-3'>
                <select 
                    id="tipo"
                    value={tipo}
                    onChange={onChangeTipo}
                >
                    <option value={"-1"}>Selecciona un tipo</option>
                    <option value={"aviso"}>Aviso</option>
                    <option value={"bloqueo"}>Bloqueo</option>
                </select>
            </div>

            <span className="d-block ms-3" title="Reset" style={{cursor: 'pointer'}} onClick={() => onReset()}>
                <FontAwesomeIcon icon={faRedo} color="#dc3545" className="icon" />
            </span>
        </div>
    )
}
