import API from '../../../../../services/api';

export const getTarifas = async () => {
    return await API.get('/tarifas').then(res => res.data);
}

export const getTarifa = async (idTarifa) => {
    return API.get('/tarifas/' + idTarifa).then(res => res.data);
}

export const newTarifa = async (tarifa) => {
    return API.post('/tarifas', tarifa).then(res => res.data);
}

export const updateTarifa = async (tarifa) => {
    return API.put('/tarifas/' + tarifa.id, tarifa).then(res => res.data);
}

export const deleteTarifa = async (idTarifa) => {
    return API.delete('/tarifas/' + idTarifa).then(res => res.data);
}