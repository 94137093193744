import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { Box } from "@mui/system";
import moment from "moment";
import { editTransportista, saveTransportista } from "../../../../../../../../redux/features/currentProveedor";

export default function TransportistasForm({
    transportistaFav, 
    proveedorId, 
    isEdit,
    onActionDone
}) {
    const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm({
        mode: "onChange",
        defaultValues: {
            id: 0,
            tipo: 'comun',
            hora_recogida: '',
            proveedor_id: proveedorId,
            transportista_id: 0
        }
    });
    const dispatch = useDispatch();
    const transportistas = useSelector(state => {
        return state.transportistas
    });
    const [horaRecogida, setHoraRecogida] = useState(null);

    useEffect(() => {
        reset(transportistaFav);

        if(transportistaFav) {
            const fechaAux = moment(moment().format("YYYY-MM-DD") + ' ' + transportistaFav.hora_recogida + ':00');
            setHoraRecogida(fechaAux);
        }
    }, [transportistaFav]);

    const handleChangeTransportista = (e) => { setValue('transportista_id', parseInt(e.target.value)); }
    const handleChangeTipo = (e) => { setValue('tipo', e.target.value); }

    const handleSubmitTransportistaFav = async(data, e) => {
        e.preventDefault();
        dispatch({type: 'loading/set', payload: true});
        data.hora_recogida = moment(horaRecogida).format('HH:mm');
        
        if(isEdit) {
            dispatch(editTransportista(data));
        } else {
            delete data.id;
            dispatch(saveTransportista(data));
        }

        onActionDone();
    }
    
    return (
        <div className="w-100 d-flex flex-column">
            <form className="form mb-4" onSubmit={handleSubmit(handleSubmitTransportistaFav)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={isEdit ? transportistaFav.id : ''} />
                <input 
                    type="hidden" 
                    {...register("proveedor_id")} 
                    defaultValue={isEdit ? transportistaFav.proveedor_id : ''} />

                <div className="w-100">
                    <div className="form-group">
                        <label>Tipo</label>

                        <select 
                            {...register("tipo", {required: true})}
                            defaultValue={isEdit ? transportistaFav.tipo : 'comun'}
                            onChange={handleChangeTipo}>
                                <option value={'comun'}>Común</option>
                                <option value={'voluminoso'}>Voluminoso</option>
                                <option value={'paletizado'}>Paletizado</option>
                        </select>
                    </div>
                </div>
                
                <div className="w-100 d-flex flex-wrap flex-md-nowrap">
                    <div className="form-group me-0 me-md-3">
                        <label htmlFor="transportista_id">Transportista:</label>
                        <select 
                            {...register("transportista_id", {required: true})}
                            defaultValue={""}
                            onChange={handleChangeTransportista}>
                            {transportistas && transportistas.length > 0 && transportistas.map(op => <option key={'transportista-' + op.id} value={op.id}>{op.nombre}</option>)}
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Horario de recogida:</label>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                            <TimePicker
                                type="time"
                                value={horaRecogida}
                                ampm={false}
                                onChange={(newValue) => {
                                    setHoraRecogida(newValue);
                                }}
                                mask={'__:__'}
                                inputFormat="HH:mm"
                                inputProps={{step: 300, /* 5min */ }}
                                renderInput={({ inputRef, inputProps, InputProps }) => (
                                    <Box className="date-picker" sx={{ position: 'relative' }}>
                                        <input className="form-control" type="time" ref={inputRef} {...inputProps} />
                                        {InputProps?.endAdornment}
                                    </Box>
                                )}
                            />
                        </LocalizationProvider>
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    )
}