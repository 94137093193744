import tecDocAPI from '../../../../../services/tecdoc'

export const getInfoByOENbr = async (oen) => {
  return tecDocAPI.get(`/references/getInfoByOENbr?OENbr=${oen}`).then(res => res.data)
}

export const getPrdInfo = async (supplierArticleNumber, supplierId) => {
  return tecDocAPI.get(`/references/getPrdInfo?datasupplierarticlenumber=${supplierArticleNumber}&supplierid=${supplierId}`)
}

export const getArticlesIAMCross = async (oenbr) => {
  return tecDocAPI.get(`/references/getArticlesIAMCross?OENbr=${oenbr}`)
}

export const getArticlesOENCross = async (supplierArticleNumber, supplierId) => {
  return tecDocAPI.get(`/references/getArticlesOENCross?datasupplierarticlenumber=${supplierArticleNumber}&supplierid=${supplierId}`)
}

export const getPicturesSupplierArtNum = async (supplierArticleNumber, supplierId) => {
  return tecDocAPI.get(`/references/getPicturesSupplierArtNum?datasupplierarticlenumber=${supplierArticleNumber}&supplierid=${supplierId}`)
}