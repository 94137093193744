import { useEffect, useState } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import VirtualizedAutocompletePersonas from "../../../shared/components/AutocompletePersonas";

export default function FusionForm({
    proveedor,
    onSubmitFusion
}) {
    const columnas = ['nombre', 'cif', 'telefono', 'email', 'direccion', 'descuento', 'poblacion', 'administracion', 'recambios'];
    const [columnasStatus, setColumnasStatus] = useState(new Array(columnas.length).fill(false) );
    const { register, formState: { errors }, handleSubmit, setValue, control } = useForm({
        mode: "onChange",
        defaultValues: {
            from: null,
            to: proveedor.id,
            fusion_columnas: false,
            columnas: [],
            fusion_descuentos: false,
            descuentos: []
        }
    });
    const [proveedorFusion, setProveedorFusion] = useState(null);

    useEffect(() => {
    }, []);

    const handleChangeProveedor = (proveedor) => {
        if(proveedor !== "" || proveedor !== undefined || proveedor !== null) {
            setProveedorFusion(proveedor);
            setValue('proveedor', proveedor);
            setValue('from', proveedor.id);
        } else setProveedorFusion(null);
    }

    const handleChangeColumna = (position) => {
        const updatedCheckedState = columnasStatus.map((item, index) =>
          index === position ? !item : item
        );
    
        setColumnasStatus(updatedCheckedState);
    };
    

    const handleSubmitFusion = (data, e) => {
        e.preventDefault();

        if(columnasStatus.find(s => s === true)) {
            data.fusion_columnas = true;
            data.columnas = columnasStatus.map((column, i) => column === true ? columnas[i] : null)
                .filter(c => c !== null);

            if(data.columnas.find(c => c === 'descuento')) data.fusion_descuentos = true;
        }
        
        onSubmitFusion(data);
    }

    const getInfoProveedor = (proveedor, item) => {
        if(proveedor) {
            return (
                <div className="w-100 d-flex flex-column">
                    <table className="table align-middle">
                        <tbody>
                            { columnas.map((columna, i) => {
                                let info = "";

                                if((columna !== 'poblacion' && columna !== 'descuento')) {
                                    info =   <tr key={'columna-' + i}>
                                                    { (item === 'origen') &&
                                                        <td>
                                                            <div>
                                                                <input 
                                                                    type="checkbox"
                                                                    id={`columnas-${i}`}
                                                                    name={columna}
                                                                    value={columna}
                                                                    checked={columnasStatus[i]}
                                                                    onChange={() => handleChangeColumna(i)}
                                                                />
                                                            </div>
                                                        </td>
                                                    }
                                                    <td><label>{columna}</label></td>
                                                    <td><p className="m-0">{proveedor[columna]}</p></td>
                                                </tr>
                                } else {
                                    if(columna === 'poblacion') {
                                        info =  <tr key={'columna-' + i}>
                                                     { (item === 'origen') && 
                                                        <td>
                                                            <div>
                                                                <input 
                                                                    type="checkbox"
                                                                    id={`columnas-${i}`}
                                                                    name={columna}
                                                                    value={columna}
                                                                    checked={columnasStatus[i]}
                                                                    onChange={() => handleChangeColumna(i)}
                                                                />
                                                            </div>
                                                        </td>
                                                     }
                                                    <td><label>{columna}</label></td>
                                                    <td>
                                                        { proveedor[columna] ?
                                                            <p className="m-0">
                                                                {proveedor[columna].codigo_postal} {proveedor[columna].poblacion}, {proveedor.pais.nombre}
                                                            </p>
                                                            : <p className="m-0">-</p>
                                                        }
                                                    </td>
                                                </tr>
                                    }
                                    if(columna === 'descuento') {
                                        info =  <tr key={'columna-' + i}>
                                                     { (item === 'origen') && 
                                                        <td>
                                                            <div>
                                                                <input 
                                                                    type="checkbox"
                                                                    id={`columnas-${i}`}
                                                                    name={columna}
                                                                    value={columna}
                                                                    checked={columnasStatus[i]}
                                                                    onChange={() => handleChangeColumna(i)}
                                                                />
                                                            </div>
                                                        </td>
                                                     }
                                                    <td><label>{columna}</label></td>
                                                    <td>
                                                        <p className="m-0">
                                                            {proveedor[columna]} | &nbsp; 
                                                            {proveedor.descuentos?.map(descuento => descuento.min + ' - ' + (descuento.max ? descuento.max : 'sin máximo') + ' = ' + descuento.descuento + '%')}
                                                        </p>
                                                    </td>
                                                </tr>
                                    }
                                    
                                }
                                return info;
                            }) }
                        </tbody>
                    </table>
                </div>
            )
        } else return null;
    }
   
    return (
        <div className="w-100 d-flex">
            <form className="form w-100" onSubmit={handleSubmit(handleSubmitFusion)}>
                <input 
                    type="hidden" 
                    {...register("from")} 
                    defaultValue={proveedor.id} />

                <input 
                    type="hidden" 
                    {...register("to")} 
                    defaultValue={''} />

                <div className="w-100 d-flex justify-content-end">
                    <div className="col-12 col-md-6  d-flex flex-column justify-content-between">
                        <h1>Proveedor destino</h1>
                        {getInfoProveedor(proveedor, 'destino')}
                    </div>
                    
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-between">
                        <div className="w-100">
                            <h1>Proveedor origen (a fusionar)</h1>
                            <small className="d-block mb-3">
                            
                                <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action" color="#dc3545" />
                                    <b>Este proveedor se va a eliminar permanentemente.</b>
                                <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action" color="#dc3545" />
                            </small>
                        </div>
                        
                        {getInfoProveedor(proveedorFusion, 'origen')}
                    </div>
                </div>

                

                <div className="w-100 mt-5">
                    <div className="form-group">
                        <label>Proveedor a fusionar:</label>
                        <VirtualizedAutocompletePersonas 
                            id={"proveedor"}
                            control={control}
                            item={'proveedor'}
                            labels={['nombre', 'telefono', 'score']}
                            defaultValue={null}
                            onChange={handleChangeProveedor}
                        />
                    </div>
                </div>

                <button className="btn btn-primary align-self-end">GUARDAR</button>
            </form>
        </div>
           
    );
}