import React, { useEffect, useState } from "react";
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import DateSelector from "../../../../../shared/components/DateSelector";
import VerticalBarChart from "../../../../../shared/components/Charts/components/VerticalBar";
import { fetchMetricasProveedor } from "../../../../../../../../redux/features/fichaProveedor";

export default function ProveedorMetrics({proveedorId}) {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(moment().date() >= 26 ?
        moment().date(26).format('YYYY-MM-DD')
    :   moment().subtract(1, 'month').date(26).format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().date() >= 26 ? 
        moment().add(1, 'month').date(25).format('YYYY-MM-DD')
    :   moment().date(25).format('YYYY-MM-DD'));
    const [view, setView] = useState('mes');
    const proveedor = useSelector(state => {
        return state.fichaProveedor.entity
    });

    useEffect(() => {
        dispatch(fetchMetricasProveedor(proveedorId, startDate, endDate, view));
    }, []);

    const handleDateInterval = (startDate, endDate, view) => {
        setView(view);
        setStartDate(startDate);
        setEndDate(endDate);

        dispatch(fetchMetricasProveedor(proveedorId, startDate, endDate, view));
    }
   
    return (
        <div className="w-100 d-flex flex-column justify-content-end">
            <DateSelector onSelectedDate={handleDateInterval} /> 

            { proveedor.metricas ?
                <div className="w-100 d-flex flex-column mt-3">
                    <h1>Información General</h1>

                    <div className="w-100 d-flex align-items-center flex-wrap card-grid">
                        <div className='card-item d-flex flex-column align-items-center mb-3'>
                        { (view === 'dia') && <h2>Presupuestos diarios</h2>}
                            { (view === 'semana') && <h2>Presupuestos semanales</h2>}
                            { (view === 'mes' || view === 'variable') && <h2>Presupuestos mensuales</h2>}
                            { (view === 'año' || view === 'año-variable') && <h2>Presupuestos anuales</h2>}
                            { (view === 'ultimos-12meses') && <h2>Presupuestos</h2>}

                            <h1>{proveedor.metricas.num_presupuestos}</h1>
                        </div>

                        <div className='card-item d-flex flex-column align-items-center mb-3'>
                            { (view === 'dia') && <h2>Ventas diarias</h2>}
                            { (view === 'semana') && <h2>Ventas semanales</h2>}
                            { (view === 'mes' || view === 'variable') && <h2>Ventas mensuales</h2>}
                            { (view === 'año' || view === 'año-variable') && <h2>Ventas anuales</h2>}
                            { (view === 'ultimos-12meses') && <h2>Ventas</h2>}

                            <h1>{proveedor.metricas.num_ventas}</h1>
                        </div>

                        <div className='card-item d-flex flex-column align-items-center mb-3'>
                            { (view === 'dia') && <h2>Devoluciones diarias</h2>}
                            { (view === 'semana') && <h2>Devoluciones semanales</h2>}
                            { (view === 'mes' || view === 'variable') && <h2>Devoluciones mensuales</h2>}
                            { (view === 'año' || view === 'año-variable') && <h2>Devoluciones anuales</h2>}
                            { (view === 'ultimos-12meses') && <h2>Devoluciones</h2>}

                            <h1>{proveedor.metricas.num_devoluciones}</h1>
                        </div>

                        <div className='card-item d-flex flex-column align-items-center mb-3'>
                        { (view === 'dia') && <h2>Compras diarias</h2>}
                            { (view === 'semana') && <h2>Compras semanales</h2>}
                            { (view === 'mes' || view === 'variable') && <h2>Compras mensuales</h2>}
                            { (view === 'año' || view === 'año-variable') && <h2>Compras anuales</h2>}
                            { (view === 'ultimos-12meses') && <h2>Compras</h2>}

                            <h1>{proveedor.metricas.facturacion_total}€</h1>
                        </div>
                    </div>

                    <div className="w-100 d-flex flex-wrap align-items-center justify-content-center mt-3">
                        <div className="col-12 col-md-8">
                            <VerticalBarChart 
                                title={'Número de presupuestos, ventas y devoluciones'} 
                                dataset={proveedor.metricas.num_chart}
                            />
                        </div>
                        
                        <div className="col-12 col-md-8">
                            <VerticalBarChart 
                                title={'Facturación y Beneficios'} 
                                dataset={proveedor.metricas.facturacion_chart}
                            />
                        </div>
                    </div>
                </div>
                : ''
            }
        </div>
    );
}