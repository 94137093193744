import { getAvisos } from "../views/Dashboard/components/shared/services/avisos";
import { asyncMac, makeAsyncTypes, makeCrudReducer, makeFetchingReducer, makeSetReducer, reduceReducers } from "./utils";

const crudReducer = makeCrudReducer([
    'avisos/add',
    'avisos/edit',
    'avisos/delete'
])

const asyncAvisos = makeAsyncTypes('avisos');

const [setPending, setFullFilled, setError ] = asyncMac(asyncAvisos);

export const avisosFetchingReducer = makeFetchingReducer([
    'avisos/pending', 
    'avisos/fullfilled', 
    'avisos/rejected'
]);

const fullfilledReducer = makeSetReducer(['avisos/fullfilled']);

export const fetchAvisos = (page, search) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getAvisos(page, search)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const avisosReducer = reduceReducers(crudReducer, fullfilledReducer);
export const avisosStatusReducer = avisosFetchingReducer;


//export const avisosReducer = makeSetReducer(['avisos/fullfilled']);