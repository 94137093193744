// LOAD ALL THE READ ONLY DATA
import { getTransportistas } from "../views/Dashboard/components/shared/services/transportistas";
import { asyncMac, makeAsyncTypes, makeFetchingReducer, makeSetReducer } from "./utils";

const asyncTarifas = makeAsyncTypes('transportistas');

const [setPending, setFullFilled, setError ] = asyncMac(asyncTarifas);

export const transportistasFullfilledReducer = makeSetReducer(['transportistas/fullfilled']);

export const fetchingReducer = makeFetchingReducer([
    'transportistas/pending', 
    'transportistas/fullfilled', 
    'transportistas/rejected'
]);

export const fetchTransportistas = () => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getTransportistas()
        const data = await response.data;
        dispatch(setFullFilled(data));
        const readOnly = JSON.parse(localStorage.getItem('read_only'));
        readOnly.transportistas = data;
        localStorage.setItem('read_only', JSON.stringify(readOnly));
    } catch (e) {
        dispatch(setError(e.message))
    }
}