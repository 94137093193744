import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from "react";
import React from "react";
import { useForm } from "react-hook-form";

import { toast } from 'react-toastify';
import { userChangePassword } from '../../../shared/services/users';


export default function ChangePasswordForm({onResetPassword}) {
    const { 
        register,  
        handleSubmit, 
        watch,
        reset
     } = useForm({
            defaultValues: {
                password: '',
                password_repeat: ''
            }
    });
    const user = JSON.parse(localStorage.getItem('user'));
  
    const [isShowPassword, setIsShowPassword] = useState(false)
    const [isShowPasswordRepeat, setIsShowPasswordRepeat] = useState(false)

    const onSubmit = async (data, e) => {
        e.preventDefault();
        if(data.password !== data.password_repeat) return toast.error('La contraseña no coincide!')
    
        const res = await userChangePassword(user.id, data.password)
        if(res?.success) {
            reset()
            onResetPassword();
            return toast.success('Contraseña modificada correctamente.')
        }
        return toast.error('Algo ha ido mal...')
    };

    return (
        <div className="w-100 change-password">
            <div className="w-100 d-flex flex-wrap justify-content-center flex-md-nowrap">
                <form className="form col-12 col-md-6 col-xl-4 my-4 change-password__form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="w-100 d-flex flex-column mt-2 gap-2">
                        <div className='change-password__form--each-password'>
                            <label>Nuevo password:</label>
                            <input
                                {...register('password', {required: true})}
                                type={isShowPassword ? 'text' : "password"}
                                className='form-control'
                                />
                            {watch('password', '') && (isShowPassword
                                ? <FontAwesomeIcon icon={faEyeSlash} onClick={() => setIsShowPassword(false)} />
                                : <FontAwesomeIcon icon={faEye} onClick={() => setIsShowPassword(true)} />)
                            }
                        </div>
                        <div className='change-password__form--each-password'>
                            <label>Repetir password:</label>
                            <input
                                {...register('password_repeat', {required: true})}
                                type={isShowPasswordRepeat ? 'text' : "password"}
                                className='form-control'
                            />
                            {watch('password_repeat', '') && (isShowPasswordRepeat
                                ? <FontAwesomeIcon icon={faEyeSlash} onClick={() => setIsShowPasswordRepeat(false)} />
                                : <FontAwesomeIcon icon={faEye} onClick={() => setIsShowPasswordRepeat(true)} />)
                            }
                        </div>                   
                    </div>

                    <div className="d-flex align-content-center align-self-end w-100 pt-4">
                        <button type="submit" className="btn btn-primary w-100">Guardar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}