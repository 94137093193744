import API from '../../../../../services/api';

export const getAlbaranes = async (page = null, search = null) => {
    const pagination = page !== null ? '?page=' + page : '';
    const searcher = search !== null && page !== null ? '&' + search : search !== null ? '?' + search : '';
    return await API.get('/albaranes' + pagination + searcher).then(res => res.data);
}

export const generateAlbaranPDF = async (idAlbaran) => {
    return API.get('/albaranes/generate_pdf/' + idAlbaran, {responseType: 'blob'}).then(res => res.data);
}

export const sendAlbaranByEmail = async (albaranId) => {
    return API.post('/albaranes/' + albaranId + '/email', null).then(res => res.data);
}

export const regenerateAlbaran = async (albaran) => {
    return API.post('/albaranes-s3', albaran).then(res => res.data);
}