import { useState, useEffect } from "react";
import React from "react";
import { Link } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import { getActions } from "./actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamationTriangle, faTimes } from "@fortawesome/free-solid-svg-icons";
import ActionsMenu from "../../../shared/components/ActionsMenu";

export default function AutorizacionesTable({
    id,
    currPage,
    pedidos,
    onPageChange,
    onValidate,
    onAssingPresupuesto
}) {
    const [pedidosList, setPedidosList] = useState(pedidos.pedidos);
 
    useEffect(() => {
        const list = pedidos.pedidos;
        setPedidosList(list);
    }, [id, currPage, pedidos]);

    const handleChangePage = (event, newPage) => { onPageChange(newPage, id); }
  
    const getPaginationCount = () => {
        var totalPages = pedidos.total_items < 15 ? 1 : Math.ceil(pedidos.total_items / 15);
        return parseInt(totalPages);
    }

    const handleAction = (action, pedido) => {
        if(action === 'assign') {
            onAssingPresupuesto(pedido)
        } else if(action === 'validate') {
            onValidate(pedido)
        }
    }

    return (
        <div className="w-100 d-flex flex-column align-items-end">
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Fecha recepcion</th>
                            <th className="text-center">delegación</th>
                            <th className="text-center">bastidor</th>
                            <th className="text-center">matricula</th>
                            <th className="text-center">pedido</th>
                            <th className="text-center">origen</th>
                            <th className="text-center">cliente</th>
                            <th className="text-center">presupuesto</th>
                            <th className="text-center">validado</th>
                            <th className="text-center"></th>
                        </tr>
                    </thead>

                    <tbody>
                        { pedidosList?.length > 0 ? 
                        pedidosList?.map((pedido) => {
                            return(
                                <tr key={'pedido-' + pedido.id}>
                                    <td>{pedido.fecha_recepcion}</td>
                                    <td className="text-center">{pedido.delegacion}</td>
                                    <td className="text-center">{pedido.bastidor}</td>
                                    <td className="text-center">{pedido.matricula}</td>
                                    <td className="text-center">{pedido.pedido}</td>
                                    <td className="text-center text-uppercase">{pedido.cliente.nom}</td>
                                    <td className="text-center">
                                        <div className="d-flex flex-column">
                                            { (pedido.entity_id && pedido.entity) ? 
                                                <Link target="_blank" rel="noopener noreferrer" to={"/cliente/ficha/" + pedido.entity.id} title="Ficha Cliente" className="text-green text-uppercase">
                                                    {pedido.entity?.nombre}
                                                </Link>
                                                : '-'
                                            }
                                            {(pedido.client_id === 2) ?
                                                <small className="text-uppercase">{pedido.destinatario}</small>
                                                : ''
                                            }
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        { (pedido.presupuesto_id && pedido.presupuesto) ? 
                                            <Link target="_blank" rel="noopener noreferrer" to={"/presupuesto/" + pedido.presupuesto?.id} title="Ficha presupuesto" className="text-green fw-bold">
                                                {pedido.presupuesto?.numero}
                                            </Link>
                                            : 
                                            <span title="No hay presupuesto asignado">
                                                <FontAwesomeIcon icon={faExclamationTriangle} size="lg" color="#ffc107" /> 
                                            </span>
                                        }
                                    </td>
                                    <td className="text-center">
                                        { (pedido.validado) ?
                                            <FontAwesomeIcon icon={faCheck} size="1x" color="#00986C" />
                                            : 
                                            <FontAwesomeIcon icon={faTimes} size="1x" color="#dc3545" />
                                        }
                                    </td>
                                    <td className="text-center">
                                        <ActionsMenu 
                                            options={getActions(pedido)}
                                            onAction={(action) => handleAction(action, pedido)}
                                        />
                                    </td>
                                </tr>
                            )})
                            :
                            <tr>
                                <td colSpan={9} className="text-center">No hay pedidos</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            
            <Pagination 
                id={id}
                className="mt-3" 
                count={getPaginationCount()} 
                page={currPage} 
                onChange={handleChangePage} 
            />
        </div>
    )
}