import { faCheck, faDownload, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getVale } from "../../../../../shared/services/proveedores";

export default function ValesTable({
    vales
}) {
    const dispatch = useDispatch();

    useEffect(() => {
        
    }, [vales]);

    const handleDownloadVale = async(vale) => {
        dispatch({type: 'loading/set', payload: true});
        const exportResult = await getVale(vale.id);

        if(exportResult) {
            dispatch({type: 'loading/set', payload: false});
            toast.success('Vale descargado correctamente!')
            
            const url = window.URL.createObjectURL(new Blob([exportResult]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', vale.foto);
            document.body.appendChild(link);
            link.click();
        } else {
            toast.error('Ups! Algo ha ido mal...')
        }
    }

    
    return (
        <div className="w-100 d-flex flex-column align-items-end">
            <div className="w-100 table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>fecha</th>
                            <th className="text-center">total</th>
                            <th className="text-center">gastado</th>
                            <th className="text-center">pendiente</th>
                            <th className="text-center">terminado</th>
                            <th className="text-center">activo</th>
                            <th className="text-center">acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        { vales?.map(vale => {
                            return (
                                <tr key={'vale-' + vale.id}>
                                    <td>{moment(vale.fecha).format('DD-MM-YYYY')}</td>
                                    <td className="text-center">{vale.total}€</td>
                                    <td className="text-center">{vale.gastado}€</td>
                                    <td className="text-center text-warning fw-bold">{vale.total - vale.gastado}€</td>
                                    <td className="text-center">
                                        { (vale.terminado) ?
                                            <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#00986C" />
                                            : 
                                            <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                                        }
                                    </td>
                                    <td className="text-center">
                                        { (vale.active) ?
                                            <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#00986C" />
                                            : 
                                            <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                                        }
                                    </td>
                                    <td className="text-center" width={'8%'}>
                                        <span title="Descargar" className="action me-2" style={{cursor: 'pointer'}} onClick={() => handleDownloadVale(vale)}>
                                            <FontAwesomeIcon icon={faDownload} size="1x" color="#215732" />
                                        </span>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}