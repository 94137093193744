import API from '../../../../../services/api';

export const getAvisosStock = async (page = null, search = null) => {
    const pagination = page !== null ? '?page=' + page : '';
    const searcher = search !== null && page !== null ? '&' + search : search !== null ? '?' + search : '';
    return await API.get('/avisos-stock' + pagination + searcher).then(res => res.data);
}

export const newAvisoStock = async (aviso) => {
    return API.post('/avisos-stock', aviso).then(res => res.data);
}

export const updateAvisoStock = async (aviso) => {
    return API.put('/avisos-stock/' + aviso.id, aviso).then(res => res.data);
}