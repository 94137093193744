import API from '../../../../../services/api';

export const getModelos = async () => {
    return await API.get('/modelos').then(res => res.data);
}

export const getModelo = async (idModelo) => {
    return API.get('/modelos/' + idModelo).then(res => res.data);
}

export const newModelo = async (modelo) => {
    return API.post('/modelos', modelo).then(res => res.data);
}

export const updateModelo = async (modelo) => {
    return API.put('/modelos', modelo).then(res => res.data);
}

export const deleteModelo = async (idModelo) => {
    return API.delete('/modelos/' + idModelo).then(res => res.data);
}